// GENERATED FILE - DO NOT MODIFY
export enum WageTypeString {
    Simple = "A",
    Over = "B",
    Double = "C",
    Bonus = "P",
    Equipment = "M",
}

export let nameof = "WageTypeString";
