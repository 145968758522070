import routerHelper from "helpers/routerHelper";
import documentService from "services/documentService";
import settingRepository from "repositories/settingRepository";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { autoinject } from "aurelia-dependency-injection";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class MaterialOrderDocumentAdd extends FormBase {
    public readonly: boolean = false;
    public dispatchProjectCode: number | null = null;
    public dispatchProjectDate: string | null = null;

    public actions: any = {};
    public isCategorySelectionAllowed: boolean = false;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly dispatchTemplateService: DispatchTemplateService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<void> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");

        this.setupHeaderInfos(params.dispatchProjectCode, params.dailyEntryDate);

        await this.setAction(params.orderId);
    }

    private setupHeaderInfos(projectCode: number, date: string): void {
        this.dispatchProjectCode = projectCode;
        this.dispatchProjectDate = date;
    }

    private async setAction(orderId: string): Promise<void> {
        const dispatchTemplateId = settingRepository.getDispatchTemplate();
        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(dispatchTemplateId!);
        this.isCategorySelectionAllowed = dispatchTemplate!.AllowCategorySelection;

        this.actions = {
            save: documentService.addDocumentByPurchaseOrderId.bind(self, orderId, dispatchTemplateId)
          };
    }
}
