define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getContact: function (id) {
            return requests.getJson(apiHelper.getBaseUrl() + "/contacts/" + id);
        },

        // TODO: KG - Ne semble pas utilisé
        getContacts: function (dispatchId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/contact");
        },

        // TODO: KG - ShowLoading: false - Transport
        getContactsLookup: function (filter, page, getEmployeeContacts) {
            return requests.getJson(
                    apiHelper.appendFilterPagingParams(apiHelper.getBaseUrl() + "/contacts/lookup" + (getEmployeeContacts ? "?getEmployeeContacts=true" : ""),
                        filter,
                        page)
             );
        },

        getContactLookupForDispatch: function (dispatchId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/contact/lookup");
        },

        getProjectContactsLookupForWorkOrder: function (workOrderId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/workorders/" + workOrderId + "/projectcontacts/lookup");
        },

        getClientContactsLookup: function (dispatchId, filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/clientcontacts/lookup",
                    filter,
                    page)
                );
        },

        getLookupByDispatchProjectCode: function (dispatchProjectCode, filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getBaseUrl() + "/dispatch/projects/" + dispatchProjectCode + "/contacts/lookup",
                    filter,
                    page)
            );
        },

        // TODO: KG - ShowLoading: false - Transport
        getLookupByCustomerCode: function (customerCode, filter, page) {
            return requests.getJson(
                        apiHelper.appendFilterPagingParams(
                            apiHelper.getBaseUrl() + "/clients/" + customerCode + "/contacts/lookup",
                            filter,
                            page)
                    );
        },

        // TODO: KG - ShowLoading: false - Transport
        getByCustomerCode: function (customerCode, filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getBaseUrl() + "/clients/" + customerCode + "/contacts",
                    filter,
                    page)
            );
        },

        getContactLookupForWorkOrder: function (workOrderId) {
            return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/contact/lookup");
        },

        // TODO: KG - ShowLoading: false - Transport
        getContactLookupForDailyEntrySummary: function (dispatchProjectCode, dispatchDate, filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/signature/contacts",
                    filter,
                    page)
            );
        }
    };

    return exports;
});
