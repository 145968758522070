import { DailyEntryWorkTaskDocumentService } from "services/daily-entry-work-task-document-service";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class DailyEntryWorkTaskEditDocumentsAdd extends FormBase {

    public actions: any;
    public dispatchDate: any;
    public dispatchProjectCode: string = "";
    public transactionNumber: number = 0;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly dailyEntryWorkTaskDocumentService: DailyEntryWorkTaskDocumentService
    ) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<void> {
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.transactionNumber = params.transactionNumber;
        
        this.actions = { save: (models: any): JQueryDeferred<any> => {
            const dfd = jQuery.Deferred();
            this.dailyEntryWorkTaskDocumentService.AddDocumentForWorkTask(this.transactionNumber, models).then(() => {
                return dfd.resolve();
            });
            return dfd;
         }};
    }
}
