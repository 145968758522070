// NOTE! ECM : La vraie librairie utilisait à un endroit particulier une syntaxe que notre Webpack n'était pas capable de comprendre.
// L'on a donc modifié légèrement le code et l'on a hardcodé le chemin auquel les fichiers de la librairie se retrouvent dans le site web.
// Donc, actuellement, le WebPack va copier les fichiers .js et .wasm dans '<chemin_vers_site_mobile>/dynamsoft/*'
// Pour se faire, la partie hardcodée est le bout de code 'var e=c._bDom?"dynamsoft":void 0;' où l'on force le chemin "dynamsoft".

/**
 * Dynamsoft JavaScript Library
 * @product Dynamsoft Barcode Reader JS Edition
 * @website http://www.dynamsoft.com
 * @preserve Copyright 2019, Dynamsoft Corporation
 * @author Dynamsoft
 * @version 6.5.1
 * @fileoverview Dynamsoft JavaScript Library for Barcode Reader
 * More info on DBR JS: https://www.dynamsoft.com/Products/barcode-recognition-javascript.aspx
 */
/* eslint-disable */
var $jscomp=$jscomp||{};$jscomp.scope={};$jscomp.arrayIteratorImpl=function(c){var f=0;return function(){return f<c.length?{done:!1,value:c[f++]}:{done:!0}}};$jscomp.arrayIterator=function(c){return{next:$jscomp.arrayIteratorImpl(c)}};$jscomp.makeIterator=function(c){var f="undefined"!=typeof Symbol&&Symbol.iterator&&c[Symbol.iterator];return f?f.call(c):$jscomp.arrayIterator(c)};var dynamsoft="undefined"!=typeof dynamsoft?dynamsoft:("undefined"!=typeof dynamsoft?this.dynamsoft:void 0)||{};
dynamsoft.TaskQueue=dynamsoft.TaskQueue||function(){var c=function(){this._queue=[];this.isWorking=!1;this.timeout=100};c.prototype.push=function(c,a,e){this._queue.push({task:c,context:a,args:e});this.isWorking||this.next()};c.prototype.unshift=function(c,a,e){this._queue.unshift({task:c,context:a,args:e});this.isWorking||this.next()};c.prototype.next=function(){if(0==this._queue.length)this.isWorking=!1;else{this.isWorking=!0;var c=this._queue.shift(),a=c.task,e=c.context?c.context:null,d=c.args?
c.args:[];setTimeout(function(){a.apply(e,d)},this.timeout)}};return c}();dynamsoft.dbrEnv=dynamsoft.dbrEnv||{};"undefined"!=typeof self?(dynamsoft.dbrEnv._self=self,self.document?dynamsoft.dbrEnv._bDom=!0:dynamsoft.dbrEnv._bWorker=!0):(dynamsoft.dbrEnv._self=global,dynamsoft.dbrEnv._bNodejs=!0);
dynamsoft.BarcodeReader=dynamsoft.BarcodeReader||function(){var c=dynamsoft.dbrEnv,f=c._self,a=function(b){b=b||a.licenseKey||"";if(!("string"==typeof b||"object"==typeof b&&b instanceof String))throw TypeError("'Constructor BarcodeReader(licenseKey)': Type of 'licenseKey' should be 'String'.");this._licenseKey=b;if(a._BarcodeReaderWasm){this._instance=new a._BarcodeReaderWasm(this._licenseKey);if(!this._instance)throw a.BarcodeReaderException(a.EnumErrorCode.DBR_NULL_REFERENCE,"Can't create BarcodeReader instance.");
this._instance.bInWorker&&(this._instance=void 0)}else throw Error("'Constructor BarcodeReader(licenseKey)': Autoload haven't start or you want to call `loadWasm` manually.");};a._jsVersion="6.5.1";a._jsEditVersion="20190426";a.version="loading...(JS "+a._jsVersion+"."+a._jsEditVersion+")";a.isLoaded=function(){return"loaded"==this._loadWasmStatus};a.licenseKey=void 0;var e=c._bDom?"dynamsoft":void 0;a._resourcesPath=e;a._workerName=void 0;a._wasmjsName=void 0;a._wasmName=void 0;a._workerResourcePath=
void 0;a._wasmjsResourcePath=void 0;a._wasmResourcePath=void 0;a._isShowRelDecodeTimeInResults=!1;a._bCustomWorker=void 0;a._bWithio=void 0;a._onWasmDownloaded=void 0;a.createInstance=function(b){return a.loadWasm().then(function(){return Promise.resolve(new a(b))})};var d={};a._loadWorker=function(){return new Promise(function(b,h){var c=a._workerName||"dbr-"+a._jsVersion+".min.js";if(a._workerResourcePath||a._resourcesPath){var l=a._workerResourcePath||a._resourcesPath;"/"!=l.charAt(l.length-1)&&
(l+="/");c=l+c}fetch(c).then(function(b){return b.blob()}).then(function(c){var l=URL.createObjectURL(c);c=a._dbrWorker=new Worker(l);c.onerror=function(b){b="worker error, you should host the page in web service: "+b.message;f.kConsoleLog&&f.kConsoleError(b);h(b)};c.onmessage=function(c){c=c.data;switch(c.type){case "log":f.kConsoleLog&&f.kConsoleLog(c.message);break;case "load":c.success?(a.version=c.version,a._defaultRuntimeSettings=c._defaultRuntimeSettings,f.kConsoleLog&&f.kConsoleLog("load dbr worker success"),
b()):h(c.exception);URL.revokeObjectURL(l);break;case "task":try{d[c.id](c.body),d[c.id]=void 0}catch(u){throw d[c.id]=void 0,u;}break;default:f.kConsoleLog&&f.kConsoleLog(c)}};var e=document.createElement("a");e.href=a._resourcesPath||"./";var q=void 0;a._wasmjsResourcePath&&(q=document.createElement("a"),q.href=a._wasmjsResourcePath||"./",q=q.href);var p=void 0;a._wasmResourcePath&&(p=document.createElement("a"),p.href=a._wasmResourcePath||"./",p=p.href);c.postMessage({type:"loadWorker",_resourcesPath:e.href,
_wasmjsResourcePath:q,_wasmResourcePath:p,origin:location.origin})}).catch(function(b){h(b)})})};a.prototype._createWorkerInstance=function(){var b=this;return new Promise(function(c,e){var h=Math.random();d[h]=function(d){return d.success?d.instanceID?(b._instanceID=d.instanceID,c()):e(a.BarcodeReaderException(a.EnumErrorCode.DBR_NULL_REFERENCE,"Can't create BarcodeReader instance.")):e(d.exception)};a._dbrWorker.postMessage({type:"createInstance",id:h,licenseKey:b._licenseKey})})};a.prototype.deleteInstance=
function(){var b=this;if(this._instance)this._instance.delete();else return new Promise(function(c,e){var h=Math.random();d[h]=function(b){return b.success?c():e(b.exception)};a._dbrWorker.postMessage({type:"deleteInstance",id:h,instanceID:b._instanceID})})};a.prototype._decodeBlob=function(b,a){var d=this;if(!(f.Blob&&b instanceof Blob))return Promise.reject("'_decodeBlob(blob, templateName)': Type of 'blob' should be 'Blob'.");a=a||"";return c._bDom?(new Promise(function(a,d){var c=URL.createObjectURL(b),
h=new Image;h.dbrObjUrl=c;h.src=c;h.onload=function(){a(h)};h.onerror=function(){d(TypeError("'_decodeBlob(blob, templateName)': Can't convert the blob to image."))}})).then(function(b){return d._decodeImage(b)}):(new Promise(function(a){var d=new FileReader;d.onload=function(){a(d.result)};d.readAsArrayBuffer(b)})).then(function(b){return d._decodeArrayBuffer(b,a)})};a.prototype._decodeArrayBuffer=function(b,a){if(!(f.arrayBuffer&&b instanceof ArrayBuffer||f.Buffer&&b instanceof Buffer))return Promise.reject("'_decodeBlob(arrayBuffer, templateName)': Type of 'arrayBuffer' should be 'ArrayBuffer' or 'Buffer'.");
a=a||"";return c._bDom?this._decodeBlob(new Blob(b),a):this._decodeUint8Array(new Uint8Array(b))};a.prototype._decodeUint8Array=function(b,d){var h=this;if(!(f.Uint8Array&&b instanceof Uint8Array||f.Uint8ClampedArray&&b instanceof Uint8ClampedArray))return Promise.reject("'_decodeBlob(uint8Array, templateName)': Type of 'uint8Array' should be 'Uint8Array'.");d=d||"";return c._bDom?h._decodeBlob(new Blob(b),d):new Promise(function(c){if(a._isShowRelDecodeTimeInResults){var e=(new Date).getTime(),l=
h._instance.DecodeFileInMemory(b,d);e=(new Date).getTime()-e;l=g(l);l._decodeTime=e;return c(l)}return c(g(h._instance.DecodeFileInMemory(b,d)))})};a.prototype._decodeImage=function(b,a){var d=this;return(new Promise(function(d){if(!(f.HTMLImageElement&&b instanceof HTMLImageElement))throw TypeError("'_decodeImage(image, templateName)': Type of 'image' should be 'HTMLImageElement'.");if(b.crossOrigin&&"anonymous"!=b.crossOrigin)throw"cors";a=a||"";d()})).then(function(){var c=document.createElement("canvas");
c.width=b.naturalWidth;c.height=b.naturalHeight;c.getContext("2d").drawImage(b,0,0);b.dbrObjUrl&&URL.revokeObjectURL(b.dbrObjUrl);return d._decodeCanvas(c,a)})};a.prototype._decodeCanvas=function(b,d){var c=this;return(new Promise(function(a){if(!(f.HTMLCanvasElement&&b instanceof HTMLCanvasElement))throw TypeError("'_decodeCanvas(canvas, templateName)': Type of 'canvas' should be 'HTMLCanvasElement'.");if(b.crossOrigin&&"anonymous"!=b.crossOrigin)throw"cors";d=d||"";var c=b.getContext("2d").getImageData(0,
0,b.width,b.height).data;a(c)})).then(function(h){return c._decodeRawImageUint8Array(h,b.width,b.height,4*b.width,a.EnumImagePixelFormat.IPF_ARGB_8888,d)})};a.prototype._decodeVideo=a.prototype.decodeVideo=function(b){var a=this,d,c=arguments,e,r;return(new Promise(function(h){if(!(f.HTMLVideoElement&&b instanceof HTMLVideoElement))throw TypeError("'_decodeVideo(video [ [, sx, sy, sWidth, sHeight], dWidth, dHeight] [, templateName] )': Type of 'video' should be 'HTMLVideoElement'.");if(b.crossOrigin&&
"anonymous"!=b.crossOrigin)throw"cors";var l,g,p;if(2>=c.length){var t=l=0;var k=g=b.videoWidth;var m=p=b.videoHeight}else 4>=c.length?(t=l=0,k=b.videoWidth,m=b.videoHeight,g=c[1],p=c[2]):(t=c[1],l=c[2],k=c[3],m=c[4],g=c[5],p=c[6]);d="string"==typeof c[c.length-1]?c[c.length-1]:"";r=document.createElement("canvas");r.width=g;r.height=p;var n=r.getContext("2d");a._bAddOriVideoCanvasToResult&&(e=document.createElement("canvas"),e.width=b.videoWidth,e.height=b.videoHeight,e.getContext("2d").drawImage(b,
0,0));n.drawImage(e||b,t,l,k,m,0,0,g,p);h()})).then(function(){return a._decodeCanvas(r,d)}).then(function(b){if(a._bAddOriVideoCanvasToResult)for(var d=$jscomp.makeIterator(b),c=d.next();!c.done;c=d.next())c.value.oriVideoCanvas=e;if(a._bAddSearchRegionCanvasToResult)for(d=$jscomp.makeIterator(b),c=d.next();!c.done;c=d.next())c.value.searchRegionCanvas=r;return b})};a.prototype._decodeBase64=a.prototype.decodeBase64String=function(b,a){var d=this;if(!("string"==typeof b||"object"==typeof b&&b instanceof
String))return Promise.reject("'decodeBase64(base64Str, templateName)': Type of 'base64Str' should be 'String'.");"data:image/"==b.substring(0,11)&&(b=b.substring(b.indexOf(",")+1));a=a||"";return new Promise(function(h){if(c._bNodejs)h(d._decodeArrayBuffer(Buffer.from(b,"base64"),a));else{for(var e=atob(b),f=e.length,g=new Uint8Array(f);f--;)g[f]=e.charCodeAt(f);c._bDom?h(d._decodeBlob(new Blob([g]),a)):h(d._decodeUint8Array(g,a))}})};a.prototype._decodeUrl=function(b,a){var d=this;return new Promise(function(e,
h){if(!("string"==typeof b||"object"==typeof b&&b instanceof String))throw TypeError("'_decodeUrl(url, templateName)': Type of 'url' should be 'String'.");a=a||"";if(c._bNodejs)(b.startsWith("https")?require("https"):require("http")).get(b,function(b){if(200==b.statusCode){var c=[];b.on("data",function(b){c.push(b)}).on("end",function(){e(d._decodeArrayBuffer(Buffer.concat(c),a))})}else h("http get fail, statusCode: "+b.statusCode)});else{var f=new XMLHttpRequest;f.open("GET",b,!0);f.responseType=
c._bDom?"blob":"arraybuffer";f.send();f.onloadend=function(){c._bDom?e(d._decodeBlob(this.response,a)):e(d._decodeArrayBuffer(this.response,a))};f.onerror=function(){h(f.error)}}})};a.prototype._decodeFilePath=function(b,a){var d=this;return new Promise(function(c,e){if(!("string"==typeof b||"object"==typeof b&&b instanceof String))throw TypeError("'_decodeFilePath(path, templateName)': Type of 'path' should be 'String'.");a=a||"";require("fs").readFile(b,function(b,h){b?e(b):c(d._decodeArrayBuffer(h,
a))})})};a.prototype._decodeRawImageBlob=function(b,a,d,c,e,g){var h=this;return(new Promise(function(a,d){if(!(f.Blob&&b instanceof Blob))throw TypeError("'_decodeRawImageBlob(buffer, width, height, stride, enumImagePixelFormat, templateName)': Type of 'buffer' should be 'Blob'.");g=g||"";var c=new FileReader;c.readAsArrayBuffer(b);c.onload=function(){a(c.result)};c.onerror=function(){d(c.error)}})).then(function(b){return h._decodeRawImageUint8Array(new Uint8Array(b),a,d,c,e,g)})};a.prototype._decodeRawImageArrayBuffer=
function(b,a,d,c,e,g){var h=this;return(new Promise(function(a){if(!(f.ArrayBuffer&&b instanceof ArrayBuffer))throw TypeError("'_decodeRawImageArrayBuffer(buffer, width, height, stride, enumImagePixelFormat, templateName)': Type of 'buffer' should be 'ArrayBuffer'.");g=g||"";a()})).then(function(){return h._decodeRawImageUint8Array(new Uint8Array(b),a,d,c,e,g)})};a.prototype._decodeRawImageUint8Array=function(b,c,e,l,q,r){var h=this;return(new Promise(function(a){if(!(f.Uint8Array&&b instanceof Uint8Array||
f.Uint8ClampedArray&&b instanceof Uint8ClampedArray))throw TypeError("'_decodeRawImageUint8Array(buffer, width, height, stride, enumImagePixelFormat, templateName)': Type of 'buffer' should be 'Uint8Array'.");r=r||"";h._localizationResultArray=void 0;a()})).then(function(){if(h._instance){if(a._isShowRelDecodeTimeInResults){var f=(new Date).getTime(),p=h._instance.DecodeBuffer(b,c,e,l,q,r);f=(new Date).getTime()-f;p=g(p);p._decodeTime=f;return Promise.resolve(p)}return Promise.resolve(g(h._instance.DecodeBuffer(b,
c,e,l,q,r)))}var t;p=h._instanceID?Promise.resolve():h._createWorkerInstance();return p.then(function(){return null===h._runtimeSettings?new Promise(function(b,c){var m=Math.random();d[m]=function(a){return a.success?(h._runtimeSettings=void 0,b()):c(a.exception)};a._dbrWorker.postMessage({type:"resetRuntimeSettings",id:m,instanceID:h._instanceID})}):Promise.resolve()}).then(function(){return new Promise(function(f,k){var m=Math.random();d[m]=function(b){return b.success?f(b.results):k(b.exception)};
a._dbrWorker.postMessage({type:"decodeRawImageUint8Array",id:m,instanceID:h._instanceID,body:{buffer:b,width:c,height:e,stride:l,enumImagePixelFormat:q,templateName:r},_isShowRelDecodeTimeInResults:a._isShowRelDecodeTimeInResults})})}).then(function(b){t=b;return new Promise(function(b,c){var m=Math.random();d[m]=function(a){return a.success?b(a.results):c(a.exception)};a._dbrWorker.postMessage({type:"getAllLocalizationResults",id:m,instanceID:h._instanceID})})}).then(function(b){h._localizationResultArray=
b;return Promise.resolve(t)})})};a.prototype.decode=a.prototype.decodeFileInMemory=function(b,a){return f.Blob&&b instanceof Blob?this._decodeBlob(b,a):f.ArrayBuffer&&b instanceof ArrayBuffer||f.Buffer&&b instanceof Buffer?this._decodeArrayBuffer(b,a):f.Uint8Array&&b instanceof Uint8Array||f.Uint8ClampedArray&&b instanceof Uint8ClampedArray?this._decodeUint8Array(b,a):f.HTMLImageElement&&b instanceof HTMLImageElement?this._decodeImage(b,a):f.HTMLCanvasElement&&b instanceof HTMLCanvasElement?this._decodeCanvas(b,
a):f.HTMLVideoElement&&b instanceof HTMLVideoElement?this._decodeVideo(b,a):"string"==typeof b||b instanceof String?"data:image/"==b.substring(0,11)?this._decodeBase64(b,a):c._bNodejs?"http"==b.substring(0,4)?this._decodeUrl(b,a):this._decodeFilePath(b,a):this._decodeUrl(b,a):Promise.reject(TypeError("'_decode(source, templateName)': Type of 'source' should be 'Blob', 'ArrayBuffer', 'Uint8Array', 'HTMLImageElement', 'HTMLCanvasElement', 'HTMLVideoElement', 'String(base64 with image mime)' or 'String(url)'."))};
a.prototype._decodeRawImage=a.prototype.decodeBuffer=function(b,a,c,d,e,g){return f.Blob&&b instanceof Blob?this._decodeRawImageBlob(b,a,c,d,e,g):f.ArrayBuffer&&b instanceof ArrayBuffer?this._decodeRawImageArrayBuffer(b,a,c,d,e,g):f.Uint8Array&&b instanceof Uint8Array||f.Uint8ClampedArray&&b instanceof Uint8ClampedArray?this._decodeRawImageUint8Array(b,a,c,d,e,g):Promise.reject(TypeError("'_decodeRawImage(source, width, height, stride, enumImagePixelFormat, templateName)': Type of 'source' should be 'Blob', 'ArrayBuffer' or 'Uint8Array'."))};
var g=a._handleRetJsonString=function(b){b="string"==typeof b||"object"==typeof b&&b instanceof String?JSON.parse(b):b;var d=a.EnumErrorCode;switch(b.exception){case d.DBR_SUCCESS:case d.DBR_LICENSE_INVALID:case d.DBR_LICENSE_EXPIRED:case d.DBR_1D_LICENSE_INVALID:case d.DBR_QR_LICENSE_INVALID:case d.DBR_PDF417_LICENSE_INVALID:case d.DBR_DATAMATRIX_LICENSE_INVALID:case d.DBR_DBRERR_AZTEC_LICENSE_INVALID:case d.DBR_RECOGNITION_TIMEOUT:if(b.textResult)for(d=0;d<b.textResult.length;++d){var e=b.textResult[d];
try{e.BarcodeText=c._bNodejs?Buffer.from(e.BarcodeText,"base64").toString():atob(e.BarcodeText)}catch(l){e.BarcodeText=""}}return b.textResult||b.localizationResultArray||b.Result||b.templateSettings||b.settings||b.outputSettings||b;default:throw a.BarcodeReaderException(b.exception,b.description);}};a.prototype.getAllLocalizationResults=function(){return this._localizationResultArray?this._localizationResultArray:g(this._instance.GetAllLocalizationResults())};a.prototype.getAllParameterTemplateNames=
function(){return g(this._instance.GetAllParameterTemplateNames())};a.prototype.getRuntimeSettings=function(){return this._instance?g(this._instance.GetRuntimeSettings()):this._runtimeSettings?JSON.parse(this._runtimeSettings):a._defaultRuntimeSettings};a.prototype.updateRuntimeSettings=function(b){var c=this;if("string"==typeof b||"object"==typeof b&&b instanceof String)var e=b;else if("object"==typeof b)e=JSON.stringify(b);else throw TypeError("'UpdateRuntimeSettings(settings)': Type of 'settings' should be 'String' or 'PlainObject'.");
if(c._instance)try{return g(c._instance.UpdateRuntimeSettings(e)),Promise.resolve()}catch(l){return Promise.reject(l)}else return Promise.resolve().then(function(){return c._instanceID?Promise.resolve():c._createWorkerInstance()}).then(function(){return new Promise(function(b,f){var g=Math.random();d[g]=function(a){return a.success?(c._runtimeSettings=e,b()):f(a.exception)};a._dbrWorker.postMessage({type:"updateRuntimeSettings",id:g,instanceID:c._instanceID,body:{settings:e}})})}).catch(function(b){return Promise.reject(b)})};
a.prototype.resetRuntimeSettings=function(){this._instance?g(this._instance.ResetRuntimeSettings()):this._runtimeSettings=null};a.prototype.outputSettingsToString=function(b){return g(this._instance.OutputSettingsToString(b||""))};a.prototype.initRuntimeSettingsWithString=function(b,a){if(!("string"==typeof b||"object"==typeof b&&b instanceof String))if("object"==typeof b)b=JSON.stringify(b);else throw TypeError("'initRuntimeSettingstWithString(template, enumComflictMode)': Type of 'template' should be 'String' or 'PlainObject'.");
g(this._instance.InitRuntimeSettingstWithString(b,a?a:2))};a.prototype.appendTplStringToRuntimeSettings=function(b,a){if(!("string"==typeof b||"object"==typeof b&&b instanceof String))if("object"==typeof b)b=JSON.stringify(b);else throw TypeError("'appendTplStringToRuntimeSettings(template, enumComflictMode)': Type of 'template' should be 'String' or 'PlainObject'.");g(this._instance.AppendTplStringToRuntimeSettings(b,a?a:2))};a.EnumBarcodeFormat=a.EnumBarcodeFormat||{All:503317503,OneD:1023,CODE_39:1,
CODE_128:2,CODE_93:4,CODABAR:8,ITF:16,EAN_13:32,EAN_8:64,UPC_A:128,UPC_E:256,INDUSTRIAL_25:512,PDF417:33554432,QR_CODE:67108864,DATAMATRIX:134217728,AZTEC:268435456};a.EnumErrorCode=a.EnumErrorCode||{DBR_SYSTEM_EXCEPTION:1,DBR_SUCCESS:0,DBR_UNKNOWN:-1E4,DBR_NO_MEMORY:-10001,DBR_NULL_REFERENCE:-10002,DBR_LICENSE_INVALID:-10003,DBR_LICENSE_EXPIRED:-10004,DBR_FILE_NOT_FOUND:-10005,DBR_FILETYPE_NOT_SUPPORTED:-10006,DBR_BPP_NOT_SUPPORTED:-10007,DBR_INDEX_INVALID:-10008,DBR_BARCODE_FORMAT_INVALID:-10009,
DBR_CUSTOM_REGION_INVALID:-10010,DBR_MAX_BARCODE_NUMBER_INVALID:-10011,DBR_IMAGE_READ_FAILED:-10012,DBR_TIFF_READ_FAILED:-10013,DBR_QR_LICENSE_INVALID:-10016,DBR_1D_LICENSE_INVALID:-10017,DBR_DIB_BUFFER_INVALID:-10018,DBR_PDF417_LICENSE_INVALID:-10019,DBR_DATAMATRIX_LICENSE_INVALID:-10020,DBR_PDF_READ_FAILED:-10021,DBR_PDF_DLL_MISSING:-10022,DBR_PAGE_NUMBER_INVALID:-10023,DBR_CUSTOM_SIZE_INVALID:-10024,DBR_CUSTOM_MODULESIZE_INVALID:-10025,DBR_RECOGNITION_TIMEOUT:-10026,DBR_JSON_PARSE_FAILED:-10030,
DBR_JSON_TYPE_INVALID:-10031,DBR_JSON_KEY_INVALID:-10032,DBR_JSON_VALUE_INVALID:-10033,DBR_JSON_NAME_KEY_MISSING:-10034,DBR_JSON_NAME_VALUE_DUPLICATED:-10035,DBR_TEMPLATE_NAME_INVALID:-10036,DBR_JSON_NAME_REFERENCE_INVALID:-10037,DBR_PARAMETER_VALUE_INVALID:-10038,DBR_DOMAIN_NOT_MATCHED:-10039,DBR_RESERVEDINFO_NOT_MATCHED:-10040,DBR_DBRERR_AZTEC_LICENSE_INVALID:-10041};a.EnumImagePixelFormat=a.EnumImagePixelFormat||{IPF_Binary:0,IPF_BinaryInverted:1,IPF_GrayScaled:2,IPF_NV21:3,IPF_RGB_565:4,IPF_RGB_555:5,
IPF_RGB_888:6,IPF_ARGB_8888:7,IPF_RGB_161616:8,IPF_ARGB_16161616:9};a.EnumResultType=a.EnumResultType||{EDT_StandardText:0,EDT_RawText:1,EDT_CandidateText:2,EDT_PartialText:3};a.EnumTerminateStage=a.EnumTerminateStage||{ETS_Prelocalized:0,ETS_Localized:1,ETS_Recognized:2};a.EnumConflictMode=a.EnumConflictMode||{ECM_Ignore:1,ECM_Overwrite:2};a.BarcodeReaderException=a.BarcodeReaderException||function(){return function(b,d){var c=a.EnumErrorCode.DBR_UNKNOWN;"number"==typeof b?(c=b,b=Error(d)):b=Error(b);
b.code=c;return b}}();return a}();
!dynamsoft.dbrEnv._bWorker||dynamsoft.BarcodeReader._bCustomWorker||dynamsoft.dbrEnv._self.onmessage||function(){var c=dynamsoft.dbrEnv,f=c._self,a=dynamsoft.BarcodeReader;f.kConsoleLog=f.kConsoleLog||function(d){if(f.onmessage!=a._onWorkerMessage)f.kConsoleLog=void 0;else{var c=void 0;if(void 0===d)c="undefined";else{try{c=JSON.stringify(d,function(){var b=[],a=[];return function(c,d){if("object"===typeof d&&null!==d){var e=b.indexOf(d);if(-1!==e)return"[Circular "+a[e]+"]";b.push(d);a.push(c||"root")}return d}}())}catch(b){}if(void 0===
c||"{}"===c)c=d.toString()}try{postMessage({type:"log",message:c})}catch(b){f.console&&console.error(message)}}};f.kConsoleLog("have detected in worker: "+a.version);var e={};f.onmessage=a._onWorkerMessage=function(d){d=d.data;switch(d.type){case "loadWorker":(function(){a._resourcesPath=d._resourcesPath;a._wasmjsResourcePath=d._wasmjsResourcePath;a._wasmResourcePath=d._wasmResourcePath;c.origin=d.origin;a.loadWasm().then(function(){postMessage({type:"load",success:!0,version:a.version,_defaultRuntimeSettings:a._defaultRuntimeSettings})},
function(a){postMessage({type:"load",success:!1,exception:a.message||a})})})();break;case "createInstance":(function(){try{var c=Math.random();e[c]=new a(d.licenseKey);postMessage({type:"task",id:d.id,body:{success:!0,instanceID:c}})}catch(b){postMessage({type:"task",id:d.id,body:{success:!1,exception:b.message||b}})}})();break;case "deleteInstance":try{e[d.instanceID].deleteInstance(),e[d.instanceID]=void 0,postMessage({type:"task",id:d.id,body:{success:!0}})}catch(g){postMessage({type:"task",id:d.id,
body:{success:!1,exception:g.message||g}})}break;case "decodeRawImageUint8Array":(function(){try{a._isShowRelDecodeTimeInResults=d._isShowRelDecodeTimeInResults;var c=d.body;e[d.instanceID]._decodeRawImageUint8Array(c.buffer,c.width,c.height,c.stride,c.enumImagePixelFormat,c.templateName).then(function(b){postMessage({type:"task",id:d.id,body:{success:!0,results:b}})}).catch(function(b){postMessage({type:"task",id:d.id,body:{success:!1,exception:b.message||b}})})}catch(b){postMessage({type:"task",
id:d.id,body:{success:!1,exception:b.message||b}})}})();break;case "getAllLocalizationResults":(function(){try{var a=e[d.instanceID].getAllLocalizationResults();postMessage({type:"task",id:d.id,body:{success:!0,results:a}})}catch(b){postMessage({type:"task",id:d.id,body:{success:!1,exception:b.message||b}})}})();break;case "getRuntimeSettings":(function(){try{var a=e[d.instanceID].getRuntimeSettings();postMessage({type:"task",id:d.id,body:{success:!0,settings:a}})}catch(b){postMessage({type:"task",
id:d.id,body:{success:!1,exception:b.message||b}})}})();break;case "updateRuntimeSettings":try{e[d.instanceID].updateRuntimeSettings(d.body.settings),postMessage({type:"task",id:d.id,body:{success:!0}})}catch(g){postMessage({type:"task",id:d.id,body:{success:!1,exception:g.message||g}})}break;case "resetRuntimeSettings":try{e[d.instanceID].resetRuntimeSettings(),postMessage({type:"task",id:d.id,body:{success:!0}})}catch(g){postMessage({type:"task",id:d.id,body:{success:!1,exception:g.message||g}})}break;
default:postMessage({type:"task",id:d.id,body:{success:!1,exception:"No such task."}})}}}();
dynamsoft.BarcodeReader.loadWasm=dynamsoft.BarcodeReader.loadWasm||function(){return new Promise(function(c,f){var a=dynamsoft.dbrEnv,e=a._self,d=dynamsoft.BarcodeReader;if("loaded"==d._loadWasmStatus)return c();d._loadWasmTaskQueue=d._loadWasmTaskQueue||function(){var a=new dynamsoft.TaskQueue;a.timeout=0;return a}();d._loadWasmTaskQueue.push(function(g){if("loaded"==d._loadWasmStatus)return d._loadWasmTaskQueue.next(),c();if(g)return d._loadWasmTaskQueue.next(),f(d._loadWasmStatus);d._loadWasmStatus=
"loading";return(new Promise(function(b,c){d._BarcodeReaderWasm=function(){throw Error("'Constructor BarcodeReader(licenseKey)': The wasm hasn't finish loading.");};if(!e.WebAssembly||"undefined"!=typeof navigator&&/Safari/.test(navigator.userAgent)&&!/Chrome/.test(navigator.userAgent)&&/\(.+\s11_2_([2-6]).*\)/.test(navigator.userAgent))return c("'Constructor BarcodeReader(licenseKey)': The browser doesn't support Webassembly.");if(a._bDom)return d._loadWorker().then(function(){d._BarcodeReaderWasm=
function(){this.bInWorker=!0};return b()},function(a){d._BarcodeReaderWasm=function(){throw Error("'Constructor BarcodeReader(licenseKey)': The wasm load failed. ex: "+(a.message||a));};return c(a)});var f={locateFile:function(){var b=d._wasmName||"dbr-"+d._jsVersion+(d._bWithio?".withio":"")+".wasm";if(d._wasmResourcePath||d._resourcesPath){var c=d._wasmResourcePath||d._resourcesPath;"/"!=c.charAt(c.length-1)&&(c+="/");b=c+b}else a._bNodejs&&(b=require("path").join(__dirname,b));return b},onRuntimeInitialized:function(){d._BarcodeReaderWasm=
f.BarcodeReaderWasm;var a=new f.BarcodeReaderWasm("");d.version=a.GetVersion()+"(JS "+d._jsVersion+"."+d._jsEditVersion+")";d._defaultRuntimeSettings=d._handleRetJsonString(a.GetRuntimeSettings());a.delete();e.kConsoleLog&&e.kConsoleLog("load dbr wasm success, version: "+d.version);b()}};f.onExit=f.onAbort=function(a){e.kConsoleLog&&kConsoleLog(a);d._BarcodeReaderWasm=function(){throw Error("'Constructor BarcodeReader(licenseKey)': The wasm load failed. Error: "+(a.message||a));};c(a)};f.print=function(a){e.kConsoleLog&&
kConsoleLog(a)};if("undefined"==typeof _dbrLoadWasm){void 0==d._bWithio&&(d._bWithio=a._bNodejs||a._bWorker&&(d._bCustomWorker||e.onmessage!=d._onWorkerMessage));var g=d._wasmjsName||"dbr-"+d._jsVersion+".wasm"+(d._bWithio?".withio":"")+".min.js";if(d._wasmjsResourcePath||d._resourcesPath){var h=d._wasmjsResourcePath||d._resourcesPath;"/"!=h.charAt(h.length-1)&&(h+="/");g=h+g}else a._bNodejs&&(g=require("path").join(__dirname,g));a._bWorker?importScripts(g):_dbrLoadWasm=require(g)}_dbrLoadWasm(f,
function(b,c,f){e.kConsoleLog&&kConsoleLog("start handle dbr wasm, version: "+b);var g=(new Date).getTime();if(a._bNodejs)return new Promise(function(a,b){require("fs").readFile(c,function(c,d){if(c)return b(c);var m=(new Date).getTime();a(WebAssembly.instantiate(d,f).then(function(a){e.kConsoleLog&&kConsoleLog("build instance from binary timecost: "+((new Date).getTime()-m));e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+((new Date).getTime()-g));return Promise.resolve(a)}))})});
var h=""+b;d._openDb=d._openDb||function(){return new Promise(function(a,b){var c=function(){d.result.createObjectStore("info");d.result.createObjectStore("wasm");d.result.createObjectStore("module")},d=indexedDB.open("dynamsoft-dbr-wasm",1);d.onupgradeneeded=c;d.onsuccess=function(){a(d.result)};d.onerror=function(){var e=d.error.message||d.error;-1!=e.indexOf("version")?(d=indexedDB.deleteDatabase("dynamsoft-dbr-wasm"),d.onsuccess=function(){var d=indexedDB.open("dynamsoft-dbr-wasm",1);d.onupgradeneeded=
c;d.onsuccess=function(){a(d.result)};d.onerror=function(){b("open db [dynamsoft-dbr-wasm] fail")}},d.onerror=function(){b("open db [dynamsoft-dbr-wasm] fail")}):b("open db [dynamsoft-dbr-wasm] fail: "+e)}})};var l=function(a){return new Promise(function(b,c){var d=a.transaction(["info"]).objectStore("info").get("bSupportStoreModuleInDb"),e=function(){var d=a.transaction(["info"],"readwrite").objectStore("info"),e=new Uint8Array([0,97,115,109,1,0,0,0,1,5,1,96,0,1,127,3,2,1,0,7,6,1,2,95,97,0,0,10,
6,1,4,0,65,1,11]);try{var f=d.put(new WebAssembly.Module(e.buffer),"testStoreModule");f.onsuccess=function(){d.put(!0,"bSupportStoreModuleInDb").onsuccess=function(){b("set bSupportStoreModuleInDb = true success")}};f.onerror=function(){c("Failed to store [testStoreModule] in wasm cache, bSupportStoreModuleInDb == false: "+(f.error.message||f.error))}}catch(y){c("Failed to store [testStoreModule] in wasm cache, bSupportStoreModuleInDb == false: "+(y.message||y))}};d.onsuccess=function(){d.result?
b("bSupportStoreModuleInDb == true"):e()}})};d._lookupInDatabase=d._lookupInDatabase||function(a,b,c){return new Promise(function(d,e){var f=a.transaction([b]).objectStore(b).get(c);f.onsuccess=function(){f.result?d(f.result):e(c+" was not found in "+b)}})};d._storeInDatabase=d._storeInDatabase||function(a,b,c,d){return new Promise(function(e,f){var m=a.transaction([b],"readwrite").objectStore(b).put(d,c);m.onerror=function(){f("Failed to store "+c+" in wasm cache: "+(m.error.message||m.error))};
m.onsuccess=function(){e("Successfully stored "+c+" in "+b)}})};return d._openDb().then(function(a){e.kConsoleLog&&kConsoleLog("open db success");return l(a).then(function(a){e.kConsoleLog&&kConsoleLog(a);return Promise.resolve(!0)},function(a){e.kConsoleLog&&kConsoleLog(a.message||a);return Promise.resolve(!1)}).then(function(b){var m=(new Date).getTime();return d._lookupInDatabase(a,"wasm",h).then(function(c){if(c instanceof WebAssembly.Module){e.kConsoleLog&&kConsoleLog("get a wasm module from db, timecost:"+
((new Date).getTime()-m));var k=(new Date).getTime();return WebAssembly.instantiate(c,f).then(function(a){e.kConsoleLog&&kConsoleLog("build instance from module timecost: "+((new Date).getTime()-k));e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+((new Date).getTime()-g));return Promise.resolve({module:c,instance:a})})}e.kConsoleLog&&kConsoleLog("get a wasm binary from db, timecost:"+((new Date).getTime()-m));var n=(new Date).getTime();return WebAssembly.instantiate(c,f).then(function(c){e.kConsoleLog&&
kConsoleLog("build instance from binary timecost: "+((new Date).getTime()-n));if(b){var f=(new Date).getTime();return d._storeInDatabase(a,"wasm",h,c.module).then(function(a){e.kConsoleLog&&kConsoleLog(a+", timecost: "+((new Date).getTime()-f));e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+((new Date).getTime()-g));return Promise.resolve(c)})}e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+((new Date).getTime()-g));return Promise.resolve(c)})},function(m){e.kConsoleLog&&
kConsoleLog(m.message||m);e.kConsoleLog&&kConsoleLog("downloading...");var k=(new Date).getTime();return b?WebAssembly.instantiateStreaming(fetch(c),f).then(function(b){d._onWasmDownloaded&&d._onWasmDownloaded();e.kConsoleLog&&kConsoleLog("download with build timecost: "+((new Date).getTime()-k));var c=(new Date).getTime();return d._storeInDatabase(a,"wasm",h,b.module).then(function(a){e.kConsoleLog&&kConsoleLog(a+", timecost: "+((new Date).getTime()-c));e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+
((new Date).getTime()-g));return Promise.resolve(b)},function(a){e.kConsoleLog&&kConsoleLog(a.message||a);return Promise.reject("Can't store wasm in db.")})}):fetch(c).then(function(a){return a.arrayBuffer()}).then(function(b){e.kConsoleLog&&kConsoleLog("download timecost: "+((new Date).getTime()-k));d._onWasmDownloaded&&d._onWasmDownloaded();var c=(new Date).getTime();return d._storeInDatabase(a,"wasm",h,b).then(function(a){e.kConsoleLog&&kConsoleLog(a+", timecost: "+((new Date).getTime()-c));return Promise.resolve(b)},
function(a){e.kConsoleLog&&kConsoleLog(a.message||a);return Promise.reject("Can't store wasm in db.")})}).then(function(a){var b=(new Date).getTime();return WebAssembly.instantiate(a,f).then(function(a){e.kConsoleLog&&kConsoleLog("build instance from binary timecost: "+((new Date).getTime()-b));e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+((new Date).getTime()-g));return Promise.resolve(a)})})})})},function(a){e.kConsoleLog&&kConsoleLog(a.message||a);return WebAssembly.instantiateStreaming?
WebAssembly.instantiateStreaming(fetch(c),f).then(function(a){d._onWasmDownloaded&&d._onWasmDownloaded();e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+((new Date).getTime()-g));return Promise.resolve(a)}):fetch(c).then(function(a){return a.arrayBuffer()}).then(function(a){d._onWasmDownloaded&&d._onWasmDownloaded();return WebAssembly.instantiate(a,f).then(function(a){e.kConsoleLog&&kConsoleLog("finish handle dbr wasm, total timecost: "+((new Date).getTime()-g));return Promise.resolve(a)})})})},
d)})).then(function(){d._loadWasmStatus="loaded";d._loadWasmTaskQueue.next();c()}).catch(function(a){d._loadWasmStatus=a;d._loadWasmTaskQueue.next();f(a)})},null,["loading"==d._loadWasmStatus])})};
dynamsoft.BarcodeReader.clearCache=dynamsoft.BarcodeReader.clearCache||function(){return new Promise(function(c,f){try{var a=window.indexedDB.deleteDatabase("dynamsoft-dbr-wasm");a.onsuccess=a.onerror=function(){a.error&&alert("Clear failed: "+(a.error.message||a.error));c()}}catch(e){alert(e.message||e),f()}})};
dynamsoft.BarcodeReader.Scanner=function(c){c=c||{};var f;(f=c.htmlElement)||(f=document.createElement("div"),f.style.position="fixed",f.style.width="100%",f.style.height="100%",f.style.left="0",f.style.top="0",f.style.background="#eee",f.innerHTML='<p style="width:100%;height:32px;line-height:32px;position:absolute;margin:auto 0;top:0;bottom:0;text-align:center;">loading</p><video class="dbrScanner-video" playsinline="true" style="width:100%;height:100%;position:absolute;left:0;top:0;"></video><select class="dbrScanner-sel-camera" style="position:absolute;left:0;top:0;"></select><select class="dbrScanner-sel-resolution" style="position:absolute;left:0;top:20px;"></select><button class="dbrScanner-btn-close" style="position:absolute;right:0;top:0;"><svg width="16" height="16" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"/></svg></button>');
this.htmlElement=f;this.videoSettings=c.videoSettings;this.confidence=c.confidence;void 0==this.confidence&&(this.confidence=30);this.intervalTime=c.intervalTime;void 0==this.intervalTime&&(this.intervalTime=100);this.runtimeSettings=c.runtimeSettings||{};this.searchRegion=c.searchRegion||{sx:void 0,sy:void 0,sWidth:void 0,sHeight:void 0,dWidth:void 0,dHeight:void 0};this.bAddOriVideoCanvasToResult=c.bAddOriVideoCanvasToResult;this.bAddSearchRegionCanvasToResult=c.bAddSearchRegionCanvasToResult;this.onFrameRead=
c.onFrameRead;this.duplicateForgetTime=c.duplicateForgetTime;void 0==this.duplicateForgetTime&&(this.duplicateForgetTime=3E3);this.onNewCodeRead=c.onNewCodeRead;this._isOpen=!1};dynamsoft.BarcodeReader.Scanner.prototype.isOpen=function(){return this._isOpen};
dynamsoft.BarcodeReader.Scanner.prototype.open=function(){var c=this;if(c._isOpen)return Promise.reject("The scanner is already open.");c._isOpen=!0;var f=c.htmlElement,a,e,d,g,b;(function(){for(var c=[],m=$jscomp.makeIterator(f.children),n=m.next();!n.done;n=m.next())c.push(n.value);for(m=0;m<c.length;++m){var h=$jscomp.makeIterator(c[m].children);for(n=h.next();!n.done;n=h.next())c.push(n.value)}c=$jscomp.makeIterator(c);for(n=c.next();!n.done;n=c.next())n=n.value,n.classList.contains("dbrScanner-video")?
a=n:n.classList.contains("dbrScanner-sel-camera")?e=n:n.classList.contains("dbrScanner-sel-resolution")?(d=n,d.options.length||(d.innerHTML='<option class="dbrScanner-opt-gotResolution" value="got"></option><option data-width="3840" data-height="2160">ask 3840 x 2160</option><option data-width="2560" data-height="1440">ask 2560 x 1440</option><option data-width="1920" data-height="1080">ask 1920 x 1080</option><option data-width="1600" data-height="1200">ask 1600 x 1200</option><option data-width="1280" data-height="720">ask 1280 x 720</option><option data-width="800" data-height="600">ask 800 x 600</option><option data-width="640" data-height="480">ask 640 x 480</option><option data-width="640" data-height="360">ask 640 x 360</option>',
g=d.options[0])):n.classList.contains("dbrScanner-opt-gotResolution")?g=n:n.classList.contains("dbrScanner-btn-close")&&(b=n)})();var h=c._updateDevice=function(){return navigator.mediaDevices.enumerateDevices().then(function(b){var c=[],d;if(e){var f=e.value;e.innerHTML=""}for(var g=0;g<b.length;++g){var h=b[g];if("videoinput"==h.kind){var k={};k.deviceId=h.deviceId;k.label=h.label||"camera "+g;c.push(k)}}b=$jscomp.makeIterator(a.srcObject.getTracks());for(g=b.next();!g.done;g=b.next()){g=g.value;
if(l)break;if("video"==g.kind)for(h=$jscomp.makeIterator(c),k=h.next();!k.done;k=h.next())if(k=k.value,g.label==k.label){var l=k;break}}if(e){b=$jscomp.makeIterator(c);for(k=b.next();!k.done;k=b.next())g=k.value,h=document.createElement("option"),h.value=g.deviceId,h.innerText=g.label,e.appendChild(h),f==g.deviceId&&(d=h);f=e.childNodes;if(!d&&l&&f.length)for(f=$jscomp.makeIterator(f),b=f.next();!b.done;b=f.next())if(b=b.value,l.label==b.innerText){d=b;break}d&&(e.value=d.value)}return Promise.resolve({current:l,
all:c})})};c._pausevideo=function(){a.pause()};var p=function(){a.srcObject&&(self.kConsoleLog&&self.kConsoleLog("======stop video========"),a.srcObject.getTracks().forEach(function(a){a.stop()}))},l=c._playvideo=function(b,e,f){return new Promise(function(m,h){p();self.kConsoleLog&&self.kConsoleLog("======before video========");var k=c.videoSettings=c.videoSettings||{video:{facingMode:{ideal:"environment"}}};/Safari/.test(navigator.userAgent)&&/iPhone/.test(navigator.userAgent)&&!/Chrome/.test(navigator.userAgent)?
1280<=e?k.video.width=1280:640<=e?k.video.width=640:320<=e&&(k.video.width=320):(e&&(k.video.width={ideal:e}),f&&(k.video.height={ideal:f}));b&&(k.video.facingMode=void 0,k.video.deviceId={exact:b});var n=!1,l=function(){self.kConsoleLog&&self.kConsoleLog("======try getUserMedia========");self.kConsoleLog&&self.kConsoleLog("ask "+JSON.stringify(k.video.width)+"x"+JSON.stringify(k.video.height));navigator.mediaDevices.getUserMedia(k).then(function(b){self.kConsoleLog&&self.kConsoleLog("======get video========");
return new Promise(function(c,e){a.srcObject=b;a.onloadedmetadata=function(){self.kConsoleLog&&self.kConsoleLog("======play video========");a.play().then(function(){self.kConsoleLog&&self.kConsoleLog("======played video========");var b="got "+a.videoWidth+"x"+a.videoHeight;d&&g&&(g.setAttribute("data-width",a.videoWidth),g.setAttribute("data-height",a.videoHeight),d.value="got",g.innerText=b);self.kConsoleLog&&self.kConsoleLog(b);c();m({width:a.videoWidth,height:a.videoHeight})},function(a){e(a)})};
a.onerror=function(){e()}})}).catch(function(a){self.kConsoleLog&&self.kConsoleLog(a);!n&&k.video?(n=!0,k.video.width=void 0,k.video.height=void 0,l()):h(a)})};l()})};c.arrDiffCodeInfo=[];var q,r=function(a,b){if(a instanceof Array){var c=[];a=$jscomp.makeIterator(a);for(var d=a.next();!d.done;d=a.next())c.push(r(d.value,b));return c}c=JSON.parse(JSON.stringify(a,function(a,b){if("oriVideoCanvas"!=a&&"searchRegionCanvas"!=a)return b}));b||(c.oriVideoCanvas=a.oriVideoCanvas,c.searchRegionCanvas=a.searchRegionCanvas);
return c},t=function(){if(c._isOpen){if(a.paused)return self.kConsoleLog&&self.kConsoleLog("Video is paused. Ask in 1s."),setTimeout(t,1E3);self.kConsoleLog&&self.kConsoleLog("======= once read =======");var b=(new Date).getTime();Promise.all([JSON.stringify(q.getRuntimeSettings())!=JSON.stringify(c.runtimeSettings)?q.updateRuntimeSettings(c.runtimeSettings):Promise.resolve(),function(){var b=c.searchRegion;if(b&&(b.sx||b.sy||b.sWidth||b.sHeight||b.dWidth||b.dHeight)){var d=b.sx||0,e=b.sy||0,f=b.sWidth||
a.videoWidth,g=b.sHeight||a.videoHeight,h=b.dWidth||b.sWidth||a.videoWidth;b=b.dHeight||b.sHeight||a.videoHeight;0<d&&1>d&&(d=Math.round(d*a.videoWidth));0<e&&1>e&&(e=Math.round(e*a.videoHeight));1>=f&&(f=Math.round(f*a.videoWidth));1>=g&&(g=Math.round(g*a.videoHeight));1>=h&&(h=Math.round(h*a.videoWidth));1>=g&&(b=Math.round(b*a.videoHeight));return[a,d,e,f,g,h,b]}return a}()]).then(function(a){a=a[1];a instanceof Array||(a=[a]);q._bAddOriVideoCanvasToResult=c.bAddOriVideoCanvasToResult;q._bAddSearchRegionCanvasToResult=
c.bAddSearchRegionCanvasToResult;return q.decodeVideo.apply(q,a)}).then(function(a){var d=(new Date).getTime();self.kConsoleLog&&self.kConsoleLog("time cost: "+(d-b)+"ms");for(var e=[],f=0;f<a.length;++f){var g=a[f],h=g.BarcodeText;self.kConsoleLog&&self.kConsoleLog(h);g.LocalizationResult.ExtendedResultArray[0].Confidence>=c.confidence&&e.push(g)}e=r(e);for(f=0;f<c.arrDiffCodeInfo.length;++f){g=c.arrDiffCodeInfo[f];h=-1;for(var k in e){var m=e[k];if(g.result.BarcodeText==m.BarcodeText&&g.result.BarcodeFormat==
m.BarcodeFormat){h=k;break}}-1!=h?(g.time=d,g.result=e[h],++g.count,e.splice(h,1)):d-g.time>c.duplicateForgetTime&&(c.arrDiffCodeInfo.splice(f,1),--f)}for(k=0;k<e.length;++k)for(f=e[k],g=k+1;g<e.length;)h=e[g],f.BarcodeText==h.BarcodeText&&f.BarcodeFormat==h.BarcodeFormat?e.splice(g,1):++g;f=$jscomp.makeIterator(e);for(k=f.next();!k.done;k=f.next())c.arrDiffCodeInfo.push({result:r(k.value,!0),time:d,count:1});if(c.onFrameRead)c.onFrameRead(r(a));a=$jscomp.makeIterator(e);for(k=a.next();!k.done;k=
a.next())if(d=k.value,c.onNewCodeRead)c.onNewCodeRead(d.BarcodeText,r(d));setTimeout(t,c.intervalTime)}).catch(function(a){self.kConsoleLog&&self.kConsoleLog(a);setTimeout(t,c.intervalTime);throw a;})}else q&&(q.deleteInstance(),q=void 0)},w=function(){l(e.value).then(function(){c._isOpen||p()}).catch(function(a){alert("Play video failed: "+(a.message||a))})};e&&e.addEventListener("change",w);var x=function(){if(d&&-1!=d.selectedIndex){var a=d.options[d.selectedIndex];var b=a.getAttribute("data-width");
a=a.getAttribute("data-height")}l(void 0,b,a).then(function(){c._isOpen||p()}).catch(function(a){alert("Play video failed: "+(a.message||a))})};d&&d.addEventListener("change",x);var v=!!f.parentNode,u=c._closeWindow=function(){p();c._isOpen=!1;e&&e.removeEventListener("change",w);d&&d.removeEventListener("change",x);c._closeWindow=void 0;b&&b.removeEventListener("click",u);var a=f.parentNode;a&&!v&&a.removeChild(f)};b&&b.addEventListener("click",u);v||document.body.appendChild(f);return Promise.all([dynamsoft.BarcodeReader.loadWasm(),
l()]).then(function(a){if(!c._isOpen)return p(),Promise.resolve();q=new dynamsoft.BarcodeReader;var b=q.getRuntimeSettings();b.mAntiDamageLevel=3;b.mDeblurLevel=0;for(var d in c.runtimeSettings)void 0!=b[d]&&(b[d]=c.runtimeSettings[d]);c.runtimeSettings=b;return Promise.all([a[1],q.updateRuntimeSettings(b)])}).then(function(a){t();return Promise.all([a[0],h()])}).then(function(a){var b=a[0];a=a[1];return Promise.resolve({width:b.width,height:b.height,current:a.current,all:a.all})})};
dynamsoft.BarcodeReader.Scanner.prototype.play=function(c,f,a){return this._isOpen?this._playvideo(c,f,a):Promise.reject("The scanner is not open.")};dynamsoft.BarcodeReader.Scanner.prototype.pause=function(){this._isOpen&&this._pausevideo()};dynamsoft.BarcodeReader.Scanner.prototype.close=function(){this._isOpen&&this._closeWindow()};
dynamsoft.BarcodeReader.Scanner.prototype.updateDevice=function(){return this._isOpen?this._updateDevice():navigator.mediaDevices.enumerateDevices().then(function(c){for(var f=[],a=0;a<c.length;++a){var e=c[a];if("videoinput"==e.kind){var d={};d.deviceId=e.deviceId;d.label=e.label||"camera "+a;f.push(d)}}return Promise.resolve({current:void 0,all:f})})};
export default dynamsoft.BarcodeReader;