import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { Dialog } from "interfaces/dialog";
import { DialogController } from "aurelia-dialog";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationCustomerProjetContractModel } from "api/models/company/service-call/service-call-quotation-customer-projet-contract-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";

export interface QuotationClientContractProjectDialogParameters {
    quotationId: number;
}

@autoinject()
export class QuotationClientContractProjectDialog implements Dialog {

    public dialogHeaderTitle: string = "";
    public customerProjectContract!: ServiceCallQuotationCustomerProjetContractModel;
    public quotationId!: number;
    public isProjectReadonly: boolean = false;
    public hasCustomerId: boolean = false;
    public hideProjectId: boolean = false;
    public displaySysGenerated: string = this.i18n.tr("GeneratedBySystem");

    constructor(private readonly i18n: I18N, private readonly serviceCallQuotationService: ServiceCallQuotationService,
                private readonly dialogController: DialogController) {
    }

    public activate(params: QuotationClientContractProjectDialog): void {
        this.quotationId = params.quotationId;
    }

    public async bind(): Promise<any> {
        this.customerProjectContract = await this.serviceCallQuotationService.getServiceQuotationCustomerProjetContractInfos(this.quotationId);

        this.hideProjectId = !this.customerProjectContract.ProjectId && !this.customerProjectContract.MustCreateProject && !this.customerProjectContract.IsProjectIdSystemGenerated;
        this.isProjectReadonly = !!this.customerProjectContract.ProjectId;

        this.hasCustomerId = !!this.customerProjectContract.CustomerId;
    }

    public async ok(): Promise<void> {
        if ((!this.hideProjectId && !this.customerProjectContract.IsProjectIdSystemGenerated && !this.customerProjectContract.ProjectId) || !this.customerProjectContract.CustomerId) {
            return;
        }

        if (this.customerProjectContract.CustomerId) {
            this.customerProjectContract.CustomerId = this.customerProjectContract.CustomerId.toUpperCase();
        }
        if (this.customerProjectContract.ProjectId) {
            this.customerProjectContract.ProjectId = this.customerProjectContract.ProjectId.toUpperCase();
        }

        this.serviceCallQuotationService.createServiceQuotationCustomerProjetContract(this.customerProjectContract).then((result: ServiceCallQuotationDetailsModel) => {
            this.dialogController.ok(result);
        });
    }

    public cancel(): void {
        this.dialogController.cancel();
    }
}
