import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";

@autoinject
export class EquipmentCamera {
  @bindable public entityId: string = "";
  @bindable public presence: string = "";
  @bindable public equipmentCode: string = "";
  @bindable public equipmentId: string = "";

  private i18n: I18N;
  
  constructor(i18n: I18N) {
    this.i18n = i18n;
  }

  private showDocument(code: string, id: string): void {
    const params = {
      equipmentId: this.equipmentId,
       entityId: this.entityId,
        presence: this.presence
      };

    const baseURI = routerHelper.getRelativeUrl(encodeURIComponent(this.equipmentCode), "document");

    routerHelper.navigate(baseURI + routerHelper.addQuerystring(params));
  }
}
