import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

@autoinject()
export class QuotationTotal {
    @bindable
    public quotation: ServiceCallQuotationDetailsModel | null = null;

    @bindable.boolean
    public showSellingPrices: boolean = true;

    @bindable.boolean
    public showCostPrices: boolean = true;

    @bindable.string
    public specifiedLanguage: string | undefined = undefined;
}
