define([
    "services/requests",
    "helpers/apiHelper"
], function(requests, apiHelper) {
    "use strict";

    var exports = {
        // TODO: KG - ShowLoading: false - Transport
        getByUrl: function (url, filter, page) {

            if (filter) {
                url += (url.indexOf('?') > -1 ? "&" : "?") + "filter=" + filter;
            }
            if (page) {
                url += (url.indexOf('?') > -1 ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url, null);
        },

        // TODO: KG - ShowLoading: false - Transport
        getAllNoBilling: function(filter, page) {
            var url = apiHelper.getBaseUrl() + "/catalog/all/nobilling";
            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url, null);
        }
    };

    return exports;
});
