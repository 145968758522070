import { ContactModel } from "api/models/company/contact/contact-model";
import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import phoneHelper from "helpers/phoneHelper";
import { ContactService } from "services/contact-service";

@autoinject
export class ContactDetails {
    @bindable public id!: number;
    public hlpPhone: string = "";
    public contact: ContactModel | null = null;

    public phoneHelper: typeof phoneHelper = phoneHelper;

    constructor(private readonly contactService: ContactService) {
    }

    public bind(): any {
        this.loadData();
    }

    public loadData(): void {
        this.contactService.getContact(this.id).then((result: ContactModel | null) => {
            this.contact = result;
        });
    }

}
