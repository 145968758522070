import { AxiosRequestConfig } from "axios";
import { HttpClient } from "http-client/http-client";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { inject } from "aurelia-framework";
import { Nonce } from "http-client/nonce";
import { ClientApplication } from "api/enums/client-application";
import { Container } from "aurelia-dependency-injection";

@inject(Nonce)
export class MaestroApiHttpClient extends HttpClient {
    constructor(nonce: Nonce, defaultRequestConfig: HttpClientRequestConfig) {
        super(nonce, defaultRequestConfig);
    }

    protected createAxiosRequestConfig(source: HttpClientRequestConfig): AxiosRequestConfig {
        const result = super.createAxiosRequestConfig(source);

        if (source.maestroVersion) {
            result.headers.MaestroVersion = source.maestroVersion;
        }

        if (source.language) {
            result.params.lang = source.language;
        }

        const viewModeHelper = Container.instance.get(ViewModeHelper);
        result.headers.ClientApplication = viewModeHelper.getIsMobileMode() ? ClientApplication.Mobile : ClientApplication.Maestro;

        return result;
    }
}
