import { autoinject } from "aurelia-framework";
import moment from "moment";
import { I18N } from "aurelia-i18n";
import { EventAggregator } from "aurelia-event-aggregator";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class AddUnavailability extends FormBase {

    public dispatchProjectCode: string = "";
    public dispatchDate!: Date;
    public employeeId: number = 0;
    public comments: string = "";

    public startDate: Date = new Date();
    public endDate: Date = new Date();
    public increment: number = 5;
    public unAvailabilityId: number = 0;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly eventAggregator: EventAggregator) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<any> {

        this.startDate = moment(params.startTime).toDate();
        this.endDate =  moment(params.endTime).toDate();
        this.employeeId = params.employeeId;
        this.comments = params.comments ? params.comments : "";

        if (params.unAvailabilityId) {
            this.unAvailabilityId = params.unAvailabilityId;
            this.eventAggregator.publish("updateTitle", this.i18n.tr("pageTitle_Calendar_Employee_EditNonAvailability"));
        }
    }
}
