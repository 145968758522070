import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
import { bindable, autoinject} from "aurelia-framework";
import dateHelper from "helpers/dateHelper";

@autoinject()
export class InventoryProjectTransferHeader {
    public title: string = "";
    public subtitle: string = "";

    @bindable public transferId!: number;

    constructor(private readonly inventoryProjectTransferService: InventoryProjectTransferService) {
    }

    public async bind(): Promise<void> {
        const transfer = await this.inventoryProjectTransferService.GetTransferHeader(this.transferId!);
        this.setDisplayValues(transfer!.AccountingDate, transfer!.Id);
    }

    private setDisplayValues(accountingDate: Date, id: number): void {
        this.title = accountingDate ? dateHelper.getFullTextDate(accountingDate) : "";
        this.subtitle = id.toString();
    }
}
