import { autoinject } from "aurelia-framework";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";

@autoinject
export class DocumentEdit {

    public dispatchId: string | null = null;
    public actions: any;
    public isCategorySelectionAllowed: boolean = false;
    public getCategoryProxy?: () => Promise<DocumentCategoryModel[] | null>;

    public imageId: any;
    public files: any;
    public fileIndex: any;
    public routeName: any;

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.dispatchId;
        this.imageId = params.imageId;
        this.files =  params.files;
        this.fileIndex =  params.fileIndex;
    }

}
