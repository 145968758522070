import { PagingInfo } from "api/paging-info";
import { OverrideContext, TaskQueue, computedFrom, inject } from "aurelia-framework";
import { customElement, processContent } from "aurelia-templating";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import config from "config";
import { CancellationTokenFactory } from "core/cancellation-token";
import { ElementClickOutsideDetector } from "core/element-click-outside-detector";
import { processContentAsReplacablePart } from "helpers/au-process-content-helper";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { SelectItem } from "models/select-item";
import Popper, { Placement } from "popper.js";
import { Key } from "ts-keycode-enum";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { DropdownBase } from "./dropdown-base";

@inject(Element, CancellationTokenFactory, TaskQueue, ViewModeHelper)
@processContent(processContentAsReplacablePart("item-template"))
@customElement("dropdown")
export class Dropdown<T> extends DropdownBase<T> {

    @bindable.number({ defaultValue: config.defaultRequestPageSize })
    public pageSize!: number;

    // DONE
    // Placeholder (null values)
    // Add search input
    // Display popper
    // Click outside close
    // Focus search on open
    // Load first x items
    // Infinite scrolling, noMoreData
    // EmptyItemTemplate (No item found)
    // Promise cancellation
    // Filters/Searching
    // Esc close
    // Select with keyboard keys
    // Handle Items list
    // test long list & infinite scrolling
    // Find a way to clear the selected item.
    // -----------------------------------

    // TODO View Light
    // TODO Pop an ag-grid for advanced filtering
    // TODO Hide Add button if no route was passed

    public items: Array<SelectItem<T>> = [];

    public isLoading: number = 0;

    @observable.string({ defaultValue: "" }) //
    public filterValue!: string;
    public isOpen: boolean = false;

    public isTruncated: boolean = false;

    public filterTextbox!: HTMLElement;
    public filterInput!: HTMLInputElement;

    public itemListElement!: HTMLElement;
    public dropdownToggleElement!: HTMLElement;
    public dropdownElement!: HTMLElement;

    @bindable
    public showClear: boolean = true;

    @bindable
    public showSearchInput: boolean = true;

    //Treshold in px where the popper dropdown will flip open
    //to top instead of opening at the bottom
    private dropdownElementHeightFlipThreshold: number = 100;

    private nextItemsPage: number = -1;
    private hasMoreItems: boolean = true;

    private popper: Popper | null = null;
    private readonly clickOutsideDetector: ElementClickOutsideDetector;
    private readonly onKeyDownEventHandlerPointer: EventListener;

    @computedFrom("selectedValue", "selectedData")
    public get hasValue(): boolean {
        return (!!this.selectedValue && this.selectedValue !== "0") || this.selectedData;
    }

    private get dropdownElementTopPosition(): number {
        const boundingClientRect = this.element.getBoundingClientRect();

        return boundingClientRect.top + boundingClientRect.height;
    }
    private get dropdownElementLeftPosition(): number {
        const boundingClientRect = this.element.getBoundingClientRect();
        const dropdownComputedStyle = window.getComputedStyle(this.dropdownElement);
        return boundingClientRect.left + parseFloat(dropdownComputedStyle.paddingLeft || "0");
    }

    private get adjustedDropdownElementWidth(): number {
        return (window.innerWidth - this.dropdownElementLeftPosition);
    }

    private get adjustedDropdownElementHeight(): number {
        return (window.innerHeight - this.dropdownElementTopPosition);
    }

    private get dropdownElementWidth(): string {
        const dropdownComputedStyle = window.getComputedStyle(this.dropdownElement);
        const dropdownElementWidth = this.dropdownElement.clientWidth;

        return dropdownElementWidth - (parseFloat(dropdownComputedStyle.paddingLeft || "0") + parseFloat(dropdownComputedStyle.paddingRight || "0")) + "px";
    }
    private set dropdownElementWidth(value: string) {
        this.dropdownElement.style.maxWidth = value;
    }

    private get dropdownElementHeight(): string {
        const dropdownComputedStyle = window.getComputedStyle(this.dropdownElement);
        const dropdownElementHeight = this.dropdownElement.clientHeight;

        return dropdownElementHeight - parseFloat(dropdownComputedStyle.paddingTop || "0") + parseFloat(dropdownComputedStyle.paddingBottom || "0") + "px";
    }

    private set dropdownElementHeight(value: string) {
        this.dropdownElement.style.height = value;
    }

    constructor(element: Element, cancellationTokenFactory: CancellationTokenFactory, private readonly taskQueue: TaskQueue,
                private readonly viewModeHelper: ViewModeHelper) {
        super(element, cancellationTokenFactory);

        this.clickOutsideDetector = new ElementClickOutsideDetector(
            (): HTMLElement[] => [this.dropdownToggleElement, this.dropdownElement],
            (): void => {
                this.closeDropdown();
            }
        );

        this.onKeyDownEventHandlerPointer = (e: KeyboardEvent): void => {
            this.onKeyDownEventHandler(e);
        };
    }

    // #region Observable
    public async filterValueChanged(): Promise<void> {
        this.clearItems();
        await this.loadMoreItems();
    }
    // #endregion

    // #region Lifecycle
    public bind(bindingContext: any, overrideContext: OverrideContext): void {
        super.bind(bindingContext, overrideContext);
    }

    public attached(): void {
        // TODO JL: Expose a "hasFocus" bindable on the textbox to prevent having to call focus & blur?
        this.filterInput = this.filterTextbox.getElementsByTagName("input").item(0) as HTMLInputElement;

        window.addEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
    }

    public detached(): void {
        this.closeDropdown();

        window.removeEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
    }
    // #endregion

    // #region Commands
    public itemSelected(item: SelectItem<T>): void {
        this.selectedItem = item;
        this.closeDropdown();
    }

    public async toggleDropdown(): Promise<void> {
        if (this.isOpen) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    }

    public async handleScroll(): Promise<void> {
        await this.manageInfiniteScroll();
    }

    public async reloadItems(): Promise<void> {
        this.clearItems();
        this.loadMoreItems();
    }

    // #endregion

    // #region Privates
    private async openDropdown(): Promise<void> {

        if (this.disabled) {
            return;
        }

        this.isOpen = true;
        this.clickOutsideDetector.enable();

        (document.activeElement as HTMLElement).blur();
        // Fix a bug where we can scroll the page behind the modals
        // TODO JL: Marche pas sous iOS. Voir ticket 262564 pour réparer.
        document.body.style.overflow = "hidden";

        if (this.viewModeHelper.getIsDesktopMode()) {
            this.taskQueue.queueMicroTask(
                (): void => {
                    this.filterInput.focus();
                }
            );
        }

        this.clearItems();
        this.loadMoreItems();

        CustomEventHelper.dispatchEvent(this.element, "opened", {});
    }

    private closeDropdown(): void {
        this.cancelCancellationToken();

        this.isOpen = false;
        this.filterInput.blur();

        (document.activeElement as HTMLElement).blur();
        // Fix a bug where we can scroll the page behind the modals
        // TODO JL: Marche pas sous iOS. Voir ticket 262564 pour réparer.
        document.body.style.overflow = "";

        this.clickOutsideDetector.disable();

        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
    }

    private clearItems(): void {
        this.cancelCancellationToken();

        this.items = [];
        this.nextItemsPage = 1;
        this.hasMoreItems = true;
    }

    private async loadMoreItems(): Promise<void> {
        this.isLoading++;

        try {
            const paging = { page: this.nextItemsPage, pageSize: this.pageSize } as PagingInfo;
            const results = await this.getResults(this.filterValue, paging);

            this.items = this.items.concat(results);

            this.hasMoreItems = results.length >= this.pageSize;
            this.nextItemsPage++;

            this.taskQueue.queueMicroTask(() => {
                this.manageInfiniteScroll();

                this.createDropdownElement(results.length > 0);
            });

        } catch (error) {
            if (!error.isHttpRequestCancellation) {
                throw error;
            }
        } finally {
            this.isLoading--;
        }
    }

    private async manageInfiniteScroll(): Promise<void> {
        if (this.isOpen !== true) {
            return;
        }

        if (this.itemListElement.scrollTop + this.itemListElement.clientHeight >= this.itemListElement.scrollHeight) {
             if (this.hasMoreItems && this.isLoading <= 0) {
                 await this.loadMoreItems();
            }
        }
    }

    private onKeyDownEventHandler(e: KeyboardEvent): void {

        const escapeHandler = (): void => {
            if (!this.isOpen) {
                return;
            }

            this.closeDropdown();
            (this.dropdownToggleElement as HTMLElement).focus();
        };

        const upArrowHandler = (): void => {
            if (!this.isOpen) {
                return;
            }

            e.preventDefault();

            const activeElement = window.document.activeElement;

            if (!(activeElement instanceof HTMLLIElement)) {
                const lastElementChild = this.itemListElement.lastElementChild as HTMLElement;

                setListItemFocus(lastElementChild);
                return;
            }

            const previousElement = window.document.activeElement.previousElementSibling as HTMLElement;
            setListItemFocus(previousElement);

        };

        const downArrowHandler = (): void => {
            const activeElement = window.document.activeElement;

            if (!this.isOpen) {
                if (activeElement === this.dropdownToggleElement) {
                    this.openDropdown();
                }
                return;
            }

            e.preventDefault();

            if (!(activeElement instanceof HTMLLIElement)) {
                const firstElementChild = this.itemListElement.firstElementChild as HTMLElement;

                setListItemFocus(firstElementChild);
                return;
            }
            // The focus is currently on a select item.
            const nextElement = window.document.activeElement.nextElementSibling as HTMLElement;

            setListItemFocus(nextElement);
        };

        const tabHandler = (): void => {
            if (!this.isOpen) {
                 return;
            }

            //focus on element inside the dropdown.
            //<TAB> will exit the dropdown and focus on next element
            if (e.target) {
                const target = e.target as HTMLElement;
                if (target === this.filterInput || target.classList.contains("dropdown-item")) {
                    this.closeDropdown();
                    this.dropdownToggleElement.focus();

                    return;
                }
            }

            e.preventDefault();
            downArrowHandler();
        };

        const enterHandler = (): void => {
            if (!this.isOpen) {
                return;
            }

            if (e.target) {
                const target = e.target as HTMLElement;
                target.click();
                this.dropdownToggleElement.focus();
                return;
            }
        };

        const setListItemFocus = (element: HTMLElement): void => {
            if (element instanceof HTMLLIElement) {
                element.focus();
            } else {
                this.filterInput.focus();
            }
        };

        switch (e.keyCode) {
            case Key.Enter:
                return enterHandler();
            case Key.Escape:
                return escapeHandler();
            case Key.UpArrow:
                return upArrowHandler();
            case Key.DownArrow:
                return downArrowHandler();
            case Key.Tab:
                return tabHandler();
            default:
                return;
        }
    }
    //#region "Dropdown resizing/repositionning methods"

    private createDropdownElement(hasResults: boolean): void {

        if (this.viewModeHelper.getIsDesktopMode() && hasResults) {

            this.dropdownElementHeight = "auto";

            const dropdownElementPlacement = this.getDropdownElementPlacement();

            if (this.dropdownElementTopPosition + parseFloat(this.dropdownElementHeight) > window.innerHeight) {
                this.resizeDropdownElement();
            }

            this.createPopperDropdownElement(dropdownElementPlacement);
        }
    }

    private getDropdownElementPlacement(): Placement {

        if (this.adjustedDropdownElementHeight < this.dropdownElementHeightFlipThreshold) {
            return "top-start";
        } else {
            return "bottom-start";
        }
    }

    private resizeDropdownElement(): void {

        this.resizeDropdownElementWidth();
        this.resizeDropdownElementHeight();
    }

    private createPopperDropdownElement(placement: Placement): void {
        if (this.viewModeHelper.getIsDesktopMode()) {
            this.popper = new Popper(this.dropdownToggleElement, this.dropdownElement, {
                placement: placement,
                positionFixed : true,
                modifiers: {
                    flip: {
                        enabled : false
                    }
                }
            });
        }
    }

    private resizeDropdownElementWidth(): void {
        this.dropdownElementWidth = this.adjustedDropdownElementWidth + "px";
        this.isTruncated = true;
    }

    private async resizeDropdownElementHeight(): Promise<void> {
        this.dropdownElement.style.height = this.adjustedDropdownElementHeight + "px";
    }
    //#endregion "Dropdown resizing/repositionning methods"

    // #endregion
}
