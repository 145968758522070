import { ServiceCallOccupationCategoryModel } from "api/models/company/service-call/service-call-occupation-category-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallOccupationCategoryProxy } from "api/proxies/service-call-occupation-category-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class ServiceCallOccupationCategoryService {
    constructor(private readonly serviceCallOccupationCategoryProxy: ServiceCallOccupationCategoryProxy) {
    }

    public async getOccupationCategories(dispatchTemplateId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<ServiceCallOccupationCategoryModel[]> {
        return await this.serviceCallOccupationCategoryProxy.GetServiceCallOccupationCategories(dispatchTemplateId, filter, pagingInfo, requestConfig) || [];
    }
}
