import { GridValueFormatter } from "helpers/gridValueFormatter";
import { TakeoffVariableHelper } from "helpers/takeoffVariableHelper";
import { AgGridHelper } from "helpers/ag-grid-helper";
import EstimateVariableService from "services/estimateVariableService";
import EstimateService from "services/estimateService";
import EstimateTakeoffVariableImportService from "services/estimateTakeoffVariableImportService";

import { default as resx } from "core/resx";
import { default as settingRepository } from "repositories/settingRepository"
import { default as routerHelper } from "helpers/routerHelper";
import { Routes } from "enums/routes";
import { TakeoffSoftwares } from "enums/takeOffSoftwares";
import { TakeoffFormats } from "enums/takeOffFormats";
import { TakeoffVariableActionStatus } from "enums/takeoffVariableActionStatus";
import { NotificationHelper } from "helpers/notification-helper";

import TextBoxCellEditor from "ag-grid/editors/textBoxCellEditor";

import { inject, bindable, observable } from "aurelia-framework";
import { default as notifier } from "helpers/notificationHelper";


@inject(AgGridHelper, TakeoffVariableHelper, EstimateVariableService, EstimateService, EstimateTakeoffVariableImportService, GridValueFormatter, NotificationHelper)
export class TakeoffQuantityImport {
    estimateId = null;
    companyName = null;
    softwares = [];
    @observable selectedSoftware = null;
    formats = [];
    @observable selectedFormat = null;
    formatsEnabled = false;
    hasSelectedFile = false;
    hasImportedFile = false;
    importFileEnabled = false;
    configurationEnabled = false;
    variables = [];
    @observable hideUnchangedVariables = true;
    hideUnchangedVariablesCheckBoxVisible = false;
    @observable importFileArray;
    statusMessage = '';

    get importFile() {
        return this.importFileArray && this.importFileArray.length > 0 ? this.importFileArray[0].name : null;
    }

    get isDirty() { return this._isDirty; }
    set isDirty(value) { this._isDirty = value; }

    constructor(agGridHelper, takeoffVariableHelper, estimateVariableService, estimateService, estimateTakeoffVariableImportService, gridValueFormatter, notificationHelper) {
        this.routerHelper = routerHelper;
        this.takeoffVariableHelper = takeoffVariableHelper;
        this.resx = resx;
        this.agGridHelper = agGridHelper;
        this.estimateVariableService = estimateVariableService;
        this.estimateService = estimateService;
        this.estimateTakeoffVariableImportService = estimateTakeoffVariableImportService;
        this.gridValueFormatter = gridValueFormatter;
        this.notificationHelper = notificationHelper;
        this.isDirty = false;
       
    }

    hideUnchangedVariablesChanged(newValue, oldValue) {
        this.setVariables();
    }

    selectedSoftwareChanged(newValue, oldValue) {
        this.formats = this.getFormats();

        if (!this.selectedSoftware) {
            this.formatsEnabled = false;
            this.importFileEnabled = false;
            this.selectedFormat = null;
        } else if (this.selectedSoftware && this.selectedSoftware === TakeoffSoftwares.ExtrAXION.id) {
            this.formatsEnabled = false;
            this.importFileEnabled = false;
            this.selectedFormat = TakeoffFormats.Integrated.id;
        } else {
            this.formatsEnabled = true;
            this.importFileEnabled = true;
            this.selectedFormat = null;
        }
    }

    selectedFormatChanged(newValue, oldValue) {
        if (newValue === TakeoffFormats.Other.id) {
            this.configurationEnabled = true;
        } else {
            this.configurationEnabled = false;
        }
    }

    importFileArrayChanged(newValue, oldValue) {
        // TODO: Handle the file dialog in C# from the host window.
        // We need to specify a default directory and we cannot control access to local drive from the browser.
        // We have to bind the file input to a native C# file dialog, and probably handle the upload from there too.
        this.hasSelectedFile = !!newValue && !!newValue.length > 0;

        if (this.hasSelectedFile) {
            this.importSelectedExcelFile();
        }
    }

    activate(params, routeConfig) {
        this.estimateId = params.estimateId;
        this.companyName = settingRepository.getCompanyName();

        this.selectedSoftware = TakeoffSoftwares.Excel.id;
        this.selectedFormat = TakeoffFormats.Maestro.id;

        const columnDefs = [
            { headerName: this.resx.localize("Counter"), field: "ImportedVariableId", width: 120, cellStyle: this.agGridHelper.cellStyleRightAlign, enableRowGroup: true },
            { headerName: this.resx.localize("Processing"), field: "Action", width: 140, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true, valueFormatter: (p) => this.gridValueFormatter.formatVariableActionStatus(p) },
            { headerName: this.resx.localize("VariableName"), field: "VariableName", width: 180, editable: true, cellStyle: this.agGridHelper.cellStyleLeftAlign, cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 100 }, enableRowGroup: true },
            {
                headerName: this.resx.localize("ValueBefore"),
                children: [
                    { headerName: this.resx.localize("Quantity"), field: "QuantityBefore", width: 120, cellStyle: this.agGridHelper.cellStyleRightAlign, enableRowGroup: true, filter: "number" },
                    { headerName: this.resx.localize("Unit"), field: "UnitBefore", width: 120, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true }
                ]
            },
            {
                headerName: this.resx.localize("NewValue"),
                children: [
                    { headerName: this.resx.localize("Quantity"), field: "Quantity", width: 120, cellStyle: this.agGridHelper.cellStyleRightAlign, enableRowGroup: true, filter: "number" },
                    { headerName: this.resx.localize("Unit"), field: "Unit", width: 120, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true }
                ]
            },
            { headerName: this.resx.localize("Drawing"), field: "Drawing", width: 120, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true },
            { headerName: this.resx.localize("Sheet"), field: "Sheet", width: 180, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true },
            { headerName: this.resx.localize("Description"), field: "Description", width: 180, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true },
            { headerName: this.resx.localize("Category"), field: "Category", width: 120, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true },
            { headerName: this.resx.localize("Notes"), field: "Notes", width: 120, cellStyle: this.agGridHelper.cellStyleLeftAlign, enableRowGroup: true }
        ];

        this.gridOptions = this.agGridHelper.createDefaultGridOptions(columnDefs);
        this.gridOptions.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.gridOptions.getMainMenuItems = this.getMainMenuItems.bind(this);
        this.gridOptions.getRowStyle = this.getRowStyle;
        this.gridOptions.onSelectionChanged = () => {
            var selectedRows = this.gridOptions.api.getSelectedRows();
            if (selectedRows.length === 1) {
                this.statusMessage = selectedRows[0].validationError;
            } else {
                this.statusMessage = '';
            }

            this.gridOptions.onCellValueChanged = () => {
                this.isDirty = true;
            };
        };

        return this.loadEstimateConfig().then(() => {
            this.softwares = this.getSoftwares();
            this.formats = this.getFormats();
            this.selectedSoftware = this.takeoffConfig.ImportSoftware;
            this.selectedFormat = this.takeoffConfig.ImportFormat;

            this.clear();
        });
    }

    async attached() {
        this.grid = await this.agGridHelper.createGrid(this.gridElement, this.gridOptions);

        this.gridOptions.api.setSortModel([{ colId: "ImportedVariableId", sort: "asc" }]);

        if (this.variables) {
            this.gridOptions.api.setRowData(this.variables);
        }
    }

    loadEstimateConfig() {
        return this.estimateService.getTakeoffConfig(this.estimateId)
            .then((data) => {
                this.takeoffConfig = data;

                // TODO: Handle the file dialog in C# from the host window.
                // For now we cannot set value to a input type file since we cannot access the local drive from the browser.
                //this.importFile = this.takeoffConfig.TakeoffImportDefaultPath;
            });
    }

    saveEstimateConfig() {
        if (!this.selectedSoftware || !this.selectedFormat) {
            return jQuery.Deferred().resolve();
        }
        var configModel = {
            ImportSoftware: this.selectedSoftware,
            ImportFormat: this.selectedFormat,
            ImportFilePath: this.takeoffConfig.ImportFilePath
        };

        return this.estimateService.saveTakeoffConfig(this.estimateId, configModel);
    }

    clear() {
        this.variables = [];
        this.setVariables();
    }

    setVariables() {
        if (!this.grid || !this.gridOptions) {
            return;
        }

        if (this.hideUnchangedVariables) {
            this.gridOptions.api.setRowData(this.variables.filter(x => x.Action !== TakeoffVariableActionStatus.Unchanged.id));
            if (this.variables.filter(x => x.Action !== TakeoffVariableActionStatus.Unchanged.id).length > 0) {
                this.isDirty = true;
            }
        } else {
            this.gridOptions.api.setRowData(this.variables);
            if (this.variables.length > 0) {
                this.isDirty = true;
            }
        }
    }

    exit() {
        if (this.isDirty) {
            notifier.showDialogYesNo(resx.localize('ExitWithoutSavingImport'), "", false, { thirdButton: true, otherLabel: resx.localize("Cancel") })
                .done((success) => {
                    if (success === true) {
                        this.save().then(() => {
                            routerHelper.navigateBack();
                        });
                    } else if (success === false) {
                        routerHelper.navigateBack();
                    }
                });
        } else {
            this.routerHelper.navigateBack();
        }
    }

    showConfigurationWindow() {
        return this.saveEstimateConfig().then(() => {
            this.routerHelper.navigateToRoute("takeoffQuantityImportConfig", { estimateId: this.estimateId });
        });
    }

    getContextMenuItems(params) {
        const result = [
            "copy",
            "destroyColumnComps"
        ];

        return result;
    }

    getMainMenuItems(params) {
        const result = [];
        result.push("pinSubMenu");
        result.push("separator");
        result.push("autoSizeThis");
        result.push("autoSizeAll");
        result.push("separator");
        if (params.column.colDef.showRowGroup !== true) {
            result.push(params.column.rowGroupActive ? "rowUnGroup" : "rowGroup");
            result.push("separator");
        }

        result.push("destroyColumnComps");

        const group = this.gridOptions.columnApi.getAllColumns().find(x => x.rowGroupActive === true);
        if (group) {
            result.push("expandAll");
            result.push("contractAll");
        }

        return result;
    }

    importSelectedExcelFile() {
        if (!this.importFileArray || this.importFileArray.length === 0) {
            return;
        }

        const file = this.importFileArray[0];

        if (file) {
            this.saveEstimateConfig();
            this.importExcelFile(file);
        }
    }

    importExcelFile(file) {
        return this.estimateTakeoffVariableImportService.importFile(file, this.estimateId, this.selectedFormat, file.name)
            .then((data) => {
                this.isDirty = false;
                this.variables = data;
                this.setVariables();
                this.hideUnchangedVariablesCheckBoxVisible = true;
                this.hasImportedFile = true;
                if (data.length) {
                    this.notificationHelper.showSuccess(this.resx.localize(this.isDirty ? "FileImportedWithSuccess" : "FileImportedNoChanges"));
                }
                else {
                    this.notificationHelper.showWarning(this.resx.localize("FileImportedNoVariables"));
                }
            });
    }

    getSoftwares() {
        return [
            //TakeoffSoftwares.ExtrAXION,    // Non-disponible dans cette phase
            TakeoffSoftwares.Excel
        ];
    }

    getFormats() {
        if (this.selectedSoftware === TakeoffSoftwares.ExtrAXION.id) {
            return [TakeoffFormats.Integrated];
        } else {
            return [TakeoffFormats.ExtrAXION, TakeoffFormats.Maestro, TakeoffFormats.Other];
        }
    }

    getRowStyle(params) {
        var result = {};

        if (!params.data)
            return result;

        const status = Object.values(TakeoffVariableActionStatus).find(x => x.id === params.data.Action);

        if (status && status.color) {
            result['background-color'] = status.color;
        }

        if (params.data.validationError) {
            result['color'] = "red";
            result['font-weight'] = 'bold';
        }

        return result;
    }

    save() {
        return this.saveEstimateConfig().then(() => {
            const validationResult = this.validateVariables();

            if (validationResult === true) {
                return this.estimateVariableService.updateVariables(this.estimateId, this.variables)
                    .then(() => {
                        this.isDirty = false;
                        this.exit();
                    });
            } else {
                return jQuery.Deferred().resolve();
            }
        });
    }

    validateVariables() {
        const validationResults = this.takeoffVariableHelper.validateVariables(this.variables);
        this.gridOptions.api.redrawRows();
        return validationResults;
    }
}