import { WorkTaskProxy } from "../api/proxies/work-task-proxy";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";

import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { WorkTaskModel } from "api/models/company/work-task-model";

@autoinject()
export class WorkTaskService {
    constructor(private readonly workTaskProxy: WorkTaskProxy) {}

    public async GetTasks(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<WorkTaskModel[] | null> {
        return await this.workTaskProxy.GetTasks(filter, pagingInfo, requestConfig);
    }
}
