import urlHelper from 'helpers/urlHelper';

export class UrlHelper {
    getWebServiceBaseUrl() {
        return urlHelper.getWebServiceBaseUrl();
    }

    getLegacyServiceBaseUrl() {
        return urlHelper.getLegacyServiceBaseUrl();
    }

    getBaseUrl() {
        return urlHelper.getBaseUrl();
    }

    getBaseUrlWithDash() {
        return urlHelper.getBaseUrl() + "/";
    }

    getParameterByName(name, url) {
        return urlHelper.getParameterByName(name, url);
    }
}