import { PagingInfo } from "api/paging-info";
import { EquipmentInspectionProxy } from "api/proxies/equipment-inspection-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { InspectionTypeLookupModel } from "api/models/company/inspection/inspection-type-lookup-model";
import { InspectionTypeModel } from "api/models/company/inspection/inspection-type-model";

@autoinject()
export class EquipmentInspectionService {
    constructor(private readonly equipmentInspectionProxy: EquipmentInspectionProxy) {}

    public async GetEquipmentsForInspections(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        return this.equipmentInspectionProxy.GetEquipmentsForInspections(filter, pagingInfo, requestConfig);
    }

    public async GetInspectionTypesLookup(equipmentId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InspectionTypeLookupModel[] | null> {
        return this.equipmentInspectionProxy.GetInspectionTypesLookup(equipmentId, filter, pagingInfo, requestConfig);
    }

    public async GetInspectionType(inspectionTypeId: string | null, equipmentId: string | null, requestConfig?: HttpClientRequestConfig): Promise<InspectionTypeModel | null> {
        return this.equipmentInspectionProxy.GetInspectionType(inspectionTypeId, equipmentId, requestConfig);
    }
}
