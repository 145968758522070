import { DocumentModel } from "api/models/company/document/document-model";
import { autoinject } from "aurelia-framework";
import apiHelper from "helpers/apiHelper";
import documentQueueService from "services/documentQueueService";

@autoinject()
export class ServiceCallQuotationDocumentService {

    public AddDocumentForServiceCallQuotation(quotationId: number, model: DocumentModel[]): Promise<any> {
         const url = apiHelper.getBaseUrl() + `/service-call-quotations/${quotationId}/documents`;
         return documentQueueService.addMultiple("service-quotation", quotationId, url, model);
    }
}
