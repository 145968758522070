import { UserGridStateSaveModel } from "api/models/company/user-grid-state-save-model";
import { UserGridStateModel } from "api/models/company/user-grid-state-model";
import { UserGridStateProxy } from "api/proxies/user-grid-state-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { SettingRepository } from "repositories/setting-repository";
import { GridStateType } from "api/enums/grid-state-type";

@autoinject()
export class UserGridStateService {
    constructor(private readonly userGridStateProxy: UserGridStateProxy, private readonly settingRepository: SettingRepository) {
    }

    public async getUserGridState(gridId: string, requestConfig?: HttpClientRequestConfig): Promise<UserGridStateModel | null> {
        const usercode = this.settingRepository.getUser();
        return await this.userGridStateProxy.GetUserGridState(usercode, gridId, requestConfig ? requestConfig : undefined);
    }

    public async setUserGridState(gridId: string, columnState: string, filterState: string, sortState: string, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const usercode = this.settingRepository.getUser();

        const userGridStateSaveModel: UserGridStateSaveModel = {
            GridStateType: GridStateType.Column,
            UserCode: usercode,
            GridId: gridId,
            State: columnState
        };

        await this.userGridStateProxy.SetUserGridState(userGridStateSaveModel, requestConfig ? requestConfig : undefined);
        userGridStateSaveModel.GridStateType = GridStateType.Filter;
        userGridStateSaveModel.State = filterState;
        await this.userGridStateProxy.SetUserGridState(userGridStateSaveModel, requestConfig ? requestConfig : undefined);
        userGridStateSaveModel.GridStateType = GridStateType.Sort;
        userGridStateSaveModel.State = sortState;
        return await this.userGridStateProxy.SetUserGridState(userGridStateSaveModel, requestConfig ? requestConfig : undefined);
    }
}
