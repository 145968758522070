import { autoinject } from "aurelia-framework";
import documentService from "services/documentService";
import documentHelper from "helpers/documentHelper";
import routerHelper from "helpers/routerHelper";
import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";

@autoinject()
export class ProjectDocuments {

    public actions: any;
    public dispatchProjectCode: string = "";
    public readonly: boolean = false;

    constructor(private readonly i18n: I18N, private readonly dispatchProjectService: DispatchProjectService) {
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    public async activate(params: any): Promise<void> {

        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchProjectCode = params.dispatchProjectCode;

        this.actions = {
            getSections: documentService.getSectionsByDispatchProjectCode.bind(self, params.dispatchProjectCode),
            getSectionDocuments: documentService.getDocumentsByDispatchProjectCode.bind(self, params.dispatchProjectCode),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService)
        };
    }

    private async checkIsProjectActive(): Promise<void> {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
