import { SmsContactModel } from "api/models/company/contact/sms-contact-model";
import { autoinject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-typed-observable-plugin";
import { MaCardLineModel, MaCardOptionsModel } from "components/layout/ma-card/ma-card-options.model";
import { MaMessageOptionsModel } from "./ma-message-options.model";
import { NotificationHelper } from "helpers/notification-helper";
import val from "core/val";

@autoinject()
export class MaMessages {

    @bindable public messageOptionsModel!: MaMessageOptionsModel;

    @observable public message: string = "";
    public contacts: SmsContactModel[] = [];
    public contactCards: MaCardOptionsModel[] = [];

    public hasAtLeastOneContactSelected: boolean = false;

    public messageMaxLength: number = val.get("sms.message", "maxLength");

    constructor(private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper) {
    }

    public async bind(): Promise<void> {
        this.contacts = await this.messageOptionsModel.getContactsService();

        if (this.contacts.length < 1) { return; }

        this.contacts.forEach((x: SmsContactModel) => {
            this.contactCards.push(this.createContactCard(x));
        });

        this.countSelectedContacts();
    }

    public async sendMessage(): Promise<void> {
        const selectedContactsCount = this.countSelectedContacts();

        if (selectedContactsCount < 1 || this.message.length < 1) {
            return;
        }

        const confirmation = await this.notificationHelper.showDialogYesNo(this.i18n.tr("Confirm_SendSMS"));
        if (confirmation) {
            const contacts: SmsContactModel[] = this.contactCards.filter((x: MaCardOptionsModel) => x.model.IsSelected).map((x: MaCardOptionsModel) => x.model);

            const result = await this.messageOptionsModel.sendMessageService(contacts, this.message);

            if (!result) { return; }

            if (result.ErrorCode !== 0) {
                const contactsWithError = result.Contacts!.filter((x: SmsContactModel) => x.ErrorCode !== 0);
                contactsWithError!.forEach((contact: SmsContactModel): any => {
                    if (contact.ErrorCode > 0) {
                        const apiError = this.i18n.tr(`ApiError.${contact.ErrorCode}`);
                        this.notificationHelper.showError(`${contact.EmployeeNumber} - ${contact.EmployeeDescription} : ${apiError}`);
                    } else {
                        this.notificationHelper.showError(`${contact.EmployeeNumber} - ${contact.EmployeeDescription} : ${contact.ErrorMessage!}`);
                    }
                });
            } else {
                this.notificationHelper.showSuccess(this.i18n.tr("SMSSent_Success"), "");
                this.message = "";
            }
        }
    }

    private countSelectedContacts(): number {
        const count = this.contactCards.filter((x: MaCardOptionsModel) => x.model.IsSelected).length;
        this.hasAtLeastOneContactSelected = count > 0;
        return count;
    }

    private createContactCard(contact: SmsContactModel): MaCardOptionsModel {
        const hasContact = !!contact.ContactNo;
        const hasCellphone = !!contact.CellPhone;
        const canReceiveSMS = hasContact && hasCellphone;
        contact.IsSelected = canReceiveSMS;

        const card = new MaCardOptionsModel({
            model: contact,
            cardBodyClass: "py-2",
            id: "card_" + contact.EmployeeNumber,
            isSelectable: canReceiveSMS,
            isSelected: canReceiveSMS,
            displayLines: [
                new MaCardLineModel({ display: `${contact.EmployeeNumber} - ${contact.EmployeeDescription!}` , class: "text-primary font-weight-bold", textAction: this.messageOptionsModel.goToContactProfile.bind(this, contact.EmployeeNumber, contact.DispatchId) }),
            ],
        });

        if (!hasContact) {
            card.displayLines.push(new MaCardLineModel({ display: this.i18n.tr("EmployeNoContact"), class: "text-danger"}));
        } else if (!hasCellphone) {
            card.displayLines.push(new MaCardLineModel({ display: this.i18n.tr("ContactHasNoCellphone"), class: "text-danger"}));
        } else {
            card.action = this.selectContact.bind(this, card);
            card.selectAction = this.selectContact.bind(this, card);
        }

        return card;
    }

    private selectContact(card: MaCardOptionsModel): void {
        if (!card.isSelectable) { return; }

        card.isSelected = !card.isSelected;
        card.model.IsSelected = card.isSelected;

        this.countSelectedContacts();
    }
}
