import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";

import resx from "core/resx";


@inject(resx, DialogController, EventAggregator)
export default class ExportSelection {
    constructor(resx, dialogController, eventAggregator) {
        this.resx = resx;
        this.dialogController = dialogController;
        this.eventAggregator = eventAggregator;

        this.popupTitle = null;
        this.popupMessage ="";
        this.saveLabel = "";
        this.cancelLabel = "";
       
        this.isErrorBox = false;

        this.exportFormat = "";


    }

    activate(options) {
        this.popupMessage = options.message || "";
        this.popupTitle =options.title || null;
        this.saveLabel = options.saveLabel || this.resx.localize('Save');
        this.cancelLabel = options.cancelLabel || this.resx.localize('Cancel');
        this.exportFormat = options.exportFormat;
        this.exportFormatList = options.exportFormatList;

        this.isErrorBox= options.isErrorBox || false;
       

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);
    }

    cancelAction() {
        this.dialogController.cancel("");
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    saveAction() {
        this.dialogController.ok(this.exportFormat);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}