import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, computedFrom } from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import { RouterHelper } from "helpers/router-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";

export interface QuotationRefusalParameters {
    quotationId: number;
}

@autoinject()
export class QuotationRefusal {
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings);
    }

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        private readonly routerHelper: RouterHelper,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController
    ) {
    }

    public async activate(params: QuotationRefusalParameters): Promise<void> {
        await Promise.all([
            this.initQuotation(Number(params.quotationId)),
            this.initSecuritySettings()
        ]);

        this.initValidation();
    }

    public async save(): Promise<void> {
        if (!this.quotation) { return; }

        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
        if (isValid !== true) {
            return;
        }

        await this.serviceCallQuotationService.refuseQuotation(this.quotation.Id, this.quotation.RefusalReason || "");
        this.routerHelper.navigateBack();
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, false, false, false, false, false);
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }

    private initValidation(): void {
        ValidationRules
            .ensure((x: ServiceCallQuotationDetailsModel) => x.RefusalReason).required().withMessageKey("err_ReasonForRefusalRequired")
            .on(this.quotation);
    }
}
