import { DOM, bindingMode, inject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { default as dateHelper } from "helpers/dateHelper";
import "jquery-ui/ui/i18n/datepicker-fr-CA";
import "jquery-ui/ui/widgets/datepicker";
import { SettingRepository } from "repositories/setting-repository";
import { CustomEventHelper } from "helpers/custom-event-helper";

const date_internal_format = "yy-mm-dd";

@inject(SettingRepository, Element)
export class JqueryUiDatePicker {
    calendar = null;
    $calendar = null;

    @bindable.boolean open = false;
    @bindable.date({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable.date minDate;
    @bindable.date maxDate;

    constructor(settingRepository, element) {
        this.settingRepository = settingRepository;
        this.element = element;
    }

    attached() {
        this.initDatePicker();
    }

    valueChanged(newValue) {
        this.updateCalendarDate(newValue);
    }

    initDatePicker() {
        const $container = jQuery(this.container);
        this.$calendar = jQuery(this.calendar);

        this.$calendar.datepicker({
            onSelect: this.onDateSelected.bind(this),
            minDate: this.minDate,
            maxDate: this.maxDate
        });

        this.$calendar.datepicker("option", jQuery.datepicker.regional[this.settingRepository.getLanguage()]);
        // Laisser date_internal_format car le pluggin jQuery n'utilise pas le formats standard,
        // par exemple, "YYYY-MM-DD" retourne "YYYY-June-Friday" en jquery :)
        this.$calendar.datepicker("option", "dateFormat", date_internal_format);
        this.updateCalendarDate(this.value);
    }

    updateCalendarDate(newDate) {
        if (this.$calendar !== null) {
            this.$calendar.datepicker("setDate", newDate);
        }
    }

    onDateSelected(newDate) {
        this.value = dateHelper.dateFromUTC(newDate);

        CustomEventHelper.dispatchEvent(this.element,
            "date-selected",
            this.value,
            false,
            false);
    }
}