import { computedFrom, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { BonusType } from "api/enums/bonus-type";

interface Bonus {
    unit: number | null;
    type: BonusType;
    description: string;
    value: any;
}

@transient()
export class BonusEditor {
    @bindable public bonus: Bonus | null = null;
    @bindable.booleanAttr public disabled: boolean = false;

    @computedFrom("bonus")
    public get hasUnit(): boolean {
        if (!this.bonus || !this.bonus.unit) {
            return false;
        }
        return this.isQuantity;
    }

    @computedFrom("bonus")
    public get isQuantity(): boolean {
        if (!this.bonus) {
            return false;
        }
        return this.bonus.type === BonusType.Quantity;
    }
}
