import materialService from "services/materialService";
import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";

@autoinject
export class FieldServiceMaterial {
    public workOrderId: string = "";
    public readonly: boolean = false;
    public actions: any;

    public activate(params: any): void {
        this.bindViewModel(decodeURIComponent(params.workOrderId), params.q);
    }

    public bindViewModel(workOrderId: string, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.workOrderId = workOrderId;

        this.actions = {
            deleteMaterial: materialService.deleteForWorkOrder.bind(materialService, this.workOrderId),
            getMaterials: materialService.listForWorkOrder.bind(materialService, this.workOrderId),
            getReserved: materialService.getIsReservedMaterials.bind(materialService, this.workOrderId),
            setReservedMaterials: materialService.setWorkOrderReservedMaterials.bind(materialService, this.workOrderId)
        };
    }
}
