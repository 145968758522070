// GENERATED FILE - DO NOT MODIFY
export enum AccessType {
    MaestroOnly = 0,
    MobileOnly = 1,
    MaestroAndMobile = 2,
    MobileEmployee = 5,
    MaestroAndMobileEmployee = 6,
    MobileProfilEmployee = 7,
}

export let nameof = "AccessType";
