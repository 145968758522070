// GENERATED FILE - DO NOT MODIFY
import { WorkOrderMaintenanceModel } from "api/models/company/workorder/work-order-maintenance-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class WorkOrderMaintenanceTaskProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetworkOrderMaintenanceTasks(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderMaintenanceModel | null> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/maintenance-tasks",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: WorkOrderMaintenanceModel | null = await this.get<WorkOrderMaintenanceModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderMaintenanceModel");
        return data;
    }

    public async UpdateWorkOrderMaintenanceTasks(workOrderMaintenance: WorkOrderMaintenanceModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/workorders/maintenance-tasks",
            null,
            null
        );

        await this.post<WorkOrderMaintenanceModel | null, void>(uri, workOrderMaintenance, requestConfig);
    }

    public async UpdateWorkOrderMaintenanceCounters(workOrderMaintenance: WorkOrderMaintenanceModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/workorders/maintenance-tasks/counters",
            null,
            null
        );

        await this.post<WorkOrderMaintenanceModel | null, void>(uri, workOrderMaintenance, requestConfig);
    }
}
