import { InspectionService } from "services/inspection-service";
import { InspectionContext } from "api/enums/inspection-context";
import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { bindable, autoinject} from "aurelia-framework";
import dateHelper from "helpers/dateHelper";

@autoinject()
export class InspectionHeader {
    public title: string = "";
    public subtitle: string = "";

    @bindable public id!: number;

    constructor(private readonly inspectionService: InspectionService) {
    }

    public async bind(): Promise<void> {
        const inspection = await this.inspectionService.GetInspection(this.id!);
        this.setDisplayValues(inspection!);
    }

    private setDisplayValues(inspection: InspectionModel): void {
        this.title = dateHelper.getFullTextDate(inspection.InspectionDate);

        const contextDisplay = inspection.InspectionContext === InspectionContext.Project ? `${inspection.ProjectDescription} (${inspection.ProjectNo})` : `${inspection.EquipmentDescription} (${inspection.EquipmentId})`;

        this.subtitle = inspection.InspectionContext !== InspectionContext.ServiceCall ? `${inspection.Id} - ${contextDisplay}` : `${inspection.Id}`;
    }
}
