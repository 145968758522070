import { JobModelStatus } from "api/enums/job-model-status";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { BindingSignaler } from "aurelia-templating-resources";
import { NotificationHelper } from "helpers/notification-helper";
import { ProcoreSynchronizationService } from "services/procore/procore-synchronization-service";
import { ProcoreToolsService } from "services/procore/procore-tools-service";
import { Synchronization } from "../../synchronization";
import { ProcoreGridItem } from "../grid/models/procore-grid-item";
import { ProcoreMenuItemId } from "../side-bar/enums/menu-item-id";

@autoinject
export class ProcoreSynchronizer {
  constructor(
    private readonly notificationHelper: NotificationHelper,
    private readonly i18n: I18N,
    private readonly synchronizationService: ProcoreSynchronizationService,
    private readonly signaler: BindingSignaler,
    private readonly procoreToolsService: ProcoreToolsService) { }

  public async synchronize(synchronization: Synchronization): Promise<void> {
    if (!synchronization) { return; }

    const selectedItemId: ProcoreMenuItemId | null = synchronization.sidebar.selectedItemId;
    const items = synchronization.grid.items;

    if (!items || selectedItemId == null) { return; }

    await this.synchronizeItems(items,
                                selectedItemId,
                                synchronization);
  }

  private async synchronizeItems(items: ProcoreGridItem[], selectedItemId: ProcoreMenuItemId, synchronization: Synchronization): Promise<void> {
    if (synchronization.grid.items) {

      // On sync par niveau
      this.synchronizeByLevel(items, selectedItemId, synchronization);
    }
  }

  private synchronizeByLevel(items: ProcoreGridItem[], selectedItemId: ProcoreMenuItemId, synchronization: Synchronization, ignoreDetailsItems: boolean = true): void {
    items.forEach((item: ProcoreGridItem) => {
      const isSelected = item.Selected;
      // const onTransactionGrid = selectedItemId === ProcoreMenuItemId.Transactions;

      if (!ignoreDetailsItems || !item.IsDetails) {
          if (isSelected) {
                item.Selected = false;
                this.synchronizeItem(item, synchronization).then(() => {
                  this.synchronizeByLevel(item.Children, selectedItemId, synchronization);
                });
              }  else {
                this.synchronizeByLevel(item.Children, selectedItemId, synchronization);
          }
        }
    });
  }

  private getFlatList(items: ProcoreGridItem[], ignoreDetailsItems: boolean = true): ProcoreGridItem[] {
    const flatList: ProcoreGridItem[] = [];

    if (!items || items.length <= 0) { return []; }

    items.forEach((item: ProcoreGridItem) => {

      if (!ignoreDetailsItems || !item.IsDetails) {
        flatList.push(item);
      }

      const children = this.getFlatList(item.Children);

      children.forEach((child: ProcoreGridItem) => {
        child.Parent = flatList.find((i: ProcoreGridItem) => i.Code === child.ParentCode);
        flatList.push(child);
      });
    });

    return flatList;
  }

  private async synchronizeItem(item: ProcoreGridItem, synchronization: Synchronization): Promise<void> {
    item.SynchronizationTried = true;
    item.IsSynchronizing = true;
    item.JobStatus = JobModelStatus.Waiting;
    item.Message = "";
    item.Errors = [];
    item.Warnings = [];

    this.signaler.signal("procore-polling");

    try {
      switch (synchronization.sidebar.selectedItemId) {
        case ProcoreMenuItemId.Projects:
          item.JobId = await this.synchronizationService.SynchronizeProject(item);
          break;
        case ProcoreMenuItemId.Suppliers:
          item.JobId = await this.synchronizationService.SynchronizeSupplier(item);
          break;
          case ProcoreMenuItemId.PccoClients:
            item.JobId = await this.synchronizationService.SynchronizePccoClient(item);
            break;
        case ProcoreMenuItemId.Transactions:
          item.JobId = await this.synchronizationService.SynchronizeTransactions(item, synchronization.filters.transactionFilters.ignoreDate);
          break;
        case ProcoreMenuItemId.Employees:
          item.JobId  = await this.synchronizationService.SynchronizeEmployee(item);
          break;
        case ProcoreMenuItemId.DeleteDirectCosts:
            if (!synchronization.filters.deleteDirectCostFilters.date) {
                this.notificationHelper.showError(this.i18n.tr(`Procore.DeleteDirectCosts_Date_Required`), "");
                item.IsSynchronizing = false;
                item.JobStatus = null;
            } else {
                item.JobId  = await this.procoreToolsService.DeleteDirectCosts(item, synchronization.filters.deleteDirectCostFilters.date);
            }
            break;
        case ProcoreMenuItemId.Budget:
            item.JobId  = await this.synchronizationService.SynchronizeProjectBudget(item);
            break;
        case ProcoreMenuItemId.BudgetModifications:
            item.JobId = await this.synchronizationService.SynchronizeBudgetModification(item);
            break;
          case ProcoreMenuItemId.PrimeContracts:
            item.JobId = await this.synchronizationService.SynchronizePrimeContracts(item);
            break;
      }
    } catch (e) {
        item.IsSynchronizing = false;
        item.JobStatus = JobModelStatus.Error;
        // TODO: Ajouter les erreurs dans l'item
    } finally {
      item.DetailsOpen = false;
      item.Details = [];
    }
  }
}
