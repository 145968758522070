import { PagingInfo } from "api/paging-info";
import { MaestroBiProxy } from "api/proxies/maestro-bi-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { UserSettingsModel } from "api/models/common/settings/user-settings-model";
import { MaestroBiSessionModel } from "api/models/company/maestro-bi/maestro-bi-session-model";
import { MaestroBiReportHierarchyModel } from "api/models/company/maestro-bi/maestro-bi-report-hierarchy-model";
import { CompanyModel } from "api/models/common/company/company-model";
import { MaestroBiReportBlocDisplayModel } from "api/models/company/maestro-bi/maestro-bi-report-bloc-display-model";
import { MaestroBiReportZipFileModel } from "api/models/company/maestro-bi/maestro-bi-report-zip-file-model";
import { MaestroBiReportImportChoiceModel } from "api/models/company/maestro-bi/maestro-bi-report-import-choice-model";

@autoinject()
export class MaestroBiService {
    private maestroBiProxy: MaestroBiProxy;

    constructor(maestroBiProxy: MaestroBiProxy) {
        this.maestroBiProxy = maestroBiProxy;
    }

    public async getCustomPrefixGroups(filter?: string, pagingInfo?: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<UserSettingsModel[] | null> {
        return await this.maestroBiProxy.GetMaestroBiCustomPrefixGroups(filter, pagingInfo, httpClientRequestConfig);
    }

    public async getCompaniesForCurrentUserAndPartition(filter?: string, pagingInfo?: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<CompanyModel[] | null> {
        return await this.maestroBiProxy.GetCompaniesForCurrentUserAndPartition(filter, pagingInfo, httpClientRequestConfig);
    }

    public async createMaestroBiSession(companyName: string | null, prefixes?: string, httpClientRequestConfig?: HttpClientRequestConfig): Promise<MaestroBiSessionModel | null> {
        return await this.maestroBiProxy.CreateMaestroBiSession(companyName, prefixes, httpClientRequestConfig);
    }

    public async FetchReportHierarchy(): Promise<MaestroBiReportHierarchyModel[] | null> {
        return await this.maestroBiProxy.FetchReportHierarchy();
    }

    public async FetchReportById(reportId: string | null): Promise<MaestroBiReportBlocDisplayModel | null> {
        return await this.maestroBiProxy.FetchReportById(reportId);
    }

    public async ExportReportById(reportId: string | null): Promise<MaestroBiReportZipFileModel | null> {
        return await this.maestroBiProxy.ExportReportById(reportId);
    }

    public async GetImportChoices(maestroBiReportZipFileModel: MaestroBiReportZipFileModel | null): Promise<MaestroBiReportImportChoiceModel | null> {
        return await this.maestroBiProxy.GetImportChoices(maestroBiReportZipFileModel);
    }

    public async ImportReportWithinZip(maestroBiReportZipFileModel: MaestroBiReportZipFileModel | null): Promise<string | null> {
        return await this.maestroBiProxy.ImportReportWithinZip(maestroBiReportZipFileModel);
    }
}
