// GENERATED FILE - DO NOT MODIFY
import { WorkOrderProgressStatusModel } from "api/models/company/workorder/work-order-progress-status-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class WorkOrderProgressStatusProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetWorkOrderProgressStatuses(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderProgressStatusModel[] | null> {
        const uri = this.buildUri(
            "/workorders/progressstatuses",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: WorkOrderProgressStatusModel[] | null = await this.get<WorkOrderProgressStatusModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderProgressStatusModel");
        return data;
    }
}
