import { autoinject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Synchronization } from "../synchronization/synchronization";

@autoinject
export class Tools extends Synchronization {
    public get isTools(): boolean {
        return true;
      }
}
