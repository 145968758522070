// GENERATED FILE - DO NOT MODIFY
import { InspectionTypeLookupModel } from "api/models/company/inspection/inspection-type-lookup-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProjectInspectionProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetProjectsForInspections(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectBaseModel[] | null> {
        const uri = this.buildUri(
            "/inspections/projects",
            null,
            { prefix: (prefix === null || prefix === undefined) ? undefined : prefix, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectBaseModel[] | null = await this.get<ProjectBaseModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectBaseModel");
        return data;
    }

    public async GetInspectionTypesLookup(projectId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InspectionTypeLookupModel[] | null> {
        const uri = this.buildUri(
            "/inspections/projet/types/lookup",
            null,
            { projectId: (projectId === null || projectId === undefined) ? undefined : projectId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: InspectionTypeLookupModel[] | null = await this.get<InspectionTypeLookupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Inspection.InspectionTypeLookupModel");
        return data;
    }
}
