import { bindingMode, inject, noView } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

// @ts-ignore TODO
import IMask from "imask";
import moment from "moment";
import Assert from "core/assert";

@inject(Element)
@noView()
export class IMaskInput<T> {
    // -- IMask Options
    @bindable.string public mask!: string;
    @bindable.string public pattern: string | null = null;

    @bindable.booleanAttr public signed: boolean = true;

    @bindable public min: T | null = null;

    @bindable public max: T | null = null;

    @bindable.nullable_number public scale: number | null = null;
    @bindable public normalizeZeros: boolean = true;
    // --

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public value: string | null = null;

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public unmaskedValue: string | null = null;

    private inputElement: HTMLInputElement;
    // @ts-ignore
    private maskObject!: IMaskObject;
    private onBlurClosure: any;

    constructor(element: HTMLInputElement) {
        Assert.strictEqual(element.tagName, "INPUT", `INVALID USAGE OF I-MASK-INPUT. Normal usage is <input type="text" as-element="i-mask-input"/>`);

        this.inputElement = element;
    }

    public attached(): void {
        this.onBlurClosure = this.onBlur.bind(this);
        this.inputElement.addEventListener("blur", this.onBlurClosure);

        this.maskObject = new IMask(this.inputElement, this.getIMaskOptions());

        if (this.value) {
            this.maskObject.value = this.value;
        } else if (this.unmaskedValue) {
            this.maskObject.unmaskedValue = this.unmaskedValue;
        }
    }

    public detached(): void {
        if (this.maskObject) {
            this.maskObject.destroy();
        }
        this.inputElement.removeEventListener("blur", this.onBlurClosure);
    }

    public valueChanged(newValue: string): void {
        if (!this.maskObject) {
            return;
        }

        this.maskObject.value = newValue;
        this.updateValueFromMaskObject();
    }

    public scaleChanged(newValue: number): void {

        if (!this.maskObject) {
            return;
        }

        this.maskObject.updateOptions({
            scale: newValue
        });

        if (!this.value) {
            return;
        }

        if (this.value) {
            this.maskObject.value = this.value;
        } else if (this.unmaskedValue) {
            this.maskObject.unmaskedValue = this.unmaskedValue;
        }

        this.updateValueFromMaskObject();
    }

    public unmaskedValueChanged(newValue: string): void {
        if (!this.maskObject) {
            return;
        }

        this.maskObject.unmaskedValue = newValue;
        this.updateValueFromMaskObject();
    }

    private onBlur(): void {
        this.updateValueFromMaskObject();
    }

    private updateValueFromMaskObject(): void {
        this.value = this.maskObject.value;
        this.unmaskedValue = this.maskObject.unmaskedValue;
    }

    private getIMaskOptions(): any {
        if (!this.mask) {
            throw new Error("IMask input mask is required.");
        }

        const baseOptions = {
            mask: this.mask,
            pattern: this.pattern,
            min: this.min,
            max: this.max,
        };

        const numberSpecificOptions = {
            mask: Number,
            scale: this.scale, // digits after point, 0 for integers
            signed: this.signed, // false = disallow negative
            padFractionalZeros: !this.normalizeZeros, // if true, then pads zeros at end to the length of scale
            normalizeZeros: this.normalizeZeros, // appends or removes zeros at ends
            radix: ".", // fractional delimiter
            mapToRadix: ["."], // symbols to process as radix
        };

        const dateSpecificOptions = {
            mask: Date,
            format: (date: Date): string => {
                return moment(date).format(this.pattern as string);
            },
            parse: (str: string): Date => {
                return moment(str, this.pattern as string).toDate();
            },
            groups: {
                YYYY: new IMask.MaskedPattern.Group.Range([2000, 2099]),
                MM: new IMask.MaskedPattern.Group.Range([1, 12]),
                DD: new IMask.MaskedPattern.Group.Range([1, 31]),
                HH: new IMask.MaskedPattern.Group.Range([0, 23]),
                mm: new IMask.MaskedPattern.Group.Range([0, 59]),
            },
        };

        switch (this.mask.toLowerCase()) {
            case "number":
                return Object.assign(baseOptions, numberSpecificOptions);
            case "date":
                return Object.assign(baseOptions, dateSpecificOptions);
            default:
                return baseOptions;
        }
    }
}
