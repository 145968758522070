import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ProcoreSynchronizationStatus } from "api/enums/procore-synchronization-status";
import { ProcoreEntityToSynchronizeModel } from "api/models/company/procore/procore-entity-to-synchronize-model";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { ActivitiesSynchronizerResult } from "api/models/procore/synchronizers/activities/activities-synchronizer-result";
import { BudgetSynchronizationResult } from "api/models/procore/synchronizers/budgets/budget-synchronization-result";
import { ProjectSynchronizationResult } from "api/models/procore/synchronizers/projects/project-synchronization-result";
import { SupplierSynchronizationResult } from "api/models/procore/synchronizers/suppliers/supplier-synchronization-result";
import { TransactionsSynchronizationResult } from "api/models/procore/synchronizers/transactions/transactions-synchronization-result";
import { ProcoreSynchronisationProxy } from "api/proxies/procore-synchronisation-proxy";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ProcoreGridItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-item";
import { ProcoreExceptionHelper } from "pages/procore/synchronization/helpers/procore-exception-helper";
import { ProcoreGridItemTransformer } from "./helpers/procore-grid-item-transformer";

@autoinject
export class ProcoreSynchronizationService {
  private failedResult: any = {
    Warnings: [],
    Errors: [],
    SynchronizationStatus: ProcoreSynchronizationStatus.Failure,
    EntitiesSynchronizedCount: 0,
    TransactionResults: []
  };

  private language: string | null = "fr";

  private requestParameters: HttpClientRequestConfig = { showLoadingScreen: false } as HttpClientRequestConfig;

  constructor(
    private proxy: ProcoreSynchronisationProxy,
    private i18n: I18N,
    private readonly notificationHelper: NotificationHelper,
    private readonly procoreGridItemTransformer: ProcoreGridItemTransformer,
    private readonly exceptionHelper: ProcoreExceptionHelper) {
      this.language = localStorage.getItem("maestro_user_profile_LANGUAGE");
    }

  public async SynchronizeProject(item: ProcoreGridItem): Promise<number | null> {
    try {
      return await this.proxy.SynchronizeProject(item.Code, this.requestParameters);

      // if (!results) {
      //   return this.failedResult;
      // }

      // item.Errors = this.procoreGridItemTransformer.transformErrors(results.Errors, this.language);
      // item.Warnings = this.procoreGridItemTransformer.transformErrors(results.Warnings, this.language);

      // item.Message = this.procoreGridItemTransformer.getMessage(item.Errors, item.Warnings);

      // item.IsSynchronized = results.IsSynchronized;

    } catch (e) {
      this.exceptionHandling(e, item);
    }

    return null;
  }

  public async SynchronizeProjectActivities(projectCode: string | null): Promise<ActivitiesSynchronizerResult> {
    // return await this.proxy.SynchronizeProjectActivities(projectCode);
    return this.failedResult;
  }

  public async SynchronizeProjectBudget(item: ProcoreGridItem): Promise<number | null> {
    try {
      item.Errors = [];
      item.Warnings = [];

      item.Message = "";

      return await this.proxy.SynchronizeBudget(item.Code, this.requestParameters);
    } catch (e) {
      this.exceptionHandling(e, item);
    }

    return null;
  }

  public async SynchronizeBudgetModification(item: ProcoreGridItem): Promise<number | null> {
    try {
      item.Errors = [];
      item.Warnings = [];

      item.Message = "";

      return await this.proxy.SynchronizeBudgetModification(item.Code, this.requestParameters);
    } catch (e) {
      this.exceptionHandling(e, item);
    }

    return null;
  }

  public async SynchronizeTransactions(item: ProcoreGridItem, ignoreDate: boolean): Promise<number | null> {
    try {
      item.Errors = [];
      item.Warnings = [];

      item.Message = "";

      return await this.proxy.SynchronizeTransactions(item.Code, ignoreDate, this.requestParameters);
    } catch (e) {
      this.exceptionHandling(e, item);
    }

    return null;
  }

  public async SynchronizeEmployee(item: ProcoreGridItem): Promise<number | null> {
    item.Errors = [];
    item.Warnings = [];
    item.Message = "";

    try {
      return await this.proxy.SynchronizeEmployee(item.Code, this.requestParameters);
    } catch (e) {
        this.exceptionHandling(e, item);
    }

    return null;
  }

  public async SynchronizeSupplier(item: ProcoreGridItem): Promise<number | null> {
    try {
      return await this.proxy.SynchronizeSupplier(item.Code, this.requestParameters);
    } catch (e) {
        this.exceptionHandling(e, item);
    }

    return null;
  }

  public async SynchronizePccoClient(item: ProcoreGridItem): Promise<number | null> {
    try {
      return await this.proxy.SynchronizePCCOClient(item.Code, this.requestParameters);
    } catch (e) {
        this.exceptionHandling(e, item);
    }

    return null;
  }

  public async SynchronizePrimeContracts(item: ProcoreGridItem): Promise<number | null> {
    try {
      return await this.proxy.SynchronizePrimeContract(item.Code, this.requestParameters);
    } catch (e) {
        this.exceptionHandling(e, item);
    }

    return null;
  }

  private transformErrors(errors: Array<ProcoreError | null> | null, language: string | null): string[] {
    if (!errors) { return []; }

    return errors.map((error: ProcoreError): string => {
      const message = language === "fr" ? error.MessageFr
        : error.MessageEn;

      if (!message) {
        return "";
      }

      return message;
    }) as string[];
  }

  private exceptionHandling(e: any, item: ProcoreGridItem): void {
    let message = "";
    if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
      message = this.exceptionHelper.getMessageFromProcore(e.response.data.Exception, item.EntityToSynchronize);
    } else {
      message = e.response.data.Exception.Message;
    }

    const error = {
      MessageFr: message,
      MessageEn: message
    } as ProcoreError;

    const errors = this.procoreGridItemTransformer.transformErrors([error], this.language);

    item.Message = this.procoreGridItemTransformer.getMessage(errors, []);
    item.Errors = errors;

    this.failedResult.Errors = item.Errors;
    this.failedResult.Warnings = item.Warnings;
  }
}
