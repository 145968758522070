import { inject, observable, bindable, computedFrom } from "aurelia-framework";
import { ValidationRules, ValidationControllerFactory, validateTrigger } from "aurelia-validation";

import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";
import { default as val } from "core/val";

import { default as dateHelper } from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as notifier } from "helpers/notificationHelper";

import { default as materialRequisitionService } from "services/materialRequisitionService";

const defaultScaleFactor = 7;
@inject(ValidationControllerFactory, I18N)
export class MaterialRequisitionItemCustomElement {

    dateRequired = null;
    description1 = "";
    description2 = "";
    description3 = "";
    quantity = 1;
    unitDescription = null;
    showCodeInput = false;
    outCatalogCode = "";

    @bindable requisitionId;
    @bindable catalogBaseUrl;
    @bindable lineNo = 0;
    @bindable requisitionDate;
    @bindable showNotInCatalog = false;
    @bindable actions;
    @bindable readonly = false;

    @observable selectedItem;

    @computedFrom("readonly", "isNew", "showNotInCatalog")
    get catalogPickerDisabled() {
        return this.readonly || (!this.isNew && this.showNotInCatalog);
    }

    @computedFrom("lineNo")
    get isNew() {
        if (!this.lineNo) { return true; }
        return false;
    }

    constructor(validationControllerFactory, i18n) {
        this.descriptionMaxLength = val.get('materialBillingEdit.description', 'maxLength');
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.manual;

        this.i18n = i18n;
        //Validationrules
        ValidationRules
            .ensure(x => x.quantity).required().withMessageKey("err_QuantityRequired")
            .ensure(x => x.selectedItem).required().withMessageKey("err_ItemRequired")
            .ensure(x => x.description).required().withMessageKey("err_DescriptionRequired")
            .on(this);
    }

    selectedItemChanged(newValue, oldValue) {
        if (this.selectedItem === undefined) {
            this.description1 = "";
            this.description2 = "";
            this.description3 = "";
            this.unitDescription = null;

            this.inventoryScaleFactor = defaultScaleFactor;
        } else if (this.selectedItem.id === "!") {
            this.description1 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description1;
            this.description2 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description2;
            this.description3 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description3;
            this.unitDescription = null;
            this.showCodeInput = true;

            this.inventoryScaleFactor = defaultScaleFactor;
        } else {
            this.description1 = this.selectedItem.data ? this.selectedItem.data.Description1 : this.selectedItem.Description1;
            this.description2 = this.selectedItem.data ? this.selectedItem.data.Description2 : this.selectedItem.Description2;
            this.description3 = this.selectedItem.data ? this.selectedItem.data.Description3 : this.selectedItem.Description3;

            this.unitDescription = (newValue.data ? newValue.data.UnitDescription : "");

            this.inventoryScaleFactor = this.selectedItem.data ? this.selectedItem.data.InventoryScaleFactor : defaultScaleFactor;
        }
    }

    formatDescription(desc1, desc2, desc3) {
        var descArray = [desc1, desc2, desc3];
        descArray = _.filter(descArray, function(n){
            return n !== "";
        });

        return descArray.join(" | ");
    }

    clearForm() {
        this.dateRequired = this.requisitionDate;
        this.description1 = "";
        this.description2 = "";
        this.description3 = "";
        this.quantity = 1;
        this.selectedItem = undefined;
    }

    async bind() {
        if (!this.lineNo) { this.lineNo = 0; }
        return await this.loadData();
    }

    async saveItem() {
        const result = await this.validationController.validate();

        if (result.valid) {
            routerHelper.showLoading();
            var item = {
                Code: this.selectedItem.id,
                Description: this.formatDescription(this.description1, this.description2, this.description3),
                Line: this.lineNo,
                Quantity: this.quantity,
                RequiredDate: dateHelper.formatDateToSend(this.dateRequired),
                Id: this.requisitionId,
                OutCatalogCode: this.outCatalogCode
            };

            item = this.actions.setSpecificFields(item);

            this.actions.saveMaterialRequisitionItem(this.requisitionId, this.lineNo, item)
                .done(() => {
                    routerHelper.navigateBack();
                }).
            always(() => {
                routerHelper.hideLoading();
            });
        } else {
            const errors = _.chain(result.results).filter((result) => { return !result.valid }).pluck("message").value();
            notifier.showValidationError(errors);
        }
    }

    async loadData() {
        const materialRequisition = await materialRequisitionService.getMaterialRequisition(this.requisitionId).promise();

        this.readonly |= materialRequisition.MobileStatus === "1";

        if (this.isNew) {
            this.clearForm();
            return;
        }

        const materialRequisitionItem = await materialRequisitionService.getMaterialRequisitionItem(this.requisitionId, this.lineNo).promise();

        this.selectedItem = { id: materialRequisitionItem.Code, text: materialRequisitionItem.Code === "!" ? this.i18n.tr('AddItemNotInCatalog') : materialRequisitionItem.Code };
        this.description1 = materialRequisitionItem.Description1;
        this.description2 = materialRequisitionItem.Description2;
        this.description3 = materialRequisitionItem.Description3;
        this.dateRequired = dateHelper.parseDate(materialRequisitionItem.RequiredDate);
        this.quantity = materialRequisitionItem.Quantity;
        this.unitDescription = materialRequisitionItem.UnitDescription;
        this.showNotInCatalog = (materialRequisitionItem.Code === "!");
        this.showCodeInput = (materialRequisitionItem.Code === "!")
        this.outCatalogCode = materialRequisitionItem.OutCatalogCode;

        this.inventoryScaleFactor = materialRequisitionItem.InventoryScaleFactor;
    }
}
