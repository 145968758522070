import { ReceiptOfGoodsService } from "./../../services/receipt-of-goods-service";
import RouteRepository from "repositories/routeRepository";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable, observable } from "aurelia-framework";

import { Tab } from "components/layout/tab";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";

import { ReceiptOfGoodsModel } from "api/models/company/receipt-of-goods-model";
import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { ReceiptOfGoodsSortOrder, nameof as nameof_ReceiptOfGoodsSortOrder } from "api/enums/receipt-of-goods-sort-order";
import defaultService from "services/defaultService";

@autoinject
export class ReceiptOfGoodsIndex {
    @bindable public currentTabId: number = 0;

    public onGoingReceptionsCards: MaCardOptionsModel[] = [];
    public closedReceptionsCards: MaCardOptionsModel[] = [];
    public orderId: string | null = null;
    public extension: string = "";
    public searchString: string | null = null;
    @observable
    public selectedSortOrder: ReceiptOfGoodsSortOrder = ReceiptOfGoodsSortOrder.DateAscending;
    public readonly sortOrders: ReceiptOfGoodsSortOrder[] = [ReceiptOfGoodsSortOrder.DateAscending, ReceiptOfGoodsSortOrder.DateDescending];
    public readonly nameof_ReceiptOfGoodsSortOrder: string = nameof_ReceiptOfGoodsSortOrder;
    public isLoading: boolean = true;

    constructor(private readonly i18n: I18N, private readonly receiptOfGoodsService: ReceiptOfGoodsService, private routeRepository: RouteRepository, public readonly enumFormatValueConverter: EnumFormatValueConverter) {}

    public async attached(): Promise<void> {
        await this.currentTabIdChanged();
    }

    public activate(params: any): void {
        this.orderId = !params.orderId ? null : params.orderId;
        this.extension = !params.extension ? "" : params.extension;
        const lastSelectedSortOrder = defaultService.getReceptionLastSelectedSortOrder();
        this.selectedSortOrder = lastSelectedSortOrder ? lastSelectedSortOrder : ReceiptOfGoodsSortOrder.DateAscending;
        this.isLoading = false;
    }

    public async currentTabIdChanged(): Promise<void> {
        if (this.isLoading || this.isLoading === undefined) {
            return;
        }

        this.onGoingReceptionsCards = [];
        this.closedReceptionsCards = [];
        if (!this.orderId) {
            if (this.currentTabId < 1) {
                const result = await this.receiptOfGoodsService.GetReceiptsOfGoods(false, this.selectedSortOrder, this.searchString || "");
                result!.forEach((item: ReceiptOfGoodsModel) => {
                    this.onGoingReceptionsCards.push(this.createCard(item, false));
                });
            } else {
                const result = await this.receiptOfGoodsService.GetReceiptsOfGoods(true, this.selectedSortOrder, this.searchString || "");
                result!.forEach((item: ReceiptOfGoodsModel) => {
                    this.closedReceptionsCards.push(this.createCard(item, true));
                });
            }
        } else {
            if (this.currentTabId < 1) {
                const result = await this.receiptOfGoodsService.GetReceiptsOfGoodsForOrder(false, this.orderId, this.extension === "" ? null : this.extension);
                result!.forEach((item: ReceiptOfGoodsModel) => {
                    this.onGoingReceptionsCards.push(this.createCard(item, true));
                });
            } else {
                this.receiptOfGoodsService.GetReceiptsOfGoodsForOrder(true, this.orderId, this.extension === "" ? null : this.extension).then((result: ReceiptOfGoodsModel[]): any => {
                    result.forEach((item: ReceiptOfGoodsModel) => {
                        this.closedReceptionsCards.push(this.createCard(item, true));
                    });
                });
            }
        }
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (Number(event.detail.tabId) === this.currentTabId) {
            return;
        }
        this.currentTabId = Number(event.detail.tabId);
    }

    public add(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.name, { receiptId: 0 });
    }

    public edit(item: ReceiptOfGoodsModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.name, { receiptId: item.Id, orderId: this.orderId });
    }

    public delete(item: ReceiptOfGoodsModel): void {
        const dialogMessage = `${this.i18n.tr("msg_DeleteReceiptOfGoodConfirmationText")} (${this.getOrderSupplierDisplay(item)})`;

        notificationHelper.showDialogYesNo(dialogMessage).then((success: boolean): any => {
            if (success) {
                this.receiptOfGoodsService.DeleteReceiptOfGoods(item.Id).then((result: boolean): any => {
                    if (result) {
                        this.removeReceptionFromList(item.Id);
                        notificationHelper.showSuccess(this.i18n.tr("Procore.Status_Success"));
                    }
                });
            }
        });
    }

    public createCard(item: ReceiptOfGoodsModel, isClosed: boolean): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getOrderSupplierDisplay(item), class: "text-primary font-weight-bold" }),
                new MaCardLineModel({ display: this.getOrderCounterDisplay(item) }),
                new MaCardLineModel({ display: this.getOrderDateDisplay(item), icon: "fa fa-plus text-success pr-1" }),
                new MaCardLineModel({ display: this.getOrderStatusDisplay(item), class: "font-weight-bold" }),
                new MaCardLineModel({ display: this.getCreatedByDisplay(item), class: "mt-2" }),
                new MaCardLineModel({ display: this.getDeliveryNoDisplay(item), class: "mt-2" }),
            ],
            actionItems: [new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.edit.bind(this, item) })],
        });

        if (!isClosed) {
            card.actionItems!.push(new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item) }));
        }
        return card;
    }

    public getOrderSupplierDisplay(item: ReceiptOfGoodsModel): string {
        const capitalizedExtensionString = this.i18n.tr("ExtensionShort:").charAt(0).toUpperCase() +  this.i18n.tr("ExtensionShort:").slice(1);
        const extensionDisplay = item.Extension ? ` ${capitalizedExtensionString} ${item.Extension}` : "";

        return `${item.OrderNumber}${extensionDisplay} - ${item.Supplier}`;
    }

    public getOrderCounterDisplay(item: ReceiptOfGoodsModel): string {
        return `${this.i18n.tr("Counter")}: ${item.Id}`;
    }

    public getOrderDateDisplay(item: ReceiptOfGoodsModel): string {
        return `${this.i18n.tr("Added")} ${dateHelper.formatDate("DD MMM YYYY", item.ReceiptDate!)}`;
    }

    public getOrderStatusDisplay(item: ReceiptOfGoodsModel): string {
        return item.Status === "0" ? this.i18n.tr("InProgress") : this.i18n.tr("Completed");
    }

    public getCreatedByDisplay(item: ReceiptOfGoodsModel): string {
        return `${this.i18n.tr("CreatedBy")} ${item.CreatedBy}`;
    }

    public getDeliveryNoDisplay(item: ReceiptOfGoodsModel): string {
        if (!item.DeliveryNo) {
            return "";
        }

        return `${this.i18n.tr("DeliveryNo")} ${item.DeliveryNo}`;
    }

    public async searchChanged(val: any): Promise<void> {
        if (this.orderId || this.searchString === null) {
            return;
        }

        await this.currentTabIdChanged();
    }

    public async selectedSortOrderChanged(newValue: ReceiptOfGoodsSortOrder): Promise<void> {
        if (this.isLoading || this.isLoading === undefined) {
            return;
        }

        defaultService.setReceptionLastSelectedSortOrder(this.selectedSortOrder);
        await this.currentTabIdChanged();
    }

    private removeReceptionFromList(id: number): void {
        this.onGoingReceptionsCards = this.onGoingReceptionsCards.filter((item: MaCardOptionsModel) => {
            return id !== item.model.Id;
        });
    }
}
