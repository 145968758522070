import { I18N } from "aurelia-i18n";
import { customElement, autoinject, DOM, bindingMode } from "aurelia-framework";
import { BindingSignaler } from "aurelia-templating-resources";
import { bindable } from "aurelia-typed-observable-plugin";

// @ts-ignore TODO Importer @types/signature_pad mais il est pas encore fonctionnel
import SignaturePad from "signature_pad";

import Assert from "core/assert";
import { NotificationHelper } from "helpers/notification-helper";
import { RouterHelper } from "helpers/router-helper";
import { CustomEventHelper } from "helpers/custom-event-helper";
import logger from "core/logger";

@autoinject()
@customElement("signature-pad")
export class SignaturePadComponent {
    @bindable.boolean({ defaultBindingMode: bindingMode.twoWay })
    public toggleOpen: boolean = false;

    public readonly updateBindingSignal: "SIGNATURE_PAD_UPDATE_BINDING_SIGNAL" = "SIGNATURE_PAD_UPDATE_BINDING_SIGNAL";
    public readonly toolbar!: HTMLDivElement;
    public readonly canvas!: HTMLCanvasElement;

    private onWindowResizeEventCallback: EventListener | null = null;
    private signaturePad: SignaturePad | undefined;

    private get isCanvasEmpty(): boolean {
        if (!this.signaturePad) {
            return true;
        }
        return this.signaturePad.isEmpty();
    }

    private get signatureData(): string | undefined {
        if (!this.signaturePad) {
            return undefined;
        }

        return this.signaturePad.toDataURL();
    }

    constructor(
        private readonly element: Element,
        private readonly i18n: I18N,
        private readonly routerHelper: RouterHelper,
        private readonly bindingSignaler: BindingSignaler,
        private readonly notificationHelper: NotificationHelper
    ) {}

    // #region Computed
    public getIsOrientationHorizontal(): boolean {
        return window.innerWidth > window.innerHeight;
    }

    public getCanvasWidth(): number {
        return window.innerWidth;
    }

    public getCanvasHeight(): number {
        return window.innerHeight - (this.toolbar ? this.toolbar.offsetHeight : 0);
    }
    // #endregion

    // #region Observable
    public toggleOpenChanged(newValue: boolean): void {
        if (newValue === true) {
            return this.open();
        }

        this.close();
    }
    // #endregion

    // #region Events Handlers
    public async clear(): Promise<void> {
        if (!this.signaturePad) {
            logger.warn("Signature pad is undefined during the clear event handler.");
            return;
        }

        const confirmation = await this.notificationHelper.showDialogYesNo(this.i18n.tr("DoYouWantToClear"));
        if (!confirmation) {
            return;
        }

        this.signaturePad.clear();
        this.onResize();
    }

    public async save(): Promise<void> {
        CustomEventHelper.dispatchEvent(this.element, "signature-completed", this.signatureData);
        this.close();
    }
    // #endregion

    // #region Privates
    private open(): void {
        this.signaturePad = this.signaturePad || new SignaturePad(this.canvas);
        this.signaturePad.clear();

        this.toggleOpen = true;

        // Make sure we are fullscreen on mobile devices
        this.hideKeyboard();

        // Prevent scroll bar to appear in front of modal
        window.document.body.style.overflow = "hidden";

        // Add events listeners
        Assert.strictEqual<any>(this.onWindowResizeEventCallback, null, "Trying to bind multiple window resize events on the signature pad.");
        this.onWindowResizeEventCallback = async (): Promise<void> => await this.onResize();
        window.addEventListener("resize", this.onWindowResizeEventCallback);
        this.signaturePad.on();

        this.onResize();
    }

    private close(): void {
        this.toggleOpen = false;

        window.document.body.style.overflow = null;

        // Remove events listeners
        window.removeEventListener("resize", this.onWindowResizeEventCallback as EventListener);

        this.onWindowResizeEventCallback = null;
        if (this.signaturePad) {
            this.signaturePad.off();
        }
    }

    private hideKeyboard(): void {
        // hack to hide keyboard prior to showing popup... ios bug with popup not being full screen
        const activeElement: HTMLInputElement = document.activeElement as HTMLInputElement;

        if (activeElement && activeElement.blur) {
            activeElement.blur();
        }
    }

    private async onResize(): Promise<void> {
        // Don't resize the canvas anymore once the user starts writing.
        if (!this.isCanvasEmpty) {
            return;
        }

        // Wait a specific delay before finishing the canvas initialization due to problems with the resize event on Safari.
        const promise = new Promise<void>(
            (resolve: () => void): void => {
                setTimeout(resolve, 1250);
            }
        );

        await this.routerHelper.waitFor<void>(promise);
        this.bindingSignaler.signal(this.updateBindingSignal);
    }
    // #endregion
}
