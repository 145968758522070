// GENERATED FILE - DO NOT MODIFY
export enum PrintFormType {
    WorkOrder = 0,
    ServiceCall = 1,
    ReturnFromCall = 2,
    WorkOrderApproval = 3,
    DailyEntry = 4,
    ServiceQuotationDetailed = 5,
    ServiceQuotationSummary = 6,
    ServiceQuotationInternal = 7,
    Inspection = 9,
    ServiceQuotationBTDetailed = 10,
    ServiceQuotationBTSummary = 11,
    ServiceQuotationBTInternal = 12,
    PurchaseOrder = 13,
}

export let nameof = "PrintFormType";
