import _ from "underscore";

export class StringHelper {
    public static keepAlphaNumericOnly: RegExp = /^[a-zA-Z0-9]*$/;
    public static keepNumericOnly: RegExp = /^[0-9]*$/;

    public static replaceAt(value: string, index: number, replacement: string): string {
        return value.substring(0, index) + replacement + value.substring(index + 1);
    }

    public static insertAt(value: string, index: number, valueToInsert: string): string {
        return value.slice(0, index) + valueToInsert + value.substring(index);
    }

    public static startsWith(value: string, searchString: string): boolean {
        return value.substring(0, searchString.length) === searchString;
    }

    public static endsWith(value: string, searchString: string): boolean {
        //minus sign in sustr function is for -1 * of the length
        return value.substring(-searchString.length) === searchString;
    }

    public static cleanString(value: string, regExp: RegExp, maxLength?: number): string {
        maxLength = maxLength ? maxLength : value.length;

        const cleanString = _.reject(value, (char: any) => {
            return !regExp.test(char);
        });

        return _.first(cleanString, maxLength!).join("");
    }

    public static UrlAndMailAsLink(comment: string): string {
        if (!comment) {
            return comment;
        }

        const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
        const emailRegex = /[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[a-zA-Z]{2,})/g;
       
        comment = this.ResolveMatch(comment, urlRegex, emailRegex, (s: string) => "<a class='blue' href=" + (s.includes("//") ? s : "//" + s + "/") + " target='_blank'>" + s + "</a>");

        //Check for emails
        comment = this.ResolveMatch(comment, emailRegex, undefined, (s: string) => "<a class='blue' href=mailto:" + s + ">" + s + "</a>");

        return comment;
    }

    public static ResolveMatch(comment: string, regex: any, excludeRegEx: any, transformText: (matchstring: string) => string): string {
        const matches = comment.match(regex);
        if (matches !== null && matches[0] !== undefined) {
            matches.forEach((match: any) => {
                if (excludeRegEx === undefined || !excludeRegEx.test(match)) {
                    comment = comment.replace(match, transformText(match));
                }
            });
        }
        return comment;
    }
}
