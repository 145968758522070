import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";

@autoinject
export class FieldServiceContactDetail {

    public id: any;
    public workOrderId: string = "";
    public readonly: boolean = false;

    public activate(params: any): any {
        this.bindViewModel(decodeURIComponent(params.workOrderId), params.id, params.q);
    }

    public bindViewModel(workOrderId: string, id: any, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.id = id;
        this.workOrderId = workOrderId;
    }
}
