define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getEquipmentTypeForCompany: function (filter, page) {
            var url = apiHelper.getBaseUrl() + '/equipmentTypes';
            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        }
    };

    return exports;
});
