import { inject, transient, bindable, bindingMode, PLATFORM } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { PickerBase } from "./picker-base";

@transient()
@inject(I18N)
export class ContractPicker extends PickerBase {
    @bindable public ajax: any = null;

    @bindable public selectedContractType: string = "";
    public contractTypes: any[] = ["Contract", "Client", "Call"];
    public contractNames!: any[];

    public templateResult: any = PLATFORM.moduleName(
        "pages/templates/maSelectTemplates/AU_contractPicker_result.html");

    constructor(i18n: I18N) {
        super(i18n);
        
        this.contractNames = [
            i18n.tr("Contract"),
            i18n.tr("Customer"),
            i18n.tr("Call")
        ];
    }
}
