import { inject, transient, bindable, bindingMode, PLATFORM } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { PickerBase } from "./picker-base";

@transient()
@inject(I18N)
export class SpecificationPicker extends PickerBase {
    @bindable public ajax: any = null;

    public templateResult: any = PLATFORM.moduleName(
        "pages/templates/maSelectTemplates/AU_specificationPicker_result.html");

    constructor(i18n: I18N) {
        super(i18n);
    }
}
