import GuidHelper from "helpers/guidHelper";
import { autoinject, bindable, transient } from "aurelia-framework";

import { default as _ } from "underscore";

@transient()
@autoinject
export class AutoShowAddButton {

    public VIEWPORT_BUFFER: number = 100;

    @bindable public text: string = "";
    @bindable public action: any = null;
    @bindable public href: string = "javascript:void(0);";
    public anchorId: any = this.guidHelper.createGuid();
    public show: boolean = false;

    constructor(private readonly guidHelper: GuidHelper) {
    }

    public attached(): any {
        this.updateDisplay();
        jQuery(window).on("resize", (this.updateDisplay));
    }

    public updateDisplay(): any {
        const element: any = document.getElementById(this.anchorId);

        if (element) {
            const JQueryElement: any = jQuery(element);
            const btnOffsetTop: number = JQueryElement.offset().top;
            const viewportHeight: number = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

            if (!this.show) {
                this.show = false;
            }

            this.show = btnOffsetTop > viewportHeight + this.VIEWPORT_BUFFER;
        }
    }
}
