// GENERATED FILE - DO NOT MODIFY
import { InvoicingProjectModel } from "api/models/company/project/invoicing-project-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceWorkOrderProjectProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetProjects(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectBaseModel[] | null> {
        const uri = this.buildUri(
            "/services/workorders/projects",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectBaseModel[] | null = await this.get<ProjectBaseModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectBaseModel");
        return data;
    }

    public async GetInvoicingProjects(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InvoicingProjectModel[] | null> {
        const uri = this.buildUri(
            "/services/workorders/invoicing/projects",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: InvoicingProjectModel[] | null = await this.get<InvoicingProjectModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.InvoicingProjectModel");
        return data;
    }
}
