// GENERATED FILE - DO NOT MODIFY
import { LocalizationModel } from "api/models/company/dispatch/localization-model";
import { TimeKeepingModel } from "api/models/company/time-keeping-model";
import { TimeKeepingResponseModel } from "api/models/company/time-keeping-response-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class TimeKeepingProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetCurrentActiveTimeKeeping(requestConfig?: HttpClientRequestConfig): Promise<TimeKeepingResponseModel | null> {
        const uri = this.buildUri(
            "/time-keeping",
            null,
            null
        );

        const data: TimeKeepingResponseModel | null = await this.get<TimeKeepingResponseModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.TimeKeepingResponseModel");
        return data;
    }

    public async GetTimeKeeping(id: number, requestConfig?: HttpClientRequestConfig): Promise<TimeKeepingModel | null> {
        const uri = this.buildUri(
            "/time-keeping/{id}",
            { id: (id === null || id === undefined) ? undefined : id.toString() },
            null
        );

        const data: TimeKeepingModel | null = await this.get<TimeKeepingModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.TimeKeepingModel");
        return data;
    }

    public async CreateTimeKeeping(localization: LocalizationModel | null, status: string | null, requestConfig?: HttpClientRequestConfig): Promise<TimeKeepingModel | null> {
        const uri = this.buildUri(
            "/time-keeping",
            null,
            { status: (status === null || status === undefined) ? undefined : status }
        );

        const data: TimeKeepingModel | null = await this.post<LocalizationModel | null, TimeKeepingModel | null>(uri, localization, requestConfig);
        this.transformModels(data, "Company.TimeKeepingModel");
        return data;
    }

    public async UpdateTimeKeeping(timeKeeping: TimeKeepingModel | null, requestConfig?: HttpClientRequestConfig): Promise<TimeKeepingModel | null> {
        const uri = this.buildUri(
            "/time-keeping/update",
            null,
            null
        );

        const data: TimeKeepingModel | null = await this.post<TimeKeepingModel | null, TimeKeepingModel | null>(uri, timeKeeping, requestConfig);
        this.transformModels(data, "Company.TimeKeepingModel");
        return data;
    }

    public async DidNotWorking(requestConfig?: HttpClientRequestConfig): Promise<TimeKeepingModel | null> {
        const uri = this.buildUri(
            "/time-keeping/did-not-work",
            null,
            null
        );

        const data: TimeKeepingModel | null = await this.post<void, TimeKeepingModel | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.TimeKeepingModel");
        return data;
    }

    public async NextDay(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/time-keeping/next-day",
            null,
            null
        );

        const data: boolean = await this.post<void, boolean>(uri, undefined, requestConfig);
        return data;
    }
}
