/**
 * Converts from a number of hours (ie: 5) to a time string in the format HH:mm
 */
export class HoursNumberToTimeValueConverter {
    public static timeRegex: RegExp = /^(([01][0-9])|([2][0-3]))\:[0-5][0-9]$/i;

    /**
     * 5.5 --> "05:30"
     * @param value
     */
    public toView(value: any): string {
        if (value === null || value === undefined || typeof value !== "number") {
            return "00:00";
        }

        value = Math.min(23.99, Math.abs(value));

        const hours = Math.floor(value);
        const minutes = Math.min(59, Math.round((value - hours) * 60));
        const hoursString = hours.toString().padStart(2, "0");
        const minutesString = minutes.toString().padStart(2, "0");

        return `${hoursString}:${minutesString}`;
    }

    /**
     * "05:30" --> 5.5
     * @param value
     */
    public fromView(value: any): number {
        if (value === null || value === undefined || typeof value !== "string" || !HoursNumberToTimeValueConverter.timeRegex.test(value)) {
            return 0;
        }

        const hours = Number(value.substr(0, 2));
        const minutes = Number(value.substr(3, 2));

        return hours + minutes / 60;
    }
}
