// GENERATED FILE - DO NOT MODIFY
import { InspectionHeaderModel } from "api/models/company/inspection/inspection-header-model";
import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class InspectionProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetInspection(inspectionId: number, requestConfig?: HttpClientRequestConfig): Promise<InspectionModel | null> {
        const uri = this.buildUri(
            "/inspections/{inspectionId}",
            { inspectionId: (inspectionId === null || inspectionId === undefined) ? undefined : inspectionId.toString() },
            null
        );

        const data: InspectionModel | null = await this.get<InspectionModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Inspection.InspectionModel");
        return data;
    }

    public async DeleteInspection(inspectionId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/inspections/{inspectionId}",
            { inspectionId: (inspectionId === null || inspectionId === undefined) ? undefined : inspectionId.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async GetInspections(onlyClosed: boolean, dispatchId: number | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InspectionHeaderModel[] | null> {
        const uri = this.buildUri(
            "/inspections",
            null,
            { onlyClosed: (onlyClosed === null || onlyClosed === undefined) ? undefined : onlyClosed.toString(), dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: InspectionHeaderModel[] | null = await this.get<InspectionHeaderModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Inspection.InspectionHeaderModel");
        return data;
    }

    public async SaveInspection(inspection: InspectionModel | null, requestConfig?: HttpClientRequestConfig): Promise<InspectionModel | null> {
        const uri = this.buildUri(
            "/inspections",
            null,
            null
        );

        const data: InspectionModel | null = await this.post<InspectionModel | null, InspectionModel | null>(uri, inspection, requestConfig);
        this.transformModels(data, "Company.Inspection.InspectionModel");
        return data;
    }

    public async GetNewInspection(inspectionContext: number, dispatchId: number | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<InspectionModel | null> {
        const uri = this.buildUri(
            "/inspections/new",
            null,
            { inspectionContext: (inspectionContext === null || inspectionContext === undefined) ? undefined : inspectionContext.toString(), dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() }
        );

        const data: InspectionModel | null = await this.get<InspectionModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Inspection.InspectionModel");
        return data;
    }

    public async Complete(inspectionId: number, visitNumber: number | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/inspections/{inspectionId}/complete",
            { inspectionId: (inspectionId === null || inspectionId === undefined) ? undefined : inspectionId.toString() },
            { visitNumber: (visitNumber === null || visitNumber === undefined) ? undefined : visitNumber.toString() }
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }
}
