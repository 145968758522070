import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class DailyEntryEmployee {

    public dispatchProjectCode: string = "";
    public dispatchId: number = 0;
    public dispatchDate: string = "";
    public readonly: boolean = false;
    public dipatchId: number = 0;
    public employeeId: number = 0;
    public employee: any;
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N) {}

    public bindViewModel(dispatchProjectCode: string, dispatchDate: string, employeeId: number, dispatchId: number, querystring: string): any {
        const params = routerHelper.getQuerystring(querystring);
        this.isProjectResponsible = (params && params.isProjectResponsible === "true") || false;
        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
        this.dispatchId = dispatchId;
        this.employeeId = employeeId;
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.employeeId, params.dispatchId, params.q);
    }

    public navigateToActivities(): void {
        let url = routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntryEmployee", this.dispatchProjectCode, this.dispatchDate, this.dispatchId);
        url += routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly });
        routerHelper.navigate(url);
    }

}
