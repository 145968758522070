import documentService from "services/documentService";
import settingRepository from "repositories/settingRepository";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { autoinject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class MaterialOrderDocumentAdd extends FormBase {
    public workOrderId?: string;

    public readonly: boolean = false;
    public actions: any = {};
    public isCategorySelectionAllowed: boolean = false;
    public isDirty: boolean = false;
    private dispatchTemplateId: string | null = "";

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly dispatchTemplateService: DispatchTemplateService
    ) {
        super(notificationHelper, i18n, router);
    }

    public async activate(params: any): Promise<void> {
        this.dispatchTemplateId = settingRepository.getDispatchTemplate();
        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(this.dispatchTemplateId!);

        this.isCategorySelectionAllowed = dispatchTemplate!.AllowCategorySelection;

        this.setupHeaderInfos(decodeURIComponent(params.workOrderId));

        await this.setAction(params.orderId);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    private setupHeaderInfos(dispatchId: string): void {
      this.readonly = false;
      this.workOrderId = dispatchId;
    }

    private async setAction(orderId: string): Promise<void> {
      this.actions = {
          save: documentService.addDocumentByPurchaseOrderId.bind(self, orderId, this.dispatchTemplateId)
        };
    }
}
