import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { PrimeContractLoaderEntity } from "api/models/procore/loaders/prime-contract-loader-entity";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { DateFormatValueConverter } from "converters/format/date-format";
import { ProcoreGridPrimeContractItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-prime-contract-item";
import { ProcoreGridItemTransformer } from "./procore-grid-item-transformer";

@autoinject
export class PrimeContractTransformer {
  constructor(private readonly i18n: I18N, private readonly procoreGridItemTransformer: ProcoreGridItemTransformer) {

  }

  public transform(result: PrimeContractLoaderEntity | null): ProcoreGridPrimeContractItem | null {
    if (!result) {
      return null;
    }

    const language = localStorage.getItem("maestro_user_profile_LANGUAGE");
    const errors = this.procoreGridItemTransformer.transformErrors(result.Errors, language);
    const warnings = this.procoreGridItemTransformer.transformErrors(result.Warnings, language);

    return {
      ContractTitle: result.ContractTitle ? result.ContractTitle : "",
      StartDate: this.shortDateFormatter(result.StartDate),

      EntityToSynchronize: ProcoreEntityToSynchronize.PrimeContracts,
      Selected: false,
      ErrorsOpen:  false,
      IsSynchronizing: false,
      IsDetails: true,
      Target: result.Target,
      Code: result.Code,
      Description: result.Description,
      AdditionalField: "",
      Message: "",
      Errors: errors,
      Warnings: warnings,
      Children: [],
      Details:  [],
      DetailsOpen: false,
      DetailsIsLoading: false,
      Status: result.Status,
      IsSynchronized: result.IsSynchronized,
      IsPccoChangeOrder: false,
      IsCommandContractTransaction: false,

      SynchronizationTried: false,
    };
  }

  private shortDateFormatter(date: Date | null): any {
    if (!date) {
      return null;
    }

    const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);

    return dateFormatValueConverter.toView(date, "L");
  }
}
