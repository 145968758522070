import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";
import notificationHelper from "helpers/notificationHelper";

import serviceService from "services/serviceService";

import { ServiceDispatchType } from "enums/service-dispatch-type";

import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { MaCardActionModel, MaCardLineModel, MaCardOptionsModel, MaCardSplitDisplayModel } from "components/layout/ma-card/ma-card-options.model";

@autoinject()
export class ServiceLabor {
    public serviceType: string = "";
    public dispatchId: number = -1;
    public fromHistory: boolean = false;
    public readonly: boolean = false;
    public estimatedTime: string = "";
    public timesheetTotalTime: string = "";
    public list: any;
    public cards: MaCardOptionsModel[] = [];

    constructor(private readonly i18n: I18N) {}

    public activate(params: any): any {
        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
        this.loadData();
    }

    public bindViewModel(serviceType: string, dispatchId: number, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.fromHistory = queryStringHelper.parse(querystring, "fromHistory") === "true";

        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
    }

    public async loadData(): Promise<void> {
        await serviceService.getLabors(this.serviceType, this.dispatchId, this.fromHistory).then((result: any) => this.setDetail(result));
    }

    public setDetail(data: any): void {
        this.list = data.Timesheets;
        this.cards = this.createCards(this.list);

        this.timesheetTotalTime = dateHelper.hoursTohmm(data.TimesheetTotalTime);
        this.estimatedTime = dateHelper.hoursTohmm(data.EstimatedTime);
    }

    public createCards(list: any): MaCardOptionsModel[] {
        const cards: MaCardOptionsModel[] = [];
        list.forEach((timesheet: any) => {
            const card = new MaCardOptionsModel({
                model: timesheet,
                class: "ma-card-link",
                cardBodyClass: "py-2",
                action: this.gotoPage.bind(this, timesheet),
                id: "card_" + timesheet.LineNo,
                displayLines: this.getDisplayLines(timesheet),

                actionItems: [new MaCardActionModel({ id: "open", icon: "fa fa-chevron-right", action: this.gotoPage.bind(this, timesheet)})]
            });

            if (!this.readonly && !timesheet.IsReadonly) {
                card.actionItems!.push(new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-4", action: this.delete.bind(this, timesheet)}));
            }

            cards.push(card);
        });
        return cards;
    }

    public getDisplayLines(timesheet: any): MaCardLineModel[] {
        const displayLines: MaCardLineModel[] = [];

        displayLines.push(new MaCardLineModel({
            class: "text-primary font-weight-bold",
            splitDisplays: this.getHeaderDisplay(timesheet)
        }));

        displayLines.push(new MaCardLineModel({
            class: "pt-1",
            splitDisplays: this.getActivityDisplay(timesheet)
        }));

        if (timesheet.IsEquipment && this.serviceType === ServiceDispatchType.Workorder) {
            displayLines.push(new MaCardLineModel({
                splitDisplays: this.getEquipmentGroupDisplay(timesheet)
            }));
        }

        if (this.serviceType !== ServiceDispatchType.Service) {
            timesheet.Bonus.forEach((bonus: any) => {
                if (bonus.Type === 1) {
                    displayLines.push(new MaCardLineModel({
                        class: "ml-2",
                        splitDisplays: this.getBonusDisplay(bonus)
                    }));
                } else {
                    displayLines.push(new MaCardLineModel({
                        class: "font-bold ml-2",
                        display: bonus.Description
                    }));
                }
            });
        }

        if (timesheet.IsEmployee) {
            displayLines.push(new MaCardLineModel({
                splitDisplays: [
                    new MaCardSplitDisplayModel({class: "font-bold", display: this.i18n.tr("Shift:")}),
                    new MaCardSplitDisplayModel({display: timesheet.Shift})]
            }));
        }

        displayLines.push(new MaCardLineModel({
            display: timesheet.Comment
        }));

        if (timesheet.IsEmployee && timesheet.Employee !== null) {
            displayLines.push(new MaCardLineModel({
                class: "font-bold",
                display: `${timesheet.Employee.FirstName} ${timesheet.Employee.LastName}`
            }));
        }
        if (timesheet.IsEquipment) {
            displayLines.push(new MaCardLineModel({
                class: "font-bold",
                display: `${timesheet.EquipmentCode} ${timesheet.EquipmentDesc}`
            }));
        }

        if (timesheet.SpecialEquipments.length > 0) {
            displayLines.push(new MaCardLineModel({
                    class: "border-blue-left ml-3 pl-1",
                    splitDisplays: this.getSpecialEquipmentDisplay(timesheet)
            }));
        }

        return displayLines;
    }

    public getSpecialEquipmentDisplay(timesheet: any): MaCardSplitDisplayModel[] {
        return timesheet.SpecialEquipments.map((specialEquipment: EquipmentModel) =>
            new MaCardSplitDisplayModel({
                class: "d-block LinkedEquipments",
                icon: "fa fa-link disabled",
                display: `${specialEquipment.Code} ${specialEquipment.Description}`
            })
        );
    }

    public getHeaderDisplay(timesheet: any): MaCardSplitDisplayModel[] {
        const headerDisplay: MaCardSplitDisplayModel[] = [];
        if (timesheet.Bonus === "" || ServiceDispatchType.Workorder) {
            if (timesheet.IsEmployee) {
                headerDisplay.push(new MaCardSplitDisplayModel({
                    display: dateHelper.hoursTohmm(timesheet.WorkTime),
                    showEndSplitter: true
                }));
            } else {
                headerDisplay.push(new MaCardSplitDisplayModel({
                    display: dateHelper.hoursTohmm(timesheet.EquipmentWorkTime),
                    showEndSplitter: true
                }));
            }
        }
        if (timesheet.Bonus !== "" && this.serviceType === ServiceDispatchType.Service) {
            headerDisplay.push(new MaCardSplitDisplayModel({
                display: `${timesheet.Bonus} - ${timesheet.BonusDesc} : ${timesheet.WorkTime} ${timesheet.BonusUnit}`
            }));
        }

        if (timesheet.IsEmployee) {
            headerDisplay.push(new MaCardSplitDisplayModel({
                display: labelHelper.getWageTypeLabel(timesheet.WageType)
            }));
        }

        return headerDisplay;
    }

    public getActivityDisplay(timesheet: any): MaCardSplitDisplayModel[] {
        const headerDisplay: MaCardSplitDisplayModel[] = [];

        headerDisplay.push(new MaCardSplitDisplayModel({
            class: "font-bold",
            display:  this.serviceType === ServiceDispatchType.Service ? this.i18n.tr("Activity:") : this.i18n.tr("ExpenseActivity:"),
        }));

        if (timesheet.ActivityCode && !timesheet.EquipmentActivityCode) {
            headerDisplay.push(new MaCardSplitDisplayModel({
                display: `${timesheet.ActivityCode} - ${timesheet.ActivityDesc}`,
            }));
        }
        if (timesheet.EquipmentActivityCode) {
            headerDisplay.push(new MaCardSplitDisplayModel({
                display: `${timesheet.EquipmentActivityCode} - ${timesheet.EquipmentActivityDesc}`,
            }));
        }

        return headerDisplay;
    }

    public getEquipmentGroupDisplay(timesheet: any): MaCardSplitDisplayModel[]  {
        const headerDisplay: MaCardSplitDisplayModel[] = [];

        headerDisplay.push(new MaCardSplitDisplayModel({
            class: "font-bold",
            display: this.i18n.tr("Group:"),
        }));

        if (timesheet.EquipmentGroupCode) {
            headerDisplay.push(new MaCardSplitDisplayModel({
                display: `${timesheet.EquipmentGroupCode} - ${timesheet.EquipmentGroupDesc}`,
            }));
        }

        return headerDisplay;
    }

    public getBonusDisplay(bonus: any): MaCardSplitDisplayModel[] {
        const headerDisplay: MaCardSplitDisplayModel[] = [];

        headerDisplay.push(new MaCardSplitDisplayModel({
            class: "font-bold",
            display: `${bonus.Description}:`,
        }));
        headerDisplay.push(new MaCardSplitDisplayModel({
            display: `${bonus.Value} ${bonus.Unit ? bonus.Unit : ""}`,
        }));

        return headerDisplay;
    }

    public async delete(item: any): Promise<void> {
        notificationHelper.showDeleteConfirmation().then(async (success: boolean) => {
            if (success) {
                await serviceService.deleteLabor(this.serviceType, this.dispatchId, item.LineNo, item.Timestamp)
                    .then((data: any) => {
                        this.setDetail(data);
                    },
                    async () => {
                        await this.loadData();
                    });
            }
        });
    }

    public gotoPage(labor: any): void {
        let url: string = "";
        let type = "time";

        if (labor && labor.LineNo) {
            // Edit existing
            url = routerHelper.getRelativeUrl("edit", labor.LineNo);
            type = labor.IsSelf ? "time" : "labor";
        } else {
            // Create new
            url = routerHelper.getRelativeUrl("edit");
        }
        routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: this.readonly, type: type, fromHistory: this.fromHistory }));
    }

    public addLabor(): void {
        const url = routerHelper.getRelativeUrl("edit");
        routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: this.readonly, type: "labor" }));
    }

}
