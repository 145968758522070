export class ScrollHelper {
  public fixTop(htmlElement: HTMLElement): void {
    if (htmlElement === undefined || htmlElement == null) {
      return;
    }

    document.addEventListener("scroll", () => {
      const fixedClass = "element-fixed";

      const currentPosition = htmlElement.getBoundingClientRect() as any;

      if (window.scrollY - 100 > currentPosition.y) {
        htmlElement!.classList.add(fixedClass);
      } else {
        htmlElement!.classList.remove(fixedClass);
      }
    });
  }
}
