import { SmsContactModel } from "api/models/company/contact/sms-contact-model";
import { SmsMessageModel } from "api/models/company/s-m-s/sms-message-model";
import { SmsResponseModel } from "api/models/company/s-m-s/sms-response-model";

export class MaMessageOptionsModel {

    public getContactsService!: () => Promise<SmsContactModel[]>;
    public sendMessageService!: (contacts: SmsContactModel[], message: string) => Promise<SmsResponseModel | null>;
    public goToContactProfile!: () => any;

    constructor(maMessageOptionsModel?: MaMessageOptionsModel) {
        Object.assign(this, maMessageOptionsModel);
    }
}
