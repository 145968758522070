import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { GroupProxy } from "../api/proxies/group-proxy";
import { ProjectGroupModel } from "api/models/company/project/project-group-model";

@autoinject()
export class GroupService {

    constructor(private readonly groupProxy: GroupProxy) {
    }

    public async GetGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        return this.groupProxy.GetGroups(filter, pagingInfo, requestConfig);
    }

    public async GetExpenseGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {

        const groups = await this.groupProxy.GetServiceCallPurchaseOrderGroups(filter, pagingInfo, requestConfig);

        if (!groups) {
            return [];
        }

        return groups.map((item: any): any => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.Description}`,
                data: item
            };
        });
    }

    public async GetProjectGroups(projectCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        return this.groupProxy.GetProjectGroups(projectCode, filter, pagingInfo, requestConfig);
    }
}
