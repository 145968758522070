// GENERATED FILE - DO NOT MODIFY
import { CreationSource } from "api/enums/creation-source";
import { ServiceCallQuotationSortOrder } from "api/enums/service-call-quotation-sort-order";
import { ClientModel } from "api/models/company/client/client-model";
import { DocumentModel } from "api/models/company/document/document-model";
import { ServiceCallQuotationCustomerProjetContractModel } from "api/models/company/service-call/service-call-quotation-customer-projet-contract-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { ServiceCallQuotationSaveModel } from "api/models/company/service-call/service-call-quotation-save-model";
import { ServiceCallQuotationUserValidationModel } from "api/models/company/service-call/service-call-quotation-user-validation-model";
import { UserGridStateModel } from "api/models/company/user-grid-state-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallQuotationProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetQuotations(sortOrder: ServiceCallQuotationSortOrder | undefined, sourceServiceCallId: number | null | undefined, statuses: Array<string | null> | null | undefined, excludedStatuses: Array<string | null> | null | undefined, currentPrefixOnly: boolean | undefined, currentUserOnly: boolean | undefined, sourceWorkOrderId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationDetailsModel[] | null> {
        const uri = this.buildUri(
            "/service-call-quotations",
            null,
            { sortOrder: (sortOrder === null || sortOrder === undefined) ? undefined : sortOrder, sourceServiceCallId: (sourceServiceCallId === null || sourceServiceCallId === undefined) ? undefined : sourceServiceCallId.toString(), statuses: (statuses === null || statuses === undefined) ? undefined : statuses, excludedStatuses: (excludedStatuses === null || excludedStatuses === undefined) ? undefined : excludedStatuses, currentPrefixOnly: (currentPrefixOnly === null || currentPrefixOnly === undefined) ? undefined : currentPrefixOnly.toString(), currentUserOnly: (currentUserOnly === null || currentUserOnly === undefined) ? undefined : currentUserOnly.toString(), sourceWorkOrderId: (sourceWorkOrderId === null || sourceWorkOrderId === undefined) ? undefined : sourceWorkOrderId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallQuotationDetailsModel[] | null = await this.get<ServiceCallQuotationDetailsModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationDetailsModel");
        return data;
    }

    public async GetQuotation(quotationId: number, includeItems: boolean | undefined, includeEquipments: boolean | undefined, includeDocuments: boolean | undefined, includeSignatureDocuments: boolean | undefined, useCustomerLanguage: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationDetailsModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            { includeItems: (includeItems === null || includeItems === undefined) ? undefined : includeItems.toString(), includeEquipments: (includeEquipments === null || includeEquipments === undefined) ? undefined : includeEquipments.toString(), includeDocuments: (includeDocuments === null || includeDocuments === undefined) ? undefined : includeDocuments.toString(), includeSignatureDocuments: (includeSignatureDocuments === null || includeSignatureDocuments === undefined) ? undefined : includeSignatureDocuments.toString(), useCustomerLanguage: (useCustomerLanguage === null || useCustomerLanguage === undefined) ? undefined : useCustomerLanguage.toString() }
        );

        const data: ServiceCallQuotationDetailsModel | null = await this.get<ServiceCallQuotationDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationDetailsModel");
        return data;
    }

    public async GetQuotationItem(quotationItemId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationItemModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations-items/{quotationItemId}",
            { quotationItemId: (quotationItemId === null || quotationItemId === undefined) ? undefined : quotationItemId.toString() },
            null
        );

        const data: ServiceCallQuotationItemModel | null = await this.get<ServiceCallQuotationItemModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationItemModel");
        return data;
    }

    public async DeleteQuotationItem(quotationItemId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations-items/{quotationItemId}",
            { quotationItemId: (quotationItemId === null || quotationItemId === undefined) ? undefined : quotationItemId.toString() },
            null
        );

        await this.delete<void>(uri, requestConfig);
    }

    public async SaveQuotationItem(quotationItem: ServiceCallQuotationItemModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations-items",
            null,
            null
        );

        await this.post<ServiceCallQuotationItemModel | null, void>(uri, quotationItem, requestConfig);
    }

    public async SaveQuotationItems(quotationItems: Array<ServiceCallQuotationItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations-items/items",
            null,
            null
        );

        await this.post<Array<ServiceCallQuotationItemModel | null> | null, void>(uri, quotationItems, requestConfig);
    }

    public async SaveQuotation(quotation: ServiceCallQuotationSaveModel | null, saveItems: boolean | undefined, saveEquipments: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/service-call-quotations",
            null,
            { saveItems: (saveItems === null || saveItems === undefined) ? undefined : saveItems.toString(), saveEquipments: (saveEquipments === null || saveEquipments === undefined) ? undefined : saveEquipments.toString() }
        );

        const data: number = await this.post<ServiceCallQuotationSaveModel | null, number>(uri, quotation, requestConfig);
        return data;
    }

    public async SaveWorkDescription(quotationId: number, workDescription: string | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/work-description",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        await this.post<string | null, void>(uri, workDescription, requestConfig);
    }

    public async SaveIsLockedQuotation(quotationId: number, isLockedQuotation: boolean, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/is-locked-quotation",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            { isLockedQuotation: (isLockedQuotation === null || isLockedQuotation === undefined) ? undefined : isLockedQuotation.toString() }
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async SavePricingType(quotationId: number, isLumpSum: string | null, lumpSum: number | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/pricing-type",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            { isLumpSum: (isLumpSum === null || isLumpSum === undefined) ? undefined : isLumpSum, lumpSum: (lumpSum === null || lumpSum === undefined) ? undefined : lumpSum.toString() }
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async DeleteQuotation(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        await this.delete<void>(uri, requestConfig);
    }

    public async PutQuotationOnHold(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/onhold",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async ResumeQuotation(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/resume",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async CompleteQuotation(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/complete",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async CompleteAndSubmitQuotation(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/complete-and-submit",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async CompleteAndAcceptQuotation(signature: DocumentModel | null, quotationId: number, contactName: string | null | undefined, contactEmail: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/complete-and-accept",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            { contactName: (contactName === null || contactName === undefined) ? undefined : contactName, contactEmail: (contactEmail === null || contactEmail === undefined) ? undefined : contactEmail }
        );

        await this.post<DocumentModel | null, void>(uri, signature, requestConfig);
    }

    public async RefuseQuotation(quotationId: number, refusalReason: string | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/refuse",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            { refusalReason: (refusalReason === null || refusalReason === undefined) ? undefined : refusalReason }
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async CreateNewQuotationDetails(status: string | null, creationSource: CreationSource, isWorkOrder: boolean, sourceServiceCallId: number | null | undefined, serviceCallContractEquipmentId: number | null | undefined, sourceDispatchId: number | null | undefined, sourceQuotationId: number | null | undefined, sourceWorkOrderId: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationDetailsModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations/create-new-quotation-details",
            null,
            { status: (status === null || status === undefined) ? undefined : status, creationSource: (creationSource === null || creationSource === undefined) ? undefined : creationSource, isWorkOrder: (isWorkOrder === null || isWorkOrder === undefined) ? undefined : isWorkOrder.toString(), sourceServiceCallId: (sourceServiceCallId === null || sourceServiceCallId === undefined) ? undefined : sourceServiceCallId.toString(), serviceCallContractEquipmentId: (serviceCallContractEquipmentId === null || serviceCallContractEquipmentId === undefined) ? undefined : serviceCallContractEquipmentId.toString(), sourceDispatchId: (sourceDispatchId === null || sourceDispatchId === undefined) ? undefined : sourceDispatchId.toString(), sourceQuotationId: (sourceQuotationId === null || sourceQuotationId === undefined) ? undefined : sourceQuotationId.toString(), sourceWorkOrderId: (sourceWorkOrderId === null || sourceWorkOrderId === undefined) ? undefined : sourceWorkOrderId }
        );

        const data: ServiceCallQuotationDetailsModel | null = await this.get<ServiceCallQuotationDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationDetailsModel");
        return data;
    }

    public async ServiceContractValidation(creationSource: CreationSource, dispatchId: number | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/service-call-quotations/service-contract-validation",
            null,
            { creationSource: (creationSource === null || creationSource === undefined) ? undefined : creationSource, dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() }
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async CreateQuotationItemLinkedItems(quotationId: number, contractId: string | null, catalogItemId: string | null, quantity: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationItemModel[] | null> {
        const uri = this.buildUri(
            "/service-call-quotations/create-new-quotation-item-linked-items",
            null,
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString(), contractId: (contractId === null || contractId === undefined) ? undefined : contractId, catalogItemId: (catalogItemId === null || catalogItemId === undefined) ? undefined : catalogItemId, quantity: (quantity === null || quantity === undefined) ? undefined : quantity.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallQuotationItemModel[] | null = await this.get<ServiceCallQuotationItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationItemModel");
        return data;
    }

    public async GetAvailableCustomersForQuotation(includeInactive: boolean, contractId: string | null | undefined, projectId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ClientModel[] | null> {
        const uri = this.buildUri(
            "/service-call-quotations/available-customers",
            null,
            { includeInactive: (includeInactive === null || includeInactive === undefined) ? undefined : includeInactive.toString(), contractId: (contractId === null || contractId === undefined) ? undefined : contractId, projectId: (projectId === null || projectId === undefined) ? undefined : projectId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ClientModel[] | null = await this.get<ClientModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }

    public async GetContractQuotationItems(contractId: string | null, customerId: string | null | undefined, isLumpSum: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationItemModel[] | null> {
        const uri = this.buildUri(
            "/service-call-quotations/contract-quotation-items",
            null,
            { contractId: (contractId === null || contractId === undefined) ? undefined : contractId, customerId: (customerId === null || customerId === undefined) ? undefined : customerId, isLumpSum: (isLumpSum === null || isLumpSum === undefined) ? undefined : isLumpSum, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallQuotationItemModel[] | null = await this.get<ServiceCallQuotationItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationItemModel");
        return data;
    }

    public async IsProjectInvoicing(workOrderId: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/service-call-quotations/invoicing/project",
            null,
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId }
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async GetSourceDispatchId(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/source-dispatch-id",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        const data: number = await this.get<number>(uri, requestConfig);
        return data;
    }

    public async GetUserGridStatusSelection(requestConfig?: HttpClientRequestConfig): Promise<UserGridStateModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations/current-user/grid-status-selection",
            null,
            null
        );

        const data: UserGridStateModel | null = await this.get<UserGridStateModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.UserGridStateModel");
        return data;
    }

    public async SetUserGridStatusSelection(statuses: Array<string | null> | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/current-user/grid-status-selection",
            null,
            { statuses: (statuses === null || statuses === undefined) ? undefined : statuses }
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async GetServiceQuotationCustomerProjetContractInfos(quotationId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationCustomerProjetContractModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations/creation-customer-project-contract/{quotationId}",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            null
        );

        const data: ServiceCallQuotationCustomerProjetContractModel | null = await this.get<ServiceCallQuotationCustomerProjetContractModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationCustomerProjetContractModel");
        return data;
    }

    public async CreateServiceQuotationCustomerProjetContract(model: ServiceCallQuotationCustomerProjetContractModel | null, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationDetailsModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations/creation-customer-project-contract",
            null,
            null
        );

        const data: ServiceCallQuotationDetailsModel | null = await this.post<ServiceCallQuotationCustomerProjetContractModel | null, ServiceCallQuotationDetailsModel | null>(uri, model, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationDetailsModel");
        return data;
    }

    public async UserQuotationValidation(requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationUserValidationModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations/user-validation",
            null,
            null
        );

        const data: ServiceCallQuotationUserValidationModel | null = await this.get<ServiceCallQuotationUserValidationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationUserValidationModel");
        return data;
    }

    public async CheckIfRequiredAdditionalFieldsAreEntered(additionalfields: Array<any | null> | null, forMobile: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/service-call-quotations/all-required-additionalfields-entered",
            null,
            { forMobile: (forMobile === null || forMobile === undefined) ? undefined : forMobile.toString() }
        );

        const data: boolean = await this.post<Array<any | null> | null, boolean>(uri, additionalfields, requestConfig);
        return data;
    }
}
