define([
        "services/requests",
        "helpers/apiHelper",
        "services/defaultService"
],
    function (requests, apiHelper, defaultService) {
        "use strict";

        var exports = {
            getByDispach: function (dispatchId) {
                return requests.getJson(apiHelper.getBaseUrl() + "/dispatch/projects/dispatch/" + dispatchId + "/timeentries");
            },

            getById: function (timeEntryId) {
                return requests.getJson(apiHelper.getBaseUrl() + "/dispatch/projects/timeentries/" + timeEntryId);
            },

            getDefaultTimes: function (dispatchProjectCode, dispatchDate, dispatchIds, templateCode) {
                if (dispatchIds) {
                    if (!templateCode) {
                        templateCode = defaultService.getCurrentDispatchTemplateCode();
                    }
                   
                    return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/defaulttimes?dispatchIds=" + encodeURIComponent(dispatchIds.join(",")) + "&dispatchTemplateId=" + templateCode);
                } else {
                    var dfd = jQuery.Deferred();
                    dfd.resolve(new Date());
                    return dfd;
                }
            },

            getDefaultShift: function (dispatchProjectCode) {
                return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/default-shift"); 
            },

            deleteTimeEntry: function (timeEntryId) {
                return requests.remove(apiHelper.getBaseUrl() + "/dispatch/projects/timeentries/" + timeEntryId);
            },

            setTimeEntries: function (data, forceSave, dispatchTemplateId, dispatchProjectCode, dispatchDate) {
                return requests.post(apiHelper.getBaseUrl() + "/dispatch/projects/timeentries/post?dispatchProjectCode=" + dispatchProjectCode + "&dispatchDate=" + dispatchDate + "&forceSave=" + forceSave + (dispatchTemplateId ? `&dispatchTemplateId=${dispatchTemplateId}` : ""), data)
                    .promise();
            },

            getAllDailyEntryLinkedEquipment: function (dispatchId) {
                return requests.getJson(apiHelper.getBaseUrl() + "/dispatch/projects/dispatches/" + dispatchId + "/all-linked-equipment");
            },
        };

        return exports;
    });