import { transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

@transient()
export class Tab {
    @bindable.string
    public name: string = "";

    @bindable
    public tabId: any = "";

    @bindable.booleanAttr
    public selected: boolean = false;

    @bindable
    public isVisible: boolean = true;
}
