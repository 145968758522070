// GENERATED FILE - DO NOT MODIFY
export enum ShopDrawingStatus {
    ToDo = "1",
    Returned = "2",
    Annotated = "3",
    Complete = "4",
    Denied = "5",
    ForInfo = "6",
    Submitted = "7",
    ClientApprouved = "8",
    Approuved = "9",
    SentToSupplier = "A",
    Finished = "B",
}

export let nameof = "ShopDrawingStatus";
