import _ from "underscore";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";
import notificationHelper from "helpers/notificationHelper";
import queryStringHelper from "helpers/queryStringHelper";
import requisitionService from "services/requisitionService";
import routerHelper from "helpers/routerHelper";

@autoinject
export class DailyEntryLaborRequisition {

    public dispatchProjectCode: any;
    public dispatchDate: any;

    public isProjectResponsible: boolean = false;
    public requisitions: any[] = [];

    constructor(private readonly i18n: I18N) {
    }

    public async activate(params: any): Promise<any> {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        await this.loadData();
    }

    public async loadData(): Promise<any> {
        this.mapRequisitions(await requisitionService.getRequisitionsForDispatch(this.dispatchProjectCode));
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, queryString: string): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
    }

    public mapRequisitions(data: any): void {
        this.requisitions = data.map((item: any) => {
            return {
                id: item.Id,
                title: `${item.ResourceCode} - ${item.ResourceName} (${item.Quantity})`,
                comment: item.Comment,
                dateRequired: `${this.i18n.tr("RequiredOn")} ${dateHelper.getTextDate(item.RequestDate)}`,
            };
        });
    }

    public async deleteRequisition(requisition: any): Promise<any> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await requisitionService.deleteRequisition(this.dispatchProjectCode, requisition.id);
            this.loadData();
        }
    }

    public gotoPage(rel: string): void {
        routerHelper.navigate(routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible }));
    }
}
