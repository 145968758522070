import logger from "core/logger";
import config from "config";

export default class LoggerHelper {
    static throwOrLog(...args) {
        if (config.debug === true) {
            throw new Error(args);
        }
        // Do not throw error in production
        logger.warn(args);
    }
}