import { ClientModel } from "api/models/company/client/client-model";
import { ClientProxy } from "api/proxies/client-proxy";
import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class CustomerService {
    constructor(private readonly customerProxy: ClientProxy) {}

    public async getClients(includeInactive: boolean, filter?: string, pagingInfo?: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<ClientModel[] | null> {
        return await this.customerProxy.GetClients(includeInactive, filter, pagingInfo, httpClientRequestConfig);
    }
}
