import "select2";

import _ from "underscore";
import { autoinject, bindingMode, observable, transient, bindable } from "aurelia-framework";

import { default as labelHelper } from "helpers/labelHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as payPeriodService } from "services/payPeriodService";
import { default as defaultService } from "services/defaultService";

import { PayPeriodModel } from "api/models/company/pay-period-model";

@transient()
@autoinject()
export class PayPeriodPicker {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public selectedItem: any;
    @observable public selectedId: number = 0;
    public disabled: boolean = false;
    public allowClear: boolean = false;
    public payPeriodsList: any[] = [];

    public language: string = labelHelper.getDefaultSelect2Labels();
    public minimumResultsForSearch: number = defaultService.getMinimumResultsForSearch();

    public async bind(): Promise<any> {
        await this.loadPayPeriodsList().then(() => {
            this.selectedItem = this.payPeriodsList[0];
        });
    }

    public selectedIdChanged(index: number): any {
        if (index && this.payPeriodsList.length > 0) {
            this.selectedItem = this.payPeriodsList[index];
        }
    }

    private async loadPayPeriodsList(): Promise<void> {
        const result = await payPeriodService.get();

        this.payPeriodsList = _.map(result, (item: PayPeriodModel, index: number): any => {
            return { id: index.toString(), text: dateHelper.getTextDate(item.StartDate), item: item };
        });
     }
}
