import { autoinject } from "aurelia-framework";
import { customElement } from "aurelia-templating";
import { Router } from "aurelia-router";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import { Button } from "components/buttons/button";
import { ViewModeHelper } from "helpers/view-mode-helper";
import Mousetrap from "mousetrap";
import "mousetrap/plugins/global-bind/mousetrap-global-bind";
import { KeyboardShortcutsHelper } from "helpers/keyboard-shortcuts-helper";
import { CustomEventHelper } from "helpers/custom-event-helper";

@autoinject()
@customElement("save-button")
export class SaveButton extends Button {

    @bindable.booleanAttr
    public showIcon: boolean = false;

    @bindable.booleanAttr
    public bindSaveShortcutKey: boolean = false;

    @bindable.booleanAttr
    public disabled: boolean = false;

    public elementRef!: HTMLElement;

    constructor(element: Element,
                router: Router,
                private readonly viewModeHelper: ViewModeHelper,
                private readonly keyboardShortcutsHelper: KeyboardShortcutsHelper) {
        super(element, router);

        this.elementRef = element as HTMLElement;
    }

    public attached(): void {
        if (this.viewModeHelper.getIsDesktopMode() && this.bindSaveShortcutKey) {
            this.setShortcutKey();
        }
    }

    public detached(): void {
        Mousetrap.unbind(this.keyboardShortcutsHelper.Shortcuts.Save);
    }

    private setShortcutKey(): void {
        Mousetrap.bindGlobal(this.keyboardShortcutsHelper.Shortcuts.Save, (): void => {
                 CustomEventHelper.dispatchEvent(this.elementRef, "click", true, true, true);
             });

    }

}
