import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import documentHelper from "helpers/documentHelper";

export class MaterialOrderDocument {
    public readonly: boolean = false;
    public dispatchProjectCode: number | null = null;
    public dispatchProjectDate: string | null = null;

    public actions: any = {};

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");

        this.setupHeaderInfos(params.dispatchProjectCode, paramsQueryString.dispatchProjectDate);
        this.setAction(params.orderId);
    }

    private setupHeaderInfos(projectCode: number, date: string): void {
        this.dispatchProjectCode = projectCode;
        this.dispatchProjectDate = date;
    }

    private setAction(orderId: string): void {
        this.actions = {
            getSections: documentService.getSectionsByPurchaseOrderId.bind(self, orderId),
            getSectionDocuments: documentService.getDocumentsByPurchaseOrderId.bind(self, orderId),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService)
          };
    }
}
