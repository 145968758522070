import { MaCardOptionsModel } from "components/layout/ma-card/ma-card-options.model";
import RouteRepository from "repositories/routeRepository";

import { autoinject } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";
import { I18N } from "aurelia-i18n";
import { TimeKeepingService } from "pages/services/time-keeping-service";
import { TimeKeepingModel } from "api/models/company/time-keeping-model";
import { TimeKeepingStatus } from "api/enums/time-keeping-status";
import { TimeKeepingCardGenerator } from "helpers/time-keeping-card-generator";
import notificationHelper from "helpers/notificationHelper";
import { TimeKeepingResponseModel } from "api/models/company/time-keeping-response-model";
import routerHelper from "helpers/routerHelper";
import { StringHelper } from "helpers/string-helper";
import { GeolocationService } from "services/geolocation-service";
import { isBoolean } from "util";

@autoinject
export class TimeKeeping {

  public dateTitle: string = "";
  public dateTitleClass: string = "";
  public date: string = "";

  public punchIn?: TimeKeepingModel | null;
  public punchOut?: TimeKeepingModel | null;

  public firstCard?: MaCardOptionsModel | null;
  public secondCard?: MaCardOptionsModel | null;

  public didNotWork: boolean = false;

  constructor(private readonly routeRepository: RouteRepository,
              private readonly service: TimeKeepingService,
              private readonly cardGenerator: TimeKeepingCardGenerator,
              private readonly i18n: I18N, private readonly geolocationService: GeolocationService) { }

    public async canActivate(): Promise<boolean> {
        const result = await this.geolocationService.getCurrentLocation().catch((reason: any) => {
            if (StringHelper.startsWith(reason.message, "Only secure origins are allowed") || reason.message.indexOf("Access to geolocation was blocked over insecure connection") > -1) {
                notificationHelper.showWarning(this.i18n.tr("LocationRequireSSL"), "", { timeOut: 0 });
                return false;
            } else {
                notificationHelper.showWarning(this.i18n.tr("LocationDisabled"), "", { timeOut: 0 });
                return false;
            }
            });

        if (isBoolean(result)) {
            return result;
        } else {
            return true;
        }
    }

  public async attached(): Promise<void> {
    await this.load();
  }

  public async load(): Promise<void> {
    const data = await this.service.Load();

    this.setHeader(data!.PunchDate);

    this.clearPunches();
    this.setPunches(data);

    this.setCards();
  }

  public async setNextDay(): Promise<void> {
    const dialogMessage = this.i18n.tr("PunchInOut_NextDay_confirm_text");

    notificationHelper.showDialogYesNo(dialogMessage).then((success: boolean): any => {
        if (success) {
          this.service.SetNextDay()
                      .then(() => this.load());
        }
    });
  }

  public async didNotWorkClick(): Promise<void> {
    const dialogMessage = this.i18n.tr("PunchInOut_DidNotWork_confirm_text");

    notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<any> => {
        if (success) {
          await this.service.DidNotWork();
          await this.load();
        }
    });
  }

  public async punchClick(punch?: TimeKeepingModel | null, punchStatus?: TimeKeepingStatus): Promise<void> {
    if (!punch && !punchStatus) { return; }

    // Si le punch a déjà été créé, on va en édition
    if (this.goToSummaryIfPunchExists(punch)) { return; }

    if (!!punchStatus) {
      punch = await this.service.CreateTimeKeeping(punchStatus);
    }

    if (!punch) { return; }

    this.punchInClick(punch, punchStatus);
    this.punchOutClick(punch);

    this.goToSummaryIfDateDifferentFromToday(punch, punch.PunchDate);
  }

  private setHeader(punchDate: Date): void {
    if (!punchDate) {
      punchDate = new Date();
    }

    const date = new Date(punchDate);
    const longDay = dateHelper.getDayOfWeekString(date.getDay(), "dddd");
    const dateLongFormat = dateHelper.formatDateLongFormat(date);
    this.date = `${longDay}, ${dateLongFormat}`;

    const dateTitleResult = this.service.getDateTitleWithColorClass(punchDate);
    this.dateTitle = dateTitleResult.dateTitle;
    this.dateTitleClass = dateTitleResult.dateTitleClass;
  }

  private clearPunches(): void {
    this.punchIn = undefined;
    this.punchOut = undefined;
    this.didNotWork = false;
  }

  private setPunches(data: TimeKeepingResponseModel | null): void {
    if (!data || !data.Punches) { return; }

    this.punchIn = data.Punches.find((x: TimeKeepingModel) => x.PunchStatus === TimeKeepingStatus.In);
    this.punchOut = data.Punches.find((x: TimeKeepingModel) => x.PunchStatus === TimeKeepingStatus.Out);
    this.didNotWork = data.Punches.some((x: TimeKeepingModel) => x.PunchStatus === TimeKeepingStatus.DidNotWork);
  }

  private setCards(): void {
    if (this.tryGenerateDidNotWorkCard()) { return; }
    if (this.tryGenerateNoPunchCard()) { return; }
    if (this.tryGeneratePunchInRecorded()) { return; }
    if (this.tryGenerateBothPunchRecorded()) { return; }
  }

  private tryGenerateDidNotWorkCard(): boolean {
    if (this.didNotWork) {
      this.firstCard = this.cardGenerator.generateNotWorkingCard();
      return true;
    }

    return false;
  }

  private tryGenerateNoPunchCard(): boolean {
    if (!this.punchIn && !this.punchOut) {
      this.firstCard = this.cardGenerator.generateEmptyCard(TimeKeepingStatus.In, (): void => { this.punchClick(undefined, TimeKeepingStatus.In); });
      this.secondCard = undefined;

      return true;
    }

    return false;
  }

  private tryGeneratePunchInRecorded(): boolean {
    if (this.punchIn && !this.punchOut) {
      this.firstCard = this.generatePunchInCard();
      this.secondCard = this.cardGenerator.generateEmptyCard(TimeKeepingStatus.Out, (): void => { this.punchClick(undefined, TimeKeepingStatus.Out); });

      return true;
    }

    return false;
  }

  private tryGenerateBothPunchRecorded(): boolean {
    if (this.punchIn && this.punchOut) {
      this.firstCard =  this.generatePunchInCard();
      this.secondCard = this.generatePunchOutCard();
    }

    return false;
  }

  private punchInClick(punch: TimeKeepingModel, punchStatus?: TimeKeepingStatus): void {
    if (punchStatus === TimeKeepingStatus.In) {
      this.punchIn = punch;
      this.firstCard = this.generatePunchInCard();
      this.secondCard = this.cardGenerator.generateEmptyCard(TimeKeepingStatus.Out, (): void => { this.punchClick(undefined, TimeKeepingStatus.Out); });
    }
  }

  private punchOutClick(punch: TimeKeepingModel): void {
    if (punch.PunchStatus === TimeKeepingStatus.Out) {
      this.punchOut = punch;
      this.secondCard = this.generatePunchOutCard();
    }
  }

  private goToSummaryIfPunchExists(punch?: TimeKeepingModel | null): boolean {
    if (punch && punch.Id !== 0) {
      this.goToEdit(punch);
      return true;
    }

    return false;
  }

  private goToSummaryIfDateDifferentFromToday(punch: TimeKeepingModel, punchDate: Date): void {
    const today = new Date();
    if (punchDate.getDay() !== today.getDay()) {
      this.goToEdit(punch);
    }
  }

  private generatePunchInCard(): MaCardOptionsModel | null {
    if (!this.punchIn) { return null; }

    return this.cardGenerator.generateCard(this.punchIn, (): void => { this.punchClick(this.punchIn); });
  }

  private generatePunchOutCard(): MaCardOptionsModel | null {
    if (!this.punchOut) { return null; }

    return this.cardGenerator.generateCard(this.punchOut, (): void => { this.punchClick(this.punchOut); });
  }

  private goToEdit(punch: TimeKeepingModel): void {
    const parameters = {
      punchId: punch.Id,
      q: {}
    };

    let otherPunch;
    if (punch.PunchStatus === TimeKeepingStatus.In && this.punchOut) {
      otherPunch = this.punchOut.Id;
    } else if (punch.PunchStatus === TimeKeepingStatus.Out && this.punchIn) {
      otherPunch = this.punchIn.Id;
    }

    if (!!otherPunch) {
      parameters.q = routerHelper.buildQueryString({
        otherPunchId: otherPunch
      });
    }

    routerHelper.navigateToRoute(this.routeRepository.routes.TimeKeeping_Edit.name, parameters);
  }
}
