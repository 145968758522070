import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import documentHelper from "helpers/documentHelper";
import routerHelper from "helpers/routerHelper";
import { DocumentService } from "services/document-service";
import documentService from "services/documentService";

@autoinject
export class DailyEntryDocument {

    public readonly: boolean = false;
    public dispatchProjectCode: string = "";
    public dailyEntryDate: Date = new Date();
    public actions: any;

    constructor(private readonly i18n: I18N, private readonly documentServ: DocumentService) {

    }

    public activate(params: any): any {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dailyEntryDate = params.dailyEntryDate;

        this.actions = {
            getSections: documentService.getSectionsByDispatchProjectDate.bind(this, params.dispatchProjectCode, params.dailyEntryDate),
            getSectionDocuments: documentService.getDocumentsByDispatchProjectDate.bind(this, params.dispatchProjectCode, params.dailyEntryDate),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(this),
            deleteDocument: this.documentServ.DeleteDocument.bind(this.documentServ)
        };
    }
}
