// GENERATED FILE - DO NOT MODIFY
import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import { WorkOrderWorkTaskModel } from "api/models/company/workorder/work-order-work-task-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DailyEntryWorkTaskProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetWorkTasks(projectDispatchCode: string | null, dispatchDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/work-task",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: WorkOrderWorkTaskModel[] | null = await this.get<WorkOrderWorkTaskModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderWorkTaskModel");
        return data;
    }

    public async GetNewWorkTask(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskDetailsModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/work-task/new",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: WorkOrderWorkTaskDetailsModel | null = await this.get<WorkOrderWorkTaskDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderWorkTaskDetailsModel");
        return data;
    }

    public async DeleteWorkTask(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/dispatch/projects/work-task/{transactionNumber}",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async GetWorkTask(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskDetailsModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/work-task/{transactionNumber}",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString() },
            null
        );

        const data: WorkOrderWorkTaskDetailsModel | null = await this.get<WorkOrderWorkTaskDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderWorkTaskDetailsModel");
        return data;
    }

    public async SaveWorkTask(projectDispatchCode: string | null, dispatchDate: Date, workTask: WorkOrderWorkTaskDetailsModel | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/work-task",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: number = await this.post<WorkOrderWorkTaskDetailsModel | null, number>(uri, workTask, requestConfig);
        return data;
    }
}
