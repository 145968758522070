import { LocationType } from "api/enums/location-type";
import { LocationService } from "services/location-service";
import { LocationModel } from "api/models/company/location-model";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { Dialog } from "interfaces/dialog";
import { bindable } from "aurelia-typed-observable-plugin";
import { StringHelper } from "helpers/string-helper";

@autoinject()
export class NewLocationDialog implements Dialog {

    public dialogHeaderTitle: string = "";

    @bindable public address: LocationModel = {} as LocationModel;

    public isReadonly: boolean = false;

    constructor(private readonly dialogController: DialogController, private readonly locationService: LocationService) {
    }

    public async ok(): Promise<void> {
        if (!this.address.Name) {
            return;
        }

        const result = await this.locationService.AddAlternativeLocation(this.cleanAddressStrings(this.address));

        if (result) {
            this.address = result;
            this.dialogController.ok(this.address);
        }
    }

    public cancel(): void {
        this.dialogController.cancel();
    }

    private cleanAddressStrings(address: LocationModel): LocationModel {
        address.PostalCode = StringHelper.cleanString(this.address.PostalCode || "", StringHelper.keepAlphaNumericOnly);
        address.Telephone1 = StringHelper.cleanString(this.address.Telephone1 || "", StringHelper.keepNumericOnly);
        address.Telephone2 = StringHelper.cleanString(this.address.Telephone2 || "", StringHelper.keepNumericOnly);
        address.Fax = StringHelper.cleanString(this.address.Fax || "", StringHelper.keepNumericOnly);

        return address;
    }
}
