import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { autoinject } from "aurelia-framework";
import { CatalogProxy } from "api/proxies/catalog-proxy";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { PagingInfo } from "api/paging-info";

@autoinject()
export class CatalogService {
    public defaultScaleFactor: number = 7;

    constructor(private readonly catalogProxy: CatalogProxy) {
    }

    public async getCatalogItem(catalogItemId: string): Promise<CatalogItemModel | null> {
        return await this.catalogProxy.GetCatalogItem(catalogItemId);
    }

    public async GetCatalogItemsForInventoryTransfer(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        return await this.catalogProxy.GetCatalogItemsForInventoryTransfer(filter, pagingInfo, requestConfig);
    }

}
