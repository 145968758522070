import { autoinject, computedFrom } from "aurelia-framework";
import { DailyEntryLinkEquipmentService } from "services/daily-entry-link-equipment-service";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { RouterHelper } from "helpers/router-helper";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class DalyEntryAddLinkEquipment extends FormBase {
    public selectedEquipment: EquipmentModel | null = null;
    public dispatchProjectCode: string  = "";
    public dispatchDate: any = null;
    public dispatchId: number = 0;
    public qParams: any;
    public mustEnterTimeSeparatelyFlag: boolean = false;
    public unModified: any = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly routerHelper: RouterHelper, private readonly dailyEntryLinkEquipmentService: DailyEntryLinkEquipmentService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");

        const stringifyCurrent = JSON.stringify(this.buildDataToSend()).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.id;
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.qParams = this.routerHelper.getQuerystring(params.q);
        this.mustEnterTimeSeparatelyFlag = await this.dailyEntryLinkEquipmentService.getMustEnterTimeSeparatelyForLinkEquipmentFlagFromDispatch(this.dispatchId);
        this.unModified = this.buildDataToSend();
    }

    public buildDataToSend(): any {
        const data: any = {};

        data.Equipment = this.selectedEquipment ? this.selectedEquipment : null;
        data.MustEnterTimeSeparatelyFlag = this.mustEnterTimeSeparatelyFlag;

        return data;
    }

    public async selectedEquipmentChanged(event: CustomEvent<EquipmentModel>): Promise<void> {
        this.selectedEquipment = event ? event.detail : null;
    }

    public async populateAddedEquipments(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<EquipmentModel[] | null> {
        return await this.dailyEntryLinkEquipmentService.getDailyEntryEquipments(this.dispatchProjectCode, this.dispatchDate, filter, pagingInfo, requestConfig);
    }

    public async save(): Promise<void> {
        this.unModified = null;
        this.selectedEquipment!.MustEnterTimeSeparatelyFlag = this.mustEnterTimeSeparatelyFlag;
        await this.dailyEntryLinkEquipmentService.saveNewDailyEntryLinkEquipment(this.dispatchId, this.selectedEquipment);
        this.routerHelper.navigateBack();
    }

    public setMustEnterTimeSeparatelyFlagValue(): void {
        this.mustEnterTimeSeparatelyFlag = !this.mustEnterTimeSeparatelyFlag;
    }
}
