import { bindingMode, autoinject, DOM } from "aurelia-framework";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import Parse from "helpers/parse";
import { LocalizationHelper } from "helpers/localization-helper";

@autoinject()
export class CurrencyTextbox extends FormControl {
    @bindable.booleanAttr
    public showCurrencySymbol: boolean = true;

    @bindable.string
    public currencySymbol: string = "$";

    @bindable.nullable_number
    public min: number | null = null;

    @bindable.nullable_number
    public max: number | null = null;

    @bindable.nullable_number({ defaultBindingMode: bindingMode.twoWay })
    public value: number | null = null;

    @observable.string
    public internalValue: string = "";

    @bindable.string
    public language: string = this.localizationHelper.getLanguageCode();

    constructor(element: Element, private readonly localizationHelper: LocalizationHelper) {
        super(element);
    }

    public get isCurrencyPositionFront(): boolean {
        return !this.isCurrencyPositionEnd;
    }

    public get isCurrencyPositionEnd(): boolean {
        return this.currencyPosition("fr");
    }

    public bind(): void {
        this.valueChanged();
    }

    private currencyPosition(language: string): boolean {
        return (this.showCurrencySymbol && this.language === language);
    }

    private valueChanged(): void {
        this.internalValue = this.value ? this.value.toString() : "0";
    }

    private internalValueChanged(): void {
        this.value = Parse.NullableDecimal(this.internalValue === "" ? "0" : this.internalValue);

        const valueChangedEvent = DOM.createCustomEvent("value-changed", { detail: { value: this.value }, bubbles: true });
        this.element.dispatchEvent(valueChangedEvent);
    }
}
