import { autoinject } from "aurelia-framework";

import { ServiceCallNonBillingReasonModel } from "api/models/company/service-call/service-call-non-billing-reason-model";
import { ServiceCallNonBillingReasonProxy } from "api/proxies/service-call-non-billing-reason-proxy";
import { PagingInfo } from "api/paging-info";

@autoinject()
export class ServiceCallNonBillingReasonService {
    constructor(private readonly serviceCallNonBillingReasonProxy: ServiceCallNonBillingReasonProxy) {
    }

    public async getNonBillingReasons(quotationId: number | null, filter: string, pagingInfo: PagingInfo): Promise<ServiceCallNonBillingReasonModel[]> {
        return (await this.serviceCallNonBillingReasonProxy.Get(quotationId, filter, pagingInfo)) || [];
    }
}
