import moment from "moment";
import _ from "underscore";
import val from "core/val";
import { I18N } from "aurelia-i18n";
import { autoinject, computedFrom, observable } from "aurelia-framework";

import defaultService from "services/defaultService";
import serviceService from "services/serviceService";
import dispatchService from "services/dispatchService";

import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import { TimeListHelper, TimeListItem } from "helpers/time-list-helper";

@autoinject()
export class ServiceComplete {
    public val: typeof val = val;
    public serviceType: string = "";
    public dispatchId: number = 0;
    public dateHelper: typeof dateHelper = dateHelper;
    public increment: number = 0;
    public client: any = {};
    public dispatchStatus: any = {};
    public statusList: any[] = [];
    public statusDisabled: boolean = false;
    public signatureAvailable: boolean = false;
    public completeAvailable: boolean = false;
    public showSignature: boolean = false;
    public showComplete: boolean = false;
    public isLead: boolean = false;
    public isMultiVisitForm: boolean = false;
    public isLastVisit: boolean = false;
    public isMultiAndLastVisit: boolean = false;
    public oneDayWorkDuration: any = false;
    public isMaintenanceWorkOrder: boolean = false;

    public dateFormat: string = "time";
    public dateFrom: Date = moment().toDate();
    public followUpDate: Date = moment().toDate();
    public assignedDate: Date | null = null;

    public selectedStatus: any = {};
    public externalWO: string = "";
    public poNumber: string = "";
    public estimatedLength: string = dateHelper.getVeryShortDateLocal(dateHelper.getDate());
    public isDefaultContactSelected: boolean = false;

    public timeOptions: TimeListItem[] = [];

    public isCreateUnscheduledAssignment: boolean = false;
    public techWillReturnActionEnum: any = { willNotReturn: "1", planned: "2", unplanned: "3" };
    public isFollowUpScheduled: string = this.techWillReturnActionEnum.willNotReturn;

    @observable public isDispatchFinished: boolean = false;

    @computedFrom("dispatchStatus", "dispatchStatus.CustomStatus")
    public get statusPlaceHolder(): string {
        if (this.dispatchStatus.CustomStatus !== "") {
            this.isDefaultContactSelected = true;
            return `${this.dispatchStatus.CustomStatus} - ${this.dispatchStatus.CustomStatusDescription}`;
        } else {
            this.isDefaultContactSelected = false;
            return this.statusDisabled ? this.i18n.tr("NoStatusAvailable") : this.i18n.tr("SelectOoo");
        }
    }

    constructor(private readonly i18n: I18N) {
    }

    public async activate(params: any): Promise<any> {
        this.clear();
        this.bindViewModel(params.serviceType, params.dispatchId, decodeURIComponent(params.assignedDate), decodeURIComponent(params.dateFrom), parseInt(params.increment), params.q);
        return await this.loadData();
    }

    public async loadData(): Promise<any> {
        const dfdStatus = serviceService.getCustomStatus(this.serviceType);
        const dfdClient = dispatchService.getClient(this.dispatchId);
        const dfdDispatchStatus = serviceService.getStatus(this.serviceType, this.dispatchId);

        return await Promise.all([dfdStatus, dfdClient, dfdDispatchStatus]).then(([status, client, dispatchStatus]: any): any => {
            this.bindLoadedData(status, client, dispatchStatus);
        });
    }

    public attached(): void {
        this.initDropDownList();
    }

    public bindViewModel(serviceType: string, dispatchId: any, assignedDate: string, dateFrom: string, increment: number, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        if (params.isMaintenanceWorkOrder) {
            this.isMaintenanceWorkOrder = params.isMaintenanceWorkOrder === "true";
        }

        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.oneDayWorkDuration = !dateHelper.isAfter(dateFrom, assignedDate);
        this.dateFrom = dateHelper.parseDate(dateFrom);
        this.assignedDate = dateHelper.parseDate(assignedDate);

        this.followUpDate = dateHelper.isAfter(this.dateFrom, moment().toDate()) ? this.dateFrom : moment().toDate();

        this.dateFormat = this.oneDayWorkDuration ? "time" : "datetime";
        this.increment = increment;

        this.timeOptions = TimeListHelper.loadTimeList(increment);

        if (this.isMaintenanceWorkOrder) {
            this.isDispatchFinished = true;
        }
    }

    public bindLoadedData(status: any[], client: any, dispatchStatus: any): void {
        if (client === null) {
            this.client = { MobilitySignatureRequiredness: 0 };
        } else {
            this.client = client;
        }

        status = _.map(status, (tmpData: any): any => {
            return {
                id: tmpData.Id,
                text: tmpData.Id + " - " + tmpData.Description
            };
        });

        this.statusList = status;
        this.isLead = dispatchStatus.IsLead;
        this.isMultiVisitForm = dispatchStatus.IsMultiVisitForm;
        this.isLastVisit = dispatchStatus.IsLastVisit;
        this.isMultiAndLastVisit = this.isMultiVisitForm && this.isLastVisit;
        this.statusDisabled = this.statusList.length === 0;
        //if client not specified we allow both signature and complete
        if (this.client) {
            this.signatureAvailable = this.client.MobilitySignatureRequiredness !== 2;
            this.completeAvailable = this.client.MobilitySignatureRequiredness !== 1 || this.client.AlreadySigned;
        } else {
            this.signatureAvailable = true;
            this.completeAvailable = true;
        }

        if (this.isMultiVisitForm) {
            //if it's a multi-visit, signature is only avalaible if required and that there are no more planned visits.
            this.signatureAvailable = this.signatureAvailable && this.isLastVisit;
            this.completeAvailable = this.completeAvailable || !this.isLastVisit;
        }

        this.updateShowSignatureAndComplete();

        this.externalWO = dispatchStatus.Workorder;
        this.poNumber = dispatchStatus.OrderNumber;

        this.dispatchStatus = dispatchStatus;
        if (this.dispatchStatus.CustomStatus !== "" && _.find(this.statusList, (item: any) => item.id === this.dispatchStatus.CustomStatus)) {
            this.selectedStatus = this.dispatchStatus.CustomStatus;
        }
    }

    public initDropDownList(): void {
        const select2: any = jQuery("#ddlStatus");
        select2.select2({
            minimumResultsForSearch: defaultService.getMinimumResultsForSearch(),
            placeholder: this.statusPlaceHolder,
            width: "100%",
            language: labelHelper.getDefaultSelect2Labels(),
            data: this.statusList

        }).on("change", (e: any) => {
            this.selectedStatus = e.target.value;
        }).val(this.selectedStatus).trigger("change");
    }

    public buildSaveData(): any {
        return {
            DispatchId: this.dispatchId,
            ServiceType: this.serviceType,
            Comment: "",
            CustomStatus: this.selectedStatus !== undefined ? this.selectedStatus : "",
            Date: dateHelper.formatDateToSend(this.followUpDate),
            Hour: dateHelper.getTime(moment().toString()).replace(":", ""),
            IsComplete: this.isDispatchFinished,
            IsTechWillReturn: this.isFollowUpScheduled !== this.techWillReturnActionEnum.willNotReturn,
            Length: dateHelper.toHourDecimal(this.estimatedLength),
            Workorder: this.externalWO,
            EndDate: dateHelper.formatDateToSend(this.dateFrom),
            OrderNumber: this.poNumber,
            IsClosed: true
        };
    }

    public async saveNotLead(): Promise<void> {
        if (this.isValid()) {
            routerHelper.showLoading();
            await dispatchService.setServiceCallFinish(this.dispatchId, dateHelper.formatDateToSend(this.dateFrom), {}).then(() => {
                    routerHelper.navigateBack();
                    routerHelper.hideLoading();
            });
        }
    }

    public async save(signatureRequired: boolean): Promise<void> {
        if (this.isValid()) {
            const data = this.buildSaveData();

            if (signatureRequired) {
                let url = "service/" + this.serviceType + "/" + this.dispatchId + "/summary";
                url += routerHelper.addQuerystring({ readonly: false, data: JSON.stringify(data)});

                routerHelper.navigate(url, {
                    replace: true,
                    trigger: true
                });

                notificationHelper.showWarning(this.i18n.tr("RequisitionCompleteSignatureRequiredText"));
            } else {
                routerHelper.showLoading();
                await dispatchService.setServiceCallFinish(this.dispatchId, dateHelper.formatDateToSend(this.dateFrom), data).then(() => {
                        if (!this.isMultiVisitForm || (this.isMultiAndLastVisit && this.isDispatchFinished)) {
                            if (!this.isMaintenanceWorkOrder) {
                                dispatchService.printform(this.dispatchId, "");
                            }
                        }
                        routerHelper.hideLoading();
                        routerHelper.navigateBack();
                });
            }
        }
    }

    public clear(): void {
        this.dateFrom = moment().toDate();
        this.selectedStatus = {};
        this.externalWO = "";
        this.estimatedLength = dateHelper.getVeryShortDateLocal(moment().toDate());
        this.followUpDate = moment().toDate();
        this.assignedDate = null;
        this.isDefaultContactSelected = false;
    }

    public toggleIsDispatchFinished(value: boolean): void {
        this.isDispatchFinished = value;
    }

    public toggleIsFollowUpScheduled(value: string): void {
        this.isFollowUpScheduled = value;
    }

    public toggleIsUnscheduledAssignment(value: boolean): void {
        this.isCreateUnscheduledAssignment = value;
    }

    public isDispatchFinishedChanged(): void {
        this.updateShowSignatureAndComplete();
        this.isFollowUpScheduled = this.techWillReturnActionEnum.willNotReturn;
    }

    public updateShowSignatureAndComplete(): void {
        this.showSignature = this.signatureAvailable && (!this.isMultiVisitForm || this.isDispatchFinished);
        this.showComplete = this.completeAvailable || !this.showSignature;
    }

    public isValid(): boolean {
        let errors: number = 0;
        if (!this.isEstimatedLengthValid()) {
            errors++;
        }

        if (!this.statusDisabled && this.selectedStatus === "") {
            notificationHelper.showError(this.isDefaultContactSelected ? this.i18n.tr("err_DefaultStatusUnavailable") : this.i18n.tr("err_StatusRequired"), "", { timeOut: 0 });
            errors++;
        }

        const followUpDate = moment(this.followUpDate!);
        const assignedDate = moment(this.assignedDate!);

        if (!this.isDispatchFinished && this.isFollowUpScheduled === this.techWillReturnActionEnum.planned && !followUpDate.isSameOrAfter(assignedDate)) {
            notificationHelper.showError(this.i18n.tr("err_FollowUpDateMustBeGreaterThanAssignedDate"), "", { timeOut: 0 });
            errors++;
        }

        const endDate = moment(this.dateFrom);
        if (assignedDate.isAfter(endDate)) {
            notificationHelper.showError(this.i18n.tr("ApiError.531"), "", { timeOut: 0 });
            errors++;
        }

        return errors === 0;
    }

    public isEstimatedLengthValid(): boolean {
        const estimatedLengthIsRequired = !this.isDispatchFinished && this.isFollowUpScheduled === this.techWillReturnActionEnum.planned && (this.isLead || this.isMultiVisitForm);

        if (estimatedLengthIsRequired && this.estimatedLength.length <= 0) {
            notificationHelper.showError(this.i18n.tr("err_EstimatedLengthMissing"), "", { timeOut: 0 });
            return false;
        }
        if (!this.isDispatchFinished && this.isFollowUpScheduled === this.techWillReturnActionEnum.planned && dateHelper.toHourDecimal(this.estimatedLength) <= 0) {
            notificationHelper.showError(this.i18n.tr("err_EstimatedLengthMinLength"), "", { timeOut: 0 });
            return false;
        }

        return true;
    }
}
