import { autoinject } from "aurelia-framework";

import Config from "config";
import { default as ViewportHelper } from "helpers/viewport-helper";
import { SettingRepository } from "repositories/setting-repository";

export enum ViewMode {
    Desktop = "desktop",
    Mobile = "mobile",
}

// Viewport setup handlers for each view mode.
export const viewModeViewports = {
    [ViewMode.Desktop]: (viewportHelper: ViewportHelper): void => {
        viewportHelper.setViewport(false, Config.desktopViewModeViewportWidth, null, true, true);
    },
    [ViewMode.Mobile]: (viewportHelper: ViewportHelper): void => {
        viewportHelper.setViewport(true, null, 1.0, false, true);
    },
};

@autoinject()
export class ViewModeHelper {
    constructor(
        private readonly settingRepository: SettingRepository,
        private readonly viewportHelper: ViewportHelper
    ) {
    }

    public getViewMode(): ViewMode {
        const viewMode = this.settingRepository.getViewMode();

        if (!viewMode) {
            return ViewMode.Mobile;
        }

        return viewMode;
    }

    public setViewMode(viewMode: ViewMode, reload: boolean): void {
        this.settingRepository.setViewMode(viewMode);

        if (reload) {
            window.location.reload(false);
        }
    }

    // Toggles between mobile and desktop view modes.
    public toggleViewMode(reload: boolean): void {
        if (this.getIsDesktopMode()) {
            this.setViewMode(ViewMode.Mobile, reload);
        } else {
            this.setViewMode(ViewMode.Desktop, reload);
        }
    }

    public getIsDesktopMode(): boolean {
        return this.getViewMode() === ViewMode.Desktop;
    }

    public getIsMobileMode(): boolean {
        return this.getViewMode() === ViewMode.Mobile;
    }

    // Sets up the viewport based on the current view mode.
    public initViewport(): void {
        const viewMode = this.getViewMode();

        const viewportSetupHandler = viewModeViewports[viewMode];
        if (viewportSetupHandler) {
            viewportSetupHandler(this.viewportHelper);
        }
    }
}
