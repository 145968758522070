import { inject, transient, bindable, DOM, bindingMode, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";

import { default as notificationHelper } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { SelectItem } from "models/select-item";
import * as catalog from "services/catalogService";
import { ViewModeHelper } from "helpers/view-mode-helper";

@transient()
@inject(Element, I18N, ViewModeHelper)
export class CatalogPicker {
    @bindable displayScanner = true;
    @bindable catalogBaseUrl = "";
    @bindable disable = false;
    @bindable requisitionId = false;
    @bindable showNotInCatalog = true;
    @bindable isReservation = false;
    @bindable renderAsButton = false;
    @bindable isSearchDone = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedItem = null;

    templateResult = PLATFORM.moduleName("pages/templates/maSelectTemplates/AU_catalogPicker_noId_result.html");

    // Last value picked up by the bar code scanner
    lastScannedValue = "";
    // Set to true to set lastScannedValue as the filter for the request populating the maSelect for the next page of items.
    // We should replace this since it won't work in some case (eg 20+ items matching the filter).
    // Moreover we shouldn't do this at all since we've just fetched all items for this exact call in the newScanDetected handler -_-
    filterNextPageWithLastScannedValue = false;

    @computedFrom("selectedItem")
    get btnText() {
        return this.selectedItem ? this.selectedItem.text : this.i18n.tr("SelectOoo");
    }

    constructor(element, i18n, viewModeHelper) {
        this.element = element;
        this.i18n = i18n;
        this.viewModeHelper = viewModeHelper;
    }

    bind() {
        this.ajax = this.initAjax();

        // Those templates are required for catalogPicker_noId_result
        PLATFORM.moduleName("pages/templates/maSelectTemplates/AU_catalogPicker_reservation_result.html");
        PLATFORM.moduleName("pages/templates/maSelectTemplates/AU_catalogPicker_NoReservation_result.html");

        this.displayScanner = this.viewModeHelper.getIsMobileMode() ? this.displayScanner : false;
    }

    async loadParts(params) {
        let ajaxRequest;
        const notInCatalog = { Code: "!", Description1: this.i18n.tr("AddItemNotInCatalog") };

        if (this.catalogBaseUrl) {
            const filter = this.filterNextPageWithLastScannedValue
                ? encodeURIComponent(this.lastScannedValue)
                : params.data.filter;
            ajaxRequest = catalog.getByUrl(this.catalogBaseUrl, filter, params.data.page || 1);
        } else {
            ajaxRequest = catalog.getAllNoBilling(params.data.filter, params.data.page || 1);
        }

        this.filterNextPageWithLastScannedValue = false;

        const data = await ajaxRequest.promise();
        return this.processLoadParts(data, notInCatalog, params);
    }

    processLoadParts(data, notInCatalog, params) {
        if (this.showNotInCatalog) {
            if (params.data.page === 1) {
                data.unshift(notInCatalog);
            }
        }
        return data;
    }

    initAjax() {
        var defaultAjax = {
            transport: (params, success, failure) => {
                routerHelper.showLoading();
                this.loadParts(params)
                    .then(success)
                    .catch(failure)
                    .finally(routerHelper.hideLoading);
            },
            mapResults: item => {
                item.id = item.Code;

                if (item.Code === "!") {
                    item.text = item.Description1;
                } else {
                    item.text = item.Code;
                    item.requisitionId = this.requisitionId;
                }

                return item;
            }
        };
        _.extend(defaultAjax, this.ajax);
        return defaultAjax;
    }

    templateSelection(item) {
        if (item.data) {
            var txt = item.data.Code;
            txt += item.data.Description1 ? " - " + item.data.Description1 : "";
            return txt;
        }
        return item;
    }

    async newScanDetected(scannedValue) {
        const matchingCatalogItems = await catalog
            .getByUrl(this.catalogBaseUrl, encodeURIComponent(scannedValue), 1)
            .promise();

        switch (matchingCatalogItems.length){
            case 0: return await this.manageNoMatchInCatalogForScannedItem(scannedValue);
            case 1: return this.manageSingleMatchInCatalogForScannedItem(matchingCatalogItems[0]);
            default: return this.manageMultipleMatchesInCatalogForScannedItem(matchingCatalogItems, scannedValue);
        }
    }

    async manageNoMatchInCatalogForScannedItem(scannedValue) {
        // No matches
        const options = { code: scannedValue };

        if (!this.showNotInCatalog) {
            const title = this.i18n.tr("ItemNotFoundDo");
            const message = this.i18n.tr("ItemScannedNotFound", options);
            notificationHelper.showDialogOk(message, title);
        } else {
            const message = this.i18n.tr("ItemNotFoundDoYouWantToAddNotInCatalog", options);
            const response = await notificationHelper.showDialogYesNo(message);

            if (response === false){
                // User don't want to add an item not in catalog so open to select to pick an existing one
                this.maSelect.openSelect();
                return;
            }

            this.addScannedItemNotInCatalog(scannedValue);
        }
    }

    manageSingleMatchInCatalogForScannedItem(catalogItem){
         // 1 Match
         // TODO JL: centraliser le mapping des items catalogs
         this.selectedItem = new SelectItem(catalogItem, (d) => d.Code, (d) => d.Code);
    }

    manageMultipleMatchesInCatalogForScannedItem(matchingCatalogItems, scannedValue){
        // 2+ Matches
        this.lastScannedValue = scannedValue;
        this.filterNextPageWithLastScannedValue = true;
        this.maSelect.openSelect();
    }

    addScannedItemNotInCatalog(scannedValue) {
        var description = this.i18n.tr("AddItemNotInCatalog");

        //HM: TODO: to refactor (Kossé ça ???)
        const model = {
            Code: '!',
            Description1: scannedValue,
            ReservationNumber: null,
            UnitCode: null,
            UnitDescription: null
        };

        const selectItem = {
            id: '!',
            text: description,
            scannedValue: scannedValue
        };

        const data = _.extend(selectItem, model);
        this.selectedItem = SelectItem.fromIdText(data);
    }
}
