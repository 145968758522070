define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        deleteRequisition: function (dispatchProjectCode, id) {
            return requests.remove(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/requisitions/" + id);
        },

        getRequisition: function (dispatchProjectCode, id) {            
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/requisitions/" + id);
        },

        getRequisitionsForDispatch: function (dispatchProjectCode) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/requisitions");
        },

        saveRequisition: function (dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/requisitions" , data, {});
        }
    };

    return exports;
});
