import { TakeoffVariableActionStatus } from "enums/takeoffVariableActionStatus";
import { default as resx } from "core/resx";

import { inject } from "aurelia-framework";

export class GridValueFormatter {
    formatVariableActionStatus(parameters) {
        const status = Object.values(TakeoffVariableActionStatus).find(x => x.id === parameters.value);
        return status ? resx.localize(status.i18nKey) : "";
    }
}


