import { ServiceCallService } from "services/service-call-service";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable, observable, PLATFORM } from "aurelia-framework";

import Parse from "helpers/parse";
import routerHelper from "helpers/routerHelper";
import enumHelper from "helpers/enumHelper";

import { NotificationHelper } from "helpers/notification-helper";
import { CatalogItemManagementMode } from "api/enums/catalog-item-management-mode";

import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { InventorySiteTransferItemModel } from "api/models/company/inventory-site-transfer-item-model";
import { InventorySiteTransferItemResponseModel } from "api/models/company/inventory-site-transfer-item-response-model";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";

import UserAccessService from "services/user-access-service";
import { CatalogService } from "services/catalog-service";
import { CatalogInventoryService } from "services/catalog-inventory-service";
import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { SiteService } from "services/site-service";

import { InventoryTransferSellingQtyValidation } from "api/enums/inventory-transfer-selling-qty-validation";
import { InventoryTransferQtyValidation } from "api/enums/inventory-transfer-qty-validation";
import { ServiceCallLookupModel } from "api/models/company/service-call-lookup-model";
import { default as apiHelper } from "helpers/apiHelper";
import { default as val } from "core/val";
import { CatalogItemStatus } from "api/enums/catalog-item-status";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { SiteLocationModel } from "api/models/company/site-location-model";

import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

@autoinject()
export class ItemEdit extends FormBase {

    @bindable public transferId: number | null = null;
    @bindable public transferItem: InventorySiteTransferItemModel | null = null;
    public unModified: InventorySiteTransferItemModel | null = null;

    @bindable public isActive: boolean = false;
    @bindable public isNewItem: boolean = true;
    @bindable public isItemSelected: boolean = false;

    @bindable public selectedItem: any;
    @bindable public selectedUnit: any;
    @bindable public selectedCallNumber: any;

    @bindable public inventoryQty: number = 0;
    @bindable public availableQty: number = 0;

    @bindable public editItemCard: MaCardOptionsModel | null = null;

    @bindable public unitResultTemplate: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/inventoryTransfer_unit_result.html");

    @observable public inventoryScaleFactor: number;

    @observable public newDestinationLocation: string = "";

    @observable public selectedDestinationLocation: any = null;
    @observable public selectedDestinationLocationDescription: any = null;

    public selectedItemAvailableQty: number = 0;
    public isValidationModeBlocking: boolean = false;
    public isSellingQtyValidationEnabled: boolean = false;
    public isServiceCallActive: boolean = false;
    public isItemReserved: boolean = false;
    public catalogBaseUrl: string = apiHelper.getBaseUrl() + "/catalog/inventory-transfer";
    public descriptionMaxLength: number = val.get("material.description", "maxLength");
    public destinationLocationMaxLength: number = val.get("catalog.location", "maxLength");
    public destinationLocations: any = [];

    private sourcePrefix: string = "";
    private targetPrefix: string = "";
    private sourceSiteId: string = "";
    private targetSiteId: string = "";

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly inventorySiteTransferService: InventorySiteTransferService, private readonly catalogService: CatalogService, private readonly catalogInventoryService: CatalogInventoryService, private readonly serviceCallService: ServiceCallService, private readonly userAccessService: UserAccessService, private readonly siteService: SiteService) {
        super(notificationHelper, i18n, router);
        this.inventoryScaleFactor = catalogService.defaultScaleFactor;
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        if (!this.transferItem) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        this.transferItem!.InventoryQty = this.inventoryQty;
        
        if (this.selectedDestinationLocation) {
            this.transferItem!.TargetLocation = this.selectedDestinationLocation;
        }

        if (this.newDestinationLocation) {
            this.transferItem!.TargetLocation = this.newDestinationLocation;
        }

        const stringifyCurrent = JSON.stringify(this.transferItem).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: any): Promise<void> {
        this.sourcePrefix = params.sourcePrefix;
        this.targetPrefix = params.targetPrefix;
        this.sourceSiteId = params.sourceSiteId;
        this.targetSiteId = params.targetSiteId;

        this.isNewItem = !params.sourceTransactionNumber;
        this.transferId = params.transferId;
        this.isActive = Parse.Boolean(params.isActive);
        this.isServiceCallActive = await this.inventorySiteTransferService.IsServiceCallInstalled();
       
        if (!this.isNewItem) {
            const transfer = (await this.inventorySiteTransferService.GetTransferItem(params.sourceTransactionNumber, params.targetTransactionNumber))!;

            this.transferItem = transfer.Item!;
            this.unModified = CloneHelper.deepClone(this.transferItem);
            this.inventoryScaleFactor = this.transferItem.InventoryScaleFactor;
            this.selectedItemAvailableQty = this.transferItem.AvailableQty;

            this.feedValidationProperties(transfer!);
            this.initialiseSelections(this.transferItem);

            if (this.transferItem.Unit !== 0) {
                this.genereteCard();
            }

            this.selectedDestinationLocation = null;
            this.selectedDestinationLocationDescription = null;

            if (this.transferItem.TargetLocation) {
                const destinationLocationToSelect = await this.loadDestinationLocations(this.transferItem.TargetLocation);
                if (destinationLocationToSelect!.length === 0) {
                    this.newDestinationLocation = this.transferItem.TargetLocation;
                } else {
                    this.selectedDestinationLocation = destinationLocationToSelect![0].Code;
                    this.selectedDestinationLocationDescription = destinationLocationToSelect![0].Description;
                }
            }
        } else {
            this.unModified = {SourceTransactionNumber: 0, TargetTransactionNumber: 0, SourcePrefix: null, TargetPrefix: null, TransferId: 0, SourceSite: null, TargetSite: null, SourceLocation: null, TargetLocation: null, CallNo: 0, LineNo: 0, InventoryQty: 0, Unit: 0, ProductCode: null, Description: null, Description2: null, Description3: null, InventoryUnit: null, InventoryFactor: 0, SupplierUnit: null, SupplierFactor: 0, ItemManagementMode: null, Identification: null, Characteristic: null, ReservationNo: null, AvailableQty: 0, UnitPrice: 0, SupplierUnitPrice: 0, CurrencyCode: null, InventoryScaleFactor: 0, Status: null, WorkOrderId: null };
        }
    }

    public get GetUnits(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.catalogInventoryService.GetCatalogInventoryItems(this.transferItem!.ProductCode!, this.sourceSiteId, this.sourcePrefix).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {

                        return failure(fail);
                    }
                );
            },
            mapResults: (item: CatalogInventoryModel): any => {
                return { id: item.Unit, text: item.Unit, data: item };
            },
        };
    }

    public get GetCallNumbers(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.serviceCallService.GetServiceCallLookup(this.transferItem!.TargetPrefix, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ServiceCallLookupModel): any => {
                return { id: item.Id, text: `${item.Id} ${ !item.Description ? "" : "-" + item.Description}`, data: item };
            },
        };
    }

    public async populateDestinationLocations(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<SiteLocationModel[] | null> {
        return await this.siteService.GetSiteLocations(this.targetSiteId, this.targetPrefix, null, true,
            filter,
            pagingInfo,
            requestConfig
        );
    }

    public async selectedItemChanged(catalogItem: any): Promise<void> {
        const transfer = (await this.inventorySiteTransferService.NewTransferItem(
            this.transferId!,
            catalogItem.id!,
            this.sourceSiteId,
            this.targetSiteId
        ))!;

        this.transferItem = transfer.Item!;
        this.feedValidationProperties(transfer);

        this.selectedItemAvailableQty = this.transferItem.AvailableQty;
        this.isItemReserved = this.checkIsItemReserved(this.transferItem);

        this.isItemSelected = true;
        this.inventoryScaleFactor = this.transferItem.InventoryScaleFactor;
        this.clearUnitSelection();
    }

    public async onUnitSelected(catalogUnit: CatalogInventoryModel): Promise<void> {
        this.transferItem = (await this.inventorySiteTransferService.UpdateFromCatalogInventoryItem({ Item: this.transferItem, CatalogInventoryItem: catalogUnit }))!;
        this.inventoryQty = this.transferItem.InventoryQty;
        this.availableQty = this.transferItem.AvailableQty;
        this.isItemReserved = this.checkIsItemReserved(this.transferItem);
        if (this.isItemReserved) {
            this.selectedCallNumber = null;
            this.transferItem.CallNo = 0;
        }
        this.genereteCard();
    }

    public async loadDestinationLocations(destinationLocation: string): Promise<SiteLocationModel[] | null> {
       return await this.siteService.GetSiteLocations(this.targetSiteId, this.targetPrefix, destinationLocation, false);
    }

    public onCallNumberSelected(callNumber: ServiceCallLookupModel): void {
        this.transferItem!.CallNo = callNumber.Id;
    }

    public onCallNumberCleared(): void {
        if (!this.transferItem!.ReservationNo) {
            this.transferItem!.CallNo = 0;
        }
    }

    public async clearUnitSelection(): Promise<void> {
        this.transferItem!.Unit = 0;
        this.transferItem!.SourceLocation = null;
        this.transferItem!.Identification = null;
        this.transferItem!.ReservationNo = null;

        this.isItemReserved = this.checkIsItemReserved(this.transferItem!);

        this.availableQty = this.selectedItemAvailableQty;
        this.editItemCard = null;
    }

    public async save(): Promise<void> {
        this.transferItem!.InventoryQty = this.inventoryQty;

        this.transferItem!.TargetLocation = "";
      
        if (this.selectedDestinationLocation) {
            this.transferItem!.TargetLocation = this.selectedDestinationLocation;
        }

        if (this.newDestinationLocation) {
            this.transferItem!.TargetLocation = this.newDestinationLocation;
        }

        if (await this.isTransferValid()) {
            this.executeSave();
        }
    }

    public selectedDestinationLocationChanged(newValue: any, oldValue: any): void {
        if (newValue) {
            this.newDestinationLocation = "";
        }
    }

    public newDestinationLocationChanged(newValue: any, oldValue: any): void {
        if (newValue) {
            this.selectedDestinationLocation = null;
            this.selectedDestinationLocationDescription = null;
        }
    }

    private genereteCard(): void {
        this.editItemCard = new MaCardOptionsModel({
            model: this.transferItem,
            displayLines: [
                new MaCardLineModel({ display: `${this.i18n.tr("Unit:")} ${this.transferItem!.Unit}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("Location:")} ${this.transferItem!.SourceLocation}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("Characteristic:")} ${this.transferItem!.Characteristic}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("Identification:")} ${this.transferItem!.Identification}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("ReservationNo:")} ${this.transferItem!.ReservationNo}` }),
            ],
            actionItems: [],
        });
        if (this.isActive) {
            this.editItemCard.actionItems!.push(new MaCardActionModel({ icon: "fa fa-trash text-danger pt-3", action: this.clearUnitSelection.bind(this) }));
        }
    }

    private async isTransferValid(): Promise<boolean> {
        const quantityAvailable = this.transferItem!.AvailableQty;
        const isQuantityValid = this.inventoryQty <= quantityAvailable;

        if (!this.transferItem) {
            return false;
        }

        if (this.inventoryQty <= 0) {
            this.notificationHelper.showError(this.i18n.tr("InventoryTransfer_warning_error_qty"));
            return false;
        }

        if (this.transferItem.ItemManagementMode === CatalogItemManagementMode.Serial) {
            if (!this.editItemCard) {
                this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_SerialNoItem_MustHaveInventorySelection"));
                return false;
            }
            if (this.inventoryQty > 1) {
                this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_SerialNoItem_ExceedingQuantity"));
                return false;
            }
        }

        if (this.isSellingQtyValidationEnabled && !isQuantityValid && this.transferItem.Status !== CatalogItemStatus.GeneralNoValidation) {
            if (this.isValidationModeBlocking) {
                this.notificationHelper.showError(this.i18n.tr("err_QuantityIsOverQuantityAvailable"));
                return false;
            }

            if (this.isSellingQtyValidationEnabled && !isQuantityValid && !this.isValidationModeBlocking) {
                const warningMsg = `${this.i18n.tr("err_QuantityIsOverQuantityAvailable")} ${this.i18n.tr("DoYouWantToSave")}`;
                if (!(await this.notificationHelper.showDialogYesNo(warningMsg))) {
                    return false;
                }
            }
        }

        return true;
    }

    private async executeSave(): Promise<void> {
        if (!this.isActive) {
            return;
        }
        await this.inventorySiteTransferService.SaveTransferItems([this.transferItem!]).then((success: any): any => {
            if (success) {
                this.unModified = null;
                routerHelper.navigateBack();
                return;
            }
        });
    }

    private initialiseSelections(transferItem: InventorySiteTransferItemModel): void {
        this.selectedItem = { id: transferItem.ProductCode, text: transferItem.ProductCode };
        this.selectedUnit = { id: transferItem.Unit, text: transferItem.Unit };

        this.isItemReserved = this.checkIsItemReserved(transferItem);

        if (this.isItemReserved) {
            this.selectedCallNumber = null;

        } else if (transferItem.CallNo > 0) {
            this.selectedCallNumber = {id: transferItem.CallNo, text: transferItem.CallNo };
        }

        this.inventoryQty = transferItem.InventoryQty;
        this.availableQty = transferItem.AvailableQty;

        this.isItemSelected = true;
    }

    private checkIsItemReserved(item: InventorySiteTransferItemModel): boolean {
        if (item.ReservationNo && item.CallNo > 0 && item.ReservationNo.endsWith(item.CallNo.toString())) {
            return false;
        }

        return !!item.ReservationNo;
    }

    private feedValidationProperties(item: InventorySiteTransferItemResponseModel): void {
        this.isValidationModeBlocking = item.QtyValidationMode === InventoryTransferQtyValidation.Block;
        this.isSellingQtyValidationEnabled = item.SellingQtyValidation !== InventoryTransferSellingQtyValidation.NoValidation;
    }
}
