import { singleton, inject } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";
import { ErrorCorrelationIdManager } from "error-management/error-correlation-id-manager"
import { default as logger } from "core/logger";
import { default as resx } from "core/resx";
import { default as dateHelper } from "helpers/dateHelper";
import { default as notifier } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as stringify } from "json-stringify-safe";
import { default as moment } from "moment";
import { default as applicationInsightsService } from "services/applicationInsightsService";
import { default as userService } from "services/userService";
import { default as _ } from "underscore";
import HostContext from "core/host-context";

//interface RouterNavigationErrorEventData {
//    instruction: NavigationInstruction;
//    result: PipelineResult;
//}

@singleton()
@inject(ErrorCorrelationIdManager, EventAggregator, HostContext)
export class ErrorManager {
    errorCorrelationIdManager;
    eventAggregator;
    hostContext;
    initialized = false;
    subscriptions = [];
    concurrencyErrorId = 558;
    versionMismatchErrorId = 999;

    constructor(errorCorrelationIdManager, eventAggregator, hostContext) {
        this.errorCorrelationIdManager = errorCorrelationIdManager;
        this.eventAggregator = eventAggregator;
        this.hostContext = hostContext;
    }

    init() {
        if (this.initialized) {
            return;
        }

        const subscription1 = this.eventAggregator.subscribe("error:windowerror", (error) => this.onWindowError(error));
        const subscription2 = this.eventAggregator.subscribe("error:unhandledrejection", (event) => this.onUnhandledRejection(event));
        const subscription3 = this.eventAggregator.subscribe("error:jquerydeferredexception", (error, stack) => this.onJQueryDeferredException(error, stack));
        const subscription4 = this.eventAggregator.subscribe("router:navigation:error", (data/*: RouterNavigationErrorEventData*/, eventName/*: string*/) => this.onRouterNavigationError(data, eventName));

        this.subscriptions.push(subscription1);
        this.subscriptions.push(subscription2);
        this.subscriptions.push(subscription3);
        this.subscriptions.push(subscription4);

        this.initialized = true;
    }

    onWindowError(error) {
        logger.error(
            error.message,
            {
                Error: error.error,
                ErrorUrl: window.location.hash,
                Filename: error.filename,
                LineNumber: error.lineno,
                ColNumber: error.colno,
                Stack: error.stack
            });
    }

    onUnhandledRejection(event) {
        const reason = event.detail.reason;
        const promise = event.detail.promise;
        const isHttpRequestError = reason.isHttpRequestError;

        // If the error is a failed HTTP request, don't send it to the logger.
        if (!isHttpRequestError) {
            logger.error(
                "Unhandled promise rejection\n",
                {
                    Message: reason.message,
                    StackTrace: reason.stack
                });
        }

        // Handle HTTP request errors.
        if (isHttpRequestError) {
            this.handleError(reason);
            return;
        }
    }

    onJQueryDeferredException(error, stack) {
        // TODO: Gérer les erreurs dans les deferred jQuery.
    }

    onRouterNavigationError(data/*: RouterNavigationErrorEventData*/, eventName/*: string*/)/*: void*/ {
        const isHttpRequestError = data.result.output.isHttpRequestError;

        // If the navigation error was caused by a failed HTTP request, don't send it to the logger.
        // The HTTP request error should already have been handled by the ErrorManager.
        if (!isHttpRequestError) {
            logger.error(
                "Navigation Error",
                {
                    NavigationResult: data.result
                });
        }

        if (data.instruction.previousInstruction) {
            routerHelper.navigateBack();
        } else {
            // En mode hosted, le fallback d'erreur est géré dans le app.ts avec config.fallbackRoute();
            if (!this.hostContext.isHosted) {
                routerHelper.showGlobalError();
            }
        }
    }

    handleError(error, aiInfo, additionnalInfo) {
        if (error.isHttpRequestError) {
            this.handleHttpRequestError(error.request, error.config.url, null, error.isHttpRequestTimeout);
            return;
        }

        const errorCorrelationId = this.errorCorrelationIdManager.getNextCorrelationId();
        const errorUrl = window.location.href;

        // Log error to AppInsights
        let aiParams = {
            CorrelationId: errorCorrelationId,
            ErrorUrl: errorUrl,
            AdditionnalInfo: additionnalInfo
        };
        aiParams = _.extend(aiParams, aiInfo);
        applicationInsightsService.trackException(error, aiParams);

        this.logErrorToHost(error + "\r\n" + JSON.stringify(aiParams));

        const userMessage = resx.localize("err_RequestError") + ", id: " + errorCorrelationId + "<br>";
        notifier.showError(userMessage, "", { timeOut: 0 });

        routerHelper.hideLoading(true);
    }

    handleHttpRequestError(xhr, url, deferred, isAxiosTimeout) {
        const errorCorrelationId = this.errorCorrelationIdManager.getNextCorrelationId();
        let errorUrl = url ? url.substring(url.indexOf("api/")) : undefined;

        // Log error to AppInsights
        var aiParams = {
            CorrelationId: errorCorrelationId,
            ErrorUrl: errorUrl
        };
        let responseContentAsString = this.getXhrResponseContentAsString(xhr);
        applicationInsightsService.trackException(responseContentAsString, aiParams);

        this.logErrorToHost(responseContentAsString);

        var errorData;
        switch (xhr.status) {
            case 400:
                errorData = this.handleHttpRequestError400(xhr, deferred);
                break;
            case 401:
                errorData = this.handleHttpRequestError401(xhr, deferred);
                break;
            case 402:
                errorData = this.handleHttpRequestError402(xhr, deferred);
                break;
            case 404:
                errorData = this.handleHttpRequestError404(xhr, deferred);
                break;
            case 405:
                errorData = this.handleHttpRequestError405(xhr, deferred);
                break;
            case 426:
                errorData = this.handleHttpRequestError426(xhr, deferred);
                break;
            case 500:
                errorData = this.handleHttpRequestError500(xhr, deferred);
                break;
            default:
                if (xhr.statusText === "timeout" || isAxiosTimeout) {
                    errorData = this.handleHttpRequestErrorTimeout(xhr, deferred);
                } else {
                    errorData = this.handleHttpRequestErrorUnknown(xhr, deferred);
                }
        }

        // TODO: Faire le ménage ici
        if (errorData && errorData.message && errorData.severity !== 10) {
            if (errorData.severity === 4) {
                errorData.message += ", id: " + errorCorrelationId;
                if (errorUrl) {
                    if (errorUrl.lastIndexOf("?") > -1) {
                        errorUrl = errorUrl.substring(0, errorUrl.lastIndexOf("?"));
                    }
                    errorData.message += ", url: " + errorUrl;
                }
            }

            const responseObject = this.getXhrResponseContentAsParsedJson(xhr);

            if (responseObject === "" || responseObject.Exception === undefined || responseObject.Exception.ErrorId !== this.concurrencyErrorId) {
                if (errorData.timeOut !== undefined) {
                    notifier.showError(errorData.message, "", { timeOut: errorData.timeOut });
                } else {
                    notifier.showError(errorData.message);
                }
                //user no longer access to web
                if (responseObject.Exception && responseObject.Exception.ErrorId === 7) {
                    routerHelper.navigateToRoute("Settings");
                }
            }

            if (errorData.showGlobalError) {
                routerHelper.showGlobalError();
            }
            else if (errorData.showVersionError) {
                routerHelper.showVersionError();
            }
        }
    }

    getXhrResponseContentAsString(xhr) {
        // Dans le cas d'un XHR jQuery, le responseType n'est pas pas renseigné (undefined)
        if (!xhr.responseType) {
            return xhr.responseText ? xhr.responseText : xhr.statusText;
        }

        // Dans le cas d'un XHR Axios, on doit appeler la propriété responseText seulement si le responseType correspond sinon ça lance une exception.
        if (xhr.responseType === "" || xhr.responseType === "text") {
            return xhr.responseText ? xhr.responseText : xhr.statusText;
        }

        // Autre réponse Axios.
        return xhr.response ? stringify(xhr.response) : xhr.statusText;
    }

    getXhrResponseContentAsParsedJson(xhr) {
        // jQuery: responseJSON / responseText
        var content = xhr.responseJSON ? JSON.parse(xhr.responseText) : "";

        if (!content) {
            if (!xhr.responseType || xhr.responseType === "" || xhr.responseType === "text") {
                content = xhr.responseText ? xhr.responseText : "";
            } else if (xhr.responseType === "json") {
                content = xhr.response ? xhr.response : "";
            } else {
                content = xhr.response ? xhr.response : "";
            }
        }

        return content;
    }

    /**
     * Determines if an error is an API ValidationException error.
     */
    isApiValidationExceptionError(error) {
        const validationException = this.getApiValidationExceptionFromError(error);
        return validationException !== null && validationException !== undefined;
    }

    /**
     * Extracts the API ValidationException object from a failed XHR request error.
     */
    getApiValidationExceptionFromError(error) {
        if (!error || !error.request || error.request.status !== 402) {
            return null;
        }

        const responseData = this.getXhrResponseContentAsParsedJson(error.request);

        if (responseData && responseData.Exception && responseData.Exception.ErrorId) {
            return responseData.Exception;
        }

        return null;
    }

    // 400 - Bad request
    handleHttpRequestError400() {
        return {
            severity: 3,
            message: resx.localize("err_BadRequest")
        };
    }

    // 401 - Unauthorized
    handleHttpRequestError401() {
        const message = resx.localize("err_Unauthorized");

        notifier.showWarning(message);
        userService.logout();
        return {
            severity: 10,
            message: message
        };
    }

    // 402 - Payment required
    // Used by the API to return a specific error (we should change this for 422).
    handleHttpRequestError402(xhr, deferred) {
        const responseData = this.getXhrResponseContentAsParsedJson(xhr);

        if (responseData && responseData.Exception && responseData.Exception.ErrorId === this.concurrencyErrorId) {
            notifier.showErrorDialogYesNo(resx.localize("err_ConcurrencyError"), resx.localize("ApiError.558"))
                .done((data) => {
                    if (data && deferred.funcConcurrence !== null) {
                        deferred.funcConcurrence();
                    }
                });
        }

        // TODO: Faire le ménage ici
        let errMsg = "";
        if (responseData && responseData.Exception && responseData.Exception.ErrorId) {
            const errorCode = `ApiError.${responseData.Exception.ErrorId}`;
            const errorLocalized = resx.localize(errorCode);

            if (errorCode != errorLocalized && responseData.Exception.ErrorParameters) {
                errMsg = this.substitutePlaceholders(errorLocalized, responseData.Exception.ErrorParameters);
            }
        }

        if (!errMsg) {
            errMsg = resx.localize("err_ReceivedFromMaestro") + " " + responseData.Exception.ErrorId.toString();
            if (responseData.Exception.Message) {
                errMsg += " : " + responseData.Exception.Message;
            }
        }

        return {
            showVersionError: responseData.Exception.ErrorId === this.versionMismatchErrorId,
            timeOut: 0,
            severity: responseData.Severity,
            message: errMsg
        };
    }

    // 404 - Not found
    handleHttpRequestError404() {
        return {
            severity: 4,
            message: resx.localize("err_PageNotFound")
        };
    }

    // 405 - Method not allowed
    handleHttpRequestError405() {
        return {
            severity: 4,
            timeOut: 0,
            message: resx.localize("err_MethodNotAllowed")
        };
    }

    // 426 - Upgrade required
    // Returned by the API when the frontend version != API version
    handleHttpRequestError426() {
        const message = resx.localize("err_VersionMismatchReload");

        notifier.showWarning(message);
        routerHelper.showLoading();

        window.setTimeout(function () {
            window.location.reload(true);
        },
        5000);

        return {
            severity: 10,
            message: message
        };
    }

    // 500 - Internal server error
    handleHttpRequestError500() {
        return {
            severity: 4,
            timeOut: 0,
            message: resx.localize("err_ServerError")
        };
    }

    // Timeout
    handleHttpRequestErrorTimeout() {
        return {
            severity: 3,
            timeOut: 0,
            message: (window.navigator.onLine ? resx.localize("err_ConnectionTimeout") : resx.localize("err_NetworkError"))
        };
    }

    // Other HTTP errors
    handleHttpRequestErrorUnknown(xhr) {
        if (!window.navigator.onLine) {
            routerHelper.hideLoading();
        }

        return {
            severity: (window.navigator.onLine ? 4 : 3),
            message: xhr.status + " - " + (window.navigator.onLine ? resx.localize("err_RequestError") : resx.localize("err_NetworkError"))
        };
    }

    substitutePlaceholders(message, replacementValues) {
        _.each(replacementValues,
            (data, key) => {
                var regex = new RegExp("{" + key + "}", "ig");
                message = message.replace(regex,
                    (match) => {
                        if (data.Type === 1 /* DateTime */) {
                            data.Value = dateHelper.formatDate("YYYY-MM-DD HH:mm", moment(data.Value));
                        }
                        return (typeof data !== "undefined") ? data.Value : match;
                    });
            });
        return message;
    }

    logErrorToHost(exception) {
        try {
            if (window.HostViewModel && window.HostViewModel.logError) {
                window.HostViewModel.logError(exception);
            }
        } catch (e) {
            // Ignorer l'erreur si on ne réussit pas à journaliser.
        }
    }
}
