import moment from "moment";
import { I18N } from "aurelia-i18n";
import { autoinject, computedFrom } from "aurelia-framework";

import { StringHelper } from "helpers/string-helper";
import { TimeListHelper } from "helpers/time-list-helper";
import { NotificationHelper } from "helpers/notification-helper";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";

import dispatch from "services/dispatchService";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class DetailHoursEdit {

    public serviceType: string = "";
    public readonly: boolean = true;
    public enableDateFormat: boolean = true;
    public increment: number = 0;
    public dispatchId: string = "";
    public labelFrom: string = "";
    public isTo: boolean = true;
    public labelTo: string = "";
    public routingParam: any;
    public oneDayWorkDuration: boolean = false;

    public dateFrom: Date = new Date();
    public dateTo: Date = new Date();

    public origdateFrom: Date = new Date();
    public origdateTo: Date = new Date();

    public errorMessage: string = "";
    public errorMessageTo: string = "";
    public diff: number = 0;
    public timeOptions: any;
    public isDirty: boolean = false;

    @computedFrom("dateFrom", "dateTo")
    get isDirtyCheck(): boolean {
        if (this.readonly) {
            this.isDirty = false;
            return this.isDirty;
        }

        this.isDirty = !dateHelper.areEquals(this.origdateFrom, this.dateFrom) || !dateHelper.areEquals(this.origdateTo, this.dateTo);

        return this.isDirty;
    }

    constructor(private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router) {
        this.notificationHelper = notificationHelper;
    }

    public bindQueryString(queryString: string): void {
        this.readonly = queryStringHelper.parseReadonly(queryString);
    }

    public bindViewModel(serviceType: string, dispatchId: any, dateFrom: string, dateTo: string, increment: string): void {
        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.dateFrom = moment(dateFrom).toDate();
        this.dateTo = moment(dateTo).toDate();
        this.origdateFrom = moment(dateFrom).toDate();
        this.origdateTo = moment(dateTo).toDate();

        this.initTimeOptions(parseInt(increment));
        //Get the time gap between Travel Start Time and Estimate Arrival Time
        this.diff = dateHelper.getTimeSpanInHour(dateFrom, dateTo);
        this.oneDayWorkDuration = !dateHelper.isAfter(dateTo, dateFrom);

        switch (this.routingParam.name) {
            case "Service_Detail_Action_Road":
                this.labelFrom = this.i18n.tr("TravelStartTime");
                this.labelTo = this.i18n.tr("EstimatedArrivalTime");
                this.isTo = true;
                break;

            case "Service_Detail_Action_Start":
                this.labelFrom = this.i18n.tr("ArrivalTime");
                this.labelTo = this.i18n.tr("EstimatedCompletedTime");
                this.isTo = true;
                this.enableDateFormat = !this.oneDayWorkDuration;
                break;
        }
        this.errorMessage = this.labelTo + this.i18n.tr("err_GreatherThan") + this.labelFrom;
    }

    public activate(params: any, routingParam: any): any {
        this.routingParam = routingParam;
        this.bindQueryString(params.q);
        this.bindViewModel(params.serviceType, params.dispatchId, decodeURIComponent(params.dateFrom), decodeURIComponent(params.dateTo), params.increment);
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public async save(): Promise<any> {
        const dFrom = dateHelper.getTime(this.dateFrom);
        const dTo = dateHelper.getTime(this.dateTo);

        if (this.routingParam.name === "Service_Detail_Action_Road" && dFrom > dTo) {
            const success = await this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_Service_On_Road_DateFromGreaterThanEndDate"));
            if (!success) {
                return;
            }
        }

        if (this.routingParam.name === "Service_Detail_Action_Start" && dFrom > dTo) {
            const success = await this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_Service_On_Start_DateFromGreaterThanEndDate"));
            if (!success) {
                return;
            }
        }

        if (this.routingParam.name === "Service_Detail_Action_Road") {
            await dispatch.setServiceCallEnRoute(this.dispatchId, dFrom, dTo).then(async (result: any) => {
                dispatch.serviceCallEnRouteNotify(this.dispatchId);
                this.isDirty = false;
                routerHelper.navigateBack();
            }, (err: any) => {
                if (StringHelper.startsWith(err.message, "Dispatch model not found:")) {
                    this.notificationHelper.showError(this.i18n.tr("DispatchModel") + " : " + err.message.split(":")[1], "", { timeOut: 0 });
                }
            });
        } else if (this.routingParam.name === "Service_Detail_Action_Start") {
            await dispatch.setServiceCallOnSite(this.dispatchId, dFrom, dateHelper.formatDateToSend(this.dateTo)).then(async (backOrderItems: any) => {
                this.isDirty = false;
                if (backOrderItems !== undefined && backOrderItems !== null && backOrderItems.length > 0) {
                    let warning = this.i18n.tr("msg_BackOrderMaterial");
                    let backOrderItemsDescription = "";

                    backOrderItems.forEach((boItem: any) => { backOrderItemsDescription = backOrderItemsDescription + boItem.Id + " - " + boItem.Description + "<br>"; });
                    warning = warning.replace("{0}", backOrderItemsDescription);

                    this.notificationHelper.showWarning(warning, "", { timeOut: 0 });

                    routerHelper.navigateBack();
                }
                routerHelper.navigateBack();
            }, (err: any) => {
                if (StringHelper.startsWith(err.message, "Dispatch model not found:")) {
                    this.notificationHelper.showError(this.i18n.tr("DispatchModel") + " : " + err.message.split(":")[1], "", { timeOut: 0 });
                }
            });
        }
    }

    public enterCurrentTime(): void {
        const startHour = moment();
        const endhour = moment();

        if (this.diff > 0) {
            endhour.add(this.diff, "hours");
        }

        //affichage
        this.dateFrom = dateHelper.setHoursMinOnDate(this.dateFrom, startHour.hours(), startHour.minutes());
        this.dateTo = dateHelper.setHoursMinOnDate(this.dateTo, endhour.hours(), endhour.minutes());
    }

    public isValid(): boolean {

        return false;
    }

    private initTimeOptions(increment: number): void {
        this.timeOptions = TimeListHelper.loadTimeList(increment);
    }
}
