import { bindingMode, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

interface Item {
    id: any;
    text: string;
}

@transient()
export class RadioButton {
    @bindable.string public titleKey: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay }) public selectedId: any;
    @bindable public items: Item[] = [];
    @bindable.booleanAttr public disabled: boolean = false;
    @bindable.booleanAttr public readonly: boolean = false;
    @bindable.booleanAttr public required: boolean = false;

    private itemClicked(item: Item): void {
        this.selectedId = item.id;
    }
}
