// GENERATED FILE - DO NOT MODIFY
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProcoreToolsProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async DeleteDirectCosts(projectCode: string | null, deleteAfterDate: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/tools/{projectCode}/transactions/delete",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            { deleteAfterDate: (deleteAfterDate === null || deleteAfterDate === undefined) ? undefined : deleteAfterDate }
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }
}
