import { inject, DOM } from "aurelia-framework";
import { CustomEventHelper } from "helpers/custom-event-helper";

@inject(Element)
export class SwipeToDelete {
    constructor(private readonly element: Element) {}

    public itemPressed(event: HammerInput): void {
        CustomEventHelper.dispatchEvent(this.element, "item-pressed", {});
    }

    public itemDeleted(event: HammerInput): void {
        CustomEventHelper.dispatchEvent(this.element, "item-deleted", {});
    }
}
