define([
], function() {
    "use strict";

    var exports = {
        tables: {
            picturesQueue: "PICTURES_QUEUE"
        },

        picturesQueue: function (store) {
            store.createIndex('entityType', 'entityType', { unique: false });
            store.createIndex('entityId', 'entityId', { unique: false });
            store.createIndex('trfStatus', 'trfStatus', { unique: false });
            store.createIndex('url', 'url', { unique: false });
            store.createIndex('jsonValue', 'jsonValue', { unique: false });
        }
    };

    return exports;
});
