import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ValidationError } from "api/enums/validation-error";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import { NotificationHelper } from "helpers/notification-helper";

@autoinject
export class ProcoreExceptionHelper {
  constructor(private readonly i18n: I18N,
              private readonly notificationHelper: NotificationHelper) { }

  public getMessageFromProcore(exception: any, entity?: ProcoreEntityToSynchronize): string {
    return this.getMessageFromProcoreExplicit(exception.ErrorId, exception.MessageFromProcore, entity);
  }

  public getMessageFromProcoreExplicit(errorId: any, messageFromProcore: string, entity?: ProcoreEntityToSynchronize): string {

    if (errorId === ValidationError.ProcoreSynchronizationFailedWithMessage) {
      const entityName = entity
                          ? this.i18n.tr(`Procore.ApiErrorEntities.${ProcoreEntityToSynchronize[entity]}`)
                          : "";

      return this.i18n.tr(`ApiError.${errorId}`).replace("{0}", entityName)
                                                .replace("{1}", messageFromProcore);
    } else if (errorId) {
      return this.i18n.tr(`ApiError.${errorId}`);
    }

    return "";
  }

  public toast(exception: any): void {
    const message = this.getMessageFromProcore(exception);

    this.notificationHelper.showError(message, "");
  }
}
