import { bindable } from "aurelia-framework";
import Logger from "core/logger";

export class GridFrame {
    @bindable
    public viewModel: any = null;

    public attached(): void {
        if (!this.viewModel) {
            Logger.warn("A view model should be bound to the current component.");
        }
    }
}
