// GENERATED FILE - DO NOT MODIFY
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallContractEquipmentReplaceModel } from "api/models/company/service/service-call-contract-equipment-replace-model";
import { ServiceCallContractEquipmentStatusModel } from "api/models/company/service/service-call-contract-equipment-status-model";
import { ServiceCallEquipmentBrandModel } from "api/models/company/service/service-call-equipment-brand-model";
import { ServiceCallEquipmentCategoryModel } from "api/models/company/service/service-call-equipment-category-model";
import { ServiceCallEquipmentDetailModel } from "api/models/company/service/service-call-equipment-detail-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallEquipmentProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async Get(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentStatusModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/equipment",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallContractEquipmentStatusModel[] | null = await this.get<ServiceCallContractEquipmentStatusModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallContractEquipmentStatusModel");
        return data;
    }

    public async GetEquipmentForMaintenance(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentStatusModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/equipment/maintenance",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallContractEquipmentStatusModel[] | null = await this.get<ServiceCallContractEquipmentStatusModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallContractEquipmentStatusModel");
        return data;
    }

    public async GetForEquipment(dispatchId: number, equipmentId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentStatusModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/equipment/{equipmentId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), equipmentId: (equipmentId === null || equipmentId === undefined) ? undefined : equipmentId.toString() },
            null
        );

        const data: ServiceCallContractEquipmentStatusModel | null = await this.get<ServiceCallContractEquipmentStatusModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallContractEquipmentStatusModel");
        return data;
    }

    public async GetForCallAndEquipment(callNumber: number, equipmentCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentDetailModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/{callNumber}/equipment/{equipmentCode}",
            { callNumber: (callNumber === null || callNumber === undefined) ? undefined : callNumber.toString(), equipmentCode: (equipmentCode === null || equipmentCode === undefined) ? undefined : equipmentCode },
            null
        );

        const data: ServiceCallEquipmentDetailModel | null = await this.get<ServiceCallEquipmentDetailModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallEquipmentDetailModel");
        return data;
    }

    public async Post(dispatchId: number, model: ServiceCallContractEquipmentStatusModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/equipment",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: boolean = await this.post<ServiceCallContractEquipmentStatusModel | null, boolean>(uri, model, requestConfig);
        return data;
    }

    public async GetContractEquipments(contractId: string | null, jobDate: Date | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/contract/equipment",
            null,
            { contractId: (contractId === null || contractId === undefined) ? undefined : contractId, jobDate: (jobDate === null || jobDate === undefined) ? undefined : jobDate.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallContractEquipmentModel[] | null = await this.get<ServiceCallContractEquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallContractEquipmentModel");
        return data;
    }

    public async UpdateContractEquipment(dispatchId: number, equipment: ServiceCallContractEquipmentModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/serviceCalls/contract/{dispatchId}/equipment",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: boolean = await this.post<ServiceCallContractEquipmentModel | null, boolean>(uri, equipment, requestConfig);
        return data;
    }

    public async GetNewServiceCallContractEquipments(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/contract/equipments",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallContractEquipmentModel[] | null = await this.get<ServiceCallContractEquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallContractEquipmentModel");
        return data;
    }

    public async ReplaceServiceCallContractEquipment(dispatchId: number, replaceModel: ServiceCallContractEquipmentReplaceModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/serviceCalls/contract/{dispatchId}/equipment/replace",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: boolean = await this.post<ServiceCallContractEquipmentReplaceModel | null, boolean>(uri, replaceModel, requestConfig);
        return data;
    }

    public async AllowedServiceCallContractEquipmentManagement(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/serviceCalls/contract/equipment/allowed-management",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async GetServiceCallContractEquipmentBrands(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentBrandModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/equipment/brands",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallEquipmentBrandModel[] | null = await this.get<ServiceCallEquipmentBrandModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallEquipmentBrandModel");
        return data;
    }

    public async GetServiceCallContractEquipmentCategories(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentCategoryModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/equipment/categories",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallEquipmentCategoryModel[] | null = await this.get<ServiceCallEquipmentCategoryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallEquipmentCategoryModel");
        return data;
    }
}
