import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";

@autoinject
export class ReceiptOfGoodsMemo {
    public readonly: boolean = false;
    public receiptId: number = 0;
    public orderNumber: string = "";
    public extension: string = "";
    public memo: string | null = "";
    public element: HTMLTextAreaElement | null = null;

    private minPaddingNoScroll: number = 2;

    public activate(params: any): void {
        const queryString = routerHelper.getQuerystring(params.q);
        this.receiptId = params.receiptId;
        this.memo = queryString.memo;
        this.orderNumber = queryString.orderNumber;
        this.extension = queryString.extension;
    }

    public attached(): void {
        this.resize();
    }

    public close(): void {
        routerHelper.navigateBack();
    }

    private resize(): void {
        if (this.element) {
            this.element.style.width = "100%";
            this.element.style.height = this.element.scrollHeight + this.minPaddingNoScroll + "px";
        }
    }
   
}
