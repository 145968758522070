import { autoinject } from "aurelia-framework";

import { PagingInfo } from "api/paging-info";
import { CustomerContactProxy } from "api/proxies/customer-contact-proxy";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ContactModel } from "api/models/company/contact/contact-model";

@autoinject()
export class CustomerContactService {
    private readonly customerContactProxy: CustomerContactProxy;

    constructor(customerContactProxy: CustomerContactProxy) {
        this.customerContactProxy = customerContactProxy;
    }

    public async getClientContactsLookup(customerCode: string, filter: string, pagingInfo: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<ContactLookupModel[]> {
        return await this.customerContactProxy.GetClientContactsLookup(customerCode, filter, pagingInfo, httpClientRequestConfig) || [];
    }

    public async getClientContacts(customerCode: string, filter: string, pagingInfo: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<ContactLookupModel[]> {
        return await this.customerContactProxy.GetClientContactsLookup(customerCode, filter, pagingInfo, httpClientRequestConfig) || [];
    }
}
