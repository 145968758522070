import { inject } from "aurelia-framework";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { default as AdditionalFieldsService } from "services/additionalFieldsService";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";
import { FormBase } from "pages/form-base";

@inject(AdditionalFieldsService, EventAggregator, I18N, routerHelper, NotificationHelper, AdditionalFieldMapper, Router)
export class AdditonnalFieldsServiceCall extends FormBase {
    actions = {};
    dispatchId = 0;
    readonly = true;
    additionalFieldsSource = AdditionalFieldsSourceType.ServiceCall;
    saveAdditionalFieldsButton = false;
    additionalFieldsService;
    currentFields = null;
    unModifiedFields = null;

    constructor(additionalFieldsService, eventAggregator, i18n, routerHelper, notificationHelper, additionalFieldMapper, router) {
        super(notificationHelper, i18n, router);
        this.additionalFieldsService = additionalFieldsService;
        this.eventAggregator = eventAggregator;
        this.routerHelper = routerHelper;
        this.additionalFieldMapper = additionalFieldMapper;
    }

    //#region Private Functions
    bindViewModel(dispatchId) {
        this.dispatchId = dispatchId;
        this.eventAggregator.publish("updateTitle", `${this.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${this.i18n.tr("Call")})`);
    }

    bindWidget() {
        this.actions = this.readonly ? {
            getAll: this.additionalFieldsService.getAllForServiceCall.bind(this, this.dispatchId)
        } : {
                getAll: this.additionalFieldsService.getAllForServiceCall.bind(this, this.dispatchId),
                setAll: this.additionalFieldsService.setAllForServiceCall.bind(this, this.dispatchId)
            };
    }
    //#endregion

   //#region Public Functions
   activate = function (params) {
       this.bindViewModel(params.dispatchId);
       const paramsQ = this.routerHelper.getQuerystring(params.q);
       this.readonly = !(paramsQ && paramsQ.readonly === "false");
       this.saveAdditionalFieldsButton = !this.readonly;
       this.bindWidget();
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        return this.additionalFieldMapper.isNotEqual(this.unModifiedFields, this.currentFields);
    }
}
