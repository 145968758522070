import { AdditionalFieldDefinitionSaveHelper } from "./interfaces/additional-field-definition-save-helper";

export class AdditionalFieldPhoneHelper implements AdditionalFieldDefinitionSaveHelper {
  public getSavedValue(item: any): any {
    return item.Value.replace("(", "")
                     .replace(")", "")
                     .replace("-", "")
                     .replace(" ", "");
  }
}
