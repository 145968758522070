import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { BambooSyncConfig } from "pages/bamboo-hr/models/bamboo-sync-config";
import { LocalStorageHelper } from "helpers/local-storage-helper";

@autoinject()
export class BambooHRApiProxy extends BaseProxy {

    constructor(
        modelDateTransformer: ModelDateTransformer,
        httpClientFactory: HttpClientFactory,
        apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    set bambooHRHttpClientUrl(url: string) {
        this.httpClient = this.httpClientFactory.getBambooHRHttpClient(url);
    }

    public async setBambooHrCreds(encodedCreds: string, requestConfig?: HttpClientRequestConfig): Promise<any> {
        const uri = this.buildUri(
            "set_creds",
            null,
            null
        );

        const data = { encodedCreds: encodedCreds };

        return await this.post<any, any>(uri, data, requestConfig);
    }

    public async startBambooHRSynchronization(userId: string, requestConfig?: HttpClientRequestConfig): Promise<any> {
        const uri = this.buildUri(
            "bamboohr",
            null,
            null
        );

        const data = { UserId: userId };

        return await this.post<any, any>(uri, data, requestConfig);
    }

    public async startBambooHRSynchronizationTimeOff(userId: string, requestConfig?: HttpClientRequestConfig): Promise<any> {
        const uri = this.buildUri(
            "bamboohr/time_off",
            null,
            null
        );

        const data = { UserId: userId };

        return await this.post<any, any>(uri, data, requestConfig);
    }

    public async getSyncRunDashboard(correlationId: string, requestConfig?: HttpClientRequestConfig): Promise<any> {

        const uri = this.buildUri(
            "bamboohr/" + correlationId + "",
            null,
            null
        );

        return await this.get<any>(uri, requestConfig);
    }

    public async getConfigurationStatus(requestConfig?: HttpClientRequestConfig): Promise<any> {

        const uri = this.buildUri(
            "configuration_status",
            null,
            null
        );

        return await this.get<any>(uri, requestConfig);
    }

    public async getConfiguration(requestConfig?: HttpClientRequestConfig): Promise<any> {

        const uri = this.buildUri(
            "fetch_configuration",
            null,
            null
        );

        return await this.get<any>(uri, requestConfig);
    }

    public async updateBambooApiKey(bambooApiKey: string, requestConfig?: HttpClientRequestConfig): Promise<any> {

        const uri = this.buildUri(
            "config_bamboohr_api_key",
            null,
            null
        );

        const data = { ApiKey: bambooApiKey };

        return await this.post<any, any>(uri, data, requestConfig);
    }

    public async updateBambooSubdomain(subDomain: string, requestConfig?: HttpClientRequestConfig): Promise<any> {

        const uri = this.buildUri(
            "config_bamboohr_sub_domain",
            null,
            null
        );

        const data = { SubDomain: subDomain };

        return await this.post<any, any>(uri, data, requestConfig);
    }

    public async attemptLoginWithBambooHr(requestConfig?: HttpClientRequestConfig): Promise<any> {

        const uri = this.buildUri(
            "attempt_login_with_bamboohr",
            null,
            null
        );

        return await this.get<any>(uri, requestConfig);
    }

}
