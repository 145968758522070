import { bindingMode, observable, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { Key } from "ts-keycode-enum";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { CompanyModel } from "api/models/common/company/company-model";

@transient()
export class Checkbox2 {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public checked: any;
    
    @bindable public model: any;
    @bindable public matcher: any;
    
    @bindable.booleanAttr public disabled: boolean = false;

    @bindable.string public theme: string = "primary";
    @bindable.string public iconClass: string = "fa-check";

    @bindable
    public isChecked: boolean = false;
    
    private checkboxElementRef!: HTMLElement;
    private readonly onKeyDownEventHandlerPointer!: EventListener;
    private viewModelHelper!: ViewModeHelper;
    
    constructor(private readonly element: Element, private readonly viewModeHelper: ViewModeHelper ) {

        this.viewModeHelper = viewModeHelper;

        if (viewModeHelper.getIsDesktopMode()) {
            this.onKeyDownEventHandlerPointer = (e: KeyboardEvent): void => {
                this.onKeyDownEventHandler(e);
            };
        }
    }

    public bind(): void {
        if (this.viewModeHelper.getIsDesktopMode()) {
            this.checkboxElementRef.addEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
        }
    }

    public detached(): void {
        if (this.viewModeHelper.getIsDesktopMode()) {
            this.checkboxElementRef.removeEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
        }
    }

    public toggleCheckbox(): void {
         this.isChecked = !this.isChecked;
    }

    private onKeyDownEventHandler(e: KeyboardEvent): void {

        const enterHandler = (): void => {
            this.toggleCheckbox();
        };

        switch (e.keyCode) {
            case Key.Space:
            case Key.Enter:
                return enterHandler();
            default:
                return;
        }
    }
}
