define([
    "services/requests",
    "helpers/apiHelper",
    "helpers/dateHelper"
], function (requests, apiHelper, dateHelper) {
    "use strict";

    var exports = {
        get: function () {
            //return {
            //    done: function (callback) {
            //        callback([
            //            "Lundi le 10 janvier 2017",
            //            "Lundi le 20 janvier 2017",
            //            "Lundi le 30 janvier 2017",
            //            "Lundi le 40 janvier 2017",
            //            "Lundi le 50 janvier 2017",
            //        ]);
            //    }
            //};
            return requests.getJson(
                apiHelper.appendQueryStringParameters(
                    apiHelper.getEmployeesBaseUrl() + "/current/payperiods",
                    {
                        maxStartDateTime: dateHelper.formatDateToSend(dateHelper.getDate()),
                        PageSize: 5
                    })
            );
        }
    };

    return exports;
});
