import { autoinject } from "aurelia-framework";
import documentService from "services/documentService";
import { SettingRepository } from "repositories/setting-repository";
import { DispatchTemplateService } from "services/dispatch-template-service";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class ProjectMaterialRequisitionEditDocumentAdd extends FormBase {
    public editId: number = 0;
    public readonly: boolean = false;
    public actions: any;
    public dispatchProjectCode: string = "";
    public isCategorySelectionAllowed: boolean = false;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly settingRepository: SettingRepository, private readonly dispatchTemplateService: DispatchTemplateService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<any> {
        this.editId = params.requisitionId;
        this.dispatchProjectCode = params.dispatchProjectCode;
        const dispatchTemplateId = this.settingRepository.getDispatchTemplate();

        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(dispatchTemplateId!);
        this.isCategorySelectionAllowed = dispatchTemplate!.AllowCategorySelection;

        this.actions = {
            save: documentService.saveMaterialRequisitionPicture.bind(this, params.requisitionId, dispatchTemplateId)
        };
    }
}
