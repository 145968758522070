import { SupplierContactProxy } from "./../api/proxies/supplier-contact-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ContactModel } from "api/models/company/contact/contact-model";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { PagingInfo } from "api/paging-info";

@autoinject()
export class SupplierContactService {
    constructor(private readonly supplierContactProxy: SupplierContactProxy) {}

    public async GetSupplierContacts(supplierId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ContactModel[] | null> {
        return this.supplierContactProxy.GetSupplierContacts(supplierId, filter, pagingInfo, requestConfig);
    }

    public async GetSupplierContactsLookup(supplierId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ContactLookupModel[] | null> {
        return this.supplierContactProxy.GetSupplierContactsLookup(supplierId, filter, pagingInfo, requestConfig);
    }
}
