import { inject } from "aurelia-framework";
import { ViewMode, ViewModeHelper } from "helpers/view-mode-helper";

/**
 * Automatically add the correct css class to input element depending on the current view-mode.
 *
 */

@inject(Element, ViewModeHelper)
export class ViewModeCssClassesSelectorCustomAttribute {
    private element: HTMLElement;
    private viewMode: ViewMode;

    constructor(element: HTMLElement, viewModeHelper: ViewModeHelper) {
        this.element = element;
        this.viewMode = viewModeHelper.getViewMode();
    }

    public bind(): void {
        const cssClass = this.getCssClassesFromViewMode();
        this.element.classList.add(cssClass);
    }

    private getCssClassesFromViewMode(): string {
        switch (this.viewMode) {
            case ViewMode.Mobile:
                return "mobile";
            default:
            case ViewMode.Desktop:
                return "desktop";
        }
    }
}
