import { PLATFORM, bindable } from "aurelia-framework";
import { Redirect } from "aurelia-router";

import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as moment } from "moment";

import { default as enumHelper } from "helpers/enumHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as attendanceHelper } from "helpers/attendanceHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as settingRepository } from "repositories/settingRepository";

import { default as dispatchService } from "services/dispatchService";
import { default as templateService } from "services/templateService";
import { default as attendanceService } from "services/attendanceService";

PLATFORM.moduleName("pages/projects/dailyEntry/timeEntry_templateTime.html");
PLATFORM.moduleName("pages/projects/dailyEntry/timeEntry_templateBonus.html");

import { inject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

//#region Private Functions
function bindViewModel(self, dispatchProjectCode, dispatchDate, querystring) {
    if (window.sessionStorage.getItem(self.currentTabShownKey)) {
        self.showBonus = window.sessionStorage.getItem(self.currentTabShownKey) == "true";
    } else {
        self.showBonus = false;
    }

    self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
    self.isTeamLeader = queryStringHelper.parseIsTeamLeader(querystring);
    self.readonly = queryStringHelper.parseReadonly(querystring);

    self.dispatchDate = dispatchDate;
    self.dispatchProjectCode = dispatchProjectCode;
}

function extractSectionCount(self, loadedData) {
    return _.countBy(loadedData, (item) => {
        if (item.Presence >= enumHelper.attendance().ONSITE) {
            return 'PRESENT';
        } else if (item.Presence === enumHelper.attendance().ABSENT) {
            return 'ABSENT';
        } else {
            return 'UNCONFIRMED';
        }
    });
}

function loadData(self) {
    return attendanceService.listForDate(self.dispatchProjectCode, self.dispatchDate, getAllowEquipmentTimeEntryForSimpleUser())
        .done( (loadedData) => {
            self.sectionsCount = extractSectionCount(self, loadedData);
            setSectionTitle(self, self.sectionsCount);

            mapLoadedData(self, loadedData);
            assignSelectedObservablesValue(self, [], []);
        });
}

function getAllowEquipmentTimeEntryForSimpleUser() {
    return templateService.getCurrentTemplateConfigs().AllowEquipmentTimeEntry;
}

function setSectionTitle(self, sectionsCount) {
    self.presentGroupTitle = resx.localize("Present_P") + " (" + (sectionsCount.PRESENT || 0) + ")";
    self.absentGroupTitle = resx.localize("Absent_P") + " (" + (sectionsCount.ABSENT || 0) + ")";
    self.unconfirmedGroupTitle = resx.localize("Unconfirmed_P") + " (" + (sectionsCount.UNCONFIRMED || 0) + ")";
}

function mapLoadedData(self, loadedData) {
    var lsPresent = [], lsAbsent = [], lsUnconfirmed = [];

    loadedData = _.map(loadedData, (item) => {
        item.lblName = item.EquipmentCode ? item.EquipmentCode + ' - ' + item.EquipmentDescription : item.EmployeeNumber + ' - ' + item.EmployeeFirstName + ' ' + item.EmployeeLastName;
        item.id = item.EquipmentCode ? "equipment-" + item.EquipmentCode : "emp-" + item.EmployeeNumber;
        item.selected = false;
        return item;
    });

    _.each(loadedData, (item) => {

        if (item.Presence >= enumHelper.attendance().ONSITE) {
            lsPresent.push(item);
        } else if (item.Presence === enumHelper.attendance().ABSENT) {
            lsAbsent.push(item);
        } else {
            lsUnconfirmed.push(item);
        }
    });

    self.showSelectMyEmployee = (_.find(loadedData, (item) => { return !item.IsTimeEntryResponsible; }) !== undefined);

    lsPresent = attendanceHelper.groupAndSortAttendances(lsPresent, false) || [];
    lsAbsent = attendanceHelper.groupAndSortAttendances(lsAbsent, false) || [];
    lsUnconfirmed = attendanceHelper.groupAndSortAttendances(lsUnconfirmed, false) || [];
    self.presentDispatchModel = (lsPresent.length > 0) ? _.first(_.first(lsPresent).Attendances).DispatchTemplateId : settingRepository.getDispatchTemplate();

    self.listPresent = attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(lsPresent);

    self.listPresentEmployee = attendanceHelper.getAttendancesFromEmployees(self.listPresent, false);
    self.listPresentEquipment = attendanceHelper.getAttendancesFromEmployees(self.listPresent, true);

    self.listAbsent = attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(lsAbsent);
    self.listUnconfirmed = attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(lsUnconfirmed);

    self.listPresentTeams = attendanceHelper.getTeamsFromGroupedAndSortedAttendances(lsPresent);
    self.listPresentTeamsForTimeEntry = self.listPresentTeams.reduce((teamEmployees, team) => teamEmployees.concat(team.Attendances), []);
    self.listAbsentTeams = attendanceHelper.getTeamsFromGroupedAndSortedAttendances(lsAbsent);
    self.listUnconfirmedTeams = attendanceHelper.getTeamsFromGroupedAndSortedAttendances(lsUnconfirmed);
}

function assignSelectedObservablesValue(self, selectedEntries, selectedBonusEntries) {
    //update observables
    self.selectedEntries = selectedEntries;
    self.selectedBonusEntries = selectedBonusEntries;

    self.listSelectedEmployee = selectedEntries.filter((x) => x.IsEmployee && x.TeamNumber === 0);
    self.listSelectedEquipment = selectedEntries.filter((x) => x.IsEquipment);
    self.listSelectedTeam = selectedEntries.filter((selectedItem) => selectedItem.IsEmployee && selectedItem.TeamNumber !== 0)
}

function updateSelectedEntries(self, selectedEntries, entry) {
    //add remove id from array
    var temp = _.find(selectedEntries, (item) => {
        return item.DispatchId === entry.DispatchId;
    });

    if (temp) {
        selectedEntries = _.without(selectedEntries, temp);
    } else {
        selectedEntries = [...selectedEntries, entry];
    }

    return selectedEntries;
}

function validateSingleNotTimeEntryResponsibleSelected(self, entry) {
    if (!entry.IsTimeEntryResponsible) {
        return showConfMsgTimeEntryTeamLeader([entry], !self.showBonus);
    } else {
        return new jQuery.Deferred().resolve();
    }
}

function validateNotTimeEntryResponsibleSelected(self, isTime) {
    if ( !self.isProjectResponsible != !self.isTeamLeader ) { //logical XOR: if projectResponsible and teamLeader,or if neither of them (employee) : do not notify
        var dfd = new jQuery.Deferred();
        dfd.resolve();
        return dfd;
    }

    var notMyEmployeesList = _.filter(self.selectedEntries, (item) => {
        return !item.IsTimeEntryResponsible;
    });

    return showConfMsgTimeEntryTeamLeader(notMyEmployeesList, isTime);
}

function showConfMsgTimeEntryTeamLeader(notMyEmployeesList, isTime) {
    var dfd = new jQuery.Deferred();

    if (notMyEmployeesList.length) {
        var empsList = "";
        _.each(_.first(notMyEmployeesList, 5), (item) => {
            empsList += (item.IsEquipment ? item.EquipmentCode + ' - ' + item.EquipmentDescription : item.EmployeeNumber + ' - ' + item.EmployeeFirstName + ' ' + item.EmployeeLastName) + "<br />";
        });
        if (notMyEmployeesList.length > 5) {
            empsList += "...<br/>";
        }

        this.notificationHelper.showConfirmation(resx.localize('msg_TeamLeadIsResponsibleForTimeEntryConfirmationText').replace("{employeesList}", empsList).replace('{time}', (isTime ? resx.localize('time') : resx.localize('bonus'))), resx.localize('Warning'))
            .done( (success) => {
                if (success) {
                    dfd.resolve();
                } else {
                    dfd.reject();
                }
            });
    } else {
        dfd.resolve();
    }
    return dfd;
}

function removeItemFromList(list, item) {
    var index = list.indexOf(item);

    if (index > -1) {
        list.splice(index, 1);
    }
    return list;
}

function selectAll(self, list, isFromBonus, myEmployeeOnly) {
    var selectedEntries = !isFromBonus ? [] : self.selectedEntries.filter((x) => !x.IsEmployee);
    var selectedBonusEntries = [];
    _.each(list, (team) => {
        _.each(team.Attendances, (item) => {
            if (isFromBonus) {
                if (!item.IsEquipment) {
                    if (myEmployeeOnly && !item.IsTimeEntryResponsible && item.selected) {
                        item.selected = false;
                        selectedEntries = removeItemFromList(selectedEntries, item);
                    } else if (!myEmployeeOnly || item.IsTimeEntryResponsible) {
                        item.selected = true;
                        selectedEntries.push(item);
                        selectedBonusEntries.push(item);
                    }
                }
            } else {
                if (item.Presence === enumHelper.attendance().ONSITE && !item.IsInactive) {
                    if (myEmployeeOnly && !item.IsTimeEntryResponsible && item.selected) {
                        item.selected = false;
                        selectedEntries = removeItemFromList(selectedEntries, item);

                        if (!item.IsEquipment) {
                            selectedBonusEntries = removeItemFromList(selectedBonusEntries, item);
                        }
                    } else if (!myEmployeeOnly || item.IsTimeEntryResponsible) {
                        item.selected = true;
                        selectedEntries.push(item);

                        if (!item.IsEquipment) {
                            selectedBonusEntries.push(item);
                        }
                    }
                }
            }
        });
    });

    assignSelectedObservablesValue(self, selectedEntries, selectedBonusEntries);
}

function selectAllForCardSection(self) {
    _.each(self.employeeTimeEntryCards, (card)=> {
        if (!card.isSelected) {
            card.selectAction();
        }
    });

    assignSelectedObservablesValue(self, self.selectedEntries, []);
}

function unSelectAllForCardSection(self) {
    var selectedEntries = [];

    _.each(self.employeeTimeEntryCards, (card)=> {
        if (card.isSelected) {
            card.selectAction();
        }
    });

    assignSelectedObservablesValue(self, self.selectedEntries, []);
}

function selectAllForSection(self, list) {
    _.each(list, (team) => {
        _.each(team.Attendances, (item) => {
            if (!item.selected) {
                self.selectEntry(item);
            }
        });
    });

    assignSelectedObservablesValue(self, self.selectedEntries, []);
}

function unSelectAllForSection(self, list) {
    var selectedEntries = [];

    _.each(list, (team) => {
        _.each(team.Attendances, (item) => {
            self.selectEntry(item);
        });
    });

    assignSelectedObservablesValue(self, self.selectedEntries, []);
}

//#endregion
@inject(I18N, NotificationHelper, Router)
export class TimeEntry extends FormBase {
    //#region Properties
    resx = resx;
    routerHelper = routerHelper;
    dateHelper = dateHelper;
    labelHelper = labelHelper;
    isProjectResponsible = false;
    isTeamLeader = false;
    readonly = false;
    sectionsCount = {};
    dispatchProjectCode = "";
    dispatchDate = "";
    showBonus = false;
    showSelectMyEmployee = false;
    listPresent = [];
    listPresentEmployee = [];
    listPresentEquipment = [];
    listAbsent = [];
    listUnconfirmed = [];
    listPresentTeams = [];
    listAbsentTeams = [];
    listUnconfirmedTeams = [];
    @bindable selectedEntries = [];
    selectedBonusEntries = [];
    listSelectedEmployee = [];
    listSelectedEquipment = [];
    listSelectedTeam = [];
    currentTabShownKey = "isFromBonusSection" + settingRepository.getUser();
    isDirty = false;
    drawers = {
        bonusEmpDrawer: true,
        bonusTeamDrawer: true,
        timePresentDrawer: true,
        timePresentEmpDrawer: true,
        timePresentEquipDrawer: true,
        timePresentTeamDrawer: true,
        timeUncDrawer: true,
        timeUncTeamDrawer: true,
        timeAbsDrawer: true,
        timeAbsTeamDrawer: true,
    };
    employeeTimeEntryCards = [];

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
    }

    checkDirty() {
        return this.isDirty;
    }

    //#region Public Functions

    activate(params) {
        bindViewModel(this, params.dispatchProjectCode, params.dailyEntryDate, params.q);
        return loadData(this);
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            this.notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
            return new Redirect("Settings");
        }

        return true;
    }

    goToUrlForDispatch (entry) {

        validateSingleNotTimeEntryResponsibleSelected(this, entry).done(()=>{
            var url = routerHelper.getRelativeUrl("dispatch/" + entry.DispatchId);
            url += routerHelper.addQuerystring({
                isProjectResponsible: this.isProjectResponsible,
                readonly: this.readonly,
                isTeamLeader: this.isTeamLeader,
                DispatchTemplateID: entry.DispatchTemplateId
            });

            routerHelper.navigate(url);
        });
    }

    genUrl(route) {
        var url = routerHelper.navigateTo(route, this.dispatchProjectCode, this.dispatchDate);
        return url +
            routerHelper.addQuerystring({
            isProjectResponsible: this.isProjectResponsible,
            readonly: this.readonly,
            isTeamLeader: this.isTeamLeader
            });
    }

    show(section) {
        this.showBonus = section === "bonus";
        window.sessionStorage.setItem(this.currentTabShownKey, this.showBonus);
    }

    selectCardEntry(entry, card) {
        this.selectEntry(entry);
        card.isSelected = !card.isSelected;
    }

    selectEntry (entry) {
        entry.selected = !entry.selected;

        var selectedEntries = this.selectedEntries;
        var selectedBonusEntries = this.selectedBonusEntries;

        selectedEntries = updateSelectedEntries(this, selectedEntries, entry);

        if (entry.EmployeeFirstName || entry.EmployeeLastName) {
            selectedBonusEntries = updateSelectedEntries(this, selectedBonusEntries, entry);
        }

        assignSelectedObservablesValue(this, selectedEntries, selectedBonusEntries);
    }

    isSelectedEntry(entryDispatchId) {
        return _.contains(this.selectedEntries, entryDispatchId);
    }

    selectAll (isFromBonus, myEmployeeOnly) {
        selectAll(this, this.listPresent.concat(this.listPresentTeams), isFromBonus, myEmployeeOnly);
    }

    selectAllForSection(section) {
        switch (section) {
            case "Employee":
                selectAllForCardSection(this);
                break;
            case "Equipment":
                selectAllForSection(this, this.listPresentEquipment);
                break;
            case "Team":
                selectAllForSection(this, this.listPresentTeams);
                break;
        }
    }

    unSelectAllForSection(section) {
        switch (section) {
            case "Employee":
                unSelectAllForCardSection(this);
                break;
            case "Equipment":
                unSelectAllForSection(this, this.listPresentEquipment);
                break;
            case "Team":
                unSelectAllForSection(this, this.listPresentTeams);
                break;
        }
    }

    addActivityClick (entryType) {
        validateNotTimeEntryResponsibleSelected(this, entryType !== "bonus").done(() => {
            var ids = [];
            var name = "";
            var defaultLaborActivityCode = "";
            if (entryType === "bonus") {
                ids = _.pluck(this.selectedBonusEntries, 'DispatchId').join();
                name = this.selectedBonusEntries[0].lblName;
                defaultLaborActivityCode = this.getDefaultActivity();
            } else {
                ids = _.map(this.selectedEntries,
                    (item) => {
                        return {
                            id: item.DispatchId,
                            isEmployee: item.IsEmployee,
                            name: item.lblName
                        };
                    });
                ids = JSON.stringify(ids);
            }
            var route = routerHelper.getRelativeUrl(entryType === "bonus" ? "bonus" : "edit");
            route += routerHelper.addQuerystring({
                isProjectResponsible: this.isProjectResponsible,
                readonly: this.readonly,
                dispatchIds: ids,
                editMode: "add",
                name: name,
                defaultLaborActivityCode: defaultLaborActivityCode,
                isTeamLeader: this.isTeamLeader
            });
            routerHelper.navigate(route);
        });
    }

    getDefaultActivity() {
        return this.allEmployeesSameDefaultLaborActivity(this.selectedEntries.filter(entry => entry.IsEmployee));
    }

    allEmployeesSameDefaultLaborActivity(array) {
        if (array.length === 0) {
            return "";
        }

        const sameActivity =  array.every(function (entry) {
            return  entry.DefaultLaborActivityCode === array[0].DefaultLaborActivityCode;
        });

        if (!sameActivity) { return ""; }

        return array[0].DefaultLaborActivityCode;
    }

    quit() {
        validateNotTimeEntryResponsibleSelected(this, true).done(() => {
            routerHelper.showLoading();

            var ids = [];
            var atLeastOneNoEndTime = false;
            _.each(this.selectedEntries,
                (item) => {
                    var gotTimeEntryCompleted = item.FirstEntryStartTime !== null &&
                        item.LastEntryEndTime !== null;
                    var gotTimeEntryNotCompleted = item.FirstEntryStartTime !== null &&
                        item.LastEntryEndTime === null;
                    var gotBonuses = item.Bonuses.length > 0;

                    if (!gotTimeEntryNotCompleted && (gotBonuses || gotTimeEntryCompleted)) {
                        ids.push(item.DispatchId);
                    } else {
                        atLeastOneNoEndTime = true;
                    }
                });

            dispatchService.setHasLeftDispatches(true, ids)
                .done(() => {
                    if (atLeastOneNoEndTime) {
                        this.notificationHelper.showWarning(resx.localize('msg_AtLeastOneHasNoEndTime'));
                    }
                    loadData(this);
                })
                .always(routerHelper.hideLoading);
        });
    }

    navigateToAttendance () {
        var url = this.genUrl('Project_Detail_Daily_Entry_Attendance');
        routerHelper.navigate(url, { replace: true, trigger: true });
    }

    standardInterface () {
        var timeEntryMode = templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
        if (timeEntryMode === 1) {
            return true;
        } else {
            return false;
        }
    }

    toggleDrawer (drawer) {
        this.drawers[drawer] = !this.drawers[drawer];
    }

    createEmployeeTimeEntryCards(attendance) {
        const card = {
            model: attendance,
            class: `${!attendance.IsTimeEntryResponsible ? "background-light-grey": ''}`,
            id: "card_" + attendance.DispatchId,
            isSelected: false,
            isSelectable: !this.readonly,
            displayLines: [{
                splitDisplays: [],
            }]
        };

        var firstLineSplittedDisplay = [];
        firstLineSplittedDisplay.push({ display: attendance.lblName, class: "text-primary font-weight-bold", action: this.goToUrlForDispatch.bind(this, attendance) });
        if (attendance.TradeId && attendance.TradeDescription) {
            firstLineSplittedDisplay.push({ display: `${attendance.TradeId} - ${attendance.TradeDescription}`, icon: "fa fa-user-circle-o", class: "participant-occupation"});
        }
        if (attendance.FirstEntryStartTime) {
            firstLineSplittedDisplay.push({ class: "far fa-horizontal-rule fa-rotate-90"});
            firstLineSplittedDisplay.push({ display: `${dateHelper.getTime(attendance.FirstEntryStartTime) + ' - ' + (attendance.LastEntryEndTime ? dateHelper.getTime(attendance.LastEntryEndTime) : '...')}`, class: "d-inline-block"});
        }
        if (attendance.RequiredTomorrow) {
            firstLineSplittedDisplay.push({ class: "far fa-horizontal-rule fa-rotate-90"});
            firstLineSplittedDisplay.push({ display: `${resx.localize('RequiredTomorrow')}`, class: "participant-required d-inline-block", icon: "fa fa-check m-0"});
        }
        firstLineSplittedDisplay.push({ display: `${attendance.IsInactive ? resx.localize('Inactive') : (dateHelper.parseTimeToDecimal(dateHelper.formatTimeSpan(attendance.TimeSpent)) > 24 ? '24:00+' : dateHelper.formatTimeSpan(attendance.TimeSpent))}`, class: "float-right  pt-1" });

        var equipmentLines = [];
        attendance.LinkedEquipments.forEach(linkedEquipment => {
            var equipmentSplitDisplay = [];
            equipmentSplitDisplay = [{ display:`${linkedEquipment.EquipmentCode + ' - ' + linkedEquipment.EquipmentDescription}`, class:"participant-equipment mt-0 ml-3", icon: "fa fa-link disabled py-0" }];
            equipmentSplitDisplay.push({ display:`${dateHelper.parseTimeToDecimal(dateHelper.formatTimeSpan(linkedEquipment.TimeSpent)) > 24 ? '24:00+' : dateHelper.formatTimeSpan(linkedEquipment.TimeSpent)}`, class: "float-right"});

            equipmentLines.push({splitDisplays: equipmentSplitDisplay});
        });

        card.displayLines[0].splitDisplays.push(...firstLineSplittedDisplay);

        if (attendance.LinkedEquipments) {
            card.displayLines.push(...equipmentLines);
        }
        if (attendance.HasLeft) {
            card.displayLines.push({ display: `${resx.localize('HasLeft')}`, class: "participant-description"});
        }

        card.selectAction = !attendance.IsInactive ? this.selectCardEntry.bind(this, attendance, card) : null;

        var foundCardIndex = this.employeeTimeEntryCards.findIndex((x) => x.id === "card_" + attendance.DispatchId);
        if (foundCardIndex !== -1) {
            this.employeeTimeEntryCards[foundCardIndex] = card;
        } else {
            this.employeeTimeEntryCards.push(card);
        }

        return card;
    }
}
