import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import enumHelper, { servicePresence } from "helpers/enumHelper";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import dispatchService from "services/dispatchService";

@autoinject
export class ServiceAssignation {

    public actions: any = {};
    public enumPresence: servicePresence = enumHelper.servicePresence();
    public dateHelper: typeof dateHelper = dateHelper;
    public routerHelper: typeof routerHelper = routerHelper;
    public dispatchId: string = "";
    public serviceType: string = "";
    public readonly: boolean = false;
    public detail: any = {};
    public subheaderTitle: string = "";
    public subheaderCssClass: string = "";
    public date: Date = dateHelper.getNow();
    public errorMessage: string = this.i18n.tr("err_DateGreaterThanNow");

    constructor(private readonly i18n: I18N) {}

    public activate(params: any): void {
        this.bindViewModel(params.dispatchId, params.serviceType, params.q);
    }

    public bindViewModel(dispatchId: string, serviceType: string, querystring: string): void {
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
        this.getDataFromQuerystring(querystring);
    }

    public getDataFromQuerystring(q: string): void {
        const queryString = routerHelper.getQuerystring(q);

        this.detail = JSON.parse(queryString.detail);
        this.subheaderCssClass = queryString.subheaderCssClass;
        this.subheaderTitle = queryString.subheaderTitle;
        this.readonly = queryStringHelper.parseReadonly(q);
        this.actions = {
            save: async (selectedDate: Date): Promise<void> => {
                await this.saveDate(selectedDate);
            }
        };
    }

    public genUrl(): string {
        return routerHelper.navigateTo("Service_Detail_Information", this.serviceType, this.dispatchId);
    }

    public async saveDate(selectedDate: Date): Promise<void> {
        if (this.detail.AssignedDate) {
            const assignedDate = dateHelper.dateFromUTC(this.detail.AssignedDate);
            selectedDate = dateHelper.setHoursMinOnDate(selectedDate, assignedDate.getHours(), assignedDate.getMinutes());
        } else {
            let unplannifiedDate = dateHelper.dateFromUTC(selectedDate);
            if (dateHelper.isToday(unplannifiedDate)) {
                unplannifiedDate = new Date();
            }
            selectedDate = unplannifiedDate;
        }

        await this.saveAccept(selectedDate);
    }

    public async saveAccept(selectedDate: Date): Promise<void> {
        const assignDateToSend = dateHelper.formatDateToSend(selectedDate);

        await dispatchService.setServiceCallAccept(this.dispatchId, assignDateToSend)
            .then(() => {
                const travelStartDate = this.detail.TravelStartDate || assignDateToSend;
                let url = routerHelper.navigateTo("Service_Detail_Action_Road", this.serviceType, this.dispatchId, travelStartDate, assignDateToSend, this.detail.TimeIncrement);
                url += routerHelper.addQuerystring({ readonly: false });
                routerHelper.navigate(url, {replace: true});
            });
    }

}
