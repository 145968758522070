import Logger from "core/logger";

// TODO ML: Refactoriser ça
define([
    "require",
    "jquery",
    "underscore",
    "repositories/settingRepository",
    "services/securityService",
    "moment"
], function (require, jQuery, _, settingRepository, securityService, moment) {
    "use strict";

    var exports = function () {
        var self = this;
        var appInsights = window.appInsights;
        var clientNumber = 0;
        var lastGetTime = null;
        var enabled = false;

        //#region Private Functions
        function setAuthenticatedUserContext(signInId) {
            if (signInId) {
                let validatedId = signInId.toLowerCase();
                validatedId = validatedId.replace(/[,;=| ]+/g, "_");

                appInsights.setAuthenticatedUserContext(validatedId);
            }
        }

        function initAppInsights() {
            var config = appInsights.config;
            config.enableDebug = false;
            config.verboseLogging = true;
            config.disableTelemetry = false;

            appInsights.config = config;

            const username = settingRepository.getUser();
            setAuthenticatedUserContext(username);
        }

        function validateIfGetIsNeeded() {
            var now = moment();
            if (!lastGetTime || now.diff(moment(lastGetTime), "minutes") >= 30) {
                initFromSecurityService(true);
            }
        }

        function configData() {
            return {
                "Maestro.Component": "Mobile" || null,
                "Maestro.Version": settingRepository.getVersion() || null,
                "Maestro.ClientNumber": clientNumber || null,
                "Maestro.User": settingRepository.getUser().toLowerCase() || null,
                "Maestro.CompanyTag": settingRepository.getCompany() || null,
                "Maestro.Language": settingRepository.getLanguage() || null,
                "Maestro.DispatchTemplate": settingRepository.getDispatchTemplate() || null,
                "Maestro.UnitSystem": settingRepository.getUnitSystem() || null,
                "Maestro.UserAccessType": settingRepository.getUserAccessType() || null
            };
        }

        function initFromSecurityService(forceGet) {
            securityService.getClientInfo(forceGet).done(function (clientInfo) {
                lastGetTime = moment().toISOString();
                if (clientInfo.AppInsightsEnable) {
                    initAppInsights();

                    enabled = true;
                    clientNumber = clientInfo.ClientNumber;
                    self.configData = configData();
                } else {
                    enabled = false;
                    appInsights.config.disableTelemetry = true;
                }
            });
        }
        //#endregion

        self.trackPageView = function(pageName, url) {
            validateIfGetIsNeeded();

            if (!enabled) {
                return;
            }

            try {
                appInsights.trackPageView(pageName, url, self.configData);
            } catch (ex) {
                self.trackException(JSON.stringify(ex));
            }
        }

        self.trackEvent = function(event, data) {
            if (!enabled) {
                return;
            }

            var configData = self.configData;
            _.extend(configData, data);

            try {
                appInsights.trackEvent(event, configData);
            } catch (ex) {
                self.trackException(JSON.stringify(ex));
            }
        }

        self.trackException = function(exception, extraData) {
            if (!enabled) {
                return;
            }

            try {
                var configData = self.configData;
                _.extend(configData, extraData || {});
                appInsights.trackException(exception, null, configData);
            } catch (ex) {
                Logger.error(JSON.stringify(ex));
            }
        }

        self.init = function () {
            initFromSecurityService(false);
        };

        return self;
    };

    return new exports();
});