import { inject, bindable } from "aurelia-framework";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

import { TakeoffSoftwares } from "enums/takeOffSoftwares";
import { TakeoffFormats } from "enums/takeOffFormats";
import { TakeoffVariableActionStatus } from "enums/takeoffVariableActionStatus";

export class TakeoffVariableHelper {
    constructor() {
        this.resx = resx;
    }

    static isVariablesWithEmptyName(v) {
        return !v.VariableName || v.VariableName.length === 0 || v.VariableName.match(/^\s *$/);
    }

    static isVariablesWithNameTooLong(v) {
        return v.VariableName.length > 100;
    }

    static isVariablesWithDuplicateName(v1, variables) {
        return undefined !== variables.find(v2 => v2 !== v1 && v2.VariableName.trim() === v1.VariableName.trim());
    }

    static isVariableWithoutQuantity(v) {
        return v.Quantity === null;
    }

    static isVariableWithoutUnit(v) {
        return !v.Unit || v.Unit.length === 0 || v.Unit.match(/^\s *$/);
    }

    validateVariables(variables) {
        const variablesToValidate = variables.filter(v => v.Action !== TakeoffVariableActionStatus.Deleted.id);

        for (var i = 0; i < variables.length; i++) {
            var variable = variables[i];
            variable.validationErrors = [];
            variable.validationErrorTooltips = {};

            if (TakeoffVariableHelper.isVariablesWithEmptyName(variable)) {
                this.addValidationErrorAndTooltip(variable, "VariableName", this.resx.localize("VariableNameMustBeNonEmpty"));
            } else if (TakeoffVariableHelper.isVariablesWithNameTooLong(variable)) {
                this.addValidationErrorAndTooltip(variable, "VariableName", this.resx.localize("VariableNameMustBe100Char"));
            } else if (TakeoffVariableHelper.isVariablesWithDuplicateName(variable, variablesToValidate)) {
                this.addValidationErrorAndTooltip(variable, "VariableName", this.resx.localize("VariableNameMustBeUnique"));
            }

            if (TakeoffVariableHelper.isVariableWithoutQuantity(variable)) {
                this.addValidationErrorAndTooltip(variable, "Quantity", this.resx.localize("VariableQuantityMustBeNonEmpty"));
            }

            if (TakeoffVariableHelper.isVariableWithoutUnit(variable)) {
                this.addValidationErrorAndTooltip(variable, "Unit", this.resx.localize("VariableUnitMustBeNonEmpty"));
            }
        }

        return !_.any(variablesToValidate, v => v.validationErrors.length > 0);
    }

    addValidationErrorAndTooltip(variable, fieldName, localizedLabel) {
        variable.validationErrors.push({
            field: fieldName,
            value: localizedLabel
        });
        variable.validationErrorTooltips[fieldName] = localizedLabel;
    }

    parseServerDuplicateValidationNameError(errorResponseException, variables) {
        _.each(errorResponseException.ErrorParameters.DuplicateVariableName.Value.split(', '), (duplicateVariableName) => {
            _.each(variables, (variable) => {
                if (variable.VariableName === duplicateVariableName) {
                    variable.validationErrors.push({
                        field: "VariableName",
                        value: this.resx.localize("VariableNameMustBeUnique")
                    });
                    variable.validationErrorTooltips.VariableName = this.resx.localize("VariableNameMustBeUnique");
                }
            });
        });
    }
}