import { EquipmentHistoryItemType } from "./equipment-history-item-type";

export class EquipmentHistoryItem {
  public Id: number = 0;
  public Displayed: boolean = true;
  public Title: string | null = null;
  public Date: string | null = null;
  public Description: string | null = null;
  public Description2: string | null = null;
  public Type: EquipmentHistoryItemType | null = null;
  public IsWorkOrder: boolean = false;
}
