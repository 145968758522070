// GENERATED FILE - DO NOT MODIFY
import { CallReturnEquipmentMaintenanceTaskModel } from "api/models/company/service/call-return-equipment-maintenance-task-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallDispatchEquipmentMaintenanceTaskProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetTasks(dispatchId: number, equipmentId: number, maintenanceId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CallReturnEquipmentMaintenanceTaskModel[] | null> {
        const uri = this.buildUri(
            "/service-calls/dispatch/{dispatchId}/equipment-maintenance-task",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { equipmentId: (equipmentId === null || equipmentId === undefined) ? undefined : equipmentId.toString(), maintenanceId: (maintenanceId === null || maintenanceId === undefined) ? undefined : maintenanceId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CallReturnEquipmentMaintenanceTaskModel[] | null = await this.get<CallReturnEquipmentMaintenanceTaskModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.CallReturnEquipmentMaintenanceTaskModel");
        return data;
    }

    public async SaveTasks(dispatchId: number, tasks: Array<CallReturnEquipmentMaintenanceTaskModel | null> | null, copyValuesForSameMaintenance: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-calls/dispatch/{dispatchId}/equipment-maintenance-task",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { copyValuesForSameMaintenance: (copyValuesForSameMaintenance === null || copyValuesForSameMaintenance === undefined) ? undefined : copyValuesForSameMaintenance.toString() }
        );

        await this.post<Array<CallReturnEquipmentMaintenanceTaskModel | null> | null, void>(uri, tasks, requestConfig);
    }
}
