import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationSaveModel } from "api/models/company/service-call/service-call-quotation-save-model";
import { ServiceCallQuotationPriceProxy } from "api/proxies/service-call-quotation-price-proxy";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { autoinject } from "aurelia-framework";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { CloneHelper } from "helpers/cloneHelper";
import { ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { NumberHelper } from "helpers/number-helper";

@autoinject()
export class ServiceCallQuotationPriceService {
    public maxProfitPercentage: number = 100;
  
    constructor(
        private readonly serviceCallQuotationPriceProxy: ServiceCallQuotationPriceProxy,
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly viewModeHelper: ViewModeHelper
    ) {
    }

    public async resetPrices(quotation: ServiceCallQuotationDetailsModel): Promise<void> {
        if (quotation && quotation.Items) {
            quotation.Items.forEach((item: ServiceCallQuotationItemModel) => {
                if (quotation.IsLockedQuotation && item.Id > 0) {
                    return;
                }

                if (item.Type !== ServiceCallQuotationItemType.BillingItem && item.Type !== ServiceCallQuotationItemType.MiscCost && item.IsBillable) {
                    item.ProfitMargin = quotation.ProfitMargin;
                } else {
                    item.ProfitMargin = 0;
                }
               
                item.UnitPrice = 0;
            });

            await this.updatePrices(quotation);
        }
    }

    public async updatePrices(quotation: ServiceCallQuotationDetailsModel): Promise<void> {
        if (!quotation.ContractId && !quotation.IsWorkOrder) {
            return;
        }

        if (!quotation.ProjectId && quotation.IsWorkOrder) {
            return;
        }

        let saveModel = this.serviceCallQuotationService.getSaveModel(quotation);
        saveModel = await this.getPrices(saveModel);
        this.applyPrices(quotation, saveModel);
    }

    public async getPrices(quotation: ServiceCallQuotationSaveModel): Promise<ServiceCallQuotationSaveModel> {
        return await this.serviceCallQuotationPriceProxy.GetPrices(quotation) as ServiceCallQuotationSaveModel;
    }

    public applyPrices(quotation: ServiceCallQuotationDetailsModel, quotationWithPrices: ServiceCallQuotationSaveModel): void {
        quotation.TotalCost = quotationWithPrices.TotalCost;
        quotation.TotalSelling = quotationWithPrices.TotalSelling;
        quotation.Tax = quotationWithPrices.Tax;
        quotation.TaxIncluded = quotationWithPrices.TaxIncluded;
        quotation.Total = quotationWithPrices.Total;
        quotation.Tax1 = quotationWithPrices.Tax1;
        quotation.Tax2 = quotationWithPrices.Tax2;

        for (let i = 0; i < quotation.Items!.length && i < quotationWithPrices.Items!.length; i++) {
            quotation.Items![i]!.UnitCost = quotationWithPrices.Items![i]!.UnitCost;
            quotation.Items![i]!.TotalCost = quotationWithPrices.Items![i]!.TotalCost;
            quotation.Items![i]!.UnitPrice = quotationWithPrices.Items![i]!.UnitPrice;
            quotation.Items![i]!.DiscountRate = quotationWithPrices.Items![i]!.DiscountRate;
            quotation.Items![i]!.Discount = quotationWithPrices.Items![i]!.Discount;
            quotation.Items![i]!.TotalPrice = quotationWithPrices.Items![i]!.TotalPrice;

            quotation.Items![i]!.BillableAmount = quotationWithPrices.Items![i]!.BillableAmount;
            quotation.Items![i]!.Tax1AccountId = quotationWithPrices.Items![i]!.Tax1AccountId;
            quotation.Items![i]!.Tax1 = quotationWithPrices.Items![i]!.Tax1;
            quotation.Items![i]!.Tax1IncludedOrExcluded = quotationWithPrices.Items![i]!.Tax1IncludedOrExcluded;
            quotation.Items![i]!.Tax1FederalOrProvincial = quotationWithPrices.Items![i]!.Tax1FederalOrProvincial;
            quotation.Items![i]!.Tax1Rate = quotationWithPrices.Items![i]!.Tax1Rate;
            quotation.Items![i]!.Tax1PercentageRecoverable = quotationWithPrices.Items![i]!.Tax1PercentageRecoverable;
            quotation.Items![i]!.Tax1Priority = quotationWithPrices.Items![i]!.Tax1Priority;
            quotation.Items![i]!.Tax2AccountId = quotationWithPrices.Items![i]!.Tax2AccountId;
            quotation.Items![i]!.Tax2 = quotationWithPrices.Items![i]!.Tax2;
            quotation.Items![i]!.Tax2IncludedOrExcluded = quotationWithPrices.Items![i]!.Tax2IncludedOrExcluded;
            quotation.Items![i]!.Tax2FederalOrProvincial = quotationWithPrices.Items![i]!.Tax2FederalOrProvincial;
            quotation.Items![i]!.Tax2Rate = quotationWithPrices.Items![i]!.Tax2Rate;
            quotation.Items![i]!.Tax2PercentageRecoverable = quotationWithPrices.Items![i]!.Tax2PercentageRecoverable;
            quotation.Items![i]!.Tax2Priority = quotationWithPrices.Items![i]!.Tax2Priority;
            quotation.Items![i]!.FinalBillableAmount = quotationWithPrices.Items![i]!.FinalBillableAmount;
            quotation.Items![i]!.BilledQuantity = quotationWithPrices.Items![i]!.BilledQuantity;

            if (quotation.IsLumpSum === ServiceCallQuotationPricingType.LumpSumPlus) {
                if (quotation.Items![i]!.IsBillable && quotation.Items![i]!.ProfitMargin === 0) {
                    if (quotation.ProfitMargin === 0) {
                        quotation.Items![i]!.ProfitMargin = this.UpdateItemProfitMargin(quotation.Items![i]!);
                    } else {
                        quotation.Items![i]!.ProfitMargin = quotation.ProfitMargin;
                        quotation.Items![i]!.UnitPrice = this.UpdateItemUnitPriceWithProfitMargin(quotation.ProfitMargin, quotation.Items![i]!);
                    }
                }
            }
        }

        quotation.Profit = quotation.TotalSelling - quotation.TotalCost;
        quotation.ProfitPercentage = quotation.TotalSelling > 0 ? (quotation.Profit / quotation.TotalSelling) : 0;
    }

    public async GetItemPrices(quotation: ServiceCallQuotationDetailsModel, quotationItem: ServiceCallQuotationItemModel): Promise<ServiceCallQuotationItemModel> {
        if (!quotation || !quotation.Items) {
            return quotationItem;
        }

        if (this.viewModeHelper.getIsDesktopMode() && quotation.IsLumpSum === ServiceCallQuotationPricingType.LumpSumPlus) {
            let copyQuotation: ServiceCallQuotationDetailsModel | null = null;
            copyQuotation = CloneHelper.deepClone(quotation);

            if (copyQuotation) {
                copyQuotation.Items = [];
                copyQuotation.Items.push(quotationItem);

                await this.updatePrices(copyQuotation);
                const itemPricesUpdated = copyQuotation.Items[0];
                if (itemPricesUpdated) {
                    return itemPricesUpdated;
                }
            }
        }
        return quotationItem;
    }

    public UpdateItemUnitPriceWithProfitMargin(profitMargin: number, quotationItem: ServiceCallQuotationItemModel): number {
        if (!quotationItem.IsBillable) {
            return 0;
        }

        if (quotationItem.UnitCost === 0 && quotationItem.Type === ServiceCallQuotationItemType.BillingItem) {
            return quotationItem.UnitPrice;
        }

        if (profitMargin !== 0) {
            quotationItem.UnitPrice = Number.parseFloat((quotationItem.UnitCost / (1 - profitMargin / 100)).toPrecision(6));
            return quotationItem.UnitPrice;
        }
        return quotationItem.UnitCost;
    }

    public UpdateItemProfitMargin(quotationItem: ServiceCallQuotationItemModel): number {
        if (quotationItem.IsBillable && quotationItem.UnitPrice > 0) {
            const margin = Number.parseFloat(((quotationItem.UnitPrice - quotationItem.UnitCost) / quotationItem.UnitPrice * 100).toPrecision(6));
            if (margin < this.maxProfitPercentage) {
                quotationItem.ProfitMargin = margin;
                return quotationItem.ProfitMargin;
            }
        }
        return 0;
    }

}
