import logger from "core/logger";

export abstract class BaseEnumToIcon {
    protected abstract get mappings(): { [key: string]: string };

    protected toView(value: string): string {
        const icon = this.mappings[value];

        if (icon === undefined) {
            logger.warn("Could not convert enum to icon", value);
            return "fa fa-question-circle";
        }

        return icon;
    }
}
