import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ProcoreSynchronizationStatus } from "api/enums/procore-synchronization-status";
import { ProcoreTransactionType } from "api/enums/procore-transaction-type";
import { SynchronizationStatus } from "api/enums/synchronization-status";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { ActivitiesSynchronizerResult } from "api/models/procore/synchronizers/activities/activities-synchronizer-result";
import { BudgetSynchronizationResult } from "api/models/procore/synchronizers/budgets/budget-synchronization-result";
import { ProjectSynchronizationResult } from "api/models/procore/synchronizers/projects/project-synchronization-result";
import { SupplierSynchronizationResult } from "api/models/procore/synchronizers/suppliers/supplier-synchronization-result";
import { DirectCostsDeleteSynchronizationResult } from "api/models/procore/synchronizers/transactions/direct-costs-delete-synchronization-result";
import { TransactionResult } from "api/models/procore/synchronizers/transactions/transaction-result";
import { TransactionsSynchronizationResult } from "api/models/procore/synchronizers/transactions/transactions-synchronization-result";
import { ProcoreSynchronisationProxy } from "api/proxies/procore-synchronisation-proxy";
import { ProcoreToolsProxy } from "api/proxies/procore-tools-proxy";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { ProcoreGridDirectCostDeleteResult } from "pages/procore/synchronization/composition/grid/models/procore-grid-direct-cost-delete-result";
import { ProcoreGridDirectCostResult } from "pages/procore/synchronization/composition/grid/models/procore-grid-direct-cost-result";
import { ProcoreGridGroup } from "pages/procore/synchronization/composition/grid/models/procore-grid-group";
import { ProcoreGridItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-item";
import { ProcoreExceptionHelper } from "pages/procore/synchronization/helpers/procore-exception-helper";
import { ProcoreGridItemTransformer } from "./helpers/procore-grid-item-transformer";

@autoinject
export class ProcoreToolsService {
  private failedResult: any = {
    Warnings: [],
    Errors: [],
    SynchronizationStatus: ProcoreSynchronizationStatus.Failure,
    EntitiesSynchronizedCount: 0,
    TransactionResults: []
  };

  private language: string | null = "fr";

  constructor(
    private proxy: ProcoreToolsProxy,
    private i18n: I18N,
    private readonly notificationHelper: NotificationHelper,
    private readonly procoreGridItemTransformer: ProcoreGridItemTransformer,
    private readonly exceptionHelper: ProcoreExceptionHelper) {
      this.language = localStorage.getItem("maestro_user_profile_LANGUAGE");
    }

  public async DeleteDirectCosts(item: ProcoreGridItem, date: Date | undefined): Promise<number | null> {
    try {
      const dateString = date ? date.toISOString().split("T")[0] : null;
      return await this.proxy.DeleteDirectCosts(item.Code, dateString);
    } catch (e) {
      this.exceptionHandling(e, ProcoreEntityToSynchronize.DirectCostItems);
    }
    return null;
  }

  private transformErrors(errors: Array<ProcoreError | null> | null, language: string | null): string[] {
    if (!errors) { return []; }

    return errors.map((error: ProcoreError): string => {
      const message = language === "fr" ? error.MessageFr
        : error.MessageEn;

      if (!message) {
        return "";
      }

      return message;
    }) as string[];
  }

  private exceptionHandling(e: any, entity: ProcoreEntityToSynchronize): void {
    let message = "";
    if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
      message = this.exceptionHelper.getMessageFromProcore(e.response.data.Exception, entity);
    } else {
      message = e.response.data.Exception.Message;
    }

    const error = {
      MessageFr: message,
      MessageEn: message
    } as ProcoreError;

    const errors = this.procoreGridItemTransformer.transformErrors([error], this.language);

    this.failedResult.Errors = errors;
  }
}
