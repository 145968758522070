import { customElement, autoinject, transient, bindable, containerless } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { GridBase } from "components/ag-grid/grids/grid-base";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { ProcoreEntityConfigurationProjectFilters, nameof as nameof_ProcoreEntityConfigurationProjectFilters } from "enums/project-filters";
import { EnumHelper } from "helpers/enum-helper";
import { RowNode } from "ag-grid";
import { NotificationHelper } from "helpers/notification-helper";
import { TreeData } from "../treedata";
import { EnumFormatWithIdValueConverter } from "converters/enums/enum-format-with-id";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";

export interface ProjectFilterItem {
    id: any;
    text: string;
}

// ugly, I know, but we're having a scope problem here
let selectedFilter: ProcoreEntityConfigurationProjectFilters | null = null;

@transient()
@autoinject()
@customElement("entity-configuration-grid")
@containerless
export class EntityConfigurationGrid extends TreeData {

    public readonly ProcoreEntityConfigurationProjectFilters: typeof nameof_ProcoreEntityConfigurationProjectFilters = nameof_ProcoreEntityConfigurationProjectFilters;

    public projectFilters: ProcoreEntityConfigurationProjectFilters[] = EnumHelper.getStringValues(ProcoreEntityConfigurationProjectFilters) as ProcoreEntityConfigurationProjectFilters[];
    
    @bindable
    public filtersDisabled: boolean = false;

    @bindable
    public defaultProjectFilterValue: ProcoreEntityConfigurationProjectFilters = ProcoreEntityConfigurationProjectFilters.Type;

    private readonly MIN_DATE: string = "0001-01-01T00:00:00.000";

    constructor(
        element: Element,
        i18n: I18N,
        userGridStateService: UserGridStateService,
        eventAggregator: EventAggregator,
        private readonly notificationHelper: NotificationHelper,
        private readonly enumFormatValueConverter: EnumFormatValueConverter) {
        super(element, i18n, userGridStateService, eventAggregator);

        this.initializeGrid();
        this.createEntityConfigurationGridOptions();
     }

    public onProjectFilterChanged(event: CustomEvent<ProcoreEntityConfigurationProjectFilters>): void {
        try {
            const projectFilter: ProcoreEntityConfigurationProjectFilters | null = event ? event.detail : null;

            this.filterConfigurationGrid(projectFilter);
    
            this.gridOptions.rowData.forEach((node: any) => {
                if (node.filter === "T" || node.filter === "C") {
                    node.enabled = false;
    
                    CustomEventHelper.dispatchEvent(this.element, "row-data-updated", { dataHierarchy: node.dataHierarchy, nodeState: false }, true, false);
                }
            });
    
            this.selectAllEnabledEntities();
        } catch (e) {
            this.notificationHelper.showError(this.i18n.tr(`Procore.Config_Entities_Filter_Error`), "");
        }
    }

    public lastSynchronizationTimestampFormatter: (params: any) => any = (params: any): any => {
        return params.value === this.MIN_DATE ?
                 this.i18n.tr("Procore.Entity_Grid_NeverSync") :
                 this.dateTimeFormatter(params);
    }

    private initializeGrid(): void {
        this.showRightPanel = false;
        this.showFullSearchBox = false;
        this.autosizeColumnsOnWindowResize = true;
    }

    private filterConfigurationGrid(value: ProcoreEntityConfigurationProjectFilters | null): void {
        if (value) {
            selectedFilter = value;
            this.gridOptions.api.onFilterChanged();
        }
    }

    private isExternalFilterPresent(): boolean {
        return selectedFilter !== null;
    }

    private doesExternalFilterPass(node: RowNode): boolean {
        return node.data.filter === selectedFilter || node.data.filter === "A";
    }

    private createEntityConfigurationGridOptions(): void {

        const autoGroupColumnDef = {
            suppressMovable : true,
            suppressMenu : true,
            lockPosition: true,
            editable: false,
            headerName: this.i18n.tr("Procore.Entity_Grid_Maestro"),
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
                checkbox: true,
                suppressCount : true
            }
        };

        this.gridOptions = {
            suppressRowClickSelection : true,
            autoGroupColumnDef: autoGroupColumnDef,
            groupSuppressAutoColumn : false,
            rowData : this.items,
            groupDefaultExpanded : 1,
            sideBar: false,
            treeData : true,
            isExternalFilterPresent: this.isExternalFilterPresent,
            doesExternalFilterPass: this.doesExternalFilterPass,
            getDataPath: (data: any): any => {
                return data.dataHierarchy;
            },
            onGridReady: (params: any): void => {
                params.api.sizeColumnsToFit();

                this.filterConfigurationGrid(this.defaultProjectFilterValue);

                this.selectAllEnabledEntities();
            }
        };
    }
}
