// GENERATED FILE - DO NOT MODIFY
import { EmployeeModelBase } from "api/models/company/employee/employee-model-base";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { WorkOrderTimesheetHeaderModel } from "api/models/company/workorder/work-order-timesheet-header-model";
import { WorkOrderTimesheetModel } from "api/models/company/workorder/work-order-timesheet-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class WorkOrderTimesheetProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetHeader(dispatchId: number, fromHistory: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderTimesheetHeaderModel | null> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/timesheets",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { fromHistory: (fromHistory === null || fromHistory === undefined) ? undefined : fromHistory.toString() }
        );

        const data: WorkOrderTimesheetHeaderModel | null = await this.get<WorkOrderTimesheetHeaderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderTimesheetHeaderModel");
        return data;
    }

    public async Post(dispatchId: number, timesheet: WorkOrderTimesheetHeaderModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/timesheets",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: boolean = await this.post<WorkOrderTimesheetHeaderModel | null, boolean>(uri, timesheet, requestConfig);
        return data;
    }

    public async GetHeader2(dispatchId: number, fromHistory: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderTimesheetModel | null> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/timesheets/header",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { fromHistory: (fromHistory === null || fromHistory === undefined) ? undefined : fromHistory.toString() }
        );

        const data: WorkOrderTimesheetModel | null = await this.get<WorkOrderTimesheetModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderTimesheetModel");
        return data;
    }

    public async Get(dispatchId: number, timesheetId: number, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderTimesheetModel | null> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/timesheets/{timesheetId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), timesheetId: (timesheetId === null || timesheetId === undefined) ? undefined : timesheetId.toString() },
            null
        );

        const data: WorkOrderTimesheetModel | null = await this.get<WorkOrderTimesheetModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderTimesheetModel");
        return data;
    }

    public async Delete(dispatchId: number, timesheetId: number, timesheetTimestamp: Date, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderTimesheetHeaderModel | null> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/timesheets/{timesheetId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), timesheetId: (timesheetId === null || timesheetId === undefined) ? undefined : timesheetId.toString() },
            { timesheetTimestamp: (timesheetTimestamp === null || timesheetTimestamp === undefined) ? undefined : timesheetTimestamp.toString() }
        );

        const data: WorkOrderTimesheetHeaderModel | null = await this.delete<WorkOrderTimesheetHeaderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.WorkOrderTimesheetHeaderModel");
        return data;
    }

    public async GetLastShift(requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/workorders/timesheets/lastShift",
            null,
            null
        );

        const data: number = await this.get<number>(uri, requestConfig);
        return data;
    }

    public async GetAvailableEmployees(dispatchId: number, templateCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase[] | null> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/timesheets/availableEmployees",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { templateCode: (templateCode === null || templateCode === undefined) ? undefined : templateCode, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EmployeeModelBase[] | null = await this.get<EmployeeModelBase[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Employee.EmployeeModelBase");
        return data;
    }

    public async GetSeperateTimeLinkedEquipmentDispatches(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        const uri = this.buildUri(
            "/workorders/dispatch/{dispatchId}/timesheets/seperate-time-entry/special-equipmment",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentModel[] | null = await this.get<EquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Equipment.EquipmentModel");
        return data;
    }
}
