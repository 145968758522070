// GENERATED FILE - DO NOT MODIFY
import { ServiceCallQuotationEquipmentModel } from "api/models/company/service-call/service-call-quotation-equipment-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallQuotationEquipmentProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async SaveQuotationEquipment(quotationEquipment: ServiceCallQuotationEquipmentModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations-equipments",
            null,
            null
        );

        await this.post<ServiceCallQuotationEquipmentModel | null, void>(uri, quotationEquipment, requestConfig);
    }

    public async DeleteQuotationEquipment(quotationEquipmentId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations-equipments/{quotationEquipmentId}",
            { quotationEquipmentId: (quotationEquipmentId === null || quotationEquipmentId === undefined) ? undefined : quotationEquipmentId.toString() },
            null
        );

        await this.delete<void>(uri, requestConfig);
    }

    public async GetQuotationEquipment(quotationEquipmentId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationEquipmentModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations-equipments/{quotationEquipmentId}",
            { quotationEquipmentId: (quotationEquipmentId === null || quotationEquipmentId === undefined) ? undefined : quotationEquipmentId.toString() },
            null
        );

        const data: ServiceCallQuotationEquipmentModel | null = await this.get<ServiceCallQuotationEquipmentModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationEquipmentModel");
        return data;
    }

    public async GetQuotationEquipments(quotationId: number, onlyEquipmentToAdd: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationEquipmentModel[] | null> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/equipments",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            { onlyEquipmentToAdd: (onlyEquipmentToAdd === null || onlyEquipmentToAdd === undefined) ? undefined : onlyEquipmentToAdd.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallQuotationEquipmentModel[] | null = await this.get<ServiceCallQuotationEquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationEquipmentModel");
        return data;
    }

    public async SaveQuotationEquipments(quotationId: number, quotationEquipments: Array<ServiceCallQuotationEquipmentModel | null> | null, equipmentToAdd: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/service-call-quotations/{quotationId}/equipments",
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString() },
            { equipmentToAdd: (equipmentToAdd === null || equipmentToAdd === undefined) ? undefined : equipmentToAdd.toString() }
        );

        await this.post<Array<ServiceCallQuotationEquipmentModel | null> | null, void>(uri, quotationEquipments, requestConfig);
    }
}
