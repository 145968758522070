import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { HistorySearch } from "./helpers/history-search";
import { ServiceCallQuotationHistoryService } from "services/service-call-quotation-history-service";
import labelHelper from "helpers/labelHelper";
import dateHelper from "helpers/dateHelper";
import services from "services/serviceService";
import routerHelper from "helpers/routerHelper";
import { EquipmentHistoryItem } from "./models/equipment-history-item";
import { EquipmentHistoryItemType } from "./models/equipment-history-item-type";
import requests from "services/requests";
import { ServiceCallHistoryProxy } from "api/proxies/service-call-history-proxy";
import RouteRepository from "repositories/routeRepository";
import { Tab } from "components/layout/tab";
import { default as enumHelper } from "helpers/enumHelper";
import { default as UserAccessService } from "services/user-access-service";

@autoinject
export class EquipmentDetailsHistory {
  @bindable
  public dispatchId: number = 0;
  @bindable
  public equipmentId: number = 0;
  @bindable
  public equipmentCode: string = "";
  private serviceType: string = "";

  private quotation: EquipmentHistoryItemType[] = [ EquipmentHistoryItemType.Quotation ];
  private addressCall: EquipmentHistoryItemType[] = [ EquipmentHistoryItemType.ServiceCall, EquipmentHistoryItemType.WorkOrder] ;

  private i18n: I18N;
  private historySearch: HistorySearch;
  private serviceCallQuotationHistoryService: ServiceCallQuotationHistoryService;
  private serviceCallHistoryProxy: ServiceCallHistoryProxy;

  private historyResultCount: number = 0;
  private routeRepository: RouteRepository;
  private self: EquipmentDetailsHistory = this;
  private quotationOptionEnabled: boolean = false;
  private userAccessService: UserAccessService;

  constructor(i18n: I18N,
              historySearch: HistorySearch,
              serviceCallQuotationHistoryService: ServiceCallQuotationHistoryService,
              serviceCallHistoryProxy: ServiceCallHistoryProxy,
              routeRepository: RouteRepository,
              userAccessService: UserAccessService) {

    this.i18n = i18n;
    this.historySearch = historySearch;
    this.serviceCallQuotationHistoryService = serviceCallQuotationHistoryService;
    this.serviceCallHistoryProxy = serviceCallHistoryProxy;
    this.routeRepository = routeRepository;
    this.userAccessService = userAccessService;
  }

  public async attached(params: any): Promise<void> {
    this.quotationOptionEnabled = this.userAccessService.isOptionEnabledSync(enumHelper.userOptions.SERVICEQUOTATIONS);

    let data: EquipmentHistoryItem[] = [];
    data = data.concat(await this.getAddressCallHistoryData());
    if (this.quotationOptionEnabled) {
      data = data.concat(await this.getQuotationHistoryData());
    }

    this.historySearch.setHistoryList(data);
    
    this.setResultCount(this.addressCall);
  }

  public async bind(params: any): Promise<void> {
    this.serviceType  = params.serviceType;
  }

  // seulement en SQL
  public async getQuotationHistoryData(): Promise<EquipmentHistoryItem[]> {
    const data = await this.serviceCallQuotationHistoryService.getHistory(this.dispatchId, null, this.equipmentId);
    
    return data.map((item: any) => {
      const newRow = new EquipmentHistoryItem();
      
      newRow.Id = item.Id;
      newRow.Title = this.i18n.tr("Quotation") + " - " + item.Number;
      newRow.Date = item.Date ? dateHelper.formatDate("YYYY-MM-DD", item.Date) : null;
      newRow.Description = item.Description;
      newRow.Description2 = item.CustomerDescription;
      newRow.Type = EquipmentHistoryItemType.Quotation;

      return newRow;
    });
  }

  public async getAddressCallHistoryData(): Promise<EquipmentHistoryItem[]> {
    const data = await services.getSameAdressCallsHistory(this.serviceType, this.dispatchId, 1, "", this.equipmentId);

    return data!.map((item: any) => {
      const newRow = new EquipmentHistoryItem();
      
      newRow.Id = item.Id;
      newRow.Title = labelHelper.getLabel(item.IsWorkOrder ? "W" : "S") + " - " + item.Id;
      newRow.Date = item.AssignedDate ? dateHelper.formatDate("YYYY-MM-DD", item.AssignedDate) : null;
      newRow.Description = item.Description;
      newRow.Description2 = item.TechnicianNumber + " - " + item.Technician;
      newRow.IsWorkOrder = item.IsWorkOrder;
      
      newRow.Type = item.IsWorkOrder
        ? EquipmentHistoryItemType.WorkOrder
        : EquipmentHistoryItemType.ServiceCall;

      return newRow;
    });
  }

  private changeTab(selectedTab: Tab): void {

    switch (selectedTab.name) {
      case this.i18n.tr("Quotations"):
        this.setResultCount(this.quotation);
        break;
      case this.i18n.tr("AdressServiceCalls"):
        this.setResultCount(this.addressCall);
        break;
    }
  }

  private setResultCount(types: EquipmentHistoryItemType[]): void {
     this.historyResultCount = this.historySearch.displayedHistoryList.filter((item: EquipmentHistoryItem) => {
      return item.Type !== null && item.Type !== undefined && types.includes(item.Type);
    }).length;
  }

  private goToSummary(item: EquipmentHistoryItem): void {
    switch (item.Type) {
      case EquipmentHistoryItemType.Quotation:
        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Summary.name, { quotationId: item.Id, showSignature: "false" });
        break;
        
        case EquipmentHistoryItemType.ServiceCall:
        case EquipmentHistoryItemType.WorkOrder:
          const url = routerHelper.navigateTo("Service_Detail_Summary", item.IsWorkOrder ? "W" : "S", item.Id)
          + routerHelper.addQuerystring({ id: item.Id, assignedDate: dateHelper.dateFromUTC(item.Date, "YYYY-MM-DD"),
          fromHistory: true, equipmentCode: this.equipmentCode });
    
          routerHelper.navigate(url);
          break;
    }
  }
}
