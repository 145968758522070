import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import val from "core/val";
import moment from "moment";
import queryStringHelper from "helpers/queryStringHelper";
import dateHelper from "helpers/dateHelper";
import interruptionService from "services/interruptionService";
import projectService from "services/projectService";
import routerHelper from "helpers/routerHelper";
import { TimeListHelper, TimeListItem } from "helpers/time-list-helper";
import templateService from "services/templateService";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class DailyEntryInterruptionEdit extends FormBase {

    public val: typeof val = val;

    public dispatchDate: Date = new Date();
    public dispatchProjectCode: string = "";

    public id: any;
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;

    public endTime: Date = new Date();
    public startTime: Date = new Date();

    public name: string = "";
    public description: string = "";
    public comments: string = "";
    public isEdit: boolean = false;
    public timeOptions: TimeListItem[] = [];
    public unModified: any = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");

        const modified = this.buildDto();
        const stringifyCurrent = JSON.stringify(modified).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }
   
    public async activate(params: any): Promise<any> {
        this.timeOptions = TimeListHelper.loadTimeList(await templateService.getUserTemplateIncrement());
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
        await this.loadData();
    }

    public async loadData(): Promise<any> {
        if (this.id) {
            this.mapApiData(await interruptionService.getInterruptionsForDispatchProjectCodeAndDate(this.dispatchProjectCode, this.dispatchDate, this.id));
            this.unModified = this.buildDto();
        } else {
            this.unModified = this.buildDto();
            const result = await projectService.getProjectWorkSchedule(this.dispatchProjectCode);
            this.startTime = this.getDateTime(result.StartHour);
            this.endTime = this.getDateTime(result.EndHour);
        }
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, id: any, queryString: string): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

        this.readonly = queryStringHelper.parseReadonly(queryString);

        this.id = id;
        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
        this.isEdit = this.id !== null;
    }

    public getDateTime(time: string): Date {
        const timeArr: string[] = time.split(":");
        const timeToReturn = dateHelper.dateFromUTC(this.dispatchDate);
        timeToReturn.setHours(Number.parseInt(timeArr[0]), Number.parseInt(timeArr[1]), Number.parseInt(timeArr[2]));

        return timeToReturn;
    }

    public isEndTimeValid(): boolean {
        if (!this.endTime) {
            this.notificationHelper.showError(this.i18n.tr("err_EndTimeRequired"));
            return false;
        }

        if (moment(this.endTime).isSameOrBefore(moment(this.startTime))) {
            this.notificationHelper.showError(this.i18n.tr("err_EndTimeAfterStartTime"));
            return false;
        }

        return true;
    }

    public isRequiredFieldValid(): boolean {
        if (!this.startTime) {
            this.notificationHelper.showError(this.i18n.tr("err_StartTimeRequired"));
            return false;
        }

        if (!this.name) {
            this.notificationHelper.showError(this.i18n.tr("err_CodeRequired"));
            return false;
        }

        if (!this.description) {
            this.notificationHelper.showError(this.i18n.tr("err_DescriptionRequired"));
            return false;
        }

        return true;
    }

    public mapApiData(data: any): void {
        this.startTime = data.StartDateTime;
        this.endTime = data.EndDateTime;

        this.name = data.Name;
        this.description = data.Description;
        this.comments = data.Comment;
    }

    public buildDto(): any {
        return {
            LineNumber: this.id ? this.id : 0,
            Name: this.name,
            Description: this.description,
            Comment: this.comments,
            StartTime: dateHelper.getTime(this.startTime),
            EndTime: dateHelper.getTime(this.endTime)
        };
    }

    public async save(): Promise<void> {
        if (this.isEndTimeValid() && this.isRequiredFieldValid()) {
            this.unModified = null;
            await interruptionService.setInterruptionsForDispatchProjectCodeAndDate(this.dispatchProjectCode, this.dispatchDate, this.buildDto());
            routerHelper.navigateBack();
        }
    }
}
