export type HttpClientCanceler = (message?: string) => void;

export class HttpClientCancellationToken {
    public token: any;
    private canceler: HttpClientCanceler | null;

    constructor(token: any, canceler: HttpClientCanceler) {
        this.token = token;
        this.canceler = canceler;
    }

    public cancel(message?: string): void {
        if (!this.token || !this.canceler) {
            throw new Error("Cancellation token is invalid. Perhaps it was already cancelled.");
        }

        const cancelerToInvoke = this.canceler;
        this.token = null;
        this.canceler = null;

        cancelerToInvoke(message);
    }
}
