import { autoinject, customElement } from "aurelia-framework";
import { FormControl } from "components/form-controls/form-control";

@autoinject()
@customElement("form-group") // Requis pour le moment car pour que l'héritage des bindables fonctionne la classe dérivée doit avoir @customElement ou contenir elle-même des bindables.
export class FormGroup extends FormControl {
    private children: FormControl[] = [];

    constructor(element: Element) {
        super(element);
    }

    public bind(): void {
        this.element.addEventListener("form-control-attached", this.onFormControlAttached);
    }

    public unbind(): void {
        this.element.removeEventListener("form-control-attached", this.onFormControlAttached);
        this.children = [];
    }

    protected readonlyChanged(): void {
        super.readonlyChanged();
        this.updateChildren();
    }

    protected groupReadonlyChanged(): void {
        super.groupReadonlyChanged();
        this.updateChildren();
    }

    private updateChildren(): void {
        if (!this.children) {
            return;
        }

        this.children.forEach((x: FormControl) => { x.groupReadonly = this.effectiveReadonly; });
    }

    private onFormControlAttached: (e: CustomEvent) => void = (e: CustomEvent) => {
        if (e.detail instanceof FormControl && e.detail.id !== this.id && !this.children.includes(e.detail)) {
            e.stopPropagation();
            this.children.push(e.detail);
            e.detail.groupReadonly = this.effectiveReadonly;
        }
    }
}
