import { ProcoreDirectCostTransactionType } from "api/enums/procore-direct-cost-transaction-type";
import { ProcoreTransactionType } from "api/enums/procore-transaction-type";
import { DirectCostTransactionLoaderEntity } from "api/models/procore/loaders/direct-cost-transaction-loader-entity";
import { ProcoreTransactionGroup } from "api/models/procore/loaders/procore-transaction-group";
import { TransactionLoaderEntity } from "api/models/procore/loaders/transaction-loader-entity";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { DateFormatValueConverter } from "converters/format/date-format";
import { ProcoreGridGroup } from "pages/procore/synchronization/composition/grid/models/procore-grid-group";
import { ProcoreGridItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-item";
import { ProcoreGridCommandContractTransactionItem, ProcoreGridDirectCostItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-transaction-item";
import { ProcoreGridItemTransformer } from "./procore-grid-item-transformer";

@autoinject
export class CommandContractTransactionTransformer {
  constructor(private readonly i18n: I18N, private readonly procoreGridItemTransformer: ProcoreGridItemTransformer) {

  }

  public transform(items: Array<TransactionLoaderEntity | null> | null): ProcoreGridItem[] {
    if (!items) { return []; }

    const transformedItems = items.map((item: TransactionLoaderEntity): ProcoreGridItem | ProcoreGridGroup => {
      const language = localStorage.getItem("maestro_user_profile_LANGUAGE");
      const errors = this.procoreGridItemTransformer.transformErrors(item.Errors, language);
      const warnings = this.procoreGridItemTransformer.transformErrors(item.Warnings, language);

      return {
        AdditionalField: null,
        Message: this.procoreGridItemTransformer.getMessage(errors, warnings),
        Code: item.Code,
        Description: item.Description,
        AccountingDate: this.shortDateFormatter(item.AccountingDate),
        Supplier: item.Supplier,
        Amount: item.Amount,
        Errors: errors,
        Warnings: warnings,
        Children: this.transform(item.Children),
        Details: [],
        IsDetails: true,
        IsGroup: item.IsGroup,
        Status: item.Status,
        IsSynchronized: item.IsSynchronized,
        Target: item.Target,
        IsCommandContractTransaction: true,
        IsPccoChangeOrder: false,
        Selected: false,
        IsSynchronizing: false,
        TransactionType: item.TransactionType,
        DetailsOpen: false,
        ErrorsOpen : false,
        DetailsIsLoading: false,
        SynchronizationTried: false,
        DirectCostType: ProcoreDirectCostTransactionType.Unclassified,
      } as ProcoreGridCommandContractTransactionItem;
    });

    return transformedItems;
  }

  private shortDateFormatter(date: Date | null): any {
    if (!date) {
      return null;
    }

    const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);

    return dateFormatValueConverter.toView(date, "L");
  }
}
