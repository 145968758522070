import { inject } from "aurelia-dependency-injection";
import { Loader } from "aurelia-loader";

@inject(Loader) 
export default class AureliaLoaderHelper {
    loader = null;

    constructor(loader) {
        this.loader = loader;
    }

    /**
     * @param moduleId {string} fq'd path to module ( from config.js paths )
     * @return {Promise} resolved if module is loaded, false otherwise.
     */
    exists(moduleId) {
        return new Promise((resolve, reject) => {
            this.loader.loadModule(moduleId).then(resolve).catch(reject);
        });
    }

    /**
     * @param moduleId {string} fq'd path to module ( from config.js paths )
     * @return {boolean} true if loaded, false otherwise.
     */
    async existsAsync(moduleId) {
        let result = false;
        try {
            result = !!await this.loader.loadModule(moduleId);
        }
        catch (e) {
            // noop
        }
        return result;
    }
    
    /**
     * @param moduleId {string} fq'd path to module ( from config.js paths )
     * @return true if module loaded.
     * @throws Error if module cannot be loaded.
     */
    async existsAsyncThrowOnError(moduleId) {
        return !!await this.loader.loadModule(moduleId);
    }
}