// GENERATED FILE - DO NOT MODIFY
import { CatalogItemPriceModel } from "api/models/company/catalog/catalog-item-price-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class CatalogItemPriceProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetPrice(item: CatalogItemPriceModel | null, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemPriceModel | null> {
        const uri = this.buildUri(
            "/catalog-item-price-calculation",
            null,
            null
        );

        const data: CatalogItemPriceModel | null = await this.post<CatalogItemPriceModel | null, CatalogItemPriceModel | null>(uri, item, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemPriceModel");
        return data;
    }
}
