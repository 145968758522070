
import { autoinject } from "aurelia-framework";

@autoinject
export class ServiceContactDetail {

    public id: any;
    public dispatchId: any;

    public activate(params: any): any {
        this.id = params.id;
        this.dispatchId = params.dispatchId;
        return;
    }
}
