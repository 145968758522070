// GENERATED FILE - DO NOT MODIFY
import { EmployeeModelBase } from "api/models/company/employee/employee-model-base";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { ServiceCallTimesheetHeaderModel } from "api/models/company/service/service-call-timesheet-header-model";
import { ServiceCallTimesheetModel } from "api/models/company/service/service-call-timesheet-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallTimesheetProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetHeader1(dispatchId: number, fromHistory: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallTimesheetHeaderModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/timesheets",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { fromHistory: (fromHistory === null || fromHistory === undefined) ? undefined : fromHistory.toString() }
        );

        const data: ServiceCallTimesheetHeaderModel | null = await this.get<ServiceCallTimesheetHeaderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallTimesheetHeaderModel");
        return data;
    }

    public async Get(dispatchId: number, lineId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallTimesheetModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/timesheets/{lineId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), lineId: (lineId === null || lineId === undefined) ? undefined : lineId.toString() },
            null
        );

        const data: ServiceCallTimesheetModel | null = await this.get<ServiceCallTimesheetModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallTimesheetModel");
        return data;
    }

    public async Post(dispatchId: number, timesheets: ServiceCallTimesheetHeaderModel | null, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallTimesheetHeaderModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/timesheets",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: ServiceCallTimesheetHeaderModel | null = await this.post<ServiceCallTimesheetHeaderModel | null, ServiceCallTimesheetHeaderModel | null>(uri, timesheets, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallTimesheetHeaderModel");
        return data;
    }

    public async GetHeader2(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallTimesheetHeaderModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/timesheets/header",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: ServiceCallTimesheetHeaderModel | null = await this.get<ServiceCallTimesheetHeaderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallTimesheetHeaderModel");
        return data;
    }

    public async Delete(dispatchId: number, lineId: number, timesheetTimestamp: Date, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallTimesheetHeaderModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/timesheets/{lineId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), lineId: (lineId === null || lineId === undefined) ? undefined : lineId.toString() },
            { timesheetTimestamp: (timesheetTimestamp === null || timesheetTimestamp === undefined) ? undefined : timesheetTimestamp.toString() }
        );

        const data: ServiceCallTimesheetHeaderModel | null = await this.delete<ServiceCallTimesheetHeaderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallTimesheetHeaderModel");
        return data;
    }

    public async GetLastShiftUsed(requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/serviceCalls/timesheets/lastShift",
            null,
            null
        );

        const data: number = await this.get<number>(uri, requestConfig);
        return data;
    }

    public async GetAvailableEmployees(dispatchId: number, templateCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/timesheets/availableEmployees",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { templateCode: (templateCode === null || templateCode === undefined) ? undefined : templateCode, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EmployeeModelBase[] | null = await this.get<EmployeeModelBase[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Employee.EmployeeModelBase");
        return data;
    }

    public async GetSeperateTimeLinkedEquipmentDispatches(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/timesheets/seperate-time-entry/special-equipmment",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentModel[] | null = await this.get<EquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Equipment.EquipmentModel");
        return data;
    }
}
