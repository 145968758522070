import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import materialRequisitionService from "services/materialRequisitionService";

@autoinject()
export class ServiceMaterialRequisition {

    public dispatchId: any;
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public fromHistory: boolean = false;
    public actions: any;
    public serviceType: any;
    public dispatchProjectCode: string = "";
    public projectCode: string = "";

    public activate(params: any): any {
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;

        this.fromHistory = queryStringHelper.parse(params.q, "fromHistory") === "true";

        this.actions = {
            getRequisitions: materialRequisitionService.getMaterialRequisitionsForService.bind(this, this.dispatchId, this.fromHistory),
        };

        const paramsQs = routerHelper.getQuerystring(params.q);
        if (paramsQs) {
            this.isProjectResponsible = paramsQs.isProjectResponsible === "true" || false;

            if (paramsQs.readonly) {
                this.readonly = queryStringHelper.parseReadonly(params.q);
            }

            if (paramsQs.projectCode) {
                this.projectCode = paramsQs.projectCode;
            }

            if (paramsQs.dispatchProjectCode) {
                this.dispatchProjectCode = paramsQs.dispatchProjectCode;
            }
        }
    }
}
