import { BehaviorInstruction, DOM, ViewCompiler, ViewResources } from "aurelia-framework";

export function processContentAsReplacablePart(
    replacablePartIdentifier: string
): (viewCompiler: ViewCompiler, viewResources: ViewResources, element: HTMLElement, behaviorInstruction: BehaviorInstruction) => boolean {
    return (viewCompiler: ViewCompiler, viewResources: ViewResources, element: HTMLElement, behaviorInstruction: BehaviorInstruction): boolean => {
        const templateTagName = "template";
        const htmlContent: string | null = element.innerHTML;
        const firstChild: Element | null = element.firstElementChild;

        const contentAlreadyWrapped = firstChild && firstChild.tagName.toLowerCase() === templateTagName;
        const noContentToWrap = htmlContent === null || /^\s*$/.test(htmlContent);

        if (contentAlreadyWrapped) {
            return true;
        }

        if (noContentToWrap) {
            return false;
        }
        
        // Wrap content in a <template>
        const templateWrapper: HTMLTemplateElement = DOM.createTemplateElement();
        templateWrapper.setAttribute("replace-part", replacablePartIdentifier);
        templateWrapper.innerHTML = htmlContent as string;

        // replace the element's content with the <template>
        element.innerHTML = "";
        element.appendChild(templateWrapper);

        return true;
    };
}
