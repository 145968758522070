import { BaseEnumToIcon } from "./base-enum-to-icon";
import { ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";

export class ServiceCallQuotationStatusIconValueConverter extends BaseEnumToIcon {
    protected get mappings(): {[key: string]: string} {
        const map: {[key: string]: string}  = {};

        map[ServiceCallQuotationStatus.InProgressByTheTechnician] = "fa fa-pencil";
        map[ServiceCallQuotationStatus.OnHold] = "fa fa-hourglass-start";
        map[ServiceCallQuotationStatus.CompletedByTheTechnician] = "fa fa-check";
        map[ServiceCallQuotationStatus.Entered] = "fa fa-building-o";
        map[ServiceCallQuotationStatus.Confirmed] = "fa fa-check";
        map[ServiceCallQuotationStatus.SentToCustomer] = "fa fa-envelope";
        map[ServiceCallQuotationStatus.AcceptedByTheCustomer] = "fa fa-handshake-o";
        map[ServiceCallQuotationStatus.CallGenerated] = "fa fa-lock";
        map[ServiceCallQuotationStatus.RefusedByTheCustomer] = "fa fa-minus-circle";

        return map;
    }
}
