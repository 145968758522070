import labelHelper from "helpers/labelHelper";
import RouteRepository, { NavigationNew } from "repositories/routeRepository";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import services from "services/serviceService";
import defaultService from "services/defaultService";
import UserAccessService from "services/user-access-service";
import { WorkOrderQuotationHistoryService } from "services/work-order-quotation-history-service";
import { ServiceCallQuotationHistoryService } from "services/service-call-quotation-history-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";

import enumHelper from "helpers/enumHelper";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";

import { CallType } from "api/enums/call-type";

@autoinject()
export class ServiceHistory {

    public enumHelper: typeof enumHelper = enumHelper;
    public labelHelper: typeof labelHelper = labelHelper;
    public hlpDate: typeof dateHelper = dateHelper;

    public readonly: any = false;
    public dispatchId: any;
    public history: any[] = [];
    public visitsList: any[] = [];
    public serviceType: any = "";
    public serviceCallId: any = null;

    public search: string = "";
    public equipmentCode: any = "";
    public equipmentId: any = "";
    public resultsVisible: any = false;
    public buttonCreateQuoteVisible: any = false;
    public canCreateQuotation: any = false;

    public serviceTypeEnum: any = { serviceCall: "S", workOrder: "W" };
    public recommandationOptionEnabled: boolean = false;

    public resultsCount: number = 0;
    public page: number = 1;
    public scrollMessageText: string = this.i18n.tr("ScrollForMore");
    public isScrollMessageShown: boolean = false;
    public showVisits: boolean = false;
    public showQuotations: boolean = false;
    public loadingStatus: boolean = false; // True if the end of the infinite scrolling is reached for current tab
    public isMaintenanceWO: boolean = false;

    public callType: typeof CallType = CallType;

    constructor(private readonly i18n: I18N,
                private readonly serviceCallQuotationHistoryService: ServiceCallQuotationHistoryService,
                private readonly routeRepository: RouteRepository,
                public readonly userAccessService: UserAccessService,
                private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
                private readonly workOrderQuotationHistoryService: WorkOrderQuotationHistoryService) {
    }

    public async searchChanged(val: any): Promise<void> {
        this.clear();
        await this.getData(this.page, val).then((data: any) => {
            this.getDataDone(data);
        });
    }

    public async doIt(val: any): Promise<void> {
        this.clear();
        await this.getData(this.page, val).then((data: any) => {
            this.getDataDone(data);
        });
    }

    public getQuerystringData(querystring: string): void {
            if (querystring) {
                const json = routerHelper.getQuerystring(querystring);
                if (json.equipmentCode) {
                    this.equipmentCode = decodeURIComponent(json.equipmentCode);
                }
                if (json.equipmentId) {
                    this.buttonCreateQuoteVisible = true;
                    this.equipmentId = decodeURIComponent(json.equipmentId);
                    this.buttonCreateQuoteVisible = true;
                }
                if (json.serviceCallId) {
                    this.serviceCallId = decodeURIComponent(json.serviceCallId);
                }
                this.readonly = queryStringHelper.parseReadonly(querystring);

                if (json.isMaintenanceWO) {
                    if (json.isMaintenanceWO === "true") {
                        this.isMaintenanceWO = true;
                    } else {
                        this.isMaintenanceWO = false;
                    }
                }
            }
    }

    public async activate(params: any): Promise<any> {
        await this.initSecuritySettings();

        this.bindViewModel(params.serviceType, params.dispatchId, params.q);

        return this.loadData();
    }

    public bindViewModel(serviceType: any, dispatchId: string, querystring: string): any {
            this.equipmentCode = "";
            this.equipmentId = "";
            this.serviceCallId = null;
            this.getQuerystringData(querystring);
            this.dispatchId = dispatchId;
            this.serviceType = serviceType;
            if (serviceType === this.serviceTypeEnum.serviceCall && !this.equipmentId && !this.equipmentCode) {
                this.showVisits = true;
            } else {
                this.showVisits = false;
                //if (serviceType !== serviceTypeEnum.serviceCall) {
                //    self.canCreateQuotation(false);
                //}
            }
        }

    public clear(): void {
        this.page = 1;
        this.history = [];
        this.visitsList = [];
    }

    public getDataDone(data: any): any {
        let ls = this.showVisits ? this.visitsList : this.history;
        ls = ls.concat(data);
        this.resultsCount = ls ? ls.length : 0;
        if (!data) { return; }
        if (data.length >= defaultService.getPageSize()) {
            this.loadingStatus = false;
            this.initScroll();
        } else {
            this.loadingStatus = true;
            this.disposeScroll();
        }
        this.resultsVisible = true;
        if (this.showVisits) {
            return this.visitsList = ls;
        } else {
            return this.history = ls;
        }
    }

    public async initSecuritySettings(): Promise<void> {
        const securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
        this.canCreateQuotation = this.serviceCallQuotationSecurityService.canCreateQuotationInMobile(securitySettings);
        this.recommandationOptionEnabled = this.userAccessService.isOptionEnabledSync(enumHelper.userOptions.SERVICERECOMMENDATION);
    }

    public initScroll(): void {
        jQuery(document).scroll(this.scrollHandler.bind(this));
        this.showScrollMessage("scroll");
    }

    public disposeScroll(): void {
        jQuery(document).off("scroll");
        this.hideScrollMessage();
    }

    public showScrollMessage(msgId: string): void {
        if (msgId === "loading") {
            this.scrollMessageText = this.i18n.tr("LoadingMoreResults");
        } else {
            this.scrollMessageText = this.i18n.tr("ScrollForMore");
        }

        this.isScrollMessageShown = true;
    }

    public hideScrollMessage(): void {
        this.isScrollMessageShown = false;
    }

    public disposeAll(): any {
        this.history = [];
        this.disposeScroll();
    }

    public async scrollHandler(): Promise<any> {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showScrollMessage("loading");
            this.page += 1;
            await this.getData(this.page, this.search)
                .then((data: any) => {
                    this.getDataDone(data);
                });
        }
    }

    public async getData(page: number, search: string): Promise<any> {
        this.disposeScroll();
        if (page === 1) {
            this.resultsVisible = false;
        }

        if (this.showQuotations) {

            if (this.serviceType === this.serviceTypeEnum.serviceCall) {
                return await this.serviceCallQuotationHistoryService.getHistory(this.dispatchId, !search ? null : search, !this.equipmentId && !this.equipmentCode ? null : this.equipmentId, {page: page});
            }
            return await this.workOrderQuotationHistoryService.getHistory(this.dispatchId, !search ? null : search, null, {page: page});
        } else {
            routerHelper.showLoading();
            return await this.getHistory(this.showVisits, page, search)
                .then((data: any) => {
                    routerHelper.hideLoading();
                    return data;
                }, (err: any) => {
                    routerHelper.hideLoading();
                    this.disposeAll();
                });
        }
    }

    public getHistory(showVisits: boolean, page: number, search: string): Promise<any> {
        if (showVisits) {
            return services.getCallVisitsHistory(this.serviceType,
                this.dispatchId,
                page.toString(),
                search,
                this.equipmentId);
        } else {
            return services.getSameAdressCallsHistory(this.serviceType, this.dispatchId, page, search, this.equipmentId);
        }
    }

    public async loadData(): Promise<any> {
        this.clear();
        this.search = "";
        return await this.getData(this.page, "")
            .then((data: this) => {
                return this.getDataDone(data);
            });
    }

    public deactivate(): void {
        this.disposeScroll();
    }

    public async goToSummary(item: any): Promise<any> {
        const histoItem = item;

        let dispatchId = 0;
        if (histoItem.DispatchId) {
            dispatchId = histoItem.DispatchId;
        }

        let url = "";
        if (dispatchId !== 0) {
            url = routerHelper.navigateTo("Service_Detail", histoItem.IsWorkOrder ? "W" : "S", dispatchId) + routerHelper.addQuerystring({ fromHistory: true, readonly: true });
            routerHelper.navigate(url);
        } else {
            url = routerHelper.navigateTo("Service_Detail_Summary", histoItem.IsWorkOrder ? "W" : "S", 0)
                + routerHelper.addQuerystring({ id: histoItem.Id, assignedDate: dateHelper.dateFromUTC(histoItem.AssignedDate, "YYYY-MM-DD"), fromHistory: true, equipmentCode: this.equipmentCode });
        }
        routerHelper.navigate(url);
    }

    public goToQuotationSummary(item: any): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Summary.name, { quotationId: item.Id, showSignature: "false" });
    }

    public goToVisit(dispatchId: any): void {
        const url = routerHelper.navigateTo("Service_Detail", "S", dispatchId) + routerHelper.addQuerystring({ fromHistory: true, readonly: true });
        routerHelper.navigate(url);
    }
        //#endregion

    public gotoPage(): void {
        const url = routerHelper.navigateTo("Service_Detail_Recommendation_Edit", this.serviceType, this.dispatchId) +
        routerHelper.addQuerystring({ entityId: this.dispatchId, equipmentId : this.equipmentId });
        routerHelper.navigate(url);
    }

    public gotoQuotation(): void {
            let equipId = this.equipmentId;
            if (equipId === "") {
                equipId = null;
            }

            let sourceServiceCalId = this.serviceCallId;
            let sourceWorkOrderId = null;
            let isWorkOrder = false;

            if (this.serviceType === this.serviceTypeEnum.workOrder) {
                sourceWorkOrderId = this.serviceCallId;
                sourceServiceCalId = null;
                isWorkOrder = true;
            }

            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Details.name, { quotationId: NavigationNew, sourceServiceCallId: sourceServiceCalId, contractEquipmentId: equipId, dispatchId: this.dispatchId, sourceWorkOrderId: sourceWorkOrderId, isWorkOrder: isWorkOrder});
    }

    public show(section: string): void {
        this.clear();
        this.showVisits = section === "visits";
        this.showQuotations = section === "addressQuotations";
        this.resultsCount = 0;
        this.loadData();
    }

    public manualLoadNewPage(): void {
        this.disposeScroll();
        this.showScrollMessage("loading");
        this.page += 1;
        this.getData(this.page, this.search)
            .then((data: any) => {
                this.getDataDone(data);
            });
    }
}
