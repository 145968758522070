import { bindable, transient, computedFrom } from "aurelia-framework";

import { default as resx } from "core/resx";


@transient()
export class EquipmentCounterEditor {
    @bindable disabled = false;
    @bindable equipment;

    @computedFrom('equipment')
    get hasCounter1 (){
        return this.equipment && !!this.equipment.Counter1Type;
    }

    @computedFrom('equipment')
    get hasCounter2(){
        return this.equipment && !!this.equipment.Counter2Type;
    }
    
    constructor() {
        this.resx = resx;
    }
}