import { autoinject, bindable } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as dateHelper } from "helpers/dateHelper";
import { computedFrom } from "aurelia-binding";

@autoinject()
export class TimesheetHeader {
    @bindable date = "";

    @computedFrom("date")
    get formattedDate() {
        var date = this.date;
        if (date) {
            return dateHelper.getTextDate(date);
        }
        return '';
    }
}