import { StringHelper } from "helpers/string-helper";

export class PhoneNumberFormatValueConverter {
    public toView(value: any): string {
        if (value === null || value === undefined || typeof value !== "string") {
            return "";
        }

        // String should start with 10 digits.
        if (value.length < 10 || !/^[0-9]{10}.*/.test(value)) {
            return value;
        }

        // "1234567890" -> "123-456-7890"
        return StringHelper.insertAt(StringHelper.insertAt(value, 3, "-"), 7, "-");
    }
}
