import { SalesPersonModel } from "api/models/company/sales-person-model";
import { SalesPersonProxy } from "api/proxies/sales-person-proxy";
import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class SalesPersonService {
    private salesPersonProxy: SalesPersonProxy;

    constructor(salesPersonProxy: SalesPersonProxy) {
        this.salesPersonProxy = salesPersonProxy;
    }

    public async getSalesPersons(filter?: string, pagingInfo?: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<SalesPersonModel[] | null> {
        return await this.salesPersonProxy.GetSalePersons(filter, pagingInfo, httpClientRequestConfig);
    }
}
