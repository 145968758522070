import { default as stringHelper } from "helpers/stringHelper";
import NumericCellEditor from "ag-grid/editors/numericCellEditor";

export default class DecimalCellEditor extends NumericCellEditor {
    constructor(){
        super();
    }

    init(params) {
        super.init(params);
    }

    addValidateInputListener() {
        this.inputElement.addEventListener("input", () => super.validateInput(new RegExp(/^\d*\.?\d*$/)));
    }

    getValue() {
        return parseFloat(super.getValue());
    }
}

