import phoneHelper from "helpers/phoneHelper";
import { SettingRepository } from "repositories/setting-repository";
import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import documentHelper from "helpers/documentHelper";
import employeeService from "services/employeeService";
import _ from "underscore";
import routerHelper from "helpers/routerHelper";
import moment from "moment";
import RouteRepository from "repositories/routeRepository";
import dateHelper from "helpers/dateHelper";
import notificationHelper from "helpers/notificationHelper";
import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { ValidationAttributeValueConverter } from "converters/misc/validation-attribute-value-converter";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { CloneHelper } from "helpers/cloneHelper";
import { StringHelper } from "helpers/string-helper";
import UserAccessService from "services/user-access-service";

@autoinject
export class Employee {

    @bindable public readonly: boolean = false;
    @bindable public dispatchProjectCode: string = "";
    @bindable public dispatchDate: Date = new Date();
    @bindable public workOrderId: string = "";
    @bindable public dispatchId: number = 0;
    @bindable public employeeId: number = 0;
    public employee: any = {};
    public origemployee: any = {};
    public oneActiveCertificateHasDocument: boolean = false;
    public oneInactiveCertificateHasDocument: boolean = false;
    @bindable public isResponsible: boolean = false;
    public dateHelper: typeof dateHelper = dateHelper;

    public phoneHelper: typeof phoneHelper = phoneHelper;
    public unavailabilityCards: MaCardOptionsModel[] = [];
    public maritalStatus: string | null = null;

    constructor(private readonly i18n: I18N, private readonly settingRepository: SettingRepository, private readonly routeRepository: RouteRepository, private readonly enumFormatValueConverter: EnumFormatValueConverter, private readonly userAccessService: UserAccessService, validationAttributeValueConverter: ValidationAttributeValueConverter) {
    }

    public async bind(): Promise<any> {
        await this.loadData();
    }

    public async loadData(): Promise<any> {
        this.mapLoadedData(await employeeService.getEmployeeFile(this.employeeId));
    }

    public getPicture(pictureCode: string): string {
        if (pictureCode === null) {
            return "url('images/avatar.png')";
        } else {
            return "url('data:image/jpg;base64," + pictureCode + "')";
        }
    }

    public mapLoadedData(data: any): any {
        data.avatar = this.getPicture(data.Picture);
        data.certificationActive = _.filter(data.Certifications, (certification: any) => !certification.Inactive);
        data.oneActiveCertificateHasDocument = _.filter(data.certificationActive, (certification: any) => certification.DocumentInfo !== null).length > 0;

        data.certificationInActive = _.filter(data.Certifications, (certification: any) => certification.Inactive);
        this.oneInactiveCertificateHasDocument = _.filter(data.certificationInActive, (certification: any) => certification.DocumentInfo !== null).length > 0;

        this.employee = data;
        this.origemployee = CloneHelper.deepClone(this.employee);
        this.maritalStatus = this.enumFormatValueConverter.toView(this.employee.MaritalStatus, "MaritalStatus");

        this.unavailabilityCards = [];

        this.employee.Unavailability.forEach((item: any) => {
            this.unavailabilityCards.push(this.createCard(item));
        });
    }

    public getDocumentSize(size: number): string {
        return documentHelper.formatDocumentSize(size);
    }

    public getDocumentIcon(type: string): string {
        if (type) {
            return documentHelper.getIconClass(type);
        } else {
            return "";
        }
    }

    public getDocumentType(type: string): string {
        return this.i18n.tr(documentHelper.getLocalizationKey(type));
    }

    public getDownloadUrl(documentId: number): string {
        if (documentId) {
            let documentUrl = documentHelper.getDocumentUrlById(documentId);
            documentUrl += "?access_token=" + this.settingRepository.getToken();
            return documentUrl;
        } else {
            return "javascript:void(0);";
        }
    }
    public genUrl(route: string, param: any): string {
        let relativeUrl = "";

        if (param !== undefined) {
            relativeUrl = routerHelper.getRelativeUrl(route, param);
        } else {
            relativeUrl = routerHelper.getRelativeUrl(route);
        }

        return relativeUrl + routerHelper.addQuerystring({ isProjectResponsible: this.isResponsible, readonly: this.readonly });
    }

    public navigateTo(route: string, param: any): string {
        let url = routerHelper.navigateTo(route, this.dispatchProjectCode, this.dispatchDate, param);
        url += routerHelper.addQuerystring({ isProjectResponsible: this.isResponsible, readonly: this.readonly });
        return url;
    }

    public navigateToActivities(): void {
        const url = this.navigateTo("Project_Detail_Daily_Entry_TimeEntryEmployee", this.dispatchId);
        routerHelper.navigate(url, { replace: true, trigger: true });
    }

    public addUnavailability(): void {
        const params = {
            employeeId: this.employeeId,
            startTime: moment().format(),
            endTime: moment().format(),
        };
        routerHelper.navigateToRoute(this.routeRepository.routes.Calendar_AddUnavailability.name, params);
    }

    public getDocument(item: any, isSkill?: boolean): any {
        const t = item.DocumentsInfo && item.DocumentsInfo.length > 1 ?
            {
                name: this.i18n.tr("Documents"),
                size: "",
                icon: this.getDocumentIcon("FOLDER"),
                type: "",
                url: (routerHelper.getRelativeUrl(item.CertificationAssignmentId + ""))
                + routerHelper.addQuerystring({ isProjectResponsible: this.isResponsible, readonly: this.readonly })
            }
            :
            {
                id: item.DocumentsInfo.length ? item.DocumentsInfo[0].DocumentId : "",
                name: item.DocumentsInfo.length ? item.DocumentsInfo[0].FileName : "",
                size: item.DocumentsInfo.length ? this.getDocumentSize(item.DocumentsInfo[0].FileSize) : "",
                icon: item.DocumentsInfo.length ? this.getDocumentIcon(item.DocumentsInfo[0].Type) : isSkill ? "fa fa-cogs" : "fa fa-certificate",
                type: item.DocumentsInfo.length ? this.getDocumentType(item.DocumentsInfo[0].Type) : "",
                url: item.DocumentsInfo.length ? this.getDownloadUrl(item.DocumentsInfo[0].DocumentId) : "javascript:void(0)"
            };
        return t;
    }

    public addPicture(): void {
        const params = { employeeId: this.employeeId, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, workOrderId: this.workOrderId };
        routerHelper.navigateToRoute(this.routeRepository.routes.Employee_Profile_Picture.name, params);
    }

    public async deleteUnavailability(item: any): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await employeeService.deleteNonAvailabilityForEmployee(item.Id);
            await this.loadData();
        }
    }

    public editUnavailability(item: any): void {
        const params = {
            employeeId: this.employeeId,
            startTime: item.StartDate,
            endTime: item.EndDate,
            unAvailabilityId: item.Id,
            comments: item.Comments
        };
        routerHelper.navigateToRoute(this.routeRepository.routes.Calendar_AddUnavailability.name, params);
    }

    public checkAndOpenDocument(item: any): void {
        const document = this.getDocument(item);
        documentHelper.openDocument(document);
    }

    public createCard(item: any): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.editUnavailability.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getUnavailbilityTypeDisplay(item) }),
                new MaCardLineModel({ display: this.getUnavailbilityStartDateDisplay(item) }),
                new MaCardLineModel({ display: this.getUnavailbilityEndDateDisplay(item) }),
                new MaCardLineModel({ display: this.getUnavailbilityCommentsDisplay(item) }),
            ],
            actionItems: [new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.editUnavailability.bind(this, item) }), new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.deleteUnavailability.bind(this, item) })],
        });

        return card;
    }

    public getUnavailbilityTypeDisplay(item: any): string {
        return `${item.TypeId + " - " + item.TypeDesc}`;
    }

    public getUnavailbilityStartDateDisplay(item: any): string {
        return `${this.i18n.tr("From_Date:") + dateHelper.getFullTextDateTime(item.StartDate)}`;
    }

    public getUnavailbilityEndDateDisplay(item: any): string {
        return `${this.i18n.tr("To_Date:") + dateHelper.getFullTextDateTime(item.EndDate)}`;
    }

    public getUnavailbilityCommentsDisplay(item: any): string {
        if (!item.Comments) {
            return "";
        }
        return `${this.i18n.tr("Comment") + ": " + item.Comments}`;
    }

    public isValid(): boolean {
        if (this.employee.Phone1.trim() === "" && this.origemployee.Phone1.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("Phone")));
            return false;
        }

        if (this.employee.Phone2.trim() === "" && this.origemployee.Phone2.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("OtherPhone")));
            return false;
        }

        if (this.employee.PesonalEmail.trim() === "" && this.origemployee.PesonalEmail.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("PesonalEmail")));
            return false;
        }

        if (this.employee.Address.trim() === "" && this.origemployee.Address.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("Address")));
            return false;
        }

        if (this.employee.City.trim() === "" && this.origemployee.City.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("City")));
            return false;
        }

        if (this.employee.State.trim() === "" && this.origemployee.State.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("Province")));
            return false;
        }

        if (this.employee.PostalCode.trim() === "" && this.origemployee.PostalCode.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("PostalCode")));
            return false;
        }

        if (this.employee.Country.trim() === "" && this.origemployee.Country.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("Country")));
            return false;
        }

        if (this.employee.ZipCode.trim() === "" && this.origemployee.ZipCode.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("ZipCode")));
            return false;
        }

        if (this.employee.EmergencyName.trim() === "" && this.origemployee.EmergencyName.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("EmergencyName")));
            return false;
        }

        if (this.employee.EmergencyPhoneNo.trim() === "" && this.origemployee.EmergencyPhoneNo.trim() !== "") {
            notificationHelper.showError(this.i18n.tr("Employee_Save_Personal_Infos_Error").replace("{0}", this.i18n.tr("EmergencyPhoneNo")));
            return false;
        }

        return true;
    }

    public async save(): Promise<void> {
        const employeeToSave = CloneHelper.deepClone(this.employee);
        employeeToSave.Certifications = [];
        employeeToSave.Picture = "";
        employeeToSave.Pictures = [];
        employeeToSave.Skills = [];
        employeeToSave.Unavailability = [];
        employeeToSave.certificationActive = [];
        employeeToSave.certificationInActive = [];

        employeeToSave.PostalCode = StringHelper.cleanString(employeeToSave.PostalCode || "", StringHelper.keepAlphaNumericOnly);
        employeeToSave.ZipCode = StringHelper.cleanString(employeeToSave.ZipCode || "", StringHelper.keepAlphaNumericOnly);
        employeeToSave.Phone1 = StringHelper.cleanString(employeeToSave.Phone1 || "", StringHelper.keepNumericOnly);
        employeeToSave.Phone2 = StringHelper.cleanString(employeeToSave.Phone2 || "", StringHelper.keepNumericOnly);
        employeeToSave.EmergencyPhoneNo = StringHelper.cleanString(employeeToSave.EmergencyPhoneNo || "", StringHelper.keepNumericOnly);

        if (this.isValid()) {
            await employeeService.saveEmployeePersonalInfos(employeeToSave);
            this.origemployee = CloneHelper.deepClone(this.employee);
            notificationHelper.showSuccess(this.i18n.tr("Employee_Save_Personal_Infos_Success"));
        }
    }
    
}
