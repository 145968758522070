// Polyfill for coreJS & regeneratorRuntime 
import "babel-polyfill";

// i18n Polyfill for safari <= 9
// See this issue. https://github.com/aurelia/i18n/issues/135
import "intl";

// Promises
// We need to configure bluebird before we call the webpack-aurelia-bootstrapper
// Or else, the app doesn't load on safari.
// https://github.com/aurelia/cli/issues/385
import { default as Bluebird } from "bluebird";

Bluebird.config({
    cancellation: true,
    warnings: { wForgottenReturn: false }
});