import LoggerHelper from "helpers/logger-helper";

// TODO JL: implémenter https://github.com/unassert-js/unassert pour ôter les asserts du build de prod
export default class Assert {
    public static strictEqual<T>(expected: T, actual: T, ...args: any[]): void {
        if (expected !== actual) {
            LoggerHelper.throwOrLog(`ERROR: Value must be equals. Expected ${expected}. Actual ${actual}.`, ...args);
        }
    }

    public static strictNotEqual(expected: any, actual: any, ...args: any[]): void {
        if (expected === actual) {
            LoggerHelper.throwOrLog(`ERROR: Value must not be equals. Expected ${expected}. Actual ${actual}.`, ...args);
        }
    }

    public static hasValue(expression: any, ...message: any[]): void {
        if (expression === null || expression === undefined) {
            LoggerHelper.throwOrLog("Expression must have a value", ...message);
        }
    }

    public static isDate(expression: any, ...message: any[]): void {
        if (expression === null || expression === undefined) {
            return;
        }

        if (!(expression instanceof Date)) {
            LoggerHelper.throwOrLog(`Expression must be a date: [${expression}]`, ...message);
        }
    }

    public static isTrue(expression: any, ...message: any[]): void {
        if (expression !== true) {
            LoggerHelper.throwOrLog(`Expression must be true: [${expression}]`, ...message);
        }
    }

    public static isFalse(expression: any, ...message: any[]): void {
        if (expression !== false) {
            LoggerHelper.throwOrLog(`Expression must be false: [${expression}]`, ...message);
        }
    }

    private constructor() {
        /*Static class*/
    }
}
