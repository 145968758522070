import { autoinject, BindingEngine, Disposable } from "aurelia-framework";

export type SubscriptionCallback<T> = ((newValue: T, oldValue: T) => void | Promise<void>)
                                        | ((newValue: T) => void | Promise<void>)
                                        | (() => void | Promise<void>);

@autoinject()
export class ExpressionObserver implements Disposable {
    private readonly bindingEngine: BindingEngine;
    private readonly disposables: Disposable[] = [];

    constructor(bindingEngine: BindingEngine) {
        this.bindingEngine = bindingEngine;
    }

    public subscribeToExpression<T>(object: any, pathToExpression: string, callback: SubscriptionCallback<T>): Disposable {
        const observer = this.bindingEngine.expressionObserver(object, pathToExpression);
        const subscription: Disposable = observer.subscribe(callback);

        this.disposables.push(subscription);
        return subscription;
    }

    public dispose(): void {
        while (this.disposables.length > 0) {
            const disposable: Disposable = this.disposables.pop() as Disposable;
            disposable.dispose();
        }
    }
}
