import val from "core/val";
import { autoinject, bindable } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";

import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

@autoinject()
export class InventoryProjectTransferMemo extends FormBase {
    public val: any = val;
    public unModified: InventoryProjectTransferDetailsModel | null = null;

    @bindable public transfer!: InventoryProjectTransferDetailsModel | null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private routeRepository: RouteRepository, private readonly inventoryProjectTransferService: InventoryProjectTransferService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrent = JSON.stringify(this.transfer).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: any): Promise<void> {
        this.transfer = await this.inventoryProjectTransferService.GetTransfer(params.transferId);
        this.unModified = CloneHelper.deepClone(this.transfer);
    }

    public async save(): Promise<void> {
        await this.inventoryProjectTransferService.SaveTransfer(this.transfer!).then(() => {
            this.unModified = null;
            routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Info.name,  {transferId : this.transfer!.Id});
        });
    }
}
