import { nameof as nameof_ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { nameof as nameof_ServiceCallWorkOrderQuotationStatus } from "api/enums/service-call-work-order-quotation-status";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { RouterHelper } from "helpers/router-helper";
import { QuotationLightParameters } from "pages/services/quotations/quotation-light";
import RouteRepository from "repositories/routeRepository";

@autoinject()
export class QuotationListItem {
    @bindable.none
    public Quotation!: ServiceCallQuotationDetailsModel;

    @bindable.boolean
    public showSellingPrices: boolean = true;

    public readonly nameof_ServiceCallQuotationStatus: string = nameof_ServiceCallQuotationStatus;
    public readonly nameof_ServiceCallWorkOrderQuotationStatus: string = nameof_ServiceCallWorkOrderQuotationStatus;

    constructor(
        private readonly routeRepository: RouteRepository,
        private readonly routerHelper: RouterHelper) {
    }

    private navigateToQuotation(quotationId: number): void {
        this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Mobile.name, { quotationId: quotationId } as QuotationLightParameters);
    }
}
