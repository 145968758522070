import { default as requests } from "services/requests";
import { default as apiHelper } from "helpers/apiHelper";

/*global FormData*/
export default class EstimateTakeoffVariableImportService {
    importFile(file, estimateId, format, filename) {
        const uri = apiHelper.getBaseUrl() + "/estimates/" + estimateId + "/takeoffvariables/importfile/" + format;

        const data = new FormData();
        data.append(filename, file);

        return requests.sendFile(uri, data);
    }

    getFieldNamesFromFile(file) {
        const uri = apiHelper.getBaseUrl() + "/estimates/takeoffvariables/fieldnames";

        const data = new FormData();
        data.append(file.name, file);

        return requests.sendFile(uri, data);
    }

    exportToExcel(variables) {
        return requests.post(apiHelper.getBaseUrl() + "/estimates/takeoffvariables/export");
    }

    saveCustomImportFormat(fields) {
        return requests.post(apiHelper.getBaseUrl() + "/estimates/takeoffvariables/importformat", fields);
    }

    getCustomImportFormat() {
        return requests.getJson(apiHelper.getBaseUrl() + "/estimates/takeoffvariables/importformat");
    }
}