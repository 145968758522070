/**
 * HttpClient will throw this error when a request is successfully cancelled.
 * Due to a limitation with Babel, the catcher cannot check for this type of error with instanceof. Use the isHttpRequestCancellation property instead. I think this is fixed in Babel 7.
 */
export class HttpClientCancellationError extends Error {
    public readonly isHttpRequestCancellation: boolean = true;

    constructor(message?: string) {
        super(message);
    }
}
