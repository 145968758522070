import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { autoinject, computedFrom } from "aurelia-framework";
import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { RouterHelper } from "helpers/router-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemEquipmentBase } from "pages/services/quotations/item-equipment-base";
import { EquipmentService } from "services/equipment-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { CloneHelper } from "helpers/cloneHelper";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

export interface QuotationItemEquipmentParameters {
    quotationId: number;
    itemId?: number;
}

@autoinject()
export class QuotationItemEquipment extends QuotationItemEquipmentBase {
    private originalDesc: string | null = "";

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        equipmentService: EquipmentService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        serviceCallContractEquipmentService: ServiceCallContractEquipmentService,
        private readonly routerHelper: RouterHelper,
        private readonly notificationHelper: NotificationHelper, private readonly router: Router
    ) {
        super(validationControllerFactory, i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, equipmentService, validationHelper, validationController, serviceCallQuotationPriceService, serviceCallContractEquipmentService);
    }

    public async activate(params: QuotationItemEquipmentParameters): Promise<void> {
        this.initQuotationItem(params.quotationId, params.itemId);
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            this.routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public async save(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        this.quotationItem.EquipmentDescription = this.originalDesc;

        await this.serviceCallQuotationService.saveQuotationItem(this.quotationItem);
        this.originalQuotationItem = null;
        this.routerHelper.navigateBack();
    }

    private async initQuotationItem(quotationId: number, itemId: number | undefined): Promise<void> {
        await this.initQuotation(quotationId);

        if (itemId !== null && itemId !== undefined) {
            this.quotationItem = await this.serviceCallQuotationService.getQuotationItem(itemId);

            this.originalDesc = this.quotationItem.EquipmentDescription;
            this.quotationItem.EquipmentDescription = `${this.quotationItem.EquipmentId} - ${this.quotationItem.EquipmentDescription}`;

        } else {
            this.quotationItem = this.serviceCallQuotationService.createNewQuotationItem(quotationId, ServiceCallQuotationItemType.Equipment, this.quotation.ProfitMargin);
        }

        this.initValidation();

        this.originalQuotationItem = CloneHelper.deepClone(this.quotationItem);
    }
}
