import { PagingInfo } from "api/paging-info";
import { OverrideContext, bindingMode, inject } from "aurelia-framework";
import { processContent } from "aurelia-templating";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import { CancellationTokenFactory, ICancellationToken } from "core/cancellation-token";
import { processContentAsReplacablePart } from "helpers/au-process-content-helper";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { IRequestConfig } from "models/request-config";
import { IdTextSelectItemData, SelectItem, resolveItemPathAsString } from "models/select-item";

interface CreatedFromIdTextSelectItem extends SelectItem<IdTextSelectItemData> {
    createdFromIdText: true | undefined;
}
export interface LookupParameters {
    readonly filter: string;
    readonly pagingInfo: PagingInfo;
    requestConfig?: IRequestConfig;
}

export type LookupFunction<T> = (x: LookupParameters) => Promise<T[]>;

export abstract class DropdownBase<T> extends FormControl {
    @observable()
    public selectedItem: SelectItem<T | IdTextSelectItemData> | null = null;

    @bindable.string //
    public valuePath: string = "id";

    @bindable.string
    public textPath: string = "text";

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public selectedValue: string | null = null;

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public selectedText: string | null = null;

    @bindable.none({ defaultBindingMode: bindingMode.twoWay })
    public selectedData: any | null = null;

    @bindable.none() //
    public lookup!: LookupFunction<T>;

    @bindable.booleanAttr //
    public prependValue: boolean = false;

    @bindable // tslint:disable:member-ordering
    public textConverter = ({ item }: { item: T | null }): string => {
        if (!item) {
            return "";
        }

        if (this.prependValue) {
            return resolveItemPathAsString(item, this.valuePath) + " - " + resolveItemPathAsString(item, this.textPath);
        }

        return resolveItemPathAsString(item, this.textPath);
    };

    @bindable public options: T[] | null = null;

    @bindable public showClear: boolean = false;

    @bindable public showSearchInput: boolean = true;

    protected cancellationToken: ICancellationToken | null = null;

    constructor(element: Element, private readonly cancellationTokenFactory: CancellationTokenFactory) {
        super(element);
    }

    public bind(bindingContext: any, overrideContext: OverrideContext): void {
        if (this.selectedData) {
            this.selectedItem = new SelectItem(this.selectedData, (tt: T): string => resolveItemPathAsString(tt, this.valuePath), (tt: T): string => this.textConverter({ item: tt }));
        }

        if (!this.selectedItem && this.selectedValue !== null && this.selectedValue !== undefined) {
            this.updateSelectedItemFromSelectedValueText();
        }
    }

    // #region Commands
    public async getResults(filter: string, pagingInfo: PagingInfo): Promise<Array<SelectItem<T>>> {
        // TODO Filtrer les options statiques. En ce moment on a pas accès au template. Donc on ne peut pas
        // savoir quel est le texte affiché. On a seulement accès à l'ID.
        
        const listOfT = this.options ? this.options : await this.internalLookup(filter, pagingInfo);

        return listOfT.map(
            (t: T): SelectItem<T> => new SelectItem(t, (tt: T): string => resolveItemPathAsString(tt, this.valuePath), (tt: T): string => this.textConverter({ item: tt }))
        );
    }

    public clear(e: CustomEvent): void {
        e.stopPropagation();
        this.selectedItem = null;
    }
    // #endregion

    // #region Observers
    public selectedItemChanged(newItem: SelectItem<T | IdTextSelectItemData> | null): void {
        if (newItem) {
            this.selectedValue = newItem.id;
            this.selectedText = newItem.text;
            this.selectedData = newItem.data;
        } else {
            this.selectedText = null;
            this.selectedValue = null;
            this.selectedData = null;
        }

        this.dispatchSelectedItemChangedEvent(newItem);
    }

    public selectedTextChanged(): void {
        this.updateSelectedItemFromSelectedValueText();
    }

    public selectedValueChanged(newValue: string | null): void {
        this.updateSelectedItemFromSelectedValueText();
    }

    public selectedDataChanged(): void {
        if (this.selectedData) {
            if (!this.selectedItem || this.selectedItem.data !== this.selectedData) {
                this.selectedItem = new SelectItem(this.selectedData, (tt: T): string => resolveItemPathAsString(tt, this.valuePath), (tt: T): string => this.textConverter({ item: tt }));
            }
        } else {
            this.selectedItem = null;
        }
    }
    // #endregion

    // #region Privates
    protected cancelCancellationToken(): void {
        if (this.cancellationToken) {
            this.cancellationToken.cancel();
            this.cancellationToken = null;
        }
    }

    private async internalLookup(filter: string, pagingInfo: PagingInfo): Promise<T[]> {
        this.cancelCancellationToken();
        this.cancellationToken = this.cancellationTokenFactory.getCancellationToken();

        const requestConfig = {
            cancellationToken: this.cancellationToken,
            showLoadingScreen: false,
        } as IRequestConfig;

        const listOfT = await this.lookup({ filter, pagingInfo, requestConfig });

        this.cancellationToken = null;

        return listOfT;
    }

    private dispatchSelectedItemChangedEvent(newItem: SelectItem<T | IdTextSelectItemData> | null): void {
        if (newItem && (newItem as CreatedFromIdTextSelectItem).createdFromIdText === true) {
            // Do not fire the selected-item-changed if it was updated by the parent container.
            return;
        }

        CustomEventHelper.dispatchEvent(this.element, "selected-item-changed", newItem ? newItem.data : null, false, false);
    }

    private updateSelectedItemFromSelectedValueText(): void {
        const selectedItemId = this.selectedItem ? this.selectedItem.id : null;
        const selectedItemText = this.selectedItem ? this.selectedItem.text : null;

        if (selectedItemId !== this.selectedValue || selectedItemText !== this.selectedText) {
            if (this.selectedValue === null || this.selectedValue === undefined || this.selectedValue === "") {
                this.selectedItem = null;
                return;
            }

            const idTextSelectItemData = SelectItem.fromIdText({
                id: this.selectedValue,
                text: this.selectedText as string,
            }) as CreatedFromIdTextSelectItem;

            idTextSelectItemData.createdFromIdText = true;

            this.selectedItem = idTextSelectItemData;
        }
    }
    // #endregion
}
