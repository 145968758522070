define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        get: function (filter, page) {
            var url = apiHelper.getEquipmentBaseUrl();
            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        getEquipmentToInspect: function (filter, page) {
            var url = apiHelper.getInspectionBaseUrl() + '/equipments';
            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        getEquipmentWithCounters: (equipmentId) => {
            let url = `${apiHelper.getEquipmentBaseUrl(equipmentId)}/equipmentWithCounters`;
            
            return requests.getJson(url);
        }
    };

    return exports;
});
