
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { SiteProxy } from "api/proxies/site-proxy";
import { SiteModel } from "api/models/company/site-model";
import { SiteLocationModel } from "api/models/company/site-location-model";

@autoinject()
export class SiteService {

    constructor(private readonly siteProxy: SiteProxy) {
    }

    public async GetSites(prefix: string, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<SiteModel[] | null> {
        return this.siteProxy.GetSites(prefix, filter, pagingInfo, requestConfig);
    }

    public async GetSiteLocations(siteId: string, prefix: string, locationId: string | null, enabledPaging: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<SiteLocationModel[] | null> {
        return this.siteProxy.GetSiteLocations(siteId, prefix ? prefix : null, locationId ? locationId : null, enabledPaging, filter, pagingInfo, requestConfig);
    }
}
