import Logger from "core/logger";

define([
    "jquery",
    "helpers/routerHelper"
], function (jQuery, routerHelper) {
    "use strict";

    var validationDictionary = undefined;

    function initDictionary() {
        var options = {
            dataType: "json",
            url: "assets/validations.json",
            cache: false
        };

        return jQuery.ajax(options).done((data) => {
            validationDictionary = data;
        }).fail(routerHelper.showGlobalError);
    }

    var exports = {
        init: function () {
            return initDictionary();
        },

        get: function (key, prop) {
            if (!validationDictionary) {
                Logger.error("val.get: Validation dictionary was not initialized.");
                return key;
            }

            var value = validationDictionary[key];
            if (!value) {
                Logger.error("val.get: Key not found: '" + key + "'");
                return key;
            }
            if (prop) {
                value = value[prop];
                if (value === undefined) {
                    Logger.error("val.get: Property '" + prop + "' not found for Key: '" + key + "'");
                    return key + "." + prop;
                }
                return value;
            }
            return value;
        }
    };

    return exports;
});
