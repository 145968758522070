// GENERATED FILE - DO NOT MODIFY
import { DailyEntryCancelDispatchRequestModel } from "api/models/company/project/dispatch/daily-entry-cancel-dispatch-request-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DailyEntryCancelDispatchProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async CancelDispatch(cancelRequest: DailyEntryCancelDispatchRequestModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatch/project/daily-entry-cancel-dispatch",
            null,
            null
        );

        await this.post<DailyEntryCancelDispatchRequestModel | null, void>(uri, cancelRequest, requestConfig);
    }
}
