import { autoinject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";
import { KeyValuePair } from "interfaces/key-value-pair";
import { ProcoreGridColumnEnum } from "../procore-grid-column-enum";
import { ProcoreGridConfigurations } from "../procore-grid-configurations";
import { BaseColumnsConfigurator } from "./base-columns-configurator";

@autoinject
export class EmployeesColumnsConfigurator extends BaseColumnsConfigurator {
    
    public enabledColumns: ProcoreGridColumnEnum[] = [ProcoreGridColumnEnum.Code,
                                                      ProcoreGridColumnEnum.Description,
                                                      ProcoreGridColumnEnum.AdditionalField,
                                                      ProcoreGridColumnEnum.Message];
        
    public disabledColumns: ProcoreGridColumnEnum[] = [ProcoreGridColumnEnum.Target];
    
    public headerChanges: Array<KeyValuePair<ProcoreGridColumnEnum, string>> = [{  key: ProcoreGridColumnEnum.Code,             value: this.i18n.tr("EmployeeNumber") },
                                                                           {  key: ProcoreGridColumnEnum.Description,      value: this.i18n.tr("Name") },
                                                                           {  key: ProcoreGridColumnEnum.AdditionalField,  value: this.i18n.tr("Email") }];

    constructor(private readonly i18n: I18N,
                protected readonly gridConfiguration: ProcoreGridConfigurations) {
        super(gridConfiguration);
    }
}
