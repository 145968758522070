// GENERATED FILE - DO NOT MODIFY
export enum ProcoreBudgetType {
    Miscellaneous = 1,
    Equipment = 2,
    Labor = 3,
    Materials = 4,
    Subcontractor = 5,
}

export let nameof = "ProcoreBudgetType";
