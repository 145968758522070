import { EventAggregator } from "aurelia-event-aggregator";
import dateHelper from "helpers/dateHelper";
import { TimeKeepingModel } from "api/models/company/time-keeping-model";
import { TimeKeepingService } from "pages/services/time-keeping-service";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import { TimeKeepingStatus } from "api/enums/time-keeping-status";
import { TimeListParameters } from "helpers/time-list-parameters";
import { I18N } from "aurelia-i18n";

@autoinject
export class TimeKeepingEdit {
    public punch: TimeKeepingModel | null = null;
    public otherPunch: TimeKeepingModel | null = null;

    public time?: string;

    public date?: string;
    public dateTitle?: string;
    public dateTitleClass?: string;

    constructor(private readonly service: TimeKeepingService,
                private readonly eventAggregator: EventAggregator,
                private readonly i18n: I18N) {}

    public async activate(params: any): Promise<void> {
        await this.loadPunch(params);

        this.setDateTime(params);

        this.setHeader();
    }

    public setCurrentTime(): void {
        if (this.punch) {
            const now = new Date();
            const date = dateHelper.setHoursMinOnDate(this.punch.PunchDate, now.getHours(), now.getMinutes());
            this.time = dateHelper.getTimeFromDate(date, true);
        }
    }

    public async save(): Promise<void> {
        if (!this.punch) { return; }

        this.punch.PunchDate = this.setupNewTime();
        await this.service.UpdateTimeKeeping(this.punch);
        routerHelper.navigateBack();
    }

    public punchInHourAllowedValue(): TimeListParameters {
        const parameters = {
            increment: 5
        }  as TimeListParameters;

        return parameters;
    }

    private async loadPunch(params: any): Promise<void> {
        this.punch = await this.service.LoadPunch(params.punchId as number);

        const paramsQueryString = routerHelper.getQuerystring(params.q);
        if (!!paramsQueryString && paramsQueryString.otherPunchId) {
            this.otherPunch = await this.service.LoadPunch(paramsQueryString.otherPunchId as number);
        }
    }

    private setDateTime(params: any): void {
        this.time = dateHelper.getTimeFromDate(this.punch!.PunchDate, true);

        const date = new Date(this.punch!.PunchDate);
        const longDay = dateHelper.getDayOfWeekString(date.getDay(), "dddd");
        const dateLongFormat = dateHelper.formatDateLongFormat(date);
        this.date = `${longDay}, ${dateLongFormat}`;
    }

    private setHeader(): void {
        if (this.punch!.PunchStatus === TimeKeepingStatus.In) {
            this.eventAggregator.publish("updateTitle", this.i18n.tr("PunchIn"));
        } else {
            this.eventAggregator.publish("updateTitle", this.i18n.tr("PunchOut"));
        }

        const dateTitleResult = this.service.getDateTitleWithColorClass(this.punch!.PunchDate);
        this.dateTitle = dateTitleResult.dateTitle;
        this.dateTitleClass = dateTitleResult.dateTitleClass;
    }

    private setupNewTime(): Date {
        const year = this.punch!.PunchDate.getFullYear();
        const month = this.punch!.PunchDate.getMonth();
        const day = this.punch!.PunchDate.getDate();

        const splittedTime = this.time!.split(":");

        let hours = 0;
        let minutes = 0;

        if (splittedTime) {
            hours = Number.parseInt(splittedTime[0], 10);
            minutes = Number.parseInt(splittedTime[1], 10);
        }

        return new Date(year, month, day, hours, minutes);

    }
}
