import { ServiceDispatchType } from "enums/service-dispatch-type";
import { PurchaseOrderService } from "services/purchase-order-service";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";

@autoinject()
export default class MaterialOrder {

    public serviceType: any = "";
    public readonly: boolean = false;
    public isProjectResponsible: boolean = false;
    public isProject: boolean = false;
    public dispatchId: string = "";
    public actions: { getOrders: any; } | null = null;
    public dispatchProjectCode: string = "";
    public projectCode: string = "";

    constructor(private readonly i18n: I18N, private readonly purchaseOrderService: PurchaseOrderService) {}

    public async activate(params: any): Promise<any> {
        this.serviceType = params.serviceType;

        this.isProject = this.serviceType === ServiceDispatchType.Workorder;

        this.dispatchId = params.dispatchId;

        const paramsQs = routerHelper.getQuerystring(params.q);

        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;

        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        if (paramsQs && paramsQs.projectCode) {
            this.projectCode = paramsQs.projectCode;
        }

        if (paramsQs && paramsQs.dispatchProjectCode) {
            this.dispatchProjectCode = paramsQs.dispatchProjectCode;
        }

        this.actions = {
            getOrders: this.purchaseOrderService.GetPurchaseOrderByDispatchId.bind(this.purchaseOrderService, this.dispatchId)
        };
    }
}
