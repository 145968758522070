import { autoinject } from "aurelia-framework";
import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@autoinject
export class InspectionDocumentAdd {

    public readonly: boolean = false;
    public inspectionId: any = null;
    public isDirty: boolean = false;
    public actions: any;

    constructor(private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router) {
    }

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.inspectionId = params.inspectionId;
        this.actions = { save: documentService.addDocumentByInspectionId.bind(self, this.inspectionId)};
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }
}
