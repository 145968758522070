export class FroalaLazyLoader {
    public async loadModule(): Promise<any> {
        const [froalaEditor, languageFr, pluginAlign, pluginLists, pluginFullscreen, pluginFontFamily, pluginFontSize, pluginCharCounter]: any[] = await Promise.all([
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor"),
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor/js/languages/fr"),
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor/js/plugins/align.min"),
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor/js/plugins/lists.min"),
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor/js/plugins/fullscreen.min"),
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor/js/plugins/font_family.min"),
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor/js/plugins/font_size.min"),
            // @ts-ignore
            import(/* webpackChunkName: "froala" */ "froala-editor/js/plugins/char_counter.min"),
        ]);

        return froalaEditor;
    }
}
