import { I18N } from "aurelia-i18n";
import { computedFrom, observable, autoinject, bindable } from "aurelia-framework";

import { LocationType, nameof as nameof_LocationType } from "api/enums/location-type";

import routerHelper from "helpers/routerHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { StringHelper } from "helpers/string-helper";

import { default as supplierService } from "services/supplierService";
import { PurchaseOrderService } from "services/purchase-order-service";
import { SupplierContactService } from "services/supplier-contact-service";

import { PurchaseOrderModel } from "api/models/company/purchase-order-model";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { NumberFormatValueConverter } from "converters/format/number-format";

@autoinject()
export class MaterialOrderSummaryComponent {

    @bindable public actions: any = {};
    @bindable public orderId: string = "-1";
    @bindable public extension: string = "";
    @bindable public documentUrl: string | null = null;

    @bindable public readonly: boolean = false;
    @bindable public isProject: boolean = false;
    @bindable public informationMode: boolean = false;

    @bindable public projectCode: string = "";
    @bindable public dispatchDate: string = "";
    @bindable public dispatchProjectCode: string = "";

    @observable public dateEntered: Date | null = null;
    @observable public orderDate: Date | null = null;
    @observable public isNew: boolean = true;
    @observable public purchaseOrder!: PurchaseOrderModel;
    @observable public status: any;
    @observable public selectedSupplier: any;

    @observable public isReceptionImplicit: boolean = true;
    @observable public selectedType: any;
    @observable public selectedContact: any;
    @observable public email: string = "";
    public lookupSupplier: { transport: (params: any, success: any, failure: any) => void; mapResults: (item: any) => { id: any; text: string; data: any; }; };
    public dateHelper: typeof dateHelper;
    public acceptedTypes: LocationType[] = [];

    public readonly nameof_LocationType: string = nameof_LocationType;
    public locationType: typeof LocationType;

    @computedFrom("orderDate")
    public get addUrl(): string {
        const date = dateHelper.dateToNavigationParameter(this.orderDate!);
        let temp = routerHelper.getRelativeUrl("");

        if (StringHelper.endsWith(temp, "edit")) {
            temp += "/";
            temp += this.orderId;
            temp += "/item/";
            temp += date;

            return temp;
        }
        if (this.projectCode) {
            return routerHelper.getRelativeUrl("item", date) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, extension: this.extension});
        }

        return routerHelper.getRelativeUrl("item", date) + routerHelper.addQuerystring({ readonly: this.readonly, extension: this.extension });
    }

    @computedFrom("isNew")
    public get btnText(): string {
        return this.isNew ? this.i18n.tr("Add") : this.i18n.tr("Save");
    }

    @computedFrom("isNew")
    public get purchaseOrderIdDisplay(): string {
        return this.isNew ? this.i18n.tr("New_F") : this.orderId;
    }

    @computedFrom("purchaseOrder.Items")
    public get ordItemsVisible(): boolean | null {
        return !this.isNew && this.purchaseOrder.Items && this.purchaseOrder.Items.length > 0;
    }

    @computedFrom("isNew")
    public get txtNoOrderItem(): string {
        return this.isNew ? this.i18n.tr("lstNoData_PurchaseOrderItemUnsaved") : this.i18n.tr("lstNoData_RequisitionItem");
    }

    constructor(private readonly i18n: I18N, private readonly purchaseOrderService: PurchaseOrderService, private readonly supplierContactService: SupplierContactService, private readonly numberFormatValueConverter: NumberFormatValueConverter) {
        this.dateHelper = dateHelper;

        this.actions = {};
        this.lookupSupplier = {
            transport: async (params: any, success: any, failure: any): Promise<any> => {
                await supplierService.getSupplierForCompany(params.data.filter, params.data.page || 1);
            },
            mapResults: (item: any): any => {
                return { id: item.Code, text: item.Code + " - " + item.Name, data: item };
            }
        };

        this.locationType = LocationType;

        this.purchaseOrderService = purchaseOrderService;
    }

    public get loadContacts(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.supplierContactService.GetSupplierContactsLookup(this.purchaseOrder.SupplierCode, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ContactLookupModel): any => {
                return { id: item.Id, text: item.FullName, data: item.Email };
            },
        };
    }

    public bind(): any {
        if (!this.isProject) {
            this.isProject = this.dispatchProjectCode !== "null";
        }

        this.acceptedTypes = [this.locationType.Alternative, this.locationType.Customer, this.locationType.Project] ;

        this.loadData();
    }

    public async sendToSupplier(): Promise<void>  {
        if (!this.email) { return; }
        await this.purchaseOrderService.Send(this.purchaseOrder.Id, this.email, this.extension);
        routerHelper.navigateBack();
    }

    public setEmail(email: string): void {
        this.email = email;
    }

    public genUrl(line: any): string | null {
        if (!this.informationMode) {
            return routerHelper.getRelativeUrl("item", dateHelper.dateToNavigationParameter(this.orderDate!), line) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, extension: this.extension, workOrderId: this.purchaseOrder.WorkOrderId });
        }
        return null;
    }

    private async loadData(): Promise<void> {
        this.purchaseOrder = await this.actions.getPurchaseOrder();

        if (this.purchaseOrder.Id === null) {
            this.initValuesForNewRequisition();
        } else {
            this.isNew = false;
            this.isReceptionImplicit = this.purchaseOrder.AutomaticReceipt;
        }

        this.updateself();
    }

    private initValuesForNewRequisition(): void {
        this.isNew = true;
        this.purchaseOrder.Status = enumHelper.purchaseOrderStatus.RESERVED;
        this.purchaseOrder.OrderDate = new Date();
        this.purchaseOrder.UserId = -1;
        this.purchaseOrder.Items = [];
    }

    private updateself(): void {
        this.orderDate = this.purchaseOrder.OrderDate;

        if (this.purchaseOrder.SupplierCode !== null) {
            this.selectedSupplier = { id: this.purchaseOrder.SupplierCode, text: this.purchaseOrder.SupplierCode + " - " + this.purchaseOrder.Supplier };
        }

        this.readonly = this.readonly || this.purchaseOrder.Status !== enumHelper.purchaseOrderStatus.RESERVED;
    }
}
