import templateService from 'services/templateService';

export class TemplateService {
    
    listByCompany(company) {
        return templateService.listByCompany(company);
    }

    getByCompanyAndCode(code) {
        return templateService.getByCompanyAndCode(code);
    }
    
    getTemplateConfigs(code) {
        return templateService.getTemplateConfigs(code);
    }

    getUserTemplateConfigs() {
        return templateService.getUserTemplateConfigs();
    }

    getCurrentTemplateConfigs() {
        return templateService.getCurrentTemplateConfigs();
    }

    getCurrentDispatchTemplateConfigs() {
        return templateService.getCurrentDispatchTemplateConfigs();
    }

    getCurrentDispatchIncrement() {
        return templateService.getCurrentDispatchIncrement();
    }

    getUserTemplateIncrement() {
        return templateService.getUserTemplateIncrement();
    }

    getCurrentDispatchAllowGroup() {
        return templateService.getCurrentDispatchAllowGroup();
    }
}