import { autoinject, singleton } from "aurelia-dependency-injection";
import { UrlHelper } from "helpers/url-helper";
import { SettingRepository } from "repositories/setting-repository";
import { config } from "bluebird";
import Config from "config";

@singleton()
@autoinject()
export class ApiHelper {
    private urlHelper: UrlHelper;
    private settingRepository: SettingRepository;

    constructor(urlHelper: UrlHelper, settingRepository: SettingRepository) {
        this.urlHelper = urlHelper;
        this.settingRepository = settingRepository;
    }

    public getBaseApiUrl(): string {
        // TODO: Ramener logique pour déterminer l'URL dans la classe courante.
        return this.urlHelper.getWebServiceBaseUrl();
    }

    public getBaseApiUrlForCompany(company: string): string {
        return this.getBaseApiUrl() + `/api/companies/${company}`;
    }

    public getBaseApiUrlForCurrentCompany(): string {
        const company: string | null = this.settingRepository.getCompany();
        if (!company) {
            throw new Error("No current company. Cannot determine the base API URL for the current company.");
        }

        return this.getBaseApiUrlForCompany(company);
    }
}
