import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import { WorkOrderWorkTaskService } from "services/work-order-work-task-service";
import RouteRepository from "repositories/routeRepository";
import { RouteInfo } from "components/others/ma-work-task-index";
import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import routerHelper from "helpers/routerHelper";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class WorkTaskEdit {

    public transactionNumber: number = 0;
    public newWorkTask!: () => Promise<WorkOrderWorkTaskDetailsModel>;
    public documentRouteInfo: RouteInfo = { routeName: "", routeParams: {} };
    public isDirty: boolean = false;

    constructor(private routeRepository: RouteRepository, private readonly workOrderWorkTaskService: WorkOrderWorkTaskService, private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router) {}

    public async activate(params: any): Promise<void> {

        this.transactionNumber = params.transactionNumber ? params.transactionNumber : 0;

        this.newWorkTask = (await this.workOrderWorkTaskService.GetNewWorkTaskByDispatchId.bind(this.workOrderWorkTaskService, params.id));
        this.documentRouteInfo.routeName = this.routeRepository.routes.Service_Detail_WorkTask_Documents_Add.name;
        this.documentRouteInfo.routeParams = params;
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }
}
