import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import { DailyEntryTasksService } from "services/daily-entry-tasks-service";
import dailyEntryService from "services/dailyEntryService";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

interface TaskProgressParameters {
    dispatchProjectCode: string;
    dailyEntryDate: string;
}

interface ProgressOption {
    id: number;
    text: string;
}

@autoinject()
export class TaskProgress extends FormBase {
    public dispatchProjectCode: string = "";
    public dailyEntryDate: string = "";
    public tasks: any[] = [];
    public readonly: boolean = false;
    public unModified: any = null;

    public progressOptions: ProgressOption[] = [];

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly dailyEntryTasksService: DailyEntryTasksService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");

        const stringifyCurrent = JSON.stringify(this.tasks).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: TaskProgressParameters): Promise<void> {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dailyEntryDate = params.dailyEntryDate;

        this.initProgressOptions();
        await this.initTasks();

        await dailyEntryService.get(this.dispatchProjectCode, this.dailyEntryDate, "1", false, false)
            .then((data: any) => {
                this.readonly = data.IsReadOnly;
            }).promise();
    }

    public async save(): Promise<void> {
        this.unModified = null;
        await this.dailyEntryTasksService.setTasks(this.dispatchProjectCode, this.tasks);

        routerHelper.navigateBack();
    }

    private initProgressOptions(): void {
        this.progressOptions = [
            { id: 0.0, text: this.i18n.tr("NotStarted") },
            { id: 0.1, text: "10%" },
            { id: 0.2, text: "20%" },
            { id: 0.3, text: "30%" },
            { id: 0.4, text: "40%" },
            { id: 0.5, text: "50%" },
            { id: 0.6, text: "60%" },
            { id: 0.7, text: "70%" },
            { id: 0.8, text: "80%" },
            { id: 0.9, text: "90%" },
            { id: 1.0, text: this.i18n.tr("Completed") },
        ];
    }

    private async initTasks(): Promise<void> {
        const tasks = await this.dailyEntryTasksService.getTasks(this.dispatchProjectCode);

        this.normalizeTasksProgress(tasks);
        this.tasks = tasks;
        this.unModified = CloneHelper.deepClone(this.tasks);
    }

    // Make sure the progress values are in the list of possible values (ie: 0.43 -> 0.4)
    private normalizeTasksProgress(tasks: any[]): void {
        tasks.forEach((x: any) => {
            // Coerce null values to 0.0.
            x.Progress = x.Progress || 0.0;

            // Coerce invalid values.
            const progressOption = this.progressOptions.slice().reverse().find((y: ProgressOption) => y.id <= x.Progress) || this.progressOptions[0];
            x.Progress = progressOption.id;
        });
    }
}
