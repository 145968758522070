import { PagingInfo } from "./../../api/paging-info";
import { I18N } from "aurelia-i18n";
import { default as enumHelper } from "helpers/enumHelper";

import { bindable, transient, observable, autoinject } from "aurelia-framework";

import { default as logger } from "core/logger";
import { default as common } from "services/commonService";
import _ from "underscore";

@transient()
@autoinject()
export class PredefinedSentencesButton {

    public i18n: I18N;

    @bindable private value: any;
    @bindable private refId: any;
    @bindable private option: string = "";
    @bindable private context: string = "";
    @bindable private entityId: any;

    /* The predefined sentence item selected from the select control */
    @observable private selectedValue: boolean = false;

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }

    get ajax(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                const option = _.propertyOf(enumHelper.predefinedSentences.options)(this.option) ;
                const context =  _.propertyOf(enumHelper.predefinedSentences.contexts)(this.context);

                if (!option || !context) {
                    logger.error("Invalid Predefined Sentence Configuration", { option: option, context: context });
                }

                common.getDefinedText(option, context, this.entityId, params.data.filter, params.data.page)
                    .done(success)
                    .fail(failure);
            },
            mapResults: (item: any): any => ({ id: 0, text: item.Description })
        };
    }

    private selectedValueChanged(item: any): void {
        this.insertText(item);
    }

    private insertText(item: any): void {
        if (item && item.text) {

            const textbox: any = document.getElementById(this.refId);

            textbox.value += item.text;
            const element = jQuery("div.fr-element.fr-view");
            element.html(textbox.value);

            //remove the placeholder
            jQuery("div.fr-wrapper.show-placeholder").removeClass("show-placeholder");

            textbox.focus();
        }
    }
}
