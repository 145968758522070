import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject()
export class Toggle {
  @bindable
  public toggleName: string = "";
  @bindable
  public labelKey: string = "";
  @bindable
  public checked: boolean = true;
  @bindable
  public color: string = "#f00";
  @bindable
  public event: any | null = null;

  private displayedLabel: string = "";
  private parentContext: any;

  private checkbox: HTMLInputElement | null = null;

  constructor(private readonly i18n: I18N) {
  }

  public bind(bindingContext: any): void {
    this.parentContext = bindingContext;
    this.displayedLabel = this.i18n.tr(this.labelKey);
  }

  public checkedChanged(): void {
    this.changeColor();
    
    if (this.event) {
      this.event!.call(this.parentContext);
    }
  }

  public attached(): void {
    const SimpleSwitch = require("a-simple-switch");
    
    if (!this.checkbox) { return; }

    this.checkbox.checked = this.checked;
    
    const toggle = new SimpleSwitch.Switch({
      element: this.checkbox
    });

    const self = this;
    setTimeout(() => {
      this.changeColor();
    }, 500);
  }

  private changeColor(): void {
    const button = this.checkbox!.nextElementSibling as HTMLElement;
    if (button!.classList.contains("on")) {
      button.style.background = this.color;
      button.style.borderColor = this.color;
    } else {
      button.style.background = null;
      button.style.borderColor = null;
    }
  }
}
