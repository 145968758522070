export class NumberHelper {
  
  public static clamp(value: number | null, min: number, max: number, useMinIfNull: boolean = true): number {
    if (!value) {
      return useMinIfNull
               ? min
               : max;
    }

    return Math.min(Math.max(Number(value), min), max);
   }

  public static fixDecimalTo(value: number, fixedTo: number): number {
      return Number.parseFloat((Math.floor(value * Math.pow(10, fixedTo)) / Math.pow(10, fixedTo)).toFixed(fixedTo));
    }

    public static roundTo2Decimal(value: number): number {
        const m = Number((Math.abs(value) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(value);
    }

}
