// GENERATED FILE - DO NOT MODIFY
import { ProcoreJobStatus } from "api/models/procore/job_-system/procore-job-status";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProcoreJobProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetJobStatuses(jobIds: number[] | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProcoreJobStatus[] | null> {
        const uri = this.buildUri(
            "/procore/job/get-statuses",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProcoreJobStatus[] | null = await this.post<number[] | null, ProcoreJobStatus[] | null>(uri, jobIds, requestConfig);
        this.transformModels(data, "Procore.Job_System.ProcoreJobStatus");
        return data;
    }

    public async GetAllJobStatuses(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProcoreJobStatus[] | null> {
        const uri = this.buildUri(
            "/procore/job/get-all-statuses",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProcoreJobStatus[] | null = await this.get<ProcoreJobStatus[] | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Job_System.ProcoreJobStatus");
        return data;
    }

    public async GetJobIdProject(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number | null> {
        const uri = this.buildUri(
            "/procore/job/projects/{projectCode}",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            null
        );

        const data: number | null = await this.post<void, number | null>(uri, undefined, requestConfig);
        return data;
    }

    public async GetJobIdTransactions(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number | null> {
        const uri = this.buildUri(
            "/procore/job/projects/{projectCode}/transactions",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            null
        );

        const data: number | null = await this.post<void, number | null>(uri, undefined, requestConfig);
        return data;
    }

    public async GetJobIdSupplier(supplierCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number | null> {
        const uri = this.buildUri(
            "/procore/job/suppliers/{supplierCode}",
            { supplierCode: (supplierCode === null || supplierCode === undefined) ? undefined : supplierCode },
            null
        );

        const data: number | null = await this.post<void, number | null>(uri, undefined, requestConfig);
        return data;
    }

    public async GetJobIdEmployee(employeeNumber: string | null, requestConfig?: HttpClientRequestConfig): Promise<number | null> {
        const uri = this.buildUri(
            "/procore/job/employees/{employeeNumber}",
            { employeeNumber: (employeeNumber === null || employeeNumber === undefined) ? undefined : employeeNumber },
            null
        );

        const data: number | null = await this.post<void, number | null>(uri, undefined, requestConfig);
        return data;
    }
}
