define(function () {
    "use strict";

    var exports = {
        //45 784.34 $
        getDefaultFormat: function (amount) {
            var i = parseFloat(amount);
            if (isNaN(i)) {
                i = 0.00;
            }
            var minus = '';
            if (i < 0) {
                minus = '-';
            }
            i = Math.abs(i);
            i = parseInt((i + 0.005) * 100);
            i = i / 100;
            var s = i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

            if (s.indexOf('.') < 0) {
                s += '.00';
            }
            if (s.indexOf('.') === (s.length - 2)) {
                s += '0';
            }
            s = minus + s;
            return s + ' $';
        }
    };

    return exports;
});
