// GENERATED FILE - DO NOT MODIFY
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class CatalogInventoryProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetCatalogInventoryItems(code: string | null, siteId: string | null, prefix: string | null | undefined, onlyNotReserved: boolean | undefined, onlyHavingQtyInStock: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogInventoryModel[] | null> {
        const uri = this.buildUri(
            "/catalog/inventory",
            null,
            { code: (code === null || code === undefined) ? undefined : code, siteId: (siteId === null || siteId === undefined) ? undefined : siteId, prefix: (prefix === null || prefix === undefined) ? undefined : prefix, onlyNotReserved: (onlyNotReserved === null || onlyNotReserved === undefined) ? undefined : onlyNotReserved.toString(), onlyHavingQtyInStock: (onlyHavingQtyInStock === null || onlyHavingQtyInStock === undefined) ? undefined : onlyHavingQtyInStock.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogInventoryModel[] | null = await this.get<CatalogInventoryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogInventoryModel");
        return data;
    }

    public async GetCatalogInventoryReservationItems(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogInventoryModel[] | null> {
        const uri = this.buildUri(
            "/catalog/inventory/reservations",
            null,
            { prefix: (prefix === null || prefix === undefined) ? undefined : prefix, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogInventoryModel[] | null = await this.get<CatalogInventoryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogInventoryModel");
        return data;
    }
}
