import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";

import { default as _ } from "underscore";

import { default as enumHelper } from "helpers/enumHelper";

import NavigationItemBuilderService from "services/navigation-item-builder-service";

import { SettingRepository } from "repositories/setting-repository";

@inject(NavigationItemBuilderService, SettingRepository)
export class Home {
    show = false;
    navItems = [];

    isUserAccessToMobileAllowed = false;

    constructor(navigationItemBuilderService, settingRepository) {
        this.enumHelper = enumHelper;
        this.navigationItemBuilderService = navigationItemBuilderService;
        this.settingRepository = settingRepository;
    }

    canActivate() {
        if (this.settingRepository.getCompany() === null) {
            return new Redirect("Settings");
        }
        this.show = true;
        return true;
    }

    async activate() {
        this.navItems  = await this.navigationItemBuilderService.buildNavigationItemsForHomePage();
    }
}
