// GENERATED FILE - DO NOT MODIFY
export enum UnitPriceType {
    Custom = 0,
    Order = 1,
    InvTrfAdj = 2,
    Requisition = 3,
    AverageCost = 4,
    ChangeNotice = 5,
}

export let nameof = "UnitPriceType";
