import { I18N } from "aurelia-i18n";
import { autoinject, bindable, PLATFORM } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import notificationHelper from "helpers/notificationHelper";

import { InventorySiteTransferService } from "services/inventory-site-transfer-service";

import { InventoryProjectTransferStatus } from "api/enums/inventory-project-transfer-status";

import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { ExpandableButtonOptions, ExpandableButtonOptionsSubButtonModel } from "components/buttons/expandable-button/expandable-button-options";
import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";
import { InventorySiteTransferItemModel } from "api/models/company/inventory-site-transfer-item-model";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { CatalogInventoryService } from "services/catalog-inventory-service";

@autoinject
export class Items {
    @bindable public isAddDisabled: boolean = true;
    @bindable public isActive: boolean = false;
    @bindable public selectedSourceSite: any;
    @bindable public selectedTargetSite: any;
    @bindable public selectedReservations: any[] = [];

    @bindable public sourceSitesData: any[] | null = [];
    @bindable public targetSitesData: any[] | null = [];

    @bindable public inventorySiteTransferItemsCards: MaCardOptionsModel[] = [];
    @bindable public unitResultTemplate: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/inventoryTransfer_unit_result.html");

    private transferId!: number;
    private transfer!: InventorySiteTransferDetailsModel;

    constructor(private readonly i18n: I18N,
                private readonly routeRepository: RouteRepository,
                private readonly service: InventorySiteTransferService,
                private readonly catalogInventoryService: CatalogInventoryService) {}

    public async activate(params: any): Promise<void> {
        this.transferId = params.transferId;

        const response = (await this.service.GetTransfer(this.transferId))!;

        this.transfer = response;
        this.isActive = response.Status === InventoryProjectTransferStatus.Active;

        this.inventorySiteTransferItemsCards = response.Items!.map((item: InventorySiteTransferItemModel) => this.createCard(item));

        this.sourceSitesData = await this.service.GetSites(response.SourcePrefix!);
        this.targetSitesData = await this.service.GetSites(response.TargetPrefix!);

        this.initDefaultSelectedSites(response);
        this.setAddDisabled();
    }

    public async deleteItemConfirmation(sourceTransactionNumber: number, targetTransactionNumber: number): Promise<void> {
        const dialogMessage = this.i18n.tr("InventoryTransfer_Delete_Confirmation");

        notificationHelper.showDialogYesNo(dialogMessage).then(
            async (success: boolean): Promise<void> => {
                if (success) {
                    await this.service.DeleteTransferItem(sourceTransactionNumber, targetTransactionNumber);
                    routerHelper.navigateSelf();
                }
            }
        );
    }

    public getButtonOptions(): ExpandableButtonOptions {
        return new ExpandableButtonOptions({
            mainButtonIcon: "fa fa-plus",
            subButtons: [
                new ExpandableButtonOptionsSubButtonModel({
                    icon: "fa fa-plus fa-fw",
                    text: this.i18n.tr("Add"),
                    action: this.gotoNewItem.bind(this),
                }),
                new ExpandableButtonOptionsSubButtonModel({
                    icon: "far fa-tag fa-fw",
                    isCustomTemplate: true,
                }),
                new ExpandableButtonOptionsSubButtonModel({
                    icon: "fa fa-barcode fa-fw",
                    text: this.i18n.tr("AddByScanner"),
                    action: this.addByScan.bind(this),
                }),
            ],
        });
    }

    public createCard(item: InventorySiteTransferItemModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            id: item.SourceTransactionNumber,
            model: item,
            class: "ma-card-link",
            action: this.gotoEditItem.bind(this, item.SourceTransactionNumber, item.TargetTransactionNumber),
            displayLines: [new MaCardLineModel({ isCustomTemplate: true })],
            actionItems: [new MaCardActionModel({ id: "edit", icon: "fa fa-chevron-right", action: this.gotoEditItem.bind(this, item.SourceTransactionNumber, item.TargetTransactionNumber) })],
        });
        if (this.isActive) {
            card.actionItems!.push(new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.deleteItemConfirmation.bind(this, item.SourceTransactionNumber, item.TargetTransactionNumber) }));
        }

        return card;
    }

    public async selectedSourceSiteChanged(newValue: any, oldValue: any): Promise<void> {
        if (newValue.id === this.transfer.SourceSiteId) {
            return;
        }

        const hasItemWithQuantity: boolean = this.transfer.Items!.some((x: InventorySiteTransferItemModel) => x.InventoryQty > 0);

        if (hasItemWithQuantity) {
            const dialogMessage = this.i18n.tr("InventoryTransfer_Site_Changed_Confirmation");

            notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<void> => {
                if (success) {
                    this.transfer.SourceSiteId = this.selectedSourceSite.id;
                    await this.service.UpdateTransferItems(this.transfer);

                    this.transfer.Items = [];
                    this.inventorySiteTransferItemsCards = [];
                } else {
                    this.selectedSourceSite = this.sourceSitesData!.find((x: any) => x.id === oldValue.id);
                }
            });
        }

        this.setAddDisabled();
    }

    public async selectedTargetSiteChanged(newValue: any, oldValue: any): Promise<void> {
        if (newValue.id === this.transfer.TargetSiteId || this.transfer.Items!.length === 0) {
            this.setAddDisabled();
            return;
        }

        this.transfer.TargetSiteId = this.selectedTargetSite.id;
        await this.service.UpdateTransferItems(this.transfer);
        routerHelper.navigateSelf();

        this.setAddDisabled();
    }

    public get GetReservations(): any {
        return {
            transport: async (params: any, success: any, failure: any): Promise<any> => {
                await this.catalogInventoryService.GetCatalogInventoryReservationItems(this.selectedSourceSite.id, this.transfer.SourcePrefix!, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: CatalogInventoryModel): any => {
                const display = `${item.ReservationNo} - ${item.ProductCode} - ${item.Description1}`;
                return { id: item.ReservationNo, text: display, data: item };
            },
        };
    }

    public async onReservationChanged(detail: any[]): Promise<void> {
        if (detail && !this.isAddDisabled) {
            const reservations = detail.map((x: any): CatalogInventoryModel => x.data.data);
            await this.service.AddReservations(this.transferId, this.selectedSourceSite.id, this.selectedTargetSite.id, reservations);
            this.selectedReservations = [];
            routerHelper.navigateSelf();
        }
    }

    private initDefaultSelectedSites(transfer: InventorySiteTransferDetailsModel): void {
        if (transfer.Items!.length > 0) {
            transfer.SourceSiteId = transfer.Items![0]!.SourceSite;
            transfer.TargetSiteId = transfer.Items![0]!.TargetSite;
        }

        if (transfer.SourceSiteId) {
            this.selectedSourceSite = this.sourceSitesData!.find((x: any) => x.id === transfer.SourceSiteId);
        }
        if (transfer.TargetSiteId) {
            this.selectedTargetSite = this.targetSitesData!.find((x: any) => x.id === transfer.TargetSiteId);
        }

    }

    private addByScan(): void {
        const parameters = {
            transferId: this.transferId,
            sourcePrefix: this.transfer.SourcePrefix,
            sourceSiteId: this.selectedSourceSite.id,
            targetSiteId: this.selectedTargetSite.id,
            isActive: this.isActive,
        };

        routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Items_Scanner.name, parameters);
    }

    private gotoNewItem(): void {
        const parameters = {
            transferId: this.transferId,
            sourcePrefix: this.transfer.SourcePrefix,
            sourceSiteId: this.selectedSourceSite.id,
            targetSiteId: this.selectedTargetSite.id,
            isActive: this.isActive,
            targetPrefix: this.transfer.TargetPrefix,
        };

        routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Item_Add.name, parameters);
    }

    private gotoEditItem(sourceTransactionNumber: number, targetTransactionNumber: number): void {
        const parameters = {
            transferId: this.transferId,
            sourceTransactionNumber: sourceTransactionNumber,
            targetTransactionNumber: targetTransactionNumber,
            sourcePrefix: this.transfer.SourcePrefix,
            sourceSiteId: this.selectedSourceSite.id,
            targetSiteId: this.selectedTargetSite.id,
            isActive: this.isActive,
            targetPrefix: this.transfer.TargetPrefix,
        };

        routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Item_Edit.name, parameters);
    }

    private setAddDisabled(): void {
        this.isAddDisabled = !this.selectedSourceSite || !this.selectedTargetSite;
    }
}
