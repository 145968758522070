// GENERATED FILE - DO NOT MODIFY
import { PlaceModel } from "api/models/company/project/dispatch/place-model";
import { ProductionCollectionModel } from "api/models/company/project/dispatch/production-collection-model";
import { ProductionModel } from "api/models/company/project/dispatch/production-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProductionProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetProduction(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<ProductionCollectionModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/productions",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: ProductionCollectionModel | null = await this.get<ProductionCollectionModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProductionCollectionModel");
        return data;
    }

    public async GetProductionItem(projectDispatchCode: string | null, dispatchDate: Date, id: number, line: number, requestConfig?: HttpClientRequestConfig): Promise<ProductionModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/productions/{id}/{line}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString(), id: (id === null || id === undefined) ? undefined : id.toString(), line: (line === null || line === undefined) ? undefined : line.toString() },
            null
        );

        const data: ProductionModel | null = await this.get<ProductionModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProductionModel");
        return data;
    }

    public async SaveProduction(projectDispatchCode: string | null, dispatchDate: Date, model: ProductionModel | null, requestConfig?: HttpClientRequestConfig): Promise<ProductionCollectionModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/productions",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: ProductionCollectionModel | null = await this.post<ProductionModel | null, ProductionCollectionModel | null>(uri, model, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProductionCollectionModel");
        return data;
    }

    public async DeleteProduction(projectDispatchCode: string | null, dispatchDate: Date, id: number, line: number, requestConfig?: HttpClientRequestConfig): Promise<ProductionCollectionModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/productions/{id}/{line}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString(), id: (id === null || id === undefined) ? undefined : id.toString(), line: (line === null || line === undefined) ? undefined : line.toString() },
            null
        );

        const data: ProductionCollectionModel | null = await this.delete<ProductionCollectionModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProductionCollectionModel");
        return data;
    }

    public async GetPlaces(projectDispatchCode: string | null, activityId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PlaceModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/productions/places",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { activityId: (activityId === null || activityId === undefined) ? undefined : activityId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PlaceModel[] | null = await this.get<PlaceModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.PlaceModel");
        return data;
    }
}
