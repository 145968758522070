import { FormControl } from "./../form-controls/form-control";
import { bindable, autoinject, bindingMode } from "aurelia-framework";

@autoinject()
export class MaTextarea extends FormControl {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) public maxlength: number = 100;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) public data: any;
    @bindable({ defealtBindingMode: bindingMode.twoWay }) public disabled: boolean = false;

    constructor(protected readonly element: Element) {
        super(element);
    }
}
