define([
    "core/resx",
    "repositories/settingRepository"
], function (resx, settingRepository) {
    "use strict";

    var exports = {
        getSelectedDispatchModel: function () {
            return settingRepository.getDispatchTemplate();
        },

        hasDispatchModel: function () {
            var template = settingRepository.getDispatchTemplate();
            return exports.hasSettings() && template && template !== "null" && template !== "-1";
        },

        hasSettings: function () {
            var company = settingRepository.getCompany();
            return company !== undefined && company !== null && company !== "";
        },

        isMultiPrefix: function() {
            return settingRepository.getPrefix() !== "";
        }

    };

    return exports;
});
