import { WorkOrderMaintenanceModel } from "api/models/company/workorder/work-order-maintenance-model";
import { PagingInfo } from "api/paging-info";
import { WorkOrderMaintenanceTaskProxy } from "api/proxies/work-order-maintenance-task-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class WorkOrderMaintenanceTaskService {
    constructor(private readonly workOrderMaintenanceTaskProxy: WorkOrderMaintenanceTaskProxy) {
    }

    public async getMaintenanceTasks(dispatchId: number, requestConfig?: IRequestConfig): Promise<WorkOrderMaintenanceModel | null> {
        return (await this.workOrderMaintenanceTaskProxy.GetworkOrderMaintenanceTasks(dispatchId, requestConfig));
    }

    public async updateMaintenanceTasks(workOrderMaintenanceModel: WorkOrderMaintenanceModel | null, requestConfig?: IRequestConfig): Promise<void> {
        await this.workOrderMaintenanceTaskProxy.UpdateWorkOrderMaintenanceTasks(workOrderMaintenanceModel, requestConfig);
    }

    public async updateMaintenanceCounters(workOrderMaintenanceModel: WorkOrderMaintenanceModel | null, requestConfig?: IRequestConfig): Promise<void> {
        await this.workOrderMaintenanceTaskProxy.UpdateWorkOrderMaintenanceCounters(workOrderMaintenanceModel, requestConfig);
    }
}
