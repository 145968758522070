import { Container } from "aurelia-dependency-injection";
import { ErrorManager } from "error-management/error-manager";

define([
    "jquery"
], function (jQuery) {
    "use strict";

    var clientInfo;

    var exports = {
        init: function () {
            var self = this;
            var dfd = new jQuery.Deferred();
            self.getClientInfo().done(function () {
                dfd.resolve(true);
            });
            return dfd;
        },

        validate: function () {
            jQuery.noop();
        },

        getClientInfo: function (forceGet) {
            var dfd = new jQuery.Deferred();
            if (clientInfo && !forceGet) {
                dfd.resolve(clientInfo);
            }
            else {
                //to eliminate circular reference...
                require(["services/requests"], function (requests) {
                    const errorManager = Container.instance.get(ErrorManager);

                    requests.getJson("api/clientinfo")
                        .done(function (data) {
                            clientInfo = data;
                            dfd.resolve(clientInfo);
                        })
                        .fail(function (xhr) {
                            errorManager.handleHttpRequestError(xhr);
                            dfd.reject();
                        });
                });
            }
            return dfd;
        },

        getMaestroVersion: function () {
            var self = this;
            var dfd = new jQuery.Deferred();
            self.getClientInfo().done(function (clientInfo) {
                var version = clientInfo.MaestroVersion.split('.');

                dfd.resolve(version[0]+ '.' + version[1]);
            });

            return dfd;
        }
    };

    return exports;
});
