import { autoinject, bindingMode, transient } from "aurelia-framework";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import Assert from "core/assert";
import { TimeListHelper, TimeListItem } from "helpers/time-list-helper";

@transient()
@autoinject()
export class TimePickerList {
    @bindable.nullable_number
    public increment: number | null = null;

    @bindable.booleanAttr
    public disabled: boolean = false;

    @observable.string
    public internalValue: string | null = null;

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public value: string | null = null;

    @bindable.string
    public label: string | null = null;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public options: TimeListItem[] = [];

    public async bind(): Promise<void> {
        // Generate the options if they were not provided
        if (!this.options || !this.options.length) {
            Assert.isTrue(this.increment !== null && 0 < this.increment, "Time picker list must either have options or increment to build options.");
            this.options = TimeListHelper.loadTimeList(this.increment as number);
        }

        // Trigger a value change to make sure the initial value is coerced to one of the items in the list.
        this.valueChanged(this.value);
    }

    /**
     * Called by the (+/-) buttons in the UI to iterate through the values.
     * @param step
     */
    public incrementValue(step: 1 | -1): void {
        // We expect the options to be sorted
        const firstItemId = this.options[0].id;
        const lastItemId = this.options[this.options.length - 1].id;

        const selectedItem = this.options.find((x: TimeListItem) => x.id + "" === this.internalValue);

        if (this.internalValue === null || selectedItem === undefined) {
            this.internalValue = firstItemId + "";
            return;
        }

        let newId: number = selectedItem.id + step;

        // Wrap around if we've reached the end
        if (lastItemId < newId) {
            newId = firstItemId;
        } else if (newId < firstItemId) {
            newId = lastItemId;
        }

        this.internalValue = newId + "";
    }

    public internalValueChanged(newValue: string | null): void {
        if (!this.options) {
            return; // Control is not initialized
        }

        const selectedItem = this.options.find((x: TimeListItem) => x.id + "" === newValue + "");

        if (newValue === null || selectedItem === undefined) {
            this.value = null;
        } else {
            this.value = selectedItem.text;
        }
    }

    public valueChanged(newValue: string | null): void {
        const item = this.findClosestItem(newValue);

        if (!item) {
            this.internalValue = null;
            return;
        }

        this.internalValue = item.id + "";
    }

    private findClosestItem(value: string | null): TimeListItem | null {
        if (value === null || value === undefined) {
            return null;
        }

        const item = this.findExactItem(value);
        if (item) {
            return item;
        }

        const lowerItems = this.options.filter((x: TimeListItem) => x.text < value);
        if (lowerItems.length > 0) {
            return lowerItems[lowerItems.length - 1];
        }

        return null;
    }

    private findExactItem(value: string | null): TimeListItem | null {
        if (value === null || value === undefined) {
            return null;
        }

        const item = this.options.find((x: TimeListItem) => x.text + "" === value + "");
        if (item !== undefined) {
            return item;
        }

        return null;
    }
}
