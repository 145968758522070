import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, computedFrom } from "aurelia-framework";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import documentService from "services/documentService";
import documentHelper from "helpers/documentHelper";
export interface QuotationDocumentsParameters {
    quotationId: number;
}

@autoinject()
export class QuotationDocuments {
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;

    public deleteAction!: () => Promise<boolean>;
    public subscriptions: Subscription[] = [];
    public actions: any;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings);
    }

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService) {
    }

    public async activate(params: QuotationDocumentsParameters): Promise<void> {

        this.quotation = await this.serviceCallQuotationService.getQuotation(params.quotationId, false, false, false, false, false);

        this.actions = {
            getSections: documentService.getSectionsByQuotationId.bind(self, this.quotation.Id),
            getSectionDocuments: documentService.getDocumentsByQuotationId.bind(self, this.quotation.Id),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService)
        };
    }
}
