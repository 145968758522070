import { Container } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";

// Ce module est maintenant une simple coquille qui appelle i18n. Éliminer quand on utilisera i18n directement partout.
define([], function () {
    "use strict";
    let i18n = null;

    const exports = {
        localize: function (key, language) {
            if (!i18n) {
                i18n = Container.instance.get(I18N);
            }

            if (language) {
                return i18n.tr(key, { lng: language });
            } else {
                return i18n.tr(key);
            }
        }
    };

    return exports;
});
