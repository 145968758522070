import { autoinject, bindingMode, customElement } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import { Key } from "ts-keycode-enum";

@autoinject()
@customElement("textbox")
export class Textbox extends FormControl {
    @bindable.string //
    public icon: string = "";

    // Set to true to position the icon before the input instead of after. Default is Append
    @bindable.booleanAttr //
    public prependIcon: boolean = false;

    @bindable.booleanAttr //
    public acceptSpace: boolean = true;
    
    @bindable.booleanAttr //
    public toUppercase: boolean = false;

    @bindable.number({ defaultValue: -1 }) //
    public maxlength!: number;

    @bindable.string //
    public placeholder: string = "";

    @bindable.booleanAttr
    public showClearButton: boolean = false;

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public value: string = "";

    private inputTextElementRef!: HTMLElement;
    private readonly onKeyDownSpaceEventHandlerPointer!: EventListener;
    private readonly onKeyUpSpaceEventHandlerPointer!: EventListener;

    constructor(element: Element) {
        super(element);

        this.onKeyDownSpaceEventHandlerPointer = (e: KeyboardEvent): void => {
            this.onKeyDownSpaceEventHandler(e);
        };

        this.onKeyUpSpaceEventHandlerPointer = (e: KeyboardEvent): void => {
            this.onKeyUpSpaceEventHandler(e);
        };
    }

    public clear(event: Event): void {
        event.stopPropagation();
        this.value = "";
    }

    public bind(): void {
        if (this.inputTextElementRef) {
            this.inputTextElementRef.addEventListener("keydown", this.onKeyDownSpaceEventHandlerPointer, true);
            this.inputTextElementRef.addEventListener("keyup", this.onKeyUpSpaceEventHandlerPointer, true);
        }
    }

    private onKeyDownSpaceEventHandler(e: KeyboardEvent): void {

        const enterHandler = (): void => {
            e.preventDefault();
        };

        switch (e.keyCode) {
            case Key.Space:
                if (this.acceptSpace) {
                    return;
                }

                return enterHandler();
            default:
                return;
        }
    }

    private onKeyUpSpaceEventHandler(e: KeyboardEvent): void {
        if (!this.acceptSpace && this.value) {
            this.value = this.value.replace(/\s/g, "");
        }

        if (this.toUppercase && this.value) {
            this.value = this.value.toUpperCase();
        }
    }
}
