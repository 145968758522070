import { inject, transient, bindable, bindingMode, DOM, observable, customElement } from "aurelia-framework";

import GuidHelper from "helpers/guidHelper";
import { default as resx } from "core/resx";
import { default as _ } from "underscore";
import { default as ajaxHelper } from "helpers/ajaxHelper2";
import { default as defaultValues } from "services/defaultService";

function updateLabel(self, newvalue) {
    if (!self.renderAsButton) {
        if (newvalue) {
            self.displayBtnText = newvalue.text;
            self.cssPlaceHolder = false;
        } else {
            self.displayBtnText = self.btnText;
            self.cssPlaceHolder = true;
        }
    }
}

function infiniteScroll(self) {
    var container = self.resultContainerElement;

    var scrollHeight = container.scrollHeight;
    var scrollTop = container.scrollTop;
    var offsetHeight = container.offsetHeight;

    // offsetHeight / 3 since there is a a space without item to allow scroll at the end of the list.
    var buffer = offsetHeight / 3 + 225; // 45px * 5 items = 225

    // Fix un bug ou on load des items à l'infini quand le contrôle est en display none
    if (offsetHeight === 0) return;

    if (scrollHeight - (scrollTop + offsetHeight) < buffer) {
        if (self.hasMoreResults && self.loadingMore === false) {
            self.loadingMore = true;
            ajaxHelper
                .loadMoreItems(self, false)
                .finally(() => {
                    self.loadingMore = false;
                })
                .then(() => {
                    infiniteScroll(self);
                });
        }
    }
}

function toggleModal(self, show) {
    if (show === true) {
        self.openSelect();
    } else {
        self.closeSelect();
    }
}

function reloadItems(self) {
    if (self.ajax && !self.inputTooShort) {
        self.searching = true;
        ajaxHelper
            .loadMoreItems(self, true)
            .finally(() => {
                self.searching = false;
            });
    }

    if (!self.ajax && !self.inputTooShort && self.unfilteredItems) {
        self.items = self.unfilteredItems.filter((item) => {
            return item.text.includes(self.searchInput);
        });
    }
}

@transient()
@inject(GuidHelper, Element)
@customElement("ma-select")
export class MaSelect {
    @bindable ajax;
    @bindable allowClear;
    @bindable btnIcon;
    @bindable btnText = resx.localize("SelectOoo");
    @bindable clearOnClose = false;
    @bindable clearSearchOnClose = false;
    @bindable context;
    @bindable cssPlaceHolder;
    @bindable data;
    @bindable disabled = false;
    // TODO Enlever cette propriété pourquoi on a ça et btn text c'est quoi la différence!!!!!
    @bindable displayBtnText = resx.localize("SelectOoo");
    @bindable displayIcon = false;
    @bindable fill = false;
    @bindable icon;
    @bindable initialisedList;
    @bindable minimumInputLength = 0;
    @bindable minimumResultsForSearch = defaultValues.getMinimumResultsForSearch();
    @bindable multiSelect = false;
    @bindable pageSize = defaultValues.getPageSize();
    @bindable placeholder = resx.localize("Search");
    @bindable prependIds = false;
    @bindable renderAsButton = false;
    @bindable searchEnabled = true;
    @bindable searchShown = true;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedItem;
    @bindable templateResult;
    @bindable templateSelection;
    @bindable title = resx.localize("SelectOoo");
    @bindable toggleModal;
    @bindable updateLabelOnSelection = true;
    @bindable okAction;

    // TODO JL Sortir les filtres du maSelect.Ils ont pas d'affaire là
    // Filters
    @bindable checkBoxFilter = false;
    @bindable checkBoxFilterOriginal = false;
    @bindable checkBoxFilterText = "";
    @bindable showCheckBoxFilter = false;
    @bindable filters = [];
    @bindable filterSelected = "";
    @bindable filtersFormat = "";
    @bindable filtersName = [];

    @observable({ changeHandler: 'filterSelectedChanged' }) searchInput = "";

    unfilteredItems = [];
    @bindable items = [];
    subscriptionReferences = [];
    resultContainerElement = null;
    page = 1;
    searching = false;
    loadingMore = false;
    hasMoreResults = false;
    modalShown = false;
    resx = resx;

    get iconCssClass() {
        return "fa fa-" + this.icon + " icon-title";
    }

    get inputTooShort() {
        return (
            this.minimumInputLength !== undefined &&
            this.searchInput.length < this.minimumInputLength
        );
    }

    get noResults() {
        if (this.searching === true) {
            return false;
        }

        if (this.inputTooShort) {
            return false;
        }

        if (_.isEmpty(this.items)) {
            return true;
        }

        return (
            this.multiSelect &&
            !_.any(this.items, item => {
                return !item.selected;
            })
        );
    }

    get buttonCssClass() {
        let cssClass = "button ma-select-container";

        if (!this.renderAsButton) {
            cssClass += " with-arrow";
        } else {
            cssClass += " action";
            cssClass += " blue";
        }

        if (this.disabled) {
            cssClass += " disabled";
        }

        if (this.fill) {
            cssClass += " fill";
        }

        if (this.btnIcon) {
            cssClass += ` ${this.btnIcon}`;
        }

        return cssClass;
    }

    constructor(guidHelper, element) {
        this.GuidHelper = guidHelper;
        this.element = element;
    }

    bind(bindingContext, overrideContext) {
        this.id = "maSelect_" + this.GuidHelper.createGuid();
        this.cssPlaceHolder = !this.renderAsButton;

        if (this.ajax != null) {
            ajaxHelper.initAjax(this, this.ajax);
        }

        if (this.selectedItem && this.updateLabelOnSelection) {
            updateLabel(this, this.selectedItem);
        }
    }

    attached() {
        if (this.data) {
            this.searchShown = this.searchShown && this.data.length >= this.minimumResultsForSearch;
        }

        toggleModal(this, this.toggleModal);

        this.unfilteredItems = this.items;
    }

    selectedItemChanged(newValue, oldValue) {
        if (this.updateLabelOnSelection) {
            updateLabel(this, newValue);
        }
    }

    filterSelectedChanged(newValue, oldValue) {
        reloadItems(this);
    }

    toggleModalChanged(newValue, oldValue) {
        toggleModal(this, newValue);
    }

    searchInputChanged(newValue, oldValue) {
        if (!this.items || !this.ajax) {
            return;
        }

        this.items = [];
        reloadItems(this);
    }

    addItem(data) {
        //TODO JL Sortir les filtres du maSelect.Ils ont pas d'affaire là
        if (this.filterSelected) {
            data.type = this.filterSelected;
        }

        if (this.multiSelect) {
            this.selectedItem.push(data);

            // Hide selected items in multiselect
            data.selected = true;

            // Load more items if we're at the end of the list
            infiniteScroll(this);
        } else {
            this.selectedItem = data;
            this.select();
        }
    }

    addItems(newItems) {
        this.items.push(...newItems);
    }

    removeItem(data) {
        var index = this.selectedItem.indexOf(data);

        if (index !== -1) {
            this.selectedItem.splice(index, 1);
        }

        if (this.multiSelect) {
            data.selected = false;
        }
    }

    openSelect() {
        document.activeElement.blur();
        // Fix a bug where we can scroll the page behind the modals
        // TODO JL: Marche pas sous iOS. Voir ticket 262564 pour réparer.
        document.body.style.overflow = "hidden";
        this.searchInput = "";

        if (this.initialisedList && this.initialisedList.length > 0) {
            this.selectedItem = this.initialisedList;
        }

        reloadItems(this);
        this.modalShown = true;
    }

    closeSelect(isCancelling) {
        document.activeElement.blur();

        // Fix a bug where we can scroll the page behind the modals
        // TODO JL: Marche pas sous iOS. Voir ticket 262564 pour réparer.
        document.body.style.overflow = "";

        this.modalShown = false;

        if (isCancelling) {
            this.cancel();
        }

        if(this.clearSearchOnClose) {
            this.items = [];
        }

        if (this.clearOnClose) {
            if (this.multiSelect) {
                this.selectedItem = [];
            } else {
                this.selectedItem = null;
            }
        }
    }

    toggleCheckBoxFilter() {
        this.checkBoxFilter = !this.checkBoxFilter;
        reloadItems(this);
    }

    resultsScrolled() {
        infiniteScroll(this);
    }

    //TODO JL Sortir les filtres du maSelect.Ils ont pas d'affaire là
    equipmentFilterSelected() {
        //TODO: HM: TO refactor, this code will be changed with filters/templates refactor as 'refresh' setting received.
        if (this.filtersName[this.filterSelected() - 1] === this.resx.localize("Equipment")) {
            return true;
        } else {
            return false;
        }
    }

    cancel() {
        var cancel = DOM.createCustomEvent("cancel", {});
        this.element.dispatchEvent(cancel);
    }

    clear(e) {
        e.stopPropagation();
        this.selectedItem = null;

        var clear = DOM.createCustomEvent("clear", {});
        this.element.dispatchEvent(clear);
    }

    select() {
        document.activeElement.blur();

        var select = DOM.createCustomEvent("select", {
            detail: this.selectedItem
        });
        this.element.dispatchEvent(select);

        if (this.okAction) {
            this.okAction(this.selectedItem);
        }

        this.closeSelect(false);
    }
}
