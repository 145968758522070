import { ShopDrawingModel } from "api/models/company/shop-drawing-model";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { ShopDrawingService } from "services/shop-drawing-service";
import { nameof as nameof_ShopDrawingStatus } from "api/enums/shop-drawing-status";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { ShopDrawingStatusModel } from "api/models/company/shop-drawing-status-model";

@autoinject
export class ShopDrawingEdit {
    public drawingId: number = 0;
    public status: string = "";
    public category: string | null = "";
    public contact: string | null = "";
    public supplier: string | null = "";
    public manufacturer: string | null = "";
    public dispatchProjectCode: string | null = "";
    public drawing: ShopDrawingModel | null = null;
    public readonly: boolean = true;
    public nameof_ShopDrawingStatus: string = nameof_ShopDrawingStatus;
    public statuses: ShopDrawingStatusModel[] | null = null;
 
    constructor(private readonly service: ShopDrawingService, private readonly enumFormatValueConverter: EnumFormatValueConverter,
                private readonly i18n: I18N) {
    }

    public async activate(params: any): Promise<void> {
        this.drawingId = params.drawingId;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.drawing = await this.service.getShopDrawing(this.drawingId);
        this.statuses = await this.service.getShopDrawingStatuses();

        this.status = this.drawing!.Status + " - " + this.getShopDrawingStatusDisplay(this.drawing!);

        if (this.drawing!.Category !== null) {
            this.category = this.drawing!.Category;
            if (this.drawing!.CategoryDescription !== null) {
                this.category = this.category + " - " + this.drawing!.CategoryDescription;
            }
        }

        if (this.drawing!.ContactNo !== 0) {
            this.contact = this.drawing!.ContactNo.toString();
            if (this.drawing!.ContactDescription !== null) {
                this.contact = this.contact + " - " + this.drawing!.ContactDescription;
            }
        }

        if (this.drawing!.SupplierId !== null) {
            this.supplier = this.drawing!.SupplierId;
            if (this.drawing!.SupplierDescription !== null) {
                this.supplier = this.supplier + " - " + this.drawing!.SupplierDescription;
            }
        }

        if (this.drawing!.ManufacturerCode !== null) {
            this.manufacturer = this.drawing!.ManufacturerCode;
            if (this.drawing!.ManufacturerDescription !== null) {
                this.manufacturer = this.manufacturer + " - " + this.drawing!.ManufacturerDescription;
            }
        }

    }

    public getShopDrawingStatusDisplay(item: ShopDrawingModel): string {
        if (this.statuses) {
            const status = this.statuses.find((stat: ShopDrawingStatusModel) => stat.Id === item.Status);
            if (status && status.Description) {
                return status.Description;
            }
        }
        return `${this.enumFormatValueConverter.toView(item.Status, nameof_ShopDrawingStatus)}`;
    }
}
