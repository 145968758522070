import Parse from "helpers/parse";

export class IntegerToStringValueConverter {
    public fromView(value: any): number | null {
        return Parse.NullableInteger(value);
    }

    public toView(value: any): string | null {
        return Parse.NullableString(value);
    }
}
