import Assert from "core/assert";
import { TimeListParameters } from "./time-list-parameters";
import { NumberHelper } from "./number-helper";

export interface TimeListItem {
    id: number;
    text: string;
    totalMinutes: number;
}

export class TimeListHelper {
    public static loadTimeList(increment: number): TimeListItem[] {
        const parameters = {
            increment: increment
        } as TimeListParameters;
        
        return this.loadTimeListWithParameters(parameters);
    }

    public static loadTimeListWithParameters(parameters: TimeListParameters): TimeListItem[] {
        const increment = !!parameters.increment ? parameters.increment : 30;
        
        const minHour = NumberHelper.clamp(parameters!.minHour, 0, 24);
        const minMinutes = NumberHelper.clamp(parameters!.minMinutes, 0, 59);

        const maxHour = NumberHelper.clamp(parameters!.maxHour, 0, 24, false);
        const maxMinutes = NumberHelper.clamp(parameters!.maxMinutes, 0, 59, false);

        Assert.isTrue(this.validateParameters(parameters));

        return this.generateTimeList(increment, minHour, minMinutes, maxHour, maxMinutes);
    }

    private static validateParameters(parameters: TimeListParameters): boolean {
        const incrementIsValid = !!parameters.increment && parameters.increment > 0;
        
        const minHourIsValid = !parameters.minHour || (parameters.minHour > 0 || parameters.minHour < 24);
        const minMinutesIsValid = !parameters.minMinutes || (parameters.minMinutes > 0 || parameters.minMinutes < 60);

        return incrementIsValid &&
               minHourIsValid &&
               minMinutesIsValid;
    }

    private static generateTimeList(increment: number, minHour: number, minMinutes: number, maxHour: number, maxMinutes: number): TimeListItem[] {
        const times: TimeListItem[] = [];
        let id = 0;

        for (let hours = minHour; hours <= maxHour && hours < 24; hours++) {
            for (let minutes = minMinutes; (hours < maxHour && minutes < 60) || (hours === maxHour && minutes <= maxMinutes); minutes += increment) {

                const time = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
                times.push({ id: ++id, text: time, totalMinutes: hours + minutes * 60 });
            }

            minMinutes = 0;
        }

        return times;
    }
}
