import { autoinject } from "aurelia-framework";

import { DispatchTemplateProxy } from "api/proxies/dispatch-template-proxy";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DispatchTemplateService {
    private readonly dispatchTemplateProxy: DispatchTemplateProxy;

    constructor(dispatchTemplateProxy: DispatchTemplateProxy) {
        this.dispatchTemplateProxy = dispatchTemplateProxy;
    }

    public async getDispatchTemplatesByDispatchId(dispatchId: number, httpClientRequestConfig?: HttpClientRequestConfig): Promise<DispatchTemplateModel> {
        return await this.dispatchTemplateProxy.GetDispatchTemplatesByDispatchId(dispatchId, httpClientRequestConfig) as DispatchTemplateModel;
    }

    public async GetDispatchTemplatesByCode(dispatchTemplateId: string, httpClientRequestConfig?: HttpClientRequestConfig): Promise<DispatchTemplateModel | null> {
        return await this.dispatchTemplateProxy.GetDispatchTemplatesByCode(dispatchTemplateId, httpClientRequestConfig);
    }

}
