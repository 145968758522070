define(function () {
    "use strict";
    
    var exports = {
        //(450) 888-8888
        getDefaultFormat: function (input) {
            if (input) {
                return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            } else {
                return '';
            }
        }
    };

    return exports;
});
