// GENERATED FILE - DO NOT MODIFY
import { TradeModel } from "api/models/company/trade-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class TradeProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetTrades(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
        const uri = this.buildUri(
            "/trades",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: TradeModel[] | null = await this.get<TradeModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }

    public async GetDispatchTrade(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/trade",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: TradeModel | null = await this.get<TradeModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }

    public async SetDispatchTrade(dispatchId: number, tradeCode: number, requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/trade/{tradeCode}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), tradeCode: (tradeCode === null || tradeCode === undefined) ? undefined : tradeCode.toString() },
            null
        );

        const data: TradeModel | null = await this.post<void, TradeModel | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }

    public async GetEmployeeTrades(employeeId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
        const uri = this.buildUri(
            "/employees/{employeeId}/trades",
            { employeeId: (employeeId === null || employeeId === undefined) ? undefined : employeeId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: TradeModel[] | null = await this.get<TradeModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }

    public async GetEmployeeDefaultTrade(employeeId: number, requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
        const uri = this.buildUri(
            "/employees/{employeeId}/defaulttrade",
            { employeeId: (employeeId === null || employeeId === undefined) ? undefined : employeeId.toString() },
            null
        );

        const data: TradeModel | null = await this.get<TradeModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }

    public async GetCurrentEmployeeTrades(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
        const uri = this.buildUri(
            "/employees/current/trades",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: TradeModel[] | null = await this.get<TradeModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }

    public async GetCurrentEmployeeDefaultTrade(requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
        const uri = this.buildUri(
            "/employees/defaulttrade",
            null,
            null
        );

        const data: TradeModel | null = await this.get<TradeModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }

    public async GetDispatchEmployeeTrades(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/trades",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: TradeModel[] | null = await this.get<TradeModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.TradeModel");
        return data;
    }
}
