import { Container, bindable, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import Config from "config";
import { DateFormatValueConverter } from "converters/format/date-format";
import { CurrencyFormatValueConverter } from "converters/format/number-format";
import { BooleanToYesNoValueConverter } from "converters/misc/boolean-to-yes-no";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { RowNode } from "ag-grid";
import { UserGridStateService } from "services/user-grid-state-service";
import { UserGridStateModel } from "api/models/company/user-grid-state-model";
import { EventAggregator } from "aurelia-event-aggregator";

export class GridBase {
    @bindable
    public items: any[] = [];
    @bindable
    public showFullSearchBox: boolean = true;
    @bindable
    public showRightPanel: boolean = false;
    @bindable
    public minHeight: number = 400;

    @bindable
    public autosizeColumnsOnWindowResize: boolean = false;

    public self: GridBase = this;

    public internalItems?: any[];

    @bindable
    public gridOptions: any;

    @bindable
    public gridId: string = "";

    protected columns: any;

    private onWindowResizeHandlerPointer!: EventListener;

    @observable
    private fullSearchText: string = "";

    private currentGridColumnState: string | null = null;
    private currentGridFilterState: string | null = null;
    private currentGridSortState: string | null = null;

    constructor(protected readonly element: Element, protected readonly i18n: I18N, protected readonly userGridStateService: UserGridStateService, protected readonly eventAggregator: EventAggregator) {
    }

    public async bind(): Promise<void> {
        this.gridOptions = this.createGridOptions();
        this.gridOptions.cacheQuickFilter = true;

        if (this.eventAggregator) {
            const subscription = this.eventAggregator.subscribe("ApplyFilter", async () => {
                const userGridState: UserGridStateModel | null = await this.userGridStateService.getUserGridState(this.gridId);

                if (userGridState !== null) {
                    this.currentGridFilterState = userGridState.FilterState;
                    if (this.currentGridFilterState) {
                        this.gridOptions.api.setFilterModel(JSON.parse(this.currentGridFilterState));
                    }
                }
            });
        }
    }

    public async created(): Promise<void> {
        // TODO: Le lazy-load ne fonctionne pas?
        // @ts-ignore
        await import("../../../../styles/ag-grid.scss");
        if (this.autosizeColumnsOnWindowResize) {
            this.onWindowResizeHandlerPointer = (e: Event): void => {
                this.onWindowResizeEventHandler();
            };
            window.addEventListener("resize", this.onWindowResizeHandlerPointer);
        }
    }

    public detached(): void {
        if (this.autosizeColumnsOnWindowResize) {
            window.removeEventListener("resize", this.onWindowResizeHandlerPointer);
        }

    }

    public fullSearchTextChanged(): void {
        if (!this.gridOptions) { return; }
        // TODO: Va fonctionner seulement si les données sont toutes dans le client. À revoir quand on va vouloir tout exécuter sur le serveur.
        this.gridOptions.api.setQuickFilter(this.fullSearchText);
    }

    public onRowDoubleClicked(node: any): void {
        CustomEventHelper.dispatchEvent(this.element, "row-double-clicked", { data: node.data, grid: this }, true, false);
    }

    public onRowSelected(row: any): void {
        const selectedRows = this.getSelectedItems();

        CustomEventHelper.dispatchEvent(this.element, "row-selected", { data: selectedRows, grid: this, row: (row as RowNode) }, true, false);
    }

    public addItem(item: any): void {
        const transaction: any = this.gridOptions.api.updateRowData({
            add: [item]
        });

        //this.gridOptions.api.deselectAll();
        transaction.add[0].setSelected(true, true);
    }

    public removeItems(items: any[]): void {
        this.gridOptions.api.updateRowData({
            remove: items
        });
    }

    public getAllItems(): any[] {
        if (this.gridOptions.api !== undefined) {
            const rowData: any[] = [];

            this.gridOptions.api.forEachNode((node: any) => rowData.push(node.data));

            return rowData;
        }
        return [];
    }

    public getSelectedItems(): any[] {
        if (this.gridOptions.api !== undefined) {
            const dataFunction = this.getRowData;
            return this.gridOptions.api.getSelectedNodes().map((x: any): any => dataFunction(this, x));

        }
        return [];
    }

    public refresh(): void {
        // TODO: Raffraichir seulement certaines cellules/rangées.
        this.gridOptions.api.refreshCells();
    }

    public setRowData(data: any): void {
        this.gridOptions.api.setRowData(data);
    }

    public cellStyleRightAlign(): any {
        return { "text-align": "right" };
    }
    public cellStyleCenterAlign(): any {
        return { "text-align": "center" };
    }
    public cellStyleLeftAlign(): any {
        return { "text-align": "left" };
    }
    public cellStyleLeftAlignWithValidationErrors(): any {
        return { "text-align": "left", "border": "2px solid red" };
    }

    public booleanFormatter(params: any): string {
        const booleanToYesNoValueConverter: BooleanToYesNoValueConverter = Container.instance.get(BooleanToYesNoValueConverter);

        return booleanToYesNoValueConverter.toView(params.value);
    }

    public currencyFormatter(params: any): any {
        const currencyFormatValueConverter: CurrencyFormatValueConverter = Container.instance.get(CurrencyFormatValueConverter);

        return currencyFormatValueConverter.toView(params.value);
    }

    public shortDateFormatter(params: any): any {
        const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);
        return dateFormatValueConverter.toView(params.value, "L");
    }

    public dateTimeFormatter(params: any): any {
        const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);
        return dateFormatValueConverter.toView(params.value, "L LTS");
    }

    public dateTimeFormatterFromValue(value: any): any {
        const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);
        return dateFormatValueConverter.toView(value, "L LTS");
    }

    public dateTimeRemoveEmptyTimeFormatter(params: any): any {
        const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);

        if (params.value && params.value.toString().includes("00:00:00")) {
            return dateFormatValueConverter.toView(params.value, "L");
        }

        return dateFormatValueConverter.toView(params.value, "L LTS");
    }

    public getRowData(self: any, params: any): any {
        let data = params.data;

        if (!data) {
            const key = params.node ? params.node.key : params.key;
            data = self.internalItems!.find((item: any): any => item.MaestroIdentifier === key ||
                                                                item.ProcoreIdentifier === key);

            if (!data) {
                return {
                    Id: null,
                    Errors: [],
                    Warnings: []
                };
            }
        }

        return data;
    }

    protected async onGridStateChanged(params: any): Promise<void> {
        if (this.gridId !== "") {
            if (params.type === "columnResized") {
                if (!params.finished) {
                    return;
                }
            }

            const newGridColumnState = JSON.stringify(this.gridOptions.columnApi.getColumnState());
            const newGridFilterState = JSON.stringify(this.gridOptions.api.getFilterModel());
            const newGridSortState = JSON.stringify(this.gridOptions.api.getSortModel());

            if (this.currentGridColumnState !== newGridColumnState || this.currentGridFilterState !== newGridFilterState || this.currentGridSortState !== newGridSortState) {
                await this.userGridStateService.setUserGridState(this.gridId, newGridColumnState, newGridFilterState, newGridSortState);
            }
        }
    }

    protected onGridReady(params: any): void {
        CustomEventHelper.dispatchEvent(this.element, "grid-ready", { grid: this }, true, false);

        this.getGridStates();
    }

    private async getGridStates(): Promise<void> {
        if (this.gridId !== "") {

            const userGridState: UserGridStateModel | null  = await this.userGridStateService.getUserGridState(this.gridId);

            if (userGridState !== null) {
                this.currentGridColumnState = userGridState.ColumnState;
                this.currentGridFilterState = userGridState.FilterState;
                this.currentGridSortState = userGridState.SortState;

                if (this.currentGridColumnState) {
                    this.gridOptions.columnApi.setColumnState(JSON.parse(this.currentGridColumnState));
                }

                if (this.currentGridFilterState) {
                    this.gridOptions.api.setFilterModel(JSON.parse(this.currentGridFilterState));
                }

                if (this.currentGridSortState) {
                    this.gridOptions.api.setSortModel(JSON.parse(this.currentGridSortState));
                }
            }
        }
    }

    private createGridOptions(): any {

        if (this.gridId !== "") {
            return {
                onGridReady: (params: any): any => this.onGridReady(params),
                onColumnMoved: (params: any): any => this.onGridStateChanged(params),
                onColumnResized: (params: any): any => this.onGridStateChanged(params),
                onColumnVisible: (params: any): any => this.onGridStateChanged(params),
                onColumnRowGroupChanged: (params: any): any => this.onGridStateChanged(params),
                onSortChanged: (params: any): any => this.onGridStateChanged(params),
                onFilterChanged: (params: any): any => this.onGridStateChanged(params),
                getMainMenuItems: (params: any): any => this.getMainMenuItems(params),
                getContextMenuItems: (params: any): any => this.getContextMenuItems(params),
                getRowNodeId: (data: any): any => data.Id,
                localeTextFunc: (key: any, defaultValue: any): string =>
                    this.localizeAgGridText(key, defaultValue),
                debug: Config.debug,

                singleClickEdit: true,
                multiSortKey: "ctrl"
            };
        }

        return {
            onGridReady: (params: any): any => this.onGridReady(params),
            getMainMenuItems: (params: any): any => this.getMainMenuItems(params),
            getContextMenuItems: (params: any): any => this.getContextMenuItems(params),
            localeTextFunc: (key: any, defaultValue: any): string =>
                this.localizeAgGridText(key, defaultValue),
            debug: Config.debug,

            singleClickEdit: true
        };
    }

    private getMainMenuItems(params: any): any[] {
        const result: any[] = [];
        result.push("pinSubMenu"); // "Figer la colonne"
        result.push("separator");
        result.push("autoSizeThis"); // "Redimensionner automatiquement cette colonne"
        result.push("autoSizeAll"); // "Redimensionner automatiquement toutes les colonnes"
        result.push("separator");

        // "Grouper/Dégrouper par [Column Name]"
        if (params.column.colDef.showRowGroup !== true) {
            result.push(
                params.column.rowGroupActive ? "rowUnGroup" : "rowGroup"
            );
            result.push("separator");
        }

        // "Réinitialiser les colonnes"
        result.push("resetColumns");

        const group = this.gridOptions.columnApi
            .getAllColumns()
            .find((x: any): boolean => x.rowGroupActive === true);
        if (group) {
            result.push("expandAll"); // "Tout développer"
            result.push("contractAll"); // "Tout fermer"
        }

        return result;
    }

    private getContextMenuItems(params: any): any[] {
        return [
            //"copy",
            //"separator",
            "resetColumns" // "Réinitialiser les colonnes"
        ];
    }

    private localizeAgGridText(key: any, defaultValue: any): string {
        const prefixedKey = `ag-grid-${key}`;
        const value = this.i18n.tr(prefixedKey);

        if (!value || value === prefixedKey) {
            return defaultValue;
        }

        return value;
    }

    private onWindowResizeEventHandler(): void {
        if (this.gridOptions.api !== undefined) {
            this.gridOptions.api.sizeColumnsToFit();
        }
    }
}
