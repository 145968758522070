import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";

export class ProjectContactDetail {

    public contactId: string = "";
    public dispatchProjectCode: string = "";
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N) {
    }

    public activate(params: any): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);

        this.dispatchProjectCode = params.dispatchProjectCode;
        this.contactId = params.id;
    }
}
