import moment from "moment";
import { LocalizationHelper } from "helpers/localization-helper";
import { autoinject } from "aurelia-framework";

interface DateFormatConfig {
    format?: string;
    locale?: string;
}

@autoinject()
export class DateFormatValueConverter {

    constructor(private readonly localizationHelper: LocalizationHelper) {
        
    }

    public toView(value: any, config: DateFormatConfig | string): string | null {
        if (!value) {
            return null;
        }

        const format = typeof config === "string" ? config : (config as DateFormatConfig).format;
        const locale = typeof config === "string" ? this.localizationHelper.getCultureCode() : this.localizationHelper.getCultureCode((config as DateFormatConfig).locale);

        moment.locale(locale);

        return moment(value).format(format || "LLLL");
    }
}
