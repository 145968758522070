import { DocumentProxy } from "api/proxies/document-proxy";
import routerHelper from "helpers/routerHelper";
import documentService from "services/documentService";
import { TemplateService } from "services/template-service";
import { autoinject } from "aurelia-framework";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { SettingRepository } from "repositories/setting-repository";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class DailyEntryDocumentAdd extends FormBase {
    public readonly: boolean = false;
    public dispatchProjectCode: string | null = null;
    public dailyEntryDate: string | null = null;
    public actions: any;
    public isCategorySelectionAllowed: boolean = false;
    public getCategoryProxy?: () => Promise<DocumentCategoryModel[] | null>;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly templateService: TemplateService, private readonly documentProxy: DocumentProxy, private readonly settingRepository: SettingRepository) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dailyEntryDate = params.dailyEntryDate;
        this.actions = { save: documentService.saveMultipleProjectPictures.bind(self, this.dispatchProjectCode, this.dailyEntryDate) };

        this.isCategorySelectionAllowed = this.templateService.getCurrentTemplateConfigs().AllowCategorySelection;
        this.getCategoryProxy = this.documentProxy.GetCategories.bind(this.documentProxy, this.settingRepository.getDispatchTemplate());
    }
}
