import { inspectionTypes } from "helpers/enumHelper";
import { checkedNumberValues } from "helpers/enumHelper";
import { yesNoNumberValues } from "helpers/enumHelper";
import { bindable, autoinject } from "aurelia-framework";
import { InspectionItemModel } from "api/models/company/inspection/inspection-item-model";
import { InspectionItemValueModel } from "api/models/company/inspection/inspection-item-value-model";
import val from "core/val";
import _ from "underscore";

@autoinject()
export class MaInspections {
    public val: any = val;

    public id: any;
    public type: number = 0;
    public min: number = 1;
    public max: number = 100;
    public unit: string = "";
    public equipmentCounterType: string = "";
    public counterActualValue: string = "";
    public hasEquipmentCounter: boolean = false;
    @bindable public value: any;
    @bindable public item: InspectionItemModel | null = null;
    @bindable public choiceValues: Array<InspectionItemValueModel | null> | null = null;
    @bindable public disable: boolean = false;

    public inspectionTypes: typeof inspectionTypes = inspectionTypes;
    public checkedNumberValues: typeof checkedNumberValues = checkedNumberValues;
    public yesNoNumberValues: typeof yesNoNumberValues = yesNoNumberValues;

    public bind(): void {
        if (this.item !== null) {
            const itemType = this.item.Type!;
            const measurementType = itemType.MeasurementType!;

            this.id = this.item.LineNumber;
            this.type = measurementType.Type;
            this.min = measurementType.MinimumValue;
            this.max = measurementType.MaximumValue;
            this.unit = measurementType.Unit.toString();
            this.counterActualValue = itemType.CurrentEquipmentCounterValue.toString();
            this.equipmentCounterType = itemType.EquipmentCounterType ? itemType.EquipmentCounterType : "";
            this.hasEquipmentCounter = itemType.HasEquipmentCounter;
        }
    }

    public setValue(value: any): void {
        this.value = value;
    }

    public choiceChanged(inspectionItemValueId: number): void {
        if (!this.item || !this.choiceValues) { return; }
        const currentItemInspectionValue = _.find(this.choiceValues, (value: InspectionItemValueModel) => value!.Id === inspectionItemValueId);
        const index = this.choiceValues.indexOf(currentItemInspectionValue!);
        if (!currentItemInspectionValue) { return; }

        if (this.type === inspectionTypes.SIMPLE) {
            this.choiceValues.forEach((value: any) => {
                value.IsSelected = value.Id === inspectionItemValueId;
            });
        } else {
            currentItemInspectionValue.IsSelected = !currentItemInspectionValue.IsSelected;
        }

        this.choiceValues[index] = currentItemInspectionValue;
    }
}
