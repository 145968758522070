import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";
import services from "services/serviceService";
import { I18N } from "aurelia-i18n";

import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import RouteRepository from "repositories/routeRepository";
import { EquipmentMaintenanceParameters } from "./equipment-maintenance-required-material";
import { ServiceCallContractEquipmentStatusModel } from "../../api/models/company/service/service-call-contract-equipment-status-model";
import { ServiceCallMaintenanceStatus } from "api/enums/service-call-maintenance-status";

@autoinject
export class EquipmentMaintenance {
    public equipmentsCards!: MaCardOptionsModel[];
    public i18n: I18N;
    public context: any = this;

    private dispatchId: number = 0;
    private entityId: number | null = null;
    private serviceType: string = "";
    private readonly: boolean = false;
    private equipments: ServiceCallContractEquipmentStatusModel[] | null = null;

    constructor(private readonly routeRepository: RouteRepository, private readonly i18N: I18N) {
        this.i18n = i18N;
        this.routeRepository = routeRepository;
    }

    public async searchEquipment(filter: string): Promise<void> {
        this.loadData(filter);
    }

    public async activate(params: any): Promise<void> {
        const queryResults = routerHelper.getQuerystring(params.q);

        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.entityId = queryResults.entityId;
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;

        await this.loadData(null);
    }

    public createCard(item: ServiceCallContractEquipmentStatusModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            id: item.Id,
            model: item,
            action: this.goToMaintenanceTasks.bind(this, item),
            class: "ma-card-link",
            displayLines: [
                new MaCardLineModel({ isCustomTemplate: true })
            ]
        });

        card.actionItems!.push(new MaCardActionModel({ id: "status", icon: this.setMaintenanceStatus(item.Status)}));
        card.actionItems!.push(new MaCardActionModel({ id: "mat", icon: "fa fa-info-circle fa-lg blue pb-3 fa-fw", action: this.goToMaintenanceRequiredMaterial.bind(this, item) }));
        card.actionItems!.push(new MaCardActionModel({ id: "edit", icon: "fa fa-chevron-right fa-fw", action: this.goToMaintenanceTasks.bind(this, item) }));

        return card;
    }

    public goToMaintenanceTasks(model: ServiceCallContractEquipmentStatusModel): void {
        const params = {
            serviceType: this.serviceType,
            dispatchId: this.dispatchId,
            equipmentId: model.Id,
            equipmentCode: model.Code,
            maintenanceId: model.MaintenanceId,
            maintenanceFullDescription: model.MaintenanceFullDescription,
            equipmentBrand: model.Brand,
            equipmentDescription: model.Description,
            equipmentSerialNo: model.Serial,
            readonly: this.readonly
        } as EquipmentMaintenanceParameters;

        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_EquipmentMaintenanceTask.name, params);
    }

    public goToMaintenanceRequiredMaterial(model: ServiceCallContractEquipmentStatusModel): void {
        const params = {
            serviceType: this.serviceType,
            dispatchId: this.dispatchId,
            equipmentId: model.Id,
            equipmentCode: model.Code,
            maintenanceId: model.MaintenanceId,
            maintenanceFullDescription: model.MaintenanceFullDescription,
            equipmentBrand: model.Brand,
            equipmentDescription: model.Description,
            equipmentSerialNo: model.Serial,
            readonly: this.readonly
        } as EquipmentMaintenanceParameters;

        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_EquipmentMaintenanceRequiredMaterial.name, params);
    }

    public goToRequiredMaterialSummary(model: ServiceCallContractEquipmentStatusModel): void {
        const params = {
            serviceType: this.serviceType,
            dispatchId: this.dispatchId,
            readonly: this.readonly
        } as any;

        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_EquipmentMaintenanceMaterialSummary.name, params);
    }

    private async loadData(filter: string | null): Promise<void> {
        this.equipments = await services.getEquipmentsForMaintenance(this.serviceType, this.dispatchId, filter);
        this.equipmentsCards = this.equipments!.map(this.createCard.bind(this));
    }

    private setMaintenanceStatus(status: ServiceCallMaintenanceStatus): string {
        switch (status) {
            case ServiceCallMaintenanceStatus.Complete: {
                return "fa fa-check-circle fa-lg text-success pb-3 fa-fw";
            }
            case ServiceCallMaintenanceStatus.InProgress: {
                return "fa fa-exclamation-triangle fa-lg text-warning pb-3 fa-fw";
            }
            default:
                return "";
        }
    }
}
