define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getTradesForCompany: function (filter, page) {
            var url = apiHelper.getBaseUrl() + '/trades';
            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        getTradeForDispatch: function (dispatchId) {
            return requests.getJson(apiHelper.getBaseUrl() + '/dispatch/' + dispatchId + '/trade');
        },

        getTradesForDispatch: function (dispatchId, filter, page) {
            var url = apiHelper.getBaseUrl() + '/dispatch/' + dispatchId + '/trades';

            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        getTradesForEmployee: function (employeeId, filter, page) {
            var url = apiHelper.getBaseUrl() + '/employees/' + employeeId + '/trades';
            url = apiHelper.appendFilterPagingParams(url, filter, page);
            return requests.getJson(url);
        },

        setTradeForDispatch: function (dispatchId, tradeCode) {
            return requests.post(apiHelper.getBaseUrl() + '/dispatch/' + dispatchId + '/trade/' + tradeCode);
        },

        getEmployeeDefaultTrade: function (employeeId) {
            var url = apiHelper.getBaseUrl() + "/employees/" + employeeId + "/defaulttrade";
            return requests.getJson(url);
        },

        getCurrentEmployeeDefaultTrade: function () {
            var url = apiHelper.getBaseUrl() + "/employees/defaulttrade";
            return requests.getJson(url);
        }
    };

    return exports;
});
