import {
    coerceFunctions,
    createTypedBindable,
    createTypedObservable,
    usePropertyTypeForBindable,
    usePropertyTypeForObservable,
} from "aurelia-typed-observable-plugin";

import Assert from "core/assert";
import Parse from "helpers/parse";

export async function configure(): Promise<void> {
    overrideCoerceFunctions();

    // Guess observable/bindable type from typescript decorator metadata.
    usePropertyTypeForBindable(false);
    usePropertyTypeForObservable(false);
}

function overrideCoerceFunctions(): void {
    // Do not coerce.
    coerceFunctions.none = (value: any): any => {
        return value;
    };

    coerceFunctions.boolean = (value: any): boolean => {
        return Parse.Boolean(value);
    };

    coerceFunctions.nullable_boolean = (value: any): boolean | null => {
        return Parse.NullableBoolean(value);
    };

    // Convert to boolean
    coerceFunctions.booleanAttr = (value: any): boolean => {
        if (value === "") {
            /* String empty required for attribute without value eg: <input readonly>, readonly must be true */
            return true;
        }

        return Parse.Boolean(value);
    };

    // Convert to decimal
    coerceFunctions.number = (value: any): number => {
        return Parse.Decimal(value);
    };

    // Try to convert to decimal or return null
    coerceFunctions.nullable_number = (value: any): number | null => {
        return Parse.NullableDecimal(value);
    };

    // Cast to string
    coerceFunctions.string = (value: any): string | null => {
        if (value === null || value === undefined) {
            return null;
        }

        return "" + value;
    };

    // Convert to date
    coerceFunctions.date = (value: any): Date | null => {
        if (value === null || value === undefined) {
            return null;
        }

        if (value === "today") {
            return new Date();
        }

        Assert.isDate(value);

        return value;
    };

    // Add custom type to enable fluent syntax
    ["none", "nullable_number"].forEach((x: string) => {
        createTypedBindable(x);
        createTypedObservable(x);
    });
}
