define([
    "services/requests",
    "repositories/settingRepository",
    "helpers/apiHelper"
], function (requests, settings, apiHelper) {
    "use strict";

    var exports = {
        // TODO: KG - ShowLoading: True et False - Transport
        getClientsLookup: function (filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getBaseUrl() + "/clients/lookup",
                    filter,
                    page)
            );
        },

        getClientInfoForWorkOrder: function (workOrderId) {
            return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/client");
        },

        // TODO: KG - ShowLoading: True et False - Transport
        getClientLookupForWorkOrder: function (workOrderId) {
            return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/client/lookup");
        },

        getClientFromProject: function (projectId) {
             return requests.getJson(apiHelper.getBaseUrl() + "/project/client?projectId=" + projectId);
        }

    };

    return exports;
});
