// GENERATED FILE - DO NOT MODIFY
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { DocumentModel } from "api/models/company/document/document-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DocumentProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async CheckDocumentExists(documentId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/documents/{documentId}/checkexists",
            { documentId: (documentId === null || documentId === undefined) ? undefined : documentId.toString() },
            null
        );

        const data: void = await this.get<void>(uri, requestConfig);
        return data;
    }

    public async GetDocument(documentId: number, requestConfig?: HttpClientRequestConfig): Promise<Uint8Array | null> {
        const uri = this.buildUri(
            "/documents/{documentId}",
            { documentId: (documentId === null || documentId === undefined) ? undefined : documentId.toString() },
            null
        );

        const data: Uint8Array | null = await this.get<Uint8Array | null>(uri, requestConfig);
        return data;
    }

    public async GetCategories(dispatchTemplateId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentCategoryModel[] | null> {
        const uri = this.buildUri(
            "/documents/{dispatchTemplateId}/categories",
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DocumentCategoryModel[] | null = await this.get<DocumentCategoryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.DocumentCategoryModel");
        return data;
    }

    public async Update(documentId: number, model: DocumentModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/documents/{documentId}",
            { documentId: (documentId === null || documentId === undefined) ? undefined : documentId.toString() },
            null
        );

        await this.post<DocumentModel | null, void>(uri, model, requestConfig);
    }

    public async DeleteDocument(documentId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/documents/{documentId}",
            { documentId: (documentId === null || documentId === undefined) ? undefined : documentId.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }
}
