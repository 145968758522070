export class EnumHelper {
    public static getNumericValues<T>(enumObject: T): number[] {
        return Object
            .values(enumObject)
            .filter((x: any) => typeof x === "number")
            .map((x: any) => x as number);
    }

    public static getStringValues<T>(enumObject: T): string[] {
        return Object
            .values(enumObject)
            .filter((x: any) => typeof x === "string")
            .map((x: any) => x as string);
    }

    public static getEnumKeyByEnumValue(myEnum: any, enumValue: any): string | null {
        const keys: any = Object.keys(myEnum).filter((x: any) => myEnum[x] === enumValue);
        return keys.length > 0 ? keys[0] : null;
    }
}
