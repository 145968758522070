import { default as jQuery } from "jquery";
import Config from "config";
import logger from "core/logger";

export class GeolocationService {
    getCurrentLocation() {
        const dfd = new jQuery.Deferred();

        const successCallback = (position) => {
            dfd.resolve(position);
        };

        const failureCallback = (positionError) => {
            logger.warn("Geolocation failed: " + positionError.code + " - " + positionError.message);
            dfd.reject(positionError);
        };
        
        window.navigator.geolocation.getCurrentPosition(successCallback, failureCallback, { timeout: Config.geolocationTimeout, maximumAge: Config.geolocationMaximumAge });

        return dfd;
    }
}