import { customElement, autoinject, transient, containerless } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { SynchronizationGrid } from "../synchronization-grid";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
@customElement("order-invoices-synchronization-grid")
@containerless
export class OrderInvoicesSynchronizationGrid extends SynchronizationGrid {
    constructor(element: Element, i18n: I18N, userGridStateService: UserGridStateService, eventAggregator: EventAggregator) {
        super(element, i18n, userGridStateService, eventAggregator);
    }
}
