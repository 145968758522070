import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";
import routerHelper from "helpers/routerHelper";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { ServiceDispatchType } from "enums/service-dispatch-type";

@autoinject()
export class ServiceMaterialRequisitionEdit extends FormBase {

    public actions: any = {};
    public dispatchId: number = 0;
    public requisitionId: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public isDirty: boolean = false;
    public parentModel: any = null;
    public isProject: boolean = false;
    public serviceType: string = "";
    public dispatchProjectCode: string = "";
    public projectCode: string = "";

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
    }

    public activate(params: any): any {
        this.dispatchId = params.dispatchId;
        this.requisitionId = params.requisitionId;
        this.serviceType = params.serviceType;
        this.isProject = this.serviceType === ServiceDispatchType.Workorder;
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.parentModel = this;

        const qsParams = routerHelper.getQuerystring(params.q);
          
        if (qsParams && qsParams.projectCode) {
            this.projectCode = qsParams.projectCode;
        }

        if (qsParams && qsParams.dispatchProjectCode) {
            this.dispatchProjectCode = qsParams.dispatchProjectCode;
        }
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForService.bind(materialRequisitionService, this.dispatchId),
            setSpecificFields: this.setSpecificFields.bind(this)
        };

    }

    public setSpecificFields(item: MaterialRequisitionModel): any {
        item.DispatchId = this.dispatchId;
        return item;
    }

}
