
export class BambooSyncCellRenderer {

    public eGui: any = null;

    public init(params: any): void {
        const tmpDir = document.createElement("div");
        const value = params.value;
        if (!!params.data) {
            const reasonType = params.data.reasonType;
            if (reasonType) {
                let faIcon = "<icon-to-be-set>";
                let iconColor = "<icon-color-to-be-set>";

                if (reasonType === "warning-icon") {
                    faIcon = "fa-exclamation-triangle";
                    iconColor = "#EEC302;";
                    
                } else if (reasonType === "info-icon") {
                    faIcon = "fa-exclamation-circle";
                    iconColor = "#6699CC;";

                } else if (reasonType === "error-icon") {
                    faIcon = "fa-exclamation-triangle";
                    iconColor = "red;";

                } else {
                    faIcon = "circle";
                    iconColor = "red;";
                }
                tmpDir.innerHTML = '<span><i class="fa ' + faIcon + '" style="color:' + iconColor + '"></i><span>&nbsp;&nbsp;&nbsp;' + value + "</span></span>";
            } else {
                tmpDir.innerHTML = "<span>" + value + "</span>";
            }
            this.eGui = tmpDir.firstChild;
        }
    }

    public getGui(): any {
        return this.eGui;
    }
}
