import { TimeKeepingResponseModel } from "api/models/company/time-keeping-response-model";
import { TimeKeepingModel } from "api/models/company/time-keeping-model";
import { TimeKeepingProxy } from "api/proxies/time-keeping-proxy";
import { autoinject } from "aurelia-framework";
import { TimeKeepingStatus } from "api/enums/time-keeping-status";
import { GeolocationService } from "services/geolocation-service";
import { I18N } from "aurelia-i18n";
import moment from "moment";

@autoinject
export class TimeKeepingService {
  constructor(private readonly timeKeepingProxy: TimeKeepingProxy,
              private readonly geolocationService: GeolocationService,
              private readonly i18n: I18N) { }
  
  public async Load(): Promise<TimeKeepingResponseModel | null> {
    return await this.timeKeepingProxy.GetCurrentActiveTimeKeeping();
  }

  public async LoadPunch(punchId: number): Promise<TimeKeepingModel | null> {
    return await this.timeKeepingProxy.GetTimeKeeping(punchId);
  }

  public async CreateTimeKeeping(punchStatus: TimeKeepingStatus): Promise<TimeKeepingModel | null> {
    const currentPosition = await this.geolocationService.getCurrentLocation();

    return await this.timeKeepingProxy.CreateTimeKeeping(currentPosition, punchStatus);
  }

  public async UpdateTimeKeeping(punch: TimeKeepingModel | null): Promise<TimeKeepingModel | null> {
    return await this.timeKeepingProxy.UpdateTimeKeeping(punch);
  }

  public async SetNextDay(): Promise<void> {
    await this.timeKeepingProxy.NextDay();
  }

  public async DidNotWork(): Promise<void> {
    await this.timeKeepingProxy.DidNotWorking();
  }

  public getDateTitleWithColorClass(punchDate: Date): any {
      const today = moment(new Date());

      const isToday = moment(punchDate).isSame(today, "days");
      const isYesterday = moment(punchDate).isSame(today.subtract(1, "days"), "days");

      if (isToday) {
      return {
        dateTitle: this.i18n.tr("Today"),
        dateTitleClass: "text-success"
      };
      } else if (isYesterday) {
      return {
        dateTitle: this.i18n.tr("Yesterday"),
        dateTitleClass: "text-warning"
      };
    } else {
      return {
        dateTitle: this.i18n.tr("Late"),
        dateTitleClass: "text-danger"
      };
    }
  }
}
