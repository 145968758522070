import { default as requests } from "services/requests";
import { default as apiHelper } from "helpers/apiHelper";

export default class EstimateService {

    getEstimate(estimateId) {
        return requests.getJson(apiHelper.getBaseUrl() + "/estimates/" + estimateId);
    }

    getTakeoffConfig(estimateId) {
        return requests.getJson(apiHelper.getBaseUrl() + "/estimates/takeoffconfig/" + estimateId);
    }

    saveTakeoffConfig(estimateId, configModel) {
        return requests.post(apiHelper.getBaseUrl() + "/estimates/takeoffconfig/" + estimateId, configModel);
    }
}
