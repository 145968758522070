import { autoinject, Container, elementConfig } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AgGridHelper } from "helpers/ag-grid-helper";

import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { ProcoreSynchronizationDirection } from "api/enums/procore-synchronization-direction";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ProcoreEntityToSynchronizeModel } from "api/models/company/procore/procore-entity-to-synchronize-model";
import { DateFormatValueConverter } from "converters/format/date-format";
import { ProcoreConfigurationService } from "services/procore/procore-configuration-service";
import { ProcoreOrderType } from "api/enums/procore-order-type";
import { ProcoreConfiguration } from "../configuration";
import { ProcoreEntityToSynchronizeContentModel } from "models/procore/procore-entities-to-synchronize-content-model";

@autoinject()
export class ConfigurationEntities {
  public entitiesGrid: HTMLElement | null = null;
  public entities: Array<ProcoreEntityToSynchronizeModel | null> | null = [];

  private self: ConfigurationEntities = this;

  private gridOptions: any;

  constructor(private readonly i18n: I18N) { }

  public Load(data: ProcoreCompanyConfigurationModel): void {
    this.entities = data.EntitiesToSynchronize;
  }

  public getDirection(direction: ProcoreSynchronizationDirection): string {
    switch (direction) {
      case ProcoreSynchronizationDirection.ToMaestro:
        return "<i class='fas fa-long-arrow-alt-left'></i>";
      case ProcoreSynchronizationDirection.ToProcore:
        return "<i class='fas fa-long-arrow-alt-right'></i>";
      case ProcoreSynchronizationDirection.Both:
        return "<i class='fas fa-arrows-alt-h'></i>";
      case ProcoreSynchronizationDirection.None:
        return "";
    }
  }

  public getEntityName(entityToSynchronize: ProcoreEntityToSynchronize, selectedOrderType: ProcoreOrderType): string {
    let entityName = this.i18n.tr(`Procore.Procore_Entity_${ProcoreEntityToSynchronize[entityToSynchronize]}`);

    if (entityToSynchronize === ProcoreEntityToSynchronize.PurchaseOrders && selectedOrderType === ProcoreOrderType.StockOrder) {
      entityName = this.i18n.tr("Procore.Procore_Entity_StockOrders");
    }

    return entityName;
  }

  public getProcoreEntityName(entityToSynchronize: ProcoreEntityToSynchronize): string {
    return this.i18n.tr(`Procore.Procore_Entity_Procore${ProcoreEntityToSynchronize[entityToSynchronize]}`);
  }

  public validate(): string | void {
      if (!this.entities) {
        return ;
      }

      const directCostItemsLaborIsChecked = this.entities.some((e: ProcoreEntityToSynchronizeModel) => e.EntityType === ProcoreEntityToSynchronize.DirectCostItemsLabor && e.Enabled);
      if (directCostItemsLaborIsChecked) {
        const totalIsChecked = this.entities.some((e: ProcoreEntityToSynchronizeModel) => e.EntityType === ProcoreEntityToSynchronize.DirectCostItemsLaborTotal && e.Enabled);
        const hoursIsChecked = this.entities.some((e: ProcoreEntityToSynchronizeModel) => e.EntityType === ProcoreEntityToSynchronize.DirectCostItemsLaborBonusHours && e.Enabled);
        if (!totalIsChecked && !hoursIsChecked) {
            return this.i18n.tr("Procore.err_Numberofhours_Or_Totalamount_When_Synchronizing_Projectcosthours");
        }
      }
  }

  private dateTimeFormatter(unformattedDate: string): any {
    const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);
    return dateFormatValueConverter.toView(unformattedDate, "L LTS");
  }
}
