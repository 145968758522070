define([
    "underscore",
    "services/requests",
    "helpers/apiHelper",
    "helpers/dateHelper",
    "repositories/settingRepository",
    "services/defaultService"
], function (_, requests, apiHelper, dateHelper, settings, defaultService) {
    "use strict";
    var settingsExpire = 3600000;

    var exports = {

        getByCompanyAndCode: function(code) {
            return requests.getJson(apiHelper.getBaseUrl() + "/template/" + code);
        },

        listByCompany: function (company) {
            //Do not use apiHelper because the company isn't set when this is called on first load
            return requests.getJson("api/companies/" + company + "/templates");
        },

        /*
         * Config structure
         *  templateCode : string
         *  timestamp : ts
         *  configs: {}
         */
        getTemplateConfigs: function (code) {
            var dfd = new jQuery.Deferred();
            var template = JSON.parse(settings.getCurrentTemplateConfigs());

            if (!template || template.templateCode !== code || dateHelper.calculateTimeSpan(template.timestamp) > settingsExpire) {
                this.getByCompanyAndCode(code).done(function(data) {
                    template = {
                        templateCode: code,
                        timestamp: dateHelper.getNow(),
                        configs: data
                    };
                    settings.setCurrentTemplateConfigs(JSON.stringify(template));
                    dfd.resolve(template.configs);
                });
            } else {
                dfd.resolve(template.configs);
            }
            
            return dfd;
        },

        getUserTemplateConfigs: function() {
            return this.getTemplateConfigs(settings.getDispatchTemplate());
        },

        getCurrentTemplateConfigs: function () {
            var template = JSON.parse(settings.getCurrentTemplateConfigs());

            if (template) {
                return template.configs;
            }
            return {};
        },

        getCurrentDispatchTemplateConfigs : function() {
            return this.getTemplateConfigs(defaultService.getCurrentDispatchTemplateCode());
        },

        getCurrentDispatchIncrement: function () {
            var dfd = new jQuery.Deferred();
            this.getCurrentDispatchTemplateConfigs().done(function(configs) {
                dfd.resolve(configs.TimeIncrement);
            });
            return dfd;
        },

        getUserTemplateIncrement: function () {
            var dfd = new jQuery.Deferred();
            this.getUserTemplateConfigs().done(function (configs) {
                dfd.resolve(configs.TimeIncrement);
            });
            return dfd;
        },

        getCurrentDispatchAllowGroup: function () {
            var dfd = new jQuery.Deferred();
            this.getCurrentDispatchTemplateConfigs().done(function (configs) {
                dfd.resolve(configs.AllowWorkOrderGroupInput);
            });
            return dfd;
        }
    };
    return exports;
});
