import { transient } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as resx } from "core/resx";

function loadWidgetSettings(self, widgetSettings) {
    if (widgetSettings.employee) {
        self.employee = widgetSettings.employee;
    }
    if (widgetSettings.showEmployeeName === false) {
        self.showEmployeeName = false;
    }

    self.linkedEquipments = widgetSettings.linkedEquipments;
}

@transient()
export class TimesheetEmployeeHeader {
    constructor() {
        this.resx = resx;

        this.showEmployeeName = true;
        this.employee = {}
        this.linkedEquipments = [];
    }

    activate(widgetSettings) {
        loadWidgetSettings(this, widgetSettings);
    }

    employeeName() {
        return this.employee.Id + " - " + this.employee.FirstName + " " + this.employee.LastName;
    }
}