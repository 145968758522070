import { PLATFORM } from "aurelia-framework";

export function configure(config) {
    config.globalResources([
        PLATFORM.moduleName("./add-attributes"),
        PLATFORM.moduleName("./css-class"),
        PLATFORM.moduleName("./hammerjs"),
        PLATFORM.moduleName("./input-css-class"),
        PLATFORM.moduleName("./view-mode-css-classes-selector"),
        PLATFORM.moduleName("./ma-mask"),
    ]);
}
