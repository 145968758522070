import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import dateHelper from "helpers/dateHelper";

import { MaCardOptionsModel, MaCardLineModel } from "components/layout/ma-card/ma-card-options.model";
import { ProjectDispatchBreaksScheduleModel } from "api/models/company/project/dispatch/project-dispatch-breaks-schedule-model";
import { ProjectDispatchBreaksModel } from "api/models/company/project/dispatch/project-dispatch-breaks-model";

import { DispatchProjectBreakTimeProxy } from "api/proxies/dispatch-project-break-time-proxy";
import _ from "underscore";

class Team {
    public teamNumber!: number;
    public teamName!: string;
    public teamCards!: MaCardOptionsModel[];
}

@autoinject
export class ManageLunchAndBreaks {
    public dateHelper: any = dateHelper;

    public readonly: boolean = false;
    public dispatchDate!: Date;
    public dispatchProjectCode!: string;

    public isAllSelected: boolean = false;

    public teams: Team[] = [];

    public cards!: MaCardOptionsModel[];
    public dispatchBreaksSchedule!: ProjectDispatchBreaksScheduleModel;

    private isClosingDayMode: boolean = false;

    constructor(private readonly i18n: I18N, private readonly dispatchProjectBreakTimeProxy: DispatchProjectBreakTimeProxy) {
    }

    public async activate(params: any): Promise<void> {

        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");

        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.isClosingDayMode = params.isClosingDayMode;

        this.dispatchBreaksSchedule = (await this.dispatchProjectBreakTimeProxy.GetDispatchesBreaksSchedule(this.dispatchProjectCode, this.dispatchDate, this.isClosingDayMode))!;

        if (this.dispatchBreaksSchedule.DispatchesBreaks) {
            this.cards = this.dispatchBreaksSchedule.DispatchesBreaks.map((employeeBreaks: ProjectDispatchBreaksModel) => this.createCard(employeeBreaks));

            this.teams = this.createTeams(this.dispatchBreaksSchedule.DispatchesBreaks);

            this.updateIsAllSelected();
        }
    }

    public ok(): void {
        if (this.isClosingDayMode) {
            routerHelper.navigate(this.genUrl("break"), { replace: true, trigger: true });
        } else {
            routerHelper.navigateBack();
        }
    }

    public toggle(option: boolean | null, forceState?: boolean): boolean | null {
        if (option == null) {
            return null;
        }
        if (forceState !== undefined) {
            return forceState;
        }
        return !option;
    }

    public toggleLunch(model: ProjectDispatchBreaksModel, forceState?: boolean): void {
        model.IsLunchTaken = this.toggle(model.IsLunchTaken, forceState);

        if (forceState === undefined) {
            this.saveDispatchBreaksLines([model]);
            this.updateIsAllSelected();
        }
    }

    public toggleBreak1(model: ProjectDispatchBreaksModel, forceState?: boolean): void {
        model.IsBreak1Taken = this.toggle(model.IsBreak1Taken, forceState);

        if (forceState === undefined) {
            this.saveDispatchBreaksLines([model]);
            this.updateIsAllSelected();
        }
    }

    public toggleBreak2(model: ProjectDispatchBreaksModel, forceState?: boolean): void {
        model.IsBreak2Taken = this.toggle(model.IsBreak2Taken, forceState);

        if (forceState === undefined) {
           this.saveDispatchBreaksLines([model]);
           this.updateIsAllSelected();
        }
    }

    public toggleBreak3(model: ProjectDispatchBreaksModel, forceState?: boolean): void {
        model.IsBreak3Taken = this.toggle(model.IsBreak3Taken, forceState);

        if (forceState === undefined) {
           this.saveDispatchBreaksLines([model]);
           this.updateIsAllSelected();
        }
    }

    public toggleSelectAll(): void {
        this.updateIsAllSelected();

        this.cards.forEach((card: MaCardOptionsModel) => {
            const model: ProjectDispatchBreaksModel = card.model;
            this.toggleLunch(model, !this.isAllSelected);
            this.toggleBreak1(model, !this.isAllSelected);
            this.toggleBreak2(model, !this.isAllSelected);
            this.toggleBreak3(model, !this.isAllSelected);
        });

        this.isAllSelected = !this.isAllSelected;
        this.saveDispatchBreaksLines(this.cards.map((card: MaCardOptionsModel) => card.model));
    }

    private updateIsAllSelected(): void {
        this.isAllSelected = this.cards.every((card: MaCardOptionsModel) => {
            const lunchState = (card.model.IsLunchTaken || card.model.IsLunchTaken === null || !this.dispatchBreaksSchedule.HasLunch);
            const break1State = (card.model.IsBreak1Taken || card.model.IsBreak1Taken === null || !this.dispatchBreaksSchedule.HasBreak1);
            const break2State = (card.model.IsBreak2Taken || card.model.IsBreak2Taken === null || !this.dispatchBreaksSchedule.HasBreak2);
            const break3State = (card.model.IsBreak3Taken || card.model.IsBreak2Taken === null || !this.dispatchBreaksSchedule.HasBreak3);

            return lunchState && break1State && break2State && break3State;
        });
    }

    private createCard(employeeBreaks: ProjectDispatchBreaksModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            id: employeeBreaks.EmployeeId,
            model: employeeBreaks,
            cardBodyClass: "pt-0",
            displayLines: [
                new MaCardLineModel({class: "bgGray4 mb-2 py-2", display: `${employeeBreaks.EmployeeId} - ${employeeBreaks.FirstName} ${employeeBreaks.LastName} (${dateHelper.getTime(employeeBreaks.StartTime!)} -
                ${dateHelper.getTime(employeeBreaks.EndTime!)})`}),
                new MaCardLineModel({ isCustomTemplate: true })
            ]
        });

        return card;
    }

    private createTeams(dispatchBreaks: Array<ProjectDispatchBreaksModel | null>): Team[] {
        const teamsNumber = _.union(dispatchBreaks.map((x: ProjectDispatchBreaksModel) => x.TeamNumber));

        return teamsNumber.map((teamNumber: number) => {
            const teamCards = this.cards.filter((card: MaCardOptionsModel) => card.model.TeamNumber === teamNumber);

            const teamDisplay = teamNumber === 0 ? `${this.i18n.tr("Employees")} (${teamCards.length})` : `${teamCards[0].model.TeamName} (${teamCards.length})`;

            const team: Team = {
                teamNumber: teamNumber,
                teamName: teamDisplay,
                teamCards: teamCards
            };

            return team;
        });
    }

    private genUrl(param: string): string {
        return routerHelper.navigateTo("Project_Detail_Daily_Entry", this.dispatchProjectCode, this.dispatchDate, param) + routerHelper.addQuerystring({ isProjectResponsible: true, readonly: false, lunchSelected: true, breakSelected: true, manualBreaksManagementEnabled: true });
    }

    private async saveDispatchBreaksLines(models: ProjectDispatchBreaksModel[]): Promise<void> {
       await this.dispatchProjectBreakTimeProxy.SaveDispatchBreaks(this.dispatchProjectCode, this.dispatchDate, models);
    }
}
