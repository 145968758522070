import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { ServiceCallNonBillingReasonModel } from "api/models/company/service-call/service-call-non-billing-reason-model";
import { autoinject, computedFrom } from "aurelia-framework";
import { default as routerHelper } from "helpers/routerHelper";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { PagingInfo } from "api/paging-info";
import { default as notificationHelper } from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";

@autoinject()
export class QuotationItemBase {
    public quotationItem!: ServiceCallQuotationItemModel;
    public originalQuotationItem: ServiceCallQuotationItemModel | null = null;
    public quotation!: ServiceCallQuotationDetailsModel;
    public readonly: boolean = false;
    public minLumpSumPlusSaleAmount: number = -99999999.9999;
    public minSaleAmount: number = 0;

    @computedFrom("quotationItem", "quotationItem.Id")
    public get isNew(): boolean {
        return this.quotationItem && this.quotationItem.Id <= 0;
    }

    constructor(protected readonly i18n: I18N,
                protected readonly serviceCallQuotationService: ServiceCallQuotationService,
                protected readonly serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService) {
    }

    public isDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        if (!this.originalQuotationItem) {
            return false;
        }
   
        const stringifyUnmodifiedQuotationItem = JSON.stringify(this.originalQuotationItem).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrentQuotationItem = JSON.stringify(this.quotationItem).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodifiedQuotationItem !== stringifyCurrentQuotationItem;
    }

    public onClickIsBillable(): void {
        if (!!this.quotationItem) {
            if (!this.quotationItem.IsBillable) {
                this.quotationItem.NonBillableReasonDescription = "";
                this.quotationItem.NonBillableReasonId = null;
            } else {
                this.quotationItem.ProfitMargin = 0;
            }
        }
    }

    public selectedNonBillingChanged(event: CustomEvent<ServiceCallNonBillingReasonModel>): void {
        const serviceCallNonBillingReason = event ? event.detail : null;
        if (!!this.quotationItem) {
            this.quotationItem.NonBillableReasonDescription = serviceCallNonBillingReason ? serviceCallNonBillingReason.CustomerDescription : "";
            this.quotationItem.NonBillableReasonId = serviceCallNonBillingReason ? serviceCallNonBillingReason.Id : null;
        }
    }

    public async delete(): Promise<void> {
        if (await this.confirmDelete()) {
            await this.serviceCallQuotationService.deleteQuotationItem(this.quotationItem.Id);

            routerHelper.navigateBack();
        }
    }

    protected async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, false, false, false, false, false);
    }

    protected async populateNonBillingReasons(filter: string, pagingInfo: PagingInfo): Promise<ServiceCallNonBillingReasonModel[]> {
        return await await this.serviceCallNonBillingReasonService.getNonBillingReasons(this.quotation.Id, filter, pagingInfo);
    }

    private async confirmDelete(): Promise<boolean> {
        return await notificationHelper.showConfirmation(this.i18n.tr("msg_DeleteEntryConfirmationText"));
    }
}
