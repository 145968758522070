import { LocationModel } from "api/models/company/location-model";
import { autoinject, customElement } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { PhoneNumberFormatValueConverter } from "converters/format/phone-number-format";

@autoinject()
@customElement("location-view")
export class LocationView {
    @bindable.none public location: LocationModel | null = null;

    public textLine1: string = "";
    public textLine2: string = "";
    public textLine3: string = "";
    public textLine4: string = "";
    public textLine5: string = "";
    public textLine6: string = "";
    
    constructor(private readonly element: Element, private readonly phoneNumberFormatValueConverter: PhoneNumberFormatValueConverter) { }

    public bind(): void {
        if (!this.location) { return; }

        this.initText(this.location);
    }

    private locationChanged(newValue: LocationModel | null): void {
        if (!newValue) {
            this.clearText();
        } else {
            this.initText(newValue);
        }
    }

    private clearText(): void {
        this.textLine1 = "";
        this.textLine2 = "";
        this.textLine3 = "";
        this.textLine4 = "";
        this.textLine5 = "";
        this.textLine6 = "";
    }

    private initText(location: LocationModel): void {
        this.clearText();

        this.textLine1 = this.addPart(this.textLine1, location.Name);

        this.textLine2 = this.addPart(this.textLine2, location.Division);

        this.textLine3 = this.addPart(this.textLine3, location.Address);

        this.textLine4 = this.addPart(this.textLine4, location.City);
        this.textLine4 = this.addPart(this.textLine4, location.Province);
        this.textLine4 = this.addPart(this.textLine4, location.Country);
        this.textLine4 = this.addPart(this.textLine4, location.PostalCode);
        this.textLine4 = this.addPart(this.textLine4, location.ZipCode);

        this.textLine5 = this.addPart(this.textLine5, this.phoneNumberFormatValueConverter.toView(location.Telephone1));
        this.textLine5 = this.addPart(this.textLine5, this.phoneNumberFormatValueConverter.toView(location.Telephone2));
        if (location.Fax && location.Fax.trim()) {
            this.textLine5 = this.addPart(this.textLine5, this.phoneNumberFormatValueConverter.toView(location.Fax) + " (fax)");
        }
    }

    private addPart(text: string, part: string | null): string {
        if (part === null || part === undefined || part.length === 0) {
            return text;
        }

        text = text.trim();
        part = part.trim();

        if (text.length > 0) {
            if (part.startsWith(",")) {
                text += " ";
            } else {
                text += ", ";
            }
        }

        return text + part;
    }
}
