import { autoinject, OverrideContext, containerless } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { SettingRepository } from "repositories/setting-repository";

@autoinject()
export class PageHeader {
    @bindable.string
    public title: string = "";

    @bindable.booleanAttr
    public diplayBorderBottomLine: boolean = false;

    public companyName: string | null = "";

    constructor(private readonly settingRepository: SettingRepository) { }

    public bind(bindingContext: any, overrideContext: OverrideContext): void {
        this.companyName = this.settingRepository.getCompanyName();
    }
}
