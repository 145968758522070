import { inject, singleton, Factory } from "aurelia-framework";
import Config from "config";
import { UrlHelper } from "helpers/url-helper";
import { HttpClient } from "http-client/http-client";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { MaestroApiHttpClient } from "http-client/maestro-api-http-client";
import { SettingRepository } from "repositories/setting-repository";
import { BambooHRApiHttpClient } from "http-client/bamboo-hr-api-http-client";

@singleton()
@inject(
    UrlHelper,
    SettingRepository,
    Factory.of(MaestroApiHttpClient),
    Factory.of(BambooHRApiHttpClient))
export class HttpClientFactory {
    private readonly urlHelper: UrlHelper;
    private readonly settingRepository: SettingRepository;

    private readonly maestroApiHttpClientFactory: (defaultRequestConfig: HttpClientRequestConfig) => MaestroApiHttpClient;
    private readonly bambooHRHttpClientFactory: (defaultRequestConfig: HttpClientRequestConfig) => BambooHRApiHttpClient;

    constructor(
        urlHelper: UrlHelper,
        settingRepository: SettingRepository,
        maestroApiHttpClientFactory: (defaultRequestConfig: HttpClientRequestConfig) => MaestroApiHttpClient,
        bambooHRHttpClientFactory: (defaultRequestConfig: HttpClientRequestConfig) => BambooHRApiHttpClient
        ) {
            this.urlHelper = urlHelper;
            this.settingRepository = settingRepository;
            this.maestroApiHttpClientFactory = maestroApiHttpClientFactory;
            this.bambooHRHttpClientFactory = bambooHRHttpClientFactory;
    }

    public getMaestroApiHttpClient(baseUri: string): HttpClient {
        const defaultConfig: HttpClientRequestConfig = {
            baseUrl: baseUri,
            timeout: Config.defaultRequestTimeout,
            responseType: "json",
            authorizationToken: this.settingRepository.getToken(),
            cache: false,

            maestroVersion: this.settingRepository.getVersion(),
            language: this.settingRepository.getLanguage(),
        };

        return this.maestroApiHttpClientFactory(defaultConfig);
    }

    public getBambooHRHttpClient(baseUri: string): HttpClient {
        const defaultConfig: HttpClientRequestConfig = {
            baseUrl: baseUri,
            timeout: Config.defaultRequestTimeout,
            responseType: "json",
            cache: false
        };

        return this.bambooHRHttpClientFactory(defaultConfig);
    }

}
