import { AxiosRequestConfig } from "axios";
import { HttpClient } from "http-client/http-client";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { inject } from "aurelia-framework";
import { Nonce } from "http-client/nonce";

@inject(Nonce)
export class BambooHRApiHttpClient extends HttpClient {
    constructor(nonce: Nonce, defaultRequestConfig: HttpClientRequestConfig) {
        super(nonce, defaultRequestConfig);
    }

    protected createAxiosRequestConfig(source: HttpClientRequestConfig): AxiosRequestConfig {
        const result = super.createAxiosRequestConfig(source);

        return result;
    }

}
