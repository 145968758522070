import val from "core/val";
import urlHelper from "helpers/urlHelper";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { NotificationHelper } from "helpers/notification-helper";

import { InspectionService } from "services/inspection-service";

import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { InspectionStatus } from "api/enums/inspection-status";
import { ServiceDispatchType } from "enums/service-dispatch-type";

@autoinject()
export class InspectionInfo {
    public val: any = val;
    public dateHelper: any = dateHelper;
    public contextDisplay: string = "";

    public inspection!: InspectionModel;

    public isActive: boolean = true;

    public btnLinks: any[] | undefined;
    public dispatchId: any;
    public isServiceCallInspection: boolean = false;

    constructor(private readonly i18n: I18N,
                private routeRepository: RouteRepository,
                private readonly inspectionService: InspectionService,
                private readonly notificationHelper: NotificationHelper) {}

    public async activate(params: any): Promise<void> {
         this.inspection = (await this.inspectionService.GetInspection(params.inspectionId))!;

         this.contextDisplay = this.inspection.InspectionContext === 1 ? this.i18n.tr("Projet") : this.i18n.tr("Equipment");
         this.isServiceCallInspection = this.inspection.VisitNumber > 0;

         if (this.isServiceCallInspection) {
            this.contextDisplay = this.i18n.tr("ServiceCall");
            this.dispatchId = params.dispatchId;
         }

         const isInspectionClosed = this.inspection.Status === InspectionStatus.Closed || this.inspection.Status === InspectionStatus.Complete;
         this.isActive = !isInspectionClosed;

         this.initButtonLinks();
    }

    public initButtonLinks(): void {
        this.btnLinks = [
            {
                id: "documents",
                display: true,
                displayChevron: true,
                count: 0,
                linkMain: routerHelper.generate(this.routeRepository.routes.Inspection_Info_Documents.name, {inspectionId : this.inspection.Id}),
                text: "Pictures/Documents"
            }, {
                id: "measures",
                display: this.isActive,
                displayChevron: true,
                displayCount: true,
                count: this.inspection.Items != null ? this.inspection.Items.length : 0,
                linkMain: this.isServiceCallInspection ? routerHelper.generate(this.routeRepository.routes.Service_Call_Inspections_Details.name, { inspectionId: this.inspection.Id, dispatchId: this.dispatchId, serviceType: ServiceDispatchType.Service }) : routerHelper.generate(this.routeRepository.routes.Inspection_Detail.name, { inspectionId: this.inspection.Id }),
                text: "Measures"
            },
            {
                id: "summary",
                display: true,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkMain: this.isServiceCallInspection ? routerHelper.generate(this.routeRepository.routes.Service_Call_Inspections_Details.name, { inspectionId: this.inspection.Id, isSummary: true, dispatchId: this.dispatchId, serviceType: ServiceDispatchType.Service }) : routerHelper.generate(this.routeRepository.routes.Inspection_Detail.name, { inspectionId: this.inspection.Id, isSummary: true }),
                text: "Summary"
            }];
    }

    public async complete(): Promise<void> {
        if (!this.isActive) {
            return;
        }

        if (!this.inspection.Result) {
            this.notificationHelper.showWarning(this.i18n.tr("msg_ConnotComplete_Inspection_NotFilled"), "");
            return;
        }

        if (this.inspection.IsHavingSignature) {
            if (this.isServiceCallInspection) {
                routerHelper.navigateToRoute(this.routeRepository.routes.Service_Call_Inspections_Details.name, { inspectionId: this.inspection.Id,  isReadOnly: true, dispatchId: this.dispatchId, serviceType: ServiceDispatchType.Service});
            } else {
                routerHelper.navigateToRoute(this.routeRepository.routes.Inspection_Detail.name, { inspectionId: this.inspection.Id,  isReadOnly: true});
            }
            return;
        }

        this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_CompleteConfirmation_Inspection")).then(async (value: boolean) => {
            if (value) {
                if (this.isServiceCallInspection) {
                    this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_IncludeInMessage_Inspection")).then(async (include: boolean) => {
                        await this.inspectionService.Complete(this.inspection.Id, include ? this.inspection.VisitNumber : null);
                        this.replaceBrowsingHistoryAndNavigate();
                    });
                } else {
                    await this.inspectionService.Complete(this.inspection.Id, null);
                    this.replaceBrowsingHistoryAndNavigate();
                }
        }});
    }

    private replaceBrowsingHistoryAndNavigate(): void {
        this.replaceBrowsingHistory();
        if (this.isServiceCallInspection) {
            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Call_Inspections_Info.name, { inspectionId: this.inspection.Id, replace: true, trigger: true, dispatchId: this.dispatchId, serviceType: ServiceDispatchType.Service});
        } else {
            routerHelper.navigateToRoute(this.routeRepository.routes.Inspection_Info.name, { inspectionId: this.inspection.Id, replace: true, trigger: true});
        }
    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        let route =  this.routeRepository.routes.Inspection.route.toString();
        if (this.isServiceCallInspection) {
            route =  this.routeRepository.routes.Service_Call_Inspections.route.toString().replace(":dispatchId", this.dispatchId).replace(":serviceType", ServiceDispatchType.Service);
        }
        window.history.replaceState("", "", baseUrl + route);
    }
}
