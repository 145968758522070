import { ServiceCallEquipmentMaintenanceMaterialModel } from "api/models/company/service/service-call-equipment-maintenance-material-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallDispatchEquipmentMaintenanceMaterialProxy } from "api/proxies/service-call-dispatch-equipment-maintenance-material-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class ServiceCallDispatchEquipmentMaintenanceMaterialService {
    constructor(private readonly serviceCallDispatchEquipmentMaintenanceMaterialProxy: ServiceCallDispatchEquipmentMaintenanceMaterialProxy) {
    }

    public async get(dispatchId: number, equipmentId: string | null, maintenanceId: string, equipmentid: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<ServiceCallEquipmentMaintenanceMaterialModel[]> {
        return (await this.serviceCallDispatchEquipmentMaintenanceMaterialProxy.GetRequiredMaterialsForMaintenance(dispatchId, equipmentId, maintenanceId, equipmentid, filter, pagingInfo, requestConfig)) || [];
    }

    public async getAllRequiredMaterialsForMaintenance(dispatchId: number): Promise<ServiceCallEquipmentMaintenanceMaterialModel[]> {
        return (await this.serviceCallDispatchEquipmentMaintenanceMaterialProxy.GetAllRequiredMaterialsForMaintenance(dispatchId))!;
    }

    public async getRequiredMaterialForMaintenance(dispatchId: number, equipmentid: number, requiredMaterialId: string | null): Promise<ServiceCallEquipmentMaintenanceMaterialModel | null> {
        return (await this.serviceCallDispatchEquipmentMaintenanceMaterialProxy.GetRequiredMaterialForMaintenance(dispatchId, equipmentid, requiredMaterialId));
    }

    public async replaceRequiredMaterialForMaintenance(dispatchId: number, newRequiredMaterial: ServiceCallEquipmentMaintenanceMaterialModel | null, equipmentid: number, requiredMaterialIdToReplace: string | null, onlycurrentVisit: boolean | undefined): Promise<void> {
        await this.serviceCallDispatchEquipmentMaintenanceMaterialProxy.ReplaceRequiredMaterialForMaintenance(dispatchId, newRequiredMaterial, equipmentid, requiredMaterialIdToReplace, onlycurrentVisit);
    }
}
