import { DispatchProjectService } from "services/dispatch-project-service";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { TimeListHelper } from "helpers/time-list-helper";
import moment from "moment";
import notificationHelper from "helpers/notificationHelper";
import projectService from "services/projectService";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import settingHelper from "helpers/settingHelper";
import dailyEntryService from "services/dailyEntryService";
import templateService from "services/templateService";

@autoinject
export class DailyEntryAdd {

    public isProjectResponsible: boolean = false;
    public dispatchProjectCode: string = "";
    public templateCode: string = "";
    public startTime: any;
    public endTime: any;
    public timeOptions: any;

    constructor(private readonly i18n: I18N, private readonly dispatchProjectService: DispatchProjectService) {
    }

    public async canActivate(params: any): Promise<any> {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }

        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }

        return true;
    }

    public async activate(params: any): Promise<any> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);

        this.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
        this.dispatchProjectCode = params.dispatchProjectCode;

        this.timeOptions = TimeListHelper.loadTimeList(await templateService.getUserTemplateIncrement());

        if (!this.isProjectResponsible) {
            routerHelper.navigateBack();
            return false;
        }

        this.templateCode = settingHelper.getSelectedDispatchModel();
        this.loadData(params.dispatchProjectCode, this.templateCode);
    }

    public loadData(dispatchProjectCode: string, templateCode: string): void {
        projectService.getProjectWorkSchedule(dispatchProjectCode, templateCode).then((data: any) => {
            this.startTime = dateHelper.addTimeSpan(new Date(), data.StartHour);
            this.endTime = dateHelper.addTimeSpan(new Date(), data.EndHour);
        });
    }

    public navigateToNext(): void {
        routerHelper.navigate(routerHelper.navigateTo("Project_Detail_Daily_Entry_Existing", this.dispatchProjectCode) + routerHelper.addQuerystring({
                isProjectResponsible: this.isProjectResponsible,
                dailyEntryDate: dateHelper.dateFromUTC(this.startTime, dateHelper.NavigationParameterFormat)
        }));
    }

    public async copyNewDailyEntry(): Promise<void> {
        if (this.isStartTimeValid()) {
            try {
                await dailyEntryService.checkExistingDailyEntry(this.dispatchProjectCode, dateHelper.dateFromUTC(this.startTime, dateHelper.NavigationParameterFormat));
                this.navigateToNext();
            } catch (error) {
                return;
            }
        }
    }

    public save(): void {
        const data = this.mapDataToPost();
        const formatedStartTime = dateHelper.dateFromUTC(this.startTime, dateHelper.NavigationParameterFormat);

        if (this.isStartTimeValid()) {
            dailyEntryService.checkExistingDailyEntry(this.dispatchProjectCode, formatedStartTime)
                .then(() => {
                    dailyEntryService.addEntry(this.dispatchProjectCode, data).then(() => {
                        routerHelper.navigate(routerHelper.navigateTo("Project_Detail_Daily_Entry", this.dispatchProjectCode, formatedStartTime)  + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible }),
                            {
                                replace: true,
                                trigger: true
                            });
                    });
                });
        }
    }

    public mapDataToPost(): any {
        const startDateTime = new Date(this.startTime);
        const tempEndDateTime = new Date(this.startTime);
        const endDateTime = new Date(this.endTime);

        tempEndDateTime.setHours(endDateTime.getHours());
        tempEndDateTime.setMinutes(endDateTime.getMinutes());
        tempEndDateTime.setSeconds(endDateTime.getSeconds());

        if (tempEndDateTime <= startDateTime) {
            tempEndDateTime.setDate(tempEndDateTime.getDate() + 1);
        }

        return {
            templateCode: this.templateCode,
            endTime: dateHelper.formatDateToSend(tempEndDateTime),
            projectId: this.dispatchProjectCode,
            startTime: dateHelper.formatDateToSend(startDateTime)
        };
    }

    public isStartTimeValid(): boolean {
        const startTime = moment(this.startTime);
        const today = moment().startOf("day");
        const durationPast = moment.duration(today.diff(startTime));
        const diffDaysPast = durationPast.asDays();
        const durationFuture = moment.duration(startTime.diff(today));
        const diffDaysFuture = durationFuture.asDays();
        let pastLengthAllow = 7;
        let futureLengthAllow = 10; //8 days future + 2 for week-end

         //on saturday we use 5 in past...
        if (today.day() === 6) {
            pastLengthAllow = 5;
        }

        //on sunday we use 6 in past...
        if (today.day() === 0) {
            pastLengthAllow = 6;
        }

        //on thursday and Friday we use 12 in future...
        if (today.day() === 4 || today.day() === 5) {
            futureLengthAllow = 12;
        }

        if (diffDaysPast > pastLengthAllow || diffDaysFuture > futureLengthAllow) {
            notificationHelper.showError(this.i18n.tr("err_EntryTodayORWithin5Days"));
            return false;
        }

        return true;
    }

    public async checkIsProjectActive(): Promise<any> {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }

}
