import { SiteProxy } from "api/proxies/site-proxy";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";

import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { InventorySiteTranferProxy } from "api/proxies/inventory-site-tranfer-proxy";

import { InventorySiteTransferModel } from "api/models/company/inventory-site-transfer-model";
import { InventorySiteTransferItemModel } from "api/models/company/inventory-site-transfer-item-model";
import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";
import { InventorySiteTransferItemResponseModel } from "api/models/company/inventory-site-transfer-item-response-model";
import { InventorySiteTransferUpdateFromCatalogInventoryModel } from "api/models/company/inventory-site-transfer-update-from-catalog-inventory-model";
import { SiteModel } from "api/models/company/site-model";
import { NewMaterialReservationUpdateFromCatalogInventoryModel } from "api/models/company/new-material-reservation-update-from-catalog-inventory-model";

@autoinject()
export class InventorySiteTransferService {
    constructor(private readonly inventorySiteTransferProxy: InventorySiteTranferProxy, private readonly siteProxy: SiteProxy) {}

    public async GetTransfers(onlyClose: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InventorySiteTransferModel[] | null> {
        return this.inventorySiteTransferProxy.GetTransfers(onlyClose, filter, pagingInfo, requestConfig);
    }

    public async GetTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<InventorySiteTransferDetailsModel | null> {
        return this.inventorySiteTransferProxy.GetTransfer(transferId, requestConfig);
    }

    public async CompleteTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return this.inventorySiteTransferProxy.Complete(transferId, requestConfig);
    }

    public async DeleteTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<any> {
        return this.inventorySiteTransferProxy.DeleteTransfer(transferId, requestConfig);
    }

    public async NewTransfer(requestConfig?: HttpClientRequestConfig): Promise<InventorySiteTransferDetailsModel | null> {
        return this.inventorySiteTransferProxy.NewTransfer(requestConfig);
    }

    public async SaveTransfer(inventorySiteTransferDetails: InventorySiteTransferDetailsModel | null, requestConfig?: HttpClientRequestConfig): Promise<number | null> {
        return this.inventorySiteTransferProxy.SaveTransfer(inventorySiteTransferDetails, requestConfig);
    }

    public async SaveAndCompleteNewReservation(inventorySiteTransferDetails: InventorySiteTransferDetailsModel | null, requestConfig?: HttpClientRequestConfig): Promise<number | null> {
        return this.inventorySiteTransferProxy.SaveAndCompleteNewReservation(inventorySiteTransferDetails, requestConfig);
    }

    public async SaveTransferItems(items: InventorySiteTransferItemModel[], requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.inventorySiteTransferProxy.SaveTransferItems(items, requestConfig);
    }

    public async DeleteTransferItem(sourceTransactionNumber: number, targetTransactionNumber: number): Promise<void> {
        await this.inventorySiteTransferProxy.DeleteTransferItem(sourceTransactionNumber, targetTransactionNumber);
    }

    public async UpdateTransferItems(item: InventorySiteTransferDetailsModel): Promise<InventorySiteTransferDetailsModel | null> {
        return await this.inventorySiteTransferProxy.UpdateTransferItems(item);
    }

    public async NewTransferItem(
        transferId: number,
        productCode: string,
        sourceSiteId: string,
        targetSiteId: string,
        requestConfig?: HttpClientRequestConfig
    ): Promise<InventorySiteTransferItemResponseModel | null> {
        return this.inventorySiteTransferProxy.NewTransferItem(transferId, productCode, sourceSiteId, targetSiteId, requestConfig);
    }

    public async NewReservationItem(
        dispatchId: number,
        inventorySiteTransferDetails: InventorySiteTransferDetailsModel | null,
        productCode: string,
        requestConfig?: HttpClientRequestConfig
    ): Promise<InventorySiteTransferItemResponseModel | null> {
        return this.inventorySiteTransferProxy.NewReservationItem(dispatchId, productCode, inventorySiteTransferDetails, requestConfig);
    }

    public async GetTransferItem(sourceTransactionNumber: number, targetTransactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<InventorySiteTransferItemResponseModel | null> {
        return this.inventorySiteTransferProxy.GetTransferItem(sourceTransactionNumber, targetTransactionNumber);
    }

    public async IsServiceCallInstalled(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.inventorySiteTransferProxy.IsServiceCallInstalled(requestConfig);
    }

    public async UpdateFromCatalogInventoryItem(
        updateModel: InventorySiteTransferUpdateFromCatalogInventoryModel | null,
        requestConfig?: HttpClientRequestConfig
    ): Promise<InventorySiteTransferItemModel | null> {
        return this.inventorySiteTransferProxy.UpdateFromCatalogInventoryItem(updateModel, requestConfig);
    }

    public async UpdateReservationFromCatalogInventoryItem(
        updateModel: NewMaterialReservationUpdateFromCatalogInventoryModel | null,
        requestConfig?: HttpClientRequestConfig
    ): Promise<InventorySiteTransferItemModel | null> {
        return this.inventorySiteTransferProxy.UpdateReservationFromCatalogInventoryItem(updateModel, requestConfig);
    }

    public async GetTransferHeader(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<InventorySiteTransferDetailsModel | null> {
        return this.inventorySiteTransferProxy.GetTransferHeader(transferId, requestConfig);
    }

    public async AddReservations(transferId: number, sourceSiteId: string, targetSiteId: string, reservations: any): Promise<void> {
        await this.inventorySiteTransferProxy.AddReservations(transferId, sourceSiteId, targetSiteId, reservations);
    }

    public async GetSites(prefix: string, filter?: string, requestConfig?: HttpClientRequestConfig): Promise<any[] | null> {
        const sites = await this.siteProxy.GetSites(prefix ? prefix : null);

        if (!sites) {
            return [];
        }

        return sites.map((item: any): any => {
            return {
                id: item.Code,
                text: `${item.Code} - ${item.Description}`,
                data: item,
            };
        });
    }
}
