import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import materialRequisitionService from "services/materialRequisitionService";

@autoinject()
export class FieldServiceMaterialRequisition {
    public workOrderId: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public actions: any;
    public projectCode: string = "";
    public dispatchProjectCode: string = "";

    public activate(params: any): any {
        this.workOrderId = decodeURIComponent(params.workOrderId);

        this.actions = {
            getRequisitions: materialRequisitionService.getMaterialRequisitionsForFieldService.bind(this, this.workOrderId)
        };

        const paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        if (paramsQs && paramsQs.projectCode) {
            this.projectCode = paramsQs.projectCode;
        }

        if (paramsQs && paramsQs.dispatchProjectCode) {
            this.dispatchProjectCode = paramsQs.dispatchProjectCode;
        }
    }
}
