import val from "core/val";

export class ValidationAttributeValueConverter {

    public toView(value: string, key: string): any {
        if (!value || !key) {
            return "";
        }

        const attribute = val.get(key, value);

        if (!attribute) {
            return "";
        }

        return attribute;
    }
}
