import { default as recommendationService } from "services/recommendationService";

import { DispatchProjectService } from "services/dispatch-project-service";
import { I18N } from "aurelia-i18n";
import { inject } from "aurelia-framework";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@inject(I18N, DispatchProjectService, NotificationHelper, Router)
export default class RecommendationEdit extends FormBase {
    dispatchProjectCode;
    dispatchDate;
    recommendationId = "";
    readonly = false;
    parentModel =  null;
    isDirty = false;

    saveRecommendation;

    constructor(i18n, dispatchProjectService, notificationHelper, router) {
        super(notificationHelper, i18n, router);
        this.dispatchProjectService = dispatchProjectService;
    }

    checkDirty() {
        return this.isDirty;
    }

    async canActivate(params) {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                this.notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                this.notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    activate = function (params) {
        this.bindViewModel(params.dispatchProjectCode, params.recommendationId);
        this.parentModel = this;
    };

    bindViewModel(dispatchProjectCode, recommendationId) {
        this.recommendationId = recommendationId;
        this.dispatchProjectCode = dispatchProjectCode;

        this.saveRecommendation = recommendationService.addRecommendationForProject.bind(null, dispatchProjectCode);
    }

    async checkIsProjectActive() {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
