import { bindable, autoinject} from "aurelia-framework";
import { default as dateHelper } from "helpers/dateHelper";
import { PhysicalInventoryService } from "services/physical-inventory-service";
import RouteRepository from "repositories/routeRepository";

@autoinject()
export class PhysicalInventoryHeader {
    public title: string = "";
    public subtitle: string = "";
 
    @bindable
    public id!: number;
   
    constructor(private readonly physicalInventoryService: PhysicalInventoryService, private readonly routeRepository: RouteRepository) {
    }

    public async bind(): Promise<void> {
        await this.initTitleAndSubtitle();
    }

    private async initTitleAndSubtitle(): Promise<void> {
        const phyInv = await this.physicalInventoryService.Get(this.id);
        if (phyInv != null && phyInv != null) {
            this.setDisplayValues(phyInv.AccountingDate, phyInv.Id);
        }
    }

    private setDisplayValues(accountingDate: Date, id: number): void {
        this.title = accountingDate ? dateHelper.getFullTextDate(accountingDate) : "";
        this.subtitle = id.toString();
    }
}
