import { I18N } from "aurelia-i18n";
import { computedFrom, inject, transient, DOM } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

import dateHelper from "helpers/dateHelper";
import Parse from "helpers/parse";
import { PhoneNumberFormatValueConverter } from "converters/format/phone-number-format";

interface SubcontractorContactItem {
    SubcontractorDesc: string;
    SubcontractorId: string;
    Present: number;
    StartTime: string;
    EndTime: string;
    Quantity: number;
    TypeInt: number;
    Line: number;
    Email: string;
    PhoneNumber: string;
    Mobile: string;
}

@transient()
@inject(Element, I18N, PhoneNumberFormatValueConverter)
export class SubcontractorContact {
    @bindable public item!: SubcontractorContactItem;
    @bindable public isContact: boolean = false;
    @bindable.booleanAttr public disabled: boolean = false;

    public subcontractorDisplay: string = "";

    constructor(private readonly element: Element, private readonly i18n: I18N, private readonly phoneNumberFormatValueConverter: PhoneNumberFormatValueConverter) {
    }

    @computedFrom("item")
    public get isFirstSplitterDisplayed(): boolean {
        return this.item.PhoneNumber !== "" && (this.showAttendanceDetails && this.item.Email !== "");
    }

    @computedFrom("item")
    public get isSecondSplitterDisplayed(): boolean {
        return this.item.Mobile !== "" && (this.showAttendanceDetails && (this.item.Email !== "" || this.item.PhoneNumber !== ""));
    }

    @computedFrom("item")
    public get isSubcontractor(): boolean {
        return this.item.TypeInt === 0;
    }

    @computedFrom("item")
    public get showAttendanceDetails(): boolean {
        return !this.isSubcontractor || this.item.Quantity > 0;
    }

    @computedFrom("item")
    public get attendanceLabel(): string {
        const presence = Parse.Integer(this.item.Present);
        switch (presence) {
            case 1:
                return this.i18n.tr("Absent");
            case 2:
                return this.i18n.tr("Onsite");
            default:
                return this.i18n.tr("Undefined");
        }
    }

    @computedFrom("item")
    public get startTime(): string {
        return dateHelper.getTime(this.item.StartTime);
    }

    @computedFrom("item")
    public get endTime(): string {
        return dateHelper.getTime(this.item.EndTime);
    }

    @computedFrom("item")
    public get phone(): string {
        return this.phoneNumberFormatValueConverter.toView(this.item.PhoneNumber);
    }

    @computedFrom("item")
    public get mobile(): string {
        return this.phoneNumberFormatValueConverter.toView(this.item.Mobile);
    }

    public bind(): any {
        if (this.item) {
            this.subcontractorDisplay = `${this.item.SubcontractorDesc} (${this.isContact ? this.i18n.tr("Contact") : this.i18n.tr("Subcontractor")}: ${this.item.SubcontractorId})`;
        }
    }

    public delete(item: SubcontractorContactItem): void {
        const event: CustomEvent = DOM.createCustomEvent("delete", {
            detail: item,
            bubbles: false,
            cancelable: false,
        } as CustomEventInit<SubcontractorContactItem>);
        this.element.dispatchEvent(event);
    }
}
