import { default as routerHelper } from "helpers/routerHelper";
import { default as purchaseOrderService } from "services/purchaseOrderService"
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { observable, autoinject } from "aurelia-framework";

@autoinject()
export default class MaterialOrder {

    @observable workOrderId = "";

    constructor() {
        this.isProjectResponsible = false;
        this.readonly = false;
    }

    activate(params) {
        this.serviceType = params.serviceType;

        this.workOrderId = decodeURIComponent(params.workOrderId);

        this.actions = {
            getOrders: purchaseOrderService.getWorkOrderPurchaseOrders.bind(this, this.workOrderId)
        };

        var paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        if (paramsQs && paramsQs.projectCode) {
            this.projectCode = paramsQs.projectCode;
        }

        if (paramsQs && paramsQs.dispatchProjectCode) {
            this.dispatchProjectCode = paramsQs.dispatchProjectCode;
        }
    }
}
