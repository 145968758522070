import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import additionalFieldsService from "services/additionalFieldsService";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class ReceiptOfGoodsAdditionalFields extends FormBase {

    public actions: any = {};
    public receiptId: number = 0;
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.ReceiptOfGoods;
    public saveAdditionalFieldsButton: boolean = true;
    public currentFields: any = null;
    public unModifiedFields: any = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly additionalFieldMapper: AdditionalFieldMapper) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.additionalFieldMapper.isNotEqual(this.unModifiedFields, this.currentFields);
    }

    public activate(params: any): any {
        this.bindViewModel(params.receiptId, params.q);
        this.bindWidget();
    }

    public bindViewModel(receiptId: number, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.receiptId = receiptId;
    }

    public bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForReceiptOfGoods.bind(this, this.receiptId),
            setAll: additionalFieldsService.setAllForReceiptOfGoods.bind(this, this.receiptId)
        };
    }
}
