import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { bindable, autoinject} from "aurelia-framework";
import dateHelper from "helpers/dateHelper";

@autoinject()
export class InventorySiteTransferHeader {
    public title: string = "";
    public subtitle: string = "";

    @bindable public transferId!: number;

    constructor(private readonly inventorySiteTransferService: InventorySiteTransferService) {
    }

    public async bind(): Promise<void> {
        const transfer = await this.inventorySiteTransferService.GetTransferHeader(this.transferId!);
        this.setDisplayValues(transfer!.AccountingDate, transfer!.Id);
    }

    private setDisplayValues(accountingDate: Date, id: number): void {
        this.title = accountingDate ? dateHelper.getFullTextDate(accountingDate) : "";
        this.subtitle = id.toString();
    }
}
