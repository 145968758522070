import { autoinject, bindable } from "aurelia-framework";
import { ProcoreOrderType } from "api/enums/procore-order-type";
import { I18N } from "aurelia-i18n";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";

@autoinject()
export class ConfigurationOrdersSynchronization {
  public readonly ProcoreOrderType: typeof ProcoreOrderType = ProcoreOrderType;

  @bindable
  public SelectedOrderType: ProcoreOrderType = ProcoreOrderType.PurchaseOrder;

  public orderTypes: any[] = [
    { id: ProcoreOrderType.StockOrder,    text: this.i18n.tr("Procore.Procore_Entity_StockOrders") },
    { id: ProcoreOrderType.PurchaseOrder, text: this.i18n.tr("Procore.Procore_Entity_PurchaseOrders") }
  ];

  constructor(private readonly i18n: I18N) { }

  public Load(data: ProcoreCompanyConfigurationModel): void {
    this.SelectedOrderType = data.OrderSynchronizationOrderType as ProcoreOrderType;
  }

  public changeOrderMode(orderType: ProcoreOrderType): void {
    this.SelectedOrderType = orderType;
}
}
