// GENERATED FILE - DO NOT MODIFY
import { ServiceCallQuotationSaveModel } from "api/models/company/service-call/service-call-quotation-save-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallQuotationPriceProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetPrices(quotation: ServiceCallQuotationSaveModel | null, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationSaveModel | null> {
        const uri = this.buildUri(
            "/service-call-quotations-prices",
            null,
            null
        );

        const data: ServiceCallQuotationSaveModel | null = await this.post<ServiceCallQuotationSaveModel | null, ServiceCallQuotationSaveModel | null>(uri, quotation, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationSaveModel");
        return data;
    }
}
