import { PhysicalInventoryModel } from "api/models/company/physical-inventory-model";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { CatalogItemManagementMode } from "api/enums/catalog-item-management-mode";
import { PhysicalInventoryStatus } from "api/enums/physical-inventory-status";
import { PhysicalInventoryMobileStatus } from "api/enums/physical-inventory-mobile-status";
import { PhysicalInventoryItemModel } from "api/models/company/physical-inventory-item-model";
import { PhysicalInventoryService } from "services/physical-inventory-service";
import notificationHelper from "helpers/notificationHelper";
import RouteRepository from "repositories/routeRepository";
import routerHelper from "helpers/routerHelper";
import { MaCardActionModel, MaCardLineModel, MaCardOptionsModel } from "components/layout/ma-card/ma-card-options.model";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
import { CatalogItemTypeService } from "services/catalog-item-type-service";
import { CatalogItemCategoryService } from "services/catalog-item-category-service";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import userSettingService from "services/userSettingsService";
import { CatalogInventoryLocationService } from "services/catalog-inventory-location-service";

@autoinject
export class PhysicalInventoryEdit {
    public Id: number = 0;
    public readonly CatalogItemManagementMode: typeof CatalogItemManagementMode = CatalogItemManagementMode;
    public readonly PhysicalInventoryStatus: typeof PhysicalInventoryStatus = PhysicalInventoryStatus;
  
    public physInv: PhysicalInventoryModel | null = null;

    public itemCards: MaCardOptionsModel[] = [];
    public isDrawerOpen: boolean = true;
    public includeReservationNoItems: boolean = false;
    public siteMandatory: boolean = false;

    private isReadonly: boolean = false;
    private isLoading: boolean = true;

    constructor(private readonly routeRepository: RouteRepository,
                private readonly service: PhysicalInventoryService,
                private readonly inventoryProjectTransferService: InventoryProjectTransferService,
                private readonly catalogItemTypeService: CatalogItemTypeService,
                private readonly catalogItemCategoryService: CatalogItemCategoryService,
                private readonly catalogInventoryLocationService: CatalogInventoryLocationService,
                private readonly i18n: I18N) {}

    public async activate(params: any): Promise<void> {
        this.Id = params.id;
       
        if (this.Id > 0) {
            this.physInv = await this.service.Get(this.Id);
            this.isDrawerOpen = false;
        } else {
            this.physInv = await this.service.CreatePhysicalInventory();
        }

        this.isReadonly = this.physInv!.MobileStatus !== PhysicalInventoryMobileStatus.Active;

        if (this.physInv && this.physInv.Items!.length > 0) {
            this.refreshItemCards();
        }

        this.siteMandatory = await this.service.IsSiteMandatory();
        const option = await userSettingService.getPhInvIncludeReservationNoForCurrentUserSetting();
        if (option !== null) {
            this.includeReservationNoItems = option.Value === "true";
        }
    }

    public toggleDrawer(): void {
        this.isDrawerOpen = !this.isDrawerOpen;
    }

    public refreshItemCards(): void {
        this.itemCards = [];
        this.itemCards = this.physInv!.Items!.map((item: PhysicalInventoryItemModel) => this.createCard(item));
    }

    public async attached(): Promise<void> {
        this.isLoading = false;
    }

    public async getSites(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<any> {
        return await this.inventoryProjectTransferService.GetSites(this.physInv!.Prefix, filter, pagingInfo, requestConfig);
    }

    public async getItemTypes(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<any> {
        return await this.catalogItemTypeService.GetTypes(filter, pagingInfo, requestConfig);
    }

    public async getCategories(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<any> {
        return await this.catalogItemCategoryService.GetCategories(filter, pagingInfo, requestConfig);
    }

    public async getLocations(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<any> {
        return await this.catalogInventoryLocationService.GetLocations(this.physInv!.Prefix, this.physInv!.Site, this.physInv!.ItemType, this.physInv!.Category, filter, pagingInfo, requestConfig);
    }

    public refresh(id: number): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.PhysicalInventoryCount_Edit.name, { id: id.toString() }, { replace: true, trigger: true } );
    }

    public async askOkToResetItems(): Promise<boolean> {
        const response = await notificationHelper.showDialogYesNo(this.i18n.tr("msg_ResetItemsPhysicalInventoryConfirmationText"));
        return response;
    }

    public async selectedSiteChanged(event: CustomEvent<any>): Promise<void> {
        const site = event ? event.detail : null;
        this.physInv!.Site = site ? site.id : null;
        this.physInv!.SiteDescription = site ? site.text : null;

        if (this.physInv!.Items!.length > 0) {
            if (await this.askOkToResetItems()) {
                this.physInv!.Items = [];
            } else {
                this.refresh(this.physInv!.Id);
                return;
            }
        }

        await this.resetLocations();
    }

    public async selectedTypeChanged(event: CustomEvent<any>): Promise<void> {
        const catType = event ? event.detail : null;
        this.physInv!.ItemType = catType ? catType.id : null;
        this.physInv!.ItemTypeDescription = catType ? catType.text : null;

        if (this.physInv!.Items!.length > 0) {
            if (await this.askOkToResetItems()) {
                this.physInv!.Items = [];
            } else {
                this.refresh(this.physInv!.Id);
                return;
            }
        }

        await this.resetLocations();
    }

    public async selectedCategoryChanged(event: CustomEvent<any>): Promise<void> {
        const cat = event ? event.detail : null;
        this.physInv!.Category = cat ? cat.id : null;
        this.physInv!.CategoryDescription = cat ? cat.text : null;

        if (this.physInv!.Items!.length > 0) {
            if (await this.askOkToResetItems()) {
                this.physInv!.Items = [];
            } else {
                this.refresh(this.physInv!.Id);
                return;
            }
        }

        await this.resetLocations();
    }

    public async locationChanged(event: CustomEvent<any>, fromLocation: boolean): Promise<void> {
        const loc = event ? event.detail : null;
        fromLocation ? (this.physInv!.LocationFrom = loc ? loc.id : null) : (this.physInv!.LocationTo = loc ? loc.id : null);

        if (this.physInv!.Items!.length > 0) {
            if (await this.askOkToResetItems()) {
                this.physInv!.Items = [];
            } else {
                this.refresh(this.physInv!.Id);
                return;
            }
        }

        if (this.physInv!.Id > 0) {
            await this.save();
        }
    }

    public async onClickIncludeReservationNoItems(): Promise<void> {
        if (this.isReadonly) {
            return;
        }

        await userSettingService.setPhInvIncludeReservationNoForCurrentUserSetting(!this.includeReservationNoItems);
    }

    public goToDocument(physInv: PhysicalInventoryModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.PhysicalInventoryCount_Document.name, {
            id: physInv.Id
        });
    }

    public async newScanDetected(scannedValue: any): Promise<any> {
        if (scannedValue) {
            const items = await this.service.GetNewPhysicalInventoryDetail(this.physInv!.Id, scannedValue);

            if (items!.length === 0) {
                notificationHelper.showWarning(this.i18n.tr("msg_PhysicalInventoryNoItemFound"));
                return;
            }

            this.physInv!.Items! = this.physInv!.Items!.concat(items);
            this.refreshItemCards();
        }
    }

    public async valueChanged(item: PhysicalInventoryItemModel, value: number): Promise<void> {
        if (!this.physInv || this.isLoading || routerHelper.isLoading()) {
            return;
        }

        if (!this.includeReservationNoItems) {
            const qtiesReserved = await this.service.ValidationPhysicalInventoryDetailQty(item);
            if (item.ActualQty === null) {
                item.ActualQty = 0;
            }
            if (qtiesReserved !== 0) {
                notificationHelper.showWarning(this.i18n.tr("msg_PhysicalInventoryQtyActualLowerThanReserved").replace("{0}", item.ActualQty.toString()).replace("{1}", qtiesReserved.toString()), undefined, { timeOut: 0 });
            }
        }
      
        await this.service.SavePhysicalInventoryDetail(item);
    }

    public async saveDate(value: any): Promise<void> {
        if (!this.physInv || this.physInv.Id < 1 || this.isLoading || routerHelper.isLoading()) {
            return;
        }

        await this.save();
    }

    public async deleteItem(itemToDelete: PhysicalInventoryItemModel): Promise<void> {
        if (this.isReadonly) { return; }
      
        const dialogMessage = `${this.i18n.tr("InventoryTransfer_Delete_Confirmation")} (${itemToDelete.ProductCode} - ${itemToDelete.Description})`;

        notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<any> => {
            if (success) {
                await this.service.DeletePhysicalInventoryDetail(itemToDelete.PhysicalInventoryId, itemToDelete.LineNo, itemToDelete.ProductCode);

                this.itemCards = this.itemCards.filter((card: MaCardOptionsModel) => {
                    return itemToDelete.LineNo !== card.model.LineNo;
                });

                this.physInv!.Items = this.physInv!.Items!.filter((item: PhysicalInventoryItemModel | null) => {
                    return itemToDelete.LineNo !== item!.LineNo;
                });
            }
        });
    }

    private async resetLocations(): Promise<void> {
        this.physInv!.LocationFrom = null;
        this.physInv!.LocationTo = null;

        if (this.physInv!.Id > 0) {
            await this.save();
        }
    }

    private createCard(item: PhysicalInventoryItemModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            displayLines: [
                new MaCardLineModel({ isCustomTemplate: true})
            ],
            actionItems: [],
        });

        if (!this.isReadonly) {
            card.actionItems = [
                new MaCardActionModel({ icon: "fa fa-trash text-danger pt-3", action: this.deleteItem.bind(this, item) })];
        }

        card.actionItems!.push();

        return card;
    }

    private async save(): Promise<void> {
        if (this.physInv!.Status !== PhysicalInventoryStatus.Entered) {
            return;
        }

        if (this.siteMandatory && !this.physInv!.Site) {
            notificationHelper.showError(this.i18n.tr("err_PhysicalInventorySiteRequired"));
            return;
        }

        const physInvUpdated = await this.service.save(this.physInv!);

        if (this.physInv!.Id < 1) {

            this.refresh(physInvUpdated!.Id);
            return;
        }

        this.physInv!.Items = physInvUpdated!.Items;
        this.refreshItemCards();
    }
}
