import { default as _ } from 'underscore';

import { Container } from 'aurelia-dependency-injection';
import { DialogService } from "aurelia-dialog";

import MessageBox from "pages/shared/messages/MessageBox";
import DialogWithOptions from "pages/shared/messages/DialogWithOptions";
import TemplateDialog from "pages/shared/messages/templateDialog";

import { EventAggregator } from 'aurelia-event-aggregator';
import ProjectBreakTimesSelection from "pages/shared/messages/ProjectBreakTimesSelection";
import ExportSelection from "pages/shared/messages/exportSelection";

define([
    "toastr",
    "underscore",
    "core/resx"
], function (toastr, _, resx) {
    "use strict";

    var timeOut = 3000;
    var openedDialogs = [];
    
    function setOptions(options) {
        var settings = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-full-width",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1000",
            "timeOut": timeOut,
            "extendedTimeOut": timeOut, //mouse out timeoute
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
        if (_.isObject(options)) {
            settings = _.extendOwn(settings, options);
        }
        toastr.options = settings;
    } 

    function showDialogInternal(viewModel, model) {
        model = model === undefined ? {} : model;

        var dialogService = Container.instance.get(DialogService);

        var promise = new Promise((resolve, reject) => {
            dialogService.open({
                viewModel: viewModel,
                model: model
            }).whenClosed(response => {
                if (response.wasCancelled) {
                    resolve(response.output);
                }
                else {
                    resolve(response.output);
                }
            });
        });

        promise.done = promise.then;
        return promise;
    }

    var viewModel = {
        registerDialog: function (dialog) {
            openedDialogs.push(dialog);
        },

        unregisterDialog: function (dialog) {
            _.without(openedDialogs, dialog);
        },

        closeDialogs: function () {
            openedDialogs = [];
        },
        
        showDialogOk: function (msg, title) {
            var model = {
                title: title === undefined ? null : title,
                message: msg,
                singleButton: true
            };
            
            return showDialogInternal(MessageBox, model);
        },

        showDialogOkCancel: function (msg, title) {
            var model = {
                title: title === undefined ? null : title,
                message: msg
            };

            return showDialogInternal(MessageBox, model);
        },

        showDialogYesNo: function (msg, title, isError, options) {
            var model = {
                title: title === undefined ? null : title,
                isErrorBox: isError || false,
                message: msg,
                yesLabel: resx.localize("Yes"),
                noLabel: resx.localize("No")
            };
            if (options) {
                model = _.extend(model, options);
            }

            return showDialogInternal(MessageBox, model);
        },

        showDialogSaveCancel: function (msg, title, isError, options) {
            var model = {
                title: title === undefined ? null : title,
                isErrorBox: isError || false,
                message: msg,
                yesLabel: resx.localize("Save2"),
                noLabel: resx.localize("DoNotSave"),
                labelClass: "small"
            };
            if (options) {
                model = _.extend(model, options);
            }

            return showDialogInternal(MessageBox, model);
        },

        showDialogResetFields: function (msg, title, isError, options) {
            if (!msg) {
                msg = resx.localize("Procore.ConfigurationsResetFields")
            }

            var model = {
                title: title === undefined ? null : title,
                isErrorBox: isError || false,
                message: msg,
                yesLabel: resx.localize("ResetFields"),
                noLabel: resx.localize("DoNotResetFields"),
                labelClass: "small",
                larger: true
            };

            if (options) {
                model = _.extend(model, options);
            }

            return showDialogInternal(MessageBox, model);
        },

        showErrorDialogYesNo: function (msg, title) {
            return this.showDialogYesNo(msg, title, true);
        },

        showDeleteConfirmation: function () {
            return this.showConfirmation(resx.localize("msg_DeleteEntryConfirmationText"));
        },

        showConfirmation: function (msg, title) {
            return this.showDialogYesNo(
                msg || resx.localize("msg_ConfirmationText"),
                title || resx.localize("msg_ConfirmationTitle"));
        },

        showOptionsDialog: function (msg, title, options) {
            var model = {
                title: title === undefined ? null : title,
                isErrorBox: false,
                message: msg,
                optionsList: []
            };

            if (options) {
                model = _.extend(model, options);
            }

            return showDialogInternal(DialogWithOptions, model);
        },

        showTemplateDialog: function (msg, title, template, options) {
            var model = {
                title: title === undefined ? null : title,
                isErrorBox: false,
                message: msg,
                template: template
            };

            if (options) {
                model = _.extend(model, options);
            }

            return showDialogInternal(TemplateDialog, model);
        },

        showDialogProjectBreakTimesSelection: function (msg, title, options) {
            var model = {
                title: title === undefined ? null : title,
                isErrorBox: false,
                message: msg,
                yesLabel: resx.localize("Yes"),
                noLabel: resx.localize("No")
            };

            if (options) {
                model = _.extend(model, options);
            }

            return showDialogInternal(ProjectBreakTimesSelection, model);
        },

        showDialogExportSelection: function (msg, title, options) {
            var model = {
                title: title === undefined ? null : title,
                isErrorBox: false,
                message: msg
            };

            if (options) {
                model = _.extend(model, options);
            }

            return showDialogInternal(ExportSelection, model);
        },

        
        showInfo: function (msg, title, options) {
            setOptions(options);
            toastr.info(msg, title);
        },

        showWarning: function (msg, title, options) {
            setOptions(options);
            toastr.warning(msg, title);
        },
        
        showError: function (msg, title, options) {
            setOptions(options);
            toastr.error(msg, title);
        },
        
        showSuccess: function (msg, title, options) {
            setOptions(options);
            toastr.success(msg, title);
        },
        
        showNagger: function (msg, title) {
            setOptions({ timeOut: 0 });
            toastr.warning(msg, title);
        },
        
        showValidationError: function (errors, options) {            
            var msg = "";

            if (_.isFunction(errors)){
                // HACK since we used to pass ko observable directly to the notifier. Remove this when we removed knockout.
                errors = errors();
            }

            _.each(errors, function (error) {
                msg += error + "<br/>";
            });
        
            var opt = { timeOut: 4000, extendedTimeOut: 4000 };
            if (options) {
                opt = _.extend(opt, options);
            }
            this.showWarning(msg, resx.localize("ValidationError"), opt);
        },

        
        notificationCenterMessageSet: function (key, text, type, addActionButton, options) {
            var eventAggregator = Container.instance.get(EventAggregator);
            eventAggregator.publish("notification-center:set", [key, text, type, addActionButton, options]);
        },
        
        notificationCenterMessageRemove: function (key) {
            var eventAggregator = Container.instance.get(EventAggregator);
            eventAggregator.publish("notification-center:remove", key);
        },

        notificationCenterMessageRemoveMaestroServerConnect: function (key) {
            var eventAggregator = Container.instance.get(EventAggregator);
            eventAggregator.publish("notification-center-maestro-server-connect:remove", key);
        }
    };

    
    return viewModel;
});
