import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { DocumentService } from "services/document-service";

define([
    "jquery",
    "helpers/routerHelper",
    "services/documentService",
    "helpers/documentHelper"
], function(jQuery, routerHelper, documentService, documentHelper) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        ctor.inject = [EventAggregator, DocumentService];

        function ctor(eventAggregator, docService) {
            self = this;
            this.docService = docService;

            //#region Properties
            self.readonly = false;
            self.dispatchId = "";
            self.entityId = 0;

            self.actions = {};
            self.eventAggregator = eventAggregator;
            self.subscriptions = [];
            //#endregion
        }

        //#region Public Methods
        ctor.prototype.activate = function (params) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQs && paramsQs.readonly === "false");
            self.entityId = paramsQs.entityId;
            self.dispatchId = params.dispatchId;
            self.actions = {
                getSections: documentService.getSectionsByDispatchId.bind(self, params.serviceType, params.dispatchId),
                getSectionDocuments: documentService.getDispatchDocumentsBySectionId.bind(self, params.serviceType, params.dispatchId),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                deleteDocument: self.docService.DeleteDocument.bind(self.docService)
            };
            self.subscriptions.push(self.eventAggregator.subscribe("document-upload-finish",
                () => { document.location.reload(true) }));
        };

        ctor.prototype.detached = function () {
            self.subscriptions.forEach((subscription) => subscription.dispose());
        }
        //#endregion

        return ctor;
    })();

    return viewModel;
});
