import { autoinject } from "aurelia-framework";
import contactService from "services/contactService";

@autoinject
export class ServiceContact {

    public contacts: any[] = [];
    public dispatchId: any;
    public actions: any;

    public activate(params: any): any {
        this.dispatchId = params.dispatchId;
        this.loadData();
        return;
    }

    public loadData(): any {
        this.actions = {
            getContacts: contactService.getClientContactsLookup.bind(this, this.dispatchId)
        };
    }
}
