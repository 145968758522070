define([
    "underscore",
    "helpers/routerHelper"
], function (_, routerHelper) {
    "use strict";

    var exports = {

        parse: function(queryString, param) {
            var params = routerHelper.getQuerystring(queryString);
            return _.propertyOf(params)(param);
        },

        parseIsProjectResponsible: function (queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return (params && params.isProjectResponsible === "true") || false;
        },

        parseManualBreaksManagementEnabled: function (queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return (params && params.manualBreaksManagementEnabled === "true") || false;
        },

        parseIsTeamLeader: function (queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return (params && params.isTeamLeader === "true") || false;
        },

        parseReadonly: function (queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return !(params && params.readonly === "false") || false;
        },

        parseType: function (queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return !(params && params.Type) || "";
        },

        parseIsTeamLeaderTimesheet: function(queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return (params && params.CurrentEmployeeIsTeamLeader === "true") || false;
        },

        IsResponsibleforTimeEntry: function(queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return (params && params.TeamLeaderIsResponsibleForTimeEntry === "true") || false;
        },

        isBillingItem: function(queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return (params && params.billingItem === "true") || false;
        },

        parseCanDeleteDailyEntry: function (queryString) {
            var params = routerHelper.getQuerystring(queryString);
            return !(params && params.canDeleteDailyEntry === "false") || false;
        },

    };

    return exports;
});
