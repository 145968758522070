
import { Tab } from "components/layout/tab";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import notificationHelper from "helpers/notificationHelper";
import { default as settingHelper } from "helpers/settingHelper";

import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";

import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { InventorySiteTransferModel } from "api/models/company/inventory-site-transfer-model";
import { InventoryProjectTransferStatus } from "api/enums/inventory-project-transfer-status";

@autoinject
export class InventorySiteTransferIndex {
    @bindable public currentTabId: number = 0;
    public isClosedInventorySiteTransferLoaded: boolean = false;

    public currentInventorySiteTransferCards: MaCardOptionsModel[] = [];
    public closedInventorySiteTransferCards: MaCardOptionsModel[] = [];
    public isMultiPrefix: boolean = false;

    constructor(private readonly i18n: I18N, private readonly inventorySiteTransferService: InventorySiteTransferService, private routeRepository: RouteRepository) {}

    public bind(): void {
        this.isMultiPrefix = settingHelper.isMultiPrefix();
        this.inventorySiteTransferService.GetTransfers(false).then((result: InventorySiteTransferModel[]): any => {
            this.currentInventorySiteTransferCards = result.map((item: InventorySiteTransferModel) => this.createCard(item, false));
        });
    }

    public currentTabIdChanged(): void {
        if (!this.isClosedInventorySiteTransferLoaded) {
            this.inventorySiteTransferService.GetTransfers(true).then((result: InventorySiteTransferModel[]): any => {
                this.closedInventorySiteTransferCards = result.map((item: InventorySiteTransferModel) => this.createCard(item, true));
            });
            this.isClosedInventorySiteTransferLoaded = true;
        }
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (Number(event.detail.tabId) === this.currentTabId) {
            return;
        }
        this.currentTabId = Number(event.detail.tabId);
    }

    public add(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Add.name);
    }

    public edit(item: InventorySiteTransferModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Info.name, { transferId: item.Id });
    }

    public delete(item: InventorySiteTransferModel): void {
        const dialogMessage = `${this.i18n.tr("msg_DeleteInventorySiteTransferConfirmationText")} (${this.getCounterDisplay(item)})`;

        notificationHelper.showDialogYesNo(dialogMessage).then((success: boolean): any => {
            if (success) {
                this.inventorySiteTransferService.DeleteTransfer(item.Id).then((result: boolean): any => {
                    if (result) {
                        this.removeTransferFromList(item.Id);
                        notificationHelper.showSuccess(this.i18n.tr("Procore.Status_Success"));
                    }
                });
            }
        });
    }

    public createCard(item: InventorySiteTransferModel, isClosed: boolean): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            // id: item.Id,
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getCounterDisplay(item), class: "text-primary font-weight-bold"}),
                new MaCardLineModel({ display: this.getDateDisplay(item), icon: "fa fa-plus text-success pr-1" }),
                new MaCardLineModel({ display: this.getStatusDisplay(item), class: "font-weight-bold" })
            ],
            actionItems: [new MaCardActionModel({id: "edit", icon: "fa fa-chevron-right", action: this.edit.bind(this, item) })],
        });

        if (this.isMultiPrefix) {
            card.displayLines.push(new MaCardLineModel({ display: this.getCompanySourceDisplay(item)}));
            card.displayLines.push(new MaCardLineModel({ display: this.getCompanyTargetDisplay(item)}));
        }

        card.displayLines.push(new MaCardLineModel({ display: this.getCreatedByDisplay(item), class: "mt-2" }));

        if (!isClosed) {
            card.actionItems!.push(new MaCardActionModel({id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item) }));
        }
        return card;
    }

    private getCounterDisplay(item: InventorySiteTransferModel): string {
        return `${this.i18n.tr("Counter")}: ${item.Id}`;
    }

    private getDateDisplay(item: InventorySiteTransferModel): string {
        return `${this.i18n.tr("CreatedOn")} ${dateHelper.getShortTextDate(item.AccountingDate!)}`;
    }

    private getStatusDisplay(item: InventorySiteTransferModel): string {
        return item.Status === InventoryProjectTransferStatus.Active ? this.i18n.tr("InProgress") : this.i18n.tr("Completed");
    }

    private getCompanySourceDisplay(item: InventorySiteTransferModel): string {
        return `${this.i18n.tr("SourceCompany")}: ${item.SourcePrefix} - ${item.SourceCompanyName}`;
    }

    private getCompanyTargetDisplay(item: InventorySiteTransferModel): string {
        return `${this.i18n.tr("TargetCompany")}: ${item.TargetPrefix} - ${item.TargetCompanyName}`;
    }

    private getCreatedByDisplay(item: InventorySiteTransferModel): string {
        return `${this.i18n.tr("CreatedBy")} ${item.CreatedBy}`;
    }

    private removeTransferFromList(id: number): void {
        this.currentInventorySiteTransferCards = this.currentInventorySiteTransferCards.filter((item: MaCardOptionsModel) => {
            return id !== item.model.Id;
        });
    }
}
