import { bindable, bindingMode, transient } from "aurelia-framework";

import { default as resx } from "core/resx";

@transient()
export class CounterEditor {
    @bindable disabled = false;
    @bindable description = null;
    @bindable currentCounterValue = null;
    @bindable unit = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;

    constructor() {
        this.resx = resx;
    }

    bind(){
        // No null value in pervasive so it return 0 instead.
        if (this.value === 0) {
            this.value = null;
        }
    }
}