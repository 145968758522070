// GENERATED FILE - DO NOT MODIFY
import { EmployeeHoursSummaryHeaderModel } from "api/models/company/employee-hours-summary-header-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class EmployeeHoursSummaryProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetHoursSummary(startDate: Date, endDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EmployeeHoursSummaryHeaderModel[] | null> {
        const uri = this.buildUri(
            "/employees/current/hourssummary",
            null,
            { startDate: (startDate === null || startDate === undefined) ? undefined : startDate.toString(), endDate: (endDate === null || endDate === undefined) ? undefined : endDate.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EmployeeHoursSummaryHeaderModel[] | null = await this.get<EmployeeHoursSummaryHeaderModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.EmployeeHoursSummaryHeaderModel");
        return data;
    }

    public async IsAlreadyApproved(startDate: Date, endDate: Date, requestConfig?: HttpClientRequestConfig): Promise<string | null> {
        const uri = this.buildUri(
            "/employees/current/hourssummary/is-already-approved",
            null,
            { startDate: (startDate === null || startDate === undefined) ? undefined : startDate.toString(), endDate: (endDate === null || endDate === undefined) ? undefined : endDate.toString() }
        );

        const data: string | null = await this.get<string | null>(uri, requestConfig);
        return data;
    }

    public async IsCCQReportAvailable(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/employees/current/hourssummary/ccq-report-available",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async CCQReport(startDate: Date, endDate: Date, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/employees/current/hourssummary/ccq-report",
            null,
            { startDate: (startDate === null || startDate === undefined) ? undefined : startDate.toString(), endDate: (endDate === null || endDate === undefined) ? undefined : endDate.toString() }
        );

        const data: boolean = await this.post<void, boolean>(uri, undefined, requestConfig);
        return data;
    }
}
