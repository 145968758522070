import { bindable, computedFrom, inject, transient } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@transient()
@inject(I18N)
export class NodataPlaceholder {
    @bindable context;
    @bindable text;
    @bindable isReadOnly = false;
    @bindable showBody = false;

    txtHeader;
    txtBody;

    @computedFrom("txtBody", "isReadOnly", "showBody")
    get displayBody() {
        return this.txtBody !== "" && !this.isReadOnly && this.showBody;
    }

    constructor(i18n) {
        this.i18n = i18n;
    }

    bind(bindingContext) {
        if (this.text) {
            this.txtHeader = this.text;
            this.txtBody = "";
        } else if (this.context) {
            this.txtHeader = this.i18n.tr("lstNoData_" + this.context + "_Header");
            this.txtBody = this.showBody ? this.i18n.tr("lstNoData_" + this.context + "_Body") : "";            
        } else {
            this.txtHeader = this.i18n.tr("lstNoData_Generic_Header");
            this.txtBody = this.showBody ? this.i18n.tr("lstNoData_Generic_Body") : "";
        }
    }
}