// GENERATED FILE - DO NOT MODIFY
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { InspectionTypeLookupModel } from "api/models/company/inspection/inspection-type-lookup-model";
import { InspectionTypeModel } from "api/models/company/inspection/inspection-type-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class EquipmentInspectionProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetEquipmentsForInspections(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        const uri = this.buildUri(
            "/inspections/equipments",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentModel[] | null = await this.get<EquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Equipment.EquipmentModel");
        return data;
    }

    public async GetInspectionTypesLookup(equipmentId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InspectionTypeLookupModel[] | null> {
        const uri = this.buildUri(
            "/inspections/types/lookup",
            null,
            { equipmentId: (equipmentId === null || equipmentId === undefined) ? undefined : equipmentId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: InspectionTypeLookupModel[] | null = await this.get<InspectionTypeLookupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Inspection.InspectionTypeLookupModel");
        return data;
    }

    public async GetInspectionType(inspectionTypeId: string | null, equipmentId: string | null, requestConfig?: HttpClientRequestConfig): Promise<InspectionTypeModel | null> {
        const uri = this.buildUri(
            "/inspections/types/{inspectionTypeId}/equipment/{equipmentId}",
            { inspectionTypeId: (inspectionTypeId === null || inspectionTypeId === undefined) ? undefined : inspectionTypeId, equipmentId: (equipmentId === null || equipmentId === undefined) ? undefined : equipmentId },
            null
        );

        const data: InspectionTypeModel | null = await this.get<InspectionTypeModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Inspection.InspectionTypeModel");
        return data;
    }
}
