import { LocationType, nameof as nameof_LocationType } from "api/enums/location-type";
import { LocationModel } from "api/models/company/location-model";
import { PagingInfo } from "api/paging-info";
import { autoinject, bindingMode } from "aurelia-framework";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import { RadioButtonItem } from "components/buttons/radio-button-new";
import { LookupParameters } from "components/form-controls/dropdown-base";
import { Dropdown } from "components/form-controls/dropdown";
import { FormControl } from "components/form-controls/form-control";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { IRequestConfig } from "models/request-config";
import { object } from "underscore";
import { DialogCloseResult, DialogService } from "aurelia-dialog";
import { NewLocationDialog } from "pages/services/quotations/new-location-dialog";

export interface LocationLookupParameters extends LookupParameters {
    addressType: string;
}

export type LocationLookupFunction<T> = (x: LocationLookupParameters) => Promise<T[]>;

@autoinject()
export class LocationPicker extends FormControl {
    public readonly nameof_LocationType: string = nameof_LocationType;

    public locationTypes: RadioButtonItem[] = [];

    @bindable.string
    public selectedLocationType: LocationType | null = null;

    @observable.none
    public internalSelectedLocationType: RadioButtonItem | null = null;

    @bindable.none()
    public lookup!: LocationLookupFunction<LocationModel>;

    @bindable.none({ defaultBindingMode: bindingMode.twoWay })
    public value: LocationModel | null = null;

    public dropdownViewModel: Dropdown<LocationModel> | null = null;

    @bindable
    public acceptedTypes: LocationType[] = [];

    @bindable
    public showClear: boolean = true;

    @bindable
    public showAddAlternative: boolean = false;

    constructor(element: Element, private readonly enumFormatValueConverter: EnumFormatValueConverter, private readonly dialogService: DialogService) {
        super(element);
    }

    public bind(): any {
        this.initLocationTypes();
    }

    public async selectedLocationTypeChanged(newValue: string): Promise<void> {
        if (this.selectedLocationType) {
            this.internalSelectedLocationType = this.locationTypes.find((x: RadioButtonItem): boolean => x.id === this.selectedLocationType) || null;
        } else {
            this.internalSelectedLocationType = null;
        }

        if (this.dropdownViewModel && this.dropdownViewModel.isOpen) {
            this.dropdownViewModel.reloadItems();
        }
    }

    public addNewLocation(): void {
        if (this.dropdownViewModel && this.dropdownViewModel.isOpen) {
            this.dropdownViewModel.isOpen = false;
        }
        this.dialogService.open({ viewModel: NewLocationDialog, model: {} , lock: false, overlayDismiss: false }).whenClosed(async (result: DialogCloseResult) => {
            if (!result.wasCancelled) {
                const item = result.output as LocationModel;
                this.value = item;
            } else {
                this.internalSelectedLocationType = null;
            }
        });
    }

    public internalSelectedLocationTypeChanged(): void {
        if (this.internalSelectedLocationType) {
            this.selectedLocationType = this.internalSelectedLocationType.id;
        } else {
            this.selectedLocationType = null;
        }
    }

    public dropdownOpened(): void {
        if (this.value && this.value.Type) {
            this.internalSelectedLocationType = this.locationTypes.find((x: RadioButtonItem): boolean => !!this.value && x.id === this.value.Type) || null;
        } else if (this.locationTypes.length > 0) {
            this.internalSelectedLocationType = this.locationTypes[0];
        }
    }

    public async populateLocations(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<LocationModel[] | null> {
        if (!this.internalSelectedLocationType) { return []; }

        return await this.lookup({ addressType: this.internalSelectedLocationType.id, filter: filter, pagingInfo: pagingInfo, requestConfig: requestConfig } as LocationLookupParameters);
    }

    public valueChanged(newValue: LocationModel | null): void {
        CustomEventHelper.dispatchEvent(this.element, "selected-item-changed", this.value, false, false);
    }

    private initLocationTypes(): void {
        let locationTypes = Object.values(LocationType);

        this.showAddAlternative = locationTypes.includes(LocationType.Alternative);

        if (this.acceptedTypes.length > 0) {
            locationTypes = locationTypes.filter((x: LocationType) => this.acceptedTypes.includes(x));
        }

        this.locationTypes = Object.values(locationTypes)
            .filter((x: LocationType) => typeof x === "string")
            .map((x: LocationType) => this.createRadioButtonItem(x));
    }

    private createRadioButtonItem(locationType: LocationType): RadioButtonItem {
        return { id: locationType, text: this.enumFormatValueConverter.toView(locationType, this.nameof_LocationType) } as RadioButtonItem;
    }
}
