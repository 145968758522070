import { PLATFORM } from "aurelia-framework";

export function configure(config) {
    config.globalResources(
        [
            // Enums
            PLATFORM.moduleName("./enums/enum-format"),
            PLATFORM.moduleName("./enums/enum-format-with-id"),

            // Format
            PLATFORM.moduleName("./format/number-format"),
            PLATFORM.moduleName("./format/date-format"),
            PLATFORM.moduleName("./format/file-size-format"),
            PLATFORM.moduleName("./format/phone-number-format"),

            // Icons
            PLATFORM.moduleName("./icons/service-call-quotation-item-type-icon"),
            PLATFORM.moduleName("./icons/service-call-quotation-status-icon"),
            PLATFORM.moduleName("./icons/file-type-to-icon"),

            // Misc
            PLATFORM.moduleName("./misc/boolean-to-yes-no"),
            PLATFORM.moduleName("./misc/hours-number-to-time"),
            PLATFORM.moduleName("./misc/map-link-value-converter"),
            PLATFORM.moduleName("./misc/validation-attribute-value-converter"),
            PLATFORM.moduleName("./misc/zero-to-null-value-converter"),

            // Types
            PLATFORM.moduleName("./types/boolean-to-string"),
            PLATFORM.moduleName("./types/integer-to-string"),
            PLATFORM.moduleName("./types/string-to-integer"),
            PLATFORM.moduleName("./types/string-to-boolean"),
            PLATFORM.moduleName("./types/inverse-boolean"),

            //
            PLATFORM.moduleName("./location-single-line-format"),
        ]);
}
