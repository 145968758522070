// GENERATED FILE - DO NOT MODIFY
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { DispatchProjectAttendanceModel } from "api/models/company/project/dispatch/dispatch-project-attendance-model";
import { DispatchProjectResourceModel } from "api/models/company/project/dispatch/dispatch-project-resource-model";
import { DispatchProjectTimeEntriesSummaryResourceModel } from "api/models/company/project/dispatch/dispatch-project-time-entries-summary-resource-model";
import { DispatchProjectTimeEntryModel } from "api/models/company/project/dispatch/dispatch-project-time-entry-model";
import { DispatchProjectTimeEntrySaveModel } from "api/models/company/project/dispatch/dispatch-project-time-entry-save-model";
import { DispatchProjectTimeEntrySaveResultModel } from "api/models/company/project/dispatch/dispatch-project-time-entry-save-result-model";
import { ProjectLookupModel } from "api/models/company/project/project-lookup-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DispatchProjectTimeEntryProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetTimeEntriesForDispatch(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectResourceModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatch/{dispatchId}/timeentries",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: DispatchProjectResourceModel | null = await this.get<DispatchProjectResourceModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectResourceModel");
        return data;
    }

    public async GetTimeEntry(timeEntryId: number, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectTimeEntryModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/timeentries/{timeEntryId}",
            { timeEntryId: (timeEntryId === null || timeEntryId === undefined) ? undefined : timeEntryId.toString() },
            null
        );

        const data: DispatchProjectTimeEntryModel | null = await this.get<DispatchProjectTimeEntryModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectTimeEntryModel");
        return data;
    }

    public async GetTimeEntriesSummary(dispatchDate: Date, maxDispatchDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectTimeEntriesSummaryResourceModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatchdates/{dispatchDate}/{maxDispatchDate}/timeentriessummary",
            { dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString(), maxDispatchDate: (maxDispatchDate === null || maxDispatchDate === undefined) ? undefined : maxDispatchDate.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DispatchProjectTimeEntriesSummaryResourceModel[] | null = await this.get<DispatchProjectTimeEntriesSummaryResourceModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectTimeEntriesSummaryResourceModel");
        return data;
    }

    public async GetProjectTimeEntriesSummary(projectDispatchCode: string | null, dispatchDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectTimeEntriesSummaryResourceModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/timeentriessummary",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DispatchProjectTimeEntriesSummaryResourceModel[] | null = await this.get<DispatchProjectTimeEntriesSummaryResourceModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectTimeEntriesSummaryResourceModel");
        return data;
    }

    public async GetLastProjectUsed(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<ProjectLookupModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/lastprojectused",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: ProjectLookupModel | null = await this.get<ProjectLookupModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectLookupModel");
        return data;
    }

    public async CreateOrUpdateTimeEntries(timeEntries: Array<DispatchProjectTimeEntrySaveModel | null> | null, dispatchProjectCode: string | null, dispatchDate: Date, forceSave: boolean | undefined, dispatchTemplateId: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectTimeEntrySaveResultModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/timeentries/post",
            null,
            { dispatchProjectCode: (dispatchProjectCode === null || dispatchProjectCode === undefined) ? undefined : dispatchProjectCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString(), forceSave: (forceSave === null || forceSave === undefined) ? undefined : forceSave.toString(), dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId }
        );

        const data: DispatchProjectTimeEntrySaveResultModel | null = await this.post<Array<DispatchProjectTimeEntrySaveModel | null> | null, DispatchProjectTimeEntrySaveResultModel | null>(uri, timeEntries, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectTimeEntrySaveResultModel");
        return data;
    }

    public async DeleteTimeEntry(timeEntryId: number, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectResourceModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/timeentries/{timeEntryId}",
            { timeEntryId: (timeEntryId === null || timeEntryId === undefined) ? undefined : timeEntryId.toString() },
            null
        );

        const data: DispatchProjectResourceModel | null = await this.delete<DispatchProjectResourceModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectResourceModel");
        return data;
    }

    public async SaveDispatchRequiredTomorrow(dispatchId: number, requiredTomorrow: boolean, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/requiredtomorrow/{requiredTomorrow}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), requiredTomorrow: (requiredTomorrow === null || requiredTomorrow === undefined) ? undefined : requiredTomorrow.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async SaveDispatchHasLeft(dispatchId: number, hasLeft: boolean, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/hasleft/{hasLeft}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), hasLeft: (hasLeft === null || hasLeft === undefined) ? undefined : hasLeft.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async SaveDispatchesHaveLeft(hasLeft: boolean, dispatchIds: number[] | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatches/hasleft/{hasLeft}",
            { hasLeft: (hasLeft === null || hasLeft === undefined) ? undefined : hasLeft.toString() },
            null
        );

        await this.post<number[] | null, void>(uri, dispatchIds, requestConfig);
    }

    public async SaveDispatchInactive(dispatchId: number, inactive: boolean, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectResourceModel | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/inactive/{inactive}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), inactive: (inactive === null || inactive === undefined) ? undefined : inactive.toString() },
            null
        );

        const data: DispatchProjectResourceModel | null = await this.post<void, DispatchProjectResourceModel | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectResourceModel");
        return data;
    }

    public async BreakEquipmentLink(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectAttendanceModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatches/{dispatchId}/breaklink",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DispatchProjectAttendanceModel[] | null = await this.post<void, DispatchProjectAttendanceModel[] | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectAttendanceModel");
        return data;
    }

    public async GetSeperateTimeLinkedEquipmentDispatches(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatches/{dispatchId}/timeentries/seperate-time-entry/special-equipment",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentModel[] | null = await this.get<EquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Equipment.EquipmentModel");
        return data;
    }

    public async GetAllLinkedEquipmentForDispatch(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatches/{dispatchId}/all-linked-equipment",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentModel[] | null = await this.get<EquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Equipment.EquipmentModel");
        return data;
    }
}
