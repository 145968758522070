import { ContractSearchAddressType } from "api/enums/contract-search-address-type";
import { CreationSource } from "api/enums/creation-source";
import { ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ClientModel } from "api/models/company/client/client-model";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { EmployeeModelBase } from "api/models/company/employee/employee-model-base";
import { LocationModel } from "api/models/company/location-model";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallEmergencyTypeModel } from "api/models/company/service-call/service-call-emergency-type-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationTypeModel } from "api/models/company/service-call/service-call-quotation-type-model";
import { ContractDetailSearchModel } from "api/models/company/service/contract-detail-search-model";
import { SkillModel } from "api/models/company/skill-model";
import { PagingInfo } from "api/paging-info";
import { computedFrom } from "aurelia-framework";
import { ValidationAttributeValueConverter } from "converters/misc/validation-attribute-value-converter";
import Logger from "core/logger";
import { RouteViewModel } from "core/route-view-model";
import { CloneHelper } from "helpers/cloneHelper";
import { NotificationHelper } from "helpers/notification-helper";
import Parse from "helpers/parse";
import { RouterHelper } from "helpers/router-helper";
import { IRequestConfig } from "models/request-config";
import { NavigationIdOrNew, NavigationNew } from "repositories/routeRepository";
import { CustomerContactService } from "services/customer-contact-service";
import { CustomerService } from "services/customer-service";
import { EmployeeService } from "services/employee-service";
import { ServiceCallContractService } from "services/service-call-contract-service";
import { ServiceCallEmergencyTypeService } from "services/service-call-emergency-type-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationTypeService } from "services/service-call-quotation-type-service";
import { SkillService } from "services/skill-service";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import { SalesPersonModel } from "api/models/company/sales-person-model";
import { SalesPersonService } from "services/sales-person-service";
import { ServiceCallRequiredDateType } from "api/enums/service-call-required-date-type";
import { EnumHelper } from "helpers/enum-helper";
import { ServiceCallService, ServiceCallGenerationOption } from "services/service-call-service";
import { SettingRepository } from "repositories/setting-repository";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { ServiceCallQuotationEquipmentService, ServiceCallQuotationRelatedEquipmentModel } from "services/service-call-quotation-equipment-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { LocationService } from "services/location-service";
import { ContactService } from "services/contact-service";
import { I18N } from "aurelia-i18n";
import { LocationType } from "api/enums/location-type";
import { WorkOrderPriorityService } from "services/work-order-priority-service";
import { WorkOrderPriorityModel } from "api/models/company/workorder/work-order-priority-model";
import { ServiceWorkOrderProjectService } from "services/service-work-order-project-service";
import { InvoicingProjectModel } from "api/models/company/project/invoicing-project-model";
import { ServiceWorkOrderService } from "services/service-work-order-service";
import { ServiceCallWorkOrderQuotationStatus } from "api/enums/service-call-work-order-quotation-status";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";
import { nameof as nameof_CallType, CallType } from "api/enums/call-type";
import { ProjectInvoicingMode } from "api/enums/project-invoicing-mode";
import userService from "services/userService";
import { UserSecurityLookupModel } from "api/models/common/settings/user-security-lookup-model";
import { AdditionalFieldType } from "api/enums/additional-field-type";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { TemplateService } from "services/template-service";
import { default as settingHelper } from "helpers/settingHelper";
import { DataModificationLogFileModel } from "api/models/company/data-modification-log-file-model";

export interface QuotationEditParameters {
    quotationId: NavigationIdOrNew<number>;
    isWorkOrder: boolean;
    sourceServiceCallId?: number;
    contractEquipmentId?: number;
    sourceWorkOrderId?: string;
    sourceQuotationId?: number;
}

export abstract class QuotationEditBase implements RouteViewModel<QuotationEditParameters> {
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public unmodifiedQuotation: ServiceCallQuotationDetailsModel | null = null;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;
    public readonly serviceCallQuotationStatus: typeof ServiceCallQuotationStatus = ServiceCallQuotationStatus;
    public readonly serviceCallWorkOrderQuotationStatus: typeof ServiceCallWorkOrderQuotationStatus = ServiceCallWorkOrderQuotationStatus;
    public requiredDateTypes: ServiceCallRequiredDateType[] = EnumHelper.getStringValues(ServiceCallRequiredDateType) as ServiceCallRequiredDateType[];
    public quotationEquipments: ServiceCallQuotationRelatedEquipmentModel[] = [];

    public selectedContractId: string | null = null;
    public selectedProjectId: string | null = null;
    public selectedProjectDescription: string | null = null;
    public selectedPricingType: string | null = ServiceCallQuotationPricingType.TimeMaterial;
    public lumpSumPlusProfitMargin: number = 0;

    public acceptedTypes: LocationType[] = [];

    public userHasAccessTabItems: boolean = false;
    public pagetitle: string = "";
    public nameof_CallType: string = nameof_CallType;
    public serviceCallTypes: CallType[] = EnumHelper.getStringValues(CallType).filter((type: string) => type !== CallType.All) as CallType[];
    public alternativeAddress!: LocationModel;
    public refreshAdditionalFields: boolean = true;
    public refreshRelatedEquipments: boolean = true;
    public isSaving: boolean = false;
    public dispatchTemplate: DispatchTemplateModel | null = null;
    public refreshQuotationModifications: boolean = true;
    public refreshQuotationItemsModifications: boolean = true;

    public quotationModifications: DataModificationLogFileModel[] = [];
    public quotationItemsModifications: DataModificationLogFileModel[] = [];

    @computedFrom("quotation", "quotation.MustCreateCustomerContract", "quotation.IsContractMiscCustomer")
    public get isResidential(): boolean {
        if (!this.quotation) { return false; }

        return !this.quotation.IsWorkOrder && this.quotation.MustCreateCustomerContract && this.quotation.IsContractMiscCustomer;
    }

    @computedFrom("quotation", "quotation.MustCreateCustomerContract", "quotation.InvoicingMode")
    public get isProjectInvoicingModeMultiCustomer(): boolean {
        if (!this.quotation) { return false; }

        return this.quotation.IsWorkOrder && this.quotation.MustCreateCustomerContract && this.quotation.InvoicingMode === ProjectInvoicingMode.MultiCust;
    }

    @computedFrom("quotation", "quotation.MustCreateCustomerContract", "quotation.IsContractMiscCustomer")
    public get isResidentialQuotationType(): boolean {
        return !!this.quotation && !this.quotation.IsWorkOrder && !!this.quotation.MustCreateCustomerContract;
    }

    @computedFrom("quotation", "quotation.MustCreateCustomerContract", "quotation.IsContractMiscCustomer", "quotation.CustomerId", "quotation.InvoicingMode")
    public get displayAlternativeAddressFields(): boolean {
        if (!this.quotation) { return false; }
        return ((!this.quotation.IsWorkOrder && this.quotation.MustCreateCustomerContract && this.quotation.IsContractMiscCustomer) || (this.quotation.IsWorkOrder && this.quotation.MustCreateCustomerContract && this.quotation.InvoicingMode === ProjectInvoicingMode.MultiCust)) && this.alternativeAddress.NumericId === null && (!this.quotation.CustomerId || this.quotation.IsWorkOrder);
    }

    protected filterContractsByPrefix: boolean = false;

    public abstract get readonly(): boolean;

    @computedFrom("quotation", "quotation.Id")
    public get isNew(): boolean {
        return !!this.quotation && this.quotation.Id <= 0;
    }

    @computedFrom("unmodifiedQuotation", "unmodifiedQuotation.Status", "unmodifiedQuotation.GeneratedServiceCallId", "securitySettings")
    public get canCreateServiceCall(): boolean {
        return this.serviceCallQuotationSecurityService.canCreateServiceCallFromQuotation(this.unmodifiedQuotation, this.securitySettings);
    }

    @computedFrom("unmodifiedQuotation", "unmodifiedQuotation.Status", "unmodifiedQuotation.GeneratedServiceCallId", "securitySettings")
    public get canEditServiceCallType(): boolean {
        return this.serviceCallQuotationSecurityService.canEditServiceCallType(this.unmodifiedQuotation, this.securitySettings);
    }

    @computedFrom("unmodifiedQuotation", "unmodifiedQuotation.Status", "unmodifiedQuotation.GeneratedWorkOrderId", "securitySettings")
    public get canCreateWorkOrder(): boolean {
        return this.serviceCallQuotationSecurityService.canCreateWorkOrderFromQuotation(this.unmodifiedQuotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.ContactId")
    public get readOnlyContactName(): boolean {
        if (this.quotation === null) {
            return true;
        }

        if (this.readonly) {
            return true;
        }

        //TODO le contrôle dropdown avec allowClear devrait retourner la valeur dans le bon type, on devrait pas être obligé de faire une conversion
        return Parse.Integer(this.quotation.ContactId) !== 0;
    }

    @computedFrom("quotation", "quotation.CustomerTypeId")
    public get customerTypeFullDescription(): string | null {
        if (this.quotation === null) {
            return "";
        }

        let customerTypeFullDesc = this.quotation.CustomerTypeId;
        if (customerTypeFullDesc && this.quotation.CustomerTypeDescription) {
            customerTypeFullDesc = customerTypeFullDesc + " - " + this.quotation.CustomerTypeDescription;
        }

        return customerTypeFullDesc;
    }

    @computedFrom("quotation", "quotation.ServiceContractZoneId")
    public get serviceContractZoneFullDescription(): string | null {
        if (this.quotation === null) {
            return "";
        }

        let serviceContractZoneFullDesc = this.quotation.ServiceContractZoneId;
        if (serviceContractZoneFullDesc && this.quotation.ServiceContractZoneDescription) {
            serviceContractZoneFullDesc = serviceContractZoneFullDesc + " - " + this.quotation.ServiceContractZoneDescription;
        }

        return serviceContractZoneFullDesc;
    }

    protected abstract get initialQuotationStatus(): string;

    @computedFrom("quotation", "quotation.Address")
    public get selectedWorkLocation(): LocationModel | null {
        if (!this.quotation) { return null; }

        return this.quotation.Address || null;
    }

    @computedFrom("quotation", "quotation.InvoicingMode")
    public get canEditCustomer(): boolean {
        if (!this.quotation) { return false; }

        return this.quotation.IsWorkOrder && this.quotation.InvoicingMode === ProjectInvoicingMode.MultiCust;
    }

    protected constructor(
        protected readonly serviceCallQuotationService: ServiceCallQuotationService,
        protected readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        protected readonly routerHelper: RouterHelper,
        protected readonly notificationHelper: NotificationHelper,
        protected readonly customerService: CustomerService,
        protected readonly customerContactService: CustomerContactService,
        protected readonly serviceCallContractService: ServiceCallContractService,
        protected readonly serviceWorkOrderProjectService: ServiceWorkOrderProjectService,
        protected readonly skillService: SkillService,
        protected readonly serviceCallEmergencyTypeService: ServiceCallEmergencyTypeService,
        protected readonly serviceCallQuotationTypeService: ServiceCallQuotationTypeService,
        protected readonly employeeService: EmployeeService,
        protected readonly validationAttributeValueConverter: ValidationAttributeValueConverter,
        protected readonly validationHelper: ValidationHelper,
        protected readonly validationController: ValidationController,
        protected readonly salesPersonService: SalesPersonService,
        protected readonly serviceCallService: ServiceCallService,
        protected readonly settingRepository: SettingRepository,
        protected readonly viewModeHelper: ViewModeHelper,
        protected readonly serviceCallQuotationEquipmentService: ServiceCallQuotationEquipmentService,
        protected readonly serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        protected readonly locationService: LocationService,
        protected readonly contactService: ContactService,
        protected readonly serviceWorkOrderService: ServiceWorkOrderService,
        protected readonly i18N: I18N,
        private readonly workOrderPriorityService: WorkOrderPriorityService,
        private readonly serviceCallContractEquipmentService: ServiceCallContractEquipmentService,
        public readonly templateService: TemplateService
    ) {
        this.alternativeAddress = this.locationService.createNewAlternateAddress();
    }

    public dateChanged(event: CustomEvent<Date | null>): void {
        if (this.quotation === null) {
            Logger.warn("Quotation is null.");
            return;
        }
        this.quotation.RequiredDate = this.quotation.Date;
    }

    public async requiredDateChanged(event: CustomEvent<Date | null>): Promise<void> {
        if (this.quotation === null) {
            Logger.warn("Quotation is null.");
            return;
        }

        await this.initQuotationEquipments();
        this.updateServiceQuotationEquipments();
    }

    public async selectedQuotationTypeChanged(event: CustomEvent<ServiceCallQuotationTypeModel>): Promise<void> {
        if (this.quotation === null) {
            Logger.warn("Quotation is null.");
            return;
        }

        this.quotation.MustCreateCustomerContract = event.detail ? event.detail.MustCreateCustomerContract : false;
        this.quotation.MustCreateProject = event.detail ? event.detail.MustCreateProject : false;

        if (event.detail && event.detail.EmergencyId && this.quotation.EmergencyId !== event.detail.EmergencyId) {
            this.quotation.EmergencyId = event.detail.EmergencyId;
            this.quotation.EmergencyDescription = event.detail.EmergencyDescription;
            const message = this.i18N.tr("msg_Quotation_priority_changed").replace("{0}", event.detail.EmergencyId + "-" + event.detail.EmergencyDescription);
            this.notificationHelper.showWarning(message, "");
        }
    }

    public async selectedContractChanged(event: CustomEvent<ContractDetailSearchModel>): Promise<void> {
        if (this.quotation === null) {
            Logger.warn("Quotation is null.");
            return;
        }

        let changeContract = true;

        if ((this.quotation.Id !== 0 && this.quotation.Items !== null && this.quotation.Items.length > 0) || this.userHasAccessTabItems) {
            const confirm = await this.notificationHelper.showConfirmationKey("msg_ChangeContractConfirmation");
            if (!confirm) {
                changeContract = false;
            }
        }

        if (changeContract) {
            const contractDetailModel = event ? event.detail : null;
            this.quotation.ContractId = contractDetailModel ? contractDetailModel.Id : null;
            this.selectedContractId = contractDetailModel ? contractDetailModel.Id : null;
            this.quotation.CustomerId = contractDetailModel ? contractDetailModel.CustomerId !== "" ? contractDetailModel.CustomerId : null : null;
            this.quotation.CustomerDescription = contractDetailModel ? contractDetailModel.CustomerName : null;
            this.quotation.CustomerTypeId = contractDetailModel ? contractDetailModel.CustomerTypeId !== "" ? contractDetailModel.CustomerTypeId : null : null;
            this.quotation.CustomerTypeDescription = contractDetailModel ? contractDetailModel.CustomerTypeDescription !== "" ? contractDetailModel.CustomerTypeDescription : null : null;
            this.quotation.IsContractMiscCustomer = !!contractDetailModel && contractDetailModel.IsMiscCustomer;
            this.quotation.CustomerOrderNumber = !!contractDetailModel ? contractDetailModel.ServiceOrderNumber : null;
            this.quotation.BillingMethod = contractDetailModel ? contractDetailModel.BillingMethod : null;
            this.quotation.LabourBillingCatalogCodeConfiguration = contractDetailModel ? contractDetailModel.LabourBillingCatalogCodeConfiguration : null;
            this.quotation.SalespersonId = !!contractDetailModel ? contractDetailModel.SalesRepId : null;
            this.quotation.SalespersonDescription = !!contractDetailModel ? contractDetailModel.SalesRep : null;
            this.quotation.ServiceContractProjectId = !!contractDetailModel ? contractDetailModel.ProjectId : null;
            this.quotation.ServiceContractZoneId = !!contractDetailModel ? contractDetailModel.Zone : null;
            this.quotation.ServiceContractZoneDescription = !!contractDetailModel ? contractDetailModel.ZoneDescription : null;

            if (this.viewModeHelper.getIsDesktopMode()) {
                this.quotation.TechnicianId = !!contractDetailModel ? contractDetailModel.EmployeeInCharge : 0;
                this.quotation.TechnicianDescription = !!contractDetailModel ? contractDetailModel.EmployeeInChargeDescription : null;
            }

            await this.initQuotationEquipments();
            this.quotation.Items = await this.serviceCallQuotationService.GetQuotationItemsFromContract(this.quotation);

            this.quotation.ContactId = 0;
            this.quotation.ContactName = null;
            this.quotation.ContactEmail = null;
            this.quotation.ContactDescription = null;

            //Set contact from Contract
            if (contractDetailModel) {
                if (contractDetailModel.ContactId !== 0) {
                    const contact = await this.contactService.getContactLookup(contractDetailModel.ContactId);
                    if (contact) {
                        this.quotation.ContactId = contact.Id;
                        this.quotation.ContactDescription = contact.FullName;
                        this.quotation.ContactName = contact.FullName;
                        this.quotation.ContactEmail = contact.Email;
                    }
                } else if (contractDetailModel.Contact) {
                    this.quotation.ContactName = contractDetailModel.Contact;
                }

                this.refreshAdditionalFields = false;
                this.quotation.AdditionalFields = null;
                this.quotation.AdditionalFields = await this.serviceCallQuotationService.getAdditionalFieldsFromServiceContract(this.quotation.Id, contractDetailModel.Id!);
                this.refreshAdditionalFields = true;
            }

            this.serviceCallQuotationService.clearAddress(this.quotation);

            //Set default location from Contract
            if (this.quotation.ContractId) {
                const locations = await this.locationService.getLocations(LocationType.Contract, this.quotation.ContractId, null, null);
                if (locations.length > 0) {
                    this.quotation.Address = locations[0];
                }
            }

            if (this.viewModeHelper.getIsDesktopMode()) {
                await this.serviceCallQuotationPriceService.resetPrices(this.quotation);
            }
        } else {
            this.selectedContractId = this.quotation.ContractId;
        }

    }

    public async selectedCustomerChanged(event: CustomEvent<ClientModel>): Promise<void> {
        if (!this.quotation) {
            Logger.warn("Quotation is null.");
            return;
        }

        const clientModel = event ? event.detail : null;

        this.quotation.CustomerTypeId = clientModel ? clientModel.TypeId !== "" ? clientModel.TypeId : null : null;
        this.quotation.CustomerTypeDescription = clientModel ? clientModel.TypeDescription !== "" ? clientModel.TypeDescription : null : null;

        this.quotation.ContactId = 0;
        this.quotation.ContactName = null;
        this.quotation.ContactEmail = null;
        this.quotation.ContactDescription = null;
    }

    public async selectedProjectChanged(event: CustomEvent<InvoicingProjectModel>): Promise<void> {
        if (this.quotation === null) {
            Logger.warn("Quotation is null.");
            return;
        }

        let changeProject = true;

        if ((this.quotation.Id !== 0 && this.quotation.Items !== null && this.quotation.Items.length > 0) || this.userHasAccessTabItems) {
            //TODO change msg
            const confirm = await this.notificationHelper.showConfirmationKey("msg_ChangeContractConfirmation");
            if (!confirm) {
                changeProject = false;
            }
        }

        if (changeProject) {
            const projectInvoicingModel = event ? event.detail : null;
            this.quotation.ProjectId = projectInvoicingModel ? projectInvoicingModel.Id : null;
            this.quotation.ProjectDescription = projectInvoicingModel ? projectInvoicingModel.Description : null;
            this.selectedProjectId = projectInvoicingModel ? projectInvoicingModel.Id : null;
            this.selectedProjectDescription = projectInvoicingModel ? projectInvoicingModel.Description : null;
            this.quotation.CustomerId = projectInvoicingModel ? projectInvoicingModel.CustomerId !== "" ? projectInvoicingModel.CustomerId : null : null;
            this.quotation.CustomerDescription = projectInvoicingModel ? projectInvoicingModel.CustomerName : null;
            this.quotation.CustomerTypeId = projectInvoicingModel ? projectInvoicingModel.CustomerTypeId !== "" ? projectInvoicingModel.CustomerTypeId : null : null;
            this.quotation.CustomerTypeDescription = projectInvoicingModel ? projectInvoicingModel.CustomerTypeDescription !== "" ? projectInvoicingModel.CustomerTypeDescription : null : null;
            this.quotation.CustomerOrderNumber = !!projectInvoicingModel ? projectInvoicingModel.PurchaseOrderNumber : null;

            this.quotation.ProjectBonusBillingCatalogCodeConfiguration = projectInvoicingModel ? projectInvoicingModel.ProjectBonusBillingCatalogCodeConfiguration : null;
            this.quotation.ProjectLabourBillingCatalogCodeConfiguration = projectInvoicingModel ? projectInvoicingModel.ProjectLabourBillingCatalogCodeConfiguration : null;
            this.quotation.ProjectEquipmentBillingCatalogCodeConfiguration = projectInvoicingModel ? projectInvoicingModel.ProjectEquipmentBillingCatalogCodeConfiguration : null;
            this.quotation.InvoicingMode = projectInvoicingModel ? projectInvoicingModel.InvoicingMode : null;

            this.quotation.CustomerOrderNumber = projectInvoicingModel ? projectInvoicingModel.PurchaseOrderNumber : null;

            this.quotation.SalespersonId = !!projectInvoicingModel ? projectInvoicingModel.SalesRepId : null;
            this.quotation.SalespersonDescription = !!projectInvoicingModel ? projectInvoicingModel.SalesRep : null;

            this.quotation.ContactId = 0;
            this.quotation.ContactName = null;
            this.quotation.ContactEmail = null;
            this.quotation.ContactDescription = null;

            //Set contact from Contract
            if (projectInvoicingModel) {
                if (projectInvoicingModel.ContactId !== 0) {
                    const contact = await this.contactService.getContactLookup(projectInvoicingModel.ContactId);
                    if (contact) {
                        this.quotation.ContactId = contact.Id;
                        this.quotation.ContactDescription = contact.FullName;
                        this.quotation.ContactName = contact.FullName;
                        this.quotation.ContactEmail = contact.Email;
                    }
                }
            }

            this.serviceCallQuotationService.clearAddress(this.quotation);

            if (this.quotation.ProjectId) {
                const locations = await this.locationService.getLocations(LocationType.Project, this.quotation.ProjectId, null, null);
                if (locations.length > 0) {
                    this.quotation.Address = locations[0];
                    this.quotation.ProjectIdWorkLocation = this.quotation.ProjectId;
                }
            }

        } else {
            this.selectedProjectId = this.quotation.ProjectId;
            this.selectedProjectDescription = this.quotation.ProjectDescription;
        }
    }

    public selectedContactChanged(event: CustomEvent<ContactLookupModel>): void {
        if (this.quotation === null) {
            Logger.warn("Quotation is null.");
            return;
        }

        const selectedContact: ContactLookupModel | null = event ? event.detail : null;

        this.quotation.ContactId = selectedContact ? selectedContact.Id : 0;

        const maxlengthContactName = this.validationAttributeValueConverter.toView("maxLength", "service.serviceCall.quotation.contact.name");
        let contactName = selectedContact ? selectedContact.FullName : null;
        if (contactName && contactName.length > maxlengthContactName) {
            contactName = contactName.substring(0, maxlengthContactName);
        }

        this.quotation.ContactName = contactName;

        const maxlengthContactEmail = this.validationAttributeValueConverter.toView("maxLength", "service.serviceCall.quotation.contact.email");
        let contacEmail = selectedContact ? selectedContact.Email : null;
        if (contacEmail && contacEmail.length > maxlengthContactEmail) {
            contacEmail = contacEmail.substring(0, maxlengthContactEmail);
        }

        this.quotation.ContactEmail = contacEmail;
    }

    public async selectedLocationChanged(event: CustomEvent<LocationModel | null>): Promise<void> {
        if (!this.quotation) { return; }

        this.serviceCallQuotationService.assignAddressIdsForQuotation(this.quotation, event.detail);

        if (!this.isSaving && !this.quotation.IsWorkOrder && this.viewModeHelper.getIsDesktopMode()) {
            await this.serviceCallQuotationPriceService.resetPrices(this.quotation);
        }
    }

    public selectedEstimatorChanged(event: CustomEvent<UserSecurityLookupModel | null>): void {
        if (!this.quotation) { return; }

        const selectedEstimator: UserSecurityLookupModel | null = event ? event.detail : null;

        this.quotation.AssignedEstimatorId = selectedEstimator ? selectedEstimator.Id : 0;
        this.quotation.AssignedEstimatorDescription = selectedEstimator ? selectedEstimator.Description : "";
    }

    public abstract async activate(params: QuotationEditParameters): Promise<void>;

    public async populateContracts(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ContractDetailSearchModel[]> {
        // TODO JL: On met quoi dans les filtres?
        const searchAddressType: ContractSearchAddressType | undefined = undefined;
        const statusToExclude: string[] | undefined = undefined;
        let contractTypes: Array<string | null> | undefined;

        if (this.dispatchTemplate) {
            contractTypes = this.dispatchTemplate.QuotationContractTypeList ? this.dispatchTemplate.QuotationContractTypeList || undefined : undefined;
            if (this.dispatchTemplate.QuotationContractTypeListAll) {
                contractTypes = undefined;
            }
        }
       
        const contractTypesToExclude: string[] = ["T"];

        return await (this.serviceCallContractService as ServiceCallContractService).GetContracts(
            searchAddressType,
            statusToExclude,
            contractTypes,
            contractTypesToExclude,
            this.filterContractsByPrefix,
            filter,
            pagingInfo,
            requestConfig
        );
    }

    public async populateProjects(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<InvoicingProjectModel[] | null> {
        return await (this.serviceWorkOrderProjectService as ServiceWorkOrderProjectService).GetInvoicingProjects(
            filter,
            pagingInfo,
            requestConfig
        );
    }

    public async populateCustomers(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ClientModel[] | null> {
        if (!this.quotation || (!this.quotation.IsWorkOrder && !this.quotation.ContractId) || (this.quotation.IsWorkOrder && !this.quotation.ProjectId) ) { return []; }

        return await this.serviceCallQuotationService.getAvailableCustomers(false, this.quotation.IsWorkOrder ? null : this.quotation.ContractId, this.quotation.ProjectId, filter, pagingInfo, requestConfig);
    }

    public async populateTechnicians(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<EmployeeModelBase[] | null> {
        return await this.employeeService.getTechnicians(filter, pagingInfo, requestConfig);
    }

    public async populateEmployees(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<EmployeeModelBase[] | null> {
        return await this.employeeService.getEmployeesLookup( null, filter, pagingInfo, requestConfig);
    }

    public async populateSalesPersons(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<SalesPersonModel[] | null> {
        return await this.salesPersonService.getSalesPersons(filter, pagingInfo, requestConfig);
    }

    public async populateLocations(addressType: string, filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<LocationModel[] | null> {
        if (!this.quotation) { return []; }

        return await this.serviceCallQuotationService.getAvailableWorkLocations(addressType, this.quotation, filter, pagingInfo, requestConfig);
    }

    public async populateContacts(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<any[]> {
        const customerId: string | null = (this.quotation as ServiceCallQuotationDetailsModel).CustomerId;

        if (!customerId) {
            throw new Error("A customer is required to fetch its contacts");
        }

        return await this.customerContactService.getClientContactsLookup(customerId, filter, pagingInfo, requestConfig);
    }

    public async populateSkills(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<SkillModel[] | null> {
        return await this.skillService.getSkills(filter, pagingInfo, requestConfig);
    }

    public async populateQuotationTypes(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ServiceCallQuotationTypeModel[] | null> {
        return await this.serviceCallQuotationTypeService.getQuotationTypes(filter, pagingInfo, requestConfig);
    }

    public async populateEmergencyTypes(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ServiceCallEmergencyTypeModel[] | null> {
        return await this.serviceCallEmergencyTypeService.getEmergencyTypes(filter, pagingInfo, requestConfig);
    }

    public async populateEmergencyTypesWorkOrder(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<WorkOrderPriorityModel[] | null> {
        return await this.workOrderPriorityService.GetPriorities(filter, pagingInfo, requestConfig);
    }

    public async populateEquipments(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ServiceCallContractEquipmentModel[] | null> {
        return await this.serviceCallContractEquipmentService.getNewServiceCallContractEquipments(filter, pagingInfo, requestConfig);
    }

    public async populateEstimators(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ClientModel[] | null> {
        if (!this.quotation) { return []; }

        return await userService.getAllUsersLookup(filter, pagingInfo.page || 1);
    }

    public async delete(): Promise<void> {
        if (this.quotation && (await this.confirmDelete())) {
            await this.serviceCallQuotationService.deleteQuotation(this.quotation.Id);
            this.routerHelper.navigateBack();
        }
    }

    public async initQuotationEquipments(): Promise<void> {
        if (!this.quotation) {
            return;
        }

        if (!this.quotation.ContractId) {
            this.quotationEquipments = [];
            this.quotation.Equipments = this.quotationEquipments;
            this.updateServiceQuotationEquipments();
            return;
        }

        this.refreshRelatedEquipments = false;
        this.quotationEquipments = [];
        this.quotationEquipments = await this.serviceCallQuotationEquipmentService.getRelatedEquipments(this.quotation.Id, this.quotation.ContractId, this.quotation.RequiredDate ? this.quotation.RequiredDate : this.quotation.Date);
        this.refreshRelatedEquipments = true;
    }

    public updateServiceQuotationEquipments(): void {
        if (!this.quotation) {
            return;
        }

        const onlySelectedRelatedEquipments = this.quotationEquipments.filter((equipment: ServiceCallQuotationRelatedEquipmentModel) => equipment.Selected);
        const quotationsEquipments = this.serviceCallQuotationEquipmentService.buildQuotationEquipmentsFromRelatedEquipments(onlySelectedRelatedEquipments);

        this.quotation.Equipments = quotationsEquipments;
    }

    public isDirty(): boolean {
        if (this.readonly) {
            return false;
        }
        if (!this.quotation) {
            return false;
        }

        this.updateServiceQuotationEquipments();

        if (!this.quotation.IsWorkOrder) {
            if (this.unmodifiedQuotation && this.unmodifiedQuotation!.AdditionalFields && this.unmodifiedQuotation!.AdditionalFields!.length > 0) {
                this.unmodifiedQuotation!.AdditionalFields!.forEach((field: any) => {
                    if (field.Definition.Type === AdditionalFieldType.Date) {
                        field.Value = field.Value && field.Value !== "" ? field.Value : null;
                    }
                });
            }

            if (this.quotation && this.quotation!.AdditionalFields && this.quotation!.AdditionalFields!.length > 0) {
                this.quotation!.AdditionalFields!.forEach((field: any) => {
                    if (field.Definition.Type === AdditionalFieldType.Date) {
                        field.Value = field.Value && field.Value !== "" ? field.Value : null;
                    }
                });
            }
        }
        
        const stringifyUnmodifiedQuotation = JSON.stringify(this.unmodifiedQuotation).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrentQuotation = JSON.stringify(this.quotation).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodifiedQuotation !== stringifyCurrentQuotation;
    }

    protected async getSourceDispatchId(quotationId: number): Promise<number> {
        return await this.serviceCallQuotationService.getSourceDispatchId(quotationId);
    }

    protected async initQuotation(quotationId: NavigationIdOrNew<number>, creationSource: CreationSource, isWorkOrder: boolean, sourceServiceCallId?: number, contractEquipmentId?: number, sourceDispatchId?: number, sourceQuotationId?: number, sourceWorkOrderId?: string): Promise<void> {
        this.userHasAccessTabItems = false;

        this.pagetitle = this.i18N.tr("pageTitle_Service_Quotation_Desktop");

        if (quotationId !== NavigationNew) {
            this.quotation = await this.fetchQuotation(Number(quotationId));

        } else {
            this.pagetitle = this.i18N.tr("NewQuotation");
            this.quotation = await this.serviceCallQuotationService.createNewQuotationDetails(this.initialQuotationStatus, creationSource, isWorkOrder, sourceServiceCallId, contractEquipmentId, sourceDispatchId, sourceQuotationId, sourceWorkOrderId);
        }

        if (this.quotation && this.quotation.ContractId && this.quotation.IsServiceContractNotFound) {
            const warning = this.i18N.tr("WarningQuotationServiceCallContractNotFound");
            this.notificationHelper.showWarning(warning.replace("{0}", this.quotation.ContractId.toString()), "", { timeOut: 0 });
        }

        await this.initQuotationEquipments();

        if (quotationId !== NavigationNew) {
            this.updateServiceQuotationEquipments();
        }

        if (this.quotation.IsWorkOrder) {
            this.selectedProjectId = this.quotation.ProjectId;
            this.selectedProjectDescription = this.quotation.ProjectDescription;
        } else {
            this.selectedContractId = this.quotation.ContractId;
            this.selectedPricingType = this.quotation.IsLumpSum;
            this.lumpSumPlusProfitMargin = this.quotation.ProfitMargin;
        }

        this.filterContractsByPrefix = this.viewModeHelper.getIsDesktopMode() ? false : true;

        this.acceptedTypes = this.quotation.IsWorkOrder ? [LocationType.Alternative, LocationType.Customer, LocationType.Project] : [LocationType.Contract, LocationType.Alternative, LocationType.Contact, LocationType.Customer, LocationType.CustomerSite];

        if ((this.isResidential || this.isProjectInvoicingModeMultiCustomer) && this.quotation.Address && this.quotation.Address.Type === LocationType.Alternative) {
            this.alternativeAddress = this.quotation.Address;
        }

        this.unmodifiedQuotation = CloneHelper.deepClone(this.quotation);

        this.dispatchTemplate = await this.templateService.getTemplateConfigs(settingHelper.getSelectedDispatchModel());
    }

    private async fetchQuotation(quotationId: number): Promise<ServiceCallQuotationDetailsModel> {
        return await this.serviceCallQuotationService.getQuotation(quotationId, true, true, false, true, false);
    }

    private async confirmDelete(): Promise<boolean> {
        return await this.notificationHelper.showConfirmationKey("msg_DeleteEntryConfirmationText");
    }
}
