import { autoinject, customElement } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { FileInfo } from "services/file-service";

@autoinject()
@customElement("file-thumbnail-list")
export class FileThumbnailList {
    @bindable.none
    public files: FileInfo[] = [];
    
    constructor(private readonly element: Element) { }

    public toggleFileSelected(file: FileInfo): void {
        file.selected = !file.selected;

        CustomEventHelper.dispatchEvent(this.element, "file-selected-changed", file);
    }
}
