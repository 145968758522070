import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { DailyEntrySmsHistoryProxy } from "api/proxies/daily-entry-sms-history-proxy";
import { SmsMessageModel } from "api/models/company/s-m-s/sms-message-model";

@autoinject()
export class DailyEntrySmsHistoryService {

    constructor(private readonly dailyEntrySmsHistoryProxy: DailyEntrySmsHistoryProxy) {
    }

    public async GetSmsMessagesHistory(projectDispatchCode: string | null, dispatchDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<SmsMessageModel[] | null> {
        return this.dailyEntrySmsHistoryProxy.GetSmsMessagesHistory(projectDispatchCode, dispatchDate, filter, pagingInfo, requestConfig);
    }
}
