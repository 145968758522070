import { default as routerHelper } from "helpers/routerHelper";
import { default as purchaseOrderService } from "services/purchaseOrderService"
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { observable, computedFrom, inject } from "aurelia-framework";
import { DispatchProjectService } from "services/dispatch-project-service";
import { I18N } from "aurelia-i18n";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

function setSpecificFields(item) {
    return item;
}

@inject(I18N, DispatchProjectService, NotificationHelper, Router)
export default class MaterialOrderEdit extends FormBase {

    @observable dispatchProjectCode = "";
    @observable dispatchDate = "";
    @observable projectCode = "";
    @observable orderId = "";
    @observable extension = "";
    isDirty = false;

    @computedFrom("dispatchDate")
    get documentUrl() {
        const queryString = routerHelper.addQuerystring({
            readonly: false,
            dispatchProjectDate: this.dispatchDate
        });

        return `${routerHelper.getRelativeUrl("document")}${queryString}`;
    }

    constructor(i18n, dispatchProjectService, notificationHelper, router) {
        super(notificationHelper, i18n, router);
        this.isProjectResponsible = false;
        this.readonly = false;
        this.dispatchProjectService = dispatchProjectService;
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        return this.isDirty;
    }

    async canActivate(params) {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                this.notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                this.notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    activate(params) {
        this.dispatchProjectCode= params.dispatchProjectCode;

        this.orderId = params.orderId ? params.orderId : "-1";
        this.extension = params.extension ? params.extension : "";

        if (params.q) {
            const qsParams = routerHelper.getQuerystring(params.q);
            if (qsParams) {
                this.readonly = queryStringHelper.parseReadonly(params.q);
            }

            if (qsParams && qsParams.dispatchDate) {
                this.dispatchDate = qsParams.dispatchDate;
            }

            if (qsParams && qsParams.projectCode) {
                this.projectCode= qsParams.projectCode;
            }

            if (qsParams && qsParams.extension) {
                this.extension = qsParams.extension ? qsParams.extension : "";
            }

        }

        this.actions = {
            getPurchaseOrder: purchaseOrderService.getPurchaseOrder.bind(this, this.orderId, this.extension),
            savePurchaseOrderService: purchaseOrderService.setPurchaseOrderHeaderForProject.bind(this, this.projectCode),
            setSpecificFields: setSpecificFields
        };
    }

    async checkIsProjectActive() {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}