// GENERATED FILE - DO NOT MODIFY
import { WorkOrderLocationModel } from "api/models/company/work-order-location-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class WorkOrderAddressProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetWorkOrderAddress(workOrderId: string | null, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderLocationModel | null> {
        const uri = this.buildUri(
            "/workorders/{workOrderId}/address",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            null
        );

        const data: WorkOrderLocationModel | null = await this.get<WorkOrderLocationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.WorkOrderLocationModel");
        return data;
    }

    public async UpdateWorkOrderAddress(workOrderId: string | null, location: WorkOrderLocationModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/workorders/{workOrderId}/address",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            null
        );

        await this.post<WorkOrderLocationModel | null, void>(uri, location, requestConfig);
    }

    public async GetWorkOrderProjectAddress(workOrderId: string | null, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderLocationModel | null> {
        const uri = this.buildUri(
            "/workorders/{workOrderId}/projectaddress",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            null
        );

        const data: WorkOrderLocationModel | null = await this.get<WorkOrderLocationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.WorkOrderLocationModel");
        return data;
    }

    public async GetWorkOrderProjectAddressByProjectId(projectId: string | null, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderLocationModel | null> {
        const uri = this.buildUri(
            "/workorders/projectaddress",
            null,
            { projectId: (projectId === null || projectId === undefined) ? undefined : projectId }
        );

        const data: WorkOrderLocationModel | null = await this.get<WorkOrderLocationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.WorkOrderLocationModel");
        return data;
    }

    public async GetWorkOrderCustomerAddress(workOrderId: string | null, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderLocationModel | null> {
        const uri = this.buildUri(
            "/workorders/{workOrderId}/clientaddress",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            null
        );

        const data: WorkOrderLocationModel | null = await this.get<WorkOrderLocationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.WorkOrderLocationModel");
        return data;
    }

    public async GetWorkOrderCustomerAddressByCustomerCode(customerCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderLocationModel | null> {
        const uri = this.buildUri(
            "/workorders/clientaddress",
            null,
            { customerCode: (customerCode === null || customerCode === undefined) ? undefined : customerCode }
        );

        const data: WorkOrderLocationModel | null = await this.get<WorkOrderLocationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.WorkOrderLocationModel");
        return data;
    }
}
