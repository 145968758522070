import { bindable, bindingMode } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

export abstract class PickerBase {
    public i18n: I18N;

    // ajax seems to keep the reference, we need to declare inside child.
    @bindable public abstract ajax: any = null;
    
    @bindable public disable: boolean = false;
    @bindable public renderAsButton: boolean = false;
    @bindable public showNotInCatalog: boolean = true;
    @bindable public allowClear: boolean = false;

    public templateSelection: any = this.tSelection;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedItem: any = null;

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }

    private tSelection(item: any): string {
        return `${item.data.Id} - ${item.data.CustomerId}`;
    }
}
