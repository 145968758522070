// GENERATED FILE - DO NOT MODIFY
import { ProjectConfigModel } from "api/models/company/project/project-config-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProjectConfigProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetConfig(requestConfig?: HttpClientRequestConfig): Promise<ProjectConfigModel | null> {
        const uri = this.buildUri(
            "/projects/config",
            null,
            null
        );

        const data: ProjectConfigModel | null = await this.get<ProjectConfigModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectConfigModel");
        return data;
    }
}
