import { singleton } from "aurelia-framework";

@singleton()
export default class ApplicationContextRepository {
    applicationContext = {};

    constructor(){

    }
    
    get userOptions(){
        return this.applicationContext.userOptions || null;
    }

    set userOptions(value){
        this.applicationContext.userOptions = value;
    }    
}