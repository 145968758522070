import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { RouteViewModel } from "core/route-view-model";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import UserAccessService from "services/user-access-service";
import enumHelper from "helpers/enumHelper";
import { ViewModeHelper } from "helpers/view-mode-helper";

export interface QuotationListParameters {
    sourceServiceCallId?: string;
    dispatchId?: number; // Présent seulement quand on accède à la liste des soumissions à partir d'une répartition dans mobile.
    sourceWorkOrderId?: string;
}

export abstract class QuotationListBase implements RouteViewModel<QuotationListParameters> {
    public sourceServiceCallId: number | null = null;
    public sourceWorkOrderId: string | null = null;
    public dispatchId: number | null = null;
    public quotations: ServiceCallQuotationDetailsModel[] = [];
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;
    public serviceQuotationBTEnabled: boolean = false;
    public serviceQuotationASEnabled: boolean = false;

    protected constructor(
        protected readonly serviceCallQuotationService: ServiceCallQuotationService,
        protected readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        protected readonly userAccessService: UserAccessService,
        protected readonly viewModeHelper: ViewModeHelper
    ) {
    }

    public async activate(params: QuotationListParameters): Promise<void> {
        this.sourceServiceCallId = params.sourceServiceCallId ? Number(params.sourceServiceCallId) : null;
        this.sourceWorkOrderId = params.sourceWorkOrderId ? params.sourceWorkOrderId : null;
        this.dispatchId = params.dispatchId || null;
        this.serviceQuotationBTEnabled = this.viewModeHelper.getIsMobileMode() ? await this.userAccessService.isServiceQuotationBTOptionEnabledSync(enumHelper.userOptions.SERVICEQUOTATIONS) : await this.userAccessService.isServiceQuotationBTOptionEnabledSync(enumHelper.userOptions.SERVICEQUOTATIONSBTDESKTOP);
        this.serviceQuotationASEnabled = this.viewModeHelper.getIsMobileMode() ? this.userAccessService.isOptionEnabledSync(enumHelper.userOptions.SERVICEQUOTATIONS) : this.userAccessService.isOptionEnabledSync(enumHelper.userOptions.SERVICEQUOTATIONSASDESKTOP);

        await this.initSecuritySettings();
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }
}
