import { ReceiptOfGoodsService } from "services/receipt-of-goods-service";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";
import { ValidationHelper } from "helpers/validation-helper";

import routerHelper from "helpers/routerHelper";

import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { ReceiptOfGoodsSerialNoModel } from "api/models/company/receipt-of-goods-serial-no-model";

import { ReceiptOfGoodsItemModel } from "api/models/company/receipt-of-goods-item-model";
import RouteRepository from "repositories/routeRepository";
import { ReceiptOfGoodsQtyValidation } from "api/enums/receipt-of-goods-qty-validation";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

export interface ManageSerialNumbersParameters {
    receiptId: number;
    lineNo: number;
    productCode: string;
    validationMode: string;
    readonly: boolean;
}

@autoinject
export class ManageSerialNumbers extends FormBase {
    @bindable public cards: MaCardOptionsModel[] = [];

    public receiptItem: ReceiptOfGoodsItemModel | null = null;
    public unModified: ReceiptOfGoodsItemModel | null = null;
    public manageSerialNumbersParameters!: ManageSerialNumbersParameters;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router,
                private readonly validationHelper: ValidationHelper,
                private readonly receiptOfGoodsService: ReceiptOfGoodsService,
                private readonly routeRepository: RouteRepository) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        if (!this.receiptItem) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        const stringifyCurrent = JSON.stringify(this.receiptItem).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public activate(params: any): void {
        this.manageSerialNumbersParameters = {
             receiptId: params.receiptId,
             lineNo: params.lineNo,
             productCode: params.productCode,
             validationMode: params.validationMode,
             readonly: params.readonly === "true"
            };
    }

    public async bind(): Promise<void> {
        this.receiptItem = await this.receiptOfGoodsService.GetReceiptOfGoodsDetail(this.manageSerialNumbersParameters.receiptId!, this.manageSerialNumbersParameters.lineNo, this.manageSerialNumbersParameters.productCode);
        this.unModified = CloneHelper.deepClone(this.receiptItem);
        this.createCardListFromDatasource(this.receiptItem!.SerialNos!);
    }

    public add(): void {
        if (this.validateActiveCard()) {
            this.receiptItem!.SerialNos!.push({ SerialNo: "", ToDelete: false, TransactionNumber: 0 });
            this.refreshCards();
        }
    }

    public toggleDelete(serialNoItem: ReceiptOfGoodsSerialNoModel): void {
        if (serialNoItem.ToDelete && !this.validateActiveCard()) {
            return;
        }

        serialNoItem.ToDelete = !serialNoItem.ToDelete;
        if (this.isDeletable(serialNoItem)) {
            this.removeDeletedNewSerialItems();
        }

        this.refreshCards();
    }

    public async save(): Promise<void> {
        if (this.isDataValid()) {
            this.refreshCards();

            const hasItemMarkedForDeletion = this.receiptItem!.SerialNos!.some((serialNoItem: ReceiptOfGoodsSerialNoModel) => {
                return serialNoItem.ToDelete;
            });

            if (hasItemMarkedForDeletion) {
                const dialogMessage = this.i18n.tr("ReceiptOfGoodsConfirmDelete");

                this.notificationHelper.showDialogYesNo(dialogMessage).then(
                    async (success: boolean): Promise<any> => {
                        if (success) {
                            await this.receiptOfGoodsService.SaveReceiptOfGoodsDetail(this.receiptItem);
                            this.unModified = null;
                            routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.name, { receiptId: this.receiptItem!.receiptId });
                        }
                    }
                );
            } else {
                await this.receiptOfGoodsService.SaveReceiptOfGoodsDetail(this.receiptItem);
                this.unModified = null;
                routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.name, { receiptId: this.receiptItem!.receiptId });
            }
        } else {
            setTimeout(() => {
                this.refreshCards();
            }, 3000);
            this.notificationHelper.showError(this.i18n.tr("ReceiptOfGoodsEmptyItemsValidation"));
        }
    }

    private isDataValid(): boolean {
        let isDataValidFlag = true;

        this.cards.forEach((card: MaCardOptionsModel) => {
            if (card.model.SerialNo === "" && !card.model.ToDelete) {
                // Flags with a red border invalid cards
                card.class = "border border-danger";
                isDataValidFlag = false;
            }
        });

        return isDataValidFlag;
    }

    private isDeletable(item: ReceiptOfGoodsSerialNoModel): boolean {
        return item.ToDelete && item.TransactionNumber === 0 && this.validationHelper.stringIsNullOrEmpty(item.SerialNo);
    }

    private createCardListFromDatasource(datasource: Array<ReceiptOfGoodsSerialNoModel | null>): void {
        this.cards = [];
        datasource!.forEach((serialNoItem: ReceiptOfGoodsSerialNoModel): void => {
            this.cards.push(this.createCard(serialNoItem));
        });
        this.updateCardsVisual();
    }

    private createCard(serialNoItem: ReceiptOfGoodsSerialNoModel): MaCardOptionsModel {
        return new MaCardOptionsModel({
            model: serialNoItem,
            displayLines: [
                new MaCardLineModel({
                    display: serialNoItem.SerialNo!,
                    class: "font-weight-bold",
                    isCustomTemplate: true,
                }),
            ],
            actionItems: [
                new MaCardActionModel({
                    action: this.toggleDelete.bind(this, serialNoItem),
                }),
            ],
        });
    }

    private removeDeletedNewSerialItems(): void {
        this.receiptItem!.SerialNos! = this.receiptItem!.SerialNos!.filter((serialNoItem: ReceiptOfGoodsSerialNoModel) => {
            return !this.isDeletable(serialNoItem);
        });
    }

    private refreshCards(): void {
        if (this.receiptItem!.SerialNos!.length !== this.cards.length) {
            this.createCardListFromDatasource(this.receiptItem!.SerialNos!);
        } else {
            this.updateCardsVisual();
        }
    }

    private updateCardsVisual(): void {
        this.cards.forEach((card: MaCardOptionsModel) => {
            if (card.model.ToDelete) {
                card.class = "alert-danger";
                card.actionItems![0].icon = "fa fa-undo text-danger";
            } else if (!this.manageSerialNumbersParameters.readonly) {
                card.class = "";
                card.actionItems![0].icon = "fa fa-trash text-danger";
            }
        });
    }

    private validateActiveCard(): boolean {
        const activeCards = this.receiptItem!.SerialNos!.filter((serialNo: ReceiptOfGoodsSerialNoModel) => !serialNo.ToDelete).length;

        const isShowingWarning = this.manageSerialNumbersParameters.validationMode === ReceiptOfGoodsQtyValidation.Block ||
                                 this.manageSerialNumbersParameters.validationMode === ReceiptOfGoodsQtyValidation.Validate;

        const isOverQuantity = activeCards >= this.receiptItem!.InventoryQtyToReceive ||
                               activeCards >= this.receiptItem!.SupplierQtyToReceive;

        if (this.receiptItem!.AddedByUser) { return true; }

        if (isOverQuantity && isShowingWarning) {
            switch (this.manageSerialNumbersParameters.validationMode) {
                case ReceiptOfGoodsQtyValidation.Validate:
                    this.notificationHelper.showWarning(this.i18n.tr("ReceiptOfGoodsInvalidFieldValidate"), "", { timeout: 0});
                    break;

                    case ReceiptOfGoodsQtyValidation.Block:
                    this.notificationHelper.showWarning(this.i18n.tr("ReceiptOfGoodsInvalidFieldBlocked"), "", { timeout: 0});

                    return false;
            }
        }

        return true;
    }
}
