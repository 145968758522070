import { bindable, inject } from "aurelia-framework";

import { default as val } from "core/val";

@inject(Element)
export class AddAttributesCustomAttribute {
    @bindable key;
    element;

    constructor(element) {
        this.element = element;
    }

    keyChanged(newValue, oldValue) {
        if (!newValue) {
            return;
        }

        const attributes = val.get(newValue);

        if (!attributes || attributes === newValue) {
            return;
        }

        // Add the attributes to the HTML element.
        for (const property in attributes) {
            if (Object.prototype.hasOwnProperty.call(attributes, property)) {
                this.element.setAttribute(property, attributes[property]);
            }
        }
    }
}