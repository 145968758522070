// GENERATED FILE - DO NOT MODIFY
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { DocumentModel } from "api/models/company/document/document-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class InspectionSignatureProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async AddSignatureForInspection(inspectionId: number, signatureNo: number, model: DocumentModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/inspections/{inspectionId}/signature/{signatureNo}",
            { inspectionId: (inspectionId === null || inspectionId === undefined) ? undefined : inspectionId.toString(), signatureNo: (signatureNo === null || signatureNo === undefined) ? undefined : signatureNo.toString() },
            null
        );

        await this.post<DocumentModel | null, void>(uri, model, requestConfig);
    }

    public async GetContactsForSignature(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ContactLookupModel[] | null> {
        const uri = this.buildUri(
            "/inspections/signature/contacts",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ContactLookupModel[] | null = await this.get<ContactLookupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Contact.ContactLookupModel");
        return data;
    }
}
