import { I18N } from "aurelia-i18n";
import { computedFrom } from "aurelia-binding";
import { autoinject, PLATFORM } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as enumHelper } from "helpers/enumHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { TimesheetService } from "services/timesheet-service";

import "widgets/timesheetEmployeeHeader";
import "widgets/timesheetItem";

PLATFORM.moduleName("pages/templates/timesheet_ListForTeamLeader.html");
PLATFORM.moduleName("pages/templates/timesheet_ListForEmployee.html");

@autoinject
export class TimesheetIndex {

    public cache: any = {};
    public currentData: any = {};

    public type: string = this.timesheetService.PAGE_TYPE_INDEX;
    public employee: any;
    public isTeamLeader: boolean = false;
    public linkedEquipments: any[] = [];
    public listGroupShown: string = this.timesheetService.LIST_GROUP_TYPE_EMPLOYEE;
    public listPeriodShown: string = this.timesheetService.LIST_SHOW_CURRENT;
    public teamLeaderIsResponsibleForTimeEntry: boolean = true;
    public loadCurrentEmployeeOnly: boolean = false;
    public context: any = this;

    @computedFrom("currentData")
    public get list(): any {
        return this.currentData.list;
    }

    @computedFrom("currentData")
    public get timeSheetId(): any {
        return this.currentData.TimeSheetId;
    }

    @computedFrom("currentData")
    public get payPeriodStartDate(): any {
        return this.currentData.PayPeriodStartDate;
    }

    @computedFrom("currentData")
    public get timeSheetStatus(): string {
        return this.currentData.TimeSheetStatus;
    }

    @computedFrom("currentData")
    public get payPeriodIsReadonly(): boolean {
        return this.currentData.PayPeriodIsReadonly;
    }

    @computedFrom("currentData")
    public get readonly(): boolean {
        if (this.isTeamLeader) {
            return this.currentData.PayPeriodIsReadonly;
        }

        //Current employee ISN'T A TEAM LEADER

        // Current user is a NOT responsible for time entry.
        // Only the teamleader should create/edit entries.
        if (this.teamLeaderIsResponsibleForTimeEntry) {
            return true;
        }

        // Regular employee can only edit INPROGRESS timesheets.
        // They can't edit COMPLETED or APPROVED timesheets.
        const timesheetInProgress = this.currentData.TimeSheetStatus === null ||
        this.currentData.TimeSheetStatus === enumHelper.timesheetStatus.INPROGRESS.id;
        return !timesheetInProgress;
    }
    // public readonly: boolean = true;

    constructor(public readonly i18n: I18N, public readonly timesheetService: TimesheetService) {
    }

    public async activate(params: any): Promise<void> {
        this.clearCache();
        await this.loadData();
    }

    public async bind(): Promise<any> {
        this.context = this;
    }

    public showPeriod(period: string): void {
        this.listPeriodShown = period;

        this.loadData();
    }

    public showGroup(group: string): void {
        this.listGroupShown = group;

        this.loadData();
    }

    public toggleOpenSection(data: any): void {
        data.isOpened = !data.isOpened;
    }

    public async loadData(): Promise<any> {
        const period: string = this.listPeriodShown;
        const groupType: string = this.listGroupShown;

        if (period && groupType && this.cache[period][groupType]) {
            this.currentData = this.cache[period][groupType];
        }

        await this.timesheetService.loadList(this.loadCurrentEmployeeOnly, groupType, period).then((result: any) => {
            this.isTeamLeader = result.CurrentEmployeeIsTeamLeader;
            this.teamLeaderIsResponsibleForTimeEntry = result.TeamLeaderIsResponsibleForTimeEntry;
            this.employee = result.CurrentEmployee;
            this.linkedEquipments = result.LinkedEquipments;

            if (this.loadCurrentEmployeeOnly) {
                result.list = result.Groups;
            } else {
                result.list  = _.map(result.Groups, (x: any) => this.timesheetService.mapFirstLevelGroup(this, x, groupType));
            }

            this.cache[period][groupType] = result;
            this.currentData = result;
        });
    }

    public genUrl(route: string, timesheetId?: number, timesheetEntryId?: number): string {
        let url = "";
        if (timesheetId) {
            url = routerHelper.getRelativeUrl(route, timesheetId, timesheetEntryId);
        } else {
            url = routerHelper.getRelativeUrl(route);
        }

        return url + routerHelper.addQuerystring({
                    CurrentEmployeeIsTeamLeader: this.isTeamLeader,
                    TeamLeaderIsResponsibleForTimeEntry: this.teamLeaderIsResponsibleForTimeEntry,
                    PayPeriodStartDate: this.currentData.PayPeriodStartDate,
                    Employee: JSON.stringify(this.employee),
                    LinkedEquipments: JSON.stringify(this.linkedEquipments),
                    ListPeriodShown: this.listPeriodShown
                });
    }

    public goToUrl(route: string, timesheetId?: number, timesheetEntryId?: number): void {
        routerHelper.navigate(this.genUrl(route, timesheetId, timesheetEntryId));
    }

    private clearCache(): void {
        this.cache = {};
        this.cache[this.timesheetService.LIST_SHOW_CURRENT] = {};
        this.cache[this.timesheetService.LIST_SHOW_PAST] = {};

        this.currentData = { list: [] };
    }
}
