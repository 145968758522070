export class ExpandableButtonOptions {
  public mainButtonIcon?: string;

  public subButtons?: ExpandableButtonOptionsSubButtonModel[] = [];

  constructor(expandableButtonOptions?: ExpandableButtonOptions) {

    Object.assign(this, expandableButtonOptions);
    }
}

export class ExpandableButtonOptionsSubButtonModel {
  public icon?: string;

  public text?: string;
  public action?: () => any;
  public isCustomTemplate?: boolean = false;

  constructor(expandableButtonOptionsSubButtonModel?: ExpandableButtonOptionsSubButtonModel) {
        this.isCustomTemplate = false;

        Object.assign(this, expandableButtonOptionsSubButtonModel);
    }
}
