declare var CONFIG_DEBUG: boolean;
declare var CONFIG_ENVIRONMENT: string;
declare var CONFIG_DEFAULT_CULTURE: string;
declare var CONFIG_FALLBACKCULTURE: string;
declare var CONFIG_API_BASE_URL: string;
declare var FROALAEDITORKEY: string;
declare var AGGRIDLICENSEKEY: string;
declare var DESKTOP_VIEWMODE_VIEWPORT_WIDTH: number;
declare var HELPURLMOBILE: string;
declare var HELPURLBI: string;
declare var GEOLOCATION_TIMEOUT: number;
declare var GEOLOCATION_MAXIMUM_AGE: number;
declare var DEFAULT_REQUEST_TIMEOUT: number;
declare var DEFAULT_REQUEST_PAGE_SIZE: number;
declare var SELECT2_MINIMUM_ITEMS_FOR_SEARCH: number;
declare var DYNAMSOFT_LICENSE_KEY: string;
declare var MAESTRO_API_BASE_URL: string;
declare var MAESTRO_API_PRIVATE_GATEWAY_BASE_URL: string;

class Config {
    public debug: boolean = CONFIG_DEBUG;
    public environment: string = CONFIG_ENVIRONMENT;

    public defaultCulture: string = CONFIG_DEFAULT_CULTURE;
    public fallbackCulture: string = CONFIG_FALLBACKCULTURE;

    public apiBaseUrl: string = CONFIG_API_BASE_URL;
    public froalaEditorKey: string = FROALAEDITORKEY;
    public agGridLicenceKey: string = AGGRIDLICENSEKEY;
    public desktopViewModeViewportWidth: number = DESKTOP_VIEWMODE_VIEWPORT_WIDTH;
    public helpUrlMobile: string = HELPURLMOBILE;
    public helpUrlBI: string = HELPURLBI;
    public geolocationTimeout: number = GEOLOCATION_TIMEOUT;
    public geolocationMaximumAge: number = GEOLOCATION_MAXIMUM_AGE;

    public defaultRequestTimeout: number = DEFAULT_REQUEST_TIMEOUT;
    public defaultRequestPageSize: number = DEFAULT_REQUEST_PAGE_SIZE;
    public select2MinimumItemsForSearch: number = SELECT2_MINIMUM_ITEMS_FOR_SEARCH;
    public dynamsoftLicenseKey: string = DYNAMSOFT_LICENSE_KEY;

    public maestroAPIBaseUrl: string = MAESTRO_API_BASE_URL;
    public maestroAPIPrivateGatewayBaseUrl: string = MAESTRO_API_PRIVATE_GATEWAY_BASE_URL;

}

export default new Config();
