import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class RouterHelper {
    public generate(name: string, params?: any, options?: any): string {
        return routerHelper.generate(name, params, options);
    }

    public navigateBack(): void {
        return routerHelper.navigateBack();
    }

    public navigateToRoute(route: string, params?: any, options?: any): Promise<void> {
        return routerHelper.navigateToRoute(route, params, options);
    }

    public navigateSelf(): void {
        return routerHelper.navigateSelf();
    }

    public hideLoading(forceHide?: boolean): void {
        routerHelper.hideLoading(forceHide);
    }

    public showLoading(): void {
        return routerHelper.showLoading();
    }

    public async waitFor<T>(action: Promise<T>): Promise<T> {
        this.showLoading();

        try {
            return await action;
        } finally {
            this.hideLoading();
        }
    }

    public buildQueryString(qryString?: any): string {
        return routerHelper.buildQueryString(qryString);
    }

    public getQuerystring(qryString?: any): any {
        return routerHelper.getQuerystring(qryString);
    }

    public buildMixedRouteParameters(routeParameters: {}, legacyParametersToEncode: {}): any {
        return routerHelper.buildMixedRouteParameters(routeParameters, legacyParametersToEncode);
    }

    public mapAddress(address: any): any {
        return "//maps.apple.com/?q=" + encodeURIComponent(address);
    }
}
