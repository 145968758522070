import { default as _ } from "underscore";

import { MultiSelectItem, SelectItem } from "models/select-item";

function buildAjaxData(maSelect, params) {
    //hack for filterSelected
    var filter = params.term ? params.term : "";
    var filterSelected = "";

    if (maSelect.filterSelected) {
        filterSelected = maSelect.filterSelected;
    }

    return {
        filter: encodeURIComponent(filter),
        page: params.page,
        filterSelected: filterSelected,
        filterChecked: maSelect.checkBoxFilter
    };
}

function processResults(maSelect, data, params) {
    if (!params || (!params.term && params.page <= 1)) {
        // Enable search only if there is more than x items
        maSelect.searchEnabled =
            data.length >= maSelect.minimumResultsForSearch;
    }

    params.page = params.page || 1;

    data = _.map(data, function(item) {
        var processedItem = maSelect.ajaxOptions.mapResults(item);

        if (maSelect.multiSelect === false) {
            return SelectItem.fromIdText(processedItem);
        }

        processedItem = MultiSelectItem.fromIdText(processedItem);

        processedItem.selected = _.any(maSelect.selectedItem, selectedItem => {
            return selectedItem.id === processedItem.id;
        });

        return processedItem;
    });

    return {
        results: data,
        pagination: { more: data.length >= maSelect.pageSize }
    };
}

function defaultMapResults(item) {
    return {
        id: item.Id,
        text: item.Description ? item.Id + " - " + item.Description : item.Id
    };
}

function fetchData(maSelect, params, callback) {
    var options = _.extend(
        {
            type: "GET"
        },
        maSelect.ajaxOptions
    );

    if (_.isFunction(options.url)) {
        options.url = options.url.call(maSelect, params);
    }

    if (_.isFunction(options.data)) {
        options.data = options.data.call(maSelect, params);
    }

    options.transport(
        options,
        data => {
            var results = maSelect.ajaxOptions.processResults(data, params);
            callback(results);
        },
        error => {
            throw new Error(error || "Error during transport.");
        }
    );
}

function initAjax(maSelect, ajax) {
    var defaultAjax = {
        data: function(params) {
            return buildAjaxData(maSelect, params);
        },
        processResults: function(data, params) {
            return processResults(maSelect, data, params);
        },
        mapResults: defaultMapResults
    };

    _.extend(defaultAjax, ajax);

    maSelect.ajaxOptions = defaultAjax;
}

function loadMoreItems(maSelect, clear) {
    if (maSelect.loadMoreItemsPromise) {
        maSelect.loadMoreItemsPromise.cancel();
    }

    const p = new Promise((resolve, reject, onCancel) => {
        let isPromiseCancelled = false;

        onCancel(() => {
            // TODO ABORT REQUEST
            isPromiseCancelled = true;
        });

        if (clear) {
            maSelect.page = 1;
            maSelect.items = [];
        }

        var params = {
            page: maSelect.page,
            term: maSelect.searchInput
        };

        fetchData(maSelect, params, function(results) {
            if (isPromiseCancelled) {
                return;
            }
            maSelect.hasMoreResults = results.pagination.more;
            maSelect.addItems(results.results);
            maSelect.page += 1;

            maSelect.loadMoreItemsPromise = null;

            return resolve(results);
        });
    });

    maSelect.loadMoreItemsPromise = p;
    return p;
}

export default { initAjax, loadMoreItems };
