define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {        
        getForDailyEntry: (dispatchProjectId, dispatchDate) => {
            let url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectId, dispatchDate);
            url += '/equipmentcounters';
            return requests.getJson(url).promise();
        },

        saveForDailyEntry: (dispatchProjectId, dispatchDate, equipments) => {
            let url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectId, dispatchDate);
            url += '/equipmentcounters';

            return requests.post(url, equipments).promise();
        }
    };

    return exports;
});
