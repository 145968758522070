import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import { Viewbag } from "helpers/view-bag";
import dailyEntryService from "services/dailyEntryService";
import _ from "underscore";

@autoinject
export class DailyEntryBreakEntry {

    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public includeBreaks: boolean = false;
    public includeLunch: boolean = false;
    public list: any[] = [];
    public manualBreaksManagementEnabled: boolean = false;

    constructor(private readonly i18n: I18N, public viewbag: Viewbag) {

    }

    public async activate(params: any): Promise<any> {
        this.manualBreaksManagementEnabled = params.manualBreaksManagementEnabled;
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        await this.loadData(params.dispatchProjectCode, params.dailyEntryDate);
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: string, querystring: string): void {
        this.manualBreaksManagementEnabled = queryStringHelper.parseManualBreaksManagementEnabled(querystring);
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
        this.readonly = queryStringHelper.parseReadonly(querystring);
        const qryString = routerHelper.getQuerystring(querystring);

        this.includeLunch = qryString.lunchSelected === "true";
        this.includeBreaks = qryString.breakSelected === "true";

        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
    }

    public async loadData(dispatchProjectCode: string, dispatchDate: string): Promise<any> {
        if (this.viewbag && this.viewbag.value && this.viewbag.value.dispatchProjectCode === dispatchProjectCode && this.viewbag.value.dispatchDate === dispatchDate) {
            this.list = this.viewbag.value.list;
        } else {
            await dailyEntryService.getBreakTimes(dispatchProjectCode, dispatchDate, this.includeLunch, this.includeBreaks).then((data: any) => {
                this.list = this.mapDataFromApi(data);
            });
        }
    }

    public setViewBag(): void {
        this.viewbag.value = {
            dispatchProjectCode: this.dispatchProjectCode,
            dispatchDate: this.dispatchDate,
            list: this.list
        };
    }

    public edit(id: any): void {
        this.setViewBag();
        routerHelper.navigate(routerHelper.getRelativeUrl("edit", id) + routerHelper.addQuerystring({
            isProjectResponsible: this.isProjectResponsible,
            readonly: this.readonly,
            manualBreaksManagementEnabled: this.manualBreaksManagementEnabled
        }));
    }

    public save(): void {
        dailyEntryService.complete(this.dispatchProjectCode, this.dispatchDate, false, this.mapDataToPost()).then(() => {
            this.viewbag.value = null;
            routerHelper.navigateBack();
        });
    }

    public getActivitiesFromData(projects: any[]): any[] {
        const activities: any = [];

        projects.forEach((project: any) => {
            activities.push(...project.TimeEntries.map((timeEntry: any) => {
                return {
                    code: timeEntry.ActivityCode,
                    description: this.getDescriptionDisplay(timeEntry),
                    startTimeDisplay: dateHelper.getTime(timeEntry.StartDateTime),
                    endTimeDisplay: dateHelper.getTime(timeEntry.EndDateTime),
                    startTime: timeEntry.StartDateTime,
                    endTime: timeEntry.EndDateTime,
                    isNewLunch: timeEntry.IsNewLunch,
                    isNewBreak: timeEntry.IsNewBreak,
                    isDeleted: timeEntry.IsDeletedForLunch,
                    projectCode: timeEntry.ProjectCode,
                    defaultBonuses: timeEntry.DefaultBonuses,
                    breakNo: timeEntry.BreakNo,
                    breakTaken: timeEntry.BreakTaken,
                    wageType: timeEntry.WageType
                };
            }));
        });

        return activities;
    }

    public getDescriptionDisplay(timeEntry: any): string {
        let display = "";
        if (timeEntry.IsNewLunch) {
            display = this.i18n.tr("Lunch");
        } else if (timeEntry.ActivityCode === "PAUSE") {
            display = this.i18n.tr("Pause");
        } else {
            display = timeEntry.ActivityDescription;
        }

        return `${display} ${labelHelper.getBreakAndLunchLabel(timeEntry.BreakNo, timeEntry.BreakTaken, timeEntry.IsLunch)}`;
    }

    public mapDataFromApi(data: any): any {
        return data.map((employee: any) => {
            return {
                dispatchId: _.first(employee.Projects).DispatchId,
                resourceId: employee.EmployeeNumber > 0 ? employee.EmployeeNumber : employee.EquipmentCode,
                resourceDesc: employee.EmployeeNumber > 0 ? (employee.EmployeeFirstName + " " + employee.EmployeeLastName) : employee.EquipmentDescription,
                activities: this.getActivitiesFromData(employee.Projects)
            };
        });
    }

    public mapDataToPost(): any[] {
        const result: any[] = [];

        this.list.forEach((employee: any) => {
            employee.activities.forEach((activity: any) => {
                if ((activity.isNewLunch || activity.isNewBreak) && !activity.isDeleted) {
                    result.push({
                            TimeEntryId: activity.TimeEntryId,
                            DispatchId : employee.dispatchId,
                            DispatchProjectCode : this.dispatchProjectCode,
                            ActivityCode : activity.code,
                            StartTime : dateHelper.getTime(activity.startTime),
                            EndTime : dateHelper.getTime(activity.endTime),
                            ProjectCode : activity.projectCode,
                            IsNewLunch : activity.isNewLunch,
                            IsNewBreak : activity.isNewBreak,
                            BreakNo : activity.breakNo,
                            BreakTaken : activity.breakTaken,
                            WageType : activity.wageType,
                            DefaultBonuses : activity.defaultBonuses
                    });
                }
            });
        });

        return result;
    }

}
