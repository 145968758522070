import { inject } from "aurelia-framework";

import { default as apiHelper } from "helpers/apiHelper";
import { default as requests } from "services/requests";

@inject(requests, apiHelper)
export default class UserOptionsProxy {
    constructor(requests){
        // TODO JL Use new es6 request class when available.
        this.requests = requests;
    }

    async get() {
        return await requests.getJson(apiHelper.getBaseUrl() + '/useroptions').promise();
    }

    async getForCompany(company) {
        return await requests.getJson(apiHelper.getBaseUrl(company) + '/useroptions').promise();
    }
}