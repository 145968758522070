// GENERATED FILE - DO NOT MODIFY
export enum ProcoreDirectCostTransactionType {
    Unclassified = 0,
    Purchases = 1,
    HoursAndLabor = 2,
    ProjectToProjectTransfers = 3,
    OrderInvoice = 4,
}

export let nameof = "ProcoreDirectCostTransactionType";
