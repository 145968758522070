import { ProcoreConfigurationProxy } from "api/proxies/procore-configuration-proxy";
import { ProcoreConnectionInfoModel } from "api/models/company/procore/procore-connection-info-model";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { autoinject } from "aurelia-framework";
import { ProcoreJobProxy } from "api/proxies/procore-job-proxy";
import { JobProxy } from "api/proxies/job-proxy";
import { ProcoreJobStatus } from "api/models/procore/job_-system/procore-job-status";
import { ProcoreConnectToProcoreResult } from "api/models/company/procore/procore-connect-to-procore-result";
import { ProcoreCompanyModel } from "api/models/procore/company/procore-company-model";

@autoinject()
export class ProcoreConfigurationService {
    constructor(private readonly procoreConfigurationProxy: ProcoreConfigurationProxy,
                private readonly procoreJobProxy: ProcoreJobProxy) { }

    public async GetProcoreConfiguration(): Promise<ProcoreCompanyConfigurationModel | null> {
        return await this.procoreConfigurationProxy.GetProcoreConfiguration();
    }

    public async SaveProcoreConfiguration(configuration: ProcoreCompanyConfigurationModel | null): Promise<ProcoreCompanyConfigurationModel | null>  {
        return await this.procoreConfigurationProxy.SaveProcoreConfiguration(configuration);
    }

    public async GetProcoreCompanies(authorizationCode: string, authorizationCodeRedirectUrl: string): Promise<ProcoreCompanyModel[] | null> {
        return await this.procoreConfigurationProxy.GetProcoreCompanies(authorizationCode, authorizationCodeRedirectUrl);
    }

    public async GetAllJobStatuses(): Promise<ProcoreJobStatus[] | null> {
        return await this.procoreJobProxy.GetAllJobStatuses();
    }

    public async ConnectToProcore(): Promise<ProcoreConnectToProcoreResult | null> {
        return await this.procoreConfigurationProxy.ConnectToProcore();
    }
}
