export default class BaseCellRenderer {
    // Gets called once before the renderer is used
    init(params) {
        
    }

    // Any cleanup we need to be done here
    destroy() {
       
    }

    // Gets called once when grid ready to insert the element
    getGui() {
        throw "NotYetImplementedException";
    }   

    refresh() {
        
    }
}

