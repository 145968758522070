import { inject, observable, bindable, computedFrom } from "aurelia-framework";
import { ValidationRules, ValidationControllerFactory, validateTrigger } from "aurelia-validation";

import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";

import { default as enumHelper } from "helpers/enumHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as notifier } from "helpers/notificationHelper";

import { default as purchaseOrderService } from "services/purchaseOrderService";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
import { UnitPriceType } from "api/enums/unit-price-type";
import { CatalogItemPriceService } from "services/catalog-item-price-service"
import { Key } from "ts-keycode-enum";
import val from "core/val";
import { NumberHelper } from "helpers/number-helper";

const defaultScaleFactor = 7;

@inject(ValidationControllerFactory, I18N, InventoryProjectTransferService, CatalogItemPriceService)
export class MaterialOrderItemCustomElement {

    unitDescription = null;
    supplierUnitDescription = null;
    supplierFactor = 1;
    inventoryFactor = 1;
    discountPercent = 0;

    activities = null;
    groups = null;
    selectedGroup = null;
    selectedActivity = null;
    purchaseOrder = null;
    purchaseOrderItem = null;
    enumHelper = enumHelper;

    @bindable orderId;
    @bindable extension = "";
    @bindable catalogBaseUrl;
    @bindable lineNo = 0;
    @bindable showNotInCatalog = false;
    @bindable actions;
    @bindable readonly = false;
    @bindable projectId = null;

    @observable description = "";
    @observable description2 = "";
    @observable description3 = "";
    @observable selectedItem;
    @observable selectedQuantityMode;
    @observable quantity = 1;
    @observable supplierQuantity = 1;
    @observable unitPrice = 0;
    @observable supplierUnitPrice = 0;

    @observable inventoryScaleFactor = defaultScaleFactor;
    @observable supplierScaleFactor = defaultScaleFactor;

    @computedFrom("readonly", "isNew", "showNotInCatalog")
    get catalogPickerDisabled() {
        return this.readonly || (!this.isNew && this.showNotInCatalog);
    }

    @computedFrom("lineNo")
    get isNew() {
        if (!this.lineNo) { return true; }
        return false;
    }

    constructor(validationControllerFactory, i18n, InventoryProjectTransferService, catalogItemPriceService) {
        this.descriptionMaxLength = val.get('materialBillingEdit.description', 'maxLength');
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.manual;
        this.showCodeInput = false;
        this.outCatalogCode = "";

        this.i18n = i18n;
        this.inventoryProjectTransferService = InventoryProjectTransferService;
        this.catalogItemPriceService = catalogItemPriceService;

        //Validationrules
        ValidationRules
            .ensure(x => x.quantity).satisfies(value => value !== 0).withMessageKey("err_QuantityRequired")
            .ensure(x => x.selectedItem).required().withMessageKey("err_ItemRequired")
            .ensure(x => x.description).required().withMessageKey("err_DescriptionRequired")
            .on(this);
    }

    valueChanged() {
        if (this.selectedQuantityMode.id === enumHelper.QuantityMode().inventory.id) {
            this.SetSupplierQuantity();
        } else {
            this.SetInventoryQuantity();
        }
    }

    SetSupplierQuantity() {
        if (this.supplierFactor === 0) {
            this.supplierFactor = 1;
        }

        if (this.inventoryFactor === 0) {
            this.inventoryFactor = 1;
        }

        this.supplierQuantity = NumberHelper.fixDecimalTo(this.quantity * (this.supplierFactor / this.inventoryFactor), this.supplierScaleFactor);
    }

    SetInventoryQuantity() {
        if (this.supplierFactor === 0) {
            this.supplierFactor = 1;
        }

        if (this.inventoryFactor === 0) {
            this.inventoryFactor = 1;
        }

        this.quantity = NumberHelper.fixDecimalTo(this.supplierQuantity * (this.inventoryFactor / this.supplierFactor), this.inventoryScaleFactor);
    }

    unitPriceChanged(newValue, oldValue) {
        if (this.supplierFactor === 0) {
            this.supplierFactor = 1;
        }

        if (newValue !== oldValue) {
            this.supplierUnitPrice = this.unitPrice  / this.supplierFactor;
        }
    }

    supplierUnitPriceChanged(newValue, oldValue) {
        if (this.supplierFactor === 0) {
            this.supplierFactor = 1;
        }

        if (newValue !== oldValue) {
            this.unitPrice = this.supplierUnitPrice * this.supplierFactor;
        }
    }

    async selectedItemChanged(newValue, oldValue) {
        this.showCodeInput = false;
        if (this.selectedItem === undefined) {
            this.description = "";
            this.description2 = "";
            this.description3 = "";
            this.unitPrice = 0;
            this.supplierFactor = 1;
            this.supplierUnitPrice = 0;
            this.inventoryFactor = 1;
            this.discountPercent = 0;

            this.inventoryScaleFactor = defaultScaleFactor;
            this.supplierScaleFactor = defaultScaleFactor;
        } else if (this.selectedItem.id === "!") {
            this.description = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : this.selectedItem.Description) : this.selectedItem.Description;
            this.description2 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : this.selectedItem.Description2) : this.selectedItem.Description2;
            this.description3 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : this.selectedItem.Description3) : this.selectedItem.Description3;
            this.unitPrice = 0;
            this.supplierFactor = 1;
            this.supplierUnitPrice = 0;
            this.inventoryFactor = 1;
            this.showCodeInput = true;
            this.discountPercent = 0;
            this.inventoryScaleFactor =  defaultScaleFactor;
            this.supplierScaleFactor =  defaultScaleFactor;
        } else {
            this.description = (this.selectedItem.data ? this.selectedItem.data.Description1 : this.selectedItem.Description);
            this.description2 = (this.selectedItem.data ? this.selectedItem.data.Description2 : this.selectedItem.Description2);
            this.description3 = (this.selectedItem.data ? this.selectedItem.data.Description3 : this.selectedItem.Description3);

            this.inventoryScaleFactor = this.selectedItem.data ? this.selectedItem.data.InventoryScaleFactor : defaultScaleFactor;
            this.supplierScaleFactor = this.selectedItem.data ? this.selectedItem.data.SupplierScaleFactor : defaultScaleFactor;

            if (this.purchaseOrderItem === null || this.purchaseOrderItem.Code !== this.selectedItem.id) {
                if (this.purchaseOrderItem !== null) {
                    this.purchaseOrderItem.Code = this.selectedItem.id;
                }
                this.supplierFactor = 1;
                this.inventoryFactor = 1;
                if (this.selectedItem.data) {
                    if (this.selectedItem.data.SupplierFactor) {
                        this.supplierFactor = this.selectedItem.data.SupplierFactor;
                    }
                    if (this.selectedItem.data.InventoryFactor) {
                        this.inventoryFactor = this.selectedItem.data.InventoryFactor;
                    }
                }
                if (this.selectedItem.id && this.purchaseOrder != null) {
                    const returnPrices = await this.catalogItemPriceService.getUnitPrice(this.selectedItem.id, UnitPriceType.Order, this.quantity, this.purchaseOrder.OrderDate, this.supplierFactor, this.inventoryFactor, this.purchaseOrder.SupplierCode, this.purchaseOrder.ProjectNo);
                    this.unitPrice = returnPrices.InventoryUnitPrice;
                    this.supplierUnitPrice = returnPrices.SupplierUnitPrice;
                    this.discountPercent = returnPrices.DiscountPercent;
                }
            }
        }

        if (newValue && newValue.data && this.projectId) {
            this.selectedActivity = this.createDefaultMaSelectElement(newValue.data.Activity, this.activities);
            this.selectedGroup = this.createDefaultMaSelectElement(newValue.data.CostGroup, this.groups);
        }

        this.valueChanged();
    }

    clearForm() {
        this.description = "";
        this.description2 = "";
        this.description3 = "";
        this.supplierFactor = 1;
        this.inventoryFactor = 1;
        this.quantity = 1;
        this.supplierQuantity = 1;
        this.selectedItem = undefined;
        this.unitPrice = 0;
        this.supplierUnitPrice = 0;
        this.discountPercent = 0;
    }

    async bind() {
        if (!this.lineNo) { this.lineNo = 0; }
        return await this.loadData();
    }

    lookupExpenseGroup = {
        transport: (params, success, failure) => {
            this.actions.getActivities(params.data.filter, params.data.page || 1).done(success).fail(failure);
        }
    }

    createDefaultMaSelectElement(id, list) {
        if (!id) { return null; }

        return list.find((item) => item.id === id);
    }

    async saveItem() {
        const result = await this.validationController.validate();

        if (result.valid) {

            if (this.supplierFactor === 0) {
                this.supplierFactor = 1;
            }

            routerHelper.showLoading();
            var item = {
                Code: this.selectedItem.id,
                Description: this.description,
                Line: this.lineNo,
                Quantity: this.quantity,
                OrderId: this.orderId,
                Extension: this.extension,
                OutCatalogCode: this.outCatalogCode,
                Activity: this.selectedActivity ? this.selectedActivity.id : "",
                Group: this.selectedGroup ? this.selectedGroup.id : "",
                UnitPrice: this.unitPrice,
                SupplierFactor: this.supplierFactor,
                SupplierUnitPrice: this.supplierUnitPrice,
                SupplierQuantity: this.supplierQuantity,
                DiscountPercent: this.discountPercent
            };

            item = this.actions.setSpecificFields(item);

            this.actions.savePurchaseOrderItem(this.orderId, this.lineNo, item)
                .done(() => {
                    routerHelper.navigateBack();
                }).
                always(() => {
                    routerHelper.hideLoading();
                });
        } else {
            const errors = _.chain(result.results).filter((result) => { return !result.valid }).pluck("message").value();
            notifier.showValidationError(errors);
        }
    }

    async loadData() {
        this.quantityModes = [{ id: enumHelper.QuantityMode().inventory.id, text: enumHelper.QuantityMode().inventory.label }, { id: enumHelper.QuantityMode().supplier.id, text: enumHelper.QuantityMode().supplier.label }];
        this.selectedQuantityMode = this.quantityModes[1];

        this.purchaseOrder = await purchaseOrderService.getPurchaseOrder(this.orderId, this.extension).promise();

        if (this.projectId) {
            this.activities = await this.inventoryProjectTransferService.GetActivities(this.projectId);
            this.groups = await this.inventoryProjectTransferService.GetExpenseGroups(this.projectId);
        }

        if (!this.readonly) {
            this.readonly = (this.purchaseOrder.Status !== enumHelper.purchaseOrderStatus.RESERVED)
        }

        if (this.isNew) {
            this.clearForm();
            return;
        }

        this.purchaseOrderItem = this.purchaseOrder.Items.find((item) => { return item.Line === parseInt(this.lineNo); });

        const itemData = {
            Description1: this.purchaseOrderItem.Description,
            Description2: this.purchaseOrderItem.Description2,
            Description3: this.purchaseOrderItem.Description3,
            UnitDescription: this.purchaseOrderItem.UnitDescription,
            SupplierUnitDescription: this.purchaseOrderItem.SupplierUnitDescription,
            SupplierScaleFactor: this.purchaseOrderItem.SupplierScaleFactor,
            InventoryScaleFactor: this.purchaseOrderItem.InventoryScaleFactor,
            CostGroup: this.purchaseOrderItem.Group,
            Activity: this.purchaseOrderItem.Activity
        };

        this.selectedItem = { id: this.purchaseOrderItem.Code, text: this.purchaseOrderItem.Code === "!" ? this.i18n.tr('AddItemNotInCatalog') : this.purchaseOrderItem.Code, Description: this.purchaseOrderItem.Description, data: itemData };

        this.supplierFactor = this.purchaseOrderItem.SupplierFactor;
        this.inventoryFactor = this.purchaseOrderItem.InventoryFactor;

        this.quantity = this.purchaseOrderItem.Quantity;
        this.supplierQuantity = this.purchaseOrderItem.SupplierQuantity;
        this.showNotInCatalog = (this.purchaseOrderItem.Code === "!");
        this.showCodeInput = (this.purchaseOrderItem.Code === "!");
        this.outCatalogCode = this.purchaseOrderItem.OutCatalogCode;

        this.unitPrice = this.purchaseOrderItem.UnitPrice;
        this.supplierUnitPrice = this.purchaseOrderItem.SupplierUnitPrice;
        this.discountPercent = this.purchaseOrderItem.DiscountPercent;
        this.inventoryScaleFactor = this.purchaseOrderItem.InventoryScaleFactor;
        this.supplierScaleFactor = this.purchaseOrderItem.SupplierScaleFactor;
    }

    removeNewLine() {
        if (!this.acceptCrLf) {
            this.description = this.description.replace(/\n/g, " ");
        }
    }

    handleKeyDown(event) {
        if (event.keyCode == Key.Enter) {
            return false;
        }
        this.removeNewLine();
        return true;
    }
}
