import { ServiceCallEmergencyTypeModel } from "api/models/company/service-call/service-call-emergency-type-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallEmergencyTypeProxy } from "api/proxies/service-call-emergency-type-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class ServiceCallEmergencyTypeService {
    constructor(private readonly serviceCallEmergencyTypeProxy: ServiceCallEmergencyTypeProxy) {
    }

    public async getEmergencyTypes(filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<ServiceCallEmergencyTypeModel[]> {
        return (await this.serviceCallEmergencyTypeProxy.Get(filter, pagingInfo, requestConfig)) || [];
    }
}
