define([
    "underscore",
    "jquery",
    "services/documentService",
    "helpers/enumHelper",
    "helpers/routerHelper",
    "helpers/documentHelper",
    "select2"
], function (_, jQuery, documentService, enumHelper, routerHelper, documentHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function hasArrived(presence){
            var enumPresence =  enumHelper.servicePresence();

            return enumPresence.ARRIVED.id + "" ===  presence + "" ||
            enumPresence.COMPLETED.id + "" ===  presence + "";
        }

        function ctor() {
            self = this;

            //#region Properties
            self.dispatchId;
            self.serviceType;
            self.equipmentCode;
            self.entityId = 0;
            self.isProjectResponsible = false;
            self.readyonly = false;
            self.equipmentId;
            self.presence = -1;
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function(params) {
            var paramsQs = routerHelper.getQuerystring(params.q);

            self.dispatchId = params.dispatchId;
            self.equipmentCode = params.equipmentCode;
            self.entityId = paramsQs.entityId;
            self.equipmentId = paramsQs.equipmentId;
            self.presence = paramsQs.presence;
            self.readonly = !hasArrived(self.presence);

            self.actions = {
                getSections: documentService.getEquipmentSectionsByEquipmentCode.bind(self,
                    params.serviceType,
                    params.dispatchId,
                    params.equipmentCode,
                    self.equipmentId),
                getSectionDocuments: documentService.getEquipmentDocumentsBySectionId.bind(self,
                    params.serviceType,
                    params.dispatchId,
                    params.equipmentCode,
                    self.equipmentId,
                    true),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                deleteDocument: documentService.deleteDocument.bind(documentService)
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
