import { inject } from "aurelia-framework";
import { Redirect, Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as apiHelper } from "helpers/apiHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

import { default as templateService } from "services/templateService";
import { default as purchaseOrderService } from "services/purchaseOrderService";
import { default as routerHelper } from "helpers/routerHelper";

@inject(I18N, Router)
export default class MaterialOrderItem {

    dispatchId;
    lineNo = '';
    orderDate = null;
    orderId = '';
    extension = '';
    showNotInCatalog = false;
    readonly = false;
    isDirty = false;

    constructor(i18n, router) {
        this.i18n = i18n;
        this.router = router;
    }

    setSpecificFields(item) {
        item.DispatchId = this.dispatchId;
        return item;
    }

    bindComponentActions() {
        this.actions = {
            setSpecificFields: item => this.setSpecificFields(item),
            savePurchaseOrderItem: (orderId, lineNo, item) => purchaseOrderService.setPurchaseOrderItemForService(this.dispatchId, orderId, lineNo, item)
        };
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }
        return true;
    }

    async canDeactivate() {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    activate(params) {
        this.dispatchId = params.dispatchId;

        this.requisitionDate = dateHelper.dateFromNavigationParameter(params.orderDate);
        this.orderId = params.orderId;
        this.catalogBaseUrl = apiHelper.getCatalogServiceBaseUrl(params.serviceType) + "dispatch/" + params.dispatchId + "/materialrequisitions";
        this.lineNo = params.lineNo;

        if (params.q) {
            this.readonly = queryStringHelper.parseReadonly(params.q);

            this.projectId =  queryStringHelper.parse(params.q, "projectCode") !== "null" ? queryStringHelper.parse(params.q, "projectCode") : undefined;

            const extension = queryStringHelper.parse(params.q, "extension");
            this.extension = extension ? extension : "";
        }
        this.bindComponentActions();

        //get template from dispatch...
        return templateService.getCurrentDispatchTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialService;
        });
    }
}