define([
    "underscore",
    "core/resx"
], function (_, resx) {
    "use strict";

    var errorCodes = [
        { code: '-325', sendStatus: 'E', saveStatus: 'E' },
        { code: '-326', sendStatus: 'E', saveStatus: 'E' },
        { code: '-327', sendStatus: 'E', saveStatus: 'S' },
        { code: '-328', sendStatus: '', saveStatus: '' },
        { code: '-329', sendStatus: '', saveStatus: '' },
        { code: '-330', sendStatus: 'S', saveStatus: '' },
        { code: '-331', sendStatus: 'S', saveStatus: 'S' },
        { code: '-332', sendStatus: '', saveStatus: 'S' },
        { code: '-333', sendStatus: 'E', saveStatus: 'S' },
        { code: '-334', sendStatus: 'E', saveStatus: 'S' },
        { code: '-335', sendStatus: 'E', saveStatus: 'E' },
        { code: '-336', sendStatus: 'E', saveStatus: 'E' },
        { code: '-337', sendStatus: 'E', saveStatus: 'E' }
    ];

    function getTitle(errorCode) {
        if (errorCode.sendStatus === 'E' && errorCode.saveStatus === 'E') {
            return resx.localize('PdfTransactionStatus_SendSaveError_Title');
        } else if (errorCode.sendStatus === 'E') {
            return resx.localize('PdfTransactionStatus_SendError_Title');
        } else if (errorCode.saveStatus === 'E') {
            return resx.localize('PdfTransactionStatus_SaveError_Title');
        } else {
            return "";
        }
    }

    function getMessage(errorCode) {
        var msg = '';

        if (errorCode.code === '-326') {
            msg = resx.localize('PdfTransactionStatus_InvalidWorkOrder_Message');
        } else if (_.contains(['-325', '-335', '-336', '-337'], errorCode.code)) {
            msg = resx.localize('PdfTransactionStatus_SendSaveError_Message');
        } else if (_.contains(['-327', '-333', '-334'], errorCode.code)) {
            msg = resx.localize('PdfTransactionStatus_NoOrInvalidContact_Message');
        } else {
            msg = "";
        }

        msg = msg.replace('{0}', errorCode.code);
        return msg;
    }

    var exports = {
        getPdfStatus: function (transactionStatus) {
            var errorCode = _.findWhere(errorCodes, { code: transactionStatus });

            //If errorCode doesn't exists (is not managed), return undefined so nothing is displayed to the user
            return errorCode ? {
                sendStatus: errorCode.sendStatus,
                saveStatus: errorCode.saveStatus,
                title: getTitle(errorCode),
                message: getMessage(errorCode)
            } : undefined;
        }
    };

    return exports;
});
