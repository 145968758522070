/****************************************************************************************
 * IMPORTANT - KNOWN ISSUE
 * In one run (no refresh), if a dispatch is accessed from the mobile site,
 * then deleted in maestro* and a new dispatch is added to the same person,
 * the header won't be refreshed if you access the new dispatch since they have the 
 * same dispatchId.
 * 
 * This is cause by maestro* which attributes the first available number as dispatchId
 * instead of using an auto-increment identity field.
 ****************************************************************************************/
import { bindable } from "aurelia-framework";
import { default as dateHelper } from "helpers/dateHelper";
import { default as purchaseOrderService } from "services/purchaseOrderService"


export class MaterialOrderHeader {
    title = "";
    subtitle = "";

    @bindable orderId;
    @bindable extension;

    bind() {
        this.initTitleAndSubtitle();
    }

    initTitleAndSubtitle() {
        if (!this.orderId) {
            return;
        }

        return purchaseOrderService.getPurchaseOrder(this.orderId, this.extension)
            .then((data) => {
                this.setDisplayValues(data.OrderDate, this.orderId, this.extension, data.SupplierCode + "-" + data.Supplier);
            });
    }

    setDisplayValues(orderDate, orderId, extension, supplierName) {
        if (extension !== "") {
            this.title = orderId + "-" + extension;
        } else {
            this.title = orderId;
        }
        this.subtitle = (orderDate ? dateHelper.getFullTextDate(orderDate) : "") + " (" + supplierName + ")";
    }
}
