import { autoinject } from "aurelia-framework";
import { AdditionalFieldType } from "api/enums/additional-field-type";

import { AdditionalFieldDateHelper } from "./components/additional-field-date-helper";
import { AdditionalFieldHourHelper } from "./components/additional-field-hour-helper";
import { AdditionalFieldCheckboxHelper } from "./components/additional-field-checkbox-helper";
import { AdditionalFieldTimestampHelper } from "./components/additional-field-timestamp-helper";
import { AdditionalFieldDropdownHelper } from "./components/additional-field-dropdown-helper";
import { AdditionalFieldMemoHelper } from "./components/additional-field-memo-helper";
import { AdditionalFieldLinkedDropdownHelper } from "./components/additional-field-linked-dropdown-helper";
import { AdditionalFieldPhoneHelper } from "./components/additional-field-phone-helper";

@autoinject
export class AdditionalFieldValueGetter {
  private additionalFieldDateHelper: AdditionalFieldDateHelper;
  private additionalFieldHourHelper: AdditionalFieldHourHelper;
  private additionalFieldCheckboxHelper: AdditionalFieldCheckboxHelper;
  private additionalFieldTimestampHelper: AdditionalFieldTimestampHelper;
  private additionalFieldDropdownHelper: AdditionalFieldDropdownHelper;
  private additionalFieldLinkedDropdownHelper: AdditionalFieldLinkedDropdownHelper;
  private additionalFieldMemoHelper: AdditionalFieldMemoHelper;
  private additionalFieldPhoneHelper: AdditionalFieldPhoneHelper;
  
  constructor(additionalFieldDateHelper: AdditionalFieldDateHelper,
              additionalFieldHourHelper: AdditionalFieldHourHelper,
              additionalFieldCheckboxHelper: AdditionalFieldCheckboxHelper,
              additionalFieldTimestampHelper: AdditionalFieldTimestampHelper,
              additionalFieldDropdownHelper: AdditionalFieldDropdownHelper,
              additionalFieldLinkedDropdownHelper: AdditionalFieldLinkedDropdownHelper,
              additionalFieldMemoHelper: AdditionalFieldMemoHelper,
              additionalFieldPhoneHelper: AdditionalFieldPhoneHelper) {

    this.additionalFieldDateHelper = additionalFieldDateHelper;
    this.additionalFieldHourHelper = additionalFieldHourHelper;
    this.additionalFieldCheckboxHelper = additionalFieldCheckboxHelper;
    this.additionalFieldTimestampHelper = additionalFieldTimestampHelper;
    this.additionalFieldDropdownHelper = additionalFieldDropdownHelper;
    this.additionalFieldLinkedDropdownHelper = additionalFieldLinkedDropdownHelper;
    this.additionalFieldMemoHelper = additionalFieldMemoHelper;
    this.additionalFieldPhoneHelper = additionalFieldPhoneHelper;
  }

  public getValue(item: any): any {
    switch (item.Definition.Type) {

      case AdditionalFieldType.Time:
        return this.additionalFieldHourHelper.getValue(item);

      case AdditionalFieldType.Date:
        return this.additionalFieldDateHelper.getValue(item);

      case AdditionalFieldType.CheckBox:
          return this.additionalFieldCheckboxHelper.getValue(item);

      case AdditionalFieldType.Timestamp:
          return this.additionalFieldTimestampHelper.getValue(item);

      case AdditionalFieldType.ComboBox:
        return this.additionalFieldDropdownHelper.getValue(item);
      
      case AdditionalFieldType.LinkedComboBox:
        return this.additionalFieldLinkedDropdownHelper.getValue(item);

      case AdditionalFieldType.Memo:
          return this.additionalFieldMemoHelper.getValue(item);
      default:
        return item.Value;
    }
  }

  public getSaveValue(item: any): any {
    switch (item.Definition.Type) {
      
      case AdditionalFieldType.Time:
        return this.additionalFieldHourHelper.getSavedValue(item);

      case AdditionalFieldType.Date:
        return this.additionalFieldDateHelper.getValue(item);

      case AdditionalFieldType.CheckBox:
        return this.additionalFieldCheckboxHelper.getValue(item);

      case AdditionalFieldType.Timestamp:
        return this.additionalFieldTimestampHelper.getValue(item);

      case AdditionalFieldType.ComboBox:
        return this.additionalFieldDropdownHelper.getSavedValue(item);
      
      case AdditionalFieldType.LinkedComboBox:
        return this.additionalFieldLinkedDropdownHelper.getSavedValue(item);

      case AdditionalFieldType.Memo:
        return this.additionalFieldMemoHelper.getSavedValue(item);

      case AdditionalFieldType.Phone:
        return this.additionalFieldPhoneHelper.getSavedValue(item);

      default:
        return item.Value;
    }
  }
}
