
import { CatalogItemPriceProxy } from "api/proxies/catalog-item-price-proxy";
import { autoinject } from "aurelia-framework";
import { UnitPriceType } from "api/enums/unit-price-type";
import { CatalogItemPriceModel } from "api/models/company/catalog/catalog-item-price-model";

@autoinject()
export class CatalogItemPriceService {
    constructor(private readonly catalogItemPriceProxy: CatalogItemPriceProxy) {
    }

    public async getUnitPrice(productCode: string, unitPriceType: UnitPriceType, quantity: number, transactionDate: Date, supplierFactor: number, inventoryFactor: number, supplierCode: string | null, projectCode: string | null): Promise<CatalogItemPriceModel> {

        const catalogItemPriceModel: CatalogItemPriceModel = {
            ProductCode: productCode,
            UpType: unitPriceType,
            Quantity: quantity,
            TransactionDate: transactionDate,
            SupplierCode: supplierCode,
            ProjectNo: projectCode,
            SupplierFactor: supplierFactor,
            InventoryFactor: inventoryFactor,
            InventoryUnitPrice: 0,
            SupplierUnitPrice: 0,
            DiscountPercent: 0
        };

        return await this.catalogItemPriceProxy.GetPrice(catalogItemPriceModel) as CatalogItemPriceModel;
    }
}
