import Config from "config";

interface FlareMappingItem {
    flareId: number;
    sourceName: string;
    routeName: string;
}

export class HelpHelper {
    private maestroFlareMapping: FlareMappingItem[] = [
        { flareId: 10000, sourceName: "mobile", routeName: "project" },
        { flareId: 20000, sourceName: "mobile", routeName: "service" },
        { flareId: 30000, sourceName: "mobile", routeName: "fieldservice" },
        { flareId: 40000, sourceName: "mobile", routeName: "recommendation" },
        { flareId: 50000, sourceName: "mobile", routeName: "inspection" },
        { flareId: 60000, sourceName: "mobile", routeName: "approval" },
        { flareId: 70000, sourceName: "mobile", routeName: "timesheet" },
        { flareId: 80000, sourceName: "mobile", routeName: "settings" },
        { flareId: 90000, sourceName: "mobile", routeName: "calendar" },
        { flareId: 100000, sourceName: "mobile", routeName: "receiptofgoods" },
        { flareId: 110000, sourceName: "mobile", routeName: "timekeeping" },
        { flareId: 120000, sourceName: "mobile", routeName: "inventorysitetransfer" },
        { flareId: 130000, sourceName: "mobile", routeName: "inventoryprojecttransfer" },
        { flareId: 140000, sourceName: "mobile", routeName: "employee" },
        { flareId: 0, sourceName: "maestrobi", routeName: "maestrobi" },
    ];

    public getHelpUrl(culture: string, routeName: string): string {
        culture = culture.replace("-", "_");
        const flareMapping = this.getFlareMappingForRoute(routeName);
        const baseHelpUrl = flareMapping == null || flareMapping.sourceName === "mobile" ? Config.helpUrlMobile : Config.helpUrlBI;

        return baseHelpUrl.replace("[culture]", culture)
                .replace("[flareId]", flareMapping ? flareMapping.flareId.toString() : "");
    }

    private getFlareMappingForRoute(routeName: string): FlareMappingItem | null {
        const routeNameId = routeName.split("_", 1)[0].toLowerCase();
        const context = this.maestroFlareMapping.find((x: FlareMappingItem) => x.routeName.toLowerCase() === routeNameId);
        return context ? context : null;
    }
}
