import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject } from "aurelia-framework";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallQuotationEquipmentModel } from "api/models/company/service-call/service-call-quotation-equipment-model";
import { RouterHelper } from "helpers/router-helper";

export interface QuotationAddedEquipmentDialogParameters {
    quotationId: number;
    lineNumber?: string;
}

@autoinject()
export class QuotationAddedEquipment {
    public addedEquipmentId: string | null = null;
    public addedEquipmentDesc: string | null = null;
    public lineNumber: number = 0;
    public quotationId: number = 0;
    public quotation!: ServiceCallQuotationDetailsModel;
    public equipmentToEdit: ServiceCallQuotationEquipmentModel | null | undefined = null;

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallContractEquipmentService: ServiceCallContractEquipmentService,
        private readonly routerHelper: RouterHelper
    ) {
    }

    public async activate(params: QuotationAddedEquipmentDialogParameters): Promise<void> {
        if (params.lineNumber) {
            this.lineNumber = parseInt(params.lineNumber);
        }
       
        await this.initQuotation(params.quotationId);

        if (this.lineNumber !== 0 && this.quotation.EquipmentsToAdd) {
            this.equipmentToEdit = this.quotation.EquipmentsToAdd.find((equip: ServiceCallQuotationEquipmentModel) => equip!.LineNumber === this.lineNumber);
            if (this.equipmentToEdit) {
                this.addedEquipmentId = this.equipmentToEdit.EquipmentId;
                this.addedEquipmentDesc = this.equipmentToEdit.Description;
            }
        }
    }

    public async populateAddedEquipments(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ServiceCallContractEquipmentModel[] | null> {
        return await this.serviceCallContractEquipmentService.getNewServiceCallContractEquipments(filter, pagingInfo, requestConfig);
    }

    public async save(): Promise<void> {
        if (!this.addedEquipmentId) {
            return;
        }

        if (this.equipmentToEdit) {
            this.equipmentToEdit.EquipmentId = this.addedEquipmentId;
            this.equipmentToEdit.Description  = this.addedEquipmentDesc;
        } else {
            if (!this.quotation.EquipmentsToAdd) {
                this.quotation.EquipmentsToAdd = [];
            }

            this.quotation.EquipmentsToAdd.push(this.serviceCallQuotationService.buildServiceCallQuotationEquipmentModel(this.addedEquipmentId, this.addedEquipmentDesc));
        }
      
        await this.serviceCallQuotationService.saveQuotation(this.quotation, true, true);
        this.routerHelper.navigateBack();
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotationId = quotationId;
        this.quotation = await this.fetchQuotation(Number(quotationId));
    }

    private async fetchQuotation(quotationId: number): Promise<ServiceCallQuotationDetailsModel> {
        return await this.serviceCallQuotationService.getQuotation(quotationId, true, false, false, false, false);
    }
}
