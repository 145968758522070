import { Configuration } from "webpack";
import { ProcoreConfiguration } from "../configuration";
import { autoinject } from "aurelia-framework";

@autoinject
export class SideBar {
  public Items: any[] = [];
  public selectedItem: any;

  constructor(public configurations: ProcoreConfiguration) { }

  public getSelectedItem(): HTMLElement | null {
    return document.querySelector("side-bar ul li.active");
  }

  public async selectItem(selectedElement: HTMLElement, item: any): Promise<void> {
    await this.configurations.ifIsDirtyShowPopup();

    this.changeSelectedItem(selectedElement);
    this.loadContent(item);
    this.selectedItem = item;
  }

  private changeSelectedItem(selectedElement: HTMLElement): void {
    // remove active class
    const sidebarList = document.querySelectorAll("side-bar ul li.active");

    if (sidebarList && sidebarList.length > 0) {
      Array.from(sidebarList).forEach((item: HTMLElement) => {
        item.classList.remove("active");
      });
    }

    // put active class on received item.
    selectedElement.classList.add("active");
  }

  private loadContent(item: any): void {

    // hide current content
    const elements = Array.from(document.querySelectorAll(`config-screen [code]`));

    elements.forEach((element: HTMLElement) => {
      element.setAttribute("hidden", "");
    });

    const contentWrapper = document.querySelector(`config-screen [code=${item.code}]`);

    if (contentWrapper) {
      contentWrapper.removeAttribute("hidden");
    }
  }
}
