import Assert from "core/assert";
import logger from "core/logger";

export default class Parse {
    public static Boolean(value: any): boolean {
        const truthies: any[] = [true, "true", 1];
        const falsies: any[] = [undefined, null, false, "false", 0, ""];

        if (truthies.includes(value)) {
            return true;
        }
        if (falsies.includes(value)) {
            return false;
        }

        logger.warn("Unknown value for boolean: ", value);

        return value ? true : false;
    }

    public static NullableBoolean(value: any): boolean | null {
        if (value === null || value === undefined) {
            return null;
        }

        return Parse.Boolean(value);
    }

    public static Decimal(inputValue: any): number {
        const decimal = Parse.NullableDecimal(inputValue);
        Assert.hasValue(decimal);

        return decimal || Number();
    }

    public static NullableDecimal(inputValue: any): number | null {
        if (inputValue === "" || inputValue === null || inputValue === undefined) {
            // Number('') is 0 in js :)
            return null;
        }

        const numeralValue: number = Number(inputValue);

        if (isNaN(numeralValue)) {
            throw new Error(`"${inputValue}" is not a valid number`);
        }

        return numeralValue;
    }

    public static Integer(inputValue: any): number {
        const num = Parse.Decimal(inputValue);

        const int = Math.trunc(num);
        Assert.strictEqual(int, num, "Invalid integer value");
        return int;
    }

    public static NullableInteger(inputValue: any): number | null {
        const num = Parse.NullableDecimal(inputValue);

        if (num === null) {
            return null;
        }

        const int = Math.trunc(num);
        Assert.strictEqual(int, num, "Invalid integer value");
        return int;
    }

    public static NullableString(value: any): string | null {
        switch (value) {
            case null:
            case undefined:
                return null;
            default:
                return value + "";
        }
    }

    public static String(value: any): string {
        return Parse.NullableString(value) || "";
    }
}
