import { autoinject } from "aurelia-framework";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallDispatchEquipmentMaintenanceMaterialService } from "services/service-call-dispatch-equipment-maintenance-material";

import { I18N } from "aurelia-i18n";

import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { ServiceCallEquipmentMaintenanceMaterialModel } from "api/models/company/service/service-call-equipment-maintenance-material-model";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

export interface EquipmentMaintenanceParameters {
    dispatchId: number;
    equipmentId: number;
    equipmentCode: string;
    maintenanceId: string;
    maintenanceFullDescription: string;
    equipmentDescription: string;
    equipmentBrand: string;
    equipmentSerialNo: string;
    readonly: boolean;
    serviceType: string;
}
@autoinject
export class EquipmentMaintenanceRequiredMaterial {
    [x: string]: any;
    public materialCards!: MaCardOptionsModel[];

    public dispatchId: number = 0;
    public equipmentCode: string = "";
    public equipmentId: number = 0;
    public maintenanceFullDescription: string = "";
    public equipmentDescription: string = "";
    public equipmentBrand: string = "";
    public equipmentSerialNo: string = "";

    private maintenanceId: string = "";
    private serviceType: string = "";
   
    private materials: ServiceCallEquipmentMaintenanceMaterialModel[] | null = null;

    constructor(private readonly i18N: I18N, private readonly serviceCallDispatchEquipmentMaintenanceMaterialService: ServiceCallDispatchEquipmentMaintenanceMaterialService, private routeRepository: RouteRepository) {
    }

    public async activate(params: EquipmentMaintenanceParameters): Promise<void> {
        this.equipmentCode = params.equipmentCode;
        this.equipmentId = params.equipmentId;
        this.maintenanceId = params.maintenanceId;
        this.maintenanceFullDescription = params.maintenanceFullDescription;
        this.dispatchId = params.dispatchId;
        this.equipmentDescription = params.equipmentDescription;
        this.equipmentSerialNo = params.equipmentSerialNo;
        this.serviceType = params.serviceType;
        this.equipmentBrand = params.equipmentBrand;
        await this.loadData();
    }

    public createCard(item: ServiceCallEquipmentMaintenanceMaterialModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            action: this.editItem.bind(this, item),
            class: "ma-card-link",
            displayLines: [
                new MaCardLineModel({ isCustomTemplate: true })
            ],
            actionItems: [],
        });

        if (item.CanBeModified) {
            card.actionItems = [
                new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.editItem.bind(this, item) })];
        }

        card.actionItems!.push();
        
        return card;
    }

    public editItem(item: ServiceCallEquipmentMaintenanceMaterialModel): void {
        if (!item.CanBeModified) {
            return;
        }

        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_EquipmentMaintenanceRequiredMaterial_Edit.name, {
            serviceType: this.serviceType,
            dispatchId: this.dispatchId,
            equipmentId: this.equipmentId ,
            equipmentCode: this.equipmentCode,
            maintenanceId: this.maintenanceId,
            maintenanceFullDescription: this.maintenanceFullDescription,
            equipmentDescription: this.equipmentDescription,
            equipmentBrand: this.equipmentBrand,
            equipmentSerialNo: this.equipmentSerialNo,
            materialId: item.Code,
            readonly: this.readonly
        });
    }

    private async loadData(): Promise<void> {
        this.materials = await this.serviceCallDispatchEquipmentMaintenanceMaterialService.get(this.dispatchId, this.equipmentCode, this.maintenanceId, this.equipmentId);
        this.materialCards = this.materials!.map(this.createCard.bind(this));
    }
}
