// GENERATED FILE - DO NOT MODIFY
import { DocumentModel } from "api/models/company/document/document-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { ListDocumentModel } from "api/models/company/document/list-document-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DailyEntryWorkTaskDocumentProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async AddDocumentForWorkTask(transactionNumber: number, models: Array<DocumentModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/daily-entry/work-task/{transactionNumber}/documents",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString() },
            null
        );

        const data: boolean = await this.post<Array<DocumentModel | null> | null, boolean>(uri, models, requestConfig);
        return data;
    }

    public async GetSectionsForWorkTask(transactionNumber: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        const uri = this.buildUri(
            "/daily-entry/work-task/{transactionNumber}/documents/sections",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DocumentSectionModel[] | null = await this.get<DocumentSectionModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.DocumentSectionModel");
        return data;
    }

    public async GetDocumentsForWorkTask(transactionNumber: number, sectionId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ListDocumentModel[] | null> {
        const uri = this.buildUri(
            "/daily-entry/work-task/{transactionNumber}/documents/sections/{sectionId}",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString(), sectionId: (sectionId === null || sectionId === undefined) ? undefined : sectionId },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ListDocumentModel[] | null = await this.get<ListDocumentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.ListDocumentModel");
        return data;
    }

    public async DeleteDocument(transactionNumber: number, documentId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/daily-entry/work-task/{transactionNumber}/documents/{documentId}",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString(), documentId: (documentId === null || documentId === undefined) ? undefined : documentId.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }
}
