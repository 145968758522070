import Parse from "helpers/parse";

export class BooleanToStringValueConverter {
    public fromView(value: any): boolean | null {
        return Parse.NullableBoolean(value);
    }

    public toView(value: any): string | null {
        return Parse.NullableString(value);
    }
}
