import { autoinject, bindingMode } from "aurelia-framework";
import { bindable, observable } from "aurelia-typed-observable-plugin";

import { FormControl } from "components/form-controls/form-control";
import Parse from "helpers/parse";

@autoinject()
export class IntegerPicker extends FormControl {
    @bindable.string
    public unit: string | null = null;
    
    @bindable.nullable_number
    public min: number | null = 0;
    @bindable.nullable_number
    public max: number | null = null;
    @bindable.nullable_number
    public step: number | null = 1;

    @bindable.nullable_number({ defaultBindingMode: bindingMode.twoWay })
    public value: number | null = null;

    @observable.string
    public internalValue: string = "";

    constructor(element: Element) {
        super(element);
    }

    public bind(): void {
        this.valueChanged();
    }
    
    public internalValueChanged(): void {
        this.value = Parse.NullableDecimal(this.internalValue);
    }

    public valueChanged(): void {
        this.internalValue = this.value ? this.value.toString() : "";
    }

}
