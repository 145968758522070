import { inject } from "aurelia-framework";
import { default as _ } from "underscore";
import { default as val } from "core/val";

import { default as enumHelper } from "helpers/enumHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as materialService } from "services/materialService";

import { default as templateService } from "services/templateService";
import { default as apiHelper } from "helpers/apiHelper";
import { default as settingHelper } from "helpers/settingHelper";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@inject(I18N, NotificationHelper, Router)
export default class MaterialEdit extends FormBase {
    readonly = false;
    dispatchProjectCode = "";
    dispatchDate = "";
    isProjectResponsible = false;
    showNotInCatalog = false;
    isDirty = false;

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        return this.isDirty;
    }

    activate(params) {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);

        return templateService.getUserTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialProject;
        });
    }

    bindViewModel(dispatchProjectCode, dispatchDate, lineNo, querystring) {
        this.catalogBaseUrl = apiHelper.getCatalogProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/material?dispatchTemplateId=" + settingHelper.getSelectedDispatchModel();
        this.lineNo = lineNo;
        this.readonly = queryStringHelper.parseReadonly(querystring);

        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;

        this.actions = {
            getActivities: new jQuery.Deferred().resolve([]),
            getMaterial: materialService.getForProjectDailyEntry.bind(null, this.dispatchProjectCode, this.dispatchDate, this.lineNo),
            setMaterial: materialService.setForProjectDailyEntry.bind(null, this.dispatchProjectCode, this.dispatchDate)
        };

        this.validationValues = {
            description: {
                isBilling: {
                    maxLength: val.get('projects.material.description', 'maxLength')
                },
                isNotBilling: {
                    maxLength: val.get('projects.material.description', 'maxLength')
                }
            }
        }
    }
}