import services from "services/serviceService";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";

@autoinject()
export class ServiceNote {

    public dispatchId: number = 0;
    public notes: any[] | null = [];
    public readonly: boolean = false;
    public serviceType: string = "";
    public timeStamp: string = "";

    public loadData(): any {
        return services.getNotes(this.serviceType, this.dispatchId!).then((result: any) => {
            this.notes = result.Notes;
            this.timeStamp = result.VisitTimestamp ? result.VisitTimestamp : result.WorkOrderTimestamp;
        });
    }

    public activate(params: any): any {
        const paramsQs = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQs && paramsQs.readonly === "false");
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;
        return this.loadData();
    }

    public deleteItem(item: any): void {
        notificationHelper.showDeleteConfirmation().then((success: boolean) => {
            if (success) {
                routerHelper.showLoading();
                services.delNote(this.serviceType, this.dispatchId, item.RowNumber, item.VisitTimestamp, item.WorkOrderTimestamp).then(() => {
                    routerHelper.hideLoading();
                    this.loadData();
                });
            }
        });
    }

    public gotoPage(note: any): any {
        const url = note && note.RowNumber ? routerHelper.getRelativeUrl("edit", note.RowNumber) : routerHelper.getRelativeUrl("edit");
        routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: this.readonly, ts: this.timeStamp }));
    }
}
