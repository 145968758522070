import { QuotationItemBonusParameters } from "./item-bonus";
import { autoinject, computedFrom } from "aurelia-framework";

import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { nameof as nameof_ServiceCallQuotationItemTypes, ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";

import { QuotationItemMaterialParameters } from "pages/services/quotations/item-material";
import { QuotationItemLaborParameters } from "pages/services/quotations/item-labor";
import { QuotationItemEquipmentParameters } from "pages/services/quotations/item-equipment";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { nameof as nameof_ServiceCallQuotationPricingType, ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";

export interface QuotationItemsParameters {
    quotationId: number;
}

@autoinject()
export class QuotationItems {
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;
    public serviceCallQuotationPricingType: typeof ServiceCallQuotationPricingType = ServiceCallQuotationPricingType;

    public readonly ServiceCallQuotationItemType: typeof ServiceCallQuotationItemType = ServiceCallQuotationItemType;
    public readonly nameof_ServiceCallQuotationItemTypes: typeof nameof_ServiceCallQuotationItemTypes = nameof_ServiceCallQuotationItemTypes;

    private quotationId: number = 0;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings);
    }

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        private readonly routeRepository: RouteRepository) {
    }

    public async activate(params: QuotationItemsParameters): Promise<void> {
        await Promise.all([this.initQuotation(params.quotationId), this.initSecuritySettings()]);
    }

    public gotoItem(type: ServiceCallQuotationItemType, itemId?: number): Promise<void> {
        switch (type) {
            case ServiceCallQuotationItemType.Material:
                return routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Item_Material.name, {
                    quotationId: this.quotationId,
                    type: type,
                    itemId: itemId,
                } as QuotationItemMaterialParameters);

            case ServiceCallQuotationItemType.BillingItem:
                return routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Item_BillingItem.name, {
                    quotationId: this.quotationId,
                    type: type,
                    itemId: itemId,
                } as QuotationItemMaterialParameters);

            case ServiceCallQuotationItemType.Labor:
                return routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Item_Labor.name, {
                    quotationId: this.quotationId,
                    type: type,
                    itemId: itemId,
                } as QuotationItemLaborParameters);

            case ServiceCallQuotationItemType.Equipment:
                return routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Item_Equipment.name, {
                    quotationId: this.quotationId,
                    type: type,
                    itemId: itemId,
                } as QuotationItemEquipmentParameters);

            case ServiceCallQuotationItemType.Bonus:
                return routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Item_Bonus.name, {
                    quotationId: this.quotationId,
                    type: type,
                    itemId: itemId,
                } as QuotationItemBonusParameters);

            default:
                return Promise.resolve();
        }
    }

    public async deleteItem(item: ServiceCallQuotationItemModel): Promise<void> {
        if (!this.quotation) {
            return;
        }

        if (item.Type === ServiceCallQuotationItemType.MiscCost && this.quotation.IsLumpSum !== this.serviceCallQuotationPricingType.LumpSum && this.quotation.IsLumpSum !== this.serviceCallQuotationPricingType.LumpSumPlus) {
            return;
        }

        await this.serviceCallQuotationService.deleteQuotationItem(item.Id);
        await this.initQuotation(this.quotationId);
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotationId = quotationId;
        this.quotation = await this.fetchQuotation(Number(quotationId));
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }

    private async fetchQuotation(quotationId: number): Promise<ServiceCallQuotationDetailsModel> {
        return await this.serviceCallQuotationService.getQuotation(quotationId, true, false, false, false, false);
    }
}
