import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { PagingInfo } from "api/paging-info";
import { DispatchProjectTimeEntryProxy } from "api/proxies/dispatch-project-time-entry-proxy";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";

@autoinject()
export class DispatchProjectTimeEntryService {
    constructor(private readonly dispatchProjectTimeEntryProxy: DispatchProjectTimeEntryProxy) {}

    public async GetSeperateTimeLinkedEquipmentDispatches(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        return await this.dispatchProjectTimeEntryProxy.GetSeperateTimeLinkedEquipmentDispatches(dispatchId, filter, pagingInfo, requestConfig);
    }
}
