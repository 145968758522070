import { InventoryProjectTransferStatus } from "api/enums/inventory-project-transfer-status";
import { Tab } from "components/layout/tab";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import notificationHelper from "helpers/notificationHelper";
import { default as settingHelper } from "helpers/settingHelper";

import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";

import { InventoryProjectTransferModel } from "api/models/company/inventory-project-transfer-model";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
@autoinject
export class InventoryProjectTransferIndex {
    @bindable public currentTabId: number = 0;
    public isClosedInventoryProjectTransferLoaded: boolean = false;

    public currentInventoryProjectTransferCards: MaCardOptionsModel[] = [];
    public closedInventoryProjectTransferCards: MaCardOptionsModel[] = [];
    public isMultiPrefix: boolean = false;

    constructor(private readonly i18n: I18N, private readonly inventoryProjectTransferService: InventoryProjectTransferService, private routeRepository: RouteRepository) {}

    public bind(): void {
        this.isMultiPrefix = settingHelper.isMultiPrefix();
        this.inventoryProjectTransferService.GetTransfers(false).then((result: InventoryProjectTransferModel[]): any => {
            this.currentInventoryProjectTransferCards = result.map((item: InventoryProjectTransferModel) => this.createCard(item, false));
        });
    }

    public currentTabIdChanged(): void {
        if (!this.isClosedInventoryProjectTransferLoaded) {
            this.inventoryProjectTransferService.GetTransfers(true).then((result: InventoryProjectTransferModel[]): any => {
                this.closedInventoryProjectTransferCards = result.map((item: InventoryProjectTransferModel) => this.createCard(item, true));
            });
            this.isClosedInventoryProjectTransferLoaded = true;
        }
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (Number(event.detail.tabId) === this.currentTabId) {
            return;
        }
        this.currentTabId = Number(event.detail.tabId);
    }

    public add(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Add.name);
    }

    public edit(item: InventoryProjectTransferModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Info.name, { transferId: item.Id });
    }

    public delete(item: InventoryProjectTransferModel): void {
        const dialogMessage = `${this.i18n.tr("msg_DeleteInventoryProjectTransferConfirmationText")} (${this.getProjecetDisplay(item)})`;

        notificationHelper.showDialogYesNo(dialogMessage).then((success: boolean): any => {
            if (success) {
                this.inventoryProjectTransferService.DeleteTransfer(item.Id).then((result: boolean): any => {
                    if (result) {
                        this.removeTransferFromList(item.Id);
                        notificationHelper.showSuccess(this.i18n.tr("Procore.Status_Success"));
                    }
                });
            }
        });
    }

    public createCard(item: InventoryProjectTransferModel, isClosed: boolean): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getProjecetDisplay(item), class: "text-primary font-weight-bold" }),
                new MaCardLineModel({ display: this.getCounterDisplay(item)}),
                new MaCardLineModel({ display: this.getDateDisplay(item), icon: "fa fa-plus text-success pr-1" }),
                new MaCardLineModel({ display: this.getStatusDisplay(item), class: "font-weight-bold" }),

            ],
            actionItems: [new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.edit.bind(this, item) })],
        });

        if (this.isMultiPrefix) {
            card.displayLines!.push(
                new MaCardLineModel({ display: this.getCompanySourceDisplay(item)}),
                new MaCardLineModel({ display: this.getCompanyTargetDisplay(item)}));
        }

        card.displayLines!.push( new MaCardLineModel({ display: this.getCreatedByDisplay(item), class: "mt-2" }));

        if (!isClosed) {
            card.actionItems!.push(new MaCardActionModel({ icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item) }));
        }
        return card;
    }

    private getProjecetDisplay(item: InventoryProjectTransferModel): string {
        return `${item.ProjectNo} - ${item.ProjectDescription }`;
    }

    private getCounterDisplay(item: InventoryProjectTransferModel): string {
        return `${this.i18n.tr("Counter")}: ${item.Id}`;
    }

    private getDateDisplay(item: InventoryProjectTransferModel): string {
        return `${this.i18n.tr("CreatedOn")} ${dateHelper.getShortTextDate(item.AccountingDate!)}`;
    }

    private getStatusDisplay(item: InventoryProjectTransferModel): string {
        return item.Status === InventoryProjectTransferStatus.Active ? this.i18n.tr("InProgress") : this.i18n.tr("Completed");
    }

    private getCompanySourceDisplay(item: InventoryProjectTransferModel): string {
        return `${this.i18n.tr("SourceCompany")}: ${item.SourcePrefix} - ${item.SourceCompanyName}`;
    }

    private getCompanyTargetDisplay(item: InventoryProjectTransferModel): string {
        return `${this.i18n.tr("TargetCompany")}: ${item.TargetPrefix} - ${item.TargetCompanyName}`;
    }

    private getCreatedByDisplay(item: InventoryProjectTransferModel): string {
        return `${this.i18n.tr("CreatedBy")} ${item.CreatedBy}`;
    }

    private removeTransferFromList(id: number): void {
        this.currentInventoryProjectTransferCards = this.currentInventoryProjectTransferCards.filter((item: MaCardOptionsModel) => {
            return id !== item.model.Id;
        });
    }
}
