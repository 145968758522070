import { inject, NewInstance } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ValidationRules, ValidationController, validateTrigger } from "aurelia-validation"

import { default as _ } from "underscore"
import { default as routerHelper } from "helpers/routerHelper"
import { default as notifier } from "helpers/notificationHelper"
import { default as workflowService } from "services/workflowService"
import NotificationService from "services/notificationService"

@inject(I18N, NewInstance.of(ValidationController), NotificationService)
export class Approval_Save {
    i18n;
    validationController;
    routerHelper = routerHelper;
    actionId = null;
    action = null;
    actionLabel;
    comment = null;

    constructor(i18n, validationController, notificationService) {
        this.i18n = i18n;
        this.notificationService = notificationService;
        this.validationController = validationController;

        this.initValidation();
    }

    activate(params) {
        this.bindViewModel(params.actionId, params.action);
    }

    bindViewModel(actionId, action) {
        this.actionId = actionId;
        this.action = action;

        if (action === "FirstStep") {
            this.actionLabel = this.i18n.tr("ReturnToFirstStep");
        } else {
            this.actionLabel = this.i18n.tr(action);
        }

        this.clear();
    }

    clear() {
        this.comment = null;
    }

    async save() {
        if (!await this.validate()) {
            return Promise.resolve();
        }

        routerHelper.showLoading();

        try {
            await workflowService.doAction(this.action, this.actionId, this.comment);

            this.notificationService.updateApprovalNotification();
            routerHelper.navigateBack();
        } finally {
            routerHelper.hideLoading();
        }
    }

    initValidation() {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure("comment").required().withMessageKey("err_CommentRequired")
            .on(this);
    }

    async validate() {
        const result = await this.validationController.validate();

        if (!result.valid) {
            notifier.showValidationError(result.results);
        }

        return result.valid;
    }
}
