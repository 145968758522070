import { SkillModel } from "api/models/company/skill-model";
import { PagingInfo } from "api/paging-info";
import { SkillProxy } from "api/proxies/skill-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class SkillService {
    constructor(private readonly skillProxy: SkillProxy) {
    }

    public async getSkills(filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<SkillModel[]> {
        return await this.skillProxy.GetSkills(filter, pagingInfo, requestConfig) || [];
    }
}
