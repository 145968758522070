import employeeService from "services/employeeService";
import { SettingRepository } from "repositories/setting-repository";
import { autoinject } from "aurelia-framework";

@autoinject
export class EmployeeProfile {
    public dispatchId: string | null = "";
    public employeeId: any = "";

    constructor(private readonly settingRepository: SettingRepository) {
    }

    public async activate(params: any): Promise<any> {
        this.dispatchId = params.dispatchId ? params.dispatchId : this.settingRepository.getDispatchTemplate();
        this.employeeId = params.employeeId ? params.employeeId : await employeeService.getCurrentEmployeeId();
    }
}
