import requests from "services/requests";
import apiHelper from "helpers/apiHelper";

export class DailyEntryTasksService {
    getTasks(dispatchProjectCode) {
        return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/tasks")
            .promise();
    }

    setTasks(dispatchProjectCode, tasks) {
        return requests.post(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/tasks/", tasks)
            .promise();
    }
}
