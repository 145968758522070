import { transient, children, bindable, inject } from "aurelia-framework";
import { Tab } from "components/layout/tab";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { KeyboardShortcutsHelper } from "helpers/keyboard-shortcuts-helper";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { default as Mousetrap } from "mousetrap";
import "mousetrap-global-bind";
import { bind } from "bluebird";
@transient()
@inject(Element, KeyboardShortcutsHelper, ViewModeHelper)
export class TabControl {
    @bindable
    public selectedTabId: any = null;
    
    @bindable
    public context: any | null = null;
    @bindable
    public changeTab: any | null = null;

    @children("tab")
    private tabs: Tab[] = [];

    constructor(private readonly element: Element,
                private readonly keyboardShortcutsHelper: KeyboardShortcutsHelper,
                private readonly viewModeHelper: ViewModeHelper) {
    }

    public bind(): void {
        //
    }

    public attached(): void {
        // Make sure one and only one tab is selected.
        if (this.tabs && this.tabs.length > 0) {
            const tabToSelect = this.tabs.find((x: Tab) => x.selected);
            this.selectTab(tabToSelect ? tabToSelect : this.tabs[0]);
        }

        if (this.viewModeHelper.getIsDesktopMode()) {
            this.initializeKeyboardShortcuts();
        }
    }

    public selectTab(tab: Tab): void {
        // Unselect all tabs except the one that was just clicked.
        this.tabs.forEach((x: Tab) => { x.selected = (x === tab); });

        // Make sure the tab is selected.
        tab.selected = true;

        this.selectedTabId = tab.tabId;

        CustomEventHelper.dispatchEvent(this.element, "selected-tab-changed", tab);

        if (this.context && this.changeTab) {
            const changeTabBinded = this.changeTab.bind(this.context);
            changeTabBinded(tab);
        }
    }

    private initializeKeyboardShortcuts(): void {

        this.initializeTabNavigation();
    }

    private initializeTabNavigation(): void {
        Mousetrap.bindGlobal(this.keyboardShortcutsHelper.Shortcuts.SwitchTab, (event: KeyboardEvent): void => {
            this.SwitchTabShortcut();
        });
    }

    private SwitchTabShortcut(): void {
        const tabToSelect = this.tabs.find((x: Tab) => x.selected);
        if (tabToSelect) {
            let tabIndex = this.tabs.findIndex((x: Tab) =>  (x.name === tabToSelect.name) );

            if (tabIndex >= this.tabs.length - 1) {
                tabIndex = -1;
            }
            this.selectTab(this.tabs[tabIndex + 1]);
        }
    }
}
