import urlHelper from "helpers/urlHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { autoinject, bindable } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { CompanyService } from "services/company-service";
import { InventorySiteTransferService } from "services/inventory-site-transfer-service";

import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";
import { CompanyModel } from "api/models/common/company/company-model";
import val from "core/val";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";
import { default as dateHelper } from "helpers/dateHelper";

@autoinject()
export class InventorySiteTransferEdit extends FormBase {
    @bindable public option: any = "";
    @bindable public transfer!: InventorySiteTransferDetailsModel | null;
    public unModified: InventorySiteTransferDetailsModel | null = null;

    @bindable public isMultiPrefix: boolean = false;

    @bindable public isNewTransfer: boolean = true;
    public val: any = val;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private routeRepository: RouteRepository, private readonly inventorySiteTransferService: InventorySiteTransferService, private readonly companyService: CompanyService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(this.regExTakeOnlyNoneAlphanumeric, "");
        const stringifyCurrent = JSON.stringify(this.transfer).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: any): Promise<void> {
        this.isNewTransfer = !params.transferId;

        if (this.isNewTransfer) {
            this.transfer = await this.inventorySiteTransferService.NewTransfer();
            this.unModified = { SourceSiteId: null, SourceSiteDescription: null, TargetSiteId: null, TargetSiteDescription: null, Id: 0, AccountingDate: dateHelper.getDate(), ReferenceNo: null, SourcePrefix: null, SourceCompanyName: null, TargetPrefix: null, TargetCompanyName: null, Memo: null, Status: null, CurrencyCode: null, Items: null };
        } else {
            this.transfer = await this.inventorySiteTransferService.GetTransfer(params.transferId);
            this.unModified = CloneHelper.deepClone(this.transfer);
        }

        this.isMultiPrefix = settingHelper.isMultiPrefix();
    }

    public async getSourceCompanies(): Promise<CompanyModel[] | null> {
        return await this.companyService.getAll();
    }

    public async save(): Promise<void> {
        await this.inventorySiteTransferService.SaveTransfer(this.transfer!).then((result: number | null): any => {
            this.unModified = null;
            this.replaceBrowsingHistory();
            routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Info.name, { transferId: result });
        });
    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        const route = this.routeRepository.routes.InventorySiteTransfer.route.toString();
        window.history.replaceState("", "", baseUrl + route);
    }
}
