import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { Dialog } from "interfaces/dialog";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";

export interface QuotationAddedEquipmentDialogParameters {
    quotation: ServiceCallQuotationDetailsModel;
}

@autoinject()
export class QuotationAddedEquipmentDialog implements Dialog {

    public dialogHeaderTitle: string = "";
    public addedEquipmentId: string | null = null;
    public addedEquipmentDesc: string | null = null;
    public quotation!: ServiceCallQuotationDetailsModel;

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallContractEquipmentService: ServiceCallContractEquipmentService,
        private readonly dialogController: DialogController
    ) {
    }

    public activate(params: QuotationAddedEquipmentDialogParameters): void {
        this.quotation = params.quotation;
    }

    public async populateAddedEquipments(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ServiceCallContractEquipmentModel[] | null> {
        return await this.serviceCallContractEquipmentService.getNewServiceCallContractEquipments(filter, pagingInfo, requestConfig);
    }

    public async ok(): Promise<void> {
        if (!this.addedEquipmentId) {
            return;
        }

        this.dialogController.ok(this.serviceCallQuotationService.buildServiceCallQuotationEquipmentModel(this.addedEquipmentId, this.addedEquipmentDesc));
    }

    public cancel(): void {
        this.dialogController.cancel();
    }
}
