import { bindingMode, transient, containerless } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

import { Position } from "enums/position";
@containerless
export class FormControlFrame {

    public readonly Position: typeof Position = Position;

    @bindable.string
    public labelPosition: Position = Position.Top;

    @bindable.string
    public id: string = "";

    @bindable.string
    public label: string = "";

    @bindable.string
    public message: string = "";

    @bindable.booleanAttr
    public required: boolean = false;
}
