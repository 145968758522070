import { TransactionLoaderEntity } from "api/models/procore/loaders/transaction-loader-entity";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ProcoreGridItemTransformer } from "./procore-grid-item-transformer";
import { ProcoreGridItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-item";
import { ProcoreGridGroup } from "pages/procore/synchronization/composition/grid/models/procore-grid-group";
import { ProcoreGridPCCOChangeOrder } from "pages/procore/synchronization/composition/grid/models/procore-grid-pcco-change-order";
import { PccoChangeOrderLoaderEntity } from "api/models/procore/loaders/pcco-change-order-loader-entity";
import { DateFormatValueConverter } from "converters/format/date-format";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";

@autoinject
export class PrimeContractChangeOrderTransactionTransformer {
    constructor(private readonly i18n: I18N, private readonly procoreGridItemTransformer: ProcoreGridItemTransformer) {

    }

    public transform(items: Array<PccoChangeOrderLoaderEntity | null> | null): ProcoreGridItem[] {
        if (!items) { return []; }

        const transformedItems = items.map((item: PccoChangeOrderLoaderEntity): ProcoreGridItem | ProcoreGridGroup => {
            const language = localStorage.getItem("maestro_user_profile_LANGUAGE");
            const errors = this.procoreGridItemTransformer.transformErrors(item.Errors, language);
            const warnings = this.procoreGridItemTransformer.transformErrors(item.Warnings, language);

            return {
                EntityToSynchronize: ProcoreEntityToSynchronize.PccoChangeOrders,
                AccountingDate: this.shortDateFormatter(item.AccountingDate),
                Amount: item.Amount,
                ChangeOrder: item.ChangeOrder,
                Quantity: item.Quantity,
                Title: item.Title,
                Selected: false,
                ErrorsOpen: false,
                Errors: errors,
                Warnings: warnings,
                IsSynchronizing: false,
                IsDetails: true,
                Target: item.Target,
                Code: item.Code,
                Description: item.Description,
                AdditionalField: null,
                Message: this.procoreGridItemTransformer.getMessage(errors, warnings),
                Children: this.transform(item.Children),
                Details: [],
                DetailsOpen: false,
                IsPccoChangeOrder: true,
                IsCommandContractTransaction: false,
                DetailsIsLoading: false,
                Status: item.Status,
                IsSynchronized: item.IsSynchronized,
                SynchronizationTried: false,
            } as ProcoreGridPCCOChangeOrder;
        });

        return transformedItems;
    }
    private shortDateFormatter(date: Date | null): any {
        if (!date) {
          return null;
        }
    
        const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);
    
        return dateFormatValueConverter.toView(date, "L");
      }

}
