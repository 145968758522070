
export interface IBambooReasonTreeviewItem {
    hierarchy: string[];
    reason: string;
}

export class BambooReasonTreeviewItem implements IBambooReasonTreeviewItem  {

    constructor(public hierarchy: string[], public reason: string, public reasonType?: string) {
    }

    public equals(other: IBambooReasonTreeviewItem): boolean {
        let isSame = other.hierarchy.every((v: string, i: number, a: string[]) => {
            return v === this.hierarchy[i];
        });

        isSame = isSame && other.reason === this.reason;
        return isSame;
    }
}
