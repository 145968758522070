define(["core/resx"],
    function (resx) {
        "use strict";

        var exports = {
            addressSources: function () {
                return {
                    PROJECT: { id: 1, value: 'P', label: resx.localize('Project') },
                    CLIENT: { id: 2, value: 'C', label: resx.localize('Customer') },
                    ALTERNATIVE: { id: 3, value: 'A', label: resx.localize('Alternative') }
                };
            },

            attendance: function () {
                return {
                    IGNORE: -1,
                    UNDEFINED: 0,
                    ABSENT: 1,
                    ONSITE: 2,
                    OUT: 3
                };
            },

            attendanceAddReturnType: function () {
                return {
                    NONE: 0,
                    ALL: 1,
                    PARTIAL: 2
                };
            },

            fieldServiceEditMode: function () {
                return {
                    ADD: 'add',
                    EDIT: 'edit',
                    SUB: 'sub'
                };
            },

            servicePresence: function () {
                return {
                    NEW: { id: 0, label: resx.localize('NewRequest'), icon: 'fa fa-envelope-o' },
                    ONMYWAY: { id: 10, label: resx.localize('OnMyWay'), icon: 'fa fa-truck' },
                    ARRIVED: { id: 11, label: resx.localize('Arrived'), icon: 'ma ma-ongoing' },
                    COMPLETED: { id: 12, label: resx.localize('Completed'), icon: 'fa fa-check' },
                    ACCEPTED: { id: 13, label: resx.localize('NotStarted'), icon: 'fa fa-hourglass-start' },
                    DECLINED: { id: 14, label: resx.localize('Declined'), icon: 'fa fa-times' },
                    STOP: { id: 15, label: resx.localize('NotStarted'), icon: 'fa fa-hourglass-start' },
                    ONHOLD: { id: 16, label: resx.localize('ServiceCallOnHoldPresence'), icon: 'fa fa-hourglass-start' }
                };
            },

            serviceStatus: function () {
                return {
                    ONGOING: { id: 1, label: resx.localize('Ongoing') },
                    UNPLANNED: { id: 2, label: resx.localize('Unplanned') },
                    COMPLETED: { id: 3, label: resx.localize('Completed_F_P') }
                };
            },

            wageType: function () {
                return {
                    SIMPLE: 0,
                    OVER: 1,
                    DOUBLE: 2,
                    BONUS: 3,
                    EQUIPMENT: 4
                };
            },

            wageTypeCombination: function () {
                return {
                    SIMPLE: [0],
                    SIMPLEOVER: [0, 1],
                    SIMPLEDOUBLE: [0, 2],
                    SIMPLEOVERDOUBLE: [0, 1, 2]
                };
            },

            workOrderActivityType: function () {
                return {
                    LABOUR: 1,
                    MATERIAL: 2,
                    EQUIPMENT: 3
                };
            },

            workOrderStatus: function () {
                return {
                    ONGOING: { id: 1, label: resx.localize('Ongoing') },
                    COMPLETED: { id: 2, label: resx.localize('Completed') }
                };
            },

            projectStatus: function () {
                return {
                    ONGOING: { id: 1, label: resx.localize('Ongoing') },
                    PREVIOUS: { id: 2, label: resx.localize('Previous') }
                };
            },

            recommendationStatus: function () {
                return {
                    ENTERED: { id: 0, label: resx.localize('Entered_F_P') },
                    ONGOING: { id: 1, label: resx.localize('Ongoing') },
                    COMPLETE: { id: 2, label: resx.localize('Completed_F_P') }
                };
            },

            serviceCallAddEntryStatus: function () {
                return {
                    YES: 1,
                    NO: 0,
                    MISSINGTEMPLATE: 2,
                    MISSINGCONTRACT: 3
                };
            },

            servicePdfStatus: function () {
                return {
                    NOTPROCESSED: '',
                    PENDING: 'P',
                    SUCCESS: 'S',
                    ERROR: 'E'
                };
            },

            recommendationTopics: function () {
                return {
                    SERVICECALL: { id: 1, label: resx.localize('ServiceCall'), global: false },
                    OTHER: { id: 2, label: resx.localize('Other'), global: true, default: true },
                    WORKORDER: { id: 3, label: resx.localize('WorkOrder'), global: false },
                    EQUIPMENT: { id: 4, label: resx.localize('Equipment'), global: true },
                    PROJECT: { id: 5, label: resx.localize('Project'), global: false },
                    HR: { id: 6, label: resx.localize('HR'), global: true }
                };
            },

            fileTransferStatus: {
                PENDING: "PENDING",
                ACTIVE: "ACTIVE",
                DONE: "DONE"
            },

            notificationCenterMessageType: {
                ALERT: "ALERT",
                SUCCESS: "SUCCESS",
                ERROR: "ERROR"
            },

            purchaseOrderStatus: {
                COMMITTED: '1',
                RESERVED: '2',
                ENTERED: 'A',
                CONFIRMED: 'P',
                ACTIVE: 'V',
                CLOSED: 'Z'
            },

            employeeEvaluationStatus: {
                NOTAVAILABLE: 0,
                AVAILABLE: 1,
                COMPLETE: 2
            },

            notificationCenterKey: {
                DOCUMENTQUEUESERVICE: 'documentQueueService',
                WORKFLOWAPPROVALSERVICE: 'workflowApprovalService',
                MAESTROSERVERCONNECTSERVICE: 'maestroServerConnectService'
            },

            inspectionTypes: {
                CHECK: 1,
                YESNO: 2,
                TEXT: 3,
                NUMERIC: 4,
                COUNTER1: 5,
                COUNTER2: 6,
                SIMPLE: 7,
                MULTIPLE: 8
            },

            checkedNumberValues: {
                UNCHECKED: "0",
                CHECKED: "-1"
            },

            yesNoNumberValues: {
                NO: "0",
                YES: "-1"
            },

            yesNoTaskValues: {
                NO: "N",
                YES: "O"
            },

            inspectionResults: {
                COMPLIANT: "C",
                NOTCOMPLIANT: "N"
            },

            workFlowTransactionType: function () {
                return {
                    CATALOGORDER: { id: 1, label: resx.localize('CatalogOrder') },
                    INVENTORYPROJECTTRANSFER: { id: 4, label: resx.localize('ProjectInventoryTransfer') },
                    INVENTORYSITETRANSFER: { id: 5, label: resx.localize('SiteInventoryTransfer') },
                    RECEIPTOFGOODS: { id: 12, label: resx.localize('ReceiptOfGoods') },
                    SUBCONTRACTORCONTRACT: { id: 13, label: resx.localize('SubcontractorContract') }
                }
            },

            attendanceTypes: function () {
                return {
                    EMPLOYEE: { id: 1, label: resx.localize('Employee') },
                    TEAM: { id: 2, label: resx.localize('Team') },
                    EQUIPMENT: { id: 3, label: resx.localize('Equipment') }
                };
            },

            paperSizes: function () {
                return {
                    Letter: { id: 'Letter', label: resx.localize('Letter') },
                    Legal: { id: 'Legal', label: resx.localize('Legal') }
                }
            },

            predefinedSentences: {
                options: {
                    projects: "001",
                    service_calls: "002",
                    fieldservices: "003",
                    recommendations: "004",
                    inspection: "005",
                    approval: "006",
                    timesheet: "007",
                    receiptOfGoods: "008"
                },
                contexts: {
                    comments: "001",
                    work_description: "002",
                    requests: "003",
                    documents: "004",
                    notes: "005",
                    materials: "006",
                    labors: "007",
                    absent: "008",
                    subcontractor: "009",
                    recommendation_description: "010",
                    recommendation_comments: "011",
                    recommendation_documents: "012",
                    evaluation: "013",
                    interruption_description: "014",
                    interruption_comments: "015",
                    inspection_question_typetext: "016",
                    inspection_question_note: "017",
                    inspection_result: "018",
                    project_recommendation_description: "019",
                    project_recommendation_comments: "020",
                    project_recommendation_documents: "021",

                }
            },

            retryOnDocumentFail: {
                RETRY: [0.5, 1, 1, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 30, 30, 30, 30]
            },

            copyDailyEntryOrWorkOrderChoices: function () {
                return {
                    RESSOURCESONLY: { id: 1, label: resx.localize('CopyRessourcesOnly') },
                    RESSOURCESANDTIME: { id: 2, label: resx.localize('CopyRessourcesAndHours') },
                    RESSOURCESANDTIMEANDSUBCONTRACTORS: { id: 3, label: resx.localize('CopyRessourcesAndHoursAndSubContractors') },
                    RESSOURCESANDTIMEANDMATERIALS: { id: 4, label: resx.localize('CopyRessourcesAndHoursAndMaterial') }
                }
            },

            userOptions: {
                PROJECT:                        {id: "PROJECT"  , parentId: null      },
                PROJECTBASEFONCTIONS:           {id: 8019       , parentId: "PROJECT" },
                DAILYENTRY:                     {id: 8001       , parentId: "PROJECT" },
                SUBCONTRACTOR:                  {id: 8002       , parentId: "PROJECT" },
                PROJECTSTOCKORDERFROMCATALOG:   {id: 8026       , parentId: "PROJECT" },
                MATERIALUSED:                   {id: 8003       , parentId: "PROJECT" },
                WEATHER:                        {id: 8004       , parentId: "PROJECT" },
                DAILYNOTE:                      {id: 8005       , parentId: "PROJECT" },
                PRODUCTION:                     {id: 8006       , parentId: "PROJECT" },
                INTERRUPTION:                   {id: 8007       , parentId: "PROJECT" },
                DOCUMENT:                       {id: 8008       , parentId: "PROJECT" },
                MATERIALREQUISITION:            {id: 8009       , parentId: "PROJECT" },
                RESOURCEREQUISITION:            {id: 8010       , parentId: "PROJECT" },
                PROJECTRECOMMENDATION:          {id: 8011       , parentId: "PROJECT" },
                SKILLCERTIFICATE:               {id: 8012       , parentId: "PROJECT" },
                SERVICE:                        {id: 8013       , parentId: null      },
                SERVICEMATERIALREQUISITION:     {id: 8022       , parentId: "SERVICE" },
                SERVICESTOCKORDERFROMCATALOG:   {id: 8023       , parentId: "SERVICE" },
                SERVICERECOMMENDATION:          {id: 8024       , parentId: "SERVICE" },
                SERVICEQUOTATIONS:              {id: 8025       , parentId: "SERVICE" },
                FIELDSERVICE:                   {id: 8014       , parentId: null      },
                TIMESHEET:                      {id: 8015       , parentId: null      },
                RECOMMENDATION:                 {id: 8016       , parentId: null      },
                INSPECTION:                     {id: 8017       , parentId: null      },
                APPROVAL:                       {id: 8018       , parentId: null      },
                DAILYENTRYEQUIPMENTCOUNTERS:    {id: 8020       , parentId: "PROJECT" },
                DAILYENTRYTASKSPROGRESS:        { id: undefined , parentId: "PROJECT" },
                DAILYENTRYWORKTASKS:            { id: 8033      , parentId: "PROJECT" },
                EQUIPMENTCOUNTERS:              { id: 8021, parentId: "TIMESHEET" },
                FIELDSERVICESTOCKORDERFROMCATALOG: { id: 8027, parentId: null },
                RECEIPTOFGOODS:                 { id: 8028, parentId: null },
                TIMEKEEPING:                    { id: 8029, parentId: null },
                INVENTORYPROJECTTRANSFER:       { id: 8030, parentId: null },
                INVENTORYSITETRANSFER:          { id: 8031, parentId: null },
                MAESTROBI_HOME:                 { id: 8032, parentId: null },
                SERVICEINSPECTION:              { id: 8034, parentId: null },
                PHYSICALINVENTORYCOUNT:         { id: 8035, parentId: null },
                SERVICEQUOTATIONSASDESKTOP:     { id: 9977, parentId: "SERVICE" },
                SERVICEQUOTATIONSBTDESKTOP:     { id: 10016, parentId: "SERVICE" },
            },

            navigationModelNames: {
                PROJECT: "Project",
                SERVICE: "Service",
                FIELDSERVICE: "FieldService",
                RECOMMENDATION: "Recommendation",
                APPROVAL: "Approval",
                INSPECTION: "Inspection",
                TIMESHEET: "Timesheet"
            },

            materialItemType: function () {
                return {
                    DEFAULT: { id: 0, label: resx.localize('Material') },
                    SALARY: { id: 1, label: resx.localize('Salary') },
                    MATERIAL: { id: 2, label: resx.localize('Material') },
                    SUBCONTRACTOR: { id: 3, label: resx.localize('Equipment') },
                    EQUIPMENT: { id: 4, label: resx.localize('Misc') },
                    MISC: { id: 5, label: resx.localize('Subcontractor') }
                }
            },

            timesheetStatus: {
                ENTERED:        { id: '1', icon: '' },
                CONFIRMED:      { id: '2', icon: '' },
                TRANSFERRED:    { id: '3', icon: '' },
                INPROGRESS:     { id: '4', icon: 'fa-hourglass-half' },
                COMPLETED:      { id: '5', icon: ' fa-calendar-check-o' },
                APPROVED:       { id: '6', icon: 'fa-thumbs-o-up' }
            },

            timesheetEntryType: {
                LABOR: 1,
                EQUIPMENT: 2,
                BONUS: 3
            },

            DailyEntryStatus: {
                INPROGRESS : "2",
                INPROGRESSGP : "3",
                COMPLETE : "5",
                APPROVED : "8",
                ENTERED : "A",
                ACCEPTED : "V",
                RETURNED : "X",
                TRANSFERRED : "Z"
            },

            QuantityMode: function () {
                return {
                    inventory: { id: 0, label: resx.localize('Inventaire') },
                    supplier: { id: 1, label: resx.localize('Supplier') }
                };
            },

            serviceCallMetricDtistances: function () {
                return [{ id: "-1", text: resx.localize("AllRanges") },
                { id: "1", text: '1 ' + resx.localize("Kilometer") },
                { id: "5", text: '5 ' + resx.localize("Kilometers") },
                { id: "15", text: '15 ' + resx.localize("Kilometers") },
                { id: "25", text: '25 ' + resx.localize("Kilometers") }
                ];
            },

            serviceCallImperialDtistances: function () {
                return [{ id: "-1", text: resx.localize("AllRanges") },
                { id: "1", text: '1 ' + resx.localize("Mile") },
                { id: "3", text: '2 ' + resx.localize("Miles") },
                { id: "10", text: '10 ' + resx.localize("Miles") },
                { id: "16", text: '16 ' + resx.localize("Miles") }
                ];
            }
        };

        return exports;
    });
