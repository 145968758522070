import UserSettingService from "services/userSettingsService";
import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import { EquipmentService } from "services/equipment-service";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import queryStringHelper from "helpers/queryStringHelper";
import services from "services/serviceService";

import dateHelper from "helpers/dateHelper";
import { MaCardOptionsModel, MaCardLineModel } from "components/layout/ma-card/ma-card-options.model";
import { EquipmentEditParameters } from "components/pages/services/equipment-details/equipment-details-informations";
import RouteRepository from "repositories/routeRepository";
import Parse from "helpers/parse";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { ServiceCallContractEquipmentStatusModel } from "api/models/company/service/service-call-contract-equipment-status-model";
import { default as _ } from "underscore";

@autoinject
export class Equipment {
    @bindable public equipmentsCards!: MaCardOptionsModel[];
    @bindable public isFilterActive: boolean = false;
    public allowedServiceCallContractEquipmentManagement: boolean = false;
    public maintenanceEquipmentCount: number = 0;

    public context: any = this;

    private dispatchId: number = 0;
    private entityId: number | null = null;
    private serviceType: string = "";
    private readonly: boolean = false;
    private presence: any;
    
    private equipments: ServiceCallContractEquipmentModel[] | null = null;

    private i18n: I18N;
    private equipmentService: EquipmentService;

    constructor(i18n: I18N, equipmentService: EquipmentService, private readonly routeRepository: RouteRepository, private readonly serviceCallContractEquipmentService: ServiceCallContractEquipmentService) {
        this.equipmentService = equipmentService;
        this.i18n = i18n;
        this.routeRepository = routeRepository;
        this.serviceCallContractEquipmentService = serviceCallContractEquipmentService;
    }

    public async activate(params: any): Promise<void> {
        const queryResults = routerHelper.getQuerystring(params.q);

        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.entityId = queryResults.entityId;
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;
        this.presence = queryResults.presence;
        const isFilterActiveResult = await UserSettingService.getOnlyActiveServiceCallContractEquipmentSetting();
        this.isFilterActive = Parse.Boolean((isFilterActiveResult !== null ? isFilterActiveResult.Value : false));
        this.allowedServiceCallContractEquipmentManagement = await this.serviceCallContractEquipmentService.allowedServiceCallContractEquipmentManagement();

        if (queryResults.maintenanceEquipmentCount) {
            this.maintenanceEquipmentCount = Parse.Integer(queryResults.maintenanceEquipmentCount);
        }
        
        await this.loadData();
    }

    public async saveFilterChange(): Promise<void> {
        this.isFilterActive = !this.isFilterActive;
        await UserSettingService.setOnlyActiveServiceCallContractEquipmentSetting(this.isFilterActive.toString());

        await this.loadData();
    }

    public createCard(item: ServiceCallContractEquipmentModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            displayLines: [new MaCardLineModel({ isCustomTemplate: true, class: "p-1"})],
        });

        return card;
    }

    public addEquipement(): void {
        const params = {
            serviceType: this.serviceType,
            dispatchId: this.dispatchId,
            equipmentId: 0,
            isNewEquipment: true,
            isReplacement: false,
            allowedServiceCallContractEquipmentManagement: this.allowedServiceCallContractEquipmentManagement,
            readonly: false
          } as EquipmentEditParameters;

        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_Equipment_Add.name, params);
    }

    public async searchEquipment(value: string): Promise<void> {
        this.equipments = await services.getEquipments(this.serviceType, this.dispatchId, value);
        this.equipmentsCards = this.equipments!.map(this.createCard);
    }

    private async loadData(): Promise<void> {
        this.equipments = await services.getEquipments(this.serviceType, this.dispatchId);
        this.equipmentsCards = this.equipments!.sort((equipA: ServiceCallContractEquipmentStatusModel, equipB: ServiceCallContractEquipmentStatusModel) => (equipA.IsPlanned ? -1 : 1) - (equipB.IsPlanned ? -1 : 1) || equipA.Code!.localeCompare(equipB.Code!)).map(this.createCard);
    }

}
