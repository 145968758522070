import { bindingMode, autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import { Position } from "enums/position";
import { ViewModeHelper } from "helpers/view-mode-helper";

@autoinject()
export class CheckboxFc extends FormControl {

    @bindable.booleanAttr({ defaultBindingMode: bindingMode.twoWay })
    public checked: boolean = false;

    @bindable.string
    public labelPosition: Position = Position.Top;

    @bindable
    public size?: number = 1;

    @bindable
    public theme?: string = "primary";

    constructor(element: Element, private readonly viewModeHelper: ViewModeHelper) {
        super(element);
    }

    public attached(): void {
         if (this.viewModeHelper.getIsMobileMode()) {
             this.labelPosition =  Position.Top;
         }
    }
}
