import { DOM } from "aurelia-framework";

export class CustomEventHelper {

    /**
     * Creates and dispatches a CustomEvent for an element.
     * @param element
     * @param eventType
     * @param detail
     * @param bubbles
     * @param cancelable
     */
    public static dispatchEvent<TDetail>(element: Element, eventType: string, detail: TDetail, bubbles: boolean = false, cancelable: boolean = false): boolean {
        const options: CustomEventInit<TDetail> = {
            detail: detail,
            bubbles: bubbles,
            cancelable: cancelable,
        };

        const event: CustomEvent = DOM.createCustomEvent(eventType, options);

        return element.dispatchEvent(event);
    }
}
