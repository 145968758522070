import { autoinject } from "aurelia-framework";
import documentHelper from "helpers/documentHelper";
import { LocalizationHelper } from "helpers/localization-helper";

@autoinject()
export class FileSizeFormatValueConverter {
    public toView(value: any): string {
        if (value === null || value === undefined || typeof value !== "number") {
            return "";
        }

        return documentHelper.formatDocumentSize(value);
    }
}
