import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";

import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { WorkOrderWorkTaskProxy } from "api/proxies/work-order-work-task-proxy";
import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import { WorkOrderWorkTaskModel } from "api/models/company/workorder/work-order-work-task-model";

@autoinject()
export class WorkOrderWorkTaskService {
    constructor(private readonly workOrderWorkTaskProxy: WorkOrderWorkTaskProxy) {}

    public async GetWorkTasksByDispatchId(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskModel[] | null> {
       return this.workOrderWorkTaskProxy.GetWorkTasksByDispatchId(dispatchId, filter, pagingInfo, requestConfig);
    }

    public async GetWorkTasksByWorkOrderId(workOrderId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskModel[] | null> {
       return this.workOrderWorkTaskProxy.GetWorkTasksByWorkOrderId(workOrderId, filter, pagingInfo, requestConfig);
    }

    public async GetNewWorkTaskByDispatchId(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskDetailsModel | null> {
        return this.workOrderWorkTaskProxy.GetNewWorkTaskByDispatchId(dispatchId, requestConfig);
    }

    public async GetNewWorkTaskByWorkOrderId(workOrderId: string | null, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskDetailsModel | null> {
       return this.workOrderWorkTaskProxy.GetNewWorkTaskByWorkOrderId(workOrderId, requestConfig);
    }

    public async DeleteWorkTask(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.workOrderWorkTaskProxy.DeleteWorkTask(transactionNumber, requestConfig);
    }

    public async GetWorkTask(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskDetailsModel | null> {
        return this.workOrderWorkTaskProxy.GetWorkTask(transactionNumber, requestConfig);
    }

    public async SaveWorkTask(workTask: WorkOrderWorkTaskDetailsModel | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
       return this.workOrderWorkTaskProxy.SaveWorkTask(workTask, requestConfig);
    }
}
