import { AdditionalFieldDefinitionHelper } from "./interfaces/additional-field-definition-helper";
import dateHelper from "helpers/dateHelper";
import { isObject } from "util";
import { AdditionalFieldDefinitionSaveHelper } from "./interfaces/additional-field-definition-save-helper";

export class AdditionalFieldLinkedDropdownHelper implements AdditionalFieldDefinitionHelper, AdditionalFieldDefinitionSaveHelper {
  public getValue(item: any): any {
    return {
      id: item.Value,
      text: item.Value,
      data: item
    };
  }

  public getSavedValue(item: any): string {
    if (!item.Value) {
      return "";
    }

    return isObject(item.Value.id)
      ? item.Value.id.id
      : item.Value.id;
  }
}
