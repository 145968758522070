import { JobModelStatus } from "api/enums/job-model-status";
import { ProcoreDirectCostTransactionType } from "api/enums/procore-direct-cost-transaction-type";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ValidationError } from "api/enums/validation-error";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { BaseLoaderEntity } from "api/models/procore/loaders/base-loader-entity";
import { EmployeeLoaderEntity } from "api/models/procore/loaders/employee-loader-entity";
import { ProjectLoaderEntity } from "api/models/procore/loaders/project-loader-entity";
import { TransactionLoaderEntity } from "api/models/procore/loaders/transaction-loader-entity";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { DateFormatValueConverter } from "converters/format/date-format";
import { ProcoreGridGroup } from "pages/procore/synchronization/composition/grid/models/procore-grid-group";
import { ProcoreGridItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-item";
import { SideBar } from "pages/procore/synchronization/composition/side-bar/side-bar";
import { ProcoreExceptionHelper } from "pages/procore/synchronization/helpers/procore-exception-helper";

@autoinject
export class ProcoreGridItemTransformer {
  constructor(private readonly i18n: I18N,
              private readonly exceptionHelper: ProcoreExceptionHelper) {

  }

  public transform(items: Array<BaseLoaderEntity | ProjectLoaderEntity | EmployeeLoaderEntity> | null,
                   isDetails: boolean = false,
                   AdditionalField: (item: any) => string | null = (): string | null => null): ProcoreGridItem[] {

    if (!items) { return []; }

    const transformedItems = items.map((item: any): ProcoreGridItem | ProcoreGridGroup => {
      const language = localStorage.getItem("maestro_user_profile_LANGUAGE");
      const errors = this.transformErrors(item.Errors, language);
      const warnings = this.transformErrors(item.Warnings, language);

      const transaction = item as TransactionLoaderEntity;
      const transactionType = transaction ? transaction.TransactionType : null;

      const errorStatuses = [JobModelStatus.CriticalError,
                             JobModelStatus.Error];

      if (errorStatuses.includes(item.JobStatus)) {
        item.JobStatus = null;
      }

      return {
        EntityToSynchronize: item.ProcoreEntityType,
        Message: this.getMessage(errors, warnings),
        Code: item.Code,
        Description: item.Description,
        AdditionalField: AdditionalField(item),
        Errors: errors,
        Warnings: warnings,
        ParentCode: item.ParentCode,
        Children: this.transform(item.Children),
        Details: [],
        IsDetails: isDetails,
        IsGroup: item.IsGroup,
        Status: item.Status,
        IsSynchronized: item.IsSynchronized,
        Target: item.Target,
        Selected: false,
        IsSynchronizing: false,
        TransactionType: transactionType,
        DetailsOpen: false,
        ErrorsOpen : false,
        JobId : item.JobId,
        JobStatus : item.JobStatus,
        ProgressBarCurrent : item.ProgressBarCurrent,
        ProgressBarMax : item.ProgressBarMax,
        DetailsIsLoading: false,
        SynchronizationTried: false,
        DirectCostGroup: false,
        IsPccoChangeOrder: false,
        IsCommandContractTransaction: false,
        DirectCostType: ProcoreDirectCostTransactionType.Unclassified
      };
    });

    return transformedItems;
  }

  public transformErrors(errors: Array<ProcoreError | null> | null, language: string | null): string[] {
    if (!errors) { return []; }

    return this.removeDuplicates(errors).filter((error: ProcoreError | null) => !!error)
                 .map((error: ProcoreError): string => {
                    const message  = language === "fr" ? error.MessageFr : error.MessageEn;
                    if (!message) {

                      const entity: ProcoreEntityToSynchronize = error.Arguments && error.Arguments.length > 0 && error.Arguments[0]
                      ? error.Arguments[0]
                      : undefined;

                      const messageFromProcore = error.Arguments && error.Arguments.length > 1 && error.Arguments[1]
                                    ? error.Arguments[1]
                                    : undefined;

                      return this.exceptionHelper.getMessageFromProcoreExplicit(error.ErrorId, messageFromProcore, entity);
                    }

                    return message!;
                  });
  }

  public getMessage(errors: string[], warnings: string[]): string {
    errors = this.trimDuplicateErrors(errors);
    warnings = this.trimDuplicateErrors(warnings);

    let message = this.getErrorsMessage(errors);

    if (errors.length > 0 && warnings.length > 0) {
      message += "<br/>";
    }

    message += this.getWarningsMessage(warnings);

    return message;
  }

  public getMessageFromPollingSystem(errors: string[], warnings: string[], sidebar?: SideBar, LastUpdatedDate?: Date | null, deletedItemsCount?: number): string {
    let message = "";
    const normalMessage = this.getMessage(errors, warnings);
    
    if (!LastUpdatedDate || !sidebar) {
      return normalMessage;
    }

    const transactionsSelected = sidebar.transactionsSelected;
    const isToolsScreen = sidebar.synchronization && sidebar.synchronization.isTools;

    const hasErrors = errors.length > 0;

    if (isToolsScreen && deletedItemsCount !== null && deletedItemsCount !== undefined) {
      message += !hasErrors
                    ? this.getDeleteMessageSuccess(LastUpdatedDate, deletedItemsCount)
                    : this.getMessageFailure(LastUpdatedDate);
    }

    if (transactionsSelected) {
          message += !hasErrors
            ? this.getTransactionsMessageSuccess(LastUpdatedDate)
            : this.getMessageFailure(LastUpdatedDate);
    }

    return message + normalMessage;
  }

  public shortDateFormatter(date: Date): any {
    const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);

    return dateFormatValueConverter.toView(date, "YYYY-MM-DD HH:mm");
  }

  private getDeleteMessageSuccess(date: Date, deletedItemsCount: number): string {
    return this.getTransactionsMessage("Procore.Delete_success", date, deletedItemsCount);
  }

  private getTransactionsMessageSuccess(date: Date): string {
    return this.getTransactionsMessage("Procore.Sync_Success", date);
  }

  private getMessageFailure(date: Date): string {
    return this.getTransactionsMessage("Procore.Sync_Failure", date);
  }

  private getTransactionsMessage(ressourceKey: string, date: Date, deletedItemsCount?: number): string {
    let text = this.i18n.tr(ressourceKey);

    text = text.replace("{0}", this.shortDateFormatter(date));

    if (deletedItemsCount !== null && deletedItemsCount !== undefined) {
      text = text.replace("{1}", deletedItemsCount.toString());
    }
    
    return  `<p class='small my-2'>${text}</p>`;
  }

  private trimDuplicateErrors(errors: string[]): string[] {
    return [...new Set(errors)];
  }

  private getWarningsMessage(warnings: string[]): string {
    if (!this.HasItems(warnings)) { return ""; }

    const message =  warnings.length === 1
                      ? warnings[0]
                      : this.i18n.tr("Procore.GridWarnings").replace("{0}", warnings.length.toString());

    return `<warning-icon class="inline-error"></warning-icon>
            <procore-grid-warning class="inline-error"> ${message} </procore-grid-warning>`;
  }

  private getErrorsMessage(errors: string[]): string {
    if (!this.HasItems(errors)) { return ""; }

    const message =  errors.length === 1
                      ? errors[0]
                      : this.i18n.tr("Procore.GridErrors").replace("{0}", errors.length.toString());

    return `<error-icon class="inline-error"></error-icon>
            <procore-grid-error class="inline-error"> ${message} </procore-grid-error>`;
  }

  private HasItems(items: any[]): boolean {
    return items && items.length > 0 && items.filter((x: any) => x !== "").length > 0;
  }

  private removeDuplicates(errors: Array<ProcoreError | null> | null): ProcoreError[] {
    if (!errors) {return []; }

    const newList: ProcoreError[] = [];

    errors.forEach((error: ProcoreError | null) => {
      if (error != null) {
        const alredyInNewList = newList.some((x: ProcoreError) => x.MessageFr === error.MessageFr);

        if (!alredyInNewList) {
          newList.push(error);
        }
      }
    });

    return newList;
  }
}
