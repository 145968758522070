import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import documentHelper from "helpers/documentHelper";

export class InspectionDocument {

    public readonly: boolean = false;
    public inspectionId: any = null;

    public actions: any;

    public activate(params: any): void {
        this.inspectionId = params.inspectionId;
        this.actions = {
            getSections: documentService.getSectionsByInspectionId.bind(self, this.inspectionId),
            getSectionDocuments: documentService.getDocumentsByInspectionId.bind(self, this.inspectionId),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService) };
    }
}
