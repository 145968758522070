define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        // TODO: KG - ShowLoading: false - Transport
        getActivitiesForWorkOrder: function (workOrderId, type, filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getWorkOrderBaseUrl(workOrderId) + "/activities?activityType=" + type,
                    filter,
                    page)
            );
        },

        getActivityForProject: function (projectCode, activityCode) {
            const url = apiHelper.getProjectBaseUrl(projectCode) + "/activities/" + activityCode;
            return requests.getJson(url).promise();
        },

        getActivity: function (activityCode) {
            const url = `${apiHelper.getProjectBaseUrl()}/activity/${activityCode}`;
            return requests.getJson(url).promise();
        },

        getActivitiesForProject: function (projectCode, filter, page) {
            let url = apiHelper.getProjectBaseUrl(projectCode) + "/activities";
            url = apiHelper.appendFilterPagingParams(url, filter, page);
            return requests.getJson(url).promise();
        },

        getActivitiesProgressForDailyEntry: function (dispatchProjectCode, dispatchDate) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/progress";
            return requests.getJson(url);
        },

        getActivityProgressForDailyEntry: function (dispatchProjectCode, dispatchDate, activityId) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/progress/" + activityId;
            return requests.getJson(url);
        },

        setActivityProgressForDailyEntry: function (dispatchProjectCode, dispatchDate, activityId, rate) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/progress/" + activityId + "/" + rate;
            return requests.post(url);
        }
    };

    return exports;
});