import { default as documentService } from "services/documentService";
import employeeService from "services/employeeService";
import { SettingRepository } from "repositories/setting-repository";
import { autoinject } from "aurelia-framework";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class EmployeeProfilePicture extends FormBase {
    public dispatchId: string | null = "";
    public employeeId: any = "";
    public readonly: boolean = false;
    public actions: any;

    public dispatchProjectCode: any;
    public dispatchDate: any;
    public workOrderId: any;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly settingRepository: SettingRepository) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<any> {
        this.dispatchId = params.dispatchId ? params.dispatchId : this.settingRepository.getDispatchTemplate();
        this.employeeId = params.employeeId ? params.employeeId : await employeeService.getCurrentEmployeeId();

        this.dispatchProjectCode = params.dispatchProjectCode ? params.dispatchProjectCode : null;
        this.dispatchDate = params.dispatchDate ? params.dispatchDate : null;
        this.workOrderId = params.workOrderId ? decodeURIComponent(params.workOrderId) : null;

        this.actions = {
            save: documentService.saveEmployeePicture.bind(self, this.employeeId)
        };
    }
}
