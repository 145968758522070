// GENERATED FILE - DO NOT MODIFY
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DocumentExtensionProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetExtensions(requestConfig?: HttpClientRequestConfig): Promise<string | null> {
        const uri = this.buildUri(
            "/extensions",
            null,
            null
        );

        const data: string | null = await this.get<string | null>(uri, requestConfig);
        return data;
    }
}
