export class ElementClickOutsideDetector {
    constructor(
        private readonly elementSelector: () => HTMLElement[],
        private readonly onClickAction: (event: Event) => void
    ) {
    }

    public enable(): void {
        document.addEventListener("click", this.onDocumentClickDelegate);
    }

    public disable(): void {
        document.removeEventListener("click", this.onDocumentClickDelegate);
    }

    private onDocumentClickDelegate = (event: Event): void => this.onDocumentClick(event);

    private onDocumentClick(event: Event): void {
        const elementContainingTheClick = this.elementSelector().find((element: any) => element.contains(event.target)); // or use: event.target.closest(selector) === null

        if (!elementContainingTheClick) {
            this.onClickAction(event);
        }
    }
}
