import { autoinject, bindable, transient } from "aurelia-framework";

@transient()
@autoinject
export class BreakTimePicker {

    @bindable public label: string = "";
    @bindable public startTime: Date | null = null;
    @bindable public endTime: Date | null = null;
    @bindable public readonly: boolean = false;
    @bindable public deleteAction: any;
    @bindable public increment?: number;
    @bindable public timeOptions?: any[];
}
