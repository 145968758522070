export interface BaseShortcutKeys {
    SwitchTab: string;
    Refresh: string;
    Save: string;
}

export class HostedShortcutKeys implements BaseShortcutKeys {
    public SwitchTab: string  = "ctrl+t";
    public Refresh: string = "f5";
    public Save: string = "f12";
}

export class DevShortcutKeys  implements BaseShortcutKeys {
    public SwitchTab: string  = "ctrl+alt+t";
    public Refresh: string = "ctrl+alt+f5";
    public Save: string = "ctrl+alt+f12";
}
