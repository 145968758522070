import { default as requests } from "services/requests";
import { default as apiHelper } from "helpers/apiHelper";

export default class AssignmentService {
    getAssignments(minDate, maxDate) {
        return requests.getJson(apiHelper.getBaseUrl() + "/employees/current/assignments" + "?minDate=" + minDate + "&maxDate=" + maxDate).promise();
    }

    saveCalendarViewSetting(view) {
        return requests.post(apiHelper.getBaseUrl() + "/employees/current/assignments/calendar-view-setting", view).promise();
    }

    getCalendarViewSetting() {
        return requests.getJson(apiHelper.getBaseUrl() + "/employees/current/assignments/calendar-view-setting").promise();
    }
}