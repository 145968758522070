import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject()
export class ExcelExporter {
  @bindable
  public data: Uint8Array | null = null;
  public name: string = "export";

  public async ExportExcel(): Promise<void> {
    if (this.data == null) {
        return;
    }

    const blob = new Blob([this.base64toBlob(this.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")], {});
    
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = `${this.name}.xlsx`;
    link.download = fileName;
    link.click();
  }

  private base64toBlob(base64Data: any, contentType: string): Blob {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
