import { PhysicalInventoryService } from "./../../services/physical-inventory-service";
import RouteRepository from "repositories/routeRepository";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";

import { Tab } from "components/layout/tab";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";

import { PhysicalInventoryModel } from "api/models/company/physical-inventory-model";
import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { PhysicalInventoryMobileStatus } from "api/enums/physical-inventory-mobile-status";

@autoinject
export class PhysicalInventoryIndex {
    @bindable public currentTabId: number = 0;

    public onGoingPhysicalInventoryCards: MaCardOptionsModel[] = [];
    public closedPhysicalInventoryCards: MaCardOptionsModel[] = [];
    public isLoading: boolean = true;

    constructor(private readonly i18n: I18N, private readonly physicalInventoryService: PhysicalInventoryService, private routeRepository: RouteRepository, public readonly enumFormatValueConverter: EnumFormatValueConverter) {}

    public async attached(): Promise<void> {
        await this.currentTabIdChanged();
    }

    public activate(params: any): void {
        this.isLoading = false;
    }

    public async currentTabIdChanged(): Promise<void> {
        if (this.isLoading || this.isLoading === undefined) {
            return;
        }

        this.onGoingPhysicalInventoryCards = [];
        this.closedPhysicalInventoryCards = [];
       
        if (this.currentTabId < 1) {
            const result = await this.physicalInventoryService.GetPhysicalInventory(false);
            result!.forEach((item: PhysicalInventoryModel) => {
                this.onGoingPhysicalInventoryCards.push(this.createCard(item, false));
            });
        } else {
            this.physicalInventoryService.GetPhysicalInventory(true).then((result: PhysicalInventoryModel[]): any => {
                result.forEach((item: PhysicalInventoryModel) => {
                    this.closedPhysicalInventoryCards.push(this.createCard(item, true));
                });
            });
        }
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (Number(event.detail.tabId) === this.currentTabId) {
            return;
        }
        this.currentTabId = Number(event.detail.tabId);
    }

    public add(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.PhysicalInventoryCount_Edit.name, { id: 0 });
    }

    public edit(item: PhysicalInventoryModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.PhysicalInventoryCount_Edit.name, { id: item.Id });
    }

    public delete(item: PhysicalInventoryModel): void {
        const dialogMessage = `${this.i18n.tr("msg_DeletePhysicalInventoryConfirmationText")} (${this.getCounterDisplay(item)})`;

        notificationHelper.showDialogYesNo(dialogMessage).then((success: boolean): any => {
            if (success) {
                this.physicalInventoryService.DeletePhysicalInventory(item.Id).then((result: boolean): any => {
                    if (result) {
                        this.removePhysicalInventoryFromList(item.Id);
                        notificationHelper.showSuccess(this.i18n.tr("Procore.Status_Success"));
                    }
                });
            }
        });
    }

    public createCard(item: PhysicalInventoryModel, isClosed: boolean): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getCounterDisplay(item) }),
                new MaCardLineModel({ display: this.getDateDisplay(item), icon: "fa fa-plus text-success pr-1" }),
                new MaCardLineModel({ display: this.getStatusDisplay(item), class: "font-weight-bold" }),
                new MaCardLineModel({ display: this.getCreatedByDisplay(item), class: "mt-2" }),
           
            ],
            actionItems: [new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.edit.bind(this, item) })],
        });

        if (!isClosed) {
            card.actionItems!.push(new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item) }));
        }
        return card;
    }

    public getCounterDisplay(item: PhysicalInventoryModel): string {
        return `${this.i18n.tr("Counter")}: ${item.Id}`;
    }

    public getDateDisplay(item: PhysicalInventoryModel): string {
        return `${this.i18n.tr("Added")} ${dateHelper.formatDate("DD MMM YYYY", item.AccountingDate!)}`;
    }

    public getStatusDisplay(item: PhysicalInventoryModel): string {
        return item.MobileStatus === PhysicalInventoryMobileStatus.Active ? this.i18n.tr("InProgress") : item.MobileStatus === PhysicalInventoryMobileStatus.Confirmed ? this.i18n.tr("Confirmed_F") : this.i18n.tr("Completed_F");
    }

    public getCreatedByDisplay(item: PhysicalInventoryModel): string {
        return `${this.i18n.tr("CreatedBy")} ${item.CreatedBy}`;
    }

    private removePhysicalInventoryFromList(id: number): void {
        this.onGoingPhysicalInventoryCards = this.onGoingPhysicalInventoryCards.filter((item: MaCardOptionsModel) => {
            return id !== item.model.Id;
        });
    }
}
