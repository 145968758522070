import { WorkOrderPriorityProxy } from "./../api/proxies/work-order-priority-proxy";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";

import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { WorkOrderPriorityModel } from "api/models/company/workorder/work-order-priority-model";

@autoinject()
export class WorkOrderPriorityService {
    constructor(private readonly workOrderPriorityProxy: WorkOrderPriorityProxy) {}

    public async GetPriorities(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderPriorityModel[] | null> {
       return this.workOrderPriorityProxy.GetPriorities(filter, pagingInfo, requestConfig);
    }
}
