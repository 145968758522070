import documentService from "services/documentService";
import documentHelper from "helpers/documentHelper";
import routerHelper from "helpers/routerHelper";

export class MaterialOrderDocument {
  public dispatchId?: string;

  public readonly: boolean = false;
  public actions: any = {};

  public activate(params: any): void {
    const paramsQueryString = routerHelper.getQuerystring(params.q);
    this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");

    this.setupHeaderInfos(params.dispatchId);
    this.setAction(params.orderId);
  }

  private setupHeaderInfos(dispatchId: string): void {
      this.dispatchId = dispatchId;
  }

  private setAction(orderId: string): void {
      this.actions = {
          getSections: documentService.getSectionsByPurchaseOrderId.bind(self, orderId),
          getSectionDocuments: documentService.getDocumentsByPurchaseOrderId.bind(self, orderId),
          getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
          deleteDocument: documentService.deleteDocument.bind(documentService)
        };
  }
}
