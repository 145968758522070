import { autoinject, bindingMode } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import Parse from "helpers/parse";
import { SelectItem, resolveItemPathAsString } from "models/select-item";
import { default as defaultService } from "services/defaultService";
import { CustomEventHelper } from "helpers/custom-event-helper";

@autoinject()
export class Lister<T> extends FormControl {
    @bindable.booleanAttr //
    public allowClear: boolean = false;

    @bindable.string //
    public icon: string = "";

    @bindable.number({ defaultValue: defaultService.getMinimumResultsForSearch() })
    public minimumResultsForSearch!: number;

    @bindable //
    public options: T[] = [];

    @bindable.string //
    public placeholderKey: string = "SelectOoo";

    @bindable.booleanAttr //
    public showButtons: boolean = false;

    @bindable.booleanAttr //
    public showSearchBox: boolean = false;

    @bindable.booleanAttr //
    public showPlaceholder: boolean = false;

    @bindable.string //
    public valuePath: string = "id";

    @bindable.string //
    public textPath: string = "text";

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selected: T | null = null;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public value: string | null = null;

    @bindable.booleanAttr //
    public prependValue: boolean = false;

    /* tslint:disable:member-ordering */
    /**
     * This function is called to produce the text to display for an item
     */
    @bindable
    public textConverter = ({ item }: { item: T | null }): string => {
        if (!item) {
            return "";
        }

        if (this.prependValue) {
            return resolveItemPathAsString(item, this.valuePath) + " - " + resolveItemPathAsString(item, this.textPath);
        }

        return resolveItemPathAsString(item, this.textPath);
    };

    public selectItems: Array<SelectItem<T>> = [];

    private isInitialised: boolean = false;

    constructor(element: Element) {
        super(element);
    }

    public bind(): void {
        this.isInitialised = true;
        this.initSelectItems();

        if (this.selected) {
            this.selectedChanged(this.selected);
        } else if (this.value) {
            this.valueChanged(this.value);
        } else if (this.selectItems.length && !this.showPlaceholder) {
            // Select the first item by default if no initial value in the model.
            this.value = resolveItemPathAsString(this.selectItems[0].data, this.valuePath);
        }
    }

    public unbind(): void {
        this.isInitialised = false;
    }

    // #region Observers
    public optionsChanged(): void {
        this.initSelectItems();
    }

    public textConverterChanged(): void {
        this.initSelectItems();
    }

    public selectedChanged(newValue: T | null): void {
        this.value = resolveItemPathAsString(newValue, this.valuePath);
    }

    public valueChanged(newValue: string): void {
        this.selected = newValue ? this.findOptionFromValue(newValue) : null;

        CustomEventHelper.dispatchEvent(this.element,
            "value-changed",
            newValue ? newValue : null,
            false,
            false);

    }
    // #endregion

    // #region Privates
    public findOptionFromValue(value: string): T | null {
        value = Parse.String(value);

        return this.options.find((t: T): boolean => resolveItemPathAsString(t, this.valuePath) === value) || null;
    }

    public initSelectItems(): void {
        if (this.isInitialised === false) {
            // Wait until both the options and the textConverter are set before initialising the list.
            return;
        }

        const options = this.options;

        if (!options || !Array.isArray(options)) {
            this.selected = null;
            this.selectItems = [];
            return;
        }

        this.selectItems = options.map((t: T) => {
            return new SelectItem(t, (tt: T): string => resolveItemPathAsString(tt, this.valuePath), (tt: T): string => this.textConverter({ item: tt }));
        });
    }
    // #endregion
}
