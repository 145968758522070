define([
        "services/requests",
        "helpers/apiHelper"
],
    function (requests, apiHelper) {
        "use strict";

        var exports = {
            getTimesheetEmployeeLinkedEquipmentsWithCounters: (employeeId) => {
                let url = `${apiHelper.getTimesheetEquipmentCounterBaseUrl()}/linkedEquipmentsWithCounters`;
                
                if (employeeId) {
                    url += "?employeeId=" + employeeId;
                }
    
                return requests.getJson(url);
            }
        };

        return exports;
    });