import { autoinject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";
import { KeyValuePair } from "interfaces/key-value-pair";
import { ProcoreGridColumnEnum } from "../procore-grid-column-enum";
import { ProcoreGridConfigurations } from "../procore-grid-configurations";
import { BaseColumnsConfigurator } from "./base-columns-configurator";

@autoinject
export class DeleteDirectCostColumnsConfigurator extends BaseColumnsConfigurator {
    public enabledColumns: ProcoreGridColumnEnum[] = [ProcoreGridColumnEnum.Target,
                                                      ProcoreGridColumnEnum.Code,
                                                      ProcoreGridColumnEnum.Description,
                                                      ProcoreGridColumnEnum.Message];

    public disabledColumns: ProcoreGridColumnEnum[] = [ProcoreGridColumnEnum.AdditionalField];

    public headerChanges: Array<KeyValuePair<ProcoreGridColumnEnum, string>> = [{ key: ProcoreGridColumnEnum.Target, value: this.i18n.tr("Procore.DeleteDirectCosts_Target") }];

    constructor(private readonly i18n: I18N,
                protected readonly gridConfiguration: ProcoreGridConfigurations) {
        super(gridConfiguration);
        this.warningFooter = this.i18n.tr("Procore.DeleteDirectCosts_Footer");
        this.ShowCheckboxOnSuccess = true;
    }
}
