import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { DailyEntryCancelDispatchService } from "services/daily-entry-cancel-dispatch-service";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class AttendancesCancel extends FormBase {

    public readonly: boolean = false;
    public isTeamLeader: boolean = true;
    public dispatchProjectCode: any = null;
    public dispatchDate: any = null;
    public cancelReason: string | null = "";
    public origCancelReason: string | null = "";
    public element: HTMLTextAreaElement | null = null;
    public params: any | null = null;

    private minPaddingNoScroll: number = 2;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly dailyEntryCancelDispatchService: DailyEntryCancelDispatchService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.origCancelReason !== this.cancelReason;
    }

    public async activate(params: any): Promise<void> {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dispatchDate = params.dailyEntryDate;

        this.params = routerHelper.getQuerystring(params.q);
    }

    public attached(): void {
        this.resize();
    }

    public close(): void {
        routerHelper.navigateBack();
    }

    public async save(): Promise<void> {
        if (await this.confirmCancel()) {
            this.origCancelReason = this.cancelReason;
            await this.dailyEntryCancelDispatchService.CancelDispatch(this.dispatchProjectCode, this.dispatchDate, this.cancelReason);

            let url = routerHelper.navigateTo("Project_Detail", this.dispatchProjectCode);
            url += routerHelper.addQuerystring(this.params);
            routerHelper.navigate(url, { replace: true, trigger: true });
        } else {
            routerHelper.navigateBack();
        }
    }

    private async confirmCancel(): Promise<boolean> {
        return await this.notificationHelper.showConfirmation(this.i18n.tr("msg_DeleteEntryCancelAllDispatchesConfirmationText"));
    }

    private resize(): void {
        if (this.element) {
            this.element.style.width = "100%";
            this.element.style.height = this.element.scrollHeight + this.minPaddingNoScroll + "px";
        }
    }
}
