import { singleton } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";

@singleton()
export class GlobalErrorCatcher {
    private readonly eventAggregator: EventAggregator;
    private initialized: boolean = false;
    private originalJQueryExceptionHook: null | ((error: any, stack: any) => void) = null;

    constructor(eventAggregator: EventAggregator) {
        this.eventAggregator = eventAggregator;
    }

    public init(): void {
        if (this.initialized) {
            return;
        }

        this.setupWindowErrorHandler();
        this.setupUnhandledRejectionHandler();
        this.setupjQueryDeferredExceptionHandler();

        this.initialized = true;
    }

    private setupWindowErrorHandler(): void {
        // When using this line (addEventListener) the stack trace is available when debugging in Chrome but not available for logging.
        // Changed to use the injected code to make this work. See https://stackoverflow.com/questions/20323600/how-to-get-errors-stack-trace-in-chrome-extension-content-script
        //window.addEventListener("error", onWindowError);
        document.addEventListener("ReportError", (e: any): void => this.onWindowError(e.detail));

        // Inject the error handler code
        const script = document.createElement("script");

        script.textContent =
            `(function() {
            window.addEventListener("error", function (e) {
                e.preventDefault();

                var error = {
                    message: e.error.message,
                    Error: e.error,
                    filename: e.filename,
                    lineno: e.lineno,
                    colno: e.colno,
                    stack: e.error.stack
                };

                document.dispatchEvent(new CustomEvent("ReportError", { detail: error }));
            });
        })();`;

        (document.head || document.documentElement).appendChild(script);
        (script.parentNode as Node).removeChild(script);
    }

    private setupUnhandledRejectionHandler(): void {
        window.addEventListener("unhandledrejection", (e: Event): void => this.onUnhandledRejection(e));
    }

    private setupjQueryDeferredExceptionHandler(): void {
        if (!jQuery.Deferred.exceptionHook) {
            return;
        }

        this.originalJQueryExceptionHook = jQuery.Deferred.exceptionHook;
        jQuery.Deferred.exceptionHook = (error: any, stack: any): void => this.onJQueryDeferredException(error, stack);
    }

    private onWindowError(error: any): void {
        this.eventAggregator.publish("error:windowerror", error);
    }

    private onUnhandledRejection(event: any): void {
        event.preventDefault();
        this.eventAggregator.publish("error:unhandledrejection", event);
    }

    private onJQueryDeferredException(error: any, stack: any): void {
        if (this.originalJQueryExceptionHook) {
            this.originalJQueryExceptionHook(error, stack);
        }

        this.eventAggregator.publish("error:jquerydeferredexception", { error: error, stack: stack });
    }
}
