import { autoinject } from "aurelia-framework";
import { ValidationController, ControllerValidateResult, ValidateResult } from "aurelia-validation";

import { NotificationHelper } from "helpers/notification-helper";

@autoinject
export class ValidationHelper {
    constructor(private readonly notificationHelper: NotificationHelper) {}

    public async validateController(controller: ValidationController): Promise<boolean> {
        const validationResults: ControllerValidateResult = await controller.validate();
        return validationResults.valid === true;
    }

    public async validateControllerAndNotifyUserIfNecessary(controller: ValidationController): Promise<boolean> {
        const validationResults: ControllerValidateResult = await controller.validate();

        if (validationResults.valid === true) {
            return true;
        }

        const errorMessageArray: string[] = validationResults.results
            .filter((resultErr: ValidateResult) => !resultErr.valid)
            .map((value: ValidateResult): string => (value.message || value.propertyName) as string);

        this.notificationHelper.showValidationError(errorMessageArray);
        return false;
    }

    public stringIsNullOrEmpty(value: string | null): boolean {
        let isNullOrEmpty = true;
        if (value && typeof (value) === "string") {
            isNullOrEmpty = !(value.trim().length > 0);
        }
        return isNullOrEmpty;
    }
}
