// GENERATED FILE - DO NOT MODIFY
import { ProjectGroupModel } from "api/models/company/project/project-group-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class GroupProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        const uri = this.buildUri(
            "/groups",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectGroupModel[] | null = await this.get<ProjectGroupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectGroupModel");
        return data;
    }

    public async GetProjectGroups(projectCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        const uri = this.buildUri(
            "/projects/{projectCode}/groups",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectGroupModel[] | null = await this.get<ProjectGroupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectGroupModel");
        return data;
    }

    public async GetServiceCallPurchaseOrderGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        const uri = this.buildUri(
            "/service-call/purchase-order/groups",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectGroupModel[] | null = await this.get<ProjectGroupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectGroupModel");
        return data;
    }
}
