import { ShowDrawingProxy } from "api/proxies/show-drawing-proxy";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ShopDrawingLightModel } from "api/models/company/shop-drawing-light-model";
import { ShopDrawingModel } from "api/models/company/shop-drawing-model";
import { ShopDrawingStatusModel } from "api/models/company/shop-drawing-status-model";

@autoinject()
export class ShopDrawingService {
    constructor(private readonly showDrawingProxy: ShowDrawingProxy) {
    }

    public async getShopDrawings(projectDispatchCode: string, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ShopDrawingLightModel[] | null> {
        return this.showDrawingProxy.GetShopDrawings(projectDispatchCode, filter, pagingInfo, requestConfig);
    }

    public async getShopDrawing(drawingId: number, requestConfig?: HttpClientRequestConfig): Promise<ShopDrawingModel | null> {
        return this.showDrawingProxy.GetShopDrawing(drawingId);
    }

    public async getShopDrawingStatuses(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ShopDrawingStatusModel[] | null> {
        return this.showDrawingProxy.GetShopDrawingStatuses(filter, pagingInfo, requestConfig);
    }
}
