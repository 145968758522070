import { autoinject, bindable } from "aurelia-framework";

@autoinject()
export class ServiceEquipmentMaintenanceHeader {
    @bindable
    public equipmentCode: string = "";
    @bindable
    public maintenanceFullDescription: string = "";
    @bindable
    public equipmentDescription: string = "";
    @bindable
    public equipmentBrand: string = "";
    @bindable
    public equipmentSerialNo: string = "";
}
