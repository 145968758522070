// GENERATED FILE - DO NOT MODIFY
import { PhysicalInventoryItemModel } from "api/models/company/physical-inventory-item-model";
import { PhysicalInventoryModel } from "api/models/company/physical-inventory-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class PhysicalInventoryProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetPhysicalInventory(onlyClose: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel[] | null> {
        const uri = this.buildUri(
            "/physical-inventory",
            null,
            { onlyClose: (onlyClose === null || onlyClose === undefined) ? undefined : onlyClose.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PhysicalInventoryModel[] | null = await this.get<PhysicalInventoryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.PhysicalInventoryModel");
        return data;
    }

    public async Get(physicalInventoryId: number, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel | null> {
        const uri = this.buildUri(
            "/physical-inventory/{physicalInventoryId}",
            { physicalInventoryId: (physicalInventoryId === null || physicalInventoryId === undefined) ? undefined : physicalInventoryId.toString() },
            null
        );

        const data: PhysicalInventoryModel | null = await this.get<PhysicalInventoryModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.PhysicalInventoryModel");
        return data;
    }

    public async DeletePhysicalInventory(physicalInventoryId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/physical-inventory/{physicalInventoryId}",
            { physicalInventoryId: (physicalInventoryId === null || physicalInventoryId === undefined) ? undefined : physicalInventoryId.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async SavePhysicalInventory(physicalInventory: PhysicalInventoryModel | null, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel | null> {
        const uri = this.buildUri(
            "/physical-inventory",
            null,
            null
        );

        const data: PhysicalInventoryModel | null = await this.post<PhysicalInventoryModel | null, PhysicalInventoryModel | null>(uri, physicalInventory, requestConfig);
        this.transformModels(data, "Company.PhysicalInventoryModel");
        return data;
    }

    public async GetPhysicalInventoryDetail(physicalInventoryId: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryItemModel | null> {
        const uri = this.buildUri(
            "/physical-inventory/{physicalInventoryId}/detail/{lineNo}",
            { physicalInventoryId: (physicalInventoryId === null || physicalInventoryId === undefined) ? undefined : physicalInventoryId.toString(), lineNo: (lineNo === null || lineNo === undefined) ? undefined : lineNo.toString() },
            { productCode: (productCode === null || productCode === undefined) ? undefined : productCode }
        );

        const data: PhysicalInventoryItemModel | null = await this.get<PhysicalInventoryItemModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.PhysicalInventoryItemModel");
        return data;
    }

    public async GetNewPhysicalInventoryHeader(requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel | null> {
        const uri = this.buildUri(
            "/physical-inventory/{physicalInventoryId}/header/new",
            null,
            null
        );

        const data: PhysicalInventoryModel | null = await this.get<PhysicalInventoryModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.PhysicalInventoryModel");
        return data;
    }

    public async GetNewPhysicalInventoryDetail(physicalInventoryId: number, productCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryItemModel[] | null> {
        const uri = this.buildUri(
            "/physical-inventory/{physicalInventoryId}/detail/new",
            { physicalInventoryId: (physicalInventoryId === null || physicalInventoryId === undefined) ? undefined : physicalInventoryId.toString() },
            { productCode: (productCode === null || productCode === undefined) ? undefined : productCode, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PhysicalInventoryItemModel[] | null = await this.get<PhysicalInventoryItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.PhysicalInventoryItemModel");
        return data;
    }

    public async SavePhysicalInventoryDetail(item: PhysicalInventoryItemModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/physical-inventory/detail",
            null,
            null
        );

        await this.post<PhysicalInventoryItemModel | null, void>(uri, item, requestConfig);
    }

    public async Complete(physicalInventoryId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/physical-inventory/{physicalInventoryId}/complete",
            { physicalInventoryId: (physicalInventoryId === null || physicalInventoryId === undefined) ? undefined : physicalInventoryId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async DeletePhysicalInventoryDetail(physicalInventoryId: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/physical-inventory/{physicalInventoryId}/detail/{lineNo}",
            { physicalInventoryId: (physicalInventoryId === null || physicalInventoryId === undefined) ? undefined : physicalInventoryId.toString(), lineNo: (lineNo === null || lineNo === undefined) ? undefined : lineNo.toString() },
            { productCode: (productCode === null || productCode === undefined) ? undefined : productCode }
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async IsSiteMandatory(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/physical-inventory/site-mandatory",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async ValidationPhysicalInventoryDetailQty(item: PhysicalInventoryItemModel | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/physical-inventory/detail-qty-validation",
            null,
            null
        );

        const data: number = await this.post<PhysicalInventoryItemModel | null, number>(uri, item, requestConfig);
        return data;
    }
}
