import requests from 'services/requests';

export class CompanyService {
    constructor() {

    }

    getAll() {
        return requests.getJson("api/companies");
    }
}