import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import notificationHelper from "helpers/notificationHelper";
import { DialogCloseResult } from "aurelia-dialog";

@autoinject()
export class NotificationHelper {
    constructor(private readonly i18n: I18N) {
    }

    public showDialogOkKey(msgKey: string, titleKey?: string): Promise<void> {
        const msg = msgKey ? this.i18n.tr(msgKey) : msgKey;
        const title = titleKey ? this.i18n.tr(titleKey) : titleKey;
        return notificationHelper.showDialogOk(msg, title);
    }

    public showDialogOk(msg: string, title: string): Promise<void> {
        return notificationHelper.showDialogOk(msg, title);
    }

    public showDialogYesNo(msg: string, title?: string, isError?: boolean, options?: {[key: string]: any}): Promise<boolean> {
        return notificationHelper.showDialogYesNo(msg, title, isError, options);
    }

    public showDialogYesNoKey(msgKey: string, titleKey?: string, isError?: boolean, options?: { [key: string]: any }): Promise<boolean> {
        const msg = msgKey ? this.i18n.tr(msgKey) : msgKey;
        const title = titleKey ? this.i18n.tr(titleKey) : titleKey;

        return notificationHelper.showDialogYesNo(msg, title, isError, options);
    }

    public showError(msg: string, title?: string, options?: {[key: string]: any}): void {
        return notificationHelper.showError(msg, title, options);
    }

    public showErrorKey(msgKey: string, titleKey?: string, options?: { [key: string]: any }): void {
        const msg = msgKey ? this.i18n.tr(msgKey) : msgKey;
        const title = titleKey ? this.i18n.tr(titleKey) : titleKey;

        return this.showError(msg, title, options);
    }

    public showInfo(msg: string, title: string, options?: {[key: string]: any}): void {
        return notificationHelper.showInfo(msg, title, options);
    }

    public showSuccess(msg: string, title: string, options?: {[key: string]: any}): void {
        return notificationHelper.showSuccess(msg, title, options);
    }

    public showWarningKey(msgKey: string, titleKey: string, options?: { [key: string]: any }): void {
        const msg = msgKey ? this.i18n.tr(msgKey) : msgKey;
        const title = titleKey ? this.i18n.tr(titleKey) : titleKey;

        return this.showWarning(msg, title, options);
    }

    public showWarning(msg: string, title: string, options?: {[key: string]: any}): void {
        return notificationHelper.showWarning(msg, title, options);
    }

    public showValidationError(errorsMessages: string[]): void {
        return notificationHelper.showValidationError(errorsMessages);
    }

    public showDeleteConfirmation(): boolean {
        return this.showConfirmation(this.i18n.tr("msg_DeleteEntryConfirmationText"));
    }

    public showConfirmation(msg: string, title?: string): boolean {
        return notificationHelper.showConfirmation(msg, title);
    }

    public showConfirmationKey(msgKey: string, titleKey?: string): boolean {
        const msg = msgKey ? this.i18n.tr(msgKey) : msgKey;
        const title = titleKey ? this.i18n.tr(titleKey) : titleKey;

        return this.showConfirmation(msg, title);
    }

    public showDialogOkCancel(msg: string, title: string): Promise<boolean> {
        return notificationHelper.showDialogOkCancel(msg, title);
    }

    public showOptionsDialog(msg: string, title: string, options: any): Promise<DialogCloseResult> {
        return notificationHelper.showOptionsDialog(msg, title, options);
    }

    public showTemplateDialog(msg: string, title: string, template: any, options: any): Promise<DialogCloseResult> {
        return notificationHelper.showTemplateDialog(msg, title, template, options);
    }
}
