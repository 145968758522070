import { ProjectActivityProgressModel } from "api/models/company/project/project-activity-progress-model";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import activityService from "services/activityService";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class DailyEntryProjectProgressEdit extends FormBase {

    public dispatchDate: Date = new Date();
    public dispatchProjectCode: string = "";
    public activity!: ProjectActivityProgressModel;
    public readonly: boolean = false;
    public rate: number = 0;
    public isProjectResponsible: boolean = false;
    public unModified: number | null = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (this.unModified === null) {
            return false;
        }

        return this.unModified !== this.rate;
    }

    public activate(params: any): any {

        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        this.loadData(params.id);
    }

    public async loadData(activityId: string): Promise<any> {
        this.activity = await activityService.getActivityProgressForDailyEntry(this.dispatchProjectCode, this.dispatchDate, activityId);
        this.rate = this.activity.Rate ? this.activity.Rate : 0;
        this.unModified = this.activity.Rate ? this.activity.Rate : 0;
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);

        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
    }

    public isRateValid(): boolean {
        if (this.rate < 0 || this.rate > 100) {
            this.notificationHelper.showError(this.i18n.tr("err_RateBetween0And100"));
            return false;
        }
        return true;
    }

    public async save(): Promise<void> {
        if (this.isRateValid()) {
            this.unModified = null;
            await activityService.setActivityProgressForDailyEntry(this.dispatchProjectCode, this.dispatchDate, this.activity.Id!, this.rate);
            routerHelper.navigateBack();
        }
    }
}
