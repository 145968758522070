define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getSupplierForCompany: function (filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getBaseUrl() + '/suppliers',
                    filter,
                    page)
            );
        }
    };

    return exports;
});
