import { bindingMode, inject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

import flatpickr from "flatpickr";

import { French } from "flatpickr/dist/l10n/fr";
import { LocalizationHelper } from "helpers/localization-helper";
import { Instance } from "flatpickr/dist/types/instance";

import { CustomEventHelper } from "helpers/custom-event-helper";
import { CustomLocale } from "flatpickr/dist/types/locale";

@inject(LocalizationHelper, Element)
export class FlatpickrDatePicker {
    @bindable.booleanAttr
    public open: boolean = false;

    @bindable.date({ defaultBindingMode: bindingMode.twoWay })
    public value: Date | null = null;

    @bindable.date
    public minDate: Date | null = null;

    @bindable.date
    public maxDate: Date | null = null;

    @bindable.boolean
    public enableTime: boolean = true;

    public calendarInputRef!: HTMLElement;

    public calendarPopupRef!: HTMLElement;

    private calendar!: Instance | Instance[];

    private locale!: CustomLocale;

    constructor(private readonly localizationHelper: LocalizationHelper, private readonly element: HTMLElement) { }

    public attached(): void {
        this.initDatePicker();
    }

    public valueChanged(newValue: Date): void {
        this.updateCalendarDate(newValue);
    }

    public openChanged(): void {
        if (this.open) {
            this.repositionDatePickerPopup();
        } else {
            this.resetDatePickerPopupPosition();
        }
    }

    public initDatePicker(): void {
        const defaultDate = this.value || new Date();

        if (this.localizationHelper.getLanguageCode() === "fr") {
            this.locale = French;
        }

        this.calendar = flatpickr(this.calendarInputRef, {
            locale: this.locale,
            minDate: this.minDate || "",
            maxDate: this.maxDate || "",
            defaultDate : defaultDate || "",
            inline: true,
            enableTime: this.enableTime,
            time_24hr: true,
            defaultHour: 0,
            onValueUpdate: (selectedDates: Date[], dateStr: string, instance: Instance): void => {
                this.onDateSelected(selectedDates[0]);
            }
        });

    }

    public updateCalendarDate(newDate: Date): void  {
        if (this.calendar) {
            const calendar = Array.isArray(this.calendar) ? this.calendar[0] : this.calendar;
            calendar.setDate(this.value || new Date());
        }
    }

    public onDateSelected(newDate: Date): void {
        this.value = newDate;

        CustomEventHelper.dispatchEvent(this.element,
            "date-selected",
            this.value,
            false,
            false);
    }

    private resetDatePickerPopupPosition(): void {
        this.element.style.right = "";
        this.element.style.left = "";
    }

    private repositionDatePickerPopup(): void {
        const boundingClientRect = this.element.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(this.element);
        const leftPosition =  boundingClientRect.left + parseFloat(computedStyle.paddingLeft || "0");

        if (window.innerWidth - leftPosition < this.calendarPopupRef.clientWidth) {
            this.element.style.right = "0px";
            this.element.style.left = "";
        } else {
            this.element.style.left = "0px";
            this.element.style.right = "";
        }
     }
}
