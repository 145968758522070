import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { autoinject } from "aurelia-framework";
import { EmployeeHoursSummaryProxy } from "api/proxies/employee-hours-summary-proxy";

@autoinject()
export class EmployeeHoursSummaryService {

    constructor(private readonly employeeHoursSummaryProxy: EmployeeHoursSummaryProxy) {

    }

    public async IsAlreadyApproved(startDate: Date, endDate: Date, requestConfig?: HttpClientRequestConfig): Promise<string | null> {
        return this.employeeHoursSummaryProxy.IsAlreadyApproved(startDate, endDate, requestConfig);
    }

    public async CCQReport(startDate: Date, endDate: Date, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.employeeHoursSummaryProxy.CCQReport(startDate, endDate, requestConfig);
    }

    public async IsCCQReportAvailable(requestConfig?: HttpClientRequestConfig): Promise<boolean | null> {
        return this.employeeHoursSummaryProxy.IsCCQReportAvailable(requestConfig);
    }

}
