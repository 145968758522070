import additionalFieldsTypeService from "services/additionalFieldsTypeService";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import routerHelper from "helpers/routerHelper";
import { FormBase } from "pages/form-base";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";

@autoinject
export class FieldServiceAdditionalFieldsType extends FormBase {

    public actions: any = {};
    public workOrderId: string = "";
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.FieldService;
    public saveAdditionalFieldsButton: boolean = true;
    public workOrderType: string = "";
    public currentFields: any = null;
    public unModifiedFields: any = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly eventAggregator: EventAggregator, private readonly additionalFieldMapper: AdditionalFieldMapper) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.additionalFieldMapper.isNotEqual(this.unModifiedFields, this.currentFields);
    }

    public activate(params: any): any {
        this.bindViewModel(decodeURIComponent(params.workOrderId), params.q);
    }

    public bindViewModel(workOrderId: string, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.workOrderId = workOrderId;
        this.workOrderType = params.workOrderType;

        this.eventAggregator.publish("updateTitle", `${this.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${this.workOrderType})`);

        this.actions = {
            getAll: additionalFieldsTypeService.getAllForFieldService.bind(this, this.workOrderId),
            setAll: additionalFieldsTypeService.setAllForFieldService.bind(this, this.workOrderId)
        };
    }
}
