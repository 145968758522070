define([
    "underscore",
    "helpers/enumHelper"
], function (_, enumHelper) {
    "use strict";

    var exports = {
        getPresentAttendanceCount: function (attendances) {
            return _.filter(attendances, function (item) {
                return item.Presence >= enumHelper.attendance().ONSITE;
            }).length;
        },

        groupAndSortAttendances: function (attendances, removeEquipment) {
            if (removeEquipment){
                attendances = _.filter(attendances, function (attendance) { return attendance.IsEmployee === true; });
            }

            attendances = _.groupBy(attendances, 'TeamName');

            attendances = _.map(attendances, function (team) {
                var sortedAttendances = _.sortBy(team, function (item) {
                    return item.EquipmentCode ? item.EquipmentDescription : item.EmployeeFirstName + ' ' + item.EmployeeLastName;
                });

                return {
                    Attendances: sortedAttendances,
                    TeamName: team[0].TeamName,
                    TeamNumber: team[0].TeamNumber,
                    IsOnlyEquipment: _.filter(sortedAttendances, function (item) {
                        return item.EquipmentCode;
                    }).length === sortedAttendances.length,
                    AttendancesCount: sortedAttendances.length,
                    PresentAttendancesCount: exports.getPresentAttendanceCount(sortedAttendances)
                };
            });

            var emptyTeam = _.find(attendances, function (item) {
                return item.TeamName === '' || item.TeamName === null; 
            });
            if (emptyTeam) {
                attendances = _.without(attendances, emptyTeam);
                attendances.unshift(emptyTeam);
            }

            return attendances;
        },

        getEmployeesFromGroupedAndSortedAttendances: function (attendances) {
            return _.filter(attendances, function (att) { return att.TeamNumber === 0; });
        },

        getTeamsFromGroupedAndSortedAttendances: function (attendances) {
            return _.filter(attendances, function (att) { return att.TeamNumber !== 0; });
        },


        getAttendancesFromEmployees: function (attendances, isEquipment) {
            var result = {
                Attendances: [],
                TeamName: (!Array.isArray(attendances) || !attendances.length) ? null : attendances[0].TeamName,
                TeamNumber: (!Array.isArray(attendances) || !attendances.length) ? 0 : attendances[0].TeamNumber,
                IsOnlyEquipment: isEquipment,
                AttendancesCount: 0,
                PresentAttendancesCount: 0
            };

            _.each(attendances, function (att) {
                _.each(att.Attendances, function (entry) {
                    if ((entry.EquipmentCode && isEquipment) || (!entry.EquipmentCode && !isEquipment)) {
                        result.Attendances.push(entry);
                    }
                });
            });

            return [result];
        },

        getEquipmentFromAttendances: function (attendances) {
            var filteredAttendances = _.filter(attendances, function (item) {
                return item.EquipmentCode;
            });

            filteredAttendances = _.sortBy(filteredAttendances, function (item) {
                return item.EquipmentCode ? item.EquipmentCode : item.LinkedEquipment.EquipmentCode;
            });

            return {
                Attendances: filteredAttendances,
                TeamName: '',
                TeamNumber: 0,
                IsOnlyEquipment: true,
                AttendancesCount: filteredAttendances.length,
                PresentAttendancesCount: exports.getPresentAttendanceCount(filteredAttendances)
            };
        }
    };

    return exports;
});

