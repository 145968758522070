// GENERATED FILE - DO NOT MODIFY
export enum ManufacturingStatus {
    Project = "3",
    ProjectReservation = "5",
    Equipment = "10",
    ServiceCall = "11",
    WorkOrder = "13",
}

export let nameof = "ManufacturingStatus";
