import documentService from "services/documentService";
import documentHelper from "helpers/documentHelper";
import routerHelper from "helpers/routerHelper";

export class MaterialOrderDocument {
  public workOrderId?: string;

  public readonly: boolean = false;
  public actions: any = {};

  public activate(params: any): void {
    this.setupHeaderInfos(decodeURIComponent(params.workOrderId));
    this.setAction(params.orderId);
  }

  private setupHeaderInfos(workOrderId: string): void {
    this.readonly = false;
    this.workOrderId = workOrderId;
  }

  private setAction(orderId: string): void {
      this.actions = {
          getSections: documentService.getSectionsByPurchaseOrderId.bind(self, orderId),
          getSectionDocuments: documentService.getDocumentsByPurchaseOrderId.bind(self, orderId),
          getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
          deleteDocument: documentService.deleteDocument.bind(documentService)
        };
  }
}
