import { ProductionService } from "services/production-service";
import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import { ProductionCollectionModel } from "api/models/company/project/dispatch/production-collection-model";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import { autoinject } from "aurelia-framework";

@autoinject
export class DailyEntryProduction {

    public dispatchProjectCode: string = "";
    public dispatchDate: Date = new Date();
    public isProjectResponsible: boolean = false;
    public productionsItems: any[] = [];
    public readonly: boolean = true;

    constructor(private readonly i18n: I18N, private readonly productionService: ProductionService) {

    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);

        return this.loadData();
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, queryString: string): void {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);
        this.readonly = queryStringHelper.parseReadonly(queryString);

        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
    }

    public async loadData(): Promise<void> {
        this.initialiseProductionsItems((await this.productionService.GetProduction(this.dispatchProjectCode, this.dispatchDate))!);
    }

    public initialiseProductionsItems(data: ProductionCollectionModel): void {
        this.readonly = data.IsReadOnly;
        this.productionsItems = data.ProductionEntries ? data.ProductionEntries : [];
    }

    public genUrl(rel: string): string {
        let url = routerHelper.getRelativeUrl(rel);
        url += routerHelper.addQuerystring({
            isProjectResponsible: this.isProjectResponsible,
            readonly: this.readonly
        });

        return url;
    }

    public gotoEditPage(): void {
        routerHelper.navigate(this.genUrl("edit"));
    }

    public async deleteItem(id: number, line: number): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            const data = (await this.productionService.DeleteProduction(this.dispatchProjectCode, this.dispatchDate, id, line))!;
            this.initialiseProductionsItems(data);
        }

    }
}
