define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getApprovals: function () {
            return requests.getJson(apiHelper.getWorkflowApprovalBaseUrl()).promise();
        },

        doAction: function (action, actionId, comment) {
            return requests.post(
                apiHelper.appendQueryStringParameters(
                    apiHelper.getWorkflowApprovalBaseUrl(action),
                    {
                        ActionId: actionId,
                        Comment:encodeURIComponent(comment)
                    })
            ).promise();
        },
        hasapprobations: function () {
            // Attention: deferred.
            return requests.getJsonNoLoading(apiHelper.getWorkflowBaseUrl() + "/hasapprobations");
        }
    };

    return exports;
});
