import { nameof as nameof_ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { nameof as nameof_ServiceCallWorkOrderQuotationStatus } from "api/enums/service-call-work-order-quotation-status";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, bindable } from "aurelia-framework";

@autoinject()
export class ServiceQuotationHeader {
    @bindable
    public quotation!: ServiceCallQuotationDetailsModel;

    public nameof_ServiceCallQuotationStatus: string = nameof_ServiceCallQuotationStatus;
    public nameof_ServiceCallWorkOrderQuotationStatus: string = nameof_ServiceCallWorkOrderQuotationStatus;
}
