import val from "core/val";
import apiHelper from "helpers/apiHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { I18N } from "aurelia-i18n";
import { PagingInfo } from "api/paging-info";
import { autoinject, observable } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { GroupService } from "services/group-service";
import { ServiceCallService } from "services/service-call-service";
import { ReceiptOfGoodsService } from "services/receipt-of-goods-service";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
import { InventorySiteTransferService } from "services/inventory-site-transfer-service";

import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { ProjectGroupModel } from "api/models/company/project/project-group-model";
import { ServiceCallLookupModel } from "api/models/company/service-call-lookup-model";
import { ReceiptOfGoodsItemModel } from "api/models/company/receipt-of-goods-item-model";
import { CatalogItemManagementMode } from "api/enums/catalog-item-management-mode";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

export interface ManageReceiptOfGoodsItemParameters {
    receiptId: number;
    pONumber: string;
    extension: string;
    prefix: string;
    lineNo: number;
    productCode: string;
    readonly: boolean;
    receiptDate: Date;
    isSupplierMode: boolean;
}

export enum QuantityMode {
    inventory= 0,
    supplier= 1
}

@autoinject
export class AddItem extends FormBase {
    public val: any = val;
    public outCatalogCode: string = "!";

    public receiptItem: ReceiptOfGoodsItemModel | null = null;
    public manageReceiptOfGoodsItemParameters!: ManageReceiptOfGoodsItemParameters;
    public quantityModeEnum: typeof QuantityMode = QuantityMode;

    public isNewItem: boolean = false;
    public isSupplierQuantityMode: boolean = false;
    @observable public selectedQuantityMode: any;

    public sites: any;
    public groups: any;
    public quantityModes: any;

    @observable public selectedItem: any;
    public selectedSite: any;
    public selectedGroup: any;
    public selectedCallNumber: any;
    public selectedActivityDescription: string = "";

    public catalogUrl: string = apiHelper.getBaseUrl() + "/catalog/inventory-transfer";
    public isSerialItemMode: boolean = false;
    public isServiceCallActive: boolean = false;
    public unModified: ReceiptOfGoodsItemModel | null = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router,
                private readonly groupService: GroupService,
                private readonly serviceCallService: ServiceCallService,
                private readonly receiptOfGoodsService: ReceiptOfGoodsService,
                private readonly routeRepository: RouteRepository, private readonly inventoryProjectTransferService: InventoryProjectTransferService, private readonly inventorySiteTransferService: InventorySiteTransferService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        if (!this.receiptItem) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        this.receiptItem!.AddedByUser = true;

        const priceToUpdate = this.selectedQuantityMode === QuantityMode.inventory ? QuantityMode.supplier : QuantityMode.inventory;
        this.updateUnitPrice(priceToUpdate);
        const stringifyCurrent = JSON.stringify(this.receiptItem).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public activate(params: any): void {
        this.manageReceiptOfGoodsItemParameters = {
            receiptId: Number.parseInt(params.receiptId),
            pONumber: params.pONumber,
            extension: params.extension ? params.extension : "",
            prefix: params.prefix ? params.prefix : "",
            lineNo: Number.parseInt(params.lineNo),
            productCode: params.productCode,
            receiptDate: params.receiptDate,
            readonly: params.readonly === "true",
            isSupplierMode: params.isSupplierMode === "true",
        };

        this.quantityModes = [{ id: QuantityMode.inventory, text: this.i18n.tr("Inventaire")}, {id: QuantityMode.supplier, text: this.i18n.tr("Supplier")}];
        this.selectedQuantityMode = this.manageReceiptOfGoodsItemParameters.isSupplierMode ? this.quantityModes[1] : this.quantityModes[0];
    }

    public async bind(): Promise<void> {
        if (this.manageReceiptOfGoodsItemParameters.productCode) {
            this.receiptItem = (await this.receiptOfGoodsService.GetReceiptOfGoodsDetail(this.manageReceiptOfGoodsItemParameters.receiptId!, this.manageReceiptOfGoodsItemParameters.lineNo, this.manageReceiptOfGoodsItemParameters.productCode))!;
            await this.setProperties();
            this.unModified = CloneHelper.deepClone(this.receiptItem);
        } else {
            this.isNewItem = true;
            this.unModified = { PONumber: null, Extension: null, receiptId: 0, InventoryQtyToReceive: 0, SupplierQtyToReceive: 0, InventoryQtyReceived: 0, SupplierQtyReceived: 0, OutCatalogCode: null, ServiceCallId: 0, ServiceCallDescription: null, LongDescription: null, Tax: null, Tax1Account: 0, Tax1ExclIncl: "", Tax1FedProv: "", TransactionNumber: 0, Tax2Account: 0, Tax2ExclIncl: "", Tax2FedProv: "", Tax1Rate: 0, Tax1Percent: 0, Tax1Priority: 0, Tax2Rate: 0, Tax2Percent: 0, Tax2Priority: 0, CurrencyRate: 0, CurrencyAccount: 0, Group: null, GroupDescription: null, Activity: null, AddedByUser: false, LineNo: 0, Prefix: null, ProjectNo: null, ProjectDescription: null, Site: null, SiteDescription: null, Location: null, ProductCode: null, Description: null, Description2: null, Description3: null, InventoryUnit: null, InventoryFactor: 0, SupplierUnit: null, SupplierFactor: 0, ItemManagementMode: null, Identification: null, Characteristic: null, ReservationNo: null, UnitPrice: 0, SupplierUnitPrice: 0, SupplierType: "", CurrencyCode: null, InventoryScaleFactor: 0, SupplierScaleFactor: 0, SerialNos: null };
        }

        this.isServiceCallActive = await this.inventorySiteTransferService.IsServiceCallInstalled();
    }

    public async save(): Promise<void> {
        if (this.isSaveAllowed()) {
            this.receiptItem!.AddedByUser = true;

            const priceToUpdate = this.selectedQuantityMode === QuantityMode.inventory ? QuantityMode.supplier : QuantityMode.inventory;
            this.updateUnitPrice(priceToUpdate);

            await this.receiptOfGoodsService.SaveReceiptOfGoodsDetail(this.receiptItem);

            this.unModified = null;
            routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.name, { receiptId: this.receiptItem!.receiptId });
        }
    }

    public async selectedItemChanged(newValue: any): Promise<void> {
        if (!newValue || !newValue.data) { return; }
        const catalogItem: CatalogItemModel = newValue.data;

        this.isSerialItemMode = catalogItem.ItemManagementMode === CatalogItemManagementMode.Serial;

        this.receiptItem = (await this.receiptOfGoodsService.GetNewReceiptOfGoodsDetail(this.manageReceiptOfGoodsItemParameters.receiptId, catalogItem.Code))!;

        if (!this.receiptItem) { return; }

        this.setSite();
    }

    public selectedQuantityModeChanged(newValue: any, oldValue: any): void {
        if (!this.receiptItem) { return; }

        this.receiptItem.SupplierFactor = this.receiptItem.SupplierFactor === 0 ? 1 : this.receiptItem.SupplierFactor;

        const priceToUpdate = oldValue.id === QuantityMode.inventory ? QuantityMode.supplier : QuantityMode.inventory;

        this.updateUnitPrice(priceToUpdate);
    }

    public async getSites(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<any> {
        return await this.inventoryProjectTransferService.GetSites(this.receiptItem!.Prefix, filter, pagingInfo, requestConfig);
    }

    public async getProjects(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ProjectBaseModel[] | null> {
        return await this.inventoryProjectTransferService.GetProjects(this.receiptItem!.Prefix!, filter, pagingInfo, requestConfig);
    }

    public async getActivities(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<any> {
        if (!this.receiptItem!.ProjectNo) { return []; }

        return await this.inventoryProjectTransferService.GetActivities(this.receiptItem!.ProjectNo, filter, pagingInfo, requestConfig);
    }

    public async onProjectChanged(projectNo: string): Promise<void> {
        if (!this.receiptItem || !projectNo) { return; }

        this.setActivity(projectNo);
    }

    public async getGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectGroupModel[] | null> {
        if (!this.receiptItem) { return null; }

        if (this.receiptItem.ProjectNo) {
            return await this.groupService.GetProjectGroups(this.receiptItem.ProjectNo, filter, pagingInfo, requestConfig);
         } else {
            return await this.groupService.GetGroups(filter, pagingInfo, requestConfig);
         }
    }

    public get GetCallNumbers(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.serviceCallService.GetServiceCallLookup(this.receiptItem!.Prefix, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ServiceCallLookupModel): any => {
                return { id: item.Id, text: `${item.Id} ${ !item.Description ? "" : "- " + item.Description}`, data: item };
            },
        };
    }

    public onCallNumberSelected(callNumber: ServiceCallLookupModel): void {
        this.receiptItem!.ServiceCallId = callNumber.Id;
        this.receiptItem!.ServiceCallDescription = callNumber.Description;
    }

    public onCallNumberCleared(): void {
        this.receiptItem!.ServiceCallId = 0;
    }

    private updateUnitPrice(priceToUpdate: QuantityMode): void {
        if (priceToUpdate === QuantityMode.supplier) {
            this.receiptItem!.SupplierUnitPrice = this.receiptItem!.UnitPrice / this.receiptItem!.SupplierFactor;
        } else {
            this.receiptItem!.UnitPrice = this.receiptItem!.SupplierUnitPrice * this.receiptItem!.SupplierFactor;
        }
    }

    private async setProperties(): Promise<void> {
        if (this.receiptItem!.ProductCode === this.outCatalogCode) {
            this.selectedItem = this.setSelected(this.receiptItem!.ProductCode, this.i18n.tr("AddItemNotInCatalog"));
        } else {
            this.selectedItem = this.setSelected(this.receiptItem!.ProductCode, this.receiptItem!.ProductCode);
        }

        await this.setSite();
        await this.setActivity();

        this.selectedGroup = this.setSelected(this.receiptItem!.Group, this.receiptItem!.GroupDescription);
        this.selectedCallNumber = this.setSelected(this.receiptItem!.ServiceCallId, `${this.receiptItem!.ServiceCallId} - ${this.receiptItem!.ServiceCallDescription}`);
    }

    private isSaveAllowed(): boolean {
        let canSave: boolean = true;

        if (this.receiptItem!.ProductCode === this.outCatalogCode && !this.receiptItem!.OutCatalogCode) {
            this.notificationHelper.showError(this.i18n.tr("err_CodeRequired"), "", { timeOut: 0});
            canSave = false;
        }

        if (this.receiptItem!.ProductCode === this.outCatalogCode && !this.receiptItem!.Description) {
            this.notificationHelper.showError(this.i18n.tr("err_DescriptionRequired"), "", { timeOut: 0 });
            canSave = false;
        }

        if (!this.receiptItem!.ServiceCallId || this.receiptItem!.OutCatalogCode) {
            if (!this.receiptItem!.ProjectNo) {
                this.notificationHelper.showError(this.i18n.tr("err_ProjectRequired"), "", { timeOut: 0});
                canSave = false;
            }
            if (!this.receiptItem!.Activity) {
                this.notificationHelper.showError(this.i18n.tr("err_ActivityRequired"), "", { timeOut: 0});
                canSave = false;
            }
        }

        if (!this.receiptItem!.Group) {
            this.notificationHelper.showError(this.i18n.tr("err_GroupRequired"), "", { timeOut: 0});
            canSave = false;
        }

        return canSave;
    }

    private async setActivity(projectNo?: string): Promise<void> {
        if (!this.receiptItem!.ProjectNo && !projectNo) { return; }

        if (this.receiptItem!.Activity && (this.receiptItem!.ProjectNo || projectNo)) {
            const activities = await this.inventoryProjectTransferService.GetActivities(projectNo ? projectNo : this.receiptItem!.ProjectNo);
            const activityFound = this.findById(this.receiptItem!.Activity, activities);

            if (activityFound) {
                this.selectedActivityDescription = activityFound.text;
            } else {
                this.receiptItem!.Activity = null;
                this.selectedActivityDescription = "";
            }
        }
    }

    private async setSite(): Promise<void> {
        const sites = await this.inventoryProjectTransferService.GetSites(this.receiptItem!.Prefix);
        const siteFound = this.findById(this.receiptItem!.Site, sites);

        if (siteFound) {
            this.receiptItem!.Site = siteFound.id;
            this.receiptItem!.SiteDescription = siteFound.text;
        } else {
            this.receiptItem!.Site = null,
            this.receiptItem!.SiteDescription = null;
        }
    }

    private findById(id: string | null, list: any[]): any {
        if (!id) { return null; }

        return list.find((item: any) => item.id === id);
    }

    private setSelected(id: any, text: string | null): any {
        if (!id) { return null; }
        return {id: id, text: text};
    }
}
