import { I18N } from "aurelia-i18n";
import val from "core/val";
import { autoinject, bindable } from "aurelia-framework";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { NotificationHelper } from "helpers/notification-helper";
import { InventoryProjectTransferStatus } from "api/enums/inventory-project-transfer-status";

import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";
import { InventorySiteTransferItemModel } from "api/models/company/inventory-site-transfer-item-model";

@autoinject()
export class InventorySiteTransferInfo {
    public val: any = val;
    public dateHelper: any = dateHelper;

    @bindable public option: any = "";

    @bindable public transfer!: InventorySiteTransferDetailsModel | null;

    @bindable public isMultiPrefix: boolean = false;
    @bindable public isActive: boolean = false;
    @bindable public isSiteLockedByWorkOrder: boolean = false;

    @bindable public btnLinks: any[] | undefined;

    constructor(
        private i18n: I18N,
        private routeRepository: RouteRepository,
        private readonly inventorySiteTransferService: InventorySiteTransferService,
        private readonly notificationHelper: NotificationHelper) {}

    public async activate(params: any): Promise<void> {
         this.transfer = await this.inventorySiteTransferService.GetTransfer(params.transferId);
         this.isActive = this.transfer!.Status === InventoryProjectTransferStatus.Active;

         this.initButtonLinks();
    }

    public initButtonLinks(): void {
        this.btnLinks = [
            {
                id: "Memo",
                display: true,
                displayChevron: true,
                count: 0,
                linkMain: routerHelper.generate(this.routeRepository.routes.InventorySiteTransfer_Memo.name, {transferId : this.transfer!.Id}),
                text: "Memo"
            },
            {
                id: "Documents",
                display: true,
                displayChevron: true,
                count: 0,
                linkMain: routerHelper.generate(this.routeRepository.routes.InventorySiteTransfer_Documents.name, {transferId : this.transfer!.Id}),
                text: "Pictures/Documents"
            },
            {
                id: "Items",
                display: true,
                displayChevron: true,
                displayCount: true,
                count: this.transfer!.Items != null ? this.transfer!.Items!.length : 0,
                linkMain: routerHelper.generate(this.routeRepository.routes.InventorySiteTransfer_Items.name, { transferId: this.transfer!.Id }),
                text: "Items"
            }];
    }

    public editTransfer(): void {
        if (this.isActive) {
            routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Edit.name, {transferId : this.transfer!.Id});
        }
    }

    public async completeTransfer(): Promise<void> {
        if (!this.isActive) {
            return;
        }

        const hasNoItem: boolean = this.transfer!.Items!.length === 0;
        const hasItemWithQuantityZero: boolean = this.transfer!.Items!.some((item: InventorySiteTransferItemModel) => item.InventoryQty === 0);

        if (hasNoItem) {
            this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_CompleteNoItems"));
            return;
        }

        if (hasItemWithQuantityZero) {
            this.notificationHelper.showError(this.i18n.tr("ApiError.631"), undefined, { timeOut: 0, extendedTimeOut: 0 });
            return;
        }

        if (await this.notificationHelper.showDialogYesNo(this.i18n.tr("InventorySiteTransfer_ConfirmComplete"))) {
            try {
                await this.inventorySiteTransferService.CompleteTransfer(this.transfer!.Id);
            } catch (e) {
                e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId ? this.notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 }) : this.notificationHelper.showError(e.response.data.ExceptionMessage, undefined, { timeOut: 0, extendedTimeOut: 0 });
                return;
            }
            routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer.name);
        }
    }

}
