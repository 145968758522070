import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";
import documentHelper from "helpers/documentHelper";
import { MaThumbnailOptionsModel } from "./ma-thumbnail-options.model";

@autoinject
export class MaThumbnail {
    @bindable public thumbnailOptions!: MaThumbnailOptionsModel | null;

    constructor(private readonly i18n: I18N) {}

    public formatImageSrc(image: MaThumbnailOptionsModel): string {
        if (image.thumbnail === null) {
            return "";
        }
        return "data:image/png;base64," + image.thumbnail;
    }

    public formatSizeInfo(size: number): string {
        return documentHelper.formatDocumentSize(size);
    }
}
