// GENERATED FILE - DO NOT MODIFY
export enum ServiceCallQuotationItemType {
    Labor = "1",
    Material = "2",
    Equipment = "3",
    BillingItem = "4",
    MiscCost = "5",
    Bonus = "6",
}

export let nameof = "ServiceCallQuotationItemType";
