import { LocationModel } from "api/models/company/location-model";

export interface LocationSingleLineFormatValueConverterConfig {
    hideName: boolean;
}

export class LocationSingleLineFormatValueConverter {
    public static defaultConfig: LocationSingleLineFormatValueConverterConfig = {
        hideName: false
    };

    public toView(value: LocationModel | null, config: LocationSingleLineFormatValueConverterConfig = LocationSingleLineFormatValueConverter.defaultConfig): string {
        if (!value) {
            return "";
        }

        let result = "";
        const parts: string[] = [];

        if (!config.hideName) {
            if (value.Name && value.Name.trim()) {
                result = value.Name.trim() + " - ";
            }
        }

        if (value.Address && value.Address.trim()) {
            parts.push(value.Address.trim());
        }
        if (value.City && value.City.trim()) {
            parts.push(value.City.trim());
        }
        if (value.Province && value.Province.trim()) {
            parts.push(value.Province.trim());
        }
        if (value.Country && value.Country.trim()) {
            parts.push(value.Country.trim());
        }
        if (value.PostalCode && value.PostalCode.trim()) {
            parts.push(value.PostalCode.trim());
        }
        if (value.ZipCode && value.ZipCode.trim()) {
            parts.push(value.ZipCode.trim());
        }

        result += parts.join(", ");

        return result;
    }
}
