import routerHelper from "helpers/routerHelper";
import documentService from "services/documentService";
import { autoinject } from "aurelia-framework";
import { SettingRepository } from "repositories/setting-repository";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class FieldServiceDocumentAdd extends FormBase {
    public readonly: boolean = false;
    public workOrderId: string = "";
    public actions: any;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly settingRepository: SettingRepository
    ) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.workOrderId = decodeURIComponent(params.workOrderId);
        this.actions = { save: documentService.saveWorkOrderPicture.bind(self, this.workOrderId, this.settingRepository.getDispatchTemplate()) };
    }
}
