import { ProcoreLoadingProxy } from "api/proxies/procore-loading-proxy";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { BaseLoaderEntity } from "api/models/procore/loaders/base-loader-entity";
import { ProcoreGridItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-item";
import { NotificationHelper } from "helpers/notification-helper";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { DirectCostTransformer } from "./helpers/direct-cost-transformer";
import { ProcoreGridItemTransformer } from "./helpers/procore-grid-item-transformer";
import { CommandContractTransactionTransformer } from "./helpers/command-contract-transaction-transformer";
import { EmployeeLoaderEntity } from "api/models/procore/loaders/employee-loader-entity";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ProcoreGridBudgetItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-budget-item";
import { BudgetTransformer } from "./helpers/budget-transformer";
import { ProcoreExceptionHelper } from "pages/procore/synchronization/helpers/procore-exception-helper";
import { ProcoreBudgetsForAProject } from "api/models/procore/loaders/procore-budgets-for-a-project";
import { PrimeContractLoaderEntity } from "api/models/procore/loaders/prime-contract-loader-entity";
import { PrimeContractTransformer } from "./helpers/prime-contract-transformer";

@autoinject
export class ProcoreLoadingService {
  private requestConfigs: HttpClientRequestConfig = { showLoadingScreen: false } as HttpClientRequestConfig;

  constructor(private readonly i18n: I18N,
              private readonly exceptionHelper: ProcoreExceptionHelper,
              private readonly proxy: ProcoreLoadingProxy,
              private readonly budgetTransformer: BudgetTransformer,
              private readonly procoreGridItemTransformer: ProcoreGridItemTransformer,
              private readonly primeContractTransformer: PrimeContractTransformer) { }

  public async LoadProjects(): Promise<ProcoreGridItem[]> {
    try {
      const projects = await this.proxy.GetProjects(undefined, undefined, this.requestConfigs);

      return this.procoreGridItemTransformer.transform(projects);
    } catch (e) {
      this.exceptionHelper.toast(e.response.data.Exception);

      return [];
    }
  }

  public async LoadSynchronizedProjects(entityToSynchronize: ProcoreEntityToSynchronize, forceNotSynchronized: boolean = false, filter?: string): Promise<ProcoreGridItem[]> {
    try {
      const projects = await this.proxy.GetSynchronizedProjects(entityToSynchronize, filter, undefined, this.requestConfigs);

      if (projects == null) { return []; }

      this.removeErrorsAndWarnings(projects);

      const items = this.procoreGridItemTransformer.transform(projects);

      if (forceNotSynchronized) {
        items.forEach((item: ProcoreGridItem) => {
          item.IsSynchronized = false;
        });
      }

      return items;
    } catch (e) {
      this.exceptionHelper.toast(e.response.data.Exception);

      return [];
    }
  }

  public async LoadSuppliers(): Promise<ProcoreGridItem[]> {
    try {
      const suppliers = await this.proxy.GetSuppliers(undefined, undefined, this.requestConfigs);

      return this.procoreGridItemTransformer.transform(suppliers);
    } catch (e) {
      this.exceptionHelper.toast(e.response.data.Exception);

      return [];
    }
  }

  public async LoadPccoClients(): Promise<ProcoreGridItem[]> {
    try {
      const items = await this.proxy.GetPccoClients(undefined, undefined, this.requestConfigs);

      return this.procoreGridItemTransformer.transform(items);
    } catch (e) {
      this.exceptionHelper.toast(e.response.data.Exception);

      return [];
    }
  }

  public async LoadEmployees(): Promise<ProcoreGridItem[]> {
    try {
      const employees = await this.proxy.GetEmployees(undefined, undefined, this.requestConfigs);

      return this.procoreGridItemTransformer.transform(employees, false, (item: EmployeeLoaderEntity) => item.Email);
    } catch (e) {
      this.exceptionHelper.toast(e.response.data.Exception);

      return [];
    }
  }

  public async LoadTransactions(item: ProcoreGridItem, stockOrdersEnabled: boolean = true, ignoreDate: boolean = false): Promise<number> {
    return await this.proxy.GetTransactions(item.Code, ignoreDate, this.requestConfigs);
  }

  public async LoadBudget(item: ProcoreGridItem, projectNumber: string | null): Promise<ProcoreGridBudgetItem[]> {
    try {
      const result = await this.proxy.GetBudget(projectNumber, this.requestConfigs);
      const budgets = this.PrepareBudgets(item, result);
      return budgets;
    } catch (e) {
      this.exceptionHelper.toast(e.response.data.Exception);
      return [];
    }
  }

  public async LoadBudgetModifications(item: ProcoreGridItem, projectNumber: string | null): Promise<ProcoreGridBudgetItem[]> {
    try {
      const result = await this.proxy.GetBudgetModification(projectNumber, this.requestConfigs);
      const budgets = this.PrepareBudgets(item, result);
      return budgets;
    } catch (e) {
      this.exceptionHelper.toast(e.response.data.Exception);
      return [];
    }
  }

  public async LoadPrimeContract(item: ProcoreGridItem, projectNumber: string | null): Promise<ProcoreGridItem | null> {
    item.Warnings = [];
    item.Errors = [];
    
    try {
      const primeContract = await this.proxy.GetPrimeContract(projectNumber, this.requestConfigs);
      
      return this.primeContractTransformer.transform(primeContract);
    } catch (e) {
      const msg = this.exceptionHelper.getMessageFromProcore(e.response.data.Exception);

      item.Errors.push(msg);

      return null;
    }
  }

  private PrepareBudgets(item: ProcoreGridItem, result: ProcoreBudgetsForAProject | null): ProcoreGridBudgetItem[] {
    item.Errors = [];
    item.Warnings = [];
    item.Message = "";
    let budgets = this.budgetTransformer.transform(result);

    const budgetCount = budgets.length;

    if (budgetCount <= 0) {
        budgets = [];
    }

    const warning = ((!result || !result.Errors || result.Errors!.length <= 0) && (budgetCount <= 0))
        ? [{
            MessageFr: this.i18n.tr("Procore.NoBudget"),
            MessageEn: this.i18n.tr("Procore.NoBudget")
        } as ProcoreError]
        : [];

    const language = localStorage.getItem("maestro_user_profile_LANGUAGE");

    const errors = this.procoreGridItemTransformer.transformErrors(!result || !result.Errors ? [] : result.Errors, language);
    const warnings = this.procoreGridItemTransformer.transformErrors(warning, language);

    item.Message = this.procoreGridItemTransformer.getMessage(errors, []);
    item.Message += this.procoreGridItemTransformer.getMessage([], warnings);

    item.Errors = errors;
    item.Warnings = warnings;
    return budgets;
  }

  private removeErrorsAndWarnings(items: BaseLoaderEntity[]): void {
    items.forEach((item: BaseLoaderEntity): void => {
      item.Errors = [];
      item.Warnings = [];
    });
  }
}
