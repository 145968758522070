import { bindable, inject, noView } from "aurelia-framework";
import Assert from "core/assert";
import _ from "underscore";
import { StringHelper } from "helpers/string-helper";

const postalCodeFormat = "postalCode";
const phoneNumberFormat = "phoneNumber";
const zipCodeFormat = "zipCode";
const postalPlaceholder = "___ ___";
const zipCodePlaceholder = "_____-____";
const phoneNumberPlaceholder = "(___) ___-____";
const postalCodePattern = /([A-Z][0-9][A-Z])(\s)([0-9][A-Z][0-9])/.source;
const phoneNumberPattern = /(\([0-9]{3}\))(\s)([0-9]{3})(-)([0-9]{4})/.source;
const zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/.source;

@inject(Element)
@noView()
export class InputMaskCustomAttribute {
    @bindable({ primaryProperty: true }) public format: string | undefined;

    private caretPosition: number = 0;
    private isRemovingValues: boolean = false;

    constructor(private element: HTMLInputElement) {
        Assert.strictEqual(element.tagName, "INPUT", `INVALID USAGE OF INPUT-MASK. Normal usage is <input type="text" input-mask="format"/>`);
        Assert.isTrue(element.getAttribute("input-mask") !== "", "format", `INVALID USAGE OF INPUT-MASK. format must be specified/>`);
    }

    public attached(): void {
        this.element.oninput = this.onInput.bind(this, event);

        let pattern = "";
        if (this.format === phoneNumberFormat) {
            this.element.placeholder = phoneNumberPlaceholder;
            pattern = phoneNumberPattern;
        }

        if (this.format === postalCodeFormat) {
            this.element.placeholder = postalPlaceholder;
            pattern = postalCodePattern;
        }

        if (this.format === zipCodeFormat) {
            this.element.placeholder = zipCodePlaceholder;
            pattern = zipCodePattern;
        }

        this.element.pattern = pattern;
    }

    public bind(): void {
        this.formatInputElementValue();
    }

    private setElementCaretPosition(): void {
        this.element.selectionStart = this.caretPosition;
        this.element.selectionEnd = this.caretPosition;
    }

    private formatInputElementValue(): void {
        if (this.format === postalCodeFormat) {
            this.postalCodeMaskFormater(StringHelper.cleanString(this.element.value, StringHelper.keepAlphaNumericOnly, 6));
        }
        if (this.format === phoneNumberFormat) {
            this.phoneNumberMaskFormater(StringHelper.cleanString(this.element.value, StringHelper.keepNumericOnly, 10));
        }
        if (this.format === zipCodeFormat) {
            this.zipCodeMaskFormater(StringHelper.cleanString(this.element.value, StringHelper.keepNumericOnly, 9));
        }
    }

    private zipCodeMaskFormater(cleanInputValue: any): void {
        let index = 0;
        let result = "";

        if (cleanInputValue.length < 1) {
            this.element.value = "";
            this.caretPosition = 0;
            return;
        }

        _.each(
            cleanInputValue,
            (char: any): any => {
                if (index === 5) {
                    result += "-";
                    this.caretPosition = this.caretPosition === 6 ? 7 : this.caretPosition;
                }
                result += char;

                index++;
            }
        );

        this.element.value = result;
    }

    private postalCodeMaskFormater(cleanInputValue: any): void {
        let index = 0;
        let result = "";
        const allowedInputs = [/[a-zA-Z]/, /[0-9]/];

        if (cleanInputValue.length < 1) {
            this.caretPosition = 0;
            return;
        }

        _.each(
            cleanInputValue,
            (char: any): any => {
                if (index === 3) {
                    result += " ";
                    this.caretPosition = this.caretPosition === 4 ? 5 : this.caretPosition;
                }
                if (allowedInputs[index % 2].test(char)) {
                    result += char;
                }

                index++;
            }
        );

        this.element.value = result.toUpperCase();
    }

    private phoneNumberMaskFormater(cleanInputValue: any): void {
        let index = 0;
        let result = "";

        if (cleanInputValue.length < 1) {
            this.element.value = "";
            this.caretPosition = 0;
            return;
        }

        _.each(
            cleanInputValue,
            (char: any): any => {
                switch (index) {
                    case 0:
                        result += "(";
                        this.caretPosition = _.contains([0, 1], this.caretPosition) ? 2 : this.caretPosition;
                        break;
                    case 3:
                        result += ") ";
                        this.caretPosition = _.contains([5, 6], this.caretPosition) ? 7 : this.caretPosition;
                        break;
                    case 6:
                        result += "-";
                        this.caretPosition = this.caretPosition === 10 ? 11 : this.caretPosition;
                        break;
                    default:
                        break;
                }
                result += char;
                index++;
            }
        );

        this.element.value = result;
    }

    private onInput(context: any, e: any): void {
        this.isRemovingValues = !e.data;
        this.caretPosition = Number(this.element.selectionStart!);

        const alphaNumericString = StringHelper.cleanString(this.element.value, StringHelper.keepAlphaNumericOnly);

        if (!this.isRemovingValues || alphaNumericString.length < 1) {
            this.formatInputElementValue();
        }

        this.setElementCaretPosition();
    }
}
