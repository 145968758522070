import { WorkOrderMaintenanceModel } from "api/models/company/workorder/work-order-maintenance-model";
import { PagingInfo } from "api/paging-info";
import { WorkOrderMaintenanceMeasureProxy } from "api/proxies/work-order-maintenance-measure-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class WorkOrderMaintenanceMeasureService {
    constructor(private readonly workOrderMaintenanceMeasureProxy: WorkOrderMaintenanceMeasureProxy) {
    }

    public async getMaintenanceMeasures(dispatchId: number, requestConfig?: IRequestConfig): Promise<WorkOrderMaintenanceModel | null> {
        return (await this.workOrderMaintenanceMeasureProxy.GetWorkOrderMaintenanceMeasures(dispatchId, requestConfig));
    }

    public async saveMaintenanceMeasures(workOrderMaintenanceModel: WorkOrderMaintenanceModel | null, requestConfig?: IRequestConfig): Promise<void> {
        await this.workOrderMaintenanceMeasureProxy.SaveWorkOrderMaintenanceMeasures(workOrderMaintenanceModel, requestConfig);
    }
}
