import requests from "services/requests";
import { BambooHRApiProxy } from "proxies/bamboo-hr/bamboo-hr-api-proxy";
import { autoinject } from "aurelia-framework";
import config from "config";
import { BambooSyncConfig } from "pages/bamboo-hr/models/bamboo-sync-config";

@autoinject()
export default class BambooHrSyncService {

    constructor(private readonly bambooHRApiProxy: BambooHRApiProxy) {

    }

    public async setBambooHrCreds(encodedCreds: string): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.setBambooHrCreds(encodedCreds);

    }

    public async startBambooHrSync(userId: string): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.startBambooHRSynchronization(userId);

    }

    public async startBambooHrSyncTimeOff(userId: string): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.startBambooHRSynchronizationTimeOff(userId);

    }

    public async getSyncRunDashboard(correlationId: string): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.getSyncRunDashboard(correlationId);

    }

    public async getConfigurationStatus(): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.getConfigurationStatus();

    }

    public async getConfiguration(): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.getConfiguration();

    }

    public async updateBambooApiKey(bambooApiKey: string): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.updateBambooApiKey(bambooApiKey);

    }

    public async updateBambooSubdomain(subDomain: string): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.updateBambooSubdomain(subDomain);
    }

    public async attemptLoginWithBambooHr(): Promise<any> {

        const baseUrl = config.maestroAPIBaseUrl + config.maestroAPIPrivateGatewayBaseUrl;
        this.bambooHRApiProxy.bambooHRHttpClientUrl = baseUrl;
        return await this.bambooHRApiProxy.attemptLoginWithBambooHr();
    }
}
