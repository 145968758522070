import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { DialogController } from "aurelia-dialog";

@autoinject
export default class TemplateDialog {
    public popupTitle?: string;
    public popupMessage: string = "";
    public isErrorBox: boolean = false;
    public singleButton: boolean = false;
    public okLabel: string = this.i18n.tr("OK");
    public cancelLabel: string = this.i18n.tr("Cancel");

    public template: string = "";
    public templateContext: any;
    public isOverflowAllowed: boolean = false;

    constructor(private readonly i18n: I18N, private readonly dialogController: DialogController, private readonly eventAggregator: EventAggregator) {
        this.dialogController.settings.lock = false;
    }

    public activate(options: any): any {
        this.popupMessage = options.message || "";
        this.popupTitle = options.title || null;
        this.isErrorBox = options.isErrorBox || false;
        this.singleButton = options.singleButton || false;
        this.okLabel = options.okLabel || this.i18n.tr("OK");
        this.cancelLabel = options.cancelLabel || this.i18n.tr("Cancel");
        this.template = options.template;
        this.templateContext = options.templateContext;

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);

        this.isOverflowAllowed = options.isOverflowAllowed || false;
    }

    public cancelAction(): void {
        this.dialogController.cancel({wasCancelled: true});
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    public okAction(): void {
        this.dialogController.ok({wasCancelled: false, output: this.templateContext});
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}
