import { ModelDateInfoItem, ModelDatesDictionary} from "api/models/model-dates-dictionary";
import { singleton } from "aurelia-dependency-injection";
import logger from "core/logger";
import dateHelper from "helpers/dateHelper";
import moment from "moment";

@singleton()
export default class ModelDateTransformer {
    public convertModelPropertiesToDate(model: any, key: string): void {
        if (!model) {
            return;
        }

        if (model instanceof Array) {
            model.forEach((x: any): void => this.convertModelPropertiesToDate(x, key));
            return;
        }

        // Get the information about date properties for this model.
        const dateInfos: ModelDateInfoItem[] = ModelDatesDictionary[key];
        if (!dateInfos) {
            logger.warn(`Date properties information not found in dictionary for model type: ${key}`);
        }

        // Convert all dates from string to Date objects.
        if (dateInfos) {
            dateInfos.forEach((info: ModelDateInfoItem) => {
                this.convertModelPropertiesToDateProperty(model, info);
            });
        }
    }

    public convertModelPropertiesToString(model: any): void {
        if (!model) {
            return;
        }

        if (model instanceof Array) {
            model.forEach((x: any): void => this.convertModelPropertiesToString(x));
            return;
        }

        for (const key of Object.keys(model)) {
            if (model[key] instanceof Date) {
                model[key] = dateHelper.formatDateToSend(model[key]);
            } else if (typeof model[key] === "object") {
                this.convertModelPropertiesToString(model[key]);
            }
        }
    }

    private convertModelPropertiesToDateProperty(model: any, info: ModelDateInfoItem): void {
        // string: name of a date property
        // { prop, model }: description of property that is itself a model
        if (typeof info === "string") {
            this.convertModelPropertiesToDatePropertySimple(model, info);
        } else if (typeof info === "object") {
            this.convertModelPropertiesToDatePropertyObject(model, info);
        }
    }

    private convertModelPropertiesToDatePropertySimple(model: any, info: string): void {
        // string -> Date
        if (model[info]) {
            model[info] = moment(model[info]).toDate();
        }
    }

    private convertModelPropertiesToDatePropertyObject(model: any, info: { prop: string, model: string }): void {
        // Convert the model property
        if (model[info.prop]) {
            this.convertModelPropertiesToDate(model[info.prop], info.model);
        }
    }
}
