import materialService from "services/materialService";
import dailyEntryService from "services/dailyEntryService";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";

@autoinject
export class DailyEntryMaterial {

    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public readonly: boolean = false;
    public isProjectResponsible: boolean = false;
    public actions: any;

    constructor(private readonly i18n: I18N) {

    }

    public activate(params: any): void {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: string, querystring: string): void {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
        this.readonly = queryStringHelper.parseReadonly(querystring);

        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;

        this.actions = {
            deleteMaterial: materialService.deleteForProjectDailyEntry.bind(materialService, this.dispatchProjectCode, this.dispatchDate),
            getMaterials: materialService.listForProjectDailyEntry.bind(materialService, this.dispatchProjectCode, this.dispatchDate),
            goToPage: this.gotoPage.bind(this),
            getReserved: dailyEntryService.getIsReservedMaterialsForProject.bind(dailyEntryService, this.dispatchProjectCode, this.dispatchDate),
            setReservedMaterials: materialService.setReservedMaterials.bind(materialService, this.dispatchProjectCode, this.dispatchDate)
        };
    }

    public gotoPage(material: any): void {
        const url = (material && material.LineNo)
            ? routerHelper.getRelativeUrl("edit", material.LineNo) :
            routerHelper.getRelativeUrl("edit", -1);

        routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: this.readonly, isProjectResponsible: this.isProjectResponsible }));
    }
}
