import { observable } from "aurelia-binding";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable, PLATFORM } from "aurelia-framework";

import Parse from "helpers/parse";
import routerHelper from "helpers/routerHelper";
import { NotificationHelper } from "helpers/notification-helper";
import { CatalogItemManagementMode } from "api/enums/catalog-item-management-mode";

import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { InventoryProjectTransferItemModel } from "api/models/company/inventory-project-transfer-item-model";
import { InventoryProjectTransferItemResponseModel } from "api/models/company/inventory-project-transfer-item-response-model";

import { CatalogService } from "services/catalog-service";
import { CatalogInventoryService } from "services/catalog-inventory-service";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";

import { InventoryTransferSellingQtyValidation } from "api/enums/inventory-transfer-selling-qty-validation";
import { InventoryTransferQtyValidation } from "api/enums/inventory-transfer-qty-validation";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { list } from "services/workOrderService";
import { default as apiHelper } from "helpers/apiHelper";
import { default as val } from "core/val";
import { CatalogItemStatus } from "api/enums/catalog-item-status";

import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

@autoinject()
export class EditDetailsItem extends FormBase {
    public params: any;

    @bindable public transferId: number | null = null;
    @bindable public transferItem: InventoryProjectTransferItemModel | null = null;

    @bindable public isActive: boolean = false;
    @bindable public isNewItem: boolean = true;
    @bindable public isItemSelected: boolean = false;

    @bindable public selectedItem: any;
    @bindable public selectedUnit: any;
    @bindable public inventoryQty: number = 0;
    @bindable public availableQty: number = 0;

    @bindable public editItemCard: MaCardOptionsModel | null = null;

    @bindable public unitResultTemplate: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/inventoryTransfer_unit_result.html");

    @observable public inventoryScaleFactor: number;
    public unModified: InventoryProjectTransferItemModel | null = null;

    public selectedItemAvailableQty: number = 0;
    public isValidationModeBlocking: boolean = false;
    public isSellingQtyValidationEnabled: boolean = false;
    public catalogBaseUrl: string = apiHelper.getBaseUrl() + "/catalog/inventory-transfer";
    public descriptionMaxLength: number = val.get("material.description", "maxLength");

        constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly inventoryProjectTransferService: InventoryProjectTransferService, private readonly catalogService: CatalogService, private readonly catalogInventoryService: CatalogInventoryService) {
            super(notificationHelper, i18n, router);
            this.inventoryScaleFactor = catalogService.defaultScaleFactor;
        }

        public checkDirty(): boolean {
            if (!this.unModified) {
                return false;
            }

            if (!this.transferItem) {
                return false;
            }

            const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");

            this.transferItem!.InventoryQty = this.inventoryQty;
            const stringifyCurrent = JSON.stringify(this.transferItem).replace(/[^0-9A-Z]+/gi, "");

            return stringifyUnmodified !== stringifyCurrent;
        }

        public async activate(params: any): Promise<void> {
            this.params = params;
            this.isNewItem = !params.transactionNumber;
            this.transferId = params.transferId;
            this.isActive = Parse.Boolean(params.isActive);

            if (!this.isNewItem) {
                const transfer = await this.inventoryProjectTransferService.GetTransferItem(params.transactionNumber);
                this.transferItem = transfer!.Item!;
                this.unModified = CloneHelper.deepClone(this.transferItem);
                this.inventoryScaleFactor = this.transferItem.InventoryScaleFactor;
                this.selectedItemAvailableQty = this.transferItem.AvailableQty;

                this.feedValidationProperties(transfer!);
                this.initialiseSelections(this.transferItem);

                if (this.transferItem.Unit !== 0) {
                    this.genereteCard();
                }
            } else {
                this.unModified = { TransactionNumber: 0, LineNo: 0, Prefix: null, transferId: 0, ProjectNo: null, InventoryQty: 0, ActivityId: null, GroupId: null, Unit: 0, Site: null, Location: null, ProductCode: null, Description: null, Description2: null, Description3: null, InventoryUnit: null, InventoryFactor: 0, SupplierUnit: null, SupplierFactor: 0, ItemManagementMode: null, Identification: null, Characteristic: null, ReservationNo: null, AvailableQty: 0, UnitPrice: 0, SupplierUnitPrice: 0, SupplierType: null, CurrencyCode: null, TimeStampTransfer: null, InventoryScaleFactor: 0, Status: null };
            }
        }

        public get GetUnits(): any {
            return {
                transport: (params: any, success: any, failure: any): any => {
                    this.catalogInventoryService.GetCatalogInventoryItems(this.transferItem!.ProductCode!, this.transferItem!.Site!, this.params.sourcePrefix).then(
                        (result: any) => {
                            return success(result);
                        },
                        (fail: any) => {
                            return failure(fail);
                        }
                    );
                },
                mapResults: (item: CatalogInventoryModel): any => {
                    return { id: item.Unit, text: item.Unit, data: item };
                },
            };
        }

       public async selectedItemChanged(catalogItem: any): Promise<void> {
            const transfer = (await this.inventoryProjectTransferService.NewTransferItem(this.transferId!, catalogItem.id!, this.params.siteId, this.params.activityId, this.params.groupId))!;
            this.transferItem = transfer.Item!;
            this.feedValidationProperties(transfer!);

            this.selectedItemAvailableQty = this.transferItem.AvailableQty;
            this.isItemSelected = true;
            this.inventoryScaleFactor = this.transferItem.InventoryScaleFactor;
            this.clearUnitSelection();
        }

        public async onUnitSelected(catalogUnit: CatalogInventoryModel): Promise<void> {
            this.transferItem = (await this.inventoryProjectTransferService.UpdateFromCatalogInventoryItem(this.transferItem!, catalogUnit))!;
            this.inventoryQty = this.transferItem.InventoryQty;
            this.availableQty = this.transferItem.AvailableQty;
            this.genereteCard();
        }

        public async clearUnitSelection(): Promise<void> {
            this.transferItem!.Unit = 0;
            this.transferItem!.Location = null;
            this.transferItem!.Identification = null;
            this.transferItem!.ReservationNo = null;

            this.availableQty = this.selectedItemAvailableQty;
            this.editItemCard = null;
        }

        public async save(): Promise<void> {
            this.transferItem!.InventoryQty = this.inventoryQty;

            if (await this.isTransferValid()) {
                this.executeSave();
            }
        }

        private genereteCard(): void {
            this.editItemCard =
                    new MaCardOptionsModel({
                    model: this.transferItem,
                    displayLines: [
                        new MaCardLineModel({ display: `${this.i18n.tr("Unit:")} ${this.transferItem!.Unit}` }),
                        new MaCardLineModel({ display: `${this.i18n.tr("Location:")} ${this.transferItem!.Location}` }),
                        new MaCardLineModel({ display: `${this.i18n.tr("Characteristic:")} ${this.transferItem!.Characteristic}` }),
                        new MaCardLineModel({ display: `${this.i18n.tr("Identification:")} ${this.transferItem!.Identification}` }),
                        new MaCardLineModel({ display: `${this.i18n.tr("ReservationNo:")} ${this.transferItem!.ReservationNo}` })
                    ],
                    actionItems: []
                });
            if (this.isActive) {
                    this.editItemCard.actionItems!.push(new MaCardActionModel({ icon: "fa fa-trash text-danger pt-3", action: this.clearUnitSelection.bind(this) }));
            }
        }

        private async isTransferValid(): Promise<boolean> {
            const quantityAvailable = this.transferItem!.AvailableQty;
            const isQuantityValid = this.inventoryQty <= quantityAvailable;

            if (!this.transferItem) {
                return false;
            }

            if (this.transferItem.ItemManagementMode === CatalogItemManagementMode.Serial) {
                if (!this.editItemCard) {
                    this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_SerialNoItem_MustHaveInventorySelection"));
                    return false;
                }
                if (this.inventoryQty > 1) {
                    this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_SerialNoItem_ExceedingQuantity"));
                    return false;
                }
            }

            if (this.isSellingQtyValidationEnabled && !isQuantityValid && this.transferItem.Status !== CatalogItemStatus.GeneralNoValidation) {
                if (this.isValidationModeBlocking) {
                    this.notificationHelper.showError(this.i18n.tr("err_QuantityIsOverQuantityAvailable"));
                    return false;
                }

                if (this.isSellingQtyValidationEnabled && !isQuantityValid && !this.isValidationModeBlocking) {
                    const warningMsg = `${this.i18n.tr("err_QuantityIsOverQuantityAvailable")} ${this.i18n.tr("DoYouWantToSave")}`;
                    if (!(await this.notificationHelper.showDialogYesNo(warningMsg))) {
                        return false;
                    }
                }
            }

            return true;
        }

        private async executeSave(): Promise<void> {
            if (!this.isActive) { return; }
            await this.inventoryProjectTransferService.SaveTransferItems([this.transferItem!]).then((success: any): any => {
                if (success) {
                    this.unModified = null;
                    routerHelper.navigateBack();
                    return;
                }
            });
        }

        private initialiseSelections(transferItem: InventoryProjectTransferItemModel): void {
            this.selectedItem = {id: transferItem.ProductCode, text: transferItem.ProductCode };
            this.selectedUnit = {id: transferItem.Unit, text: transferItem.Unit };

            this.inventoryQty = transferItem.InventoryQty;
            this.availableQty = transferItem.AvailableQty;

            this.isItemSelected = true;
        }

        private feedValidationProperties(item: InventoryProjectTransferItemResponseModel): void {
             this.isValidationModeBlocking = item.QtyValidationMode === InventoryTransferQtyValidation.Block;
             this.isSellingQtyValidationEnabled = item.SellingQtyValidation !== InventoryTransferSellingQtyValidation.NoValidation;
        }
}
