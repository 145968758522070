import { Router } from 'aurelia-router';
import { inject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";

import { default as settings } from "repositories/settingRepository";

import { default as user } from "services/userService";
import { default as enumHelper } from "helpers/enumHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { HelpHelper } from "helpers/help-helper";
import { LocalizationHelper } from "helpers/localization-helper";
import NavigationItemBuilderService from "services/navigation-item-builder-service";
import { default as notificationHelper } from "helpers/notificationHelper";

@inject(Router, settings, notificationHelper, HelpHelper, LocalizationHelper, EventAggregator, NavigationItemBuilderService, I18N)
export class MainMenu {

    constructor(router, settings, notificationHelper, helpHelper, localizationHelper, eventAggregator, navigationItemBuilderService, i18n) {
        this.i18n = i18n;
        this.enumHelper = enumHelper;
        this.router = router;
        this.eventAggregator = eventAggregator;
        this.subscriptions = [];
        this.settings = settings;
        this.notificationHelper = notificationHelper;
        this.helpHelper = helpHelper;
        this.localizationHelper = localizationHelper;
        this.navigationItemBuilderService = navigationItemBuilderService;
        this.showSide = false;
        this.animateSide = false;

        this.currentUser = this.settings.getUser();
        this.currentVersion = this.settings.getVersionForDisplay();

        this.menuItems = [];

        this.logout = this.logout.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.navigateFromMenu = this.navigateFromMenu.bind(this);
    }

    async bind() {
        this.initHelpUrl();

        await this.initialize();
        this.subscriptions.push(this.eventAggregator.subscribe("user-options:changed", async () => { await this.initialize(); }));
        this.subscriptions.push(this.eventAggregator.subscribe("router:navigation:success", ({ instruction }) => this.initHelpUrl()));
    }

    detached() {
        _.forEach(this.subscriptions, subscription => subscription.dispose());
    }

    initHelpUrl() {
        let route = "";
        if (this.router.currentInstruction && this.router.currentInstruction.config) {
            route = this.router.currentInstruction.config.name;
        }

        const culture = this.localizationHelper.getCultureCode();
        this.helpUrl = this.helpHelper.getHelpUrl(culture, route);
    }

    closeMenu(force) {
        if (!settings.getCompany() && !force) {
            this.router.navigateToRoute("Settings");
            this.notificationHelper.showError(this.i18n.tr("err_CompanySelectionRequired"));
        }

        this.showSide = false;
        return true;
    }
    
    logout() {
        user.logout();
    }

    navigateFromMenu(menuItem) {
        const url = menuItem.navModel.href;

        if (url === "#logout" || url === "#/logout") {
            this.logout();
            return;
        }
        if (!settings.getCompany()) {
            this.showSide = false;
            this.router.navigateToRoute("Settings");
            this.notificationHelper.showError(this.i18n.tr("err_CompanySelectionRequired"));
            return;
        }
        if (this.isSameRoute(url)) {
            window.location.reload(true);
            return;
        }
        this.showSide = false;
        this.router.navigate(url);
    }

    showSideMenu(newValue) {
        this.showSide = newValue;
        if (newValue === false) {
            this.animateSide = false;
        }
    }

    async initialize() {
        this.menuItems = await this.navigationItemBuilderService.buildNavigationItemsForMenu();
    }

    isSameRoute(url) {
        var currentUrl = this.router.currentInstruction.config.route;
        var rootRoute = _.find(this.router.routes, function(route) {
            return route.route === currentUrl;
        });
        if (rootRoute.route === "") {
            rootRoute.route = routerHelper.findDefaultRouteFragment();
        }
        return rootRoute && ("#/" + rootRoute.route) === url;
    }
}
