// GENERATED FILE - DO NOT MODIFY
export enum WageType {
    Simple = 0,
    Over = 1,
    Double = 2,
    Bonus = 3,
    Equipment = 4,
}

export let nameof = "WageType";
