// GENERATED FILE - DO NOT MODIFY
import { UserGridStateModel } from "api/models/company/user-grid-state-model";
import { UserGridStateSaveModel } from "api/models/company/user-grid-state-save-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class UserGridStateProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetUserGridState(usercode: string | null, gridId: string | null, requestConfig?: HttpClientRequestConfig): Promise<UserGridStateModel | null> {
        const uri = this.buildUri(
            "/user/grid-state/{usercode}/{gridId}",
            { usercode: (usercode === null || usercode === undefined) ? undefined : usercode, gridId: (gridId === null || gridId === undefined) ? undefined : gridId },
            null
        );

        const data: UserGridStateModel | null = await this.get<UserGridStateModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.UserGridStateModel");
        return data;
    }

    public async SetUserGridState(userGridState: UserGridStateSaveModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/user/grid-state",
            null,
            null
        );

        await this.post<UserGridStateSaveModel | null, void>(uri, userGridState, requestConfig);
    }
}
