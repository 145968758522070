import { ViewLocator, viewStrategy, inject, transient } from "aurelia-framework";
import { default as AureliaLoaderHelper } from "helpers/aurelia-loader-helper";
import { ViewModeHelper, ViewMode } from "helpers/view-mode-helper";

export let viewSuffixes = {
    full: "full",
    light: "light"
};

// Order in which to search views.
// For the moment the view search order is based on the current view mode.
export let viewSearchOrder = {
    [ViewMode.Desktop]: [viewSuffixes.full, viewSuffixes.light],
    [ViewMode.Mobile]: [viewSuffixes.light, viewSuffixes.full],
};

// Based on Aurelia's default ConventionalViewStrategy class
@viewStrategy()
@transient()
@inject(AureliaLoaderHelper, ViewModeHelper)
export default class CustomViewStrategy {
    aureliaLoaderHelper = null;
    viewModeHelper = null;
    origin = null;
    moduleId = null;

    /**
    * Creates an instance of CustomViewStrategy.
    * @param aureliaLoaderHelper
    * @param viewModeHelper 
    * @param origin The origin of the view model to load the view for.
    */
    constructor(aureliaLoaderHelper, viewModeHelper, origin) {
        this.aureliaLoaderHelper = aureliaLoaderHelper;
        this.viewModeHelper = viewModeHelper;
        this.origin = origin;
        this.moduleId = origin.moduleId;
    }

    /**
    * Loads a view factory.
    * @param viewEngine The view engine to use during the load process.
    * @param compileInstruction Additional instructions to use during compilation of the view.
    * @param loadContext The loading context used for loading all resources and dependencies.
    * @param target A class from which to extract metadata of additional resources to load.
    * @return A promise for the view factory that is produced by this strategy.
    */
    async loadViewFactory(viewEngine, compileInstruction, loadContext, target) {
        const viewUrl = await this.convertOriginToViewUrl(this.origin);

        compileInstruction.associatedModuleId = this.moduleId;
        return viewEngine.loadViewFactory(viewUrl, compileInstruction, loadContext, target);
    }

    // Determines which view to use for a view model.
    async convertOriginToViewUrl(origin) {
        const baseName = this.getBaseNameFromModuleId(origin.moduleId);
        const searchOrder = this.getViewSearchOrder();

        if (baseName && searchOrder) {
            // Try to find an existing view module.
            for (let i = 0; i < searchOrder.length; i++) {
                const suffix = searchOrder[i]
                const viewUrl = `${baseName}-${suffix}.html`;

                const viewExists = await this.aureliaLoaderHelper.existsAsync(viewUrl);
                if (viewExists) {
                    return viewUrl;
                }
            }
        }

        // This is the default function called to resolve views by Aurelia.
        return ViewLocator.prototype.convertOriginToViewUrl(origin);
    }

    getBaseNameFromModuleId(moduleId) {
        if (!moduleId) {
            return null;
        }

        // Remove .js or .ts extension.
        let baseName = (moduleId.endsWith(".js") || moduleId.endsWith(".ts")) ? moduleId.substring(0, moduleId.length - 3) : moduleId;            

        // "ViewModel" --> "View"
        baseName = baseName.replace("ViewModel", "View");

        return baseName;
    }

    // Returns the view search order based on the current view mode.
    getViewSearchOrder() {
        const viewMode = this.viewModeHelper.getViewMode();
        const searchOrder = viewSearchOrder[viewMode];

        if (!searchOrder) {
            return null;
        }

        return searchOrder;
    }
}