import userService from 'services/userService';

export class UserService {
    isLogged() {
        return userService.isLogged();
    }

    login(username, password) {
        return userService.login(username, password);
    }

    changePassword(username, password, newpassword) {
        return userService.changePassword(username, password, newpassword);
    }

    logout() {
        return userService.logout();
    }

    storageClear() {
        return userService.storageClear();
    }

    getLanguage() {
        return userService.getLanguage();
    }
}