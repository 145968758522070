// GENERATED FILE - DO NOT MODIFY
import { DispatchProjectTimeEntriesSummaryResourceModel } from "api/models/company/project/dispatch/dispatch-project-time-entries-summary-resource-model";
import { ProjectDispatchBreaksModel } from "api/models/company/project/dispatch/project-dispatch-breaks-model";
import { ProjectDispatchBreaksScheduleModel } from "api/models/company/project/dispatch/project-dispatch-breaks-schedule-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DispatchProjectBreakTimeProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetBreakTimes(projectDispatchCode: string | null, dispatchDate: Date, includeLunch: boolean | undefined, includeBreaks: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectTimeEntriesSummaryResourceModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/breaktimes",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { includeLunch: (includeLunch === null || includeLunch === undefined) ? undefined : includeLunch.toString(), includeBreaks: (includeBreaks === null || includeBreaks === undefined) ? undefined : includeBreaks.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DispatchProjectTimeEntriesSummaryResourceModel[] | null = await this.get<DispatchProjectTimeEntriesSummaryResourceModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectTimeEntriesSummaryResourceModel");
        return data;
    }

    public async GetDispatchesBreaksSchedule(projectDispatchCode: string | null, dispatchDate: Date, isClosingMode: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchBreaksScheduleModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/attendances/breaks-schedule",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { isClosingMode: (isClosingMode === null || isClosingMode === undefined) ? undefined : isClosingMode.toString() }
        );

        const data: ProjectDispatchBreaksScheduleModel | null = await this.get<ProjectDispatchBreaksScheduleModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDispatchBreaksScheduleModel");
        return data;
    }

    public async SaveDispatchBreaks(projectDispatchCode: string | null, dispatchDate: Date, dispatchesBreaks: Array<ProjectDispatchBreaksModel | null> | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchBreaksModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/attendances/breaks",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectDispatchBreaksModel[] | null = await this.post<Array<ProjectDispatchBreaksModel | null> | null, ProjectDispatchBreaksModel[] | null>(uri, dispatchesBreaks, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDispatchBreaksModel");
        return data;
    }
}
