import { DocumentProxy } from "api/proxies/document-proxy";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { autoinject } from "aurelia-framework";
import { DocumentModel } from "api/models/company/document/document-model";

@autoinject()
export class DocumentService {

    constructor(private readonly documentProxy: DocumentProxy) {

    }

    public async CheckDocumentExists(documentId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return this.documentProxy.CheckDocumentExists(documentId, requestConfig);
    }

    public async GetDocument(documentId: number, requestConfig?: HttpClientRequestConfig): Promise<Uint8Array | null> {
       return this.documentProxy.GetDocument(documentId, requestConfig);
    }

    public async GetCategories(dispatchTemplateId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentCategoryModel[] | null> {
      return this.documentProxy.GetCategories(dispatchTemplateId, filter, pagingInfo, requestConfig);
    }

    public async DeleteDocument(documentId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
      return this.documentProxy.DeleteDocument(documentId, requestConfig);
    }

    public async Update(documentId: number, model: DocumentModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
       return this.documentProxy.Update(documentId, model);
    }

}
