// GENERATED FILE - DO NOT MODIFY
export enum ProcoreTransactionType {
    PurchaseOrder = 0,
    Contracts = 1,
    ChangeOrders = 2,
    DirectCosts = 3,
    OrderInvoices = 4,
    PccoChangeOrder = 5,
}

export let nameof = "ProcoreTransactionType";
