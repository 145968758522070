import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import enumHelper from "helpers/enumHelper";
import dateHelper from "helpers/dateHelper";
import { CallType } from "api/enums/call-type";
import serviceService from "services/serviceService";
import settingRepository from "repositories/settingRepository";
import { I18N } from "aurelia-i18n";

@autoinject()
export class ServiceSameAddresCalls {

    public dispatchId: number = 0;
    public serviceType: string = "";

    public list: any[] = [];
    public enumPresence: any  = enumHelper.servicePresence();
    public routerHelper: typeof routerHelper = routerHelper;
    public dateHelper: typeof dateHelper = dateHelper;
    public callType: typeof CallType = CallType;
   
    constructor(public readonly i18n: I18N) {
    }

    public activate(params: any): any {
        this.bindViewModel(params.serviceType, params.dispatchId);
        return this.loadData();
    }

    public bindViewModel(serviceType: any, dispatchId: any): any {
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
    }

    public loadData(): void {
        routerHelper.showLoading();
        return serviceService.getSameAddressCalls(settingRepository.getDispatchTemplate()!, this.dispatchId, this.serviceType).then((result: any) => {
            routerHelper.hideLoading();
            this.initialise(result);
        }, this.clearList());
    }

    private initialise(data: any): void {
        this.list = data.Calls;
    }

    private clearList(): void {
        this.list = [];
    }
}
