// GENERATED FILE - DO NOT MODIFY
export enum DispatchPresence {
    Undefined = 0,
    ProjectAway = 1,
    ProjectPresent = 2,
    ProjectQuit = 3,
    ServiceCallOnMyWay = 10,
    ServiceCallStarted = 11,
    ServiceCallDeparted = 12,
    ServiceCallAccepted = 13,
    ServiceCallRefused = 14,
    ServiceCallOnMyWayStop = 15,
    ServiceCallOnHold = 16,
    Ignore = -1,
}

export let nameof = "DispatchPresence";
