import { inject, bindable, bindingMode } from "aurelia-framework";

import "select2";

import { default as resx } from "core/resx";
import { default as labelHelper } from "helpers/labelHelper";
import { default as defaultService } from "services/defaultService";

@inject(Element)
export class Select2 {
    @bindable public allowClear: boolean = false;
    @bindable public disabled: boolean = false;
    @bindable public icon: string = "";
    @bindable public minimumResultsForSearch: number = defaultService.getMinimumResultsForSearch();
    @bindable public options: any[] = [];
    @bindable public placeholderKey: string = "SelectOoo";
    @bindable public showSearchBox: boolean = false;
    @bindable public showPlaceholder: boolean = false;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public value: string | null = null;

    private element: Element;

    constructor(element: Element) {
        this.element = element;
    }

    public attached(): void {
        const self = this;
        const $select = jQuery(this.element).find("select");

        // TODO JL Fix le bug avec @type/select2
        $select
            // @ts-ignore TS2551
            .select2({
                allowClear: this.allowClear,
                // TODO Not safe
                escapeMarkup: (text: string): string => {
                    return text;
                },
                disabled: this.disabled,
                language: labelHelper.getDefaultSelect2Labels(),
                minimumResultsForSearch: this.showSearchBox ? this.minimumResultsForSearch : Infinity,
                placeholder: resx.localize(this.placeholderKey),
                width: "100%",
                templateSelection: (x: any): string => {
                    let result = "";

                    if (self.icon) {
                        result += `<i class="${self.icon}"></i> `;
                    }

                    result += x.text;
                    return result;
                },
            })
            .on("change", (event: any) => {
                // Use self since jQuery override this inside events and callback.
                self.value = (event.target as HTMLSelectElement).value;
            })
            .on("select2:unselecting", (e: any) => {
                if (e.params.args.originalEvent) {
                    // When unselecting (in multiple mode)
                    e.params.args.originalEvent.stopPropagation();
                } else {
                    // When clearing (in single mode)
                    $($select).one("select2:opening", (ev: any) => {
                        ev.preventDefault();
                    });
                }
            });
    }

    public valueChanged(newValue: string): void {
        if (newValue) {
            const $select = jQuery(this.element).find("select");
            $select.val(newValue);
            $select.trigger("change");
        }
    }

    public disabledChanged(): void {
        const $select = jQuery(this.element).find("select");
        if ($select) {
            $select.prop("disabled", this.disabled);
        }
    }
}
