import { ValidationHelper } from "helpers/validation-helper";
import { autoinject, computedFrom } from "aurelia-framework";
import { WorkOrderMaintenanceModel } from "api/models/company/workorder/work-order-maintenance-model";
import { WorkOrderMaintenanceTaskService } from "services/work-order-maintenance-task-service";
import { nameof as nameof_WorkOrderMaintenanceType, WorkOrderMaintenanceType } from "api/enums/work-order-maintenance-type";
import { RouterHelper } from "helpers/router-helper";
import { parseReadonly } from "helpers/queryStringHelper";
import { I18N } from "aurelia-i18n";
import { WorkOrderMaintenanceTaskModel } from "api/models/company/workorder/work-order-maintenance-task-model";
import notificationHelper from "helpers/notificationHelper";
import { CloneHelper } from "helpers/cloneHelper";
import { Redirect, Router } from "aurelia-router";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject()
export class MaintenanceTasks {

    public dispatchId: string = "";
    public readonly: boolean = false;
    public maintenance: WorkOrderMaintenanceModel | null = null;
    public oldMaintenance: WorkOrderMaintenanceModel | null = null;
    public nameof_WorkOrderMaintenanceType: string = nameof_WorkOrderMaintenanceType;

    constructor(
        private readonly workOrderMaintenanceTaskService: WorkOrderMaintenanceTaskService,
        private readonly routerHelper: RouterHelper,
        private readonly i18n: I18N,
        private readonly validationHelper: ValidationHelper,
        private readonly router: Router,
        private readonly eventAggregator: EventAggregator) {
    }

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.dispatchId;
        const paramsQs = this.routerHelper.getQuerystring(params.q);
        if (paramsQs && paramsQs.readonly !== "undefined" && paramsQs.readonly) {
            this.readonly = parseReadonly(params.q);
        }

        this.maintenance = await this.workOrderMaintenanceTaskService.getMaintenanceTasks(+this.dispatchId);

        if (this.maintenance != null && this.maintenance.MaintenanceType === WorkOrderMaintenanceType.Repair) {
            this.eventAggregator.publish("updateTitle", this.i18n.tr("RepairTasks"));
        }

        if (this.maintenance != null && this.maintenance.Tasks != null) {
            this.maintenance.Tasks.forEach((task: WorkOrderMaintenanceTaskModel) => {
                if (task != null && task.IsDone) {
                    task.IsDoneDescription = this.i18n.tr("TaskCompleted");
                }
            });
        }

        this.oldMaintenance = CloneHelper.deepClone(this.maintenance);
    }

    public async save(): Promise<void> {
        if (this.maintenance != null && this.maintenance.Tasks != null) {
            let okToSave = true;
            this.maintenance.Tasks.forEach( async (task: WorkOrderMaintenanceTaskModel) => {
                if (task != null && (task.NotCompleted || task.NotApplicable) && (this.validationHelper.stringIsNullOrEmpty(task.Comments))) {
                    notificationHelper.showError(`${task.TaskId} - ${task.TaskDescription} - ${this.i18n.tr("err_CommentRequiredForMaintenanceTask")}`);
                    okToSave = false;
                }
            });
            if (okToSave) {
                await this.workOrderMaintenanceTaskService.updateMaintenanceTasks(this.maintenance);
                this.oldMaintenance = CloneHelper.deepClone(this.maintenance);
                this.routerHelper.navigateBack();
            }
        }
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            this.routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public isDirty(): boolean {
        const stringifyUnmodifiedMaintenance = JSON.stringify(this.oldMaintenance);
        const stringifyCurrentMaintenance = JSON.stringify(this.maintenance);

        return stringifyUnmodifiedMaintenance !== stringifyCurrentMaintenance;
    }
}
