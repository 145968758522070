import { AgGridHelper } from "helpers/ag-grid-helper";
import { TakeoffVariableHelper } from "helpers/takeoffVariableHelper";
import DecimalCellEditor from "ag-grid/editors/decimalCellEditor";
import CheckBoxCellRenderer from "ag-grid/renderers/checkBoxCellRenderer";
import CheckBoxCellEditor from "ag-grid/editors/checkBoxCellEditor";
import TextBoxCellEditor from "ag-grid/editors/textBoxCellEditor";

import { OverrideKeyPressHelper } from "ag-grid/helpers/overrideKeyPressHelper";

import EstimateService from "services/estimateService";
import EstimateVariableService from "services/estimateVariableService";
import EstimateTakeoffVariableImportService from "services/estimateTakeoffVariableImportService";

import { TakeoffVariableActionStatus } from "enums/takeoffVariableActionStatus";
import { inject, bindable } from "aurelia-framework";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as routerHelper } from "helpers/routerHelper";
import { default as settingRepository } from "repositories/settingRepository"
import { Routes } from "enums/routes";
import { default as notifier } from "helpers/notificationHelper";
import { XlsxLazyLoader } from "helpers/xlsx-lazy-loader";

@inject(TakeoffVariableHelper, AgGridHelper, EstimateService, EstimateVariableService, EstimateTakeoffVariableImportService, OverrideKeyPressHelper, XlsxLazyLoader)
export class TakeoffQuantity {
    estimateId = null;
    estimate = null;
    companyName = "";
    statusMessage = "";
    variables = [];
    
    constructor(takeoffVariableHelper, agGridHelper, estimateService, estimateVariableService, estimateTakeoffVariableImportService, overrideKeyPressHelper, xlsxLazyLoader) {
        this.routerHelper = routerHelper;
        this.takeoffVariableHelper = takeoffVariableHelper;
        this.resx = resx;
        this.agGridHelper = agGridHelper;
        this.estimateService = estimateService;
        this.estimateVariableService = estimateVariableService;
        this.estimateTakeoffVariableImportService = estimateTakeoffVariableImportService;
        this.overrideKeyPressHelper = overrideKeyPressHelper;
        this.xlsxLazyLoader = xlsxLazyLoader;
        this.isExporting = false;
        this.exportFile = "test";
        this._isDirty = false;
    }

    get isDirty() { return this._isDirty; }
    set isDirty(value) { this._isDirty = value; }

    isImportedVariable(variable) {
        if (variable && variable.ImportedVariableId && variable.ImportedVariableId !== 0) {
            return true;
        }

        return false;
    }

    isCellEditable(params) {
        const isImportedRow = this.isImportedVariable(params.node.data);

        if (isImportedRow && (params.colDef.field === "VariableName" || params.colDef.field === "DoNotValidate")) {
            // Rang�e import�e: seulement "Ne pas valider" et "Nom de variable" �ditable
            return true;
        } else if (!isImportedRow) {
            // Rang�e manuelle: Tout �ditable sauf le compteur
            return true;
        } else {
            return false;
        }
    }

    activate(params, routeConfig) {
        this.estimateId = params.estimateId;
        this.companyName = settingRepository.getCompanyName();

        const columnDefs = [
            // TODO: Setting editable to false for checkbox renderer seems to be fixing a bug. But we should find a better solution.
            { headerName: resx.localize("DoNotValidate"), field: "DoNotValidate", width: 120, editable: true, enableRowGroup: true, cellStyle: this.agGridHelper.cellStyleCenterAlign(), cellRenderer: CheckBoxCellRenderer, cellEditor: CheckBoxCellEditor, suppressKeyboardEvent: this.overrideKeyPressHelper.overrideCheckboxCellKeyPresses},
            { headerName: resx.localize("Counter"), field: "ImportedVariableId", width: 100, editable: false, enableRowGroup: true, cellStyle: this.agGridHelper.cellStyleRightAlign(), suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses },
            {
                headerName: resx.localize("VariableName"), field: "VariableName", width: 170, editable: p => this.isCellEditable(p), enableRowGroup: true, cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 100 },
                cellStyle: p => this.hasValidationErrors(p, "VariableName") ? this.agGridHelper.cellStyleLeftAlignWithValidationErrors(): this.agGridHelper.cellStyleLeftAlign(), 
                cellClass: p => this.hasValidationErrors(p, "VariableName") ? "ag-error-cell" : "",
                tooltipField: "validationErrorTooltips.VariableName",
                suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses
            }, 
            {
                headerName: resx.localize("Quantity"), field: "Quantity", width: 80, editable: p => this.isCellEditable(p), enableRowGroup: true,
                cellStyle: p => this.hasValidationErrors(p, "Quantity") ? this.agGridHelper.cellStyleLeftAlignWithValidationErrors() : this.agGridHelper.cellStyleLeftAlign(),
                cellClass: p => this.hasValidationErrors(p, "Quantity") ? "ag-error-cell" : "",
                tooltipField: "validationErrorTooltips.Quantity",
                cellEditor: DecimalCellEditor, cellEditorParams: { maxLength: 10 }, filter: "number", suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses
            },
            {
                headerName: resx.localize("Unit"), field: "Unit", width: 60, editable: p => this.isCellEditable(p), enableRowGroup: true,
                cellStyle: p => this.hasValidationErrors(p, "Unit") ? this.agGridHelper.cellStyleLeftAlignWithValidationErrors() : this.agGridHelper.cellStyleLeftAlign(),
                cellClass: p => this.hasValidationErrors(p, "Unit") ? "ag-error-cell" : "",
                tooltipField: "validationErrorTooltips.Unit",
                cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 5 }, suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses
            },
            { headerName: resx.localize("Drawing"), field: "Drawing", width: 100, editable: p => this.isCellEditable(p), enableRowGroup: true, cellStyle: this.agGridHelper.cellStyleLeftAlign(), cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 100 }, suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses },
            { headerName: resx.localize("Sheet"), field: "Sheet", width: 200, editable: p => this.isCellEditable(p), enableRowGroup: true, cellStyle: this.agGridHelper.cellStyleLeftAlign(), cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 100 }, suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses },
            { headerName: resx.localize("Description"), field: "Description", width: 160, editable: p => this.isCellEditable(p), enableRowGroup: true, cellStyle: this.agGridHelper.cellStyleLeftAlign(), cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 100 }, suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses },
            { headerName: resx.localize("Category"), field: "Category", width: 100, editable: p => this.isCellEditable(p), enableRowGroup: true, cellStyle: this.agGridHelper.cellStyleLeftAlign(), cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 100 }, suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses },
            { headerName: resx.localize("Notes"), field: "Notes", width: 100, editable: p => this.isCellEditable(p), enableRowGroup: true, cellStyle: this.agGridHelper.cellStyleLeftAlign(), cellEditor: TextBoxCellEditor, cellEditorParams: { maxLength: 100 }, suppressKeyboardEvent: this.overrideKeyPressHelper.overrideTextBoxCellKeyPresses }
        ];

        this.gridOptions = this.agGridHelper.createDefaultGridOptions(columnDefs);
        this.gridOptions.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.gridOptions.getMainMenuItems = this.getMainMenuItems.bind(this);

        this.gridOptions.onCellValueChanged = () => {
            this.isDirty = true;
        };

        this.gridOptions.onRowSelected = () => {
            this.gridOptions.api.deselectAll();
        }

        this.gridOptions.isExternalFilterPresent = () => { return true; }
        this.gridOptions.doesExternalFilterPass = (node) => {
            return node.data.Action !== TakeoffVariableActionStatus.Deleted.id;
        }

        this.gridOptions.suppressCopyRowsToClipboard = true;

        this.gridOptions.editType = "";

        this.gridOptions.singleClickEdit = true;

        this.gridOptions.suppressRowClickSelection = true;

        var deferred1 = this.estimateService.getEstimate(this.estimateId)
            .then((data) => {
                this.estimate = data;
            });
        var deferred2 = this.loadVariables();

        return jQuery.when(deferred1, deferred2);
    }

    hasValidationErrors(params, field) {
        return params.data && params.data.validationErrors && _.any(params.data.validationErrors, (error) => {
            return error.field === field;
        });
    }

    async attached() {
        this.grid = await this.agGridHelper.createGrid(this.gridElement, this.gridOptions);

        this.gridOptions.api.setSortModel([{ colId: "ImportedVariableId", sort: "asc" }]);

        if (this.grid && this.gridOptions) {
            this.gridOptions.api.setRowData(this.variables);
        }
    }

    loadVariables() {
        return this.estimateVariableService.getVariables(this.estimateId)
            .then((data) => {
                _.each(data,
                    (variable) => {
                        variable.validationErrors = [];
                    });
                this.variables = data;

                if (this.grid && this.gridOptions) {
                    this.gridOptions.api.setRowData(this.variables);
                }
            });
    }

    deleteSelectedVariable(row) {
        const rowToUpdate = row;
        var itemToUpdate = [];       
        rowToUpdate.data.Action = TakeoffVariableActionStatus.Deleted.id; 
        itemToUpdate.push(rowToUpdate.data);
        const result = this.gridOptions.api.updateRowData({ update: itemToUpdate });

        this.isDirty = true;

        this.gridOptions.api.onFilterChanged();
    }

    addVariable() {
        var newRows = [{
            "Action": TakeoffVariableActionStatus.New.id,
            "EstimateId": this.estimateId,
            "SectionId": 0,
            "LineNumber": 0,
            "VariableName": resx.localize("(NewVariable)"),
            "Quantity": 0.0,
            "Unit": null,
            "Description": null,
            "SpreadsheetCell": null,
            "Question": null,
            "MinimumValue": null,
            "MaximumValue": null,
            "Formula": null,
            "ViewCondition": null,
            "SupField": null,
            "QuestionType": 0,
            "Classification": null,
            "MultipleSel": 0,
            "CodeRemplacement": null,
            "SourceVariableName": "",
            "SourceCounter": 0,
            "ImportedVariableId": null,
            "Drawing": null,
            "Sheet": null,
            "Category": null,
            "Notes": null,
            "DoNotValidate": false,
            "IsTakeoffVariable": true
        }];

        var result = this.gridOptions.api.updateRowData({ add: newRows });

        for (var i = 0; i < result.add.length; i++) {
            this.variables.push(result.add[i].data);
        }

        if (result.add.length > 0) {
            this.gridOptions.api.ensureIndexVisible(result.add[0].rowIndex);
        }
    }

    saveVariables() {
        const validationResult = this.validateVariables();
        this.statusMessage = "";
        if (validationResult === true) {
            return this.estimateVariableService.updateVariables(this.estimateId, this.variables)
                .then(() => {
                this.loadVariables();
                this.isDirty = false;
            }, (error) => {
                    var responseData = JSON.parse(error.responseText);
                    if (responseData.Exception.ErrorId === 603) {
                        this.takeoffVariableHelper.parseServerDuplicateValidationNameError(responseData.Exception,
                            this.variables);

                        this.statusMessage = resx.localize("VariableFormHasErrors");
                    }
                    this.gridOptions.api.redrawRows();
                });
        } else {
            this.statusMessage = resx.localize("VariableFormHasErrors");
            return jQuery.Deferred().resolve();
        }
    }

    exit() {
        if (this.isDirty) {
            notifier.showDialogYesNo(resx.localize('ExitWithoutSaving'), "", false, { thirdButton: true, otherLabel: resx.localize("Cancel") })
                .done((success) => {
                    if (success === true) {
                        this.saveVariables().then(() => {
                            if (this.statusMessage === "") {
                                routerHelper.navigateBack();
                            }
                        });
                    } else if (success === false) {
                        routerHelper.navigateBack();
                    }
                });
        } else {
            this.routerHelper.navigateBack();
        }
    }

    navigateToImportation() {
        this.routerHelper.navigateToRoute("takeoffQuantityImport", { estimateId: this.estimateId }, {isModal : true})
            .then(() => {
                // Since the child window is shown as modal, the promise will resolve only when the child window is closed. Reload the variables.
                this.loadVariables();
            });
    }

    async exportVariablesToExcel() {
        var now = new Date(Date.now());
        var year = "" + now.getFullYear();
        var month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
        var day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }

        var fileName = 'Takeoff_measurements' + year + month + day + '.xlsx';

        var params = {
            skipHeader: false,
            columnGroups: false,
            skipFooters: true,
            skipGroups: true,
            allColumns: true,
            fileName: fileName
        };

        //R�f�rence: https://github.com/ag-grid/ag-grid/issues/2004#issuecomment-339469460

        var content = this.gridOptions.api.getDataAsCsv(params);
        content = content.replace(/\"/gm, ""); // Remove double quotes

        // TODO: Cr�er le fichier sur le serveur.
        const XLSX = await this.xlsxLazyLoader.loadModule();

        var workbook = XLSX.read(content, { type: 'binary', raw: true }); // Raw

        var columnWidths = [];

        for (var i = 0; i < this.gridOptions.columnApi.getAllColumns().length; i++) {
            columnWidths.push({ wpx: 120 });
        }

        workbook.Sheets[workbook.SheetNames[0]]['!cols'] = columnWidths;
        XLSX.writeFile(workbook, fileName, { bookType: 'xlsx', type: 'base64' });
        
    }
    
    getContextMenuItems(params) {

        let result = [];
        if (this.isRowClicked(params.node)) {
            result.push({
                name: resx.localize("Refresh"),
                action: () => this.loadVariables()
            });

            result.push("separator");

            result.push({
                name: resx.localize("AddAVariable"),
                action: () => this.addVariable()
            });

            if (this.isImportedVariable(params.node.data) === false) {
                result.push({
                    name: resx.localize("RemoveTheVariable"),
                    action: () => this.deleteSelectedVariable(params.node)
                });
            }

            result.push({
                name: resx.localize("ExportToExcel"),
                action: () => this.exportVariablesToExcel()
            });

            result.push("separator");
            result.push("destroyColumnComps");
        } else {

            result.push({
                name: resx.localize("Refresh"),
                action: () => this.loadVariables()
            });

            result.push("separator");

            result.push({
                name: resx.localize("AddAVariable"),
                action: () => this.addVariable()
            });
        }

        return result;
    }

    isRowClicked(node) {
        return node && node.data;
    }
    
    getMainMenuItems(params) {
        const result = [];
        result.push("pinSubMenu");
        result.push("separator");
        result.push("autoSizeThis");
        result.push("autoSizeAll");
        result.push("separator");
        if (params.column.colDef.showRowGroup !== true) {
            result.push(params.column.rowGroupActive ? "rowUnGroup" : "rowGroup");
            result.push("separator");
        }

        result.push("destroyColumnComps");

        const group = this.gridOptions.columnApi.getAllColumns().find(x => x.rowGroupActive === true);
        if (group) {
            result.push("expandAll");
            result.push("contractAll");
        }

        return result;
    }

    validateVariables() {
        const validationResults = this.takeoffVariableHelper.validateVariables(this.variables);
        this.gridOptions.api.redrawRows();
        return validationResults;
    }
    
}
