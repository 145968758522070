import { EventAggregator } from "aurelia-event-aggregator";
import { DialogCancelableOperationResult, DialogCloseResult, DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject
export default class ProjectBreakTimesSelection {

    public popupTitle?: string;
    public popupMessage: string = "";
    public yesLabel: string = this.i18n.tr("OK");
    public noLabel: string = this.i18n.tr("Cancel");
    public singleButton: boolean = false;
    public isErrorBox: boolean = false;
    public lunchSelected: boolean = false;
    public breakSelected: boolean = false;
    public lunchVisible: boolean = false;
    public breakVisible: boolean = false;
    public options: any;

    constructor(private readonly i18n: I18N, private readonly dialogController: DialogController, private readonly eventAggregator: EventAggregator) {
    }

    public activate(options: any): any {
        this.options = options;
        this.popupMessage = options.message || "";
        this.popupTitle = options.title || null;
        this.yesLabel = options.yesLabel || this.i18n.tr("OK");
        this.noLabel = options.noLabel || this.i18n.tr("Cancel");
        this.singleButton = options.singleButton || false;
        this.isErrorBox = options.isErrorBox || false;
        this.lunchVisible = options.lunchVisible || false;
        this.breakVisible = options.breakVisible || false;

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);
    }

    public toggleBreak(): void {
        this.options.breakSelected = !this.options.breakSelected;
    }

    public toggleLunch(): void {
        this.options.lunchSelected = !this.options.lunchSelected;
    }

    public noAction(): void {
        const result: DialogCloseResult = { wasCancelled: true };
        this.dialogController.cancel(result);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    public yesAction(): void {
        const result: DialogCloseResult = { wasCancelled: false, output: this.options };
        this.dialogController.ok(result);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

}
