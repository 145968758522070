import { DialogCloseResult } from "aurelia-dialog";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { autoinject, bindable, PLATFORM } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import UserAccessService from "services/user-access-service";
import enumHelper from "helpers/enumHelper";
import { default as RouteRepository, NavigationNew } from "repositories/routeRepository";
import dateHelper from "helpers/dateHelper";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import notificationHelper from "helpers/notificationHelper";
import Parse from "helpers/parse";

export interface EquipmentEditParameters {
    serviceType: string;
    dispatchId: number;
    equipmentId: number;
    isNewEquipment: boolean;
    isReplacement: boolean;
    allowedServiceCallContractEquipmentManagement: boolean;
    readonly: boolean;
}
@autoinject
export class EquipmentDetailsInformations {

  @bindable
  public equipment: any;

  @bindable
  public equipmentCard!: MaCardOptionsModel;

  @bindable
  public presence: any;

  @bindable
  public entityId: any;

  @bindable
  public dispatchId: any;

  @bindable
  public serviceType: any;

  @bindable
  public serviceCallId: number = 0;

  @bindable
  public allowedServiceCallContractEquipmentManagement: boolean = false;

  @bindable
  public readonly: boolean = true;

  public EquipmentEditParameters!: EquipmentEditParameters;

  public dateHelper: any = dateHelper;

  private recommandationOptionEnabled: boolean = false;
  private quotationOptionEnabled: boolean = false;
  private equipmentService: ServiceCallContractEquipmentService;
  private readonly invalidateDialogTemplate: any = PLATFORM.moduleName("pages/templates/dialogTemplates/invalidateDateTemplate");

  constructor(private readonly i18n: I18N,
              private readonly routeRepository: RouteRepository,
              userAccessService: UserAccessService, equipmentService: ServiceCallContractEquipmentService) {
    this.i18n = i18n;
    this.recommandationOptionEnabled = userAccessService.isOptionEnabledSync(enumHelper.userOptions.SERVICERECOMMENDATION);
    this.quotationOptionEnabled = userAccessService.isOptionEnabledSync(enumHelper.userOptions.SERVICEQUOTATIONS);
    this.equipmentService = equipmentService;
  }

    public bind(): void {
      this.readonly = Parse.Boolean(this.readonly);
      this.equipmentCard = new MaCardOptionsModel({
          model: this.equipment,
          class: "ma-card-link",
          action: this.edit.bind(this),
          displayLines: [ new MaCardLineModel({
              isCustomTemplate: true,
          })],
          actionItems: [new MaCardActionModel({
            icon: "fa fa-chevron-right", action: this.edit.bind(this) })
          ],
      });

  }

  public edit(): void {
      const params = {
          serviceType: this.serviceType,
          dispatchId: this.dispatchId,
          equipmentId: this.equipment.Id,
          isNewEquipment: false,
          isReplacement: false,
          allowedServiceCallContractEquipmentManagement: this.allowedServiceCallContractEquipmentManagement,
          readonly: this.readonly
        } as EquipmentEditParameters;

      routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_Equipment_Edit.name, params);
  }

  public async disable(): Promise<void> {
      const today = new Date();

      const inactivityDate = this.equipment.InactivityStartDate ? this.equipment.InactivityStartDate : today;

      await notificationHelper.showTemplateDialog(
        "",
        "",
        this.invalidateDialogTemplate,
        {
            isOverflowAllowed: true,
            templateContext : {
                invalidateDate: inactivityDate,

            }
        }).then(async (result: DialogCloseResult): Promise<void> => {
            if (!result.wasCancelled) {
                this.equipment.InactivityStartDate = result.output.invalidateDate;
                this.equipmentService.updateContractEquipment(this.dispatchId, this.equipment);
            }
        });
  }

  public replace(): void {
    const params = {
        serviceType: this.serviceType,
        dispatchId: this.dispatchId,
        equipmentId: this.equipment.Id,
        isNewEquipment: true,
        isReplacement: true,
        allowedServiceCallContractEquipmentManagement: this.allowedServiceCallContractEquipmentManagement,
        readonly: this.readonly
      } as EquipmentEditParameters;

    routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_Equipment_Replace.name, params);
  }

  private showDocument(): void {
    const url = location.href.substr(0, location.href.lastIndexOf("/")) + "/" + encodeURIComponent(this.equipment.Code) + "/document" +
                                        routerHelper.addQuerystring({
                                              equipmentId: this.equipment.Id,
                                              entityId: this.entityId,
                                              presence: this.presence
                                            });

    routerHelper.navigate(url);
  }

  private showRecommandation(): void {
    const url = location.href.substr(0, location.href.lastIndexOf("/equipment")) + "/recommendation/edit" +
                                        routerHelper.addQuerystring({
                                              equipmentId: this.equipment.Id,
                                              entityId: this.entityId,
                                              presence: this.presence
                                            });

    routerHelper.navigate(url);
  }

  private gotoCreateQuotation(): void {
    routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Details.name,
      { quotationId: NavigationNew,
        sourceServiceCallId: this.entityId,
        contractEquipmentId: this.equipment.Id,
        isWorkOrder: false,
        dispatchId: this.dispatchId });
  }
}
