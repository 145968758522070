import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { autoinject, bindable, customAttribute } from "aurelia-framework";
import { Synchronization } from "../../synchronization";
import { ProcoreMenuItemId } from "./enums/menu-item-id";
import { MenuItemsGenerator } from "./helpers/menu-items-generator";
import { MenuItem } from "./models/menu-item";

@autoinject
export class SideBar {
  public synchronization?: Synchronization;

  public menuItems: MenuItem[] = [];

  constructor(public readonly menuItemsGenerator: MenuItemsGenerator) {
  }

  public get supplierSelected(): boolean {
    return this.selectedItemId === ProcoreMenuItemId.Suppliers;
  }

  public get pccoClientSelected(): boolean {
    return this.selectedItemId === ProcoreMenuItemId.PccoClients;
  }

  public get budgetSelected(): boolean {
    return this.selectedItemId === ProcoreMenuItemId.Budget;
  }

  public get budgetModificationSelected(): boolean {
    return this.selectedItemId === ProcoreMenuItemId.BudgetModifications;
  }

  public get transactionsSelected(): boolean {
    return this.selectedItemId === ProcoreMenuItemId.Transactions;
  }

  public get deleteDirectCostsSelected(): boolean {
    return this.selectedItemId === ProcoreMenuItemId.DeleteDirectCosts;
  }

  public get employeesSelected(): boolean {
    return this.selectedItemId === ProcoreMenuItemId.Employees;
  }

  public get PrimeContractsSelected(): boolean {
      return this.selectedItemId === ProcoreMenuItemId.PrimeContracts;
    }

  // Generate here

  public get selectedItemId(): ProcoreMenuItemId | null {
    if (this.selectedItem) {
      return this.selectedItem.id;
    }

    return null;
  }

  public get selectedItem(): MenuItem | undefined {
    return this.menuItems.find((menuItem: MenuItem): boolean => menuItem.selected);
  }

  public generateMenu(): void {
    if (this.synchronization) {
        this.menuItemsGenerator.generate(this.synchronization.isTools).then((results: MenuItem[]): void => {
            this.menuItems = results;
            if (this.synchronization) {
                this.synchronization.initialLoadingFinished = true;
            }
        });
    }
  }

  public selectItem(selectedItem: HTMLElement): void {
    if (!this.synchronization || this.synchronization.isLoading) { return; }
    const menuItemId = Number.parseInt(selectedItem.id);

    this.removeSelectedProperty();
    this.addSelectedProperty(menuItemId);

    this.hideOldGrids();
    this.showNewGrid();

    if (this.synchronization) {
      this.synchronization.refresh();
    }
  }

  private showNewGrid(): void {
    const gridHtmlElement = document.getElementsByTagName("grid");

    this.showGrid(gridHtmlElement[0]);
  }

  private showGrid(element: Element): void {
    element.removeAttribute("hidden");
  }

  private hideAllGrids(): void {
    this.hideNewGrid();
    this.hideOldGrids();
  }

  private hideNewGrid(): void {
    const gridHtmlElement = document.getElementsByTagName("grid");

    gridHtmlElement[0].setAttribute("hidden", "");
  }

  private hideOldGrids(): void {
    const oldGridsHtmlElements = Array.from(document.querySelectorAll("old-grids > *"));

    oldGridsHtmlElements.forEach((oldGridsHtmlElement: HTMLElement) => {
      oldGridsHtmlElement.setAttribute("hidden", "");
    });
  }

  private removeSelectedProperty(): void {
    this.menuItems.forEach((menuItem: MenuItem) => {
      menuItem.selected = false;
    });
  }

  private addSelectedProperty(selectedItemId: ProcoreMenuItemId): void {
    const menuItem = this.menuItems.find((x: MenuItem) => x.id === selectedItemId);

    if (menuItem) {
      menuItem.selected = true;
    }
  }
}
