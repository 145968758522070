define([], function() {
    "use strict";

    var store = window.localStorage;

    var exports = {
        get: function(key) {
            return store.getItem(key);
        },

        set: function(key, value) {
            store.setItem(key, value);
        },

        has: function(key) {
            return store.key(key);
        },

        remove: function(key) {
            store.removeItem(key);
        },

        clear: function() {
            store.clear();
        },

        checkStorage: function() {
            try {
                store.setItem('check', 'check');
                store.removeItem('check');
                return true;
            } catch (exception) {
                return false;
            }
        }
    };

    return exports;
});
