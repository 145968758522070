import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AgGridHelper } from "helpers/ag-grid-helper";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { ProcoreEntityToSynchronize } from "../../../../../api/enums/procore-entity-to-synchronize";
import { ProcoreBudgetType } from "../../../../../api/enums/procore-budget-type";

@autoinject()
export class ProcoreErrorGrid {
    @bindable({changeHandler: "dataChanged"})
    public errors: ProcoreError[] | null = [];
    
    @bindable({ changeHandler: "dataChanged"})
    public warnings: ProcoreError[] | null = [];
    
    @bindable
    public dataForExcel: Uint8Array | null = null;

    @bindable
    public toggleText: string;

    @bindable
    public groupName: string | null = null;

    @bindable
    public entityToSynchronize: ProcoreEntityToSynchronize | null = null;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public gridOptions: any;

    private language: string | null;
    private errorFiltered: boolean = false;
    private warningFiltered: boolean = false;
    
    private errorGrid: HTMLElement | null = null;

    constructor(private readonly agGridHelper: AgGridHelper, private readonly i18n: I18N) {
        this.toggleText = this.i18n.tr("Procore.Errors_Warnings_Toggle_Text");
        this.language = localStorage.getItem("maestro_user_profile_LANGUAGE");

        window.addEventListener("resize", () => {
            this.setupMinHeight();
        });
    }
    
    public dataChanged(newValue: any[] | null, oldValue: any[] | null): void {
        if (newValue && oldValue && newValue.length !== oldValue.length && !!this.gridOptions && this.gridOptions.api) {
            this.gridOptions.api.setRowData(this.getRowData());
        }
    }

    public attached(): void {
        const autoGroupColumnDef = {
            headerName: this.groupName,
            width: 400
        };

        this.gridOptions = {
            enableSorting: true,
            autoGroupColumnDef: autoGroupColumnDef,
            toolPanelSuppressSideButtons : true,
            columnDefs: this.getColumnDefs(),
            menuTabs: [],
            
            onGridReady: this.onGridReady.bind(this),
            enableColResize: true
        };

        if (this.errorGrid) {
            this.agGridHelper.createGrid(this.errorGrid, this.gridOptions);
        }

        if (!this.getEntitiesGrid() && this.errorGrid) {
            this.errorGrid.style.display = "none";
        }
    }

    public textToIcon(params: any): string {
        switch (params.value) {
            case "error":
                return "<i class=\"fas fa-times-circle procore-error-icon\"></i>";
            case "warning":
                return "<i class=\"fas fa-exclamation-circle procore-warning-icon\"></i>";
            default:
                return "";
        }
    }

    public toggleErrorFilter(): void {
        this.toggleFilter("error");
    }

    public toggleWarningFilter(): void {
        this.toggleFilter("warning");
    }

    public toggleFilter(value: string): void {
        if (value === "error") {
            this.errorFiltered = !this.errorFiltered;
        }
        
        if (value === "warning") {
            this.warningFiltered = !this.warningFiltered;
        }
        
        const filteredData = this.getRowData().filter((item: any) => {
            if (this.errorFiltered && !this.warningFiltered) {
                return item.Type !== "error";
            }

            if (this.warningFiltered && !this.errorFiltered) {
                return item.Type !== "warning";
            }

            return !this.warningFiltered || !this.errorFiltered;
        });

        this.gridOptions.api.setRowData(filteredData);
    }

    public toggleGrids(): void {
        if (!this.errorGrid) { return; }

        if (this.gridOptions) {
            this.gridOptions.api.setRowData(this.getRowData());
        }

        const entitiesGrid = this.getEntitiesGrid();

        this.toggleFiltersScreen();

        // Pour écran post-synchro (log)
        if (!entitiesGrid && this.errorGrid) {
            this.errorGrid.classList.toggle("closed");
            let minHeight = "200px";
            if (this.errorGrid.classList.contains("closed")) {
                minHeight = "0";
            }

            this.animateSingleGrid(this.errorGrid, minHeight);

            if (this.errorGrid.classList.contains("closed")) {
                this.errorGrid.style.display = "none";
            } else {
                this.errorGrid.style.display = "block";
            }

            return;
        }

        // Pour écran de synchro
        if (entitiesGrid && this.errorGrid) {
            entitiesGrid.classList.toggle("closed");
            this.errorGrid.classList.toggle("closed");

            if (!entitiesGrid!.classList.contains("closed")) {
                this.toggleText = this.i18n.tr("Procore.Errors_Warnings_Toggle_Text");
                this.animateGrids(this.errorGrid, entitiesGrid);
            } else {
                this.toggleText = this.i18n.tr("Procore.Errors_Warnings_Toggle_Text_To_Entities");
                this.animateGrids(entitiesGrid, this.errorGrid);
            }
        }
    }

    private getEntitiesGrid(): HTMLElement {
        return this.errorGrid!.parentElement!.nextElementSibling!.querySelector(".synchronize-grid") as HTMLElement;
    }

    private animateGrids(closingGrid: HTMLElement, openeningGrid: HTMLElement): void {
        const minHeightOpened = closingGrid.style.minHeight;
        
        this.animateSingleGrid(openeningGrid, minHeightOpened);
        this.animateSingleGrid(closingGrid, "0");
    }

    private animateSingleGrid(grid: HTMLElement, height: string | null): void {
        grid.classList.add("procore-grid-animation");
        grid.style.minHeight = height;

        const child = grid.querySelector(".ag-root-wrapper-body") as HTMLElement;
        if (child) {
            child.classList.add("procore-grid-animation");
            child.style.minHeight = height;
        }
    }

    private toggleFiltersScreen(): void {
        if (!this.errorGrid) { return; }

        const procoreFilters = this.errorGrid.parentElement!.querySelector(".procore-filters") as HTMLElement;
        
        if (procoreFilters.style.maxHeight === "20px") {
            procoreFilters.style.maxHeight = "0px";
        } else {
            procoreFilters.style.maxHeight = "20px";
        }
    }

    private getRowData(): any[] {
        const rowData: any[] = [];
        
        if (this.errors) {
            this.errors.forEach((error: ProcoreError) => {
                rowData.push({
                    Group: error.Group,
                    Group2: error.Group2,
                    Group3: error.Group3,
                    Type: "error",
                    Message: this.language === "fr" ? error.MessageFr : error.MessageEn
                });
            });
        }

        if (this.warnings) {
            this.warnings.forEach((warning: ProcoreError) => {
                rowData.push({
                    Group: warning.Group,
                    Group2: warning.Group2,
                    Group3: warning.Group3,
                    Type: "warning",
                    Message: this.language === "fr" ? warning.MessageFr : warning.MessageEn
                });
            });
        }

        return rowData;
    }

    private setupMinHeight(): void {
        const grid = this.errorGrid!.querySelector(".ag-root-wrapper-body") as HTMLElement;

        if (this.errorGrid!.classList.contains("closed")) {
            grid.style.minHeight = "0";
        } else {
            grid.style.minHeight = window.innerHeight - 225 + "px";
        }
    }

    private getColumnDefs(): any {
        const columnDefs: any[] = [];

        this.createGroups(columnDefs);
        this.createColumns(columnDefs);

        return columnDefs;
    }

    private onGridReady(params: any): any {
        params.api.setRowData(this.getRowData());
    }

    private createGroups(columnDefs: any[]): void {
        columnDefs.push(this.createRowGroup({ field: "Group", width: 250 }));
        columnDefs.push(this.createRowGroup({ field: "Group2", width: 250 }));
        columnDefs.push(this.createRowGroup({ field: "Group3", width: 250 }));
    }

    private createColumns(columnDefs: any[]): void {
        columnDefs.push(this.createColumnDefinition({ field: "Type", width: 0, hidden: true }));
        columnDefs.push(this.createColumnDefinition({ field: "Type", width: 40, cellRenderer: this.textToIcon, cellStyle: { "text-align": "center" } }));
        columnDefs.push(this.createColumnDefinition({ field: "Message", width: 1000 }));
    }

    private createRowGroup(params: any): any {
        let cellRenderer: any;
        if (params.field === "Group2") {
            cellRenderer = this.synchronizationGroupingFormatter.bind(this);
        }

        return {
            headerName: "",
            width: params.width,
            field: params.field,
            hide: true,
            rowGroup: true,
            suppressMenu: true,
            suppressMovable: true,
            cellRenderer: cellRenderer,
            enableColResize: true
        };
    }

    private createColumnDefinition(params: any): any {
        return {
            headerName: "",
            //width: params.width,
            field: params.field,
            hide: params.hidden,

            // suppressMenu: true,
            // suppressMovable: true,
            // suppressSorting: true,

            cellRenderer: params.cellRenderer,

            cellStyle: params.cellStyle,
            enableColResize: true

        };
    }

    private synchronizationGroupingFormatter(params: any): string {
        const valueAsNumber = Number(params.value);
        const valueIsNaN = Number.isNaN(valueAsNumber);

        if (!valueIsNaN && this.entityToSynchronize === ProcoreEntityToSynchronize.DirectCostItems) {
            return this.i18n.tr("Procore.Sync_Grid_Grouping_" + ProcoreBudgetType[params.value]);
        }

        return params.value;
    }
}
