// GENERATED FILE - DO NOT MODIFY
export enum ProjectLabourBillingCatalogCodeEn {
    Category = "WORKCATEG",
    Annex = "ANNEX",
    YearLevel = "LEVEL",
    Shift = "SHIFT",
    Region = "REGION",
    Sector = "SECTOR",
}

export let nameof = "ProjectLabourBillingCatalogCodeEn";
