define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        listForDate: function (dispatchProjectCode, dispatchDate, allowEquipmentTimeEntryForSimpleUser) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/attendances?allowEquipmentTimeEntryForSimpleUser=" + allowEquipmentTimeEntryForSimpleUser);
        },

        updateStatusForAllDispatches: function (dispatchProjectCode, dispatchDate, allowEquipmentTimeEntryForSimpleUser) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/allpresent?allowEquipmentTimeEntryForSimpleUser=" + allowEquipmentTimeEntryForSimpleUser);
        },

        // TODO: KG - ShowLoading: false - Transport
        listAvailableAttendanceForProject: function (dispatchProjectCode, dispatchDate, templateId, type, displayUnavailable, filter, page) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/attendances/available/" + type;
            url = apiHelper.appendQueryStringParameters(url, { 'templateCode': templateId, 'filter': filter, 'page': page, displayUnavailable: displayUnavailable });
            return requests.getJson(url);
        },

        // TODO: KG - Ne semble pas utilisé
        addEmployee: function (dispatchProjectCode, dispatchDate, employeeId, templateId) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/employees/" + employeeId;
            url = apiHelper.appendQueryStringParameters(url, { 'templateCode': templateId });
            return requests.post(url);
        },

        // TODO: KG - Ne semble pas utilisé
        addTeam: function (dispatchProjectCode, dispatchDate, teamId, templateId) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/teams/" + teamId;
            url = apiHelper.appendQueryStringParameters(url, { 'templateCode': templateId });
            return requests.post(url);
        },

        listForDateOrderByName: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/attendances?sort=name");
        },

        listForWorkorderId: function (workorderId) {
            return requests.getJson(apiHelper.getFieldServiceWorkOrderBaseUrl(workorderId) + "/attendances");
        },

        // TODO: KG - ShowLoading: false - Transport
        listAvailableAttendancesForWorkorderId: function (workorderId, type, templateId, filter, page) {
            var url = apiHelper.getFieldServiceWorkOrderAvailableAttendancesBaseUrl(workorderId) + "/" + type;
            url = apiHelper.appendQueryStringParameters(url, { 'dispatchTemplateId': templateId });
            url = apiHelper.appendFilterPagingParams(url, filter, page);

            return requests.getJson(url, null);
        },

        addAttendanceForWorkorder: function (workorderId, dataToPost, templateId) {
            var url = apiHelper.getFieldServiceWorkOrderAttendancesBaseUrl(workorderId);
            url = apiHelper.appendQueryStringParameters(url, { 'dispatchTemplateId': templateId });
            return requests.post(url, dataToPost);
        },

        addAttendanceForProject: function (dispatchProjectCode, dispatchDate, dataToPost, templateId, bypassEmployeeAvailabilityValidation) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/attendances";
            url = apiHelper.appendQueryStringParameters(url, { 'templateCode': templateId, bypassEmployeeAvailabilityValidation: !!bypassEmployeeAvailabilityValidation });
            return requests.post(url, dataToPost);  
        },

        updateHoursEquipmentAttendance: function (dispatchProjectCode, dispatchDate, dispatchId, dateFrom, dateTo) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/attendance/" + dispatchId +"/hours-edit?dateFrom=" + dateFrom + "&dateTo=" + dateTo);
        },
    };

    return exports;
});
