import { singleton, inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import logger from "core/logger";
import { default as HostContext } from "core/host-context";
import { default as routerHelper } from "helpers/routerHelper";

@singleton()
@inject(HostContext)
export class HostRedirect {
    constructor(hostContext) {
        this.hostContext = hostContext;
        this.hasError = false;
        this.errorMessage = "";
    }

    canActivate() {
        if (!this.hostContext.isHosted()) {
            // HostRedirect devrait toujours être appelé à partir du host C#, donc HostViewModel devrait être défini.
            logger.error("HostRedirect - HostViewModel not found.");
            this.errorMessage = "HostViewModel not found";
            this.hasError = true;
            return true;
        }

        const promise1 = this.hostContext.viewModel.getProperty("routeName");
        const promise2 = this.hostContext.viewModel.getProperty("routeParameters");

        return Promise.all([promise1, promise2]).then(([routeName, routeParameters]) => {
            const redirectRoute = routerHelper.getRouteByName(routeName);

            if (!redirectRoute) {
                // Route non-trouvée.
                logger.debug(`HostRedirect - Target route not found: ${routeName}.`);
                this.errorMessage = `Route not found: ${routeName}`;
                this.hasError = true;
                return true;
            } else {
                logger.debug(`HostRedirect - Redirecting to route: ${routeName}.`);
                
                const url = routerHelper.generate(routeName, JSON.parse(routeParameters));
                logger.debug(`HostRedirect - Target URL: ${url}.`);

                return new Redirect(url);
            }
        });
    }

    activate() {
    }
}
