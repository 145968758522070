// GENERATED FILE - DO NOT MODIFY
import { ServiceCallDispatchResponseModel } from "api/models/company/service-call/service-call-dispatch-response-model";
import { ServiceWorkOrderWriteModel } from "api/models/company/workorder/service-work-order-write-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceWorkOrderProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async CreateServiceWorkOrder(workOrder: ServiceWorkOrderWriteModel | null, dispatchTemplateId: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallDispatchResponseModel | null> {
        const uri = this.buildUri(
            "/services/workorders",
            null,
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId }
        );

        const data: ServiceCallDispatchResponseModel | null = await this.post<ServiceWorkOrderWriteModel | null, ServiceCallDispatchResponseModel | null>(uri, workOrder, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallDispatchResponseModel");
        return data;
    }

    public async InitNewServiceWorkOrder(requestConfig?: HttpClientRequestConfig): Promise<ServiceWorkOrderWriteModel | null> {
        const uri = this.buildUri(
            "/services/workorders/init-new-service-work-order",
            null,
            null
        );

        const data: ServiceWorkOrderWriteModel | null = await this.get<ServiceWorkOrderWriteModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.ServiceWorkOrderWriteModel");
        return data;
    }
}
