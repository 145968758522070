import { GeolocationService } from "services/geolocationService";

define([
    "moment",
    "services/requests",
    "helpers/apiHelper",
    "services/defaultService"
], function (moment, requests, apiHelper, defaultService) {
    "use strict";

    var geolocationService = new GeolocationService();

    var exports = {
        get: function (dispatchProjectCode, dispatchDate, timeEntryMode, inChargeRequireIndicateWhenEmployeeLeftWorksite, allowEquipmentTimeEntryForSimpleUser) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "?timeEntryMode=" + timeEntryMode + "&inChargeRequireIndicateWhenEmployeeLeftWorksite=" + inChargeRequireIndicateWhenEmployeeLeftWorksite + "&allowEquipmentTimeEntryForSimpleUser=" + allowEquipmentTimeEntryForSimpleUser);
        },

        getItemsCount: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/itemscount");
        },

        setWeather: function (dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/weather", data);
        },

        getWorkDescription: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/workdescription");
        },

        setWorkDescription: function (dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/workdescription", data);
        },

        addEntry: function (dispatchProjectCode, data) {
            return requests.post(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/dispatchdates", data);
        },

        complete: function (dispatchProjectCode, dispatchDate, forceSave, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/complete?forceSave="+forceSave, data);
        },

        completeList: function (dispatchDate, dispatchProjectCodes, timeEntryMode, inChargeRequireIndicateWhenEmployeeLeftWorksite) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(null, dispatchDate) + "/complete" + "?timeEntryMode=" + timeEntryMode + "&inChargeRequireIndicateWhenEmployeeLeftWorksite=" + inChargeRequireIndicateWhenEmployeeLeftWorksite, dispatchProjectCodes);
        },

        uncomplete: function (dispatchProjectCode, dispatchDate) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/uncomplete");
        },

        getBreakTimes: function (dispatchProjectCode, dispatchDate, includeLunch, includeBreak) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/breaktimes?includeLunch="+includeLunch+"&includeBreaks="+includeBreak);
        },

        // TODO: KG - ShowLoading: false
        logPositionIfNeeded: function (dispatchProjectCode, dispatchDate) {
            var lastPost = defaultService.getLastProjectGeolocationPost();
            var now = moment();

            if (!lastPost || now.diff(moment(lastPost), "minutes")>= 30) {
                geolocationService.getCurrentLocation().done(function (position) {
                    var dataToPost = {
                        Latitude:position.coords.latitude,
                        Longitude:position.coords.longitude
                    };
                    requests.postAndForget(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/location", dataToPost);
                    defaultService.setLastProjectGeolocationPost(now.toISOString());
                });
            }
        },

        addCopyDailyEntry: function (dispatchProjectCode, sourceDateTime, destinationDateTime, copyTimeEntries, timeEntryMode, inChargeRequireIndicateWhenEmployeeLeftWorksite, copySubContractors) {
            return requests.post(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/dispatchdates/copy?sourceDateTime=" + sourceDateTime + "&destinationDateTime=" + destinationDateTime + "&copyTimeEntries=" + copyTimeEntries + "&timeEntryMode=" + timeEntryMode + "&inChargeRequireIndicateWhenEmployeeLeftWorksite=" + inChargeRequireIndicateWhenEmployeeLeftWorksite + "&copySubContractors=" + copySubContractors);
        },

        checkExistingDailyEntry: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/checknotexists");
        },

        getDailyEntrySummary: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/summary");
        },

        setSignature: function (dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/signature", data);
        },

        printform: function (dispatchProjectCode, dispatchDate, data) {
            return requests.postAndForget(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/printform", data);
        },

        deleteDailyEntry: function (dispatchProjectCode, dispatchDate) {
            return requests.remove(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate));
        },

        getIsReservedMaterialsForProject: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/materials/options");
        },
    };

    return exports;
});
