import { default as requests } from "services/requests";
import { default as apiHelper } from "helpers/apiHelper";

export default class EstimateVariableService {
    getVariables(estimateId) {
        return requests.getJson(apiHelper.getBaseUrl() + "/estimates/" + estimateId + "/takeoffvariables");
    }

    updateVariables(estimateId, variables) {
        return requests.post(apiHelper.getBaseUrl() + "/estimates/" + estimateId + "/takeoffvariables", variables);
    }
}
