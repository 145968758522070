import { DOM, bindingMode, inject, observable, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import { ViewModeHelper } from "helpers/view-mode-helper";
import moment from "moment";
import { Key } from "ts-keycode-enum";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { default as dateHelper } from "helpers/dateHelper";
@transient()
@inject(Element, ViewModeHelper)
export class DatePicker extends FormControl {
    containerElement = null;
    buttonElement = null;
    calendarElement = null;
    open = false;
    dateFormat = false;

    @observable()
    stringRepresentation = "";

    @bindable disabled = false;
    @bindable.date minDate;
    @bindable.date maxDate;
    @bindable.date({ defaultBindingMode: bindingMode.twoWay }) value;

    @bindable.boolean isBubblingEnabled = false;

    onKeyDownEventHandlerDelegate;

    valueChanged(newValue) {
        this.updateStringRepresentation(newValue);

        CustomEventHelper.dispatchEvent(this.element,
            "value-changed",
            newValue ? newValue : null,
            this.isBubblingEnabled,
            false);

    }

    updateStringRepresentation(value) {
        if (!value) {
            this.stringRepresentation = "";
            return;
        }

        const date = dateHelper.setMomentLocal(value);

        this.stringRepresentation = date.format(this.dateFormat);
    }

    constructor(element, viewModeHelper) {
        super(element);
        this.viewModeHelper = viewModeHelper;

        this.onKeyDownEventHandlerDelegate = (e) => {
            this.onKeyDownEventHandler(e);
        };
    }

    bind() {

        const date = dateHelper.setMomentLocal(moment());

        this.dateFormat = this.viewModeHelper.getIsMobileMode() ? date.localeData().longDateFormat("LL") : date.localeData().longDateFormat("L");
        this.updateStringRepresentation(this.value);
    }

    attached() {
        super.attached();

        window.addEventListener("keydown", this.onKeyDownEventHandlerDelegate, true);
    }

    detached() {
        window.removeEventListener("keydown", this.onKeyDownEventHandlerDelegate, true);
    }

    buttonClicked() {
        if (this.open) {
            this.closeCalendar();
        } else {
            this.openCalendar();
        }
    }

    openCalendar() {
        this.open = true;
    }

    closeCalendar() {
        this.open = false;
    }

    onDateSelected(event) {
        event.stopPropagation();
        this.closeCalendar();
    }

    clear(event) {
        event.stopPropagation();
        this.value = null;
    }

    onKeyDownEventHandler(e) {
        const escapeHandler = () => {
            if (this.open) {
                this.closeCalendar();
            }
        };

        const downArrowHandler = () => {
            if (!this.open && this.containerElement.contains(window.document.activeElement)) {
                this.openCalendar();
            }
        };

        switch (e.keyCode) {
            case Key.Escape:
                return escapeHandler();
            case Key.DownArrow:
                return downArrowHandler();
            default:
                return;
        }
    }
}