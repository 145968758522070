import { InspectionSignatureProxy } from "api/proxies/inspection-signature-proxy";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { DocumentModel } from "api/models/company/document/document-model";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";

@autoinject()
export class InspectionSignatureService {
    constructor(private readonly inspectionSignatureProxy: InspectionSignatureProxy) {}

    public async AddSignatureForInspection(inspectionId: number, signatureNo: number, model: DocumentModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
       return this.inspectionSignatureProxy.AddSignatureForInspection(inspectionId, signatureNo, model, requestConfig);
    }

    public async GetContactsForSignature(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ContactLookupModel[] | null> {
        return this.inspectionSignatureProxy.GetContactsForSignature(filter, pagingInfo, requestConfig);
    }
}
