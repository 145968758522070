import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import settingRepository from "repositories/settingRepository";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { autoinject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";

import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class MaterialOrderDocumentAdd {
    public dispatchId?: string;

    public readonly: boolean = false;
    public actions: any = {};
    public isCategorySelectionAllowed: boolean = false;
    public isDirty: boolean = false;
    private dispatchTemplateId: string | null = "";

    constructor(
        private readonly dispatchTemplateService: DispatchTemplateService,
        private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router
    ) {}

    public async activate(params: any): Promise<void> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");

        this.dispatchTemplateId = settingRepository.getDispatchTemplate();
        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(this.dispatchTemplateId!);

        this.isCategorySelectionAllowed = dispatchTemplate!.AllowCategorySelection;

        this.setupHeaderInfos(params.dispatchId);

        await this.setAction(params.orderId);
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    private setupHeaderInfos(dispatchId: string): void {
      this.dispatchId = dispatchId;
    }

    private async setAction(orderId: string): Promise<void> {
        this.actions = {
            save: documentService.addDocumentByPurchaseOrderId.bind(self, orderId, this.dispatchTemplateId)
            };
    }
}
