import { Tab } from "components/layout/tab";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import notificationHelper from "helpers/notificationHelper";

import { InspectionService } from "services/inspection-service";

import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { InspectionHeaderModel } from "api/models/company/inspection/inspection-header-model";
import { InspectionStatus } from "api/enums/inspection-status";

@autoinject
export class InspectionIndex {
    @bindable public currentTabId: number = 0;
    public isCompletedInspectionsLoaded: boolean = false;

    public onGoingInspectionsCards: MaCardOptionsModel[] = [];
    public closedInspectionsCards: MaCardOptionsModel[] = [];

    constructor(private readonly i18n: I18N, private readonly inspectionService: InspectionService, private routeRepository: RouteRepository) {}

    public bind(): void {
        this.inspectionService.GetInspections(false, null).then((result: InspectionHeaderModel[]): any => {
            result.forEach((item: InspectionHeaderModel) => {
                this.onGoingInspectionsCards.push(this.createCard(item, false));
            });
        });
    }

    public currentTabIdChanged(): void {
        if (!this.isCompletedInspectionsLoaded) {
            this.inspectionService.GetInspections(true, null).then((result: InspectionHeaderModel[]): any => {
                result.forEach((item: InspectionHeaderModel) => {
                    this.closedInspectionsCards.push(this.createCard(item, true));
                });
            });
            this.isCompletedInspectionsLoaded = true;
        }
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (Number(event.detail.tabId) === this.currentTabId) {
            return;
        }
        this.currentTabId = Number(event.detail.tabId);
    }

    public add(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Inspection_Add.name);
    }

    public edit(item: InspectionHeaderModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Inspection_Info.name, { inspectionId: item.Id });
    }

    public delete(item: InspectionHeaderModel): void {
        const dialogMessage = `${this.i18n.tr("msg_DeleteEntryConfirmationText")} (${item.Id} - ${this.getInspectionDescriptionDisplay(item)})`;

        notificationHelper.showDialogYesNo(dialogMessage).then((success: boolean): any => {
            if (success) {
                this.inspectionService.DeleteInspection(item.Id).then((result: boolean): any => {
                    if (result) {
                        this.removeInspectionFromList(item.Id);
                        notificationHelper.showSuccess(this.i18n.tr("Procore.Status_Success"));
                    }
                });
            }
        });
    }

    public createCard(item: InspectionHeaderModel, isClosed: boolean): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            id: "card_" + item.Id,
            displayLines: [
                new MaCardLineModel({ display: this.getInspectionDescriptionDisplay(item), class: "text-primary font-weight-bold" }),
                new MaCardLineModel({ display: this.getInspectionContextDisplay(item)}),
                new MaCardLineModel({ display: this.getInspectionCounterDisplay(item) }),
                new MaCardLineModel({ display: this.getInspectionDateDisplay(item), icon: "fa fa-plus text-success pr-1" }),
                new MaCardLineModel({ display: this.getInspectionStatusDisplay(item), class: "font-weight-bold" }),
            ],
            actionItems: [new MaCardActionModel({ id: "edit", icon: "fa fa-chevron-right", action: this.edit.bind(this, item)})],
        });

        if (!isClosed) {
            card.actionItems!.push(new MaCardActionModel({id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item) }));
        }
        return card;
    }

    public getInspectionDescriptionDisplay(item: InspectionHeaderModel): string {
        return item.InspectionTypeDescription!;
    }

    public getInspectionContextDisplay(item: InspectionHeaderModel): string {
        const display = item.InspectionContext === 1 ? this.i18n.tr("Projet") : this.i18n.tr("Equipment");
        return `${display}: ${item.ContextId!} - ${item.ContextDescription}`;
    }

    public getInspectionTypeDisplay(item: InspectionHeaderModel): string {
        return item.InspectionTypeDescription!;
    }

    public getInspectionCounterDisplay(item: InspectionHeaderModel): string {
        return `${this.i18n.tr("Counter")}: ${item.Id}`;
    }

    public getInspectionDateDisplay(item: InspectionHeaderModel): string {
        return `${this.i18n.tr("Added")} ${dateHelper.formatDate("DD MMM YYYY", item.InspectionDate!)}`;
    }

    public getInspectionStatusDisplay(item: InspectionHeaderModel): string {
        return item.Status !== InspectionStatus.Closed && item.Status !== InspectionStatus.Complete ? this.i18n.tr("InProgress") : this.i18n.tr("Completed");
    }

    private removeInspectionFromList(id: number): void {
        this.onGoingInspectionsCards = this.onGoingInspectionsCards.filter((item: MaCardOptionsModel) => {
            return id !== item.model.Id;
        });
    }
}
