import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";
import documentService from "services/documentService";
import { SettingRepository } from "repositories/setting-repository";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class FieldServiceRecommendationEditDocumentAdd extends FormBase {
    public workOrderId: string = "";
    public editId: number = 0;
    public readonly: boolean = false;
    public actions: any;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly settingRepository: SettingRepository
    ) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public activate(params: any): any {
        const paramsQs = routerHelper.getQuerystring(params.q);
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        this.editId = params.recommendationId;
        this.workOrderId = decodeURIComponent(params.workOrderId);

        const dispatchTemplateId = this.settingRepository.getDispatchTemplate();

        this.actions = {
            save: documentService.saveRecommendationPicture.bind(this, params.recommendationId, "workorder", params.workOrderId, dispatchTemplateId)
        };
    }
}
