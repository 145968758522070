// GENERATED FILE - DO NOT MODIFY
import { ProjectActivityModel } from "api/models/company/project/project-activity-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProjectActivityProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetProjectActivity(projectCode: string | null, activityCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ProjectActivityModel | null> {
        const uri = this.buildUri(
            "/projects/{projectCode}/activities/{activityCode}",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode, activityCode: (activityCode === null || activityCode === undefined) ? undefined : activityCode },
            null
        );

        const data: ProjectActivityModel | null = await this.get<ProjectActivityModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectActivityModel");
        return data;
    }

    public async GetProjectActivities(projectCode: string | null, activityType: Array<any | null> | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectActivityModel[] | null> {
        const uri = this.buildUri(
            "/projects/{projectCode}/activities",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            { activityType: (activityType === null || activityType === undefined) ? undefined : activityType, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectActivityModel[] | null = await this.get<ProjectActivityModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectActivityModel");
        return data;
    }

    public async GetActivity(activityCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ProjectActivityModel | null> {
        const uri = this.buildUri(
            "/projects/activity/{activityCode}",
            { activityCode: (activityCode === null || activityCode === undefined) ? undefined : activityCode },
            null
        );

        const data: ProjectActivityModel | null = await this.get<ProjectActivityModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectActivityModel");
        return data;
    }
}
