import { autoinject } from "aurelia-framework";
import {  I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import services from "services/serviceService";
import { ServiceCallQuotationHistoryService } from "services/service-call-quotation-history-service";
import dateHelper from "helpers/dateHelper";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import Parse from "helpers/parse";

@autoinject
export class EquipmentDetails {
  public allowedServiceCallContractEquipmentManagement: boolean = false;
  public readonly: boolean = true;
  private dispatchId: any;

  private equipment: ServiceCallContractEquipmentModel | null = null;
  private historyList: any[] = [];

  private presence: any;
  private entityId: any;
  private serviceCallId: number = 0;

  private i18n: I18N;
  private serviceCallQuotationHistoryService: ServiceCallQuotationHistoryService;
  private serviceType: string = "";

  constructor(i18n: I18N, serviceCallQuotationHistoryService: ServiceCallQuotationHistoryService) {
    this.i18n = i18n;
    this.serviceCallQuotationHistoryService = serviceCallQuotationHistoryService;
  }

  public async activate(params: any): Promise < void > {
    const queryResults = routerHelper.getQuerystring(params.q);

    this.dispatchId = params.dispatchId;
    this.presence = queryResults.presence;
    this.entityId = queryResults.entityId;
    this.serviceType = params.serviceType;
    this.serviceCallId = params.serviceCallId;
    this.allowedServiceCallContractEquipmentManagement = Parse.Boolean(queryResults.allowedServiceCallContractEquipmentManagement);
    this.readonly = queryResults.readonly;
    await this.LoadEquipment(params.serviceType, params.equipmentId);
  }

  private async LoadEquipment(serviceType: any, equipmentId: number): Promise < void > {
    this.equipment = await services.getEquipmentDetails(serviceType, this.dispatchId, equipmentId);
  }
}
