import { autoinject, bindable, transient } from "aurelia-framework";

import { EventAggregator } from "aurelia-event-aggregator";

import { DialogController } from "aurelia-dialog";
import { I18N } from "aurelia-i18n";
import _ from "underscore";

@transient()
@autoinject
export default class MessageBox {

    public popupTitle?: string;
    public popupMessage: string = "";
    public yesLabel: string = this.i18n.tr("OK");
    public noLabel: string = this.i18n.tr("Cancel");
    public cancelLabel?: string;
    public otherLabel?: string;
    public singleButton: boolean = false;
    public isErrorBox: boolean = false;
    public thirdButton: boolean = false;
    public labelClass?: string;
    public larger: boolean = false;

    constructor(private readonly i18n: I18N, private readonly dialogController: DialogController, private readonly eventAggregator: EventAggregator) {
        this.dialogController.settings.lock = false;

        this.popupMessage = "";
        this.yesLabel = "";
        this.noLabel = "";
        this.cancelLabel = "";
        this.otherLabel = "";
        this.singleButton = false;
        this.isErrorBox = false;
        this.thirdButton = false;
        this.labelClass = "";
        this.larger = false;
    }

    public activate(options: any): any {
        this.popupMessage = options.message || "";
        this.popupTitle = options.title || null;
        this.yesLabel = options.yesLabel || this.i18n.tr("OK");
        this.noLabel = options.noLabel || this.i18n.tr("Cancel");
        this.singleButton = options.singleButton || false;
        this.isErrorBox = options.isErrorBox || false;
        this.otherLabel = options.otherLabel;
        this.thirdButton = options.thirdButton || false;
        this.labelClass = options.labelClass;
        this.larger = false;

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);
    }

    public noAction(): void {
        this.dialogController.cancel(false);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    public yesAction(): void {
        this.dialogController.ok(true);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    public otherAction(): void {
        this.dialogController.ok("other");
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}
