export class ZeroToNullValueConverter {
    public fromView(value: any): any {
        if (value === null) {
            return 0;
        }

        return value;
    }

    public toView(value: any): any | null {
        if (value === 0) {
            return null;
        }

        return value;
    }
}
