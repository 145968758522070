import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import { DailyEntryWorkTaskService } from "services/daily-entry-work-task-service";
import RouteRepository from "repositories/routeRepository";
import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import { RouteInfo } from "components/others/ma-work-task-index";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

@autoinject()
export class DailyEntryWorkTaskEdit extends FormBase {

    public newWorkTask!: () => Promise<WorkOrderWorkTaskDetailsModel>;
    public documentRouteInfo: RouteInfo = { routeName: "", routeParams: {} };
    public dispatchDate: any;
    public dispatchProjectCode: string = "";
    public readonly: boolean = false;
    public transactionNumber: number = 0;

    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly routeRepository: RouteRepository, private readonly dailyEntryWorkTaskService: DailyEntryWorkTaskService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<void> {

        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.transactionNumber = params.transactionNumber ? params.transactionNumber : 0;

        const paramsQs = routerHelper.getQuerystring(params.q);

        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }
   
        this.newWorkTask = (await this.dailyEntryWorkTaskService.GetNewWorkTask.bind(this.dailyEntryWorkTaskService, this.dispatchProjectCode, this.dispatchDate));
        this.documentRouteInfo.routeName = this.routeRepository.routes.Project_Detail_Daily_WorkTask_Documents_Add.name;
        this.documentRouteInfo.routeParams = params;
    }
}
