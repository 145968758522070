import { InspectionDocumentProxy } from "api/proxies/inspection-document-proxy";
import { InspectionProxy } from "api/proxies/inspection-proxy";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";

import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { DocumentModel } from "api/models/company/document/document-model";
import { InspectionHeaderModel } from "api/models/company/inspection/inspection-header-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";

@autoinject()
export class InspectionService {
    constructor(private readonly inspectionProxy: InspectionProxy, private readonly inspectionDocumentProxy: InspectionDocumentProxy) {}

    public async GetInspection(inspectionId: number, requestConfig?: HttpClientRequestConfig): Promise<InspectionModel | null> {
        return await this.inspectionProxy.GetInspection(inspectionId, requestConfig);
    }

    public async DeleteInspection(inspectionId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return await this.inspectionProxy.DeleteInspection(inspectionId, requestConfig);
    }

    public async AddDocumentForInspection(inspectionId: number, model: Array<DocumentModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return await this.inspectionDocumentProxy.AddDocumentForInspection(inspectionId, model, requestConfig);
    }

    public async GetDocumentsForInspections(
        inspectionId: number,
        sectionId: string,
        pagingInfo?: PagingInfo,
        requestConfig?: HttpClientRequestConfig
    ): Promise<DocumentSectionModel | null> {
        return await this.inspectionDocumentProxy.GetDocumentsForInspection(inspectionId, sectionId, requestConfig);
    }

    public async GetSectionsForInspection(inspectionId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        return await this.inspectionDocumentProxy.GetSectionsForInspection(inspectionId, filter, pagingInfo, requestConfig);
    }

    public async GetInspections(onlyClosed: boolean, dispatchId: number | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InspectionHeaderModel[] | null> {
        return await this.inspectionProxy.GetInspections(onlyClosed, dispatchId, filter, pagingInfo, requestConfig);
    }

    public async SaveInspection(inspection: InspectionModel | null, requestConfig?: HttpClientRequestConfig): Promise<InspectionModel | null> {
        return await this.inspectionProxy.SaveInspection(inspection, requestConfig);
    }

    public async GetNewInspection(inspectionContext: number, dispatchId: number | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<InspectionModel | null> {
        return await this.inspectionProxy.GetNewInspection(inspectionContext, dispatchId, requestConfig);
    }

    public async Complete(inspectionId: number, dispatchIdOrVisitNo: number | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return await this.inspectionProxy.Complete(inspectionId, dispatchIdOrVisitNo, requestConfig);
    }
}
