define([
    "jquery",
    "services/requests",
    "helpers/apiHelper"
], function (jQuery, requests, apiHelper) {
    "use strict";

    var exports = {
        mapWindForceValue: function (value) {
            if (isNaN(value)) {
                return 0;
            }

            value = +value;
            if (value > 0 && value <= 1) {
                return 0;
            }
            if (value > 1 && value <= 20) {
                return 1;
            }
            if (value > 20 && value <= 50) {
                return 2;
            }
            if (value > 50 && value <= 75) {
                return 3;
            }
            if (value > 75) {
                return 4;
            }
            return 0;
        },

        mapWeatherTypeValue: function (value) {
            //Excel spreadsheet Env. Canada PBI 54211
            //0	Sunny
            //1	Mainly Sunny
            //2	Partly Cloudy
            //3	Mostly Cloudy
            //6	Light Rain Shower
            //7	Light Rain Shower and Flurries
            //10	Cloudy
            //11	Precipitation
            //12	Rain
            //13	Heavy Rain
            //14	Freezing Rain
            //15	Rain and Flurries
            //16	Snow
            //17	Flurries
            //18	Heavy Snow
            //19	Thunderstorm
            //24	Fog
            //27	Hail
            //28	Drizzle
            //30	Clear
            //31	Mainly Clear
            //32	Partly Cloudy
            //33	Mostly Cloudy
            //36	Light Rain Shower
            //37	Light Rain Shower and Flurries
            //38	Light Flurries
            //39	Thunderstorm
            //42	Tornado
            //43	Windy
            //44	Smoke
            //46	Thunderstorm with Hail
            //47	Thunderstorm with Dust Storm
            
            if (value.includes("00.gif") || value.includes("01.gif") || value.includes("30.gif") || value.includes("31.gif")) {
                return 1;
            }
            if (value.includes("02.gif") || value.includes("03.gif") || value.includes("10.gif") || value.includes("24.gif") || value.includes("32.gif") || value.includes("33.gif") || value.includes("43.gif") || value.includes("44.gif")) {
                return 2;
            }
            if (value.includes("06.gif") || value.includes("07.gif") || value.includes("11.gif") || value.includes("12.gif") || value.includes("13.gif") || value.includes("14.gif") || value.includes("15.gif") || value.includes("27.gif") || value.includes("36.gif") || value.includes("37.gif")) {
                return 3;
            }
            if (value.includes("19.gif") || value.includes("39.gif") || value.includes("42.gif") || value.includes("46.gif") || value.includes("47.gif")) {
                return 4;
            }
            if (value.includes("16.gif") || value.includes("17.gif") || value.includes("18.gif") || value.includes("28.gif") || value.includes("38.gif")) {
                return 5;
            }
            return 0;
        },

        getCurrentTemperature: function (position) {
            var self = this;
            var dfd = new jQuery.Deferred();
            requests.getJson(apiHelper.getExternalWeatherApiUrl(position)).done(function (data) {
                dfd.resolve({
                    weatherType: self.mapWeatherTypeValue(data.features[0].properties.icon),
                    windForce: self.mapWindForceValue(data.features[0].properties.speed),
                    degree: Math.round(data.features[0].properties.temp)
                });
            });
            return dfd;
        }

    };

    return exports;
});
