import { observable } from "aurelia-binding";
import { EquipmentHistoryItem } from "../models/equipment-history-item";

export class HistorySearch {
  @observable({ changeHandler: "setResultCount" })
  public displayedHistoryList: EquipmentHistoryItem[] = [];
  public resultsCount: number = 0;

  private historyList: EquipmentHistoryItem[] = [];

  constructor() {
    this.displayedHistoryList = this.historyList;
  }

  public setHistoryList(list: EquipmentHistoryItem[]): void {
    this.historyList = list;
    this.displayedHistoryList = list;
  }

  public search(text: string): void {
    this.displayedHistoryList = this.historyList.map((item: any) => {
      const newItem = new EquipmentHistoryItem();

      newItem.Title = item.Title;
      newItem.Date = item.Date;
      newItem.Description = item.Description;
      newItem.Type = item.Type;

      newItem.Displayed = this.search2(item.Title, text) ||
        this.search2(item.Date, text) ||
        this.search2(item.Description, text) ||
        this.search2(item.Description2, text);

      if (this.search2(item.Title, text)) {
        newItem.Title = item.Title.replace(text, `<span class="found">${text}</span>`);
      }
      if (this.search2(item.Date, text)) {
        newItem.Date = item.Date.replace(text, `<span class="found">${text}</span>`);
      }
      if (this.search2(item.Description, text)) {
        newItem.Description = item.Description.replace(text, `<span class="found">${text}</span>`);
      }
      if (this.search2(item.Description2, text)) {
        newItem.Description2 = item.Description2.replace(text, `<span class="found">${text}</span>`);
      }

      return newItem;
    });

    this.resultsCount = this.displayedHistoryList.filter((item: EquipmentHistoryItem) => item.Displayed).length;
  }

  private search2(propertyValue: string, inputValue: string): boolean {
    if (propertyValue && propertyValue.includes(inputValue)) {
      return true;
    }

    return false;
  }

  private setResultCount(): void {
    this.resultsCount = this.displayedHistoryList.filter((item: EquipmentHistoryItem) => item.Displayed).length;
  }
}
