import { I18N } from "aurelia-i18n";
import { autoinject, bindable, computedFrom, transient } from "aurelia-framework";

import GuidHelper from "helpers/guidHelper";
import weatherHelper from "helpers/weatherHelper";

@transient()
@autoinject
export class WeatherPicker {

    public temperatureUnit: string = "";

    @bindable public id: string = this.guidHelper.createGuid();
    @bindable public value: any;
    @bindable public title: string = "";
    @bindable public readonly: boolean = false;
    @bindable public valueToReload: any;

    @computedFrom("value.weatherType")
    public get selectedWeatherIcon(): string {
        return this.weatherTypeIcon();
    }
    @computedFrom("value.windForce")
    public get selectedWindIcon(): string  {
        return this.windForceIcon();
    }

    constructor(private readonly guidHelper: GuidHelper, private readonly i18n: I18N) {
    }

    public async bind(): Promise<any> {
        this.temperatureUnit = await weatherHelper.getUserTemperatureUnit();
        this.value.degree = await weatherHelper.toUserUnitFromCelsius(this.value.degree);
        this.title = this.i18n.tr(this.title);
    }

    public weatherTypeIcon(): string {
        switch (this.value.weatherType) {
            case 1:
                return "wi-day-sunny";
            case 2:
                return "wi-cloud";
            case 3:
                return "wi-rain";
            case 4:
                return "wi-storm-showers";
            case 5:
                return "wi-snow";
            default:
                return "";
        }
    }

    public windForceIcon(): string {
        return `wind-${this.value.windForce - 1}`;
    }

    public setWeatherType(type: number): void {
        this.value.weatherType = type;
    }
    public setWindForce(force: number): void {
        this.value.windForce = force;
    }

    public clearGrid(): void {
        this.value.weatherType = 0;
        this.value.windForce = 0;
        this.value.degree = 0;
    }

    //#endregion
}
