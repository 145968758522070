/****************************************************************************************
 * IMPORTANT - KNOWN ISSUE
 * In one run (no refresh), if a dispatch is accessed from the mobile site,
 * then deleted in maestro* and a new dispatch is added to the same person,
 * the header won't be refreshed if you access the new dispatch since they have the 
 * same dispatchId.
 * 
 * This is cause by maestro* which attributes the first available number as dispatchId
 * instead of using an auto-increment identity field.
 ****************************************************************************************/
import { bindable, singleton } from "aurelia-framework";

import { default as dispatchService } from "services/dispatchService";
import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";

export class ServiceHeader {
    title = "";
    subtitle = "";

    @bindable dispatchId;

    bind() {
        this.initTitleAndSubtitle();
    }

    initTitleAndSubtitle() {
        if (this.dispatchId === "0") {
            return;
        } 

        return dispatchService.getHeader(this.dispatchId)
            .then((data) => {
                this.setDisplayValues(data.AssignedDate, data.ServiceType, data.ServiceCallType, data.Id);
            });
    }

    setDisplayValues(assignedDate, serviceType, serviceCallType, id) {
        this.title = assignedDate ? dateHelper.getFullTextDate(assignedDate) : "";
        this.subtitle = (serviceType === "S" ? labelHelper.getCallTypeLabel(serviceCallType) : labelHelper.getLabel(serviceType)) + " - " + id;
    }
}
