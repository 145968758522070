define([
    "services/defaultService",
    "services/templateService",
    "repositories/settingRepository",
    "helpers/enumHelper"
], function (defaultService, templateService, settingRepository, enumHelper) {
    "use strict";

    var exports = {

         getDispatchTemplateConfigs: function(section, dispatchCode) {
             var dfd = new jQuery.Deferred();

             //Dans le cas d'un projet/EJ : utiliser dispatchTemplateCode si disponible sinon celui de du contre maitre sinon celui du mobile
             //Services: utiliser celui de la repartition courante sinon celui du mobile
             //utilise toujours celui du mobile
            var templateCode = section === "Project"
                ? (dispatchCode ? dispatchCode : defaultService.getCurrentDispatchTemplateCode() ? defaultService.getCurrentDispatchTemplateCode() : settingRepository.getDispatchTemplate())
                : section === "Services"
                    ? (defaultService.getCurrentDispatchTemplateCode() ? defaultService.getCurrentDispatchTemplateCode() : settingRepository.getDispatchTemplate())
                    : section === "FieldService"
                        ? settingRepository.getDispatchTemplate() : settingRepository.getDispatchTemplate() ;

             if (templateCode) {
                 templateService.getByCompanyAndCode(templateCode).done(function(data) {
                     dfd.resolve(data);
                 });
             } else {
                 dfd.resolve(templateService.getUserTemplateConfigs());
             }
             return dfd;
        },

        getWageFromTemplateconfigs: function (section, data) {

            var overtimeProject = data.AllowOvertimeInProject || false;
            var doubleTimeProject = data.AllowDoubleTimeInProject || false;
            var overtimeServices = data.AllowOvertimeInService || false;
            var doubletimeServices = data.AllowDoubleTimeInService || false;
            var overtimeFieldService = data.AllowOvertimeInFieldServices ||false;
            var doubletimeFieldService = data.AllowDoubleTimeInFieldServices ||false;

            var overtime = section === "Project"
                ? overtimeProject
                : section === "Services"
                ? overtimeServices
                : section === "FieldService"
                ? overtimeFieldService
                : true;

            var doubletime = section === "Project"
                ? doubleTimeProject
                : section === "Services"
                ? doubletimeServices
                : section === "FieldService"
                ? doubletimeFieldService
                : true;

            return this.getWage(overtime, doubletime);
        },

        getWage: function (overtime, doubletime) {
            var wage = (!overtime && !doubletime)
                ? enumHelper.wageTypeCombination().SIMPLE
                : (overtime && !doubletime)
                    ? enumHelper.wageTypeCombination().SIMPLEOVER
                : (!overtime && doubletime)
                        ? enumHelper.wageTypeCombination().SIMPLEDOUBLE
                : (overtime && doubletime)
                            ? enumHelper.wageTypeCombination().SIMPLEOVERDOUBLE : enumHelper.wageTypeCombination().SIMPLE;

            return wage;

        }
    };

    return exports;
});