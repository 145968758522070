import { nameof as nameof_ServiceCallQuotationItemType, ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { nameof as nameof_WageTypeString, WageTypeString } from "api/enums/wage-type-string";
import { customElement, autoinject, transient, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { GridBase } from "components/ag-grid/grids/grid-base";
import { EnumFormatWithIdValueConverter } from "converters/enums/enum-format-with-id";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
export class QuotationItemsGrid extends GridBase {
   
    @bindable
    public hideCostPrices: boolean = false;
    @bindable
    public hideSellingPrices: boolean = false;
    @bindable
    public readonly: boolean = false;
    @bindable
    public disableDeleteButton: boolean = true;

    public readonly ServiceCallQuotationItemType: typeof ServiceCallQuotationItemType = ServiceCallQuotationItemType;

    public itemTypes: any[] = Object.values(ServiceCallQuotationItemType).filter((x: any) => typeof x === "number");
    public readonly nameof_ServiceCallQuotationItemType: string = nameof_ServiceCallQuotationItemType;
    public readonly nameof_WageTypeString: string = nameof_WageTypeString;
    private enumFormatWithIdValueConverter: EnumFormatWithIdValueConverter;
    private enumFormatValueConverter: EnumFormatValueConverter;

    constructor(element: Element, i18n: I18N, userGridStateService: UserGridStateService, eventAggregator: EventAggregator, enumFormatWithIdValueConverter: EnumFormatWithIdValueConverter, enumFormatValueConverter: EnumFormatValueConverter) {
        super(element, i18n, userGridStateService, eventAggregator);

        this.enumFormatWithIdValueConverter = enumFormatWithIdValueConverter;
        this.enumFormatValueConverter = enumFormatValueConverter;

        this.showRightPanel = true;
    }

    public onAddClicked(type: ServiceCallQuotationItemType): void {
        CustomEventHelper.dispatchEvent(this.element, "add-clicked", { grid: this, itemType: type }, true, false);
    }

    public onDeleteClicked(): void {
        CustomEventHelper.dispatchEvent(this.element, "delete-clicked", { grid: this }, true, false);
    }

    public itemTypeFormatter: (params: any) => any = (params: any): any => {
        return this.enumFormatValueConverter.toView(params.value, this.nameof_ServiceCallQuotationItemType);
    }

    public rateTypeFormatter: (params: any) => any = (params: any): any => {
        return this.enumFormatWithIdValueConverter.toView(params.value, this.nameof_WageTypeString);
    }

    public activityFormatter: (params: any) => any = (params: any) => {
        if (!params.data || !params.data.ActivityId) {
            return null;
        }

        let result = params.data.ActivityId;
        if (params.data.ActivityDescription) {
            result += " - " + params.data.ActivityDescription;
        }

        return result;
    }

    public workCategoryFormatter: (params: any) => any = (params: any) => {
        if (!params.data || !params.data.OccupationCategoryId) {
            return null;
        }

        let result = params.data.OccupationCategoryId;
        if (params.data.OccupationCategoryDescription) {
            result += " - " + params.data.OccupationCategoryDescription;
        }

        return result;
    }

    public billingGetter: (params: any) => any = (params: any) => {
        if (!params.data || !params.data.Type) {
            return null;
        }

        let result = "";
        if (params.data.Type === ServiceCallQuotationItemType.BillingItem) {
            result = params.data.CatalogId;
        }

        return result;
    }

    public materialGetter: (params: any) => any = (params: any) => {
        if (!params.data || !params.data.Type) {
            return null;
        }

        let result = "";
        if (params.data.Type === ServiceCallQuotationItemType.Material) {
            result = params.data.CatalogId;
            if (params.data.OutCatalogCode !== "") {
                result = params.data.CatalogId + params.data.OutCatalogCode;
            }
        }

        return result;
    }

    public profitMargingGetter: (params: any) => any = (params: any) => {
        if (!params.data || !params.data.ProfitMargin) {
            return null;
        }

        return params.data.ProfitMargin.toPrecision(6);
    }

}
