// GENERATED FILE - DO NOT MODIFY
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DispatchTemplateProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetDispatchTemplates(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DispatchTemplateModel[] | null> {
        const uri = this.buildUri(
            "/templates",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DispatchTemplateModel[] | null = await this.get<DispatchTemplateModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Template.DispatchTemplateModel");
        return data;
    }

    public async GetDispatchTemplatesByCode(code: string | null, requestConfig?: HttpClientRequestConfig): Promise<DispatchTemplateModel | null> {
        const uri = this.buildUri(
            "/template/{code}",
            { code: (code === null || code === undefined) ? undefined : code },
            null
        );

        const data: DispatchTemplateModel | null = await this.get<DispatchTemplateModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Template.DispatchTemplateModel");
        return data;
    }

    public async GetDispatchTemplatesByDispatchId(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<DispatchTemplateModel | null> {
        const uri = this.buildUri(
            "/template/by-dispatchId/{dispatchId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: DispatchTemplateModel | null = await this.get<DispatchTemplateModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Template.DispatchTemplateModel");
        return data;
    }
}
