import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { WorkOrderQuotationHistoryProxy } from "api/proxies/work-order-quotation-history-proxy";
import { ServiceCallQuotationHistoryModel } from "api/models/company/service-call/service-call-quotation-history-model";

@autoinject()
export class WorkOrderQuotationHistoryService {
    constructor(
        private readonly workOrderQuotationHistoryProxy: WorkOrderQuotationHistoryProxy
    ) {
    }

    public async getHistory(dispatchId: number, filter: string | null, equipmentId: number | null | undefined, pagingInfo?: PagingInfo): Promise<ServiceCallQuotationHistoryModel[]> {
        return await this.workOrderQuotationHistoryProxy.GetWorkOrderHistory(dispatchId, filter, pagingInfo) || [];
    }
}
