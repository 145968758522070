import cloneDeep from "lodash.clonedeep";

export class CloneHelper {
    public static shallowClone<T>(value: T): T {
        if (value === null || value === undefined) { return value; }

        if (typeof value !== "object") {
            throw new Error("Value must be an object.");
        }

        return Object.assign({}, value);
    }

    public static deepClone<T>(value: T): T {
        if (value === null || value === undefined) { return value; }

        if (typeof value !== "object") {
            throw new Error("Value must be an object.");
        }

        return cloneDeep(value);
    }
}
