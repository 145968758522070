import { bindable } from "aurelia-typed-observable-plugin";
import { ExpandableButtonOptions } from "./expandable-button-options";
import { observable } from "aurelia-binding";

export class ExpandableButton {

  @bindable
  public options?: ExpandableButtonOptions;

  @bindable.boolean
  @observable
  public disabled: boolean = false;

  public wrapper?: HTMLElement;
  public menu?: HTMLElement;
  public mainButton?: HTMLElement;

  public bind(): void {
    document.addEventListener("click", (event: any) => {
      if (!this.options!.subButtons) { return; }

      if (!this.menu || !this.mainButton) { return; }

      const isClickInside = this.menu!.contains(event.target) ||
                            this.mainButton!.contains(event.target);

      if (!isClickInside) {
        this.wrapper!.classList.remove("opened");
        this.setClosedMenuPosition();
      }
    });

    window.addEventListener("resize", () => { this.setClosedMenuPosition();  this.setHeight(); } );
    window.addEventListener("scroll", () => { this.setHeight(); } );

    this.setClosedMenuPosition();
    this.disabledChanged();
   }

  public toggle(): void {
    if (this.disabled) { return; }
    this.wrapper!.classList.toggle("opened");
    this.updateMenuPosition();
  }

  public buttonClick(button: any): void {
    if (!button.isCustomTemplate) {
      button.action();
    }

    this.setClosedMenuPosition(0);
  }

  private updateMenuPosition(): void {
    if (!this.menu) { return; }

    if (!this.wrapper!.classList.contains("opened")) {
        this.setClosedMenuPosition();
    } else {
        this.setOpenedMenuPosition();
    }
  }

  private setClosedMenuPosition(timer: number = 250): void {
    if (!this.menu) { return; }

    this.menu.style.left = `${document.body.clientWidth}px`;
    this.wrapper!.classList.remove("opened");

    setTimeout(() => {
      if (!this.menu) { return; }
      this.mainButton!.style.zIndex = "";
    }, timer);
  }

  private setHeight(): void {
    if (!this.menu) { return; }

    const buttonTopPosition = this.mainButton!.getBoundingClientRect().top;
    this.menu.style.top = buttonTopPosition + "px";

    const menuHeight = this.menu.offsetHeight;
    const screenHeight = window.screen.height;

    if (menuHeight + buttonTopPosition > screenHeight) {
        this.menu.style.top = (screenHeight - menuHeight) + "px";
    }
  }

  private setOpenedMenuPosition(): void {
    const windowWidth = document.documentElement.clientWidth;
    const buttonWidth = this.mainButton!.clientWidth;
    const padding = 115;
    const largestElementWidth = this.getLargestListItemWidth();

    const calculatedWidth = windowWidth - (largestElementWidth + buttonWidth + padding);

    if (!this.menu) { return; }
    this.menu!.style.left = `${ calculatedWidth + 20 }px`;
    this.menu!.style.padding = "1em";
    this.menu!.style.paddingRight = "15em";
    this.menu!.style.width = `${ windowWidth - calculatedWidth - 15}px`;

    if (!this.mainButton) { return; }
    this.mainButton!.style.zIndex = "100";
  }

  private getLargestListItemWidth(): number {
    let largestElementWidth = 0;
    const elements = this.menu!.querySelectorAll("li > span");

    for (let i = 0; !!elements[i]; i++) {
      const elementWidth = elements[i].clientWidth;

      if (elementWidth > largestElementWidth) {
        largestElementWidth = elementWidth;
      }
  }

    return largestElementWidth;
  }

  private disabledChanged(): void {
    this.wrapper!.classList.toggle("disabled", this.disabled);
  }
}
