import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { ServiceCallQuotationHistoryProxy } from "api/proxies/service-call-quotation-history-proxy";
import { ServiceCallQuotationHistoryModel } from "api/models/company/service-call/service-call-quotation-history-model";

@autoinject()
export class ServiceCallQuotationHistoryService {
    constructor(
        private readonly serviceCallQuotationHistoryProxy: ServiceCallQuotationHistoryProxy
    ) {
    }

    public async getHistory(dispatchId: number, filter: string | null, equipmentId: number | null | undefined, pagingInfo?: PagingInfo): Promise<ServiceCallQuotationHistoryModel[]> {
        return await this.serviceCallQuotationHistoryProxy.GetHistory(dispatchId, filter, equipmentId, pagingInfo) || [];
    }
}
