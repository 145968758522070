import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { Viewbag } from "helpers/view-bag";
import contactService from "services/contactService";
import queryStringHelper from "helpers/queryStringHelper";
import dailyEntryService from "services/dailyEntryService";
import _ from "underscore";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";

@autoinject
export class DailyEntrySummary {

    public dateHelper: typeof dateHelper = dateHelper;
    public labelHelper: typeof labelHelper = labelHelper;
    public routerHelper: typeof routerHelper = routerHelper;
    public notificationHelper: typeof notificationHelper = notificationHelper;
    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public dispatchId: number = 0;
    public resources: any[] = [];
    public resourcesEmploye: any[] = [];
    public resourcesEquipment: any[] = [];
    public material: any[] = [];
    public totalTime: number = 0;
    public totalEmployeeTime: number = 0;
    public totalEquipmentTime: number = 0;
    public contactNameAvailable: boolean = false;
    public additionalFields: any[] = [];
    public signatureAvailable: boolean = false;
    public readonly: boolean = true;
    public saveAdditionalFieldsButton: boolean = false;
    public inputBag: any;
    public isViewbagValid: boolean = false;

    public contact: string = "";
    public email: string = "";
    public isProjectResponsible: boolean = false;
    public signatureContactName: any;
    public signatureContact: any;
    public workDescription: any;
    public actions: any;

    constructor(private readonly i18n: I18N, public viewbag: Viewbag) {

    }

    public async activate(params: any): Promise<void> {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        await this.loadData(params.dispatchProjectCode, params.dailyEntryDate);
        this.actions = {
            getAll: this.getAdditionalFields.bind(this)
        };
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: any, querystring: string): void {
        this.clear();
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
        this.validateInputBag();
    }

    public async loadData(dispatchProjectCode: string, dispatchDate: string): Promise<void> {
        this.initialize(await dailyEntryService.getDailyEntrySummary(dispatchProjectCode, dispatchDate));
    }

    public initialize(dailySummary: any): void {
        this.signatureAvailable = dailySummary.HasSignature;
        this.signatureContactName = (this.signatureAvailable && dailySummary.SignatureContactName) ? dailySummary.SignatureContactName : "";
        this.signatureContact = dailySummary.SignatureContactName;
        this.totalTime = dailySummary.TotalTime;
        this.totalEmployeeTime = dailySummary.TotalEmployeeTime;
        this.totalEquipmentTime = dailySummary.TotalEquipmentTime;
        this.additionalFields = dailySummary.AdditionalFields;
        this.workDescription = dailySummary.Description.WorkDescription;
        this.initHours(dailySummary.Resources);
        this.initMaterials(dailySummary.Materials);
    }

    public initHours(resources: any): void {
        this.resourcesEmploye = [{
            title: this.i18n.tr("EmployeeHours"),
            resourceItems: _.filter(resources, (val: any) => val.EquipmentCode === null)
        }];
        this.resourcesEquipment = [{
            title: this.i18n.tr("EquipmentHours"),
            resourceItems: _.filter(resources, (val: any) => val.EquipmentCode !== null)
        }];
    }

    public initMaterials(material: any): void {
        const materialItemType = enumHelper.materialItemType();

        this.material = [
            {
                title: enumHelper.materialItemType().MATERIAL.label,
                items: _.filter(material, (val: any) => {
                    return val.ItemType === materialItemType.DEFAULT.id || val.ItemType === materialItemType.MATERIAL.id;
                })
            },
            { title: materialItemType.SALARY.label, items: _.where(material, { ItemType: materialItemType.SALARY.id }) },
            { title: materialItemType.SUBCONTRACTOR.label, items: _.where(material, { ItemType: materialItemType.SUBCONTRACTOR.id }) },
            { title: materialItemType.EQUIPMENT.label, items: _.where(material, { ItemType: materialItemType.EQUIPMENT.id }) },
            { title: materialItemType.MISC.label, items: _.where(material, { ItemType: materialItemType.MISC.id }) }
        ];
        this.material = _.filter(this.material, (val: any) => val.items.length > 0);
    }

    public async getAdditionalFields(): Promise<any> {
        return this.additionalFields;
    }

    public validateInputBag(): void {
        if (this.viewbag.value &&
            this.viewbag.value !== undefined &&
            this.viewbag.value.dispatchProjectCode !== undefined &&
            this.viewbag.value.dispatchProjectCode === this.dispatchProjectCode) {
            //valid
            this.inputBag = this.viewbag.value;
            this.isViewbagValid = true;
        } else {
            //invalid
            this.inputBag = {};
            this.isViewbagValid = false;
        }

        this.viewbag.value = undefined;
    }

    public  get loadContacts(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                contactService.getContactLookupForDailyEntrySummary(this.dispatchProjectCode, this.dispatchDate, params.data.filter, params.data.page || 1)
                    .then(success);
            },
            mapResults: (item: any): any => {
                return { id: item.No, text: item.Name + ", " + item.FirstName, data: item.Email };
            }
        };
    }

    public async onSignatureCompleted(event: any): Promise<void> {
        const signatureData = event.detail;

        if (!this.signatureContactName || this.signatureContactName.length === 0) {
            notificationHelper.showError(this.i18n.tr("err_SignatureRequired"), "", { timeOut: 0 });
        } else {
            this.inputBag.txtSignature = this.signatureContactName;
            this.inputBag.email = this.email ? this.email : "";

            this.inputBag.Signature = {
                Comment: this.signatureContactName.replace(" ", " "),
                Email: this.email ? this.email.replace(" ", " ") : "",
                FileData: signatureData
            };

            this.viewbag = this.inputBag;
            await dailyEntryService.setSignature(this.dispatchProjectCode, dateHelper.formatDateToSend(this.dispatchDate), this.inputBag.Signature);
            dailyEntryService.printform(this.dispatchProjectCode, dateHelper.formatDateToSend(this.dispatchDate), this.inputBag.email);

            routerHelper.navigateBack();
        }
    }

    public clear(): void {
        this.signatureContactName = "";
        this.contact = "";
        this.email = "";
    }
}
