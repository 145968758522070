
import { customElement, autoinject, transient, containerless } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { SynchronizationGrid } from "../synchronization-grid";
import { UserGridStateService } from "services/user-grid-state-service";
import { data } from "jquery";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
@customElement("costs-synchronization-grid")
@containerless
export class CostsSynchronizationGrid extends SynchronizationGrid {

    constructor(element: Element, i18n: I18N, userGridStateService: UserGridStateService, eventAggregator: EventAggregator) {
        super(element, i18n, userGridStateService, eventAggregator);
    }

    public rowDetailsGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        return params.data.RowDetails[0];
    }

    public getRowDetailsValue(params: any): any {
        return ;
    }

}
