import { ServiceCallDispatchOrderProxy } from "api/proxies/service-call-dispatch-order-proxy";
import { autoinject } from "aurelia-framework";

@autoinject()
export class ServiceCallDispatchOrderService {
    constructor(private readonly serviceCallDispatchOrderProxy: ServiceCallDispatchOrderProxy) {
    }

    public async UpdateOrderNo(dispatchId: number, orderNo: string | null): Promise<void> {
        if (!orderNo) {
            orderNo = null;
        } else {
            orderNo = encodeURIComponent(orderNo);
        }
        return await this.serviceCallDispatchOrderProxy.UpdateOrderNo(dispatchId, orderNo);
    }
}
