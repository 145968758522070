
import { MaestroBiImportExport } from "api/enums/maestro-bi-import-export";
import { autoinject, observable } from "aurelia-framework";

import { I18N } from "aurelia-i18n";

import HostContext from "core/host-context";
import { MaestroBiService } from "services/maestro-bi-service";

@autoinject()
export class MaestroBiImport {

    public maestroBiImportExport: any = MaestroBiImportExport; // allow constant in html view
    public reportAlreadyExistsChoices: any[] = [];
    public message: string = "";
    public isHosted: boolean = false;
    public alertDisplay: string = "";
    public importChoices: any;
    @observable public dummyReportAlreadyExistsBind: any;

    private choicesToResolve: Map<string, any> = new Map();
    private fileToSend: any = null;
    private canImport: boolean = false;
    private isLoading: boolean = true;
    private isTryAgain: boolean = false;
    private currentCulture: string = "en"; // default English

    private alertPossibilities: any = {
        NothingSelected: "secondary",
        FileSelectedToBeSend: "primary",
        FileImportedWithError: "danger",
        FileImportedSuccessfully: "success",
    };

    constructor(
        private readonly i18n: I18N,
        private readonly hostContext: HostContext,
        private readonly maestroBiService: MaestroBiService) {
            this.isHosted = this.hostContext.isHosted();

            this.currentCulture = this.i18n.getLocale();
            this.message = this.i18n.tr("MaestroBI_Import_ChoseReport");
            this.alertDisplay = this.alertPossibilities.NothingSelected;
    }

    public bind(): void {
        this.reportAlreadyExistsChoices = [
            { id: "Overwrite", text: this.i18n.tr("MaestroBI_ImportChoice_Report_Exists_Overwrite") },
            { id: MaestroBiImportExport.ChoiceCreateNew, text: this.i18n.tr("MaestroBI_ImportChoice_Report_Exists_Create_New") }
        ];
        this.isLoading = false;
    }

    public fileAcquired(ev: any): void {
        this.isTryAgain = false;
        const files = ev.target.files;
        if (!!files && files.length > 0) {
            this.fileToSend =  files[0];

            if (this.validateCanImportFileChosen()) {
                this.message = this.i18n.tr("MaestroBI_Import_ByClickingOnButton");
                this.alertDisplay = this.alertPossibilities.FileSelectedToBeSend;
                this.getReportImportChoices();
            } else {
                this.importChoices = null;
                this.fileToSend = null;
                this.updateCanImport();
                this.message = this.i18n.tr("MaestroBI_Import_CanOnlyProprietaryFormat");
                this.alertDisplay = this.alertPossibilities.FileImportedWithError;
            }
        }
    }

    public getReportImportChoices(): void {

        if (this.fileToSend == null) {
            this.indicateANoComebackErrorHasOccured();
            return;
        }
        this.isLoading = true;
        this.importChoices = null;
        const reader = new FileReader();
        reader.readAsArrayBuffer(this.fileToSend);

        const fileName = this.fileToSend.name;
        reader.onload = async (): Promise<void> => {
            const data = reader.result;
            if (data instanceof ArrayBuffer) {
                this.choicesToResolve = new Map();
                this.importChoices = [];
                this.maestroBiService.GetImportChoices({
                    FileNameFr: fileName,
                    FileNameEn: fileName,
                    ZipFileContent: this.buff2hex(data),
                    ImportChoices: null
                })
                .then(
                    (result: any) => {
                        this.importChoices = result.Choices;
                        if (this.importChoices.find((choice: any) => choice.ChoiceType === MaestroBiImportExport.ChoiceTypeReportAlreadyExists)) {
                            this.choicesToResolve.set(MaestroBiImportExport.ChoiceTypeReportAlreadyExists, null);
                        }
                        this.updateCanImport();
                        this.isLoading = false;
                    },
                    (fail: any) => {
                        this.isLoading = false;
                        this.indicateANoComebackErrorHasOccured();
                    }
                );

            } else {
                this.isLoading = false;
                this.indicateANoComebackErrorHasOccured();
            }
        };

        reader.onerror = (): void =>  {
            this.isLoading = false;
            this.indicateANoComebackErrorHasOccured();
        };
    }

    public importReport(ev: any): void {
        if (this.fileToSend == null) {
            this.indicateANoComebackErrorHasOccured();
            return;
        }
        this.isLoading = true;
        const reader = new FileReader();
        reader.readAsArrayBuffer(this.fileToSend);

        const fileName = this.fileToSend.name;
        reader.onload = async (): Promise<void> => {
            const data = reader.result;
            if (data instanceof ArrayBuffer) {
                try {
                    const importChoicesConverted: any[] = [];
                    this.choicesToResolve.forEach((value: any, key: string) => {
                        importChoicesConverted.push({ChoiceType: key, Value: value});
                    });
                    const resultImportation =
                        await this.maestroBiService.ImportReportWithinZip({
                            FileNameFr: fileName,
                            FileNameEn: fileName,
                            ZipFileContent: this.buff2hex(data),
                            ImportChoices: importChoicesConverted
                        });

                    if (this.isImportationResultASuccess(resultImportation)) {
                        this.isLoading = false;
                        this.importChoices = null;
                        this.fileToSend = null;
                        this.isTryAgain = false;
                        this.updateCanImport();
                        this.message = this.i18n.tr("MaestroBI_ImportReport_Success", { reportName: fileName });
                        this.alertDisplay = this.alertPossibilities.FileImportedSuccessfully;

                        if (this.isHosted) {
                            this.hostContext.viewModel.notify("Refresh", null);
                        }

                    } else {
                        this.isLoading = false;
                        this.isTryAgain = true;
                        this.indicateAnErrorHasOccured();
                    }

                } catch {
                    this.isLoading = false;
                    this.indicateANoComebackErrorHasOccured();
                }

            } else {
                this.isLoading = false;
                this.indicateANoComebackErrorHasOccured();
            }
        };

        reader.onerror = (): void =>  {
            this.isLoading = false;
            this.indicateANoComebackErrorHasOccured();
        };
    }

    public updateCanImport(): void {
        this.canImport = !!this.fileToSend;
        this.choicesToResolve.forEach((value: any) => this.canImport = this.canImport && !!value);
    }

    public dummyReportAlreadyExistsBindChanged(newValue: any, oldValue: any): void {
        this.choicesToResolve.set(MaestroBiImportExport.ChoiceTypeReportAlreadyExists, newValue.id);
        this.updateCanImport();
    }

    private validateCanImportFileChosen(): boolean {
        return this.fileToSend.name.toLowerCase().endsWith(".mbi");
    }

    private isImportationResultASuccess(resultImportation: string | null): boolean {
        return resultImportation != null && resultImportation === "SUCCESS";
    }

    private indicateANoComebackErrorHasOccured(): void {
        this.importChoices = null;
        this.fileToSend = null;
        this.updateCanImport();
        this.indicateAnErrorHasOccured();
    }

    private indicateAnErrorHasOccured(): void {
        this.message = this.i18n.tr("MaestroBI_ImportReport_Failed");
        this.alertDisplay = this.alertPossibilities.FileImportedWithError;
    }

    private buff2hex(buffer: ArrayBuffer): string {
        return Array.prototype.map.call(new Uint8Array(buffer), (x: any): string => ("00" + x.toString(16)).slice(-2)).join("-");
    }
}
