import { bindingMode, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

@transient()
export class Drawer {
    @bindable.booleanAttr({ defaultBindingMode: bindingMode.twoWay }) public isOpened: boolean = false;
    @bindable.string public title: string = "";
    @bindable.string public subtitle: string = "";

    private toggle(): void {
        this.isOpened = !this.isOpened;
    }
}
