import { nameof as nameof_ShopDrawingStatus } from "api/enums/shop-drawing-status";
import { ShopDrawingModel } from "api/models/company/shop-drawing-model";
import { autoinject, bindable } from "aurelia-framework";

@autoinject()
export class ShopDrawingHeader {
    @bindable
    public drawing!: ShopDrawingModel;

    public nameof_ShopDrawingStatus: string = nameof_ShopDrawingStatus;
}
