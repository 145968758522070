import { autoinject } from "aurelia-framework";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";

import additionalFieldsService from "services/additionalFieldsService";
import routerHelper from "helpers/routerHelper";

import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";
import { AdditionalFieldType } from "api/enums/additional-field-type";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";

@autoinject()
export class ServiceMaterialOrderAdditonnalFields {
    public actions: any = {};
    public dispatchId: number = 0;
    public orderId: string = "";
    public readonly: boolean = true;
    public extension: string = "";
    public additionalFieldsSource: number = AdditionalFieldsSourceType.PurchaseOrder;
    public saveAdditionalFieldsButton: boolean = false;
    public currentFields: any = null;
    public unModifiedFields: any = null;

    constructor(private readonly eventAggregator: EventAggregator, private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router, private readonly additionalFieldMapper: AdditionalFieldMapper) {
    }

    public isDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.additionalFieldMapper.isNotEqual(this.unModifiedFields, this.currentFields);
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchId, params.orderId, params.q);
        this.bindWidget();
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    private bindViewModel(dispatchId: number, orderId: string, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");
        this.extension = params.extension;

        this.saveAdditionalFieldsButton = !this.readonly;
        this.dispatchId = dispatchId;
        this.orderId = orderId;
    }

    private bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForPurchaseOrder.bind(this, this.orderId, this.extension),
            setAll: additionalFieldsService.setAllForPurchaseOrder.bind(this, this.orderId, this.extension)
        };
    }
}
