import { autoinject, PLATFORM, bindable } from "aurelia-framework";
import { InventoryProjectTransferItemModel } from "api/models/company/inventory-project-transfer-item-model";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { I18N } from "aurelia-i18n";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
import { InventoryProjectTransferItemResponseModel } from "api/models/company/inventory-project-transfer-item-response-model";
import { InventoryTransferQtyValidation } from "api/enums/inventory-transfer-qty-validation";
import routerHelper from "helpers/routerHelper";
import { CatalogInventoryService } from "services/catalog-inventory-service";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { CatalogItemStatus } from "api/enums/catalog-item-status";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

enum ItemStatus {
  Warning,
  Error,
  Ok
}

@autoinject
export class EditDetailsItemScanner extends FormBase {
  public transferId!: number;

  public itemsResponses: any[] = [];
  public itemsCards: MaCardOptionsModel[] = [];
  public unModified: InventoryProjectTransferItemModel[] | null = null;

  public ItemStatus: any = {
    Warning: ItemStatus.Warning,
    Error: ItemStatus.Error,
    Ok: ItemStatus.Ok
  };

  @bindable public unitResultTemplate: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/inventoryTransfer_unit_result.html");

  private sourcePrefix: string = "";
  private siteId: string = "";
  private activityId: string = "";
  private groupId: string = "";

  constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly service: InventoryProjectTransferService, private readonly catalogInventoryService: CatalogInventoryService) {
    super(notificationHelper, i18n, router);
  }

  public checkDirty(): boolean {
    if (!this.unModified) {
        return false;
    }

    const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");
    const stringifyCurrent = JSON.stringify(this.extractItemsFromCards()).replace(/[^0-9A-Z]+/gi, "");

    return stringifyUnmodified !== stringifyCurrent;
  }

  public async activate(params: any): Promise<void> {
    this.sourcePrefix = params.sourcePrefix;
    this.siteId = params.siteId;
    this.activityId = params.activityId;
    this.groupId = params.groupId;

    this.transferId = params.transferId;
    this.unModified = this.extractItemsFromCards();
  }

    public extractItemsFromCards(): InventoryProjectTransferItemModel[] {
      if (this.itemsCards.length === 0) {
            return [];
      }
      return this.itemsCards.map((card: MaCardOptionsModel): InventoryProjectTransferItemModel => {
          const model = card.model as InventoryProjectTransferItemResponseModel;
          const item = model.Item!;
          return item;
      });
  }
    
  public async save(): Promise<void> {

    let hasErrors = false;

    const items = this.itemsCards.map((card: MaCardOptionsModel): InventoryProjectTransferItemModel => {
    const model = card.model as InventoryProjectTransferItemResponseModel;
    const item = model.Item!;

    if (!this.isValid(item) && model.QtyValidationMode === InventoryTransferQtyValidation.Block) {
       hasErrors = true;
    }

    return item;
    });

    if (hasErrors) {
      this.notificationHelper.showError(this.i18n.tr("InventoryTransfer_scanner_contains_error"), "", { timeOut: 0 });
      return;
    }

    await this.service.SaveTransferItems(items);

    this.unModified = null;

    routerHelper.navigateBack();
  }

  public async newScanDetected(scannedValue: any): Promise<any> {
    if (scannedValue) {
      this.addItem(scannedValue);
    }
  }

  public createCard(response: InventoryProjectTransferItemResponseModel): MaCardOptionsModel {
    const card = new MaCardOptionsModel({
        id: response.Item!.ProductCode,
        model: response,
        leftSectionIcon: this.getCardStatusIcon(response),
        leftSectionAction: this.displayWarnings.bind(this, response),
        displayLines: [
            new MaCardLineModel({ isCustomTemplate: true })
        ],
        actionItems: [
            new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger", action: this.deleteItemConfirmation.bind(this, response) }),
        ],
    });

    return card;
  }

  public getCardStatusIcon(item: InventoryProjectTransferItemResponseModel): string {
    const status = this.changeItemStatus(item);
    const baseIconClass = "inventory-transfer-status-icon";

    switch (status) {
        case ItemStatus.Warning: {
           return `${baseIconClass} warning`;
        }
        case ItemStatus.Error: {
            return `${baseIconClass} error`;
        }
        default: {
            return `${baseIconClass} success`;
        }
     }
  }

  public onQtyChanged(card: MaCardOptionsModel): void {
    if (!card.model.Item.InventoryQty) {
      card.model.Item.InventoryQty = 0;
    }

    card.leftSectionIcon = this.getCardStatusIcon(card.model);
  }

  public async onUnitSelected(catalogUnit: CatalogInventoryModel, item: InventoryProjectTransferItemModel): Promise<void> {
    const updatedItem = await this.service.UpdateFromCatalogInventoryItem(item, catalogUnit);

    if (updatedItem) {
      this.itemsCards = this.itemsCards.map((card: MaCardOptionsModel): MaCardOptionsModel => {
        if (card.model.Item === item) {
          updatedItem.InventoryQty = item.InventoryQty;
          card.model.Item = updatedItem;
          card.leftSectionIcon = this.getCardStatusIcon(card.model);
        }

        return card;
      });
    }
  }

  public deleteItemConfirmation(response: InventoryProjectTransferItemResponseModel): void {
    const dialogMessage = this.i18n.tr("InventoryTransfer_Delete_Confirmation");

    this.notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<void> => {
      if (success) {
        this.itemsCards = this.itemsCards!.filter((card: MaCardOptionsModel) => card.model !== response);
      }
    });
  }

  public isItemWarning(response: InventoryProjectTransferItemResponseModel): boolean {
    if (!response || !response.Item ||
      response.QtyValidationMode !== InventoryTransferQtyValidation.Warning) { return false; }

    return !this.isValid(response.Item);
  }

  public isItemError(response: InventoryProjectTransferItemResponseModel): boolean {
    if (!response || !response.Item ||
      response.QtyValidationMode !== InventoryTransferQtyValidation.Block) { return false; }

    return !this.isValid(response.Item);
  }

  public isItemOk(response: InventoryProjectTransferItemResponseModel): boolean {
    if (!response || !response.Item) { return false; }

    return this.isValid(response.Item);
  }

  public displayWarnings(response: InventoryProjectTransferItemResponseModel): void {
    if (!response || !response.Item || this.isItemOk(response)) { return; }

    this.notificationHelper.showDialogOk(this.displayWarningMessage(response), this.displayWarningTitle(response));
  }

  public GetUnits(productCode: string): any {
    return {
      transport: (params: any, success: any, failure: any): any => {
        this.catalogInventoryService.GetCatalogInventoryItems(productCode, this.siteId, this.sourcePrefix).then(
          (result: any) => {
            return success(result);
          },
          (fail: any) => {
            return failure(fail);
          }
        );
      },
      mapResults: (item: CatalogInventoryModel): any => {
        return { id: item.Unit, text: item.Unit, data: item };
      },
    };
  }

  private async addItem(productCode: string): Promise<void> {
    const response = await this.service.NewTransferItem(this.transferId, productCode, this.siteId, this.activityId, this.groupId);

    if (!response || !response.Item) { return; }

    response.Item.InventoryQty = 1;

    const card = this.createCard(response);
    this.itemsCards!.push(card);
  }

  private displayWarningTitle(response: InventoryProjectTransferItemResponseModel): string {
    if (this.isItemError(response)) {
      return this.i18n.tr("Error");
    } else {
      return this.i18n.tr("Warning");
    }
  }

  private displayWarningMessage(response: InventoryProjectTransferItemResponseModel): string {
    if (!response || !response.Item) { return ""; }

    let message = "";

    if (!this.hasUnit(response.Item)) {
      message += this.i18n.tr("InventoryTransfer_warning_error_unit");
    }

    if (!this.hasInventoryQty(response.Item)) {
      if (message !== "") {
        message += "<br/>";
      }

      message += this.i18n.tr("InventoryTransfer_warning_error_qty");
    }

    if (!this.inventoryQtyLowerThanAvailableQty(response.Item)) {
      if (message !== "") {
        message += "<br/>";
      }

      message += this.i18n.tr("InventoryTransfer_warning_error_qty_over_availability");
    }

    return message;
  }

    private isValid(item: InventoryProjectTransferItemModel): boolean {

        if (item.Status === CatalogItemStatus.GeneralNoValidation) {
            return true;
        }

        return this.hasInventoryQty(item) &&
            this.inventoryQtyLowerThanAvailableQty(item) &&
            this.hasUnit(item);
  }

  private hasInventoryQty(item: InventoryProjectTransferItemModel): boolean {
    return item.InventoryQty > 0;
  }

  private inventoryQtyLowerThanAvailableQty(item: InventoryProjectTransferItemModel): boolean {
    return item.InventoryQty <= item.AvailableQty;
  }

  private hasUnit(item: InventoryProjectTransferItemModel): boolean {
    return item.Unit > 0;
  }

  private changeItemStatus(response: InventoryProjectTransferItemResponseModel): ItemStatus {
    if (this.isItemWarning(response)) {
      return ItemStatus.Warning;
    }

    if (this.isItemError(response)) {
      return ItemStatus.Error;
    }

    return ItemStatus.Ok;
  }
}
