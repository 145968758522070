import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { WorkOrderMaintenanceTaskModel } from "api/models/company/workorder/work-order-maintenance-task-model";
import val from "core/val";

@autoinject()
export class WorkOrderMaintenanceTask {
    @bindable public Task!: WorkOrderMaintenanceTaskModel;
    @bindable.booleanAttr public disabled: boolean = false;
    @bindable.booleanAttr public isPep: boolean = false;

    public commentsMaxLength: number = val.get("service.workOrder.maintenance.comments", "maxLength");

    public onClickNotCompliant(event: WorkOrderMaintenanceTaskModel): void {
        const workOrderMaintenanceTaskModel = event;
        if (workOrderMaintenanceTaskModel) {
            workOrderMaintenanceTaskModel.Compliant = false;
            workOrderMaintenanceTaskModel.NotCompleted = false;
            workOrderMaintenanceTaskModel.NotApplicable = false;
            workOrderMaintenanceTaskModel.NotCompliant = !workOrderMaintenanceTaskModel.NotCompliant;
        }
    }

    public onClickCompliant(event: WorkOrderMaintenanceTaskModel): void {
        const workOrderMaintenanceTaskModel = event;
        if (workOrderMaintenanceTaskModel) {
            workOrderMaintenanceTaskModel.NotCompliant = false;
            workOrderMaintenanceTaskModel.NotCompleted = false;
            workOrderMaintenanceTaskModel.NotApplicable = false;
            workOrderMaintenanceTaskModel.Compliant = !workOrderMaintenanceTaskModel.Compliant;
        }
    }

    public onClickCompleted(event: WorkOrderMaintenanceTaskModel): void {
        const workOrderMaintenanceTaskModel = event;
        if (workOrderMaintenanceTaskModel) {
            workOrderMaintenanceTaskModel.NotCompliant = false;
            workOrderMaintenanceTaskModel.NotCompleted = false;
            workOrderMaintenanceTaskModel.NotApplicable = false;
            workOrderMaintenanceTaskModel.Completed =  !workOrderMaintenanceTaskModel.Completed;
        }
    }

    public onClickNotCompleted(event: WorkOrderMaintenanceTaskModel): void {
        const workOrderMaintenanceTaskModel = event;
        if (workOrderMaintenanceTaskModel) {
            workOrderMaintenanceTaskModel.Completed = false;
            workOrderMaintenanceTaskModel.NotApplicable = false;
            workOrderMaintenanceTaskModel.Compliant = false;
            workOrderMaintenanceTaskModel.NotCompliant = false;
            workOrderMaintenanceTaskModel.NotCompleted = !workOrderMaintenanceTaskModel.NotCompleted;
        }
    }

    public onClickNotApplicable(event: WorkOrderMaintenanceTaskModel): void {
        const workOrderMaintenanceTaskModel = event;
        if (workOrderMaintenanceTaskModel) {
            workOrderMaintenanceTaskModel.Completed = false;
            workOrderMaintenanceTaskModel.NotCompleted = false;
            workOrderMaintenanceTaskModel.Compliant = false;
            workOrderMaintenanceTaskModel.NotCompliant = false;
            workOrderMaintenanceTaskModel.NotApplicable = !workOrderMaintenanceTaskModel.NotApplicable;
        }
    }
}
