export class NavigationContext {
    _currentNavigationInstruction;

    getCurrentRouteName(){
        return this._currentNavigationInstruction.config.name;
    }

    getParameters(){
        return this._currentNavigationInstruction.params;
    }

    getQueryParameters(){
        return this._currentNavigationInstruction.queryParams;
    }

    set currentNavigationInstruction(navigationInstruction){
        this._currentNavigationInstruction = navigationInstruction;
    }
}