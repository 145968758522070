import { customElement, containerless, bindable } from "aurelia-framework";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { nameof as nameof_ProcoreSynchronizationStatus } from "api/enums/procore-synchronization-status";
import { ValidationError } from "api/enums/validation-error";
import { I18N } from "aurelia-i18n";
import { ProcoreExceptionHelper } from "pages/procore/synchronization/helpers/procore-exception-helper";

@containerless
@customElement("synchronization-result-entity-statuses")
export class SynchronizationResultEntityStatuses {

    @bindable
    public statuses: any[] | null = null;

    public readonly nameof_ProcoreSynchronizationStatus: typeof nameof_ProcoreSynchronizationStatus = nameof_ProcoreSynchronizationStatus;
    constructor(private readonly enumFormatValueConverter: EnumFormatValueConverter,
                private readonly i18n: I18N) {

    }
}
