import { EventAggregator } from "aurelia-event-aggregator";
import { Container } from "aurelia-dependency-injection";
import * as uuidv4 from "uuid/v4";

define([
    "helpers/routerHelper",
    "jquery",
    "underscore",
    "core/resx",
    "helpers/localDBHelper",
    "helpers/notificationHelper",
    "helpers/enumHelper",
    "services/requests"
], function(routerHelper, jQuery, _, resx, localDBHelper, notificationHelper, enumHelper, requests) {
    "use strict";
    var transferActive = false;
    var nbDone = 0;
    var nbTotal = 0;
    var retryNumber = 0;

    var eventAggregator = Container.instance.get(EventAggregator);

    var exports = {

        add: function(type, id, url, value) {
            var dto = mapToPicturesQueueDto(type, id, url, value);

            return localDBHelper.insert(localDBHelper.tables.picturesQueue, dto).done(function() {
                nbTotal++;
                startTransfer();
            });
        },

        addMultiple: function(type, id, url, values) {
            var groupingId = createGuid();
            var dtoList = [];

            values.forEach(value => {
                dtoList.push(mapToPicturesQueueDto(type, id, url, value, groupingId));
            });

            return localDBHelper.insertMultiple(localDBHelper.tables.picturesQueue, dtoList).done(function() {
                nbTotal += dtoList.length;
                startTransfer();
            });
        },

        clear: function() {
            localDBHelper.truncateTable(localDBHelper.tables.picturesQueue);
            nbDone = nbTotal = retryNumber = 0;
        },

        startTransfer: function() {
            var dfd = new jQuery.Deferred();

            if (!transferActive) {
                startTransfer();
                dfd.resolve(true);
            } else {
                dfd.resolve(true);
            }

            return dfd;
        },

        resumeTransfer: function(retryFlag) {
            if (retryFlag) {
                retryNumber++;
            } else {
                retryNumber = 0;
            }
            if (!transferActive) {
                return getUnCompleteTransfer().done(function(list) {
                    nbTotal = list.length;
                    startTransfer();
                });
            } else {
                return new jQuery.Deferred().resolve();
            }
        },

        getUnCompleteTransfer: function() {
            return getUnCompleteTransfer();
        },

        updateMessageInProgress: function(progression) {
            return updateMessageInProgress(progression);
        },
    };

    return exports;

    function createGuid() {
        return uuidv4.default();
    }

    function updateMessageComplete() {
        notificationHelper.notificationCenterMessageSet(
            enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE,
            resx.localize("msg_FileTransferComplete"),
            enumHelper.notificationCenterMessageType.SUCCESS
        );
        var currentPath = routerHelper.getCurrentInstructionFragment();

        // TODO ML: Pourquoi on trigger les refresh comme ça? Toujours lancer l'événement et l'écouter partout au lieu de faire des navigateSelf.
        if (currentPath.endsWith("/document")) {
            routerHelper.navigateSelf();
        } else {
            eventAggregator.publish("document-upload-finish");
        }
    }

    function updateMessageError() {
        notificationHelper.notificationCenterMessageRemove(enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE);
        notificationHelper.showWarning(resx.localize("err_FileRetryTransfer").replace("[file_quantity]", nbTotal - nbDone), resx.localize("err_FileTransfer"), { timeOut: 0 });
        notificationHelper.notificationCenterMessageSet(
            enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE,
            resx.localize("err_FileTransfer"),
            enumHelper.notificationCenterMessageType.ERROR,
            true
        );
    }

    function updateMessageInProgress(progression) {
        if (nbTotal > nbDone) {
            notificationHelper.notificationCenterMessageSet(
                enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE,
                resx
                    .localize("msg_FileTransferProgress")
                    .replace("[currentProgressPercent]", progression)
                    .replace("[nbDone]", nbDone)
                    .replace("[nbTotal]", nbTotal),
                enumHelper.notificationCenterMessageType.ALERT
            );
        } else {
            notificationHelper.notificationCenterMessageRemove(enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE);
        }
    }

    function getUnCompleteTransfer() {
        var dfd = new jQuery.Deferred();

        var getPending = localDBHelper.selectByCriteria(localDBHelper.tables.picturesQueue, { trfStatus: enumHelper.fileTransferStatus.PENDING });
        var getActive = localDBHelper.selectByCriteria(localDBHelper.tables.picturesQueue, { trfStatus: enumHelper.fileTransferStatus.ACTIVE });

        jQuery.when(getPending, getActive).done(function(lstPending, lstActive) {
            dfd.resolve(_.union(lstActive, lstPending));
        });

        return dfd;
    }

    function getFileToSend() {
        var dfd = new jQuery.Deferred();

        getUnCompleteTransfer().done(function(list) {
            if (list.length) {
                var firstItem = list[0];
                if(firstItem.groupingId && firstItem.groupingId !== null)
                {
                    var groupedFiles = _.where(list, {groupingId: firstItem.groupingId})
                    dfd.resolve(groupedFiles);
                } else {
                    dfd.resolve(list[0]);
                }
            } else {
                dfd.resolve();
            }
        });

        return dfd;
    }

    function updateFileStatus(row, status) {
        if(Array.isArray(row))
        {
            _.each(row, function(item) {
                item.trfStatus = status;
            });

            return localDBHelper.updateMultiple(localDBHelper.tables.picturesQueue, row);
        }
        else{
            row.trfStatus = status;
            return localDBHelper.update(localDBHelper.tables.picturesQueue, row);
        }
    }

    function clearFilesDone() {
        localDBHelper.deleteByCriteria(localDBHelper.tables.picturesQueue, { trfStatus: enumHelper.fileTransferStatus.DONE });
    }

    function startTransfer() {
        nbDone = 0;

        if (!transferActive) {
            transferActive = true;
            doTransfer();
        }
    }

    function transferComplete() {
        updateMessageComplete();
        transferActive = false;
        clearFilesDone();
        nbTotal = 0;
        retryNumber = 0;
    }

    function doTransfer() {
        if (nbDone < nbTotal) {
            updateMessageInProgress();

            getFileToSend().done(function(fileToSend) {
                if (fileToSend) {
                    var fileToSendUrl = ""
                    var fileJsonValue;
                    var nbFileToTransfer = 0;

                    if(Array.isArray(fileToSend)) {
                        fileToSendUrl = fileToSend[0].url;
                        fileJsonValue =  _.pluck(fileToSend, 'jsonValue');
                        nbFileToTransfer = fileToSend.length;

                    } else {
                        fileToSendUrl = fileToSend.url;
                        fileJsonValue = fileToSend.jsonValue;
                        nbFileToTransfer = 1;
                    }

                    updateFileStatus(fileToSend, enumHelper.fileTransferStatus.ACTIVE).done(function() {
                        requests
                            .postDocument(fileToSendUrl, fileJsonValue, { timeout: 0 })
                            .done(function() {
                                updateFileStatus(fileToSend, enumHelper.fileTransferStatus.DONE).done(function() {
                                    nbDone += nbFileToTransfer;
                                    doTransfer();
                                });
                            })
                            .fail(function(xhr) {
                                transferActive = false;
                                if (retryNumber < enumHelper.retryOnDocumentFail.RETRY.length) {
                                    backgroundRetry(retryNumber);
                                }
                                //add error to menu
                                else {
                                    updateMessageError();
                                    xhr.handled = true;
                                }
                            });
                    })
                } else {
                    nbDone++;
                    doTransfer();
                }
            });
        } else if (nbTotal > 0) {
            transferComplete();
        }
    }

    function mapToPicturesQueueDto(type, id, url, value, groupingId) {
        return { trfStatus: enumHelper.fileTransferStatus.PENDING, entityType: type, entityId: id, url: url, jsonValue: value, groupingId: groupingId };
    }

    function backgroundRetry(delay) {
        var timeout = enumHelper.retryOnDocumentFail.RETRY[delay] * 60 * 1000;
        window.setTimeout(function() {
            exports.resumeTransfer(true);
        }, timeout);
    }
});
