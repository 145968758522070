import { SmsContactModel } from "api/models/company/contact/sms-contact-model";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { SmsProxy } from "api/proxies/sms-proxy";
import { SmsResponseModel } from "api/models/company/s-m-s/sms-response-model";
import { SmsMessageModel } from "api/models/company/s-m-s/sms-message-model";

@autoinject()
export class SmsService {

    constructor(private readonly smsProxy: SmsProxy) {
    }

    public async SendMessage(dispatchTemplateCode: string, transactionCpt: number, contacts: SmsContactModel[] | null, message: string, requestConfig?: HttpClientRequestConfig): Promise<SmsResponseModel | null> {

        const result: SmsMessageModel = {  MessageId: 0,
            SentDate: null,
            Message: message,
            Contacts: contacts,
            DispatchTemplateId: dispatchTemplateCode,
            TransactionCpt: transactionCpt
        };

        return this.smsProxy.SendMessage(result, requestConfig);
    }

    public async HasValidProvider(dispatchTemplateCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.smsProxy.HasValidProvider(dispatchTemplateCode, requestConfig);
    }

}
