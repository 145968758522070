// GENERATED FILE - DO NOT MODIFY
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProcoreSynchronisationProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async SynchronizeProject(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/projects/{projectCode}",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            null
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }

    public async SynchronizeTransactions(projectCode: string | null, ignoreDate: boolean, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/projects/{projectCode}/transactions",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            { ignoreDate: (ignoreDate === null || ignoreDate === undefined) ? undefined : ignoreDate.toString() }
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }

    public async SynchronizeSupplier(supplierCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/suppliers/{supplierCode}",
            { supplierCode: (supplierCode === null || supplierCode === undefined) ? undefined : supplierCode },
            null
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }

    public async SynchronizePCCOClient(pccoClientCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/pccoClient/{pccoClientCode}",
            { pccoClientCode: (pccoClientCode === null || pccoClientCode === undefined) ? undefined : pccoClientCode },
            null
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }

    public async SynchronizeEmployee(employeeNumber: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/employees/{employeeNumber}",
            { employeeNumber: (employeeNumber === null || employeeNumber === undefined) ? undefined : employeeNumber },
            null
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }

    public async SynchronizeBudget(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/projects/{projectCode}/budget",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            null
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }

    public async SynchronizeBudgetModification(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/projects/{projectCode}/budgetModification",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            null
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }

    public async SynchronizePrimeContract(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/projects/{projectCode}/primeContract",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            null
        );

        const data: number = await this.post<void, number>(uri, undefined, requestConfig);
        return data;
    }
}
