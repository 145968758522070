import { I18N } from "aurelia-i18n";
import services from "services/serviceService";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class ServiceNoteEdit {

    public dispatchId: number = 0;
    public id: number = -1;
    public note: string = "";
    public unModifiedNote: string = "";
    public readonly: boolean = false;
    public serviceType: string = "";
    public timeStamp: string = "";
    public btnText: string = "";

    constructor(private readonly i18n: I18N, private readonly router: Router) {

    }

    public loadData(): any {
        if (this.id === -1) {
            this.btnText = this.i18n.tr("Add");
            this.note = "";
            return true;
        }

        this.btnText = this.i18n.tr("Save");

        return services.getNote(this.serviceType, this.dispatchId, this.id).then((result: any) => {
            this.note = result.Note;
            this.unModifiedNote = result.Note;
            this.timeStamp = result.VisitTimestamp || result.WorkOrderTimestamp;
        });
    }

    public isDirty(): boolean {
        if (this.readonly) { return false; }
        return this.note !== this.unModifiedNote;
    }

    public activate(params: any): any {
        const paramsQs = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQs && paramsQs.readonly === "false");
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;
        this.id = params.rowNumber || -1;
        this.serviceType = params.serviceType;
        this.timeStamp = paramsQs.ts;

        return this.loadData();
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public setNote(): void {
        if (this.isValid()) {
            routerHelper.showLoading();
            const data = {
                RowNumber: (this.id === undefined) ? -1 : this.id,
                Note: this.note,
                VisitTimestamp: "",
                WorkOrderTimestamp: ""
            };

            if (this.serviceType === "S") {
                data.VisitTimestamp = this.timeStamp;
            } else {
                data.WorkOrderTimestamp = this.timeStamp;
            }

            services.setNotes(this.serviceType, this.dispatchId, data).then(() => {
                routerHelper.hideLoading();
                this.unModifiedNote = this.note;
                routerHelper.navigateBack();
            });
        }
    }

    private isValid(): boolean {
        if (this.note.trim().length === 0) {
            notificationHelper.showError(this.i18n.tr("err_VisitDetailsRequired"));
            return false;
        }
        return true;
    }
}
