import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";
import { autoinject, bindable } from "aurelia-framework";

import documentHelper from "helpers/documentHelper";

import documentService from "services/documentService";

@autoinject()
export class InventorySiteTransferDocuments {
    public actions: any;

    @bindable public transfer!: InventorySiteTransferDetailsModel | null;

    public readonly: boolean = false;

    constructor(private readonly inventorySiteTransferService: InventorySiteTransferService) {
    }

    public async activate(params: any): Promise<void> {
        this.transfer = await this.inventorySiteTransferService.GetTransfer(params.transferId);

        this.actions = {
            getSections: documentService.getSectionsBySiteTransferId.bind(self, this.transfer!.Id),
            getSectionDocuments: documentService.getDocumentsBySiteTransferId.bind(self, this.transfer!.Id),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService)
        };
    }
}
