import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject
export class SearchBar {
  @bindable
  public searchFunction: any; // Cette fonction qui est passée au component, est appelée lorsque la recherche est lancée.

  @bindable
  public context: any;

  @bindable
  public displayScanner: boolean = false;

  private input: HTMLInputElement | null = null;
  private icon: HTMLElement | null = null;

  private searchIcon: string = "fa-search";
  private cancelIcon: string = "fa-times";

  private i18n: I18N;

  constructor(i18n: I18N) {
    this.i18n = i18n;
  }

  public attached(): void {
    // On attache le contexte à la fonction de recherche
    this.searchFunction = this.searchFunction.bind(this.context);

    this.input!.addEventListener("input", () => {
      this.search();
    });

    this.icon!.addEventListener("click", () => {
      // Lorsqu l'icone pour annuler est cliquée, on vide le champ
      if (this.icon!.classList.contains(this.cancelIcon)) {
        this.input!.value = "";
      }

      // Lorsque l'icone de recherche est cliquée, on met le focus sur le champ
      if (this.icon!.classList.contains(this.searchIcon)) {
        this.input!.focus();
      }

      this.search();
    });
  }

  public async newScanDetected(scannedValue: any): Promise<any> {
    if (scannedValue) {
        this.input!.value = scannedValue;
        this.search();
    }
  }

  private search(): boolean {
    this.setIcon();

    if (typeof(this.searchFunction) === "function") {
      this.searchFunction(this.input!.value);
    }

    return true;
  }

  private setIcon(): void {
    this.input!.value
      ? this.setCloseIcon()
      : this.setMagnifierIcon();
  }

  private setMagnifierIcon(): void {
    this.icon!.classList.add(this.searchIcon);
    this.icon!.classList.remove(this.cancelIcon);
  }

  private setCloseIcon(): void {
    this.icon!.classList.add(this.cancelIcon);
    this.icon!.classList.remove(this.searchIcon);
  }
}
