// GENERATED FILE - DO NOT MODIFY
import { CurrencyModel } from "api/models/company/currency-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class CurrencyProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetQuotationCurrencies(quotationId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CurrencyModel[] | null> {
        const uri = this.buildUri(
            "/currency",
            null,
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CurrencyModel[] | null = await this.get<CurrencyModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.CurrencyModel");
        return data;
    }
}
