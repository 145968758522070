import routerHelper from "helpers/routerHelper";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

export abstract class FormBase {
    public regExTakeOnlyNoneAlphanumeric: string = "/[^0-9A-Z]+/gi";

    protected constructor(
        protected readonly notificationHelper: NotificationHelper,
        protected readonly i18n: I18N,
        protected readonly router: Router
    ) {
       
    }

    public abstract checkDirty(): boolean;

    public async canDeactivate(): Promise<any> {
        if (this.checkDirty()) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }
}
