import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { DialogCloseResult, DialogController } from "aurelia-dialog";
import { I18N } from "aurelia-i18n";

@autoinject
export default class DialogWithOptions {

    public popupTitle?: string;
    public popupMessage: string = "";
    public optionsList: any[] = [];
    public okLabel: string = this.i18n.tr("OK");
    public cancelLabel: string = this.i18n.tr("Cancel");
    public otherLabel?: string;
    public singleButton: boolean = false;
    public isErrorBox: boolean = false;
    public thirdButton: boolean = false;
    public labelClass?: string;
    public larger: boolean = false;
    public options: any;

    constructor(private readonly i18n: I18N, private readonly dialogController: DialogController, private readonly eventAggregator: EventAggregator) {
    }

    public activate(options: any): any {
        this.options = options;

        this.popupMessage = options.message || "";
        this.popupTitle = options.title || null;
        this.optionsList = options.optionsList;
        this.okLabel = options.okLabel || this.i18n.tr("OK");
        this.cancelLabel = options.cancelLabel || this.i18n.tr("Cancel");
        this.singleButton = options.singleButton || false;
        this.isErrorBox = options.isErrorBox || false;
        this.otherLabel = options.otherLabel;
        this.thirdButton = options.thirdButton || false;
        this.labelClass = options.labelClass;
        this.larger = false;

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);
    }

    public setSelectedValue(value: any): void {
        this.options.selectedValue = value;
    }

    public cancelAction(): void {
        const result: DialogCloseResult = { wasCancelled: true };
        this.dialogController.cancel(result);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    public okAction(): void {
        const result: DialogCloseResult = { wasCancelled: false, output: this.options };
        this.dialogController.ok(result);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}
