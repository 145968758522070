import { I18N } from "aurelia-i18n";
import { default as notificationHelper } from "helpers/notificationHelper";
import { CatalogInventoryProxy } from "api/proxies/catalog-inventory-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { PagingInfo } from "api/paging-info";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";

@autoinject()
export class CatalogInventoryService {
    constructor(private i18n: I18N, private readonly catalogInventoryProxy: CatalogInventoryProxy) {
    }

    public async GetCatalogInventoryItems(code: string, siteId: string, prefix?: string, onlyNotReserved?: boolean, onlyHavingQtyInStock?: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogInventoryModel[] | null> {
        return await this.catalogInventoryProxy.GetCatalogInventoryItems(code, siteId, prefix ? prefix : null, onlyNotReserved ? onlyNotReserved : false, onlyHavingQtyInStock ? onlyHavingQtyInStock : false, filter, pagingInfo, requestConfig);
    }

    public async GetCatalogInventoryReservationItems(siteId: string, prefix?: string, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogInventoryModel[] | null> {
        const result = await this.catalogInventoryProxy.GetCatalogInventoryReservationItems(prefix ? prefix : null, filter, pagingInfo, requestConfig);

        const resevations = result!.filter((reservation: CatalogInventoryModel) => reservation.SiteId === siteId);

        if (result!.length > 0 && resevations.length === 0) {
            notificationHelper.showWarning(this.i18n.tr("err_InventoryProjectTransfer_NoReservationFoundForSite"));
        }

        return resevations;
    }
}
