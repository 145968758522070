import { RowNode } from "ag-grid";
import { elementConfig } from "aurelia-framework";
import { TreeData } from "../grids/treedata";

export class ExtendedRowNode extends RowNode {
    private readonly CHECKBOX_CHECKED_ELEMENT: string = "span.ag-icon-checkbox-checked";
    private readonly CHECKBOX_UNCHECKED_ELEMENT: string = "span.ag-icon-checkbox-unchecked";
    private readonly CHECKBOX_INDETERMINATE_ELEMENT: string = "span.ag-icon-checkbox-indeterminate";
    private readonly CHECKBOX_HIDDEN_CLASS: string = "ag-hidden";
    private readonly ROW_SELECTED_CLASS: string = "ag-row-selected";

    private originElement!: Element;
    private originNode: RowNode;

    constructor(rowNode: RowNode, origin: Element) {
        super();
        this.originNode = rowNode;
        this.originElement = origin;
        this.parent = rowNode.parent;
    }

    public isParentRoot(): boolean {
        return (this.originNode.parent.level === -1);
    }

    public isParent(): boolean {
        return (this.originNode.allChildrenCount != null || this.originNode.allChildrenCount > 0);
    }

    public hasParent(): boolean {
        return (this.parent !== null &&
                this.parent !== undefined);
    }

    public hasChildren(): boolean {
        return (this.getChildrenNodes().length > 0);
    }

    public getAllLeafChildrenNodes(): RowNode[] {
        return this.originNode.allLeafChildren.filter((r: RowNode) => r.level > this.originNode.level);
    }

    public check(): void {
        this.showCheck();

        this.hideUncheck();
        this.hideIndeterminate();

        this.selectRow();
    }

    public uncheck(): void {

        this.showUncheck();

        this.hideCheck();
        this.hideIndeterminate();

        this.unselectRow();
    }

    public indeterminate(): void {
        this.showIndeterminate();

        this.hideCheck();
        this.hideUncheck();

        this.unselectRow();
    }

    public allLeafChildrenSelected(): boolean {
        return this.getAllLeafChildrenState(true);
    }

    public allLeafChildrenUnselected(): boolean {
        return this.getAllLeafChildrenState(false);
    }

    public allChildrenSelected(): boolean {
        return this.getAllChildrenState(true);
    }

    public allChildrenUnselected(): boolean {
        return this.getAllChildrenState(false);
    }

    public allChildrenIndeterminated(): boolean {
        const allChildrenHaveSameState = this.getChildrenNodes().every((element: any, index: any, array: any): boolean => {
            const extendedNode = new ExtendedRowNode(element, this.originElement);

            return (extendedNode.isIndeterminate() === true);
        });

        return allChildrenHaveSameState;
    }

    private isIndeterminate(): boolean {
        const element = this.getCheckbox(this.originElement, this.CHECKBOX_INDETERMINATE_ELEMENT);

        if (element) {
            return !element.classList.contains(this.CHECKBOX_HIDDEN_CLASS);
        }

        return false;
    }

    private getChildrenNodes(): RowNode[] {
        return this.originNode.childrenAfterFilter;
    }

    private getAllChildrenState(state: boolean): boolean {
        const allChildrenHaveSameState = this.getChildrenNodes().every((element: any, index: any, array: any): boolean => {
            return element.selected === state;
        });

        return allChildrenHaveSameState;
    }

    private getAllLeafChildrenState(state: boolean): boolean {
        const allChildrenHaveSameState = this.getAllLeafChildrenNodes().every((element: any, index: any, array: any): boolean => {
            return element.selected === state;
        });

        return allChildrenHaveSameState;
    }

    private getHTMLElement(origin: Element): HTMLElement {
        const nodeList = origin.querySelectorAll(`[row-id='${this.originNode.id}'] > div`);

        return Array.from(nodeList).find((element: Element) => element.innerHTML.indexOf("eCheckbox") >= 0) as HTMLElement;
    }

    private getCheckboxHTMLGroup(origin: Element): HTMLElement | null {
        const rowHTMLElement = this.getHTMLElement(origin);

        if (rowHTMLElement) {
            return rowHTMLElement.querySelector("span.ag-group-checkbox > span.ag-selection-checkbox") as HTMLElement;
        }

        return null;
    }

    private getCheckbox(origin: Element, checkbox: string): HTMLElement | null {
        const checkboxGroup = this.getCheckboxHTMLGroup(origin);

        if (checkboxGroup) {
            return checkboxGroup.querySelector(checkbox) as HTMLElement;
        } else {
            return null;
        }
    }

    private selectRow(): void {
        const rowHTMLElement = this.getHTMLElement(this.originElement);

        if (rowHTMLElement && rowHTMLElement.parentElement) {
            return rowHTMLElement.parentElement.classList.add(this.ROW_SELECTED_CLASS);
        }
    }
    private unselectRow(): void {
        const rowHTMLElement = this.getHTMLElement(this.originElement);

        if (rowHTMLElement  && rowHTMLElement.parentElement) {
            return rowHTMLElement.parentElement.classList.remove(this.ROW_SELECTED_CLASS);
        }
    }

    private showCheck(): void {
        this.showCheckbox(this.CHECKBOX_CHECKED_ELEMENT);
    }

    private showUncheck(): void {
        this.showCheckbox(this.CHECKBOX_UNCHECKED_ELEMENT);
    }

    private showIndeterminate(): void {
        this.showCheckbox(this.CHECKBOX_INDETERMINATE_ELEMENT);
    }

    private hideCheck(): void {
        this.hideCheckbox(this.CHECKBOX_CHECKED_ELEMENT);
    }

    private hideUncheck(): void {
        this.hideCheckbox(this.CHECKBOX_UNCHECKED_ELEMENT);
    }

    private hideIndeterminate(): void {
        this.hideCheckbox(this.CHECKBOX_INDETERMINATE_ELEMENT);
    }

    private showCheckbox(checkbox: string): void {
        const element = this.getCheckbox(this.originElement, checkbox);

        if (element) {
            element.classList.remove(this.CHECKBOX_HIDDEN_CLASS);
        }
    }

    private hideCheckbox(checkbox: string): void  {
        const element = this.getCheckbox(this.originElement, checkbox);

        if (element) {
            element.classList.add(this.CHECKBOX_HIDDEN_CLASS);
        }
    }
}
