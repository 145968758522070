import { MaThumbnailOptionsModel } from "../ma-thumbnail/ma-thumbnail-options.model";

export class MaGalleryOptionsModel {
    public id?: any;
    public images!: MaThumbnailOptionsModel[];

    public action?: () => any;

    constructor(maGalleryOptionsModel?: MaGalleryOptionsModel) {
        Object.assign(this, maGalleryOptionsModel);
    }
}
