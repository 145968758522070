import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { WorkOrderBreaksTiresModel } from "api/models/company/workorder/work-order-breaks-tires-model";
import val from "core/val";

@autoinject()
export class WorkOrderMaintenanceMeasures {
    @bindable public measures!: Array<WorkOrderBreaksTiresModel | null> | null;
    @bindable public readonly: boolean = false;

    public val: any = val;
}
