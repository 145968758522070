// GENERATED FILE - DO NOT MODIFY
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ErrorProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async ReturnCode(httpStatusCode: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/error/status/{httpStatusCode}",
            { httpStatusCode: (httpStatusCode === null || httpStatusCode === undefined) ? undefined : httpStatusCode.toString() },
            null
        );

        const data: void = await this.get<void>(uri, requestConfig);
        return data;
    }

    public async ReturnValidationException(errorCode: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/error/validation/{errorCode}",
            { errorCode: (errorCode === null || errorCode === undefined) ? undefined : errorCode.toString() },
            null
        );

        const data: void = await this.get<void>(uri, requestConfig);
        return data;
    }

    public async Delay10Sec(requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/error/delay10",
            null,
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async Delay30Sec(requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/error/delay30",
            null,
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async Delay65Sec(requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/error/delay65",
            null,
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }
}
