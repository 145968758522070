import { ServiceCallQuotationTypeModel } from "api/models/company/service-call/service-call-quotation-type-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallQuotationTypeProxy } from "api/proxies/service-call-quotation-type-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallQuotationTypeService {
    constructor(private readonly serviceCallQuotationTypeProxy: ServiceCallQuotationTypeProxy) {
    }

    public async getQuotationTypes(filter: string | null, pagingInfo: PagingInfo | null, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationTypeModel[]> {
        return await this.serviceCallQuotationTypeProxy.GetQuotationTypes(filter ? filter : undefined, pagingInfo ? pagingInfo : undefined, requestConfig ? requestConfig : undefined) || [];
    }
}
