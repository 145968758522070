import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import "bootstrap/dist/js/bootstrap";
import "popper.js";

@autoinject()
export class DropdownButton {

    @bindable.booleanAttr
    public displayDownChevron: boolean = true;

    @bindable.booleanAttr
    public dropdownAlignRight: boolean = false;

    @bindable.booleanAttr
    public disabled: boolean = false;

    @bindable.booleanAttr
    public outline: boolean = false;

    // Set the true to prevent adding legacy css classes
    @bindable.booleanAttr
    public preventLegacyBehavior: boolean = false;

    @bindable.string
    public theme: string = "";
}
