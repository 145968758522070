import { DailyEntryCancelDispatchProxy } from "api/proxies/daily-entry-cancel-dispatch-proxy";
import { DailyEntryCancelDispatchRequestModel } from "api/models/company/project/dispatch/daily-entry-cancel-dispatch-request-model";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DailyEntryCancelDispatchService {
    constructor(private readonly dailyEntryCancelDispatchProxy: DailyEntryCancelDispatchProxy) {
    }

    public async CancelDispatch(dispatchProjectCode: string | null, dispatchDate: Date, cancelReason: string | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const cancelRequest: DailyEntryCancelDispatchRequestModel = {
            DispatchProjectCode: dispatchProjectCode,
            DispatchDate: dispatchDate,
            Reason: cancelReason
        };

        await this.dailyEntryCancelDispatchProxy.CancelDispatch(cancelRequest, requestConfig);
    }
}
