import { autoinject } from "aurelia-framework";
import { RouterHelper } from "helpers/router-helper";
import { I18N } from "aurelia-i18n";
import HostContext from "core/host-context";
import BambooHrSyncService from "../../services/bamboo-hr/bamboo-hr-sync-service";
import { BambooSyncConfig } from "./models/bamboo-sync-config";
import { BambooSyncConfigStatus } from "./models/bamboo-sync-config-status";
import { BambooSyncConfigError } from "./models/bamboo-sync-config-error";
import { observable } from "aurelia-framework";
import notificationHelper from "helpers/notificationHelper";

@autoinject
export class BambooHrSyncConfig {

    public configuration: BambooSyncConfig = new BambooSyncConfig();
    public configStatus: BambooSyncConfigStatus = new BambooSyncConfigStatus();

    public isInError: boolean = false;
    public errorMessage: string = "";

    public errors: BambooSyncConfigError[] = [];

    public apiKeyStyle: string = "";
    public subDomainStyle: string = "";

    public apiKeyClassInput: string = "";
    public subDomainClassInput: string = "";

    public apiKeyDisplay: string = "";
    public apiKeyFormValue: string = "";
    public isUpdatingApiKeyLayout: boolean = false;

    public hasBeenLoaded: boolean = false;
    public attemptLoginWithBambooHr: any = {
        neverTried: true,
        isSuccess: false,
    };

    constructor(
        private readonly i18n: I18N,
        private routerHelper: RouterHelper,
        private readonly hostContext: HostContext,
        private readonly bambooHrSyncService: BambooHrSyncService) {

    }

    public async bindToWindow(w: any): Promise<any> {
        w.BambooHrSyncConfig = this;
    }

    public async bind(): Promise<void> {
        await this.bindToWindow(window);
        await this.updateModel(true);
        this.hasBeenLoaded = true;
    }

    public async digestConfig(config: BambooSyncConfig): Promise<any> {
        this.isInError = config.apiKey.length === 0 || config.subDomain.length === 0;

        this.apiKeyClassInput = config.apiKey.length > 0 ? "" : "bg-warning";
        this.subDomainClassInput = config.subDomain.length > 0 ? "" : "bg-warning";
    }

    public async resetInErrorState(): Promise<any> {
        this.apiKeyStyle = "";
        this.subDomainStyle = "";
    }

    public async digestConfigErrors(errors: BambooSyncConfigError[]): Promise<any> {
        this.isInError = errors.length > 0;
        this.errors = [];

        await this.resetInErrorState();
        for (const err of errors) {
            this.errors.push(err);

            if (err.errorName === "Synchro_BambooApiKeyIsEmpty") {
                this.apiKeyStyle = "color:red;font-style:italic;";

            } else if (err.errorName === "Synchro_BambooSubDomainIsEmpty") {
                this.subDomainStyle = "color:red;font-style:italic;";
            }
        }
    }

    public async updateModel(forceRefreshConfig: boolean = false): Promise<any> {
        if (forceRefreshConfig) {
            this.bambooHrSyncService.getConfiguration()
            .then((x: any) => {
                this.configuration = x;
                this.digestConfig(x);
            });
        }

        this.bambooHrSyncService.getConfigurationStatus()
        .then((x: any) => {
            this.configStatus = x;
            this.digestConfigErrors(this.configStatus.errors);
        })
        .catch((x: any) => {
            notificationHelper.showDialogOk(this.i18n.tr("BambooHR.CannotGetConfiguration"));
        });
    }

    public async onApiKeyEdit(): Promise<any> {
        this.apiKeyFormValue = "";
        this.isUpdatingApiKeyLayout = true;
    }

    public async onApiKeySave(): Promise<any> {
        this.bambooHrSyncService.updateBambooApiKey(this.apiKeyFormValue)
        .then((x: any) => {
            if (x.success) {
                this.updateModel(true);
                this.isUpdatingApiKeyLayout = false;
            } else {
                notificationHelper.showDialogOk(this.i18n.tr("BambooHR.IssueWhenUpdatingAPIKey"));
            }
        })
        .catch((x: any): any => {
            notificationHelper.showDialogOk(this.i18n.tr("BambooHR.CrashWhenUpdatingAPIKey"));
        });
    }

    public async onApiKeyCancel(): Promise<any> {
        this.isUpdatingApiKeyLayout = false;
    }

    public async onFormSubmit(e: Event): Promise<any> {
        e.stopPropagation();
        this.onSaveSubDomain();
    }

    public async onSaveSubDomain(): Promise<any> {
        this.bambooHrSyncService.updateBambooSubdomain(this.configuration.subDomain)
            .then((x: any) => {
                if (x.success) {
                    this.updateModel(true);
                    this.subDomainClassInput = this.configuration.subDomain.length > 0 ? "" : "bg-warning";
                } else {
                        notificationHelper.showDialogOk(this.i18n.tr("BambooHR.SubDomainNotSaved"));
                }
            })
            .catch((x: any) => {
                    notificationHelper.showDialogOk(this.i18n.tr("BambooHR.SubDomainNotSaved"));
            });
    }

    public async onTestBambooConnection(): Promise<any> {
        await this.bambooHrSyncService.attemptLoginWithBambooHr()
        .then((x: any) => {
            this.attemptLoginWithBambooHr.neverTried = false;
            this.attemptLoginWithBambooHr.isSuccess = x.result;
        })
        .catch((x: any) => {
            this.attemptLoginWithBambooHr.neverTried = false;
            this.attemptLoginWithBambooHr.isSuccess = false;
        });
    }

    public async onCancel(): Promise<any> {
        this.hostContext.viewModel.notify("OnCancel", null);
        return this.routerHelper.navigateBack();
    }

}
