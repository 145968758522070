import { WorkCategoryModel } from "api/models/company/work-category-model";
import { PagingInfo } from "api/paging-info";
import { WorkCategoryProxy } from "api/proxies/work-category-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class WorkCategoryService {
    constructor(private readonly workCategoryProxy: WorkCategoryProxy) {
    }

    public async getWorkCategories(filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<WorkCategoryModel[]> {
        return await this.workCategoryProxy.GetWorkCategories(filter, pagingInfo, requestConfig) || [];
    }
}
