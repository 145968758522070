// GENERATED FILE - DO NOT MODIFY
import { ReceiptOfGoodsItemModel } from "api/models/company/receipt-of-goods-item-model";
import { ReceiptOfGoodsModel } from "api/models/company/receipt-of-goods-model";
import { ReceiptOfGoodsResponseModel } from "api/models/company/receipt-of-goods-response-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ReceiptOfGoodsProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetReceiptsOfGoods(onlyClose: boolean, orderBy: number | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsModel[] | null> {
        const uri = this.buildUri(
            "/receipts-of-goods",
            null,
            { onlyClose: (onlyClose === null || onlyClose === undefined) ? undefined : onlyClose.toString(), orderBy: (orderBy === null || orderBy === undefined) ? undefined : orderBy.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ReceiptOfGoodsModel[] | null = await this.get<ReceiptOfGoodsModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsModel");
        return data;
    }

    public async GetReceiptsOfGoodsForOrder(onlyClose: boolean, purchaseOrderId: string | null, extension: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsModel[] | null> {
        const uri = this.buildUri(
            "/receipts-of-goods/order",
            null,
            { onlyClose: (onlyClose === null || onlyClose === undefined) ? undefined : onlyClose.toString(), purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId, extension: (extension === null || extension === undefined) ? undefined : extension, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ReceiptOfGoodsModel[] | null = await this.get<ReceiptOfGoodsModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsModel");
        return data;
    }

    public async GetReceiptsOfGoodsHeader(receiptId: number, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsModel | null> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}/header",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString() },
            null
        );

        const data: ReceiptOfGoodsModel | null = await this.get<ReceiptOfGoodsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsModel");
        return data;
    }

    public async CreateReceiptOfGoodsFromPurchaseOrder(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsResponseModel | null> {
        const uri = this.buildUri(
            "/receipts-of-goods/create-from-purchase-order",
            null,
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId, extension: (extension === null || extension === undefined) ? undefined : extension }
        );

        const data: ReceiptOfGoodsResponseModel | null = await this.get<ReceiptOfGoodsResponseModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsResponseModel");
        return data;
    }

    public async Get(receiptId: number, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsResponseModel | null> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString() },
            null
        );

        const data: ReceiptOfGoodsResponseModel | null = await this.get<ReceiptOfGoodsResponseModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsResponseModel");
        return data;
    }

    public async DeleteReceiptOfGoods(receiptId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async SaveReceiptOfGoods(receiptOfGoods: ReceiptOfGoodsModel | null, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsResponseModel | null> {
        const uri = this.buildUri(
            "/receipts-of-goods",
            null,
            null
        );

        const data: ReceiptOfGoodsResponseModel | null = await this.post<ReceiptOfGoodsModel | null, ReceiptOfGoodsResponseModel | null>(uri, receiptOfGoods, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsResponseModel");
        return data;
    }

    public async SaveReceiptOfGoodsDeliveryNo(receiptId: number, deliveryNo: string | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}/delivery-no/{deliveryNo}",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString(), deliveryNo: (deliveryNo === null || deliveryNo === undefined) ? undefined : deliveryNo },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async GetReceiptOfGoodsDetail(receiptId: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsItemModel | null> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}/detail/{lineNo}",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString(), lineNo: (lineNo === null || lineNo === undefined) ? undefined : lineNo.toString() },
            { productCode: (productCode === null || productCode === undefined) ? undefined : productCode }
        );

        const data: ReceiptOfGoodsItemModel | null = await this.get<ReceiptOfGoodsItemModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsItemModel");
        return data;
    }

    public async GetNewReceiptOfGoodsDetail(receiptId: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsItemModel | null> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}/detail/new",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString() },
            { productCode: (productCode === null || productCode === undefined) ? undefined : productCode }
        );

        const data: ReceiptOfGoodsItemModel | null = await this.get<ReceiptOfGoodsItemModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ReceiptOfGoodsItemModel");
        return data;
    }

    public async SaveReceiptOfGoodsDetail(item: ReceiptOfGoodsItemModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/receipts-of-goods/detail",
            null,
            null
        );

        await this.post<ReceiptOfGoodsItemModel | null, void>(uri, item, requestConfig);
    }

    public async Complete(receiptId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}/complete",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async DeleteReceiptOfGoodsDetail(receiptId: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/receipts-of-goods/{receiptId}/detail/{lineNo}",
            { receiptId: (receiptId === null || receiptId === undefined) ? undefined : receiptId.toString(), lineNo: (lineNo === null || lineNo === undefined) ? undefined : lineNo.toString() },
            { productCode: (productCode === null || productCode === undefined) ? undefined : productCode }
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }
}
