import { bindable, autoinject} from "aurelia-framework";
import { default as dateHelper } from "helpers/dateHelper";
import { ReceiptOfGoodsProxy } from "api/proxies/receipt-of-goods-proxy";
import { default as routerHelper } from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

@autoinject()
export class ReceiptOfGoodsHeader {
    public title: string = "";
    public subtitle: string = "";
 
    @bindable
    public receiptId!: number;
    @bindable
    public orderId!: string;
    @bindable
    public extension!: string;
    @bindable
    public hideInfo: boolean = false;

    constructor(private readonly receiptOfGoodsProxy: ReceiptOfGoodsProxy, private readonly routeRepository: RouteRepository) {
    }

    public async bind(): Promise<void> {
        await this.initTitleAndSubtitle();
    }

    public orderSummary(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_MaterialOrder_summary.name, { orderId: this.orderId, extension: this.extension, receiptId: this.receiptId});
    }

    private async initTitleAndSubtitle(): Promise<void> {
        const receipt = await this.receiptOfGoodsProxy.GetReceiptsOfGoodsHeader(this.receiptId);
        if (receipt != null && receipt != null) {
            this.setDisplayValues(receipt.AccountingDate, receipt.Id);
        }
    }

    private setDisplayValues(accountingDate: Date, id: number): void {
        this.title = accountingDate ? dateHelper.getFullTextDate(accountingDate) : "";
        this.subtitle = id.toString();
    }
}
