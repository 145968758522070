import { JobModelStatus } from "api/enums/job-model-status";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { SynchronizationStatus } from "api/enums/synchronization-status";
import { SynchronizationTarget } from "api/enums/synchronization-target";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { BindingSignaler } from "aurelia-templating-resources";
import { ProcoreGridItemTransformer } from "services/procore/helpers/procore-grid-item-transformer";
import { ProcoreLoadingService } from "services/procore/procore-loading-service";
import { Synchronization } from "../../synchronization";
import { ProcoreGridItem } from "../grid/models/procore-grid-item";
import { ProcoreMenuItemId } from "../side-bar/enums/menu-item-id";
import { TransactionsLoader } from "./details loaders/transactions-loader";

@autoinject
export class ProcoreLoader {

  constructor(
    private readonly i18n: I18N,
    private readonly loadingService: ProcoreLoadingService,
    private readonly signaler: BindingSignaler,
    private readonly procoreGridItemTransformer: ProcoreGridItemTransformer,
    private readonly transactionsLoader: TransactionsLoader) { }

  public async load(synchronization: Synchronization): Promise<void> {
    if (!synchronization) { return; }

    synchronization.isLoading = true;
    synchronization.grid.items = [];

    try {
      synchronization.grid.items = await this.loadItems(synchronization);
      this.signaler.signal("procore-polling");
    } finally {
      synchronization.isLoading = false;
    }
  }

  public async loadDetails(synchronization: Synchronization, item: ProcoreGridItem): Promise<void> {
    if (!synchronization || !item) { return; }

    item.DetailsIsLoading = true;

    if (synchronization.sidebar.selectedItemId !== ProcoreMenuItemId.PrimeContracts) {
      item.IsSynchronized = false;
      item.Status = SynchronizationStatus.New;
      item.JobStatus = null;
    }

    let noDetailsText = "";

    switch (synchronization.sidebar.selectedItemId) {
      case ProcoreMenuItemId.Transactions:
        item.JobId  = await this.transactionsLoader.loadTransactions(item, synchronization.filters.transactionFilters.ignoreDate);
        return;

      case ProcoreMenuItemId.Budget:
        item.Details  = await this.loadingService.LoadBudget(item, item.Code);
        noDetailsText = "Procore.NoBudget";
        break;

        case ProcoreMenuItemId.BudgetModifications:
          item.Details  = await this.loadingService.LoadBudgetModifications(item, item.Code);
          noDetailsText = "Procore.NoBudget";
          break;
        case ProcoreMenuItemId.PrimeContracts:
         item.Errors = [];
         item.Warnings = [];
         item.Message = "";
         const primeContract = await this.loadingService.LoadPrimeContract(item, item.Code);
         if (primeContract != null) {
           item.Errors = primeContract.Errors;
           item.Warnings = primeContract.Warnings;
           item.Message = this.procoreGridItemTransformer.getMessage(item.Errors, item.Warnings);
           item.Details = [primeContract];
         }
         break;

    }

    if (item.Details.length <= 0) {
      item.DetailsOpen = false;

      item.Warnings.push(this.i18n.tr(noDetailsText));
      item.Message = this.procoreGridItemTransformer.getMessage(item.Errors, item.Warnings);

      if (item.Errors.length <= 0) {
        item.IsSynchronized = true;
        item.JobStatus = JobModelStatus.Success;
      }
    }

    item.DetailsIsLoading = false;
  }

  private async loadItems(synchronization: Synchronization): Promise<ProcoreGridItem[]> {
    let items: ProcoreGridItem[] = [];
    switch (synchronization.sidebar.selectedItemId) {
      case ProcoreMenuItemId.Projects:
        items = await this.loadingService.LoadProjects();
        break;
      case ProcoreMenuItemId.Suppliers:
        items = await this.loadingService.LoadSuppliers();
        break;
        case ProcoreMenuItemId.PccoClients:
          items = await this.loadingService.LoadPccoClients();
          break;
      case ProcoreMenuItemId.Employees:
        items = await this.loadingService.LoadEmployees();
        break;
      case ProcoreMenuItemId.DeleteDirectCosts:
          items = await this.loadingService.LoadSynchronizedProjects(ProcoreEntityToSynchronize.DeleteDirectCostItems, true);
          items.forEach((item: ProcoreGridItem): void => {
            this.forceNotSynchronized(item);
            item.Target = SynchronizationTarget.Procore;
          });
          break;
      case ProcoreMenuItemId.Budget:
        items = await this.loadingService.LoadSynchronizedProjects(ProcoreEntityToSynchronize.ProjectBudgets, true);
        items.forEach((item: ProcoreGridItem): void => {
            this.forceNotSynchronized(item);
            item.Target = SynchronizationTarget.Procore;
        });
        break;
      case ProcoreMenuItemId.BudgetModifications:
        items = await this.loadingService.LoadSynchronizedProjects(ProcoreEntityToSynchronize.BudgetModifications, true);
        items.forEach((item: ProcoreGridItem): void => {
            this.forceNotSynchronized(item);
            item.Target = SynchronizationTarget.Maestro;
        });
        break;
        case ProcoreMenuItemId.PrimeContracts:
          items = await this.loadingService.LoadSynchronizedProjects(ProcoreEntityToSynchronize.PrimeContracts, true);
          items.forEach((item: ProcoreGridItem): void => {
              item.Target = SynchronizationTarget.Maestro;
          });
          break;
      default:
        items = await this.loadingService.LoadSynchronizedProjects(ProcoreEntityToSynchronize.DirectCostItems, true);
        items.forEach((item: ProcoreGridItem): void => {
          this.forceNotSynchronized(item);
        });
    }

    return items;
  }

  private forceNotSynchronized(item: ProcoreGridItem): void {
    item.IsSynchronized = false;
    item.Status = SynchronizationStatus.New;
    if (item.JobId) {
        item.JobStatus = JobModelStatus.Waiting;
    }

    if (item.Children) {
      item.Children.forEach((i: ProcoreGridItem) => {
        this.forceNotSynchronized(i);
      });
    }
  }
}
