import { MaGalleryOptionsModel } from "./ma-gallery-options.model";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";
@autoinject
export class MaGallery {
    @bindable public galleryOptions!: MaGalleryOptionsModel;

    constructor(private readonly i18n: I18N) {}

}
