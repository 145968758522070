import { bindable,  autoinject} from "aurelia-framework";
import { AdditionalFieldType } from "api/enums/additional-field-type";
import { I18N } from "aurelia-i18n";

@autoinject()
export class AdditionalField {
    @bindable
    public field: any = null;
    @bindable
    public readonly: boolean = false;
    @bindable
    public context: any = null;

    public additionalFieldType: any = {
        Text: AdditionalFieldType.Text,
        Memo: AdditionalFieldType.Memo,
        Date: AdditionalFieldType.Date,
        Time: AdditionalFieldType.Time,
        NumberWithDecimals: AdditionalFieldType.NumberWithDecimals,
        Amount: AdditionalFieldType.Amount,
        ShortInteger: AdditionalFieldType.ShortInteger,
        LongInteger: AdditionalFieldType.LongInteger,
        CheckBox: AdditionalFieldType.CheckBox,
        Timestamp: AdditionalFieldType.Timestamp,
        Phone: AdditionalFieldType.Phone,
        ComboBox: AdditionalFieldType.ComboBox,
        LinkedComboBox: AdditionalFieldType.LinkedComboBox,
        Grid: AdditionalFieldType.Grid
    };

    constructor(private readonly i18n: I18N) {
    }

    public gridTypeDisplay(field: any): string {
        return `${field.description} - ${field.columnName ? field.columnName : this.i18n.tr("Column") + " " + field.columnNo} - ${field.lineName ? field.lineName : this.i18n.tr("Line") + " " + field.lineNo}`;
    }
}
