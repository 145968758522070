import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import additionalFieldsService from "services/additionalFieldsService";
import { FormBase } from "pages/form-base";

@autoinject
export class AdditionalFieldsService extends FormBase {
    public actions: any = {};
    public serviceType: string = "";
    public quotationId: number = 0;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.ServiceQuotation;
    public saveAdditionalFieldsButton: any = true;
    public currentFields: any = null;
    public unModifiedFields: any = null;
    public quotation!: ServiceCallQuotationDetailsModel;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        if (!this.quotation) {
            return true;
        }

        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings) || this.quotation!.IsServiceContractNotFound;
    }

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly additionalFieldMapper: AdditionalFieldMapper, private readonly serviceCallQuotationService: ServiceCallQuotationService, private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService) {
        super(notificationHelper, i18n, router);
    }

    public async activate(params: any): Promise<void> {
        this.quotationId = params.quotationId;
        await this.initQuotation(this.quotationId);
        await this.initSecuritySettings();

        this.actions = {
            getAll: additionalFieldsService.getAllForQuotation.bind(additionalFieldsService, this.quotationId),
            setAll: additionalFieldsService.setAllForQuotation.bind(additionalFieldsService, this.quotationId)
        };
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.additionalFieldMapper.isNotEqual(this.unModifiedFields, this.currentFields);
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, true, true, false, false, false);
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }
}
