import { ReceiptOfGoodsModel } from "api/models/company/receipt-of-goods-model";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { CatalogItemManagementMode } from "api/enums/catalog-item-management-mode";
import { ReceiptOfGoodsStatus } from "api/enums/receipt-of-goods-status";
import { ReceiptOfGoodsItemModel } from "api/models/company/receipt-of-goods-item-model";
import { ManageSerialNumbersParameters } from "./manage-serial-numbers";
import { ReceiptOfGoodsService } from "services/receipt-of-goods-service";
import notificationHelper from "helpers/notificationHelper";
import RouteRepository from "repositories/routeRepository";
import routerHelper from "helpers/routerHelper";
import dateHelper from "helpers/dateHelper";
import urlHelper from "helpers/urlHelper";
import { RouterHelper } from "helpers/router-helper";
import { MaCardActionModel, MaCardLineModel, MaCardOptionsModel } from "components/layout/ma-card/ma-card-options.model";
import { NavigationContext } from "core/navigation-context";

@autoinject
export class ReceiptOfGoodEdit {
    public receiptId: number = 0;
    public readonly CatalogItemManagementMode: typeof CatalogItemManagementMode = CatalogItemManagementMode;
    public readonly ReceiptOfGoodsStatus: typeof ReceiptOfGoodsStatus = ReceiptOfGoodsStatus;
    public readonly InventoryFieldTag: string = "Inv";
    public readonly SupplierFieldTag: string = "Supp";
    public readonly PurchaseOrderProductCodeCommentLine: string = ".";

    public order: ReceiptOfGoodsModel | null = null;

    public itemCards: MaCardOptionsModel[] = [];
    public manuallyAddedItemCards: MaCardOptionsModel[] = [];
    public displayInventory: boolean = false;
    public diplayCommentLines: boolean = false;

    private orderNumber: string | null = null;
    private extension: string | null = null;

    private validationMode: string | null = null;

    private toggleLabels: HTMLSpanElement[] = [];
    private filterLabels: HTMLSpanElement[] = [];
    private localStorageKey: string = "receipt_of_goods_toggle_save";
    private isReadonly: boolean = false;
    private isLoading: boolean = true;

    private filtered: boolean = false;
    private filterSessionStorageKey: string = "";
    private fromOrderNumber: string | null = null;

    constructor(private readonly routeRepository: RouteRepository,
                private readonly service: ReceiptOfGoodsService,
                private readonly i18n: I18N,
                private readonly navigationContext: NavigationContext) { }

    public async activate(params: any): Promise<void> {
        this.receiptId = params.receiptId;
        this.fromOrderNumber = params.orderId;

        if (this.receiptId > 0) {
            const orderResponse = await this.service.getOrder(this.receiptId);

            this.order = orderResponse!.ReceiptOfGoods;

            this.validationMode = orderResponse!.QtyValidationMode;
            this.orderNumber = this.order!.OrderNumber;
            this.extension = this.order!.Extension;
            this.isReadonly = this.order!.Status !== ReceiptOfGoodsStatus.Active;

            if (this.fromOrderNumber) {
                this.isReadonly = true;
            }

            this.filterSessionStorageKey = `receipt_of_goods_${this.receiptId}_filtered`;
        }

        if (this.order && this.order.Items!.length > 0) {
            this.refreshItemCards();
            this.manuallyAddedItemCards = this.order!.Items!.filter((x: ReceiptOfGoodsItemModel) => x!.AddedByUser).map((item: ReceiptOfGoodsItemModel) => this.createCard(item));
        }

        const filtered = window.sessionStorage.getItem(this.filterSessionStorageKey);
        if (!!filtered) {
          this.filtered = filtered === "true";
        }

        const savedToggle = localStorage.getItem(this.localStorageKey);
        if (!!savedToggle) {
            this.displayInventory = savedToggle === "true";
        }
    }

    public refreshItemCards(): void {
        this.itemCards = this.order!.Items!.filter((x: ReceiptOfGoodsItemModel) => !x!.AddedByUser && (x!.ProductCode !== this.PurchaseOrderProductCodeCommentLine || (this.diplayCommentLines && x!.ProductCode === this.PurchaseOrderProductCodeCommentLine))).map((item: ReceiptOfGoodsItemModel) => this.createCard(item));
    }

    public async attached(): Promise<void> {
        this.formatToggleLabels(this.toggleLabels, !this.displayInventory);
        this.formatToggleLabels(this.filterLabels, this.filtered);

        this.isLoading = false;
    }

    public goToAdditionalFields(order: ReceiptOfGoodsModel): void {
        const route = this.navigationContext.getCurrentRouteName() + "_AdditionalFields";
        routerHelper.navigateToRoute(route, {
            receiptId: order.Id,
            orderNumber: order.OrderNumber,
            extension: order.Extension,
            q: routerHelper.buildQueryString({
                readonly: this.isReadonly,
            }),
        });
    }

    public goToDocument(order: ReceiptOfGoodsModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Document.name, {
            receiptId: order.Id,
            orderNumber: order.OrderNumber,
            extension: order.Extension,
            q: routerHelper.buildQueryString({
                readonly: this.isReadonly,
            }),
        });
    }

    public goToOrderMemo(order: ReceiptOfGoodsModel): void {
        let url =  this.routeRepository.routes.Receipt_Of_Goods_Memo.route.toString().replace(":receiptId", order.Id.toString());
        url += routerHelper.addQuerystring({
            receiptId: order.Id,
            memo: order.Memo,
            orderNumber: order.OrderNumber,
            extension: order.Extension,
            q: routerHelper.buildQueryString({readonly: this.isReadonly})
        });

        routerHelper.navigate(url);
    }

    public goToSerial(item: ReceiptOfGoodsItemModel, receiptId: number): void {
        const params = {
            receiptId: receiptId,
            lineNo: item.LineNo,
            productCode: item.ProductCode,
            validationMode: this.validationMode,
            readonly: this.isReadonly,
        } as ManageSerialNumbersParameters;

        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Manage_Serial_Numbers.name, params);
    }

    public async newScanDetected(scannedValue: any): Promise<any> {
        if (scannedValue) {
            this.manageItemMatchInReceipt(scannedValue);
        }
    }

    public goToAddItem(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Add_Item.name, {
            receiptId: this.receiptId,
            pONumber: this.order!.OrderNumber,
            extension: this.order!.Extension,
            prefix: this.order!.Prefix,
            projectNo: this.order!.ProjectNo,
            receiptDate: this.order!.ReceiptDate,
            isSupplierMode: !this.displayInventory
        });
    }

    public editItem(item: ReceiptOfGoodsItemModel): void {
        if (this.isReadonly) { return; }

        if (item.ProductCode === "!") {
            item.ProductCode += item.OutCatalogCode;
        }

        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Edit_Item.name, {
            receiptId: this.receiptId,
            pONumber: this.order!.OrderNumber,
            extension: this.order!.Extension,
            lineNo: item.LineNo,
            prefix: this.order!.Prefix,
            projectNo: this.order!.ProjectNo,
            receiptDate: this.order!.ReceiptDate,
            productCode: item.ProductCode,
            isSupplierMode: !this.displayInventory
        });
    }

    public valueChanged(item: ReceiptOfGoodsItemModel, value: number, isInventory: boolean): void {
      if (!this.order || this.isLoading || routerHelper.isLoading()) {
        return;
      }

      if (isInventory && !value) {
          item.InventoryQtyReceived = 0;
      } else if (!isInventory && !value) {
          item.SupplierQtyReceived = 0;
      }

      this.save(this.order, item, isInventory);
    }

    public saveDate(value: any, isAccountingDate: boolean): void {
        if (this.order) {
            if (isAccountingDate) {
                this.order.AccountingDate = value.detail;
            } else {
                this.order.ReceiptDate = value.detail;
            }

            this.save(this.order);
        }
    }

    public async deleteItem(itemToDelete: ReceiptOfGoodsItemModel): Promise<void> {
        if (this.isReadonly) { return; }
        if (itemToDelete.ProductCode === "!") {
            itemToDelete.ProductCode += itemToDelete.OutCatalogCode;
        }

        const dialogMessage = `${this.i18n.tr("InventoryTransfer_Delete_Confirmation")} (${itemToDelete.ProductCode} - ${itemToDelete.Description})`;

        notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<any> => {
            if (success) {
                await this.service.DeleteReceiptOfGoodsDetail(itemToDelete.receiptId, itemToDelete.LineNo, itemToDelete.ProductCode);

                this.manuallyAddedItemCards = this.manuallyAddedItemCards.filter((card: MaCardOptionsModel) => {
                    return itemToDelete.TransactionNumber !== card.model.TransactionNumber;
                });

                this.order!.Items = this.order!.Items!.filter((item: ReceiptOfGoodsItemModel | null) => {
                    return itemToDelete.TransactionNumber !== item!.TransactionNumber;
                });
            }
        });
    }

    public onClickDiplayCommentLines(): void {
        this.diplayCommentLines = !this.diplayCommentLines;
        this.refreshItemCards();
    }

    private createCard(item: ReceiptOfGoodsItemModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            displayLines: [
                new MaCardLineModel({ isCustomTemplate: true})
            ],
            actionItems: [],
        });

        if (item.AddedByUser && !this.isReadonly) {
            card.actionItems = [
                new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.editItem.bind(this, item) }),
                new MaCardActionModel({ icon: "fa fa-trash text-danger pt-3", action: this.deleteItem.bind(this, item) })];
        }

        card.actionItems!.push();

        return card;
    }

    private async manageItemMatchInReceipt(value: string): Promise<any> {
        const itemFound = this.order!.Items!.find((item: ReceiptOfGoodsItemModel) => {
            return item.ProductCode === value;
        });

        if (itemFound) {
            const isSerialManagementMode = itemFound!.ItemManagementMode === this.CatalogItemManagementMode.Serial;

            if (isSerialManagementMode) {
                this.goToSerial(itemFound!, this.receiptId);
            }

            this.highlightAndScrollToItem(value);
            this.focusOnInput(value);
        } else {
            notificationHelper.showError(this.i18n.tr("ReceiptOfGoodsScannedItemNotFound"), undefined, { timeOut: 0, extendedTimeOut: 0 });
        }
    }

    private highlightAndScrollToItem(value: string): void {
        const element: HTMLElement | null = document.getElementById(value);

        if (element) {
            element.scrollIntoView();
            element.classList.add("alert-success");

            setTimeout(() => {
                element.classList.remove("alert-success");
            }, 3000);
        }
    }

    private focusOnInput(value: string): void {
        const fieldTag: string = this.displayInventory ? this.InventoryFieldTag : this.SupplierFieldTag;
        const divToFocus: HTMLElement | null = document.getElementById(`${value}${fieldTag}`);
        const inputToFocus: HTMLInputElement | null = divToFocus!.querySelector("input");
        inputToFocus!.focus();
        inputToFocus!.select();
    }

    private filter(): void {
      this.filtered = !this.filtered;

      window.sessionStorage.setItem(this.filterSessionStorageKey, this.filtered.toString());

      this.formatToggleLabels(this.filterLabels, this.filtered);
    }

    private toggle(): void {
        this.displayInventory = !this.displayInventory;
        localStorage.setItem(this.localStorageKey, this.displayInventory.toString());
        this.formatToggleLabels(this.toggleLabels, !this.displayInventory);
    }

    private async searchOrder(): Promise<void> {
      if (!this.orderNumber) {
        notificationHelper.showWarning(this.i18n.tr("ReceiptOfGoodsOrderNumberEmpty"), "", { timeout: 0});

        return;
      }

      this.order = await this.service.createReceipt(this.orderNumber, this.extension);

      if (this.order) {
            if (this.order.Items!.length > 0) {
                this.refreshItemCards();
                this.manuallyAddedItemCards = this.order!.Items!.filter((x: ReceiptOfGoodsItemModel) => x!.AddedByUser).map((item: ReceiptOfGoodsItemModel) => this.createCard(item));
            }

            this.receiptId = this.order.Id;

            const baseUrl = urlHelper.getBaseUrlWithDash();
            const route =  this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.route.toString().replace(":receiptId", this.receiptId.toString());

            // Modifier l'url sans rediriger
            window.history.replaceState("", "", baseUrl + route);
        }
    }

    private save(order: ReceiptOfGoodsModel, item?: ReceiptOfGoodsItemModel, isInventory?: boolean | undefined): void {
        if (order.Status !== ReceiptOfGoodsStatus.Active) {
            return;
        }

        this.service.save(order, item, isInventory, this.validationMode);
    }

    private formatDate(date: Date): string {
        return dateHelper.formatDate("YYYY-MM-DD", date);
    }

    private formatToggleLabels(labels: HTMLSpanElement[], checked: boolean): void {
      if (!labels || labels.length <= 0) {
        return;
      }

      const indexToActivate = !!checked ? 1 : 0;

      // Disable everything
      labels.forEach((label: HTMLSpanElement) => {
          label.classList.remove("bold");
          label.classList.add("text-muted");
      });

      // Enable the index received
      labels[indexToActivate].classList.add("bold");
      labels[indexToActivate].classList.remove("text-muted");
    }
}
