import { Aurelia, inject, FrameworkConfiguration, PLATFORM } from "aurelia-framework";
import Config from "config";
import resx from "core/resx";

@inject(Aurelia)
export class AgGridHelper {
    aurelia;

    constructor(aurelia) {
        this.aurelia = aurelia;
    }

    cellStyleRightAlign() { return { "text-align": "right" }; }
    cellStyleCenterAlign() { return { "text-align": "center" }; }
    cellStyleLeftAlign() { return { "text-align": "left" }; }
    cellStyleLeftAlignWithValidationErrors() { return { "text-align": "left", "border": "2px solid red" }; }

    async lazyLoadAgGridModule() {
        const moduleInfo = {
            agGridEnterprise: await import(/* webpackChunkName: "ag-grid" */ "ag-grid-enterprise/main"),
            agGrid: await import(/* webpackChunkName: "ag-grid" */ "ag-grid/main")
        };

        this.setAgGridLicenseKey(moduleInfo);

        // Load ag-grid-aurelia plugin and our feature on-demand.
        const config = new FrameworkConfiguration(this.aurelia)
            .plugin(PLATFORM.moduleName("ag-grid-aurelia", "ag-grid"))
            .feature(PLATFORM.moduleName("components/ag-grid/index"));

        await config.apply();

        return moduleInfo;
    }

    async createGrid(gridElement, gridOptions) {
        const moduleInfo = await this.lazyLoadAgGridModule();

        return new moduleInfo.agGrid.Grid(gridElement, gridOptions);
    }

    setAgGridLicenseKey(moduleInfo) {
        // Set the Enterprise license key.
        moduleInfo.agGridEnterprise.LicenseManager.setLicenseKey(Config.agGridLicenceKey);
    }

    createDefaultGridOptions(columnDefs)
    {
        return {
            defaultColDef: {
                filter: "agTextColumnFilter",
                filterParams: {
                    newRowsAction: "keep"
                },
                allowedAggFuncs: ["sum", "min", "max"]
            },
            columnDefs: columnDefs,
            enableColResize: true,
            //rowModelType: 'enterprise',
            //rowData: createRowData(),
            rowGroupPanelShow: "always",
            rowSelection: "multiple",
            rowDeselection: true,
            enableFilter: true,
            editType: "fullRow",
            animateRows: true,
            showToolPanel: false,
            toolPanelSuppressSideButtons: true,
            enableSorting: true,
            suppressDragLeaveHidesColumns: true,
            stopEditingWhenGridLosesFocus: true,
            debug: Config.debug,
            onGridReady: (params) => {
                // params.api.sizeColumnsToFit();
            },
            localeTextFunc: (key, defaultValue) => this.localizeText(key, defaultValue)
        }
    }

    localizeText(key, defaultValue) {
        const prefixedKey = `ag-grid-${key}`;
        const value = resx.localize(prefixedKey);

        if (!value || value === prefixedKey) {
            return defaultValue;
        }

        return value;
    }
}
