import { bindable } from "aurelia-framework";

export class EquipmentButton {
  @bindable
  public size: number = 1; // 1 = 100%
  @bindable
  public checked: boolean = false;
  @bindable
  public disabled: boolean = false;
 
  @bindable
  public clickFunction: any | null = null;

  private buttonHtmlElement: HTMLElement | null = null;

  public attached(): void {
    this.setClasses();
  }

  private getCheckCss(): object {
    const sizeInPercentage = this.size * 100;

    return {
      fontSize: `${sizeInPercentage}%`
    };
  }

  private getCircleCss(): object {
    const newWidth  = 44 * this.size;
    const newHeight = 44 * this.size;

    return {
      width: `${newWidth}px`,
      height: `${newHeight}px`,
    };
  }

  private setClasses(): void {
    this.buttonHtmlElement!.classList.toggle("disabled", this.disabled);
    this.buttonHtmlElement!.classList.toggle("checked", this.checked);
  }
}
