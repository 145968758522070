// GENERATED FILE - DO NOT MODIFY
import { CallHistoryModel } from "api/models/company/service/call-history-model";
import { ServiceCallVisitHistoryItemModel } from "api/models/company/service/service-call-visit-history-item-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallHistoryProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetHistory(dispatchId: number, filter: string | null | undefined, equipmentId: number | null | undefined, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CallHistoryModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/history",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: (filter === null || filter === undefined) ? undefined : filter, equipmentId: (equipmentId === null || equipmentId === undefined) ? undefined : equipmentId.toString(), page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CallHistoryModel[] | null = await this.get<CallHistoryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.CallHistoryModel");
        return data;
    }

    public async GetServiceCallVisitsHistory(dispatchId: number, filter: string | null | undefined, equipmentId: number | null | undefined, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallVisitHistoryItemModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/visithistory",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: (filter === null || filter === undefined) ? undefined : filter, equipmentId: (equipmentId === null || equipmentId === undefined) ? undefined : equipmentId.toString(), page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallVisitHistoryItemModel[] | null = await this.get<ServiceCallVisitHistoryItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallVisitHistoryItemModel");
        return data;
    }
}
