import { autoinject, bindable } from "aurelia-framework";
import documentService from "services/documentService";
import settingRepository from "repositories/settingRepository";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class InventorySiteTransferDocumentsAdd extends FormBase {
    public actions: any;
    @bindable public transferId: number | null = null;
    public dispatchProjectCode: string | null = null;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router
    ) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<void> {
        this.transferId = params.transferId;
        this.dispatchProjectCode = settingRepository.getDispatchTemplate();
        this.actions = { save: documentService.saveTransferInventorySitePicture.bind(self, params.transferId, this.dispatchProjectCode)};
    }
}
