import { ProcoreOrderType } from "api/enums/procore-order-type";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ProcoreConfigurationService } from "services/procore/procore-configuration-service";
import { ProcoreLoadingService } from "services/procore/procore-loading-service";
import { ProcoreGridGroup } from "../../grid/models/procore-grid-group";
import { ProcoreGridItem } from "../../grid/models/procore-grid-item";
import { DateFormatValueConverter } from "converters/format/date-format";
import { InfiniteRowModel } from "ag-grid";
import { JobModelStatus } from "api/enums/job-model-status";
import { SynchronizationStatus } from "api/enums/synchronization-status";

@autoinject
export class TransactionsLoader {
  public stockOrdersEnabled: boolean = false;

  constructor(
    private readonly i18n: I18N,
    private readonly loadingService: ProcoreLoadingService,
    configurationService: ProcoreConfigurationService) {

    configurationService.GetProcoreConfiguration().then((configurations: ProcoreCompanyConfigurationModel | null) => {
      if (!configurations) { return; }

      this.stockOrdersEnabled = configurations.OrderSynchronizationOrderType === ProcoreOrderType.StockOrder;
    });
  }

  public async loadTransactions(item: ProcoreGridItem, ignoreDate: boolean = false): Promise<number> {
    item.Errors = [];
    item.Status = SynchronizationStatus.New;
    
    return await this.loadingService.LoadTransactions(item, this.stockOrdersEnabled, ignoreDate);
  }

  public shortDateFormatter(date: Date): any {
    const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);

    return dateFormatValueConverter.toView(date, "L");
  }
}
