import { ProcoreGridColumn } from "./procore-grid-column";
import { ProcoreGridColumnEnum } from "./procore-grid-column-enum";

export class ProcoreGridConfigurations {
  public Columns: ProcoreGridColumn[] = [];
  public WarningFooter?: string;
  public ShowCheckboxOnSuccess?: boolean;

  public get targetEnabled(): boolean {
    return this.IsColumnEnabled(ProcoreGridColumnEnum.Target);
  }

  public get codeEnabled(): boolean {
    return this.IsColumnEnabled(ProcoreGridColumnEnum.Code);
  }

  public get descriptionEnabled(): boolean {
    return this.IsColumnEnabled(ProcoreGridColumnEnum.Description);
  }

  public get additionalFieldEnabled(): boolean {
    return this.IsColumnEnabled(ProcoreGridColumnEnum.AdditionalField);
  }

  public get messageEnabled(): boolean {
    return this.IsColumnEnabled(ProcoreGridColumnEnum.Message);
  }

  public get targetHeader(): string {
    return this.getColumnHeader(ProcoreGridColumnEnum.Target);
  }

  public get codeHeader(): string {
    return this.getColumnHeader(ProcoreGridColumnEnum.Code);
  }

  public get descriptionHeader(): string {
    return this.getColumnHeader(ProcoreGridColumnEnum.Description);
  }

  public get additionalFieldHeader(): string {
    return this.getColumnHeader(ProcoreGridColumnEnum.AdditionalField);
  }

  public get messageHeader(): string {
    return this.getColumnHeader(ProcoreGridColumnEnum.Message);
  }

  constructor() {
    this.SetDefault();
  }

  public SetDefault(): void {
    this.AddColumn(ProcoreGridColumnEnum.Target,          "SynchronizeTo",   true,  "Target");
    this.AddColumn(ProcoreGridColumnEnum.Code,            "Code",            true,  "Code");
    this.AddColumn(ProcoreGridColumnEnum.Description,     "Description",     true,  "Description");
    this.AddColumn(ProcoreGridColumnEnum.AdditionalField, "AdditionalField", false, "AdditionalField");
    this.AddColumn(ProcoreGridColumnEnum.Message,         "Message",         true,  "Message");
  }

  public AddColumn(columnEnum: ProcoreGridColumnEnum, header: string, enabled: boolean, field: string): void {
    this.Columns[columnEnum] = {
      Header: header,
      Enabled: enabled,
      Field: field
    };
  }

  public EnableAllColumns(enabled: boolean = true): void {
    this.Columns.forEach((column: ProcoreGridColumn): void => {
      column.Enabled = enabled;
    });
  }

  public DisableAllColumns(): void {
    this.EnableAllColumns(false);
  }

  public EnableColumn(columnEnum: ProcoreGridColumnEnum): void {
    if (!this.Columns[columnEnum]) {
      return;
    }

    this.Columns[columnEnum].Enabled = true;
  }

  public DisableColumn(columnEnum: ProcoreGridColumnEnum): void {
    if (!this.Columns[columnEnum]) {
      return;
    }

    this.Columns[columnEnum].Enabled = false;
  }

  public ChangeHeader(columnEnum: ProcoreGridColumnEnum, header: string): void {
    this.Columns[columnEnum].Header = header;
  }

  private IsColumnEnabled(columnEnum: ProcoreGridColumnEnum): boolean {
    if (!this.Columns[columnEnum]) {
      return false;
    }

    return this.Columns[columnEnum].Enabled;
  }

  private getColumnHeader(columnEnum: ProcoreGridColumnEnum): string {
    if (!this.Columns[columnEnum]) {
      return "";
    }

    return this.Columns[columnEnum].Header;
  }
}
