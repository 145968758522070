import { DocumentProxy } from "api/proxies/document-proxy";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { autoinject } from "aurelia-framework";
import { DocumentExtensionProxy } from "api/proxies/document-extension-proxy";

@autoinject()
export class DocumentExtensionService {

    constructor(private readonly documentExtensionProxy: DocumentExtensionProxy) {

    }

    public async GetExtensions(requestConfig?: HttpClientRequestConfig): Promise<string | null> {
        return this.documentExtensionProxy.GetExtensions(requestConfig);
    }
}
