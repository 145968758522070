// GENERATED FILE - DO NOT MODIFY
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceQuotationSecurityProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetServiceQuotationSecuritySettingsForCurrentUser(requestConfig?: HttpClientRequestConfig): Promise<ServiceQuotationSecuritySettingsModel | null> {
        const uri = this.buildUri(
            "/service-call-security-settings",
            null,
            null
        );

        const data: ServiceQuotationSecuritySettingsModel | null = await this.get<ServiceQuotationSecuritySettingsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Security.ServiceQuotationSecuritySettingsModel");
        return data;
    }
}
