import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import queryStringHelper from "helpers/queryStringHelper";
import dispatchService from "services/dispatchService";
import workorderTimesheetService from "services/workorderTimesheetService";

@autoinject
export class DailyEntryHoursSummary {

    public dispatchProjectCode: string = "";
    public dispatchDate: Date = new Date();
    public list: any[] = [];
    public noDataText: string = "";
    public subscribeSelected: any;
    public hasSelectedEntries: boolean = false;

    constructor(private readonly i18n: I18N) {
    }

    public reloadButton(): any {
        return;
    }

    public async activate(params: any): Promise<any> {
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;

        await this.loadData(params.dispatchProjectCode, params.dailyEntryDate);
    }

    public async loadData(dispatchProjectCode: string, dispatchDate: Date): Promise<any> {
        const data: any[] = await workorderTimesheetService.getHoursSummaryByProjectCodeAndDispatchDate(dispatchProjectCode, dateHelper.formatDateToSend(dispatchDate));

        this.list = data.map((item: any) => {
            item.selected = false;
            return item;
        });
    }

    public selectAll(): void {
        this.list.forEach((item: any) => {
            if (!item.IsQuit && item.IsInProgress && !item.selected) {
                this.selectEntry(item);
            }
        });

    }

    public selectEntry(entry: any): void {
        entry.selected = !entry.selected;
        this.hasSelectedEntries = this.list.some((item: any) => item.selected);
    }

    public async leave(): Promise<void> {
        if (this.hasSelectedEntries) {
            const selectedIds = this.list.filter((item: any) => item.selected).map((item: any) => item.Projects[0].DispatchId);
            await dispatchService.setHasLeftDispatches(true, selectedIds);
            await this.loadData(this.dispatchProjectCode, this.dispatchDate);
            await this.reloadButton();
            this.hasSelectedEntries = false;
        }
    }

}
