// GENERATED FILE - DO NOT MODIFY
export enum ServiceCallQuotationStatus {
    InProgressByTheTechnician = "A",
    OnHold = "B",
    CompletedByTheTechnician = "C",
    Entered = "E",
    Confirmed = "P",
    SentToCustomer = "R",
    AcceptedByTheCustomer = "S",
    CallGenerated = "T",
    RefusedByTheCustomer = "X",
    ManuallyClosed = "Y",
}

export let nameof = "ServiceCallQuotationStatus";
