import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { bindingMode, autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import { SelectItem } from "models/select-item";

@autoinject()
export class CatalogItemPicker extends FormControl {
    @bindable.none({ defaultBindingMode: bindingMode.twoWay }) //
    public selectedItem: SelectItem<CatalogItemModel> | null = null;

    @bindable.string //
    public catalogBaseUrl: string = "";

    @bindable.booleanAttr //
    public showNotInCatalog: boolean = false;

    @bindable.number({ defaultValue: -1 }) //
    public requisitionId!: number;

    constructor(element: Element) {
        super(element);
    }
}
