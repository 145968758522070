import { ProjectInspectionProxy } from "api/proxies/project-inspection-proxy";
import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InspectionTypeLookupModel } from "api/models/company/inspection/inspection-type-lookup-model";

@autoinject()
export class ProjectInspectionService {
    constructor(private readonly projectInspectionProxy: ProjectInspectionProxy) {}

    public async GetProjectsForInspections(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectBaseModel[] | null> {
       return this.projectInspectionProxy.GetProjectsForInspections(prefix, filter, pagingInfo, requestConfig);
    }

    public async GetInspectionTypesLookup(projectId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InspectionTypeLookupModel[] | null> {
        return this.projectInspectionProxy.GetInspectionTypesLookup(projectId, filter, pagingInfo, requestConfig);
    }
}
