import { inject } from "aurelia-framework";

import LoggerHelper from "helpers/logger-helper";

import { default as _ } from "underscore";
import { default as routerHelper } from "helpers/routerHelper";
import { default as apiHelper } from "helpers/apiHelper";
import { default as requests } from "services/requests";

import { ErrorProxy } from "api/proxies/error-proxy";

@inject(ErrorProxy)
export class Index {
    errorProxy;

    constructor(errorProxy) {
        this.errorProxy = errorProxy;
    }

    activate() {
    }

    // EVENT HANDLER
    jsErrorInEventHandler() {
        this.undefinedFunction();
    }

    throwErrorInEventHandler() {
        throw new Error("Throw error in event handler.");
    }

    throwStringInEventHandler() {
        throw new "Throw string in event handler.";
    }

    // TIMER HANDLER
    jsErrorInTimerHandler() {
        window.setTimeout(() => { const x = {}; x.undefinedFunction(); }, 500);
    }

    throwErrorInTimerHandler() {
        window.setTimeout(() => { throw new Error("Throw error in timer handler."); }, 500);
    }

    throwStringInTimerHandler() {
        window.setTimeout(() => { throw "Throw string in timer handler"; }, 500);
    }

    // DEFERRED CHAIN
    jsErrorInDeferredChain() {
        var deferred = jQuery.Deferred();
        deferred.then(() => { this.undefinedFunction(); });

        window.setTimeout(() => deferred.resolve(), 1000);
    }

    throwErrorInDeferredChain() {
        var deferred = jQuery.Deferred();
        deferred.then(() => { throw new Error("Throw error in deferred chain."); });

        window.setTimeout(() => deferred.resolve(), 1000);
    }

    throwStringInDeferredChain() {
        var deferred = jQuery.Deferred();
        deferred.then(() => { throw "Throw string in deferred chain."; });

        window.setTimeout(() => deferred.resolve(), 1000);
    }

    // PROMISE CHAIN
    jsErrorInPromiseChain() {
        const promise = new Promise(function (resolve, reject) {
            window.setTimeout(resolve, 1000);
        });

        promise.then(() => { this.undefinedFunction(); });
    }

    throwErrorInPromiseChain() {
        const promise = new Promise(function (resolve, reject) {
            window.setTimeout(resolve, 1000);
        });

        promise.then(() => { throw new Error("Throw error in promise chain."); });
    }

    throwStringInPromiseChain() {
        const promise = new Promise(function (resolve, reject) {
            window.setTimeout(resolve, 1000);
        });

        promise.then(() => { throw new "Throw string in promise chain."; });
    }

    navigateToUnknownRoute() {
        routerHelper.navigateToRoute("RouteDoesNotExists", {});
    }

    redirectToIn(type) {
        routerHelper.navigateToRoute("Error_Manager_In", { "type": type });
    }

    redirectToApi(statusCode, type) {
        routerHelper.navigateToRoute("Error_Manager_Api", { "statusCode": statusCode, "type": type });
    }

    callServerReturnStatusCode(statusCode) {
        const url = apiHelper.getBaseUrl() + "/error/status/" + statusCode;
        return requests.getJson(url);
    }

    async callServerReturnStatusCodeAxios(statusCode) {
        return await this.errorProxy.ReturnCode(statusCode);
    }

    callServerReturnValidationError(errorCode) {
        const url = apiHelper.getBaseUrl() + "/error/validation/" + errorCode;
        return requests.getJson(url);
    }

    async callServerReturnValidationErrorAxios(errorCode) {
        return await this.errorProxy.ReturnValidationException(errorCode);
    }

    callServerTimeout() {
        const url = apiHelper.getBaseUrl() + "/error/delay65";
        return requests.getJson(url);
    }

    async callServerTimeoutAxios() {
        return await this.errorProxy.Delay65Sec();
    }

    async unhandledCancellation() {
        const token = this.errorProxy.createCancellationToken();

        window.setTimeout(() => { token.cancel(); }, 2000);

        // No try/catch, cancellation will cause an error.
        return await this.errorProxy.Delay30Sec({ cancellationToken: token });
    }

    async handledCancellation() {
        const token = this.errorProxy.createCancellationToken();

        window.setTimeout(() => { token.cancel(); }, 2000);

        try {
            return await this.errorProxy.Delay30Sec({ cancellationToken: token });
        } catch (error) {
            // Cancellation handled, everything is fine.
            if (!error.isHttpRequestCancellation) {
                throw error;
            }
        }
    }

    showGlobalError() {
        routerHelper.showGlobalError();
    }

    throwOrLogfunction () {
        LoggerHelper.throwOrLog("Test throwOrLog");
    }
}
