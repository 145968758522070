import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { DocumentProxy } from "api/proxies/document-proxy";
import { autoinject, bindable, computedFrom } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { ServiceCallQuotationDocumentService } from "services/service-call-quotation-document-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { default as settingHelper } from "helpers/settingHelper";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

export interface QuotationDocumentsAddParameters {
    quotationId: number;
}

@autoinject()
export class QuotationDocumentsAdd {

    public readonly: boolean = false;
    public dispatchId: string | null = null;
    public actions: any;
    public isCategorySelectionAllowed: boolean = false;
    public getCategoryProxy?: () => Promise<DocumentCategoryModel[] | null>;
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public dispatchTemplateCode: string | null = null;
    public isDirty: boolean = false;

    constructor(
        private readonly documentProxy: DocumentProxy,
        private readonly dispatchTemplateService: DispatchTemplateService,
        private readonly serviceCallQuotationDocumentService: ServiceCallQuotationDocumentService,
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router
    ) {}

    public async activate(params: any): Promise<void> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
       
        this.quotation = await this.serviceCallQuotationService.getQuotation(params.quotationId, false, false, false, false, false);
        this.dispatchId = this.quotation.SourceDispatchId.toString();

        this.actions = { save: this.serviceCallQuotationDocumentService.AddDocumentForServiceCallQuotation.bind(this.serviceCallQuotationDocumentService, this.quotation.Id) };

        const dispatchTemplate = this.quotation.SourceDispatchId !== 0 ?
            await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(this.quotation.SourceDispatchId) : await this.dispatchTemplateService.GetDispatchTemplatesByCode(settingHelper.getSelectedDispatchModel());

        this.dispatchTemplateCode = dispatchTemplate !== null ? dispatchTemplate.Code : null;
        this.isCategorySelectionAllowed = dispatchTemplate !== null ? dispatchTemplate.AllowCategorySelection : false;
        this.getCategoryProxy = dispatchTemplate !== null ? this.documentProxy.GetCategories.bind(this.documentProxy, dispatchTemplate.Code) : null;
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }
}
