import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject()
export class BooleanToYesNoValueConverter {
    constructor(private readonly i18n: I18N) {
    }

    public toView(value: boolean): string {
        if (value === true) {
            return this.i18n.tr("Yes");
        } else if (value === false) {
            return this.i18n.tr("No");
        }

        return "";
    }
}
