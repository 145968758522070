import { DOM, computedFrom } from "aurelia-framework";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import ElementIdManager from "services/element-id-manager";

export class FormControl {
    public id: string = ElementIdManager.nextId();

    @bindable.string //
    public label: string = "";

    @bindable.string //
    public message: string = "";

    @bindable.booleanAttr
    public readonly: boolean = false;

    @bindable.booleanAttr //
    public disabled: boolean = false;

    @bindable.booleanAttr //
    public required: boolean = false;

    @bindable
    public inheritReadonly: boolean = true;

    @observable
    public groupReadonly: boolean = false;

    @computedFrom("readonly", "groupReadonly", "inheritReadonly")
    public get effectiveReadonly(): boolean {
        return this.readonly || (this.groupReadonly && this.inheritReadonly);
    }

    constructor(protected readonly element: Element) {
    }

    public attached(): void {
        const event: CustomEvent = DOM.createCustomEvent("form-control-attached", {
            detail: this,
            bubbles: true,
            cancelable: false
        } as CustomEventInit<FormControl>);

        this.element.dispatchEvent(event);
    }

    protected readonlyChanged(): void {
        //
    }

    protected groupReadonlyChanged(): void {
        //
    }
}
