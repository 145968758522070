import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import additionalFieldsService from "services/additionalFieldsService";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import materialRequisitionService from "services/materialRequisitionService";
import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class ProjectMaterialRequisitionAdditionalFields extends FormBase {

    public actions: any = {};
    public dispatchProjectCode: string = "";
    public requisitionId: string = "";
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.Requisition;
    public saveAdditionalFieldsButton: boolean = true;
    public requisition!: MaterialRequisitionModel;
    public currentFields: any = null;
    public unModifiedFields: any = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly additionalFieldMapper: AdditionalFieldMapper) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.additionalFieldMapper.isNotEqual(this.unModifiedFields, this.currentFields);
    }

    public async activate(params: any): Promise<any> {
        await this.bindViewModel(params.dispatchProjectCode, params.requisitionId, params.q);
        this.bindWidget();
    }

    public async bindViewModel(dispatchProjectCode: string, requisitionId: string, queryString: string): Promise<void> {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.dispatchProjectCode = dispatchProjectCode;
        this.requisitionId = requisitionId;

        this.requisition = await materialRequisitionService.getMaterialRequisition(this.requisitionId);
        this.readonly = this.readonly || !this.requisition.CreatedByCurrentUser;
    }

    public bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForRequisitionEquipment.bind(this, this.requisitionId),
            setAll: additionalFieldsService.setAllForRequisitionEquipment.bind(this, this.requisitionId)
        };
    }
}
