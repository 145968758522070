import { AdditionalFieldDefinitionHelper } from "./interfaces/additional-field-definition-helper";
import dateHelper from "helpers/dateHelper";

export class AdditionalFieldTimestampHelper implements AdditionalFieldDefinitionHelper {
  public getValue(item: any): any {
    if (item.Value === null || item.Value.length !== 14) {
      return "";
    }

    const year = item.Value.substr(0, 4);
    const month = item.Value.substr(4, 2);
    const day = item.Value.substr(6, 2);

    const hours = item.Value.substr(8, 2);
    const minutes = item.Value.substr(10, 2);
    const seconds = item.Value.substr(10, 2);

    let dateValue = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    dateValue = dateHelper.formatDate("YYYY-MM-DD HH:mm:ss", new Date(dateValue));

    return dateValue === "Invalid date"
      ? ""
      : dateValue;
  }
}
