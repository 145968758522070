export class MaThumbnailOptionsModel {
    public id?: any;
    public thumbnail?: any[] | null;

    public title?: string | null = "";
    public description?: string = "";
    public size?: number;

    public action?: () => any;

    constructor(maThumbnailOptionsModel?: MaThumbnailOptionsModel) {
        Object.assign(this, maThumbnailOptionsModel);
    }
}
