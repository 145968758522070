import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";
import { default as val } from "core/val";

import { default as enumHelper } from "helpers/enumHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as activityService } from "services/activityService";
import { default as materialService } from "services/materialService";
import { default as templateService } from "services/templateService";
import { default as apiHelper } from "helpers/apiHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as Parse } from "helpers/parse";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@inject(I18N, NotificationHelper, Router)
export default class MaterialEdit extends FormBase {
    readonly = false;
    workOrderId = null;
    showNotInCatalog = false;
    isBilling = false;
    isDirty = false;

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        return this.isDirty;
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            this.notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"),"");
            return new Redirect("Settings");
        }
        return true;
    }

    activate(params) {
        this.bindViewModel(decodeURIComponent(params.workOrderId), params.isBilling, params.lineNo, params.q);

        return templateService.getUserTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialFieldService;
        });
    }

    bindViewModel(workOrderId, isBilling, lineNo, querystring) {
        this.noBillCatalogBaseUrl = apiHelper.getBaseUrl() + "/catalog/fieldservices/material?dispatchTemplateId=" + settingHelper.getSelectedDispatchModel() + "&workorderId=" + workOrderId;
        this.billCatalogBaseUrl = apiHelper.getBaseUrl() + "/catalog/fieldservices/materialbilling?dispatchTemplateId=" + settingHelper.getSelectedDispatchModel() + "&workorderId=" + workOrderId;

        this.catalogBaseUrl = Parse.Boolean(isBilling) === true ? this.billCatalogBaseUrl : this.noBillCatalogBaseUrl;

        this.isBilling = Parse.Boolean(isBilling) === true;
        this.lineNo = lineNo;
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.workOrderId = workOrderId;

        this.actions = {
            getActivities: activityService.getActivitiesForWorkOrder.bind(null, this.workOrderId, enumHelper.workOrderActivityType().MATERIAL),
            getMaterial: materialService.getForWorkOrder.bind(this, this.workOrderId, this.lineNo, this.isBilling),
            setMaterial: materialService.setForWorkOrder.bind(null, this.workOrderId)
        };

        this.validationValues = {
            description: {
                isBilling: {
                    maxLength: val.get('service.workOrder.material.description.isBilling', 'maxLength')
                },
                isNotBilling: {
                    maxLength: val.get('service.workOrder.material.description.isNotBilling', 'maxLength')
                }
            }
        };
    }
}