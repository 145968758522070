import { PhysicalInventoryProxy } from "api/proxies/physical-inventory-proxy";
import { PhysicalInventoryItemModel } from "api/models/company/physical-inventory-item-model";
import { PhysicalInventoryModel } from "api/models/company/physical-inventory-model";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import settings from "repositories/settingRepository";

@autoinject()
export class PhysicalInventoryService {
    private oldItem: any|null = null;

    constructor(private readonly physicalInventoryProxy: PhysicalInventoryProxy,
                private readonly routeRepository: RouteRepository,
                private readonly i18n: I18N) {
    }

    public async GetPhysicalInventory(onlyClose: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel[] | null> {
        return this.physicalInventoryProxy.GetPhysicalInventory(onlyClose, filter, pagingInfo, requestConfig);
    }

    public async Get(id: number, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel | null> {
        return this.physicalInventoryProxy.Get(id, requestConfig);
    }

    public async CreatePhysicalInventory(requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel | null> {

        return this.physicalInventoryProxy.GetNewPhysicalInventoryHeader(requestConfig);
    }

    public async DeletePhysicalInventory(id: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.physicalInventoryProxy.DeletePhysicalInventory(id, requestConfig);
    }

    public async SavePhysicalInventory(physInv: PhysicalInventoryModel | null, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryModel | null> {
        return this.physicalInventoryProxy.SavePhysicalInventory(physInv, requestConfig);
    }

    public async GetPhysicalInventoryDetail(id: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryItemModel | null> {
        return this.physicalInventoryProxy.GetPhysicalInventoryDetail(id, lineNo, productCode, requestConfig);
    }

    public async SavePhysicalInventoryDetail(item: PhysicalInventoryItemModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return this.physicalInventoryProxy.SavePhysicalInventoryDetail(item, requestConfig);
    }

    public async save(physInv: PhysicalInventoryModel): Promise<PhysicalInventoryModel | null> {
        return await this.physicalInventoryProxy.SavePhysicalInventory(physInv);
    }

    public async complete(physInv: PhysicalInventoryModel): Promise<void> {
        const dialogMessage = this.i18n.tr("PhysicalInventoryConfirmComplete");

        notificationHelper.showDialogYesNo(dialogMessage).then(
            async (success: boolean): Promise<any> => {
                if (success) {
                    try {
                        await this.physicalInventoryProxy.Complete(physInv.Id);
                    } catch (e) {
                        if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId && e.response.data.Exception.ErrorId === 690) {
                            const apiException = JSON.parse(e.response.data.Exception.Message);
                            let sMessage = apiException.FrenchErrorDescription;
                            if (settings.getLanguage().toLowerCase() !== "fr") {
                                sMessage = apiException.EnglishErrorDescription;
                            }
                            notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`).replace("{0}", sMessage), undefined, { timeOut: 0, extendedTimeOut: 0 });
                        } else if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                            notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
                        } else {
                            notificationHelper.showError(e.response.data.ExceptionMessage, undefined, { timeOut: 0, extendedTimeOut: 0 });
                        }
                        return;
                    }
                 
                    routerHelper.navigateToRoute(this.routeRepository.routes.PhysicalInventoryCount.name);
                }
            }
        );
    }

    public async GetNewPhysicalInventoryDetail(id: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<PhysicalInventoryItemModel[] | null> {
        return await this.physicalInventoryProxy.GetNewPhysicalInventoryDetail(id, productCode, undefined, undefined, requestConfig);
    }

    public async DeletePhysicalInventoryDetail(id: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return await this.physicalInventoryProxy.DeletePhysicalInventoryDetail(id, lineNo, productCode, requestConfig);
    }

    public async IsSiteMandatory(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return await this.physicalInventoryProxy.IsSiteMandatory(requestConfig);
    }

    public async ValidationPhysicalInventoryDetailQty(item: PhysicalInventoryItemModel | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        return await this.physicalInventoryProxy.ValidationPhysicalInventoryDetailQty(item);
    }
}
