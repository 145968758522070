export enum ProcoreMenuItemId {
  Projects,
  // Activities,
  Budget,
  PrimeContracts,
  BudgetModifications,
  Transactions,
  Suppliers,
  PccoClients,
  Employees,
  DeleteDirectCosts,
  // Generate here
}
