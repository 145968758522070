define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    function getRecommendationsFromUrl(url, status, filter, page, fromHistory) {

        if (typeof fromHistory === 'undefined' || fromHistory === null) {
            return requests.getJson(
                url + "/recommendations?"
                + "recommendationstatus=" + status
                + (filter ? "&filter=" + filter : "")
                + (page ? "&page=" + page : "")
            );
        }

        return requests.getJson(
            url + "/recommendations?"
            + "recommendationstatus=" + status
            + (filter ? "&filter=" + filter : "")
            + (page ? "&page=" + page : "")
            + "&fromHistory=" + fromHistory
        );
    }

    var exports = {
        getRecommendationTypes: function () {
            return requests.getJson(apiHelper.getBaseUrl() + "/recommendationtypes");
        },

        addRecommendation: function (data) {
            return requests.post(apiHelper.getBaseUrl() + "/recommendations", data);
        },

        addRecommendationForDispatch: function (dispatchId, data) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/recommendations", data);
        },

        addRecommendationForProject: function (dispatchProjectCode, data) {
            return requests.post(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/recommendations", data);
        },

        addRecommendationForWorkOrder: function (workOrderId, data) {
            return requests.post(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/recommendations", data);
        },

        getRecommendations: function (status, filter, page) {
            return getRecommendationsFromUrl(apiHelper.getBaseUrl(), status, filter, page);
        },

        getRecommendationsForDispatch: function (dispatchId, status, filter, page, fromHistory) {
           return getRecommendationsFromUrl(apiHelper.getDispatchBaseUrl(dispatchId), status, filter, page, fromHistory);
        },

        getRecommendationsForProject: function (dispatchProjectCode, status, filter, page) {
            return getRecommendationsFromUrl(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode), status, filter, page);
        },

        getRecommendationsForWorkOrder: function (workOrderId, status, filter, page) {
            return getRecommendationsFromUrl(apiHelper.getWorkOrderBaseUrl(workOrderId), status, filter, page);
        },

        getRecommendation: function (id) {
            return requests.getJson(apiHelper.getBaseUrl() + "/recommendations/" + id);
        }
    };

    return exports;
});
