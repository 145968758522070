// GENERATED FILE - DO NOT MODIFY
import { ClientLookupModel } from "api/models/company/client/client-lookup-model";
import { ClientModel } from "api/models/company/client/client-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ClientProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetClientsLookup(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ClientLookupModel[] | null> {
        const uri = this.buildUri(
            "/clients/lookup",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ClientLookupModel[] | null = await this.get<ClientLookupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientLookupModel");
        return data;
    }

    public async GetClientLookup(clientId: string | null, requestConfig?: HttpClientRequestConfig): Promise<ClientLookupModel | null> {
        const uri = this.buildUri(
            "/clients/{clientId}/lookup",
            { clientId: (clientId === null || clientId === undefined) ? undefined : clientId },
            null
        );

        const data: ClientLookupModel | null = await this.get<ClientLookupModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientLookupModel");
        return data;
    }

    public async GetClientForDispatch(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<ClientModel | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/client",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: ClientModel | null = await this.get<ClientModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }

    public async GetClientLookupForDispatch(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<ClientLookupModel | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/client/lookup",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: ClientLookupModel | null = await this.get<ClientLookupModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientLookupModel");
        return data;
    }

    public async GetClientForWorkOrder(workOrderId: string | null, requestConfig?: HttpClientRequestConfig): Promise<ClientModel | null> {
        const uri = this.buildUri(
            "/workorders/{workOrderId}/client",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            null
        );

        const data: ClientModel | null = await this.get<ClientModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }

    public async GetClientLookupForWorkOrder(workOrderId: string | null, requestConfig?: HttpClientRequestConfig): Promise<ClientModel | null> {
        const uri = this.buildUri(
            "/workorders/{workOrderId}/client/lookup",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            null
        );

        const data: ClientModel | null = await this.get<ClientModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }

    public async GetClients(includeInactive: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ClientModel[] | null> {
        const uri = this.buildUri(
            "/clients",
            null,
            { includeInactive: (includeInactive === null || includeInactive === undefined) ? undefined : includeInactive.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ClientModel[] | null = await this.get<ClientModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }

    public async GetClientFromProject(projectId: string | null, requestConfig?: HttpClientRequestConfig): Promise<ClientModel | null> {
        const uri = this.buildUri(
            "/project/client",
            null,
            { projectId: (projectId === null || projectId === undefined) ? undefined : projectId }
        );

        const data: ClientModel | null = await this.get<ClientModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }

    public async GetClientFromContract(contractId: string | null, requestConfig?: HttpClientRequestConfig): Promise<ClientModel | null> {
        const uri = this.buildUri(
            "/contract/client",
            null,
            { contractId: (contractId === null || contractId === undefined) ? undefined : contractId }
        );

        const data: ClientModel | null = await this.get<ClientModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }

    public async GetClient(clientId: string | null, requestConfig?: HttpClientRequestConfig): Promise<ClientModel | null> {
        const uri = this.buildUri(
            "/clients/{clientId}",
            { clientId: (clientId === null || clientId === undefined) ? undefined : clientId },
            null
        );

        const data: ClientModel | null = await this.get<ClientModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Client.ClientModel");
        return data;
    }
}
