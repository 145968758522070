import { inject, singleton } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";

import UserOptionsProxy from "api/proxies/user-options-proxy-old";
import Assert from "core/assert";
import { default as settingHelper } from "helpers/settingHelper";
import ApplicationContextRepository from "repositories/application-context-repository";

@singleton()
@inject(EventAggregator, ApplicationContextRepository, UserOptionsProxy)
export default class UserOptionsManager {
    _interval;

    constructor(eventAggregator, applicationContextRepository, userOptionsProxy) {
        this.eventAggregator = eventAggregator;
        this.applicationContextRepository = applicationContextRepository;
        this.userOptionsProxy = userOptionsProxy;
    }

    async initialize() {
        // TODO Lazy load this class once the last KO view using it has been converted.
        await this.refreshAppContext();
        this.initializePolling();
    }

    async get() {
        let userOptions = this.applicationContextRepository.userOptions;

        if (!userOptions) {
            this.refreshAppContext();
            userOptions = this.applicationContextRepository.userOptions;
        }

        return userOptions;
    }

    getSync() {
        const userOptions = this.applicationContextRepository.userOptions;
        Assert.isTrue(userOptions !== undefined);
        return userOptions;
    }

    initializePolling() {
        Assert.isTrue(this._interval === undefined, "UserOptionsManager is trying to start multiple interval!");
        const timeoutDelay = 15 * 60 * 1000; // 15 minutes
        this._interval = window.setInterval(async () => await this.refreshAppContext(), timeoutDelay);
    }

    async refreshAppContext() {
        let value = null;

        if (settingHelper.hasSettings()) {
            value = await this.userOptionsProxy.get();
        }

        this.applicationContextRepository.userOptions = value;
        this.eventAggregator.publish("user-options:changed", value);
    }
}
