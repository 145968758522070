import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";

@autoinject()
export class QuotationDetails {
    @bindable.none public Quotation!: ServiceCallQuotationDetailsModel;

    @bindable.string
    public specifiedLanguage: string | undefined = undefined;
}
