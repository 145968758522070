import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import { WorkOrderWorkTaskModel } from "api/models/company/workorder/work-order-work-task-model";
import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { DailyEntryWorkTaskProxy } from "api/proxies/daily-entry-work-task-proxy";

@autoinject()
export class DailyEntryWorkTaskService  {
    constructor(private readonly dailyEntryWorkTaskProxy: DailyEntryWorkTaskProxy) {
    }

    public async GetWorkTasks(projectDispatchCode: string | null, dispatchDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskModel[] | null> {
        return this.dailyEntryWorkTaskProxy.GetWorkTasks(projectDispatchCode, dispatchDate, filter, pagingInfo, requestConfig);
    }

    public async GetNewWorkTask(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskDetailsModel | null> {
        return this.dailyEntryWorkTaskProxy.GetNewWorkTask(projectDispatchCode, dispatchDate, requestConfig);
    }

    public async DeleteWorkTask(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.dailyEntryWorkTaskProxy.DeleteWorkTask(transactionNumber, requestConfig);
    }

    public async GetWorkTask(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<WorkOrderWorkTaskDetailsModel | null> {
        return this.dailyEntryWorkTaskProxy.GetWorkTask(transactionNumber, requestConfig);
    }

    public async SaveWorkTask(projectDispatchCode: string | null, dispatchDate: Date, workTask: WorkOrderWorkTaskDetailsModel | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        return this.dailyEntryWorkTaskProxy.SaveWorkTask(projectDispatchCode, dispatchDate, workTask, requestConfig);
    }
}
