import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as apiHelper } from "helpers/apiHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as settingHelper } from "helpers/settingHelper";

import { default as templateService } from "services/templateService";
import { default as materialRequisitionService } from "services/materialRequisitionService";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";
import serviceService from "services/serviceService";
import { default as enumHelper } from "helpers/enumHelper";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@inject(I18N, NotificationHelper, Router)
export default class MaterialRequisitionItem extends FormBase {
    workOrderId;
    lineNo = '';
    requisitionDate = null;
    requisitionId = '';
    showNotInCatalog = false;
    readonly = false;
    isDirty = false;

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        return this.isDirty;
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            this.notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"), "");
            return new Redirect("Settings");
        }
        return true;
    }

    activate(params) {

        if (params.q) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            if (paramsQs && paramsQs.readonly !== "undefined" && paramsQs.readonly) {
                this.readonly = queryStringHelper.parseReadonly(params.q);
            }
        }

        this.bindViewModel(decodeURIComponent(params.workOrderId), params.requisitionId, dateHelper.dateFromNavigationParameter(params.requisitionDate), params.lineNo);

        return templateService.getUserTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialFieldService;
        });
    }

    setSpecificFields(item) {
        item.WorkOrderId = this.workOrderId;
        return item;
    }

    bindViewModel(workOrderId, requisitionId, requisitionDate, lineNo) {
        this.catalogBaseUrl = apiHelper.getBaseUrl() + "/catalog/fieldservices/materialrequisitions?dispatchTemplateId=" + settingHelper.getSelectedDispatchModel();
        this.workOrderId = workOrderId;
        this.requisitionDate = requisitionDate;
        this.requisitionId = requisitionId;

        this.lineNo = lineNo;

        this.actions = {
            setSpecificFields: item => this.setSpecificFields(item),
            saveMaterialRequisitionItem: (requisitionId, lineNo, item) => materialRequisitionService.setMaterialRequisitionItemForFieldService(this.workOrderId, requisitionId, lineNo, item),
            getActivities: () => serviceService.getWorkorderActivitiesByTypes(this.workOrderId, enumHelper.workOrderActivityType().MATERIAL)
        };
    }
}