import val from "core/val";
import { autoinject, bindable } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

@autoinject()
export class InventorySiteTransferMemo extends FormBase {
    public val: any = val;
    public unModified: InventorySiteTransferDetailsModel | null = null;

    @bindable public transfer!: InventorySiteTransferDetailsModel | null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private routeRepository: RouteRepository, private readonly inventorySiteTransferService: InventorySiteTransferService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(this.regExTakeOnlyNoneAlphanumeric, "");
        const stringifyCurrent = JSON.stringify(this.transfer).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: any): Promise<void> {
        this.transfer = await this.inventorySiteTransferService.GetTransfer(params.transferId);
        this.unModified = CloneHelper.deepClone(this.transfer);
    }

    public async save(): Promise<void> {
        await this.inventorySiteTransferService.SaveTransfer(this.transfer!).then(() => {
            this.unModified = null;
            routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Info.name,  {transferId : this.transfer!.Id});
        });
    }
}
