import { TradeService } from "./../../../services/trade-service";
import { BonusService } from "./../../../services/bonus-service";
import { RouterHelper } from "helpers/router-helper";
import { SettingRepository } from "repositories/setting-repository";
import { ApiHelper } from "helpers/api-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { QuotationItemBonusBase } from "./item-bonus-base";
import { I18N } from "aurelia-i18n";
import { ValidationController } from "aurelia-validation";
import { autoinject } from "aurelia-framework";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { ServiceCallOccupationCategoryService } from "services/service-call-occupation-category-service";

export interface QuotationItemBonusParameters {
    quotationId: number;
    type: ServiceCallQuotationItemType;
    itemId?: number;
}

@autoinject()
export class QuotationItemBonus extends QuotationItemBonusBase {

    constructor(
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        bonusService: BonusService,
        tradeService: TradeService,
        serviceCallOccupationCategoryService: ServiceCallOccupationCategoryService,
        private readonly routerHelper: RouterHelper

    ) {
        super(i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, validationHelper, validationController, bonusService, tradeService, serviceCallOccupationCategoryService);
    }

    public async activate(params: QuotationItemBonusParameters): Promise<void> {
        await Promise.all([
            this.initQuotation(params.quotationId),
            this.initQuotationItem(params.quotationId, params.itemId)
        ]);
    }

    public async save(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        await this.serviceCallQuotationService.saveQuotationItem(this.quotationItem);
        this.routerHelper.navigateBack();
    }

    private async initQuotationItem(quotationId: number, itemId: number | undefined): Promise<void> {
        if (itemId !== null && itemId !== undefined) {
            this.quotationItem = await this.serviceCallQuotationService.getQuotationItem(itemId);
        } else {
            this.quotationItem = this.serviceCallQuotationService.createNewQuotationItem(quotationId, ServiceCallQuotationItemType.Bonus);
        }

        this.initValidation();
    }
}
