import { LocalizationHelper } from "helpers/localization-helper";
import Parse from "helpers/parse";
import { autoinject } from "aurelia-framework";

interface BaseNumberFormatConfig {
    locale?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

interface CurrencyFormatConfig extends BaseNumberFormatConfig {
    currency?: string;
}

abstract class BaseNumberFormatValueConverter {
    constructor(protected readonly localizationHelper: LocalizationHelper) {}

    protected format(value: any, formatter: Intl.NumberFormat): string {
        const valueAsNumber = Parse.NullableDecimal(value);
        if (valueAsNumber === null) {
            return "";
        }

        return formatter.format(valueAsNumber);
    }

    protected getLocale(config?: BaseNumberFormatConfig): string {
        if (config && config.locale) {
            return this.localizationHelper.getCultureCode(config.locale);
        }

        return this.localizationHelper.getCultureCode();
    }
}

@autoinject()
export class NumberFormatValueConverter extends BaseNumberFormatValueConverter {
    constructor(localizationHelper: LocalizationHelper) {
        super(localizationHelper);
    }

    public toView(value: any, config?: BaseNumberFormatConfig): string {
        const formatter = new Intl.NumberFormat(this.getLocale(config), {
            style: "decimal",
            minimumFractionDigits: config ? config.minimumFractionDigits : undefined,
            maximumFractionDigits: config ? config.maximumFractionDigits : undefined,
        });

        return this.format(value, formatter);
    }
}

@autoinject()
export class CurrencyFormatValueConverter extends BaseNumberFormatValueConverter {
    constructor(localizationHelper: LocalizationHelper) {
        super(localizationHelper);
    }

    public toView(value: any, config?: CurrencyFormatConfig): string {
        const currency = (config ? config.currency : undefined) || "CAD";

        const formatter = new Intl.NumberFormat(this.getLocale(config), {
            style: "currency",
            currency: currency,
            minimumFractionDigits: config ? config.minimumFractionDigits : undefined,
            maximumFractionDigits: config ? config.maximumFractionDigits : undefined,
        });

        return this.format(value, formatter);
    }
}

@autoinject()
export class PercentFormatValueConverter extends BaseNumberFormatValueConverter {
    constructor(localizationHelper: LocalizationHelper) {
        super(localizationHelper);
    }

    public toView(value: any, config?: BaseNumberFormatConfig): string {
        const formatter = new Intl.NumberFormat(this.getLocale(config), {
            style: "percent",
            minimumFractionDigits: config ? config.minimumFractionDigits : undefined,
            maximumFractionDigits: config ? config.maximumFractionDigits : undefined,
        });

        return this.format(value, formatter);
    }
}
