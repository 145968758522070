import { inject } from "aurelia-framework";

import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as equipmentCounterService } from "services/equipmentCounterService";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

@inject(I18N, NotificationHelper, Router)
export class EquipmentCounters extends FormBase {
    readonly = false;
    dispatchProjectCode = null;    
    dailyEntryDate = null;    
    unModified = null;

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
    }

    checkDirty() {
        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");

        const stringifyCurrent = JSON.stringify(this.equipmentList).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    activate(params) {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        return this.loadData();
    }

    bindViewModel(dispatchProjectCode, dailyEntryDate, qs) {
        this.readonly = queryStringHelper.parseReadonly(qs);
        this.dispatchProjectCode = dispatchProjectCode;    
        this.dailyEntryDate = dailyEntryDate;    
    }

    async loadData(){
        this.equipmentList = await equipmentCounterService.getForDailyEntry(this.dispatchProjectCode, this.dailyEntryDate);
        this.unModified = CloneHelper.deepClone(this.equipmentList);
    }

    async save() {
        this.unModified = null;
        await equipmentCounterService.saveForDailyEntry(this.dispatchProjectCode, this.dailyEntryDate, this.equipmentList);
        routerHelper.navigateBack();
    }
}
