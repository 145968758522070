import { KeyValuePair } from "interfaces/key-value-pair";
import { ProcoreGridColumnEnum } from "../procore-grid-column-enum";
import { ProcoreGridConfigurations } from "../procore-grid-configurations";
import { IColumnsConfigurator } from "./columns-configurator";

export abstract class BaseColumnsConfigurator implements IColumnsConfigurator {
    public abstract disabledColumns: ProcoreGridColumnEnum[];
    public abstract enabledColumns: ProcoreGridColumnEnum[];
    public abstract headerChanges: Array<KeyValuePair<ProcoreGridColumnEnum, string>>;
    public warningFooter?: string;
    public ShowCheckboxOnSuccess: boolean = false;

    constructor(protected readonly gridConfiguration: ProcoreGridConfigurations) { }

    public enableColumns(): void {
        this.enabledColumns.forEach((column: ProcoreGridColumnEnum) => this.gridConfiguration.EnableColumn(column));
    }

    public disableColumns(): void {
        this.disabledColumns.forEach((column: ProcoreGridColumnEnum) => this.gridConfiguration.DisableColumn(column));
    }

    public changeHeaders(): void {
        this.headerChanges.forEach((headerChange: KeyValuePair<ProcoreGridColumnEnum, string>): void => {
            this.gridConfiguration.ChangeHeader(headerChange.key, headerChange.value);
        });
    }

    public setCommonProperties(): void {
        this.gridConfiguration.WarningFooter = this.warningFooter;
        this.gridConfiguration.ShowCheckboxOnSuccess = this.ShowCheckboxOnSuccess;
    }
}
