import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";

import dispatchService from "services/dispatchService";

@autoinject
export class DetailRefuse {

    public serviceType: string = "";
    public dispatchId: string = "";
    public reason: string = "";
    public enumPresence: typeof enumHelper.servicePresence = enumHelper.servicePresence;
    public detail: any;

    constructor(private readonly i18n: I18N) {
    }

    public async activate(params: any): Promise<void> {
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;
        this.reason = "";
    }

    public canAcceptOrRefuse(): boolean {
        if (!this.detail) {
            return false;
        }
        const pres = this.detail.Presence;
        return (pres === this.enumPresence().NEW.id || pres === this.enumPresence().ACCEPTED.id || pres === this.enumPresence().DECLINED.id) && !this.detail.IsReadonly;
    }

    public async save(): Promise<void> {
        if (this.isReasonValid()) {
            routerHelper.showLoading();
            await dispatchService.setServiceCallRefuse(this.dispatchId, this.reason).then((result: any) => {
                routerHelper.hideLoading();
                routerHelper.navigateBack();
            });
        } else {
            notificationHelper.showValidationError([this.i18n.tr("err_ReasonRequired")]);
        }
    }

    private isReasonValid(): boolean {
        return this.reason !== "";
    }
}
