define([], function() {
    "use strict";

    function getWebServiceBaseUrl(suffix) {
        var addr = "//" + window.location.host + "/" + suffix;
        return addr;
    }

    function getBaseUrl() {
        var hashIndex = window.location.href.indexOf("/#");
        if (hashIndex === -1) {
            return window.location.href;
        }
        return window.location.href.substr(0, hashIndex + 2);
    }

    function getBaseUrlWithDash() {
        return this.getBaseUrl() + "/";
    }

    function getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    return {
        getWebServiceBaseUrl: getWebServiceBaseUrl.bind(this, "MaestroMobileApi"),
        getLegacyServiceBaseUrl: getWebServiceBaseUrl.bind(this, "WebApiSecure"),
        getParameterByName: getParameterByName,
        getBaseUrl: getBaseUrl,
        getBaseUrlWithDash: getBaseUrlWithDash
    };
});
