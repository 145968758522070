
import { autoinject, computedFrom } from "aurelia-framework";

import { I18N } from "aurelia-i18n";
import { AgGridHelper } from "helpers/ag-grid-helper";

import { NotificationHelper } from "helpers/notification-helper";
import { RouteConfig } from "aurelia-router";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { MaestroBiService } from "services/maestro-bi-service";
import { MaestroBiReportZipFileModel } from "api/models/company/maestro-bi/maestro-bi-report-zip-file-model";
import { MaestroBiReportBlocDisplayModel } from "api/models/company/maestro-bi/maestro-bi-report-bloc-display-model";
import { exception } from "console";

@autoinject()
export class MaestroBiExport {

    public reportBlocDisplay: MaestroBiReportBlocDisplayModel | null = null;

    private iconMatch: RegExp = /^({Folder}|{Report}|{DashBoard})/;
    private reportGrid: HTMLElement | null = null;
    private isLoading: boolean = true;
    private currentCulture: string = "en"; // default English
    private iconTypeToFontAwesome: any  = {};

    @computedFrom("reportBlocDisplay")
    public get isSelectedReport(): boolean {
        return !!this.reportBlocDisplay;
    }

    constructor(
        private readonly agGridHelper: AgGridHelper,
        private readonly i18n: I18N,
        private readonly notificationHelper: NotificationHelper,
        private readonly routeRepository: RouteRepository, // TODO : pouvoir naviguer vers Maestro*BI par cette page.
        private readonly maestroBiService: MaestroBiService) {
            this.currentCulture = this.i18n.getLocale();

            this.iconTypeToFontAwesome["{Folder}"] = " ";
            this.iconTypeToFontAwesome["{Report}"] = "<i class=\"fa fa-file-text\"></i>";
            this.iconTypeToFontAwesome["{DashBoard}"] = "<i class=\"fa fa-tachometer\"></i>";
    }

    public async bind(): Promise<void> {

        const autoGroupColumnDef = {
            width: 750,
            headerName: this.i18n.tr("MaestroBI_Export_TreeView_Title"),
            cellRendererParams: {suppressCount: true},
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isInverted: any): number => this.customGridSort(valueA, valueB, nodeA, nodeB, isInverted),
            sort: "asc",
        };

        const gridOptions: any = {
            treeData: true, // enable Tree Data mode
            columnDefs: [],
            autoGroupColumnDef: autoGroupColumnDef,
            onGridReady: this.onGridReady.bind(this),
            getDataPath: (data: any): string[] => this.getTreeDataPath(data),
            rowSelection: "single",
            onSelectionChanged: this.onSelected.bind(this),
            groupDefaultExpanded: -1, // expand all groups by default
            toolPanelSuppressSideButtons : true,
        };

        await this.agGridHelper.createGrid(this.reportGrid, gridOptions);
    }

    public attached(): void {
        window.addEventListener("resize", (ev: UIEvent): void => { this.resizeTreeView(); });
        this.resizeTreeView();
    }

    public onGridReady(params: any): any {
        this.getRowData().then((x: any): void => {
            params.api.setRowData(x);
            this.isLoading = false;
        });
    }

    public onSelected(gridOptions: any): void {
        this.isLoading = true;
        const selectedRow = gridOptions.api.getSelectedRows()[0];

        this.getReportBlocDisplay(selectedRow.ReportId)
        .then((value: any): void => {
            this.reportBlocDisplay = value;
            this.processReportBlocDisplayForLanguage();
            this.isLoading = false;
        },
        (error: any): void => {
            this.reportBlocDisplay = null;
            this.isLoading = false;
            throw error;
        });
    }

    public exportReport(reportId: string): void {
        this.isLoading = true;
        this.maestroBiService.ExportReportById(reportId)
        .then((result: MaestroBiReportZipFileModel): void => {
            if (result == null || result.ZipFileContent == null) {
                alert(this.i18n.tr("MaestroBI_ExportReport_Unsuccessful"));
                this.isLoading = false;
                return;
            }

            const data: Uint8Array = new Uint8Array(result.ZipFileContent.split("-").map((x: string): number => parseInt(x, 16)));
            const blob = new Blob([data], {type: "application/zip, application/octet-stream"});
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = (this.i18n.getLocale() === "fr" ? result.FileNameFr : result.FileNameEn) + ".mbi";
            link.click();

            this.isLoading = false;
        });
    }

    public async getRowData(): Promise<any> {
        return await this.maestroBiService.FetchReportHierarchy();
    }

    private async getReportBlocDisplay(reportId: string): Promise<MaestroBiReportBlocDisplayModel | null> {
        return await this.maestroBiService.FetchReportById(reportId);
    }

    private resizeTreeView(): void {
        const treeViewHeightOffset = 60;
        const treeViewElement = $(".maestro-bi-treeview")[0];
        treeViewElement.style.height = (window.innerHeight - treeViewHeightOffset) + "px";
    }

    private processReportBlocDisplayForLanguage(): void {
        if (this.reportBlocDisplay != null) {
            this.reportBlocDisplay.ReportType = this.i18n.tr("MaestroBI_ReportType_" + this.reportBlocDisplay.ReportType);

            this.reportBlocDisplay.ReportPath = this.reportBlocDisplay.ReportPath[this.currentCulture];
            this.reportBlocDisplay.ReportName = this.reportBlocDisplay.ReportName[this.currentCulture];
        }
    }

    private getTreeDataPath(data: any): string[] {
        let treeDisplay: string[] = this.currentCulture === "en" ? data.HierarchyPath.En : data.HierarchyPath.Fr;
        treeDisplay = treeDisplay.map((x: string): string => this.getIconType(x));

        return treeDisplay;
    }

    private getIconType(value: string): string {
        const matchedValue = value.match(this.iconMatch);
        if (matchedValue) {
            const matchedKey = matchedValue[0];
            if (matchedKey in this.iconTypeToFontAwesome) {
                return value.replace(matchedKey, this.iconTypeToFontAwesome[matchedKey]);
            }
        }
        return "Icon not found -> " + value;
    }

    private customGridSort(valueA: string, valueB: string, nodeA: any, nodeB: any, isInverted: any): number {
        const parsedA = this.parseCompareNode(nodeA);
        const parsedB = this.parseCompareNode(nodeB);

        if (parsedA.isFolder && !parsedB.isFolder) {
            return -1;
        }
        if (!parsedA.isFolder && parsedB.isFolder) {
            return 1;
        }
        return Intl.Collator().compare(parsedA.value, parsedB.value);
    }

    private parseCompareNode(node: any): any {
        const nodeLang = this.currentCulture === "en" ? node.data.HierarchyPath.En : node.data.HierarchyPath.Fr;
        const nodeValue = nodeLang[nodeLang.length - 1];
        const matchedValue = nodeValue.match(this.iconMatch);
        const isFolder = matchedValue ? matchedValue[0] === "{Folder}" : false;
        const compareValue = matchedValue ? nodeValue.substring(matchedValue[0].length, nodeValue.length) : nodeValue;

        return {
            isFolder: isFolder,
            value: compareValue.trim().toLowerCase()
        };
    }
}
