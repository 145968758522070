define([
    "services/requests",
    "helpers/apiHelper",
    "jquery"
], function (requests, apiHelper, jQuery) {
    "use strict";

    var exports = {
        getForWorkOrder: function (workOrderId, lineNo, billing) {
            return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + '/materials/' + lineNo + "?billing=" + billing);
        },

        listForWorkOrder: function (workOrderId) {
            return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + '/materials');
        },

        setForWorkOrder: function (workOrderId, data) {
            return requests.post(apiHelper.getWorkOrderBaseUrl(workOrderId) + '/materials/', data);
        },

        deleteForWorkOrder: function (workOrderId, lineNo, billing, timestamp) {
            return requests.remove(apiHelper.getWorkOrderBaseUrl(workOrderId) + '/materials/' + lineNo + "?billing=" + billing + (timestamp ? "&materialTimestamp=" + encodeURIComponent(timestamp) : ""));
        },

        listForProjectDailyEntry: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/materials');
        },

        deleteForProjectDailyEntry: function (dispatchProjectCode, dispatchDate, lineNo, billing, timestamp) {
            return requests.remove(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/materials/' + lineNo + "?materialTimestamp=" + encodeURIComponent(timestamp));
        },

        getForProjectDailyEntry: function (dispatchProjectCode, dispatchDate, lineNo) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/materials/' + lineNo);
        },

        setForProjectDailyEntry: function (dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/materials/', data);
        },

        setReservedMaterials: function (dispatchProjectCode, dispatchDate) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) +  "/materials/reserved");
        },

        getIsReservedMaterials: function (workOrderId) {
            return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + '/materials/options');
        },

        setWorkOrderReservedMaterials: function (workOrderId) {
            return requests.post(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/materials/reserved");
        }
    };

    return exports;
});