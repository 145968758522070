
import { autoinject } from "aurelia-framework";

import RouteRepository from "repositories/routeRepository";
import { DailyEntryWorkTaskService } from "services/daily-entry-work-task-service";
import { RouteInfo } from "components/others/ma-work-task-index";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

@autoinject
export class DailyEntryWorkTaskIndex {
    public dispatchDate: any;
    public dispatchProjectCode: string = "";

    public getWorkTask!: () => Promise<any>;
    public addRouteInfo: RouteInfo = { routeName: "", routeParams: {}};
    public editRouteInfo: RouteInfo = { routeName: "", routeParams: {}};
    public readonly: boolean = false;
    
    constructor(private routeRepository: RouteRepository, private readonly dailyEntryWorkTaskService: DailyEntryWorkTaskService) {}

    public async activate(params: any): Promise<void> {
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;

        const paramsQs = routerHelper.getQuerystring(params.q);
        
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        this.getWorkTask = (await this.dailyEntryWorkTaskService.GetWorkTasks.bind(this.dailyEntryWorkTaskService, this.dispatchProjectCode, this.dispatchDate));
        this.addRouteInfo.routeName = this.routeRepository.routes.Project_Detail_Daily_WorkTask_Add.name;
        this.addRouteInfo.routeParams = { dispatchProjectCode: this.dispatchProjectCode, dailyEntryDate: this.dispatchDate};

        this.editRouteInfo.routeName = this.routeRepository.routes.Project_Detail_Daily_WorkTask_Edit.name;
        this.editRouteInfo.routeParams = { dispatchProjectCode: this.dispatchProjectCode, dailyEntryDate: this.dispatchDate, readonly: this.readonly };
    }
}
