import { autoinject } from "aurelia-framework";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";

import additionalFieldsService from "services/additionalFieldsService";
import routerHelper from "helpers/routerHelper";

import { I18N } from "aurelia-i18n";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class ProjectMaterialOrderAdditonnalFields extends FormBase {
    public actions: any = {};
    public dispatchProjectCode: number = 0;
    public orderId: string = "";
    public readonly: boolean = true;
    public extension: string = "";
    public additionalFieldsSource: number = AdditionalFieldsSourceType.PurchaseOrder;
    public saveAdditionalFieldsButton: boolean = false;
    public currentFields: any = null;
    public unModifiedFields: any = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly additionalFieldMapper: AdditionalFieldMapper) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.additionalFieldMapper.isNotEqual(this.unModifiedFields, this.currentFields);
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.orderId, params.q);
        this.bindWidget();
    }

    private bindViewModel(dispatchProjectCode: number, orderId: string, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");
        this.extension = params.extension;

        this.saveAdditionalFieldsButton = !this.readonly;
        this.dispatchProjectCode = dispatchProjectCode;
        this.orderId = orderId;
    }

    private bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForPurchaseOrder.bind(this, this.orderId, this.extension),
            setAll: additionalFieldsService.setAllForPurchaseOrder.bind(this, this.orderId, this.extension)
        };
    }
}
