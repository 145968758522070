// GENERATED FILE - DO NOT MODIFY
export enum AdditionalFieldType {
    Text = 0,
    Date = 1,
    Time = 2,
    Amount = 3,
    NumberWithDecimals = 4,
    ShortInteger = 5,
    LongInteger = 6,
    Percentage = 7,
    Phone = 8,
    PostalCode = 9,
    Sin = 10,
    CheckBox = 11,
    SeparatorOnly = 12,
    Memo = 13,
    InternetLink = 14,
    ComboBox = 16,
    Timestamp = 17,
    LinkedComboBox = 18,
    LinkedGrid = 95,
    Grid = 96,
}

export let nameof = "AdditionalFieldType";
