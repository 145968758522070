import val from "core/val";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";

import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import Parse from "helpers/parse";
import { NotificationHelper } from "helpers/notification-helper";
import { I18N } from "aurelia-i18n";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class QuotationInternalNotes {
    public val: any = val;

    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public readonly: boolean = false;
    public originalInternalNotes: string | null = "";

    constructor(private readonly serviceCallQuotationService: ServiceCallQuotationService, private readonly notificationHelper: NotificationHelper, private readonly i18N: I18N, private readonly router: Router) {}

    public async activate(params: any): Promise<void> {
        this.readonly = Parse.Boolean(params.readonly);
        await this.initQuotation(params.quotationId);
    }

    public async canDeactivate(): Promise<any> {
        if (this.originalInternalNotes !== this.quotation!.InternalNotes) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18N.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }

        return true;
    }

    public async save(): Promise<void> {
        if (!this.quotation) { return; }

        await this.serviceCallQuotationService.saveQuotation(this.quotation, true, true).then(() => {
            this.originalInternalNotes = this.quotation!.InternalNotes;
            routerHelper.navigateBack();
        });
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, true, true, true, true, false);
        this.originalInternalNotes = this.quotation.InternalNotes;
    }
}
