import { ServiceCallService } from "services/service-call-service";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable, observable, PLATFORM } from "aurelia-framework";

import Parse from "helpers/parse";
import routerHelper from "helpers/routerHelper";
import enumHelper from "helpers/enumHelper";

import { NotificationHelper } from "helpers/notification-helper";
import { CatalogItemManagementMode } from "api/enums/catalog-item-management-mode";

import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { InventorySiteTransferItemModel } from "api/models/company/inventory-site-transfer-item-model";
import { InventorySiteTransferItemResponseModel } from "api/models/company/inventory-site-transfer-item-response-model";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";

import UserAccessService from "services/user-access-service";
import { CatalogService } from "services/catalog-service";
import { CatalogInventoryService } from "services/catalog-inventory-service";
import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { SiteService } from "services/site-service";

import { InventoryTransferSellingQtyValidation } from "api/enums/inventory-transfer-selling-qty-validation";
import { InventoryTransferQtyValidation } from "api/enums/inventory-transfer-qty-validation";
import { ServiceCallLookupModel } from "api/models/company/service-call-lookup-model";
import { default as apiHelper } from "helpers/apiHelper";
import { default as val } from "core/val";
import { CatalogItemStatus } from "api/enums/catalog-item-status";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { SiteLocationModel } from "api/models/company/site-location-model";

import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { default as dateHelper } from "helpers/dateHelper";
import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";

@autoinject()
export class AddNewReservedMaterial extends FormBase {

    @bindable public transferId: number | null = null;
    @bindable public transfer: InventorySiteTransferDetailsModel | null = null;
    @bindable public transferItem: InventorySiteTransferItemModel | null = null;
    @bindable public sourceSitesData: any[] | null = [];
    @bindable public selectedSourceSite: any;
    public unModified: InventorySiteTransferDetailsModel | null = null;

    @bindable public isActive: boolean = true;
    @bindable public isNewItem: boolean = true;
    @bindable public isItemSelected: boolean = false;
    @bindable public isUnitSelected: boolean = false;
    @bindable public isSourceSiteSelected: boolean = false;

    @bindable public selectedItem: any;
    @bindable public selectedUnit: any;
    @bindable public selectedCallNumber: any;

    @bindable public inventoryQty: number = 0;
    @bindable public availableQty: number = 0;

    @bindable public editItemCard: MaCardOptionsModel | null = null;

    @bindable public unitResultTemplate: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/inventoryTransfer_unit_result.html");

    @observable public inventoryScaleFactor: number;

    @observable public newDestinationLocation: string = "";

    @observable public selectedDestinationLocation: any = null;
    @observable public selectedDestinationLocationDescription: any = null;

    public selectedItemAvailableQty: number = 0;
    public isValidationModeBlocking: boolean = false;
    public isSellingQtyValidationEnabled: boolean = false;
    public isItemReserved: boolean = false;
    public catalogBaseUrl: string = apiHelper.getBaseUrl() + "/catalog/inventory-transfer";
    public descriptionMaxLength: number = val.get("material.description", "maxLength");
    public destinationLocationMaxLength: number = val.get("catalog.location", "maxLength");
    public destinationLocations: any = [];

    public dispatchId: number = 0;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly inventorySiteTransferService: InventorySiteTransferService, private readonly catalogService: CatalogService, private readonly catalogInventoryService: CatalogInventoryService, private readonly serviceCallService: ServiceCallService, private readonly userAccessService: UserAccessService, private readonly siteService: SiteService) {
        super(notificationHelper, i18n, router);
        this.inventoryScaleFactor = catalogService.defaultScaleFactor;
    }

    public checkDirty(): boolean {
        if (!this.unModified) {
            return false;
        }

        if (!this.transfer) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        const stringifyCurrent = JSON.stringify(this.transfer).replace(this.regExTakeOnlyNoneAlphanumeric, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.dispatchId;
        this.isNewItem = true;
      
        this.transfer = await this.inventorySiteTransferService.NewTransfer();
        this.unModified = { SourceSiteId: null, SourceSiteDescription: null, TargetSiteId: null, TargetSiteDescription: null, Id: 0, AccountingDate: dateHelper.getDate(), ReferenceNo: null, SourcePrefix: null, SourceCompanyName: null, TargetPrefix: null, TargetCompanyName: null, Memo: null, Status: null, CurrencyCode: null, Items: null };
        this.sourceSitesData = await this.inventorySiteTransferService.GetSites(this.transfer!.SourcePrefix!);
        if (this.transfer!.SourceSiteId !== "") {
            this.selectedSourceSite = this.sourceSitesData!.find((x: any) => x.id === this.transfer!.SourceSiteId);
            this.isSourceSiteSelected = true;
        }
    }

    public get GetUnits(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.catalogInventoryService.GetCatalogInventoryItems(this.transferItem!.ProductCode!, this.transfer!.SourceSiteId ? this.transfer!.SourceSiteId! : "", this.transfer!.SourcePrefix ? this.transfer!.SourcePrefix! : "", true, true).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {

                        return failure(fail);
                    }
                );
            },
            mapResults: (item: CatalogInventoryModel): any => {
                return { id: item.Unit, text: item.Unit, data: item };
            },
        };
    }

    public selectedSourceSiteChanged(newValue: any, oldValue: any): void {
        if (newValue.id !== this.transfer!.SourceSiteId && this.transferItem) {
            this.clearTransactionItem();
        }

        this.transfer!.SourceSiteId = this.selectedSourceSite.id;
        this.selectedSourceSite = this.sourceSitesData!.find((x: any) => x.id === newValue.id);
        this.isSourceSiteSelected = true;
    }

    public async populateDestinationLocations(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<SiteLocationModel[] | null> {
        return await this.siteService.GetSiteLocations(this.transfer!.TargetSiteId ? this.transfer!.TargetSiteId! : "", this.transfer!.TargetPrefix ? this.transfer!.TargetPrefix! : "", null, true,
            filter,
            pagingInfo,
            requestConfig
        );
    }

    public async selectedItemChanged(catalogItem: any): Promise<void> {
        if (!catalogItem) {
            return;
        }

        const transfer = (await this.inventorySiteTransferService.NewReservationItem(
            this.dispatchId,
            this.transfer!,
            catalogItem.id!
        ))!;

        this.transferItem = transfer.Item!;

        this.transfer!.TargetPrefix = this.transferItem.TargetPrefix;
        this.transfer!.TargetSiteId = this.transferItem.TargetSite;

        this.feedValidationProperties(transfer);

        this.selectedItemAvailableQty = this.transferItem.AvailableQty;
      
        this.isItemSelected = true;
        this.inventoryScaleFactor = this.transferItem.InventoryScaleFactor;
        this.clearUnitSelection();
    }

    public async onUnitSelected(catalogUnit: CatalogInventoryModel): Promise<void> {
        this.transferItem = (await this.inventorySiteTransferService.UpdateReservationFromCatalogInventoryItem({ Item: this.transferItem, CatalogInventoryItem: catalogUnit, InventorySiteTransfer: this.transfer }))!;
        this.inventoryQty = this.transferItem.InventoryQty;
        this.availableQty = this.transferItem.AvailableQty;
        this.isUnitSelected = true;
        this.genereteCard();
    }

    public async loadDestinationLocations(destinationLocation: string): Promise<SiteLocationModel[] | null> {
        return await this.siteService.GetSiteLocations(this.transfer!.TargetSiteId ? this.transfer!.TargetSiteId! : "", this.transfer!.TargetPrefix ? this.transfer!.TargetPrefix! : "", destinationLocation, false);
    }

    public clearUnitSelection(): void {
        this.transferItem!.Unit = 0;
        this.transferItem!.SourceLocation = null;
        this.transferItem!.Identification = null;

        this.availableQty = this.selectedItemAvailableQty;
        this.editItemCard = null;
        this.isUnitSelected = false;
    }

    public clearTransactionItem(): void {
        this.transferItem = null;
        this.isItemSelected = false;
        this.inventoryQty = 0;
        this.availableQty = 0;
        this.selectedItemAvailableQty = 0;
        this.inventoryScaleFactor = this.catalogService.defaultScaleFactor;

        this.availableQty = this.selectedItemAvailableQty;
        this.editItemCard = null;
        this.isUnitSelected = false;

        this.newDestinationLocation = "";
        this.selectedDestinationLocation = null;
        this.selectedDestinationLocationDescription = null;
        this.selectedItem = null;
    }

    public async save(): Promise<void> {
        this.transferItem!.InventoryQty = this.inventoryQty;

        this.transferItem!.TargetLocation = "";
      
        if (this.selectedDestinationLocation) {
            this.transferItem!.TargetLocation = this.selectedDestinationLocation;
        }

        if (this.newDestinationLocation) {
            this.transferItem!.TargetLocation = this.newDestinationLocation;
        }

        if (await this.isTransferValid()) {
            this.executeSave();
        }
    }

    public selectedDestinationLocationChanged(newValue: any, oldValue: any): void {
        if (newValue) {
            this.newDestinationLocation = "";
        }
    }

    public newDestinationLocationChanged(newValue: any, oldValue: any): void {
        if (newValue) {
            this.selectedDestinationLocation = null;
            this.selectedDestinationLocationDescription = null;
        }
    }

    private genereteCard(): void {
        this.editItemCard = new MaCardOptionsModel({
            model: this.transferItem,
            displayLines: [
                new MaCardLineModel({ display: `${this.i18n.tr("Unit:")} ${this.transferItem!.Unit}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("Location:")} ${this.transferItem!.SourceLocation}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("Characteristic:")} ${this.transferItem!.Characteristic}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("Identification:")} ${this.transferItem!.Identification}` }),
                new MaCardLineModel({ display: `${this.i18n.tr("ReservationNo:")} ${this.transferItem!.ReservationNo}` }),
            ],
            actionItems: [],
        });
        if (this.isActive) {
            this.editItemCard.actionItems!.push(new MaCardActionModel({ icon: "fa fa-trash text-danger pt-3", action: this.clearUnitSelection.bind(this) }));
        }
    }

    private async isTransferValid(): Promise<boolean> {
        const quantityAvailable = this.transferItem!.AvailableQty;
        const isQuantityValid = this.inventoryQty <= quantityAvailable;

        if (!this.transferItem) {
            return false;
        }

        if (this.inventoryQty <= 0) {
            this.notificationHelper.showError(this.i18n.tr("InventoryTransfer_warning_error_qty"));
            return false;
        }

        if (this.transferItem.ItemManagementMode === CatalogItemManagementMode.Serial) {
            if (!this.editItemCard) {
                this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_SerialNoItem_MustHaveInventorySelection"));
                return false;
            }
            if (this.inventoryQty > 1) {
                this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_SerialNoItem_ExceedingQuantity"));
                return false;
            }
        }

        if (this.isSellingQtyValidationEnabled && !isQuantityValid && this.transferItem.Status !== CatalogItemStatus.GeneralNoValidation) {
            if (this.isValidationModeBlocking) {
                this.notificationHelper.showError(this.i18n.tr("err_QuantityIsOverQuantityAvailable"));
                return false;
            }

            if (this.isSellingQtyValidationEnabled && !isQuantityValid && !this.isValidationModeBlocking) {
                const warningMsg = `${this.i18n.tr("err_QuantityIsOverQuantityAvailable")} ${this.i18n.tr("DoYouWantToSave")}`;
                if (!(await this.notificationHelper.showDialogYesNo(warningMsg))) {
                    return false;
                }
            }
        }

        return true;
    }

    private async executeSave(): Promise<void> {

        if (!await this.notificationHelper.showDialogYesNo(this.i18n.tr("Add_New_Reserved_Material_ConfirmComplete"))) {
            return;
        }

        if (!this.isActive) {
            return;
        }

        this.transfer!.Items = [this.transferItem!];

        await this.inventorySiteTransferService.SaveAndCompleteNewReservation(this.transfer!).then((success: any): any => {
            if (success) {
                this.unModified = null;
                routerHelper.navigateBack();
                return;
            }
        });
    }

    private feedValidationProperties(item: InventorySiteTransferItemResponseModel): void {
        this.isValidationModeBlocking = item.QtyValidationMode === InventoryTransferQtyValidation.Block;
        this.isSellingQtyValidationEnabled = item.SellingQtyValidation !== InventoryTransferSellingQtyValidation.NoValidation;
    }
}
