import { autoinject } from "aurelia-framework";
import phoneHelper from "helpers/phoneHelper";
import routerHelper from "helpers/routerHelper";
import projectService from "services/projectService";

@autoinject()
export default class ProjectInformation {
    public dispatchProjectCode: string = "";
    public information: any;
    public projectAddress: string = "";
    public stagingAddress: string = "";

    public phoneHelper: typeof phoneHelper;

    constructor() {
        this.phoneHelper = phoneHelper;
    }

    public loadData(): void {
        return projectService.getDetail(this.dispatchProjectCode).then((data: any) => {
            this.information = data;

            this.projectAddress = this.information.ProjectLocation.Address +
            (this.information.ProjectLocation.City ? ", " + this.information.ProjectLocation.City : "") +
            (this.information.ProjectLocation.Province ? ", " + this.information.ProjectLocation.Province : "") +
            (this.information.ProjectLocation.PostalCode ? ", " + this.information.ProjectLocation.PostalCode : "");

            this.stagingAddress = this.information.Address +
            (this.information.City ? ", " + this.information.City : "") +
            (this.information.Province ? ", " + this.information.Province : "") +
            (this.information.PostalCode ? ", " + this.information.PostalCode : "");
        });
    }
    //#endregion

    //#region Public Functions
    public activate(params: any): any {
        this.dispatchProjectCode = params.dispatchProjectCode;

        return this.loadData();
    }

    public openAddressMap(address: string): void {
        window.open(routerHelper.mapAddress(address));
    }
}
