import BaseCellEditor from "ag-grid/editors/baseCellEditor";

export default class TextBoxCellEditor extends BaseCellEditor  {
    constructor(){
        super();
    }

    // Gets called once before the renderer is used
    init(params) {
        super.init(params);

        this.inputElement = document.createElement("input");
        this.inputElement.type = "text";

        if (params.maxLength) {
            this.inputElement.setAttribute("maxlength", params.maxLength);
        }
        
        this.inputElement.value = params.value;
        this.inputElement.addEventListener("keydown", (event) => this.onKeyDown(event));
    }

     // when we tab onto this editor, we want to focus the contents
    focusIn() {
        const eInput = this.getGui();
        eInput.focus();
        eInput.select();
    }

    // when we tab out of the editor, this gets called
    focusOut() {
        const eInput = this.getGui();
        eInput.blur();
    }

    // Gets called once when grid ready to insert the element
    getGui() {
        return this.inputElement;
    }

    // Returns the new value after editing
    getValue() {
        return this.inputElement.value;
    }

    onKeyDown(event) {
        // Stay in the cell when key pressed is home, end, right arrow, left arrow.
        if (this.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    }

    isKeyPressedNavigation(event) {
        return event.keyCode === 35 || event.keyCode === 36 || event.keyCode === 37 || event.keyCode === 39;
    }
}

