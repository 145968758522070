import { TradeProxy } from "./../api/proxies/trade-proxy";
import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { TradeModel } from "api/models/company/trade-model";

@autoinject()
export class TradeService {

    constructor(private readonly tradeProxy: TradeProxy) {
    }

    public async GetTrades(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
        return this.tradeProxy.GetTrades(filter, pagingInfo, requestConfig);
    }

    public async GetDispatchTrade(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
        return this.tradeProxy.GetDispatchTrade(dispatchId, requestConfig);
    }

    public async SetDispatchTrade(dispatchId: number, tradeCode: number, requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
       return this.tradeProxy.SetDispatchTrade(dispatchId, tradeCode, requestConfig);
    }

    public async GetEmployeeTrades(employeeId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
       return this.tradeProxy.GetEmployeeTrades(employeeId, filter, pagingInfo, requestConfig);
    }

    public async GetEmployeeDefaultTrade(employeeId: number, requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
        return this.tradeProxy.GetEmployeeDefaultTrade(employeeId, requestConfig);
    }

    public async GetCurrentEmployeeTrades(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
        return this.tradeProxy.GetCurrentEmployeeTrades(filter, pagingInfo, requestConfig);
    }

    public async GetCurrentEmployeeDefaultTrade(requestConfig?: HttpClientRequestConfig): Promise<TradeModel | null> {
        return this.tradeProxy.GetCurrentEmployeeDefaultTrade(requestConfig);
    }

    public async GetDispatchEmployeeTrades(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<TradeModel[] | null> {
        return this.tradeProxy.GetDispatchEmployeeTrades(dispatchId, filter, pagingInfo, requestConfig);
    }
}
