export abstract class ProcoreCustomAttribute {
  private element: any;
  private attribute: string;

  constructor(element: any,
              attribute: string) {
    this.element = element;
    this.attribute = attribute;
  }

  public valueChanged(newValue: any): void {
    if (newValue) {
      this.element.setAttribute(this.attribute, "");
    } else {
      this.element.removeAttribute(this.attribute);
    }
  }
}
