import { RouteConfig } from "aurelia-router";
import { autoinject, bindable, Disposable } from "aurelia-framework";
import { observable } from "aurelia-typed-observable-plugin";
import { I18N } from "aurelia-i18n";

import { MaestroBiService } from "services/maestro-bi-service";

//import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { RouterHelper } from "helpers/router-helper";
import { CompanyModel } from "api/models/common/company/company-model";
import { NotificationHelper } from "helpers/notification-helper";
import { UserSettingsModel } from "api/models/common/settings/user-settings-model";

import { SettingRepository } from "repositories/setting-repository";
import HostContext from "core/host-context";
import { HelpHelper } from "helpers/help-helper";
import { LocalizationHelper } from "helpers/localization-helper";
import { LoadCompleteEvent } from "ag-grid/dist/lib/rowModels/cache/rowNodeBlock";

interface SelectItem {
    id: string;
    text: string;
    isCustom: boolean;
}

@autoinject()
export class BIHome {

    @bindable
    public exagoUrl: string = "";

    public exagoContainerRef!: HTMLElement;

    public selectCompanies: SelectItem [] | null = null;
    public hasCompanies: boolean = false;
    public isHosted: boolean = false;

    @observable
    public selectedCompany: SelectItem | undefined;

    @observable
    public iframeLoadIsComplete: boolean = false;

    private companyName: string | null = null;
    private currentPrefix?: string;

    private selectedCompanyHasChange: boolean = true;

    private helpUrl: string = "";
    private onKeydown: any;

    constructor(
        private readonly maestroBiService: MaestroBiService,
        protected readonly i18n: I18N,
        private readonly notificationHelper: NotificationHelper,
        protected readonly routeRepository: RouteRepository,
        protected readonly routerHelper: RouterHelper,
        private readonly settingRepository: SettingRepository,
        private readonly hostContext: HostContext,
        private readonly helpHelper: HelpHelper,
        private readonly localizationHelper: LocalizationHelper) {

        //Define event in constructor to allow access to helpUrl
        this.onKeydown = (event: KeyboardEvent): boolean => {
            if (event.key === "Starhost_F1") {
                window.open(this.helpUrl);
                return false;
            }
            return true;
        };
    }

    public canActivate(): boolean {
        return true;
    }

    public async activate(params: any, routeConfig: RouteConfig): Promise<void> {
        window.document.title = this.i18n.tr("pageTitle_MaestroBI_Home");

        this.isHosted = this.hostContext.isHosted();
        this.hasCompanies = await this.initializeCompanies();

        if (this.isHosted) {
            const culture = this.localizationHelper.getCultureCode();
            this.helpUrl = this.helpHelper.getHelpUrl(culture, "" + routeConfig.name);
            window.addEventListener("keydown", this.onKeydown, false);
        }
        await this.loadExago();
    }

    public async deactivate(): Promise<void> {
        window.removeEventListener("keydown", this.onKeydown);
    }

    public afterLoadExago(event: any): void {
        this.iframeLoadIsComplete = true;
        this.routerHelper.hideLoading();
    }

    public async selectedCompanyChanged(newValue: SelectItem, oldValue: SelectItem): Promise<void> {
        if (!!newValue && !!oldValue && this.selectedCompanyHasChange) {
            let refreshExago: any = null;

            refreshExago = await this.notificationHelper.showConfirmationKey("msg_MaestroBIConfirmationRefresh");

            if (!refreshExago) {
                // because setting to oldValue will recall the changed event.
                this.selectedCompanyHasChange = false;
                this.selectedCompany = oldValue;
                return;
            }

            this.companyName = newValue.text;
            this.currentPrefix = newValue.id;
            await this.loadExago();
        } else {
            this.selectedCompanyHasChange = true;
        }
    }

    public async navigateToExport(): Promise<void> {
        await this.routerHelper.navigateToRoute(this.routeRepository.routes.MaestroBI_Export.name, {}, { isModal: false, sizes: [1200, 750] });
    }
    
    public async navigateToImport(): Promise<void> {
        await this.routerHelper.navigateToRoute(this.routeRepository.routes.MaestroBI_Import.name, {}, { isModal: false, sizes: [1050, 450] });
    }

    private async loadExago(): Promise<void> {
        if (this.currentPrefix === "") {
            this.currentPrefix = undefined;
        }
        const session = await this.maestroBiService.createMaestroBiSession(this.companyName, this.currentPrefix);
        if (!session || !session.SessionUrl) {
            this.notificationHelper.showError(this.i18n.tr(`MaestroBI.Session_not_valid`), "");
            return;
        }
        this.exagoUrl = session.SessionUrl;
    }

    private getCompanyByCurrentCompanyTag(companies: CompanyModel[]): CompanyModel | undefined {
        const companyTag: string | null = this.settingRepository.getCompany();
        if (!companyTag) {
            this.notificationHelper.showError(this.i18n.tr(`MaestroBI.Company_tag_not_found`), "");
            return;
        }
        const currentCompany = companies.find((cpy: CompanyModel) => cpy.Tag === companyTag);
        if (!currentCompany) {
            this.notificationHelper.showError(this.i18n.tr(`MaestroBI.Company_tag_not_found_in_user_companies`), "");
            return;
        }
        return currentCompany;
    }

    private async initializeCompanies(): Promise<boolean> {
        const companies = await this.maestroBiService.getCompaniesForCurrentUserAndPartition();

        if (companies != null && companies.length) {
            this.selectCompanies = companies.map((x: CompanyModel) => {
                return {
                    id: x.Prefix,
                    text: `${x.Prefix} - ${x.Name}`,
                    isCustom: false
                } as SelectItem;
            });

            const currentCompany = this.getCompanyByCurrentCompanyTag(companies);
            if (currentCompany) {
                this.selectedCompany = this.selectCompanies.find((x: SelectItem) => {
                    return x.id === currentCompany.Prefix;
                });
                this.companyName = this.selectedCompany!.text;
                this.currentPrefix = this.selectedCompany!.id;
            }

            return true;
        }

        return false;
    }
}
