import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import { WorkOrderWorkTaskService } from "services/work-order-work-task-service";
import RouteRepository from "repositories/routeRepository";
import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import { RouteInfo } from "components/others/ma-work-task-index";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class WorkTaskEdit extends FormBase {

    public transactionNumber: number = 0;
    public newWorkTask!: () => Promise<WorkOrderWorkTaskDetailsModel>;
    public documentRouteInfo: RouteInfo = { routeName: "", routeParams: {} };
    public workOrderId: any;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly routeRepository: RouteRepository, private readonly workOrderWorkTaskService: WorkOrderWorkTaskService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<void> {

        this.transactionNumber = params.transactionNumber ? params.transactionNumber : 0;
        this.workOrderId = decodeURIComponent(params.id);

        this.newWorkTask = (await this.workOrderWorkTaskService.GetNewWorkTaskByWorkOrderId.bind(this.workOrderWorkTaskService, this.workOrderId));
        this.documentRouteInfo.routeName = this.routeRepository.routes.FieldService_WorkTask_Documents_Add.name;
        this.documentRouteInfo.routeParams = params;
    }
}
