import routerHelper from "helpers/routerHelper";
import employeeService from "services/employeeService";

export class ProjectSkillCertificate {

    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = true;
    public actions: any = {};

    public activate(params: any): void {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dispatchDate = params.dailyEntryDate;

        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
        this.readonly = (paramsQueryString && paramsQueryString.readonly === "true") || false;
        this.loadData();
    }

    public getEmployeeUrl(employee: any): string {
        return "#project/" +
            encodeURIComponent(this.dispatchProjectCode) +
            "/dailyEntry/" +
            this.dispatchDate +
            "/employee/" +
            employee.Id +
            "/" +
            employee.DispatchId +
            routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly });
    }

    public loadData(): any {
        this.actions = {
            getEmployeesForDispatch: employeeService.getEmployeesForDispatchForProject.bind(employeeService, this.dispatchProjectCode, this.dispatchDate),
            getEmployeeUrl: this.getEmployeeUrl.bind(this),
            addDispatchIdToUrl: true,
            queryStringToAppend: routerHelper.addQuerystring({
                isProjectResponsible: this.isProjectResponsible,
                readonly: this.readonly
            })
        };
    }

}
