import { Key } from "ts-keycode-enum";

export class OverrideKeyPressHelper {
    constructor() {
    }
    
    overrideCheckboxCellKeyPresses(params) {
        var keyCode = params.event.keyCode;

        if (keyCode === Key.Space) {
            if (!params.editing) {
                params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: params.column, keyPress: Key.Space, charPress: null });
            }
            return true;
        }

        if (keyCode === Key.Enter) {
            params.api.stopEditing();
            params.api.tabToNextCell();
            return true;
        }

        return false;
    }

    overrideTextBoxCellKeyPresses(params) {
        var keyCode = params.event.keyCode;

        if (keyCode === Key.Tab) {
            if (params.editing) {
                params.api.stopEditing();
                params.api.tabToNextCell();
                return false;
            }
        }

        if (keyCode === Key.Space) {
            if (!params.editing) {
                params.api.startEditingCell({
                    rowIndex: params.node.rowIndex,
                    colKey: params.column,
                    keyPress: Key.ENTER,
                    charPress: null
                });
            }
            return true;
        }

        if (keyCode === Key.Enter) {
            if (params.editing) {
                params.api.stopEditing();
                params.api.tabToNextCell();
                return true;
            }
            else if (!params.colDef.editable || !params.colDef.editable(params)) {
                params.api.tabToNextCell();
                return true;
            }
        }

        return false;
    }
}