import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";
import routerHelper from "helpers/routerHelper";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class FieldServiceMaterialRequisitionEdit extends FormBase {

    public actions: any = {};
    public workOrderId: string = "";
    public requisitionId: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public isDirty: boolean = false;
    public parentModel: any = null;
    public projectCode: string = "";
    public dispatchProjectCode: string = "";

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public activate(params: any): any {
        this.workOrderId = decodeURIComponent(params.workOrderId);
        this.requisitionId = params.requisitionId;

        const paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        const qsParams = routerHelper.getQuerystring(params.q);
        if (qsParams) {
            if (qsParams.projectCode) {
                this.projectCode = qsParams.projectCode;
            }

            if (qsParams.dispatchProjectCode) {
                this.dispatchProjectCode = qsParams.dispatchProjectCode;
            }
        }

        this.parentModel = this;
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForFieldService.bind(materialRequisitionService, this.workOrderId),
            setSpecificFields: this.setSpecificFields.bind(this)
        };
    }

     public setSpecificFields(item: any): any {
        item.workOrderId = this.workOrderId;
        return item;
    }
}
