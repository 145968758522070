import { JobModelStatus } from "api/enums/job-model-status";

export class JobStatusHelper {
    
    public get Waiting(): JobModelStatus { return JobModelStatus.Waiting; }
    public get Running(): JobModelStatus { return JobModelStatus.Running; }
    public get Delayed(): JobModelStatus { return JobModelStatus.Delayed; }
    public get Success(): JobModelStatus { return JobModelStatus.Success; }
    public get Error(): JobModelStatus { return JobModelStatus.Error; }
    public get CriticalError(): JobModelStatus { return JobModelStatus.CriticalError; }
}
