import { bindable, bindingMode } from "aurelia-framework";
import Parse from "helpers/parse";

export interface IdTextSelectItemData {
    id: string;
    text: string;
}

export type SelectItemPropertySelector<Data, SelectorType> = (data: Data) => SelectorType;

export class SelectItem<T> {
    public static fromIdText<T extends IdTextSelectItemData>(data: T): SelectItem<T> {
        return new SelectItem<T>(data, (d: T): string => d.id, (d: T): string => d.text);
    }

    public readonly id: string;
    public readonly text: string;
    public readonly data: T;

    constructor(
        data: T,
        idSelector: SelectItemPropertySelector<T, string>,
        textSelector: SelectItemPropertySelector<T, string>
    ) {
        this.data = data;
        this.id = idSelector(this.data);
        this.text = textSelector(this.data);
    }

    public toString(): string {
        return this.text;
    }
}

export class MultiSelectItem<T> extends SelectItem<T> {
    public static fromIdText<T extends IdTextSelectItemData>(data: T): MultiSelectItem<T> {
        return new MultiSelectItem<T>(data, (d: T): string => d.id, (d: T): string => d.text);
    }

    @bindable({ defaultBindingMode: bindingMode.twoWay }) //
    public selected: boolean = false;

    constructor(
        data: T,
        idSelector: SelectItemPropertySelector<T, string>,
        textSelector: SelectItemPropertySelector<T, string>
    ) {
        super(data, idSelector, textSelector);
    }
}

export function resolveItemPathAsString(item: any, path: string): string {
    if (item === null || item === undefined) {
        return "";
    }

    if (path === ".") {
        return Parse.String(item);
    }

    return Parse.String((item as { [key: string]: any })[path]);
}
