import { I18N } from "aurelia-i18n";
import { default as notificationHelper } from "helpers/notificationHelper";
import { CatalogItemTypeProxy } from "api/proxies/catalog-item-type-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { PagingInfo } from "api/paging-info";
import { CatalogItemTypeModel } from "api/models/company/catalog/catalog-item-type-model";

@autoinject()
export class CatalogItemTypeService {
    constructor(private i18n: I18N, private readonly catalogItemTypeProxy: CatalogItemTypeProxy) {
    }

    public async GetTypes(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemTypeModel[] | null> {
        const types = await this.catalogItemTypeProxy.GetTypes(filter, pagingInfo, requestConfig);

        if (!types) {
            return [];
        }

        return types.map((item: any): any => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.Description}`,
                data: item
            };
        });
    }
}
