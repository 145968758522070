import { I18N } from "aurelia-i18n";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { bindable } from "aurelia-framework";
import { autoinject } from "aurelia-dependency-injection";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { MaCardActionModel, MaCardLineModel, MaCardOptionsModel } from "components/layout/ma-card/ma-card-options.model";
import { DispatchContactService } from "services/dispatch-contact-service";

@autoinject
export class Contact {
    @bindable public actions: any;
    @bindable public readonly: boolean = false;
    @bindable public dispatchId: string = "";
    public serviceType: string = "";
    public querystringToAdd: string = "";
    public dispatchProjectCode: string = "";
    public contactCards: MaCardOptionsModel[] = [];
    public customerSiteCards: MaCardOptionsModel[] = [];
    public customerDrawerTitle: string = this.i18n.tr("Customer:");
    public customerSiteDrawerTitle: string = this.i18n.tr("Site:");

    constructor(private readonly i18n: I18N, private readonly routeRepository: RouteRepository, private readonly dispatchContactService: DispatchContactService) {
    }

    public bind(bindingContext: any): any {
        this.dispatchProjectCode = bindingContext.dispatchProjectCode;
        this.actions = bindingContext.actions;
        this.querystringToAdd = routerHelper.addQuerystring({readonly: this.readonly});
        this.loadData();
    }

    public genUrl(contact: any): string {
        return  routerHelper.getRelativeUrl("detail", contact.Id) + this.querystringToAdd;
    }

    private goToPage(contact: ContactLookupModel): void {
        routerHelper.navigate(routerHelper.getRelativeUrl("detail", contact.Id) + this.querystringToAdd);
    }

    private createContactCards(contacts: ContactLookupModel[]): MaCardOptionsModel[] {
        const cards: MaCardOptionsModel[] = [];

        contacts.forEach((contact: ContactLookupModel) => {
            const cardOptions = new MaCardOptionsModel({
                model: contact,
                class: "ma-card-link",
                action: this.goToPage.bind(this, contact),
                id: "card_" + contact.Id,
                displayLines: [
                    new MaCardLineModel({ display: contact.FullName!, class: ""}),

                ],
                actionItems: [
                    new MaCardActionModel({ id: "open", icon: "fa fa-chevron-right", action: this.goToPage.bind(this, contact)}),
                ],
            });

            if (contact.Title) {
                cardOptions.displayLines.push(new MaCardLineModel({ display: contact.Title }));
            }
            if (contact.Company) {
                cardOptions.displayLines.push(new MaCardLineModel({ display: contact.Company }));
            }

            cards.push(cardOptions);
        });

        return cards;
    }

    private loadData(): void {
        this.actions.getContacts().then((data: ContactLookupModel[]) => {
            this.contactCards = this.createContactCards(data);
            this.customerDrawerTitle += data.length > 0 ? data[0].MasterName : "";
        });

        if (this.dispatchId) {
            this.dispatchContactService.GetCustomerSiteContactsLookupForDispatchClient(Number.parseInt(this.dispatchId)).then((customerSiteContact: ContactLookupModel[]) => {
                this.customerSiteCards = this.createContactCards(customerSiteContact);
                this.customerSiteDrawerTitle += customerSiteContact.length > 0 ? customerSiteContact[0].MasterName : "";
            });
        }
    }
}
