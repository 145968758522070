define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getByDispatchIdAndStatus: function (dispatchId, filter, page) {
            return requests.getJson(
                apiHelper.appendFilterPagingParams(
                    apiHelper.getDispatchBaseUrl(dispatchId) + "/purchaseorders",
                    filter,
                    page)
            );
        },

        getPurchaseOrder: function (purchaseOrderId, extension) {
            var url = apiHelper.getBaseUrl() + "/purchaseorders/" + purchaseOrderId;
            if (extension !== "") {
                    url += "?extension=" + extension;
            }
            return requests.getJson(url);
        },

        getProjectPurchaseOrders: function (projectCode, getClosedOrders = false, getOnlyFullyReceived = false) {
            return requests.getJson(apiHelper.getBaseUrl() + "/project/purchaseorders/" + projectCode + "?getOnlyFullyReceived=" + getOnlyFullyReceived);
        },

        getWorkOrderPurchaseOrders: function (workOrderId, getClosedOrders = false, getOnlyFullyReceived = false) {
            return requests.getJson(apiHelper.getBaseUrl() + "/workorder/purchaseorders/" + workOrderId + "?getOnlyFullyReceived=" + getOnlyFullyReceived);
        },

        setPurchaseOrderHeaderForService: function (dispatchId, purchaseOrder) {
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/purchaseorders", purchaseOrder);
        },

        setPurchaseOrderHeaderForWorkOrder: function (workOrderId, purchaseOrder) {
            return requests.post(apiHelper.getBaseUrl() + "/workOrder/" + workOrderId + "/purchaseorders", purchaseOrder);
        },

        setPurchaseOrderHeaderForProject: function (projectCode, purchaseOrder) {
            return requests.post(apiHelper.getBaseUrl() + "/project/purchaseorders/" + projectCode, purchaseOrder);
        },

        getPurchaseOrderItem: function (purchaseOrderId, lineNo) {
            return requests.getJson(apiHelper.getBaseUrl() + "/purchaseorders/" + purchaseOrderId + "/" + lineNo);
        },

        setPurchaseOrderItemForService: function (dispatchId, purchaseOrderId, lineNo, item) {
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/" + dispatchId + "/purchaseorders/" + purchaseOrderId + "/" + lineNo, item);
        },

        setPurchaseOrderItemForProject: function (purchaseOrderId, lineNo, item) {
            return requests.post(apiHelper.getBaseUrl() + "/project/purchaseorders/" + purchaseOrderId + "/" + lineNo, item);
        },

        setPurchaseOrderItemForWorkOrder: function (workOrderId, purchaseOrderId, lineNo, item) {
            return requests.post(apiHelper.getBaseUrl() + "/workOrder/" + workOrderId + "/purchaseorders/" + purchaseOrderId + "/" + lineNo, item);
        },

        deletePurchaseOrder: function (purchaseOrderId, extension) {
            var url = apiHelper.getBaseUrl() + "/purchaseorders/" + purchaseOrderId;
            if (extension !== "") {
                url += "?extension=" + extension;
            }

            return requests.remove(url);
        },

        delPurchaseOrderItem: function (purchaseOrderId, lineNo, extension) {
            var url = apiHelper.getBaseUrl() + "/purchaseorders/" + purchaseOrderId + "/" + lineNo;
            if (extension !== "") {
                url += "?extension=" + extension;
            }

            return requests.remove(url);
        }

    };

    return exports;
});
