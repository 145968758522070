import { autoinject, bindingMode, customElement, observable, processContent } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-typed-observable-plugin";

import { processContentAsReplacablePart } from "helpers/au-process-content-helper";
import { SelectItem } from "models/select-item";
import "popper.js";
import "bootstrap/dist/js/bootstrap";

@autoinject()
@customElement("lister-d")
@processContent(processContentAsReplacablePart("item-template"))
export class ListerD<T extends { [key: string]: any }> {
    // TODO JL: Manage allow-clear
    @bindable.string //
    public id: string | null = null;

    @bindable.booleanAttr //
    public disabled: boolean = false;

    @bindable.none //
    public items: Array<SelectItem<T>> = [];

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedValue: any | null = null;

    public displayText: string | null = "";
    public toggleRefElement: any;

    public attached(): void {
        (jQuery(this.toggleRefElement) as any).dropdown();
    }

    public selectItem(item: SelectItem<T> | null): void {
        if (!item) {
            this.selectedValue = null;
            return;
        }

        this.selectedValue = item.id;
    }

    public selectedValueChanged(newValue: T): void {
        const selectedItem = this.items ? this.items.find((x: SelectItem<T>) => x.id === newValue + "") : null;
        this.displayText = selectedItem ? selectedItem.text : null;
    }
}
