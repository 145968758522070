import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { RouterHelper } from "helpers/router-helper";
import RouteRepository from "repositories/routeRepository";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceWorkOrderService } from "services/service-work-order-service";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallDispatchCreationModel } from "api/models/company/service/service-call-dispatch-creation-model";
import { default as templateService } from "services/templateService";
import { DispatchPresence } from "api/enums/dispatch-presence";
import { ServiceCallService, ServiceCallGenerationOption} from "services/service-call-service";
import { SettingRepository } from "repositories/setting-repository";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { NotificationHelper } from "helpers/notification-helper";
import { ServiceCallMaterialModel } from "api/models/company/service/service-call-material-model";

export interface GenerateServiceCallParameters {
    quotationId: number;
}

@autoinject()
export class GenerateServiceCall {
    public workDate: Date = new Date(Date.now());
    public timeIncrement: number = 15;
    public startTime: number = 0;
    public endTime: number = 0;
    public quotation!: ServiceCallQuotationDetailsModel;
    public readonly ServiceCallGenerationOption: typeof ServiceCallGenerationOption = ServiceCallGenerationOption;
    public selectedServiceCallGenerationOption: ServiceCallGenerationOption = ServiceCallGenerationOption.WorkingDateStartNow;

    constructor(
        private readonly i18n: I18N,
        private readonly routeRepository: RouteRepository,
        private readonly routerHelper: RouterHelper,
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallService: ServiceCallService,
        private readonly settingRepository: SettingRepository,
        private readonly dispatchTemplateService: DispatchTemplateService,
        private readonly serviceWorkOrderService: ServiceWorkOrderService,
        private readonly notificationHelper: NotificationHelper
    ) {
    
    }

    // #region Lifecycle
    public async activate(params: GenerateServiceCallParameters): Promise<void> {
        await this.initQuotation(params.quotationId);
        await this.initTimeIncrement();
    }
    // #endregion
    // #region EventHandlers
    public async generate(): Promise<void> {

        let dispatchTemplate = this.settingRepository.getDispatchTemplate();

        if (dispatchTemplate === "null" || dispatchTemplate === "undefined") {
            dispatchTemplate = null;
        }

        this.workDate.setHours(0, 0, 0, 0);

        const dispatchResponseModel = !this.quotation.IsWorkOrder ? await this.serviceCallService.createServiceCall(this.selectedServiceCallGenerationOption, this.workDate, this.startTime, this.endTime, null, null, this.quotation, dispatchTemplate, false) :
            await this.serviceWorkOrderService.createWorkOrder(this.selectedServiceCallGenerationOption, this.workDate, this.startTime, this.endTime, null, null, this.quotation, dispatchTemplate, false);
        
        if (this.selectedServiceCallGenerationOption !== ServiceCallGenerationOption.WorkingDateStartNow) {
            return this.routerHelper.navigateBack();
        }

        if (dispatchResponseModel != null) {

            if (dispatchResponseModel.BackOrderMaterialItems != null && dispatchResponseModel.BackOrderMaterialItems.length > 0) {
                let backOrderItemsDescription = "";
                dispatchResponseModel.BackOrderMaterialItems.forEach((boItem: ServiceCallMaterialModel) => { backOrderItemsDescription = backOrderItemsDescription + boItem.Id + " - " + boItem.Description + "<br>"; });
         
                let warning = this.i18n.tr("msg_BackOrderMaterial");
                warning = warning.replace("{0}", backOrderItemsDescription);
                this.notificationHelper.showWarning(warning, "", { timeOut: 0 });
            }

            if (this.quotation.IsWorkOrder) {
                return this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail.name, {
                    serviceType: "W",
                    dispatchId: dispatchResponseModel.DispatchId,
                    q: this.routerHelper.buildQueryString({ readonly: false })
                }, { replace: true });
            }

            return this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail.name, {
                serviceType: "S",
                dispatchId: dispatchResponseModel.DispatchId,
                q: this.routerHelper.buildQueryString({ readonly: false })
            }, { replace: true });
        }
       
    }

    public generateCallForOption(selectedOption: ServiceCallGenerationOption): void {
        this.selectedServiceCallGenerationOption = selectedOption;
    }
    // #endregion
    // #region Privates
    private async initTimeIncrement(): Promise<void> {
        if (!this.quotation) { return; }

        let increment = await templateService.getUserTemplateIncrement();
        if (this.quotation.SourceDispatchId !== 0) {
            const dispatchTemplate = await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(this.quotation.SourceDispatchId);
            increment = dispatchTemplate.TimeIncrement;
        }

        this.timeIncrement = increment > 0 ? increment : this.timeIncrement;
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, true, false, false, false, false);
    }
    // #endregion
}
