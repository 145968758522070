import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import browserHelper from "helpers/browserHelper";
import { CustomEventHelper } from "helpers/custom-event-helper";

@autoinject()
export class FileSelectButton {
    @bindable.string
    public theme: string = "primary";

    @bindable.booleanAttr
    public outline: boolean = true;

    @bindable.string
    public caption: string = "";

    @bindable.boolean
    public enableCameraNotAvailableWarning: boolean = true;

    @bindable.string
    public acceptFilePattern: string = "*";

    @bindable.booleanAttr
    public disabled: boolean = false;

    public inputElement: Element | null = null;
    public isIOS11WebApp: boolean = false;

    constructor(private readonly element: Element) {
    }

    public bind(): void {
        this.isIOS11WebApp = browserHelper.isIOS11WebApp();
    }

    public buttonClicked(): void {
        if (this.inputElement) {
            (this.inputElement as HTMLElement).click();
        }
    }

    public onFileSelected(event: Event): void {
        if (!event || !event.target || !event.target) {
            return;
        }

        const files = (event.target as HTMLInputElement).files;
        if (!files || files.length === 0) {
            return;
        }

        CustomEventHelper.dispatchEvent(this.element, "file-selected", files);
    }
}
