import { SettingRepository } from "repositories/setting-repository";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { default as _ } from "underscore";
import BambooHrSyncService from "../../services/bamboo-hr/bamboo-hr-sync-service";
import dateHelper from "helpers/dateHelper";
import { RouterHelper } from "helpers/router-helper";
import RouteRepository from "repositories/routeRepository";
import { BambooSyncRunDashboard } from "pages/bamboo-hr/models/bamboo-sync-run-dashboard";
import { AgGridHelper } from "helpers/ag-grid-helper";
import { GridApi } from "ag-grid";
import { BambooSyncCellRenderer } from "pages/bamboo-hr/models/bamboo-sync-cell-renderer";
import { BambooSyncRunReasonItem } from "./models/bamboo-sync-run-reason-item";
import { BambooReasonTreeviewItem, IBambooReasonTreeviewItem } from "pages/bamboo-hr/models/bamboo-reason-treview-item";
import { BambooSyncConfigError } from "pages/bamboo-hr/models/bamboo-sync-config-error";
import { BambooSyncConfigStatus } from "pages/bamboo-hr/models/bamboo-sync-config-status";
import HostContext from "core/host-context";
import notificationHelper from "helpers/notificationHelper";
import { stringify } from "querystring";

@autoinject
export class BambooHrSync {
    public logs: string = "";
    public isSyncRunning: boolean = false;
    public isSyncFailed: boolean = false;
    public isSyncSuccess: boolean = false;
    public isInConfig: boolean = false;

    public toggleFetchDashboardById: boolean = false;
    public toggleTimeOff: boolean = false;
    public reportGrid: HTMLElement | null = null;
    public gridApi: GridApi | null = null;
    public previousRowData: BambooReasonTreeviewItem[] = [];

    public dashboard: BambooSyncRunDashboard = new BambooSyncRunDashboard();
    public configurationStatus: BambooSyncConfigStatus = new BambooSyncConfigStatus();
    public isConfigStatusInError: boolean = false;

    public bambooIgnoredEmployeesKey: string = "BambooHR.Synchro_bambooIgnoredEmployeesKey";
    public employeesNeedsToBeUpdatedKey: string = "BambooHR.Synchro_employeesNeedsToBeUpdatedKey";
    public employeeNeedsToBeUpdatedRow: string = "BambooHR.Synchro_employeeNeedsToBeUpdated_Row";
    public errorKey: string = "BambooHR.Synchro_errorKey";
    public noComparisonNeededKey: string = "BambooHR.Synchro_noComparisonNeededKey";

    public infoIcon: string = "info-icon";
    public errorIcon: string = "error-icon";
    public warningIcon: string = "warning-icon";

    public currentCorrelationId: string = "";

    constructor(
        private readonly i18n: I18N,
        private readonly bambooHrSyncService: BambooHrSyncService,
        private readonly settingRepository: SettingRepository,
        private readonly agGridHelper: AgGridHelper,
        private readonly routeRepository: RouteRepository,
        private readonly routerHelper: RouterHelper,
        private readonly hostContext: HostContext) {
    }

    public async bindToWindow(w: any): Promise<any> {
        w.BambooHrSync = this;
    }

    public async bind(): Promise<void> {
        await this.bindToWindow(window);

        if (this.hostContext.viewModel != null) {
            const encodedCreds = await this.hostContext.viewModel.fetchEncodedCreds();
            await this.bambooHrSyncService.setBambooHrCreds(encodedCreds);
        } else {
            notificationHelper.showDialogOk(this.i18n.tr("BambooHR.HostContextUnavailable"));
        }

        await this.processConfigurationStatus();
        const autoGroupColumnDef = {
            width: 350,
            headerName: this.i18n.tr("BambooHR.AdditionnalInfo"),
            cellRendererParams: {
                //suppressCount: true
                innerRenderer: "syncCellRenderer"
            },
            //comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isInverted: any): number => this.customGridSort(valueA, valueB, nodeA, nodeB, isInverted),
            sort: "asc",
        };

        const gridOptions: any = {
            treeData: true, // enable Tree Data mode
            columnDefs: [
                { field: "reason", headerName: this.i18n.tr("Reason"), width: 550, }
            ],
            components: {
                syncCellRenderer: this.getSyncCellRenderer(),
            },
            autoGroupColumnDef: autoGroupColumnDef,
            onGridReady: this.onGridReady.bind(this),
            getDataPath: (data: any): string[] => data.hierarchy as string[],
            localeTextFunc: (key: any, defaultValue: any): string => this.localizeAgGridText(key, defaultValue),
            rowSelection: "single",
            //onSelectionChanged: this.onSelected.bind(this),
            groupDefaultExpanded: -1, // expand all groups by default
            toolPanelSuppressSideButtons : true,
        };

        await this.agGridHelper.createGrid(this.reportGrid, gridOptions);
        this.writeToLogs(this.i18n.tr("BambooHR.ReadyToSynchronize"));
    }

    public async onConfigSavedHandler(): Promise<any> {
        await this.processConfigurationStatus();
        this.isInConfig = false;
    }

    public async onConfigCanceledHandler(): Promise<any> {
        this.isInConfig = false;
    }

    public async processConfigurationStatus(): Promise<any> {
        this.bambooHrSyncService.getConfigurationStatus()
        .then((x: any) => {
            this.configurationStatus = x;
            this.isConfigStatusInError = !this.configurationStatus.hasConfigBeenSetOnce || this.configurationStatus.isInError;
        })
        .catch((x: any) => {
            notificationHelper.showDialogOk(this.i18n.tr("BambooHR.CannotGetConfiguration"));
        });
    }

    public async navigateToConfigPage(): Promise<any> {
        this.isInConfig = true;
        this.routerHelper.navigateToRoute(this.routeRepository.routes.BambooHrSyncConfig.name, {}, { isModal: false, sizes: [590, 490] })
        .then((value: void): any => {
            //this.isInConfig = false;
        });
    }

    public getSyncCellRenderer(): () => void {
        const result: () => void = (): void => { /**/ };
        const cellRenderer = new BambooSyncCellRenderer();

        result.prototype.innerCellRendererModel = cellRenderer;
        result.prototype.init = cellRenderer.init;
        result.prototype.getGui = cellRenderer.getGui;
        return result;
    }

    public onGridReady(params: any): any {
        this.gridApi = params.api;
        params.api.setRowData([]);
    }

    public onSelected(params: any): any {
        return;
    }

    public writeToLogs(message: string): void {
        const timeStamp = dateHelper.formatDate("YYYY-MM-DD HH:mm:ss");
        this.logs += `[${timeStamp}] - ${message} \n`;
    }

    public startSync(): void {
        if (this.isSyncRunning) {
            return;
        }

        this.isSyncFailed = this.isSyncSuccess = false;
        this.isSyncRunning = true;

        this.logs = "";
        this.writeToLogs(this.i18n.tr("BambooHR.SynchronizationStarted"));

        if (this.gridApi) {
            this.previousRowData = [];
            this.gridApi.setRowData([]);
        }
        this.bambooHrSyncService.startBambooHrSync(this.settingRepository.getUser()).then(
            (result: any) => {
                if (result) {
                    this.writeToLogs(this.i18n.tr("BambooHR.CorrelationIdIs") + result.correlationId);
                    this.showSyncRunDashboard(result.correlationId);
                }
            },
            (error: any) => {
                if (!!error.responseJSON) {
                    const errorResponse = error.responseJSON;
                    this.writeToLogs(`|${errorResponse.status}| ${errorResponse.message}`);
                } else {
                    this.writeToLogs(this.i18n.tr("BambooHR.SynchronizationConnectionFailed"));
                }

                this.isSyncRunning = false;
                this.isSyncFailed = true;
            }
        );
    }

    public async showSyncRunDashboardById(): Promise<any> {
        if (this.currentCorrelationId.length > 35) {
            return await this.showSyncRunDashboard(this.currentCorrelationId);
        }
        return 0;
    }

    public async showSyncRunDashboard(correlationId: string): Promise<any> {

        if (!this.dashboard.isSyncRunFinished) {
            this.bambooHrSyncService.getSyncRunDashboard(correlationId)
            .then(async (result: any) => {
                this.dashboard = result;
                await this.populateTreeViewByDashboard();
            });
            this.routerHelper.hideLoading();

            setTimeout(async () => {
                await this.showSyncRunDashboard(correlationId);
            }, 750);

        } else {
            this.isSyncFailed = this.dashboard.isInError;
            this.isSyncSuccess = !this.dashboard.isInError;
            this.isSyncRunning = false;
            this.dashboard.isSyncRunFinished = false; // ECM : Permits next-new synchroniszation to happen.
            this.writeToLogs(this.i18n.tr("BambooHR.SynchronizationCompleted"));
        }

        return 0;
    }

    public formatReasonDisplay(reasonItem: BambooSyncRunReasonItem): string {
        if (reasonItem.reasonType === "NoComparisonNeeded") {
            return this.i18n.tr("BambooHR.EmployeeCountComparison")
                .replace("{bamboohrcount}", reasonItem.reasonData.sourceDataCount)
                .replace("{maestrocount}", reasonItem.reasonData.destinationDataCount);

        } else if (reasonItem.reasonType === "EmployeeWithoutDataId") {
            return this.i18n.tr("BambooHR.EmployeeWithoutDataId")
                .replace("{lastname}", reasonItem.reasonData.lastName)
                .replace("{firstname}", reasonItem.reasonData.firstName);

        } else if (reasonItem.reasonType === "EmployeeSinIsInvalid") {
            return this.i18n.tr("BambooHR.EmployeeSinIsInvalid");

        } else if (reasonItem.reasonType === "EmployeeNeedsToBeUpdated") {
            if (reasonItem.reasonData.propertyNames.length === 1) {
                return this.i18n.tr("BambooHR.EmployeeNeedsToBeUpdated")
                    + this.tryTranslatingProperty(reasonItem.reasonData.propertyNames[0]);
            }
            const propertyNamesAsCommaSeparatedList = (reasonItem.reasonData.propertyNames as string[])
                .map((v: string, i: number, a: string[]) => this.tryTranslatingProperty(v))
                .join(", ");
            return this.i18n.tr("BambooHR.EmployeeNeedsToBeUpdatedMultipleData") + propertyNamesAsCommaSeparatedList;

        } else if (reasonItem.reasonType === "Error") {
            return reasonItem.reasonData.exception.stackTraceString;

        } else if (reasonItem.reasonType === "BankAccountNumberIsNotAlpha") {
            return this.i18n.tr("BambooHR.BankAccountNumberIsNotAlpha");
        } else if (reasonItem.reasonType === "BankTransitNumberIsNotAlpha") {
            return this.i18n.tr("BambooHR.BankTransitNumberIsNotAlpha");
        }

        return this.i18n.tr("BambooHR.ReasonUnknown");
    }

    public displayRoot(key: string): string {
        return this.i18n.tr(key);
    }

    public async populateTreeViewByDashboard(): Promise<any> {
        const rowData: BambooReasonTreeviewItem[] = [];

        const errors = this.dashboard.errors;
        if (errors.length > 0) {
            rowData.push(new BambooReasonTreeviewItem(
                [this.displayRoot(this.errorKey)],
                "",
                this.errorIcon
            ));

            for (const error of errors) {
                rowData.push(new BambooReasonTreeviewItem(
                    [this.displayRoot(this.errorKey), this.getPathName(error)],
                    this.formatReasonDisplay(error)
                ));
            }
        }

        if (this.dashboard.noComparisonNeededReason) {
            rowData.push(new BambooReasonTreeviewItem(
                [this.displayRoot(this.noComparisonNeededKey)],
                this.formatReasonDisplay(this.dashboard.noComparisonNeededReason),
                this.warningIcon
            ));
        }

        const bambooIgnoredEmployees = this.dashboard.ignoredBambooEmployeeReasons;
        if (bambooIgnoredEmployees.length > 0) {
            rowData.push(new BambooReasonTreeviewItem(
                [this.displayRoot(this.bambooIgnoredEmployeesKey)],
                "",
                this.warningIcon
            ));

            for (const ignoredBambooEmploye of bambooIgnoredEmployees) {
                rowData.push(new BambooReasonTreeviewItem(
                    [this.displayRoot(this.bambooIgnoredEmployeesKey), this.getPathName(ignoredBambooEmploye)],
                    this.formatReasonDisplay(ignoredBambooEmploye)
                ));
            }
        }

        const employeesNeedsToBeUpdated = this.dashboard.employeesNeedToBeUpdatedReasons;
        if (employeesNeedsToBeUpdated.length > 0) {
            rowData.push(new BambooReasonTreeviewItem(
                [this.displayRoot(this.employeesNeedsToBeUpdatedKey)],
                "",
                this.infoIcon
            ));

            for (const employeeNeedsToBeUpdated of employeesNeedsToBeUpdated) {
                rowData.push(new BambooReasonTreeviewItem(
                    [this.displayRoot(this.employeesNeedsToBeUpdatedKey), this.getPathName(employeeNeedsToBeUpdated)],
                    this.formatReasonDisplay(employeeNeedsToBeUpdated)
                ));
            }
        }

        if (this.gridApi && this.needToReplaceRowData(rowData)) {
            setTimeout(() => {
                if (this.gridApi) {
                    this.gridApi.setRowData(rowData);
                }
            }, 0);
            this.previousRowData = rowData;
        }
    }

    public needToReplaceRowData(rowData: IBambooReasonTreeviewItem[]): boolean {
        if (this.previousRowData.length < rowData.length) {
            return true;
        }

        const isSame = (this.previousRowData.length === rowData.length)
            && this.previousRowData.every((element: BambooReasonTreeviewItem, index: number) => {
                return element.equals(rowData[index]);
            });

        return !isSame;
    }

    public getPathName(reasonItem: BambooSyncRunReasonItem): string {
        let result: string = "";

        if (reasonItem.reasonType === "Error") {
            result = reasonItem.reasonData.timestamp;
        } else {
            if (!!reasonItem.dataId) {
                result = this.i18n.tr("BambooHR.MaestroEmployee") + reasonItem.dataId + " : ";

                if (reasonItem.reasonType === "EmployeeNeedsToBeUpdated") {
                    result += this.i18n.tr(this.employeeNeedsToBeUpdatedRow);
                } else {
                    result += reasonItem.reasonData.lastName + ", " + reasonItem.reasonData.firstName;
                }

            } else {
                result = this.i18n.tr("BambooHR.BambooEmployee") + reasonItem.reasonData.bambooEmployeeId + "";
            }
        }
        return result;
    }

    public startSyncTimeOff(): void {
        this.isSyncFailed = this.isSyncSuccess = false;
        this.isSyncRunning = true;

        this.logs = "";
        this.writeToLogs(this.i18n.tr("BambooHR.SynchronizationStarted"));

        this.bambooHrSyncService.startBambooHrSyncTimeOff(this.settingRepository.getUser()).then(
            (result: any) => {
                if (result) {
                    this.isSyncRunning = false;
                    this.isSyncSuccess = true;
                    this.writeToLogs(result);
                }
            },
            (error: any) => {
                if (!!error.responseJSON) {
                    const errorResponse = error.responseJSON;
                    this.writeToLogs(`|${errorResponse.status}| ${errorResponse.message}`);
                } else {
                    this.writeToLogs(this.i18n.tr("BambooHR.SynchronizationConnectionFailed"));
                }

                this.isSyncRunning = false;
                this.isSyncFailed = true;
            }
        );
    }

    private localizeAgGridText(key: string, defaultValue: string): string {
        const prefixedKey = `ag-grid-${key}`;
        const value = this.i18n.tr(prefixedKey);

        if (!value || value === prefixedKey) {
            return defaultValue;
        }

        return value;
    }

    private tryTranslatingProperty(p: string): string {
        return this.i18n.tr("BambooHR." + p).replace("BambooHR.", "");
    }
}
