// GENERATED FILE - DO NOT MODIFY
export enum JobModelStatus {
    Waiting = 0,
    Running = 1,
    Delayed = 2,
    Success = 3,
    Error = 4,
    CriticalError = 5,
}

export let nameof = "JobModelStatus";
