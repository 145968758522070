import { DOM, customElement, inject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import Assert from "core/assert";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { ExpressionObserver } from "helpers/expression-observer";
import { ServiceCallQuotationEquipmentModel } from "api/models/company/service-call/service-call-quotation-equipment-model";

@inject(Element, ExpressionObserver)
@customElement("quotation-added-equipment")
export class ServiceCallQuotationAddedEquipmentComponent {
    @bindable public Equipment!: ServiceCallQuotationEquipmentModel;
    @bindable.booleanAttr public disabled: boolean = false;

    constructor(private readonly element: Element, private readonly expressionObserver: ExpressionObserver) { }

    public selectedChanged(newValue: boolean): void {
        CustomEventHelper.dispatchEvent(this.element, "selected-changed", newValue);
    }

    public attached(): void {
        Assert.hasValue(this.Equipment);
        this.expressionObserver.subscribeToExpression(this.Equipment, "Selected", (newValue: boolean): void => this.selectedChanged(newValue));
    }

    public detached(): void {
        this.expressionObserver.dispose();
    }
}
