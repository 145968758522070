import { AdditionalFieldType } from "api/enums/additional-field-type";
import { autoinject } from "aurelia-framework";
import notificationHelper from "helpers/notificationHelper";
import { AdditionalFieldValueGetter } from "./additional-field-value-getter";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";

@autoinject
export class AdditionalFieldsValidator {
  
  private additionalFieldValueGetter: AdditionalFieldValueGetter;
  private additionalFieldsHelper: AdditionalFieldsHelper;

  constructor(additionalFieldValueGetter: AdditionalFieldValueGetter,
              additionalFieldsHelper: AdditionalFieldsHelper) {
    this.additionalFieldValueGetter = additionalFieldValueGetter;
    this.additionalFieldsHelper = additionalFieldsHelper;
  }

  public async validateAdditionalFields(fields: any, additionalFieldsSource: any): Promise<void> {
    fields
      .filter((val: any) => val.mobileRequired && val.type !== AdditionalFieldType.Timestamp)
      .forEach((field: any) => {
        if (field.type === AdditionalFieldType.Memo) {
            field.value = this.additionalFieldValueGetter.getSaveValue({ Definition: field.definition, Value: field.value });
        }

        if (!field.value || field.value === 0) {
            
        const warning = this.additionalFieldsHelper.getRequiredRessourceBySourceType(additionalFieldsSource);
        notificationHelper.showWarning(warning, "", { timeOut: 0 });
            
        return;
      }
    });
  }
}
