import { CallReturnEquipmentMaintenanceTaskModel } from "api/models/company/service/call-return-equipment-maintenance-task-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallDispatchEquipmentMaintenanceTaskProxy } from "api/proxies/service-call-dispatch-equipment-maintenance-task-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class ServiceCallDispatchEquipmentMaintenanceTaskService {
    constructor(private readonly serviceCallDispatchEquipmentMaintenanceTaskProxy: ServiceCallDispatchEquipmentMaintenanceTaskProxy) {
    }

    public async get(dispatchId: number, equipmentId: number, maintenanceId: string, filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<CallReturnEquipmentMaintenanceTaskModel[]> {
        return (await this.serviceCallDispatchEquipmentMaintenanceTaskProxy.GetTasks(dispatchId, equipmentId, maintenanceId, filter, pagingInfo, requestConfig)) || [];
    }

    public async save(dispatchId: number, tasks: Array<CallReturnEquipmentMaintenanceTaskModel | null> | null, copyValuesForSameMaintenance: boolean | undefined): Promise<void> {
        return await this.serviceCallDispatchEquipmentMaintenanceTaskProxy.SaveTasks(dispatchId, tasks, copyValuesForSameMaintenance);
    }
}
