import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { inject } from "aurelia-framework";

import subcontractorService from "services/subcontractorService";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import settingHelper from "helpers/settingHelper";
import queryStringHelper from "helpers/queryStringHelper";


@inject(I18N)
export class Subcontractor {
    dateHelper = dateHelper;
    dispatchDate;
    dispatchProjectCode;
    isProjectResponsible = false;
    readonly = false;
    dispatchModel = "";
    subcontractors = [];
    contacts = [];
    hasInterveners = false;
    i18n;
    queryString;

    constructor(i18n) {
        this.i18n = i18n;
    }

    bindViewModel(dispatchProjectCode, dispatchDate, querystring) {
        this.dispatchModel = settingHelper.getSelectedDispatchModel();
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
        this.queryString = querystring;

        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
    }

    mapLoadedData(loadedData) {
        this.subcontractors = loadedData.Subcontractors;
        this.contacts = loadedData.Contacts;
        this.hasInterveners = this.subcontractors.length > 0 || this.contacts.length > 0;
    }

    loadData() {
        return subcontractorService.getAllForTemplate(this.dispatchProjectCode, this.dispatchDate, this.dispatchModel)
            .done(data => {
                this.mapLoadedData(data);
            });
    }


    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }
        return true;
    }

    activate(params) {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        return this.loadData();
    }

    delete(item) {
        notificationHelper.showDeleteConfirmation().done((success) => {
            if (success) {
                routerHelper.showLoading();
                subcontractorService.deleteItem(this.dispatchProjectCode, this.dispatchDate, this.dispatchModel, item.detail.Line)
                    .done((data) => {
                        this.mapLoadedData(data);
                    })
                    .always(() => {
                        routerHelper.hideLoading();
                    });
            }
        });
    }

    gotoAddPage() {
        routerHelper.navigateToRoute("Project_Detail_Daily_Entry_Subcontractor_Edit", {
            dispatchProjectCode: this.dispatchProjectCode,
            dailyEntryDate: this.dispatchDate,
            type: 0,
            q: this.queryString
        });
    }
}
