import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";

import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { WorkOrderWorkTaskDocumentProxy } from "api/proxies/work-order-work-task-document-proxy";
import { DocumentModel } from "api/models/company/document/document-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { ListDocumentModel } from "api/models/company/document/list-document-model";

@autoinject()
export class WorkOrderWorkTaskDocumentService {
    constructor(private readonly workOrderWorkTaskDocumentProxy: WorkOrderWorkTaskDocumentProxy) {}

    public async AddDocumentForWorkTask(transactionNumber: number, models: Array<DocumentModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
     return this.workOrderWorkTaskDocumentProxy.AddDocumentForWorkTask(transactionNumber, models, requestConfig);
    }

    public async GetSectionsForWorkTask(transactionNumber: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        return this.workOrderWorkTaskDocumentProxy.GetSectionsForWorkTask(transactionNumber, filter, pagingInfo, requestConfig);
    }

    public async GetDocumentsForWorkTask(transactionNumber: number, sectionId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ListDocumentModel[] | null> {
       return this.workOrderWorkTaskDocumentProxy.GetDocumentsForWorkTask(transactionNumber, sectionId, filter, pagingInfo, requestConfig);
    }

    public async DeleteDocument(transactionNumber: number, documentId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.workOrderWorkTaskDocumentProxy.DeleteDocument(transactionNumber, documentId, requestConfig);
    }
}
