import { autoinject, bindable, transient } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import labelHelper from "helpers/labelHelper";
import _ from "underscore";

@transient()
@autoinject
export class TimesheetEmployeeSummary {

    @bindable public items: any;
    public labors: any;
    public equipments: any;
    public primes: any;

    constructor(private readonly i18n: I18N) {
    }

    public bind(): any {
        this.loadData(this.items);
    }

    public loadData(items: any): any {
        this.labors = this.mapForType(items, enumHelper.timesheetEntryType.LABOR);
        this.equipments = this.mapForType(items, enumHelper.timesheetEntryType.EQUIPMENT);
        this.primes = this.mapForType(items, enumHelper.timesheetEntryType.BONUS);
    }

    public mapForType(items: any, timesheetEntryType: any): any {
        const type = _.find(items, (item: any) => item.Key === timesheetEntryType);

        if (type) {
            type.Items = _.map(type.Items, (day: any) => this.mapDayOfWeek(day));
            type.hours = dateHelper.hoursTohmm(type.TotalHours);
        }

        return type;
    }

    public mapDayOfWeek(item: any): any {
        item.title = dateHelper.getDayOfWeek(item.Key);
        item.Items = _.map(item.Items, (proj: any) =>  this.mapProj(proj));
        return item;
    }

    public mapProj(item: any): any {
        item.title = item.Key.Id + " - " + item.Key.Description;
        item.Items = _.map(item.Items, (timeEntry: any) => this.mapTimeEntry(timeEntry));
        return item;
    }

    public mapTimeEntry(item: any): any {
        item.activity = item.EmployeeActivity + " - " + item.EmployeeActivityDescription;
        item.equipment = item.EquipmentId + " - " + item.EquipmentDescription;
        item.equipmentActivity = item.EquipmentActivity + " - " + item.EquipmentActivityDescription;
        item.bonus = item.Bonus + " - " + item.BonusDescription;
        item.bonusQuantity = item.BonusUnit ? item.BonusQuantity + " " + item.BonusUnit : item.BonusQuantity;
        item.hours = dateHelper.hoursTohmm(item.Hours);

        item.rateType = labelHelper.getRateTypeLabel(item.RateType);
        item.comment = item.Comment;

        return item;
    }

}
