import { Viewbag } from "helpers/view-bag";
import queryStringHelper from "helpers/queryStringHelper";
import notificationHelper from "helpers/notificationHelper";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import moment from "moment";
import routerHelper from "helpers/routerHelper";
import _ from "underscore";
import templateService from "services/templateService";
import { TimeListHelper } from "helpers/time-list-helper";

@autoinject()
export class DailyEntryBreakEntryEdit {

    public dateHelper: typeof dateHelper = dateHelper;
    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public dispatchId: number = 0;
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public lunchEntry: any;
    public breakEntries: any;
    public manualBreaksManagementEnabled: boolean = false;
    public entry: any;

    public lunchStartTime: any;
    public lunchEndTime: any;
    public lunchDescription: any;
    public breaks: any[] = [];

    public timeOptions: any;

    constructor(private readonly i18n: I18N, public viewbag: Viewbag) {}

    public async activate(params: any): Promise<any> {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.dispatchId, params.q);

        this.timeOptions = TimeListHelper.loadTimeList(await templateService.getUserTemplateIncrement());
        this.loadData();
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: any, dispatchId: number, querystring: string): void {
        this.manualBreaksManagementEnabled = queryStringHelper.parseManualBreaksManagementEnabled(querystring);
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
        this.readonly = queryStringHelper.parseReadonly(querystring) || this.manualBreaksManagementEnabled;

        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchId = dispatchId;

        this.entry = this.findEmployeeEntry(dispatchId, this.viewbag.value.list);
    }

    public loadData(): any {
        this.setBreakEntries(this.entry);
        this.setLunchEntry(this.entry);

        if (!this.lunchEntry && this.breakEntries.length === 0) {
            routerHelper.navigateBack();
            return false;
        }

        return true;
    }

    public updateValues(): void {
        if (this.isBreaksValid() && this.isLunchTimeValid()) {
            this.updateDisplayTimes();
            routerHelper.navigateBack();
        }
    }

    public deleteLunch(): void {
        this.lunchEntry.isDeleted = true;
    }

    public deleteBreak(item: any): void {
        item.isDeleted = true;
    }

    public findEmployeeEntry(id: any, entries: any): any {
        return _.find(entries,
            (item: any) => {
                return item.dispatchId === +id;
            });
    }

    public updateDisplayTimes(): void {
        this.entry.activities.forEach((item: any) => {
            item.startTimeDisplay = dateHelper.getTime(item.startTime);
            item.endTimeDisplay = dateHelper.getTime(item.endTime);
        });
    }

    public setLunchEntry(entry: any): void {
        this.lunchEntry = _.find(entry.activities, (activity: any) => {
                return activity.isNewLunch;
        });

        if (this.lunchEntry) {
            this.lunchEntry.isDeleted = false;
        }
    }

    public setBreakEntries(entry: any): any {
        this.breakEntries = _.filter(entry.activities,
            (activity: any) => {
                return activity.isNewBreak;
            });

        this.breakEntries.forEach((brk: any) => {
            brk.isDeleted = false;
        });
    }

    public isLunchTimeValid(): boolean {
        const startTime = moment(this.lunchStartTime);
        const endTime = moment(this.lunchEndTime);
        if (this.isDurationMoreThan4Hours(startTime, endTime)) {
            notificationHelper.showError(this.i18n.tr("err_LunchTimeCanNotExceed4Hours"));
            return false;
        }
        return true;
    }

    public isBreaksValid(): boolean {
        this.breakEntries.forEach((brk: any, i: number): any => {
            if (this.isDurationMoreThan4Hours(moment(brk.startTime), moment(brk.endTime))) {
                notificationHelper.showError(this.i18n.tr("err_BreakTime" + (i + 1) + "CanNotExceed4Hours"));
                return false;
            }
        });
        return true;
    }

    public isDurationMoreThan4Hours(startTime: moment.Moment, endTime: moment.Moment): boolean {
        let duration = moment.duration(endTime.diff(startTime));

        if (endTime < startTime) {
            endTime.add(1, "days");
            duration = moment.duration(endTime.diff(startTime));
        }

        if (duration.asHours() > 24) {
            endTime.add(-1, "days");
            duration = moment.duration(endTime.diff(startTime));
        }

        if (duration.asHours() > 4) {
            return true;
        }

        return false;
    }
}
