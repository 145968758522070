import BaseCellEditor from "ag-grid/editors/baseCellEditor";

export default class CheckBoxCellEditor extends BaseCellEditor  {
    constructor(){
        super();
    }

    inputElement = document.createElement("input");
    divElement = document.createElement("div");

    // Gets called once before the renderer is used
    init(params) {
        super.init(params);
        
        this.inputElement.type = "checkbox";
        
        this.divElement.classList.add("cell-checkbox");
        this.divElement.appendChild(this.inputElement);

        this.toggleInputElement(params);
    }

    // Gets called once when grid ready to insert the element
    getGui() {
        return this.divElement;
    }
   
    // Returns the new value after editing
    getValue() {
        return this.inputElement.checked;
    }

    // when we tab onto this editor, we want to focus the contents
    focusIn() {
        const eInput = this.inputElement;
        eInput.focus();
        eInput.select();
        this.focusOut();
    }

    // when we tab out of the editor, this gets called
    focusOut() {
        const eInput = this.inputElement;
        eInput.blur();
    }

    toggleInputElement(params) {
        if (params.value === true || params.value === 1 || params.value === "true" || params.value === "1") {
            this.inputElement.checked = false;
        } else {
            this.inputElement.checked = true;
        }
    }
}

