define([
    "services/requests",
    "helpers/apiHelper",
    "jquery"
], function (requests, apiHelper, jQuery) {
    "use strict";

    var exports = {
        // fieldservices/workorders/{workOrderId}
        complete: function (workOrderId) {
            return requests.post(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/complete");
        },

        get: function (workOrderId) {
            return requests.getJson(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId));
        },

        last: function () {
            return requests.getJson(apiHelper.getFieldServiceWorkOrderBaseUrl() + "/last");
        },

        list: function (isCompleted, page) {
            var url = apiHelper.getFieldServiceWorkOrderBaseUrl();

            url += '?isCompleted=' + isCompleted;

            if (page) {
                url += "&page=" + page;
            }

            return requests.getJson(url);
        },

        listOriginalLookup: function (filter, page) {
            var url = apiHelper.getFieldServiceBaseUrl() + "/originalworkorders/lookup";

            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        listTypes: function (filter, page) {
            var url = apiHelper.getWorkOrderBaseUrl() + "/types";

            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        listProgressStateLookup: function (filter, page) {
            var url = apiHelper.getWorkOrderBaseUrl() + "/progressstatuses";

            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        printForm: function (workOrderId, data) {
            return requests.postAndForget(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/printform", data);
        },

        set: function (dispatchTemplate, data) {
            return requests.post(apiHelper.appendQueryStringParameters(apiHelper.getFieldServiceWorkOrderBaseUrl(), {"dispatchTemplateId":dispatchTemplate}), data);
        },

        sign: function (workOrderId, dispatchTemplate, data) {
            return requests.post(apiHelper.appendQueryStringParameters(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/signature",{ "templateCode": dispatchTemplate }), data);
        },

        createCopy: function (workOrderId, copyAttendances, copyTimesheets, copyMaterials, startHour, endHour) {
            return requests.post(apiHelper.appendQueryStringParameters(apiHelper.getFieldServiceWorkOrderBaseUrl() + "/copy", {
                "sourceWorkOrderId" : workOrderId, 
                "copyAttendances" : copyAttendances, 
                "copyTimeEntries": copyTimesheets,
                "copyMaterials": copyMaterials,
                "startHour": startHour,
                "endHour": endHour
            }));
        },

        listLast5Workorder: function () {
            return requests.getJson(apiHelper.appendQueryStringParameters(apiHelper.getFieldServiceWorkOrderBaseUrl() + "/last", {
                "page": 1,
                "pageSize":5
            }));
        },

        isOldworkOrder: function (workOrderId) {
            return requests.getJsonNoLoading(apiHelper.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/isOld");
        }
    };

    return exports;
});
