import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

@autoinject()
export class MainMenuItem {
    @bindable.string()
    public href: string = "";

    @bindable.string()
    public iconClass: string = "";

    @bindable.string()
    public titleKey: string = "";
}
