const defaultTimer: number = 3600000; // 1 heure par défaut (3600000ms)

export class AutoReloadHelper {
    
    private time: number = defaultTimer;
    private timers: NodeJS.Timer[] = [];
    
    constructor() {
        this.init();
    }

    public init(time: number = defaultTimer, events: string[] = ["mousemove"]): void {
        this.time = time;

        this.resetTimer();

        this.startEventsListeners(events);
    }

    public resetTimer(): void {
        this.stopTimer();

        const timer = setTimeout(this.reload,
                                 this.time)!;
        this.timers.push(timer);
    }

    public stopTimer(): void {
        this.timers.forEach(this.clearTimeout);
    }

    private startEventsListeners(events: string[]): void {
        events.forEach((eventName: string): void => window.addEventListener(eventName, this.resetTimer.bind(this)));
    }

    private reload(): void {
        location.reload();
    }

    private clearTimeout(timer: NodeJS.Timer): void {
        clearTimeout(timer);
    }
}
