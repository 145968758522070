import { autoinject, bindable } from "aurelia-framework";

import documentHelper from "helpers/documentHelper";

import documentService from "services/documentService";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";

import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";

@autoinject()
export class InventoryProjectTransferDocuments {
    public actions: any;

    @bindable public transfer!: InventoryProjectTransferDetailsModel | null;

    public readonly: boolean = false;

    constructor( private readonly inventoryProjectTransferService: InventoryProjectTransferService) {}

    public async activate(params: any): Promise<void> {
        this.transfer = await this.inventoryProjectTransferService.GetTransfer(params.transferId);

        this.actions = {
            getSections: documentService.getSectionsByProjectTransferId.bind(self, this.transfer!.Id),
            getSectionDocuments: documentService.getDocumentsByProjectTransferId.bind(self, this.transfer!.Id),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService),
        };
    }
}
