import { singleton } from "aurelia-dependency-injection";

@singleton()
export class Nonce {
    private value: number = Date.now();

    public getNext(): number {
        return ++this.value;
    }
}
