// GENERATED FILE - DO NOT MODIFY
export enum ProjectGroupType {
    Billing = "2",
    Misc = "D",
    Equipment = "E",
    Labour = "L",
    Material = "M",
    Subcontract = "S",
}

export let nameof = "ProjectGroupType";
