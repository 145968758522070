import { bindingMode, transient, autoinject } from "aurelia-framework";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";
import { TimeListHelper, TimeListItem } from "helpers/time-list-helper";
import { TimeListParameters } from "helpers/time-list-parameters";

@transient()
@autoinject()
export class TimePicker extends FormControl {
    @bindable.nullable_number
    public increment: number | null = null;
    
    @bindable
    public parameters: TimeListParameters = new TimeListParameters();

    @observable.string()
    public internalValue: string | null = null;

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public value: string | null = null;

    @bindable
    public options: TimeListItem[] = [];

    @bindable.booleanAttr
    public disabled: boolean = false;

    constructor(element: Element) {
        super(element);
    }

    public bind(): void {
        this.parameters.increment = this.getIncrement();

        if (this.options.length === 0) {
            this.options = this.getOptions();
        }
    }

    private getIncrement(): number {
        if (!!this.parameters.increment) {
            return this.parameters.increment;
        }

        if (this.increment) {
            return this.increment;
        }

        return 30;
    }

    private getOptions(): TimeListItem[] {
        return TimeListHelper.loadTimeListWithParameters(this.parameters);
    }
}
