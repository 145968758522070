import { autoinject } from "aurelia-framework";
import documentService from "services/documentService";
import { SettingRepository } from "repositories/setting-repository";
import { I18N } from "aurelia-i18n";

import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";
import routerHelper from "helpers/routerHelper";
import { DispatchTemplateService } from "services/dispatch-template-service";

@autoinject()
export class ServiceMaterialRequisitionEditDocumentAdd {
    public editId: number = 0;
    public readonly: boolean = false;
    public actions: any;
    public dispatchId: number = 0;
    public isDirty: boolean = false;
    public isCategorySelectionAllowed: boolean = false;

    constructor(
        private readonly settingRepository: SettingRepository, private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router, private readonly dispatchTemplateService: DispatchTemplateService
    ) { }

    public async activate(params: any): Promise<any> {
        this.editId = params.requisitionId;
        this.dispatchId = params.dispatchId;
        const dispatchTemplateId = this.settingRepository.getDispatchTemplate();

        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(dispatchTemplateId!);
        this.isCategorySelectionAllowed = dispatchTemplate!.AllowCategorySelection;

        this.actions = {
            save: documentService.saveMaterialRequisitionPicture.bind(this, params.requisitionId, dispatchTemplateId)
        };
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }
}
