import { inject, NewInstance } from 'aurelia-framework';
import { I18N } from "aurelia-i18n";
import { ValidationRules, ValidationController} from 'aurelia-validation';
import { UserService } from 'services/user-service';
import { NotificationHelper } from 'helpers/notification-helper';
import { ErrorManager } from "error-management/error-manager";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import UserSettingService from "services/userSettingsService";
import { default as labelHelper } from "helpers/labelHelper";

@inject(UserService, NotificationHelper, I18N, ErrorManager, Router, EventAggregator, NewInstance.of(ValidationController))
export class Login {
    username = "";
    password =  "";
    newPassword = "";
    newPasswordConfirmation = "";
    isPasswordReset = false;
    showLoadingOverlay = false;
    subscriptions = [];

    constructor(userService, notificationHelper, i18n, errorManager, router, eventAggregator, validationController) {
        this.userService = userService;
        this.notificationHelper = notificationHelper;
        this.i18n = i18n;
        this.errorManager = errorManager;
        this.router = router;
        this.eventAggregator = eventAggregator;
        this.validationController = validationController;

        ValidationRules
            .ensure("username").required().withMessageKey("UserNameRequired")
            .ensure("password").required().withMessageKey("PasswordRequired")
            .ensure("newPassword").displayName("NewPassword").required().withMessageKey("NewPasswordRequired")
            .then()
            .satisfies((value, m) => {
                return value == m.newPasswordConfirmation;
            })
            .withMessageKey("err_ConfirmPasswordDoesNotMatch")
            .when(m => m.isPasswordReset)
            .on(this);
    }

    activate() {
        this.subscriptions.push(this.eventAggregator.subscribe("overlay:loading:visible", (visible) => { this.showLoadingOverlay = visible; }));
    }

    detached() {
        this.subscriptions.forEach((subscription) => subscription.dispose());
    }

    reloadApp() {
        window.location.reload(true);
    }

    focusOnNewPasswordField() {
        // TODO: Ne pas utiliser jQuery pour ça.
        jQuery("#newPassword").focus();
    }

    tryLogin() {
        return this.validationController.validate()
            .then(result => {
                if (result.valid) {
                    if (this.isPasswordReset) {
                        return this.changePassword();
                    } else {
                        return this.login();
                    }
                }
                else {
                    return new Promise(resolve => resolve({}));
                }
            });
    }

    changePassword() {
        return this.userService.changePassword(this.username, this.password, this.newPassword)
            .then(async () => {
                if (await this.validateAccessType()) {
                    this.reloadApp();
                } else {
                    this.userService.logout();
                }
            }, (error) => {
                // TODO: Pas très beau car on n'a pas le nouveau code du error-manager dans 3.04. Dans 3.05 cette vérification pourra être faite en appelant les fonctions du error-manager pour extraire l'exception et en utilisant les valeurs de l'enum ValidationError pour les numéros d'erreurs.
                if (error.status === 402 &&
                    error.responseJSON &&
                    error.responseJSON.Exception && [496, 497, 498].includes(error.responseJSON.Exception.ErrorId)) {
                    //
                } else {
                    this.errorManager.handleHttpRequestError(error);
                }
            });
    }

    async validateAccessType() {
        const userAccessType = await UserSettingService.getUserAccessType(this.username);
        const isUserAccessToMobileAllowed = parseInt(userAccessType) > 0;

        if (!isUserAccessToMobileAllowed) {
            const languageSetting = await UserSettingService.getUserLanguage(this.username);
            const language = languageSetting !== null ? languageSetting.LanguageId : 'fr';

            await this.notificationHelper.showDialogOk(this.i18n.tr("err_NoWebAccess", {lng: language }));
        }

        return isUserAccessToMobileAllowed;
    }

    login() {
        return this.userService.login(this.username, this.password)
            .then(async () => {
                if (await this.validateAccessType()) {
                    this.reloadApp();
                } else {
                    this.userService.logout();
                }
            }, (error) => {
                if (error.status === 400)
                {
                    switch (error.responseJSON.error) {
                        case "first_login":
                        case "change_password_required":
                            this.isPasswordReset = true;
                            this.focusOnNewPasswordField();
                            this.notificationHelper.showInfo(this.i18n.tr("err_ChangePasswordRequired"));
                            break;
                        case "no_web_access":
                            this.notificationHelper.showWarning(this.i18n.tr("err_NoWebAccess"));
                            break;
                        default:
                            this.notificationHelper.showWarning(this.i18n.tr("err_UserPwdInvalid"));
                    }
                }
                else
                {
                    this.errorManager.handleHttpRequestError(error);
                }
            });
    }
}