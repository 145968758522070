import { customElement, autoinject, transient, containerless, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { SynchronizationGrid } from "../synchronization-grid";
import { PropertyGetterGroupedWithParent } from "./composition/property-getter-grouped-with-parent";
import { IItemGroupedWithParent } from "./interfaces/IItemGroupedWithParent";
import { UserGridStateService } from "services/user-grid-state-service";
import { ProcoreDifferenceModel } from "api/models/procore/preview/procore-difference-model";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
@customElement("projects-synchronization-grid")
@containerless
export class ProjectsSynchronizationGrid extends SynchronizationGrid {
    @bindable
    public procoreErrorGridOptions?: any;

    @bindable
    public items: ProcoreDifferenceModel[] = []; // On doit ajouter items ici, puisque sinon on ne peut pas utiliser itemsChanged ici.
    public originalColumnDefs?: any[];

    constructor(element: Element,
                i18n: I18N,
                userGridStateService: UserGridStateService,
                eventAggregator: EventAggregator,
                public readonly propertyGetterGroupedWithParent: PropertyGetterGroupedWithParent) {

        super(element, i18n, userGridStateService, eventAggregator);
    }

    public itemsChanged(items: ProcoreDifferenceModel[], oldValue: ProcoreDifferenceModel[]): void {
        this.internalItems = this.items.map((item: ProcoreDifferenceModel): ProcoreDifferenceModel => item);

        if (!items || items.length === 0) {
            return;
        }

        this.addParentsProperties(items);
        this.createGroupColDef(items);

        this.removeHiddenProjects(items);
    }

    private createGroupColDef(items: ProcoreDifferenceModel[]): void {
        if (!this.gridOptions || !this.gridOptions.columnDefs) {
            return;
        }

        if (!this.originalColumnDefs) {
            this.originalColumnDefs = this.gridOptions.columnDefs.map((colDef: any): any => colDef);
        }

        const maxHierarchyLevel = this.MaxHierarchyLevel(items);

        const columnDefs = this.addGroupingColumns(maxHierarchyLevel);

        this.gridOptions.api.setColumnDefs(columnDefs);
    }

    private addGroupingColumns(maxHierarchyLevel: number): any[] {
        const columnDefs = this.originalColumnDefs!.map((colDef: any): any => colDef);

        if (maxHierarchyLevel < 1) {
            return columnDefs;
        }

        for (let i = 1; i <= maxHierarchyLevel; i++) {
            const newColumnsToAdd = this.getGroupingColumns(`parent${i}`);

            columnDefs.push(newColumnsToAdd.Column1);
            columnDefs.push(newColumnsToAdd.Column2);
        }

        return columnDefs;
    }

    private MaxHierarchyLevel(items: ProcoreDifferenceModel[]): number {
        let maxHierarchyLevel = 0;

        items.forEach((item: any): void => {
            if (item &&
                maxHierarchyLevel < item.hierarchyLevel) {
                maxHierarchyLevel = item.hierarchyLevel;
            }
        });

        return maxHierarchyLevel;
    }

    private getGroupingColumns(parentProperty: string): any {
        return {

            Column1: {
                headerName: "",
                showRowGroup: parentProperty,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: {
                    suppressPadding: true,
                    suppressCount: true,
                    innerRenderer: (): string => "",
                },
                suppressMovable: true,
                suppressMenu: true,
                lockPosition: true,
                editable: false,
                width: 25
            },

            Column2: {
                hide: true,
                rowGroup: true,
                field: parentProperty
            }
        };
    }

    private addParentsProperties(items: ProcoreDifferenceModel[]): void {
        const parentsToRemove: number[] = [];

        items.forEach((item: any): void => {
            const hasParent = !!item.Parent;

            if (hasParent) {
                this.generateParentsList(item, item.Parent, items, 1);
                this.generateParentsProperties(item);

                this.getParentsToRemove(item, items).forEach((parent: any) => {
                    parentsToRemove.push(parent);
                });
            }
        });

        this.removeParents(parentsToRemove, items);
    }

    private getParentsToRemove(item: any, items: any): any[] {
        const parentsToRemove: any[] = [];

        item.parents.forEach((parent: any): void => {
            const parentsFound = items.filter((x: any) => x[this.FindPropertyName(parent)] === parent[this.FindPropertyName(parent)]);

            parentsFound.forEach((parentFound: any) => {
                parentsToRemove.push(parentFound);
            });
        });

        return parentsToRemove;
    }

    private generateParentsList(item: any, parent: any, list: any[], i: number): void {
        if (!item.parents) {
            item.parents = [];
        }

        item.parents.push(parent);

        if (parent.Parent) {
            this.generateParentsList(item, parent.Parent, list, ++i);
        }
    }

    private generateParentsProperties(item: any): void {
        if (!item.parents) {
            return;
        }

        item.parents = item.parents.reverse();
        
        let i = 1;
        item.parents.forEach((parent: any): any => {
            const propertyName = this.FindPropertyName(parent);
            if (item) {
                item["parent" + i] = parent[propertyName];
                item.hierarchyLevel = i;
            }
            
            i++;
        });
    }

    private removeParents(parentsToRemove: any[], items: ProcoreDifferenceModel[]): void {
        parentsToRemove.sort();
        parentsToRemove.reverse();
        
        this.items.splice(0, this.items.length); // On vide le array

        const newItems = this.internalItems!.map((item: any) => {
            const propertyName = this.FindPropertyName(item);

            const isParent = parentsToRemove.some((parent: any) => parent[propertyName] === item[propertyName]);

            if (isParent) {
                item.Hidden = true;
            }

            return item;
          });

        newItems.forEach((newItem: ProcoreDifferenceModel) => {
            this.items.push(newItem);
        });
    }

    private FindPropertyName(item: ProcoreDifferenceModel): string {
        return item.MaestroIdentifier ? "MaestroIdentifier" : "ProcoreIdentifier";
    }

    private removeHiddenProjects(items: ProcoreDifferenceModel[]): void {
        if (!this.internalItems ||
            !items || items.length === 0) {
                return;
        }
        
        items.splice(0, items.length); // On vide le array

        this.internalItems.forEach((item: any) => {
            if (!item.Hidden) {
                items.push(item);
            }
        });
    }
}
