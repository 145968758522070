import { WorkOrderWorkTaskDocumentService } from "services/work-order-work-task-document-service";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class WorkTaskEditDocumentsAdd {

    public actions: any;
    public transactionNumber: number = 0;
    public isDirty: boolean = false;

    constructor(private readonly i18n: I18N, private readonly workOrderWorkTaskDocumentService: WorkOrderWorkTaskDocumentService, private readonly notificationHelper: NotificationHelper, private readonly router: Router) {}

    public async activate(params: any): Promise<void> {
        this.transactionNumber = params.transactionNumber;
        this.actions = { save: (models: any): JQueryDeferred<any> => {
            const dfd = jQuery.Deferred();
            this.workOrderWorkTaskDocumentService.AddDocumentForWorkTask(this.transactionNumber, models).then(() => {
                return dfd.resolve();
            });
            return dfd;
         }};
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }
}
