import { autoinject, bindable } from "aurelia-framework";
import { WorkOrderMaintenanceModel } from "api/models/company/workorder/work-order-maintenance-model";
import { nameof as nameof_WorkOrderMaintenanceType } from "api/enums/work-order-maintenance-type";
import { I18N } from "aurelia-i18n";

@autoinject()
export class WorkOrderMaintenanceHeader {
    public nameof_WorkOrderMaintenanceType: string = nameof_WorkOrderMaintenanceType;

    @bindable
    public readonly: boolean = false;
    @bindable
    public maintenance: WorkOrderMaintenanceModel | null = null;
    @bindable
    public isCounterVisible: boolean = true;

    public readonly i18n: I18N;

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }
}
