import documentService from "services/documentService";
import documentHelper from "helpers/documentHelper";

export class PhysicalInventoryDocument {
    public readonly: boolean = false;
    public physicalInventoryId: number = 0;
    public actions: any;

    public async activate(params: any): Promise<void> {
        this.physicalInventoryId = params.id;
       
        this.actions = {
            getSections: documentService.getSectionsByPhysicalInventoryId.bind(self, this.physicalInventoryId),
            getSectionDocuments: documentService.getDocumentsByPhysicalInventoryId.bind(self, this.physicalInventoryId),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService)
        };
    }
}
