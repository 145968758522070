import { autoinject, bindingMode, customElement, containerless } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";

@autoinject()
@containerless
@customElement("textbox2")
export class Textbox2 extends FormControl {

    @bindable.booleanAttr
    public required: boolean = false;

    @bindable
    public class: string = "";

    @bindable.string //
    public icon: string = "";

    // Set to true to position the icon before the input instead of after. Default is Append
    @bindable.booleanAttr //
    public prependIcon: boolean = false;

    @bindable.number({ defaultValue: -1 }) //
    public maxlength!: number;

    @bindable.string //
    public placeholder: string = "";

    @bindable.booleanAttr
    public showClearButton: boolean = false;

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public value: string = "";

    constructor(element: Element) {
        super(element);
    }

    public clear(event: Event): void {
        event.stopPropagation();
        this.value = "";
    }
}
