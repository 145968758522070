define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        criteriasExist: function () {
            return requests.getJson(apiHelper.getBaseUrl() + '/evaluationCriteriasExists');
        },

        getAllCriterias: function () {
            return requests.getJson(apiHelper.getBaseUrl() + '/evaluationCriterias');
        },

        addEmployeeEvaluation: function (dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getBaseUrl() + "/employees/evaluation/projects/" + dispatchProjectCode + "/dispatchdates/" + dispatchDate, data);
        },

        getEmployeeEvaluation: function(date) {
            return requests.getJson(apiHelper.getBaseUrl() + '/employees/evaluation?evaluationDate=' + date);
        }
    };

    return exports;
});
