// GENERATED FILE - DO NOT MODIFY
import { CatalogItemTypeModel } from "api/models/company/catalog/catalog-item-type-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class CatalogInventoryLocationProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetLocations(prefix: string | null | undefined, siteId: string | null | undefined, typeItem: string | null | undefined, itemCategory: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemTypeModel[] | null> {
        const uri = this.buildUri(
            "/catalog-inventory-location",
            null,
            { prefix: (prefix === null || prefix === undefined) ? undefined : prefix, siteId: (siteId === null || siteId === undefined) ? undefined : siteId, typeItem: (typeItem === null || typeItem === undefined) ? undefined : typeItem, itemCategory: (itemCategory === null || itemCategory === undefined) ? undefined : itemCategory, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemTypeModel[] | null = await this.get<CatalogItemTypeModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemTypeModel");
        return data;
    }
}
