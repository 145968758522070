import { autoinject } from "aurelia-framework";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { SelectItem } from "models/select-item";
import { EnumConfig } from "converters/enums/enum-format";

@autoinject()
export class EnumFormatWithIdValueConverter {
    private readonly enumFormatValueConverter: EnumFormatValueConverter;

    constructor(enumFormatValueConverter: EnumFormatValueConverter) {
        this.enumFormatValueConverter = enumFormatValueConverter;
    }

    public toView(value: number | string | null, config: EnumConfig | string ): string {
        if (!value) {
            return "";
        }

        return (
            value +
            " - " +
            this.enumFormatValueConverter.toView(value, config)
        );
    }

    public toSelectItem(value: number | string, config: EnumConfig | string): SelectItem<any> {
        return new SelectItem(
            value,
            (x: number | string): string => x + "",
            (x: number | string): string => this.toView(x, config)
        );
    }
}
