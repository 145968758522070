import { autoinject, bindingMode, customElement, containerless } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { FormControl } from "components/form-controls/form-control";

@autoinject()
@customElement("text-label")

export class TextLabel extends FormControl {

    @bindable.booleanAttr
    public boldLabel: boolean = false;

    @bindable.booleanAttr
    public boldText: boolean = false;

    @bindable
    public class: string = "";

    @bindable.string({ defaultBindingMode: bindingMode.twoWay })
    public value: string = "";

    constructor(element: Element) {
        super(element);
    }
}
