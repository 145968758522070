import { TradeModel } from "api/models/company/trade-model";
import { TradeService } from "services/trade-service";
import { BonusService } from "services/bonus-service";

import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { PagingInfo } from "api/paging-info";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemBase } from "pages/services/quotations/item-base";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { inject, NewInstance } from "aurelia-dependency-injection";
import { ServiceCallOccupationCategoryModel } from "api/models/company/service-call/service-call-occupation-category-model";
import { ServiceCallOccupationCategoryService } from "services/service-call-occupation-category-service";
import { BonusConfigModel } from "api/models/company/bonus/bonus-config-model";

@inject(NewInstance.of(ValidationController))
export class QuotationItemBonusBase extends QuotationItemBase {
    constructor(
        i18n: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController,
        private readonly bonusService: BonusService,
        private readonly tradeService: TradeService,
        private readonly serviceCallOccupationCategoryService: ServiceCallOccupationCategoryService
    ) {
        super(i18n, serviceCallQuotationService, serviceCallNonBillingReasonService);
    }

    public onSelectedBonusChanged(event: CustomEvent<BonusConfigModel>): void {
        const bonus = event ? event.detail : null;
        this.quotationItem.BonusId = bonus ? bonus.Code : null;
        this.quotationItem.BonusDescription = bonus ? bonus.Description : "";
    }

    public onSelectedTradeChanged(event: CustomEvent<any>): void {
        const trade = event ? event.detail : null;
        this.quotationItem.TradeId = trade ? trade.Code : null;
        this.quotationItem.TradeDescription = trade ? trade.Description : null;
    }

    public onSelectedOccupationCategoryChanged(event: CustomEvent<ServiceCallOccupationCategoryModel>): void {
        if (this.quotation === null || this.quotationItem === null) {
            return;
        }

        const serviceCallOccupationCategory = event ? event.detail : null;
        this.quotationItem.OccupationCategoryId = serviceCallOccupationCategory ? serviceCallOccupationCategory.Id : 0;

        this.quotationItem.OccupationCategoryDescription = serviceCallOccupationCategory ? serviceCallOccupationCategory.Description : "";
    }

    public async populateOccupationCategories(filter: string, pagingInfo: PagingInfo): Promise<ServiceCallOccupationCategoryModel[]> {
        return await this.serviceCallOccupationCategoryService.getOccupationCategories(null, filter, pagingInfo);
    }

    public async populateBonus(filter: string, pagingInfo: PagingInfo): Promise<BonusConfigModel[] | null> {
        return await this.bonusService.GetConfig(filter, pagingInfo);
    }

    public async populateTrades(filter: string, pagingInfo: PagingInfo): Promise<TradeModel[] | null> {
        return await this.tradeService.GetTrades(filter, pagingInfo);
    }

    protected initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: ServiceCallQuotationItemModel) => x.BonusId).required().withMessageKey("err_BonusRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.RateType).required().withMessageKey("err_RateTyoeRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.TradeId).required().withMessageKey("err_TradeRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.OccupationCategoryId).required().withMessageKey("err_OccupationCategoryRequired")
            .on(this.quotationItem);
    }

    protected async validate(): Promise<boolean> {
        return await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
    }
}
