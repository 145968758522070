import { transient, bindable, inject } from "aurelia-framework";
import _ from "underscore";
import jQuery from "jquery";
import GuidHelper from "helpers/guidHelper";

@transient()
@inject(GuidHelper)
export class AutoShowSection {
    @bindable topOffset = 100;
    @bindable showPredicate;
    show = false;
    guid = null;
    timerId = null;

    constructor(guidHelper) {
        // Use the default predicate if none was specified.
        this.showPredicate = this.defaultShowPredicate;

        this.guid = guidHelper.createGuid();
    }

    attached() {
        this.update();

        // Listen for window resizes.
        jQuery(window).on(`resize.${this.guid}`, () => this.update());

        // Update at an interval.
        this.timerId = window.setInterval(() => this.update(), 250);
    }

    detached() {
        // Stop listening for window resizes.
        jQuery(window).off(`resize.${this.guid}`);

        // Stop updating at an interval.
        if (this.timerId) {
            window.clearInterval(this.timerId);
            this.timerId = null;
        }
    }

    update() {
        this.show = _.isFunction(this.showPredicate) && this.showPredicate();
    }

    defaultShowPredicate() {
        // By default, show the element if the total document height is greater than the viewport height (plus an offset).
        const totalDocumentHeight = document.body.clientHeight || 0;
        const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        return totalDocumentHeight > (viewportHeight + Math.max(this.topOffset, 0));
    }
}