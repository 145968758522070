import { inject, bindable, DOM } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as browserHelper } from "helpers/browserHelper";
import { default as logger } from "core/logger";
import BareCodeReader  from "components/others/dynamsoft-customized";
import config from "config";

@inject(Element, I18N)
export class Scanner {
    ScanFrequency = 10;                // Maximum number of scans per second
    MaxScanArrayLength = 3;            // Number of scans to keep in an array to find the best one
    MinAcceptableCodeScanCount = 2;    // Minimum number of times a same code has to appear in the array to be considered acceptable

    element;
    i18n;
    isRunning = false;
    results = [];
    processResultCallback = (result) => this.triggerOnScanEvent(result);
   
    constructor(element, i18n) {
        this.element = element;
        this.i18n = i18n;

        BareCodeReader.licenseKey = config.dynamsoftLicenseKey;
        const self = this;
        this.scanner = new BareCodeReader.Scanner({
            videoSettings: {
                video: { 
                    facingMode: { exact: "environment" },
                    advanced: [{
                        width: 1100,
                    }]
                }
            },
            onNewCodeRead: function (txt, result) { 
                self.processResultCallback(txt);
            }
        });
    }

    detached() {
        if (this.isRunning) {
            this.stopScan();
        }
    }

    async initializeScanner() {
        this.scanner.open();
    }

    handleError(error) {
        logger.error(error);
    }
    
    stopScan() {
       
        this.scanner.close();
        this.isRunning = false;
        jQuery("#barcode-scanner").empty();
    }

    async startScan() {
        if (this.isRunning) {
            this.stopScan();
        }

        if (!await this.checkCameraLiveStreamAccessibility()) {
            return;
        }

        this.results.length = 0;
        this.initializeScanner();
    }

    triggerOnScanEvent(code) {
        const newScanEvent = DOM.createCustomEvent("newscan", { detail: { value: code, scanner: this }, bubbles: true });
        this.element.dispatchEvent(newScanEvent);
    }

    async checkCameraLiveStreamAccessibility() {
        if (browserHelper.isChromeOnIOS()) {
            const warningMessage = this.i18n.tr("CameraChromeIOS");
            notificationHelper.showWarning(warningMessage, null, { timeOut: 0 });

            return false;
        }

        if (!browserHelper.isGetUserMediaSupported()) {
            if (browserHelper.isIOS11WebApp()) {
                notificationHelper.showWarning(this.i18n.tr("WarningIOS11WebAppNoCameraStream"));
            } else {
                notificationHelper.showWarning(this.i18n.tr("WarningNoCameraStream"));
            }

            return false;
        }
        if (!await browserHelper.isCameraDeviceFound()) {
            notificationHelper.showWarning(this.i18n.tr("CameraNotFound"));
            return false;
        }
        if (!browserHelper.isProtocolSecure()) {
            notificationHelper.showWarning(this.i18n.tr("CameraAccessRequireSSL"));
            return false;
        }

        const result = await browserHelper.checkCameraAccess();

        if (result !== browserHelper.checkCameraAccessResult.Ok) {
            if (result === browserHelper.checkCameraAccessResult.NotAllowed) {
                notificationHelper.showWarning(this.i18n.tr("CameraAccessDenied"));
                return false;
            } else if (result === browserHelper.checkCameraAccessResult.NotReadable) {
                notificationHelper.showWarning(this.i18n.tr("CameraAccessError"));
                return false;
            } else {
                notificationHelper.showWarning(this.i18n.tr("CameraAccessError"));
                return false;
            }
        }

        return true;
    }
}