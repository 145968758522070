import { DataModificationLogFileModel } from "api/models/company/data-modification-log-file-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallQuotationDataModificationsProxy } from "api/proxies/service-call-quotation-data-modifications-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class ServiceCallQuotationDataModificationsService {
    constructor(private readonly serviceCallQuotationDataModificationsProxy: ServiceCallQuotationDataModificationsProxy) {
    }

    public async getHeaderDataModificationsLogFile(quotationId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<DataModificationLogFileModel[]> {
        return (await this.serviceCallQuotationDataModificationsProxy.GetHeaderDataModificationsLogFile(quotationId, filter, pagingInfo, requestConfig)) || [];
    }

    public async getDetailsDataModificationsLogFile(quotationId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<DataModificationLogFileModel[]> {
        return (await this.serviceCallQuotationDataModificationsProxy.GetDetailsDataModificationsLogFile(quotationId, filter, pagingInfo, requestConfig)) || [];
    }
}
