import urlHelper from "helpers/urlHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { I18N } from "aurelia-i18n";
import { observable } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { SettingRepository } from "repositories/setting-repository";
import { NotificationHelper } from "helpers/notification-helper";

import { InspectionService } from "services/inspection-service";
import { ProjectInspectionService } from "services/project-inspection-service";
import { EquipmentInspectionService } from "services/equipment-inspection-service";

import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InspectionTypeLookupModel } from "api/models/company/inspection/inspection-type-lookup-model";

import { InspectionContext } from "api/enums/inspection-context";
import { Redirect, Router } from "aurelia-router";
import { CloneHelper } from "helpers/cloneHelper";

@autoinject
export class InspectionAdd {

    public contexts: any[] = [];

    @observable public inspectionContext: any = null;

    public inspection!: InspectionModel;

    public inspectionEnum: any = InspectionContext;
    public isContextSelected: boolean = false;
    public isInspectionTypeSelectable: boolean = false;
    public selectedInspectionTypeId: any;
    public okToSave: boolean = false;
    public isDirty: boolean = false;

    constructor(private readonly i18n: I18N,
                private readonly inspectionService: InspectionService,
                private readonly projectInspectionService: ProjectInspectionService,
                private readonly equipmentInspectionService: EquipmentInspectionService,
                private readonly routeRepository: RouteRepository,
                private readonly settingRepository: SettingRepository,
                private readonly notificationHelper: NotificationHelper,
                private readonly router: Router) {
        this.contexts = this.buildContextsList();
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public buildContextsList(): any[] {
        return [
            { id: this.inspectionEnum.Equipment, display: this.i18n.tr("Equipment")},
            { id: this.inspectionEnum.Project, display: this.i18n.tr("Project")}
        ];
    }

    public get GetEquipments(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.equipmentInspectionService.GetEquipmentsForInspections(params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: EquipmentModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: EquipmentModel): any => {
                return { id: item.Code, text: `${item.Code} - ${item.Description}`, data: item };
            }
        };
    }

    public get GetProjects(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                const prefix = this.settingRepository.getPrefix();
                this.projectInspectionService.GetProjectsForInspections(prefix ? prefix : null, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: ProjectBaseModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ProjectBaseModel): any => {
                return { id: item.Id, text: `${item.Id} - ${item.Description}`, data: item };
            }
        };
    }

    public get GetProjectInspectionTypes(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.projectInspectionService.GetInspectionTypesLookup(this.inspection.ProjectNo, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: InspectionTypeLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: InspectionTypeLookupModel): any => {
                return { id: item.Id, text: `${item.Id} - ${item.Description}`, data: item };
            }
        };
    }

    public get GetEquipmentInspectionTypes(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.equipmentInspectionService.GetInspectionTypesLookup(this.inspection.EquipmentId, params.data.filter, { page: params.data.page, pageSize: 20}).then(
                    (result: InspectionTypeLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: InspectionTypeLookupModel): any => {
                return { id: item.Id, text: `${item.Id} - ${item.Description}`, data: item };
            }
        };
    }

    public async inspectionContextChanged(newValue: any): Promise<void> {
        if (!newValue) {
            return;
        }

        if (this.isContextSelected) {
            this.notificationHelper.showDialogYesNo(this.i18n.tr("ms_ChangeContextConfirmation")).then(async (value: boolean) => {
                if (value) {
                    this.selectedInspectionTypeId = null;
                    this.inspection = (await this.inspectionService.GetNewInspection(newValue, null))!;
                    this.isOkToSave();
                }
            });
        } else {
            this.inspection = (await this.inspectionService.GetNewInspection(newValue, null))!;
        }

        this.isDirty = true;
        this.isContextSelected = true;

        this.isOkToSave();
    }

    public onInspectionTypeSelected(inspectionType: InspectionTypeLookupModel): void {
        this.inspection.InspectionTypeId = inspectionType.Id;
        this.isOkToSave();
    }

    public async onInspectionEquipmentSelected(equipment: EquipmentModel): Promise<void> {
        this.inspection.EquipmentId = equipment.Code;
        await this.selectSingleInspectionType();
        this.isOkToSave();
    }

    public async onInspectionProjectSelected(project: ProjectBaseModel): Promise<void> {
        this.inspection.ProjectNo = project.Id;
        await this.selectSingleInspectionType();
        this.isOkToSave();
    }

    public async save(): Promise<void> {

        const result: InspectionModel | null = await this.inspectionService.SaveInspection(this.inspection);

        this.isDirty = false;

        if (result) {
            this.replaceBrowsingHistory();
            routerHelper.navigateToRoute(this.routeRepository.routes.Inspection_Info.name, { inspectionId: result.Id});
        }

    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        const route =  this.routeRepository.routes.Inspection.route.toString();
        window.history.replaceState("", "", baseUrl + route);
    }

    private async selectSingleInspectionType(): Promise<void> {
        let result: InspectionTypeLookupModel[];
        if (this.inspection.InspectionContext === this.inspectionEnum.Project) {
            result = (await this.projectInspectionService.GetInspectionTypesLookup(this.inspection.ProjectNo))!;
        } else {
            result = (await this.equipmentInspectionService.GetInspectionTypesLookup(this.inspection.EquipmentId))!;
        }

        this.isInspectionTypeSelectable = result.length > 1;

        if (!this.isInspectionTypeSelectable) {
            const selectableType = result.length > 0 ? result[0] : null;
            if (selectableType) {
                this.inspection.InspectionTypeId = selectableType.Id;
                this.selectedInspectionTypeId = { id: selectableType.Id, text: `${selectableType.Id} - ${selectableType.Description}`, data: selectableType };
            }
        } else {
            this.inspection.InspectionTypeId = null;
        }
    }
    private isOkToSave(): void {
        this.okToSave = this.isContextSelected && this.inspection !== null && (this.inspection.EquipmentId !== null || this.inspection.ProjectNo !== null) && this.inspection.InspectionTypeId !== null;
    }
}
