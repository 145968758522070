import { autoinject, transient } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { GridBase } from "components/ag-grid/grids/grid-base";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { CustomEventHelper } from "helpers/custom-event-helper";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { UserGridStateService } from "services/user-grid-state-service";
import { ProcoreBudgetType } from "api/enums/procore-budget-type";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
export class SynchronizationGrid extends GridBase {
    public errorIconTooltipMessage: string = this.i18n.tr("Procore.Sync_Error_Tooltip");
    
    protected readonly i18n: I18N;

    constructor(element: Element, i18n: I18N, userGridStateService: UserGridStateService, eventAggregator: EventAggregator) {
        super(element, i18n, userGridStateService, eventAggregator);

        this.i18n = i18n;
        this.minHeight = window.innerHeight - 225;
        this.showRightPanel = false;
        this.showFullSearchBox = false;
        this.autosizeColumnsOnWindowResize = true;

        window.addEventListener("resize", () => {
            this.setupMinHeight();
        });
    }

    public synchronizationTypeFormatter: (params: any) => any = (params: any): any => {
        return this.i18n.tr("Procore.Procore_Entity_" + ProcoreEntityToSynchronize[params.value]);
    }

    public synchronizationGroupingFormatter: (params: any) => any = (params: any): any => {
        return this.i18n.tr("Procore.Sync_Grid_Grouping_" + ProcoreBudgetType[params.value]);
    }

    public attached(): void {
        this.createSyncGridOptions();
    }

    public onGridReady(grid: any): void {
        CustomEventHelper.dispatchEvent(this.element, "grid-ready", { grid: grid }, true, false);
    }

    public selectAllEnabledEntities(): void {
        this.gridOptions.api.forEachNode((node: any): void => {
            if (node.data && node.data.ToSynchronize) {
                node.setSelected(node.data.ToSynchronize);
            }
        });
    }

    public doesNothaveErrors(params: any): boolean {
        return !this.hasErrors(params);
    }

    public hasErrors(params: any): boolean {
        const errors = this.getRowErrors(params);

        return errors && errors.length > 0;
    }

    public doesNothaveWarnings(params: any): boolean {
        return !this.hasWarnings(params);
    }

    public hasWarnings(params: any): boolean {
        const warnings = this.getRowWarnings(params);

        return warnings && warnings.length > 0;
    }

    public async openErrorGrid(params: any): Promise<void> {

        const fakeLink = document.querySelector(".fake-link") as HTMLElement;
        if (!fakeLink) { return; }

        fakeLink.click();

        const clickedKey = this.getClickedKey(params);

        const procoreErrorGridOptions = this.getProcoreErrorGridOptions(params);

        this.setProcoreErrorGridClickedErrorBold(procoreErrorGridOptions, clickedKey);
        this.setProcoreErrorGridClickedErrorExpanded(procoreErrorGridOptions, clickedKey);

        procoreErrorGridOptions.getRowStyle = undefined;
    }

    protected getProcoreErrorGridOptions(params: any): any {
        return params.context.viewModel.procoreErrorGridOptions;
    }

    private setProcoreErrorGridClickedErrorBold(procoreErrorGridOptions: any, clickedKey: string): void {
        procoreErrorGridOptions.getRowStyle = (params: any): any => {
            if (params.data && params.data.Group === clickedKey) {
                return { fontWeight: "bold" };
            }
        };
    }

    private setProcoreErrorGridClickedErrorExpanded(procoreErrorGridOptions: any, clickedKey: string): void {
        procoreErrorGridOptions.api.forEachNode((node: any): void => {
            if (node.key === clickedKey) {
                node.setExpanded(true);
            }
        });
    }

    private getClickedKey(params: any): string {
        const data = this.getRowData(this, params);

        return data.MaestroIdentifier
            ? `${data.MaestroIdentifier} - ${data.MaestroEntityName}`
            : `${data.ProcoreIdentifier} - ${data.ProcoreEntityName}`;
    }

    private getRowErrors(params: any): ProcoreError[] {
        const data = this.getRowData(this, params);

        return data.Errors;
    }

    private getRowWarnings(params: any): ProcoreError[] {
        const data = this.getRowData(this, params);

        return data.Warnings;
    }

    private createSyncGridOptions(): void {
        this.gridOptions = {
            onModelUpdated: (param: any): void => {
                this.selectAllEnabledEntities();
                param.api.sizeColumnsToFit();
            },
            onGridReady: (params: any): void => {
                params.api.hidePopupMenu();
                params.api.sizeColumnsToFit();
                this.selectAllEnabledEntities();
            }
        };
    }

    private setupMinHeight(): void {
        const grids = document.querySelectorAll(".synchronize-grid") as any;

        grids.forEach((e: any) => {
            if (e.classList.contains("closed")) {
                e.style.minHeight = "0";
            } else {
                e.style.minHeight = window.innerHeight - 225 + "px";
            }
        });
    }
}
