import { MaCardOptionsModel, MaCardLineModel } from "components/layout/ma-card/ma-card-options.model";
import { TimeKeepingStatus } from "api/enums/time-keeping-status";
import dateHelper from "helpers/dateHelper";
import { TimeKeepingModel } from "api/models/company/time-keeping-model";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject
export class TimeKeepingCardGenerator {
  private timeKeepingCardClass: string = "time-keeping-card";
  private timeKeepingCardDidNotWorkClass: string = "time-keeping-card-did-not-work";
  private timeKeepingCardLeft: string = "time-keeping-card-left";
  private timeKeepingCardDateClass: string = "time-keeping-card-date";
  private notWorkingClass: string = "mt-4";

  constructor(private readonly i18n: I18N) { }

  public generateNotWorkingCard(): MaCardOptionsModel {
    return new MaCardOptionsModel({
      class: this.notWorkingClass,
      displayLines: [new MaCardLineModel({ display: this.i18n.tr("PunchInOut_DidNotWork"), class: this.timeKeepingCardDidNotWorkClass })]
    });
  }

  public generateEmptyCard(punchStatus: string, clickAction: any): MaCardOptionsModel {
    const isPunchIn = punchStatus === TimeKeepingStatus.In;
    
    return new MaCardOptionsModel({
      action: clickAction,
      leftSectionClass: this.timeKeepingCardLeft,
      leftSectionText: [this.getCardLeftText(isPunchIn)],
      displayLines: [
        new MaCardLineModel({ display: "", class: this.timeKeepingCardDateClass }),
        new MaCardLineModel({ display: dateHelper.getTimeFromDate(undefined), class: this.timeKeepingCardClass })
       ]
    });
  }

  public generateCard(punch: TimeKeepingModel, clickAction: any): MaCardOptionsModel {
    const formattedDate = !!punch.PunchDate
      ? dateHelper.formatDateLongFormat(new Date(punch.PunchDate))
      : "";
    
    const isPunchIn = punch.PunchStatus === TimeKeepingStatus.In;

    return new MaCardOptionsModel({
      action: clickAction,
      leftSectionClass: this.timeKeepingCardLeft,
      leftSectionText: [this.getCardLeftText(isPunchIn)],
      displayLines: [
        new MaCardLineModel({ display: formattedDate, class: this.timeKeepingCardDateClass }),
        new MaCardLineModel({ display: dateHelper.getTimeFromDate(punch.PunchDate), class: this.timeKeepingCardClass })
       ]
    });
  }

  private getCardLeftText(isPunchIn: boolean): string {
    return isPunchIn
      ? this.i18n.tr("In")
      : this.i18n.tr("Out");
  }
}
