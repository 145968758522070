import { singleton, inject } from "aurelia-framework";
import { default as equipmentService } from "services/equipmentService";
import { default as recommendationService } from "services/recommendationService";
import { default as routerHelper } from "helpers/routerHelper";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@singleton()
@inject(I18N, Router, NotificationHelper)
export class RecommendationEdit {
    fetchClients;
    fetchEquipments;
    saveRecommendation;
    updatePageSubtitle;

    subTitle = "";
    recommendationId = "";
    isDirty = false;
    parentModel;

    constructor(i18n, router, notificationHelper) {
        this.i18n = i18n;
        this.router = router;
        this.notificationHelper = notificationHelper;
    }

    activate(params) {
        this.recommendationId = params.recommendationId;
     
        this.fetchEquipments = equipmentService.get;
        this.saveRecommendation = recommendationService.addRecommendation;
        this.updatePageSubtitle = title => { this.subTitle = title; };
        this.parentModel = this;
    }

    canDeactivate = async function () {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    };
}
