import { autoinject, transient } from "aurelia-framework";
import { TimeListHelper } from "helpers/time-list-helper";

@transient()
@autoinject
export class StartEndTimeTemplate {

    public readonly: boolean = false;
    public templateContext: any;
    public timeOptions: any;

    public async activate(settings: any): Promise<any> {
        this.templateContext = settings;
        this.timeOptions = await TimeListHelper.loadTimeList(15);
        this.readonly = settings.readonly || false;
    }
}
