import { ServiceCallActivityModel } from "api/models/company/service/service-call-activity-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallActivityProxy } from "api/proxies/service-call-activity-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class ServiceCallActivityService {
    constructor(private readonly serviceCallActivityProxy: ServiceCallActivityProxy) {
    }

    public async getActivities(onlyForMobile: boolean | undefined, quotationId: number | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<ServiceCallActivityModel[]> {
        return await this.serviceCallActivityProxy.GetActivities(onlyForMobile, quotationId, filter, pagingInfo, requestConfig) || [];
    }
}
