define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getAllForProject: function(dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/additionalfields");
        },

        setAllForProject: function(dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/additionalfields", data);
        },

        getAllForDispatch: function (serviceType, dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + '/' + (serviceType === 'W' ? 'workorder' : 'callreturn') + "/additionalfields");
        },

        getAllForServiceCall: function (dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/service-call/additionalfields");
        },

        setAllForServiceCall: function (dispatchId, data) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + '/service-call/additionalfields', data);
        },

        getAllForServiceCallContract: function (dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/service-call/contract/additionalfields");
        },

        setAllForDispatch: function (serviceType, dispatchId, data) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + '/' + (serviceType === 'W' ? 'workorder' : 'callreturn') + "/additionalfields", data);
        },

        getAllForFieldService: function (workOrderId) {
            return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/additionalfields");
        },

        setAllForFieldService: function (workOrderId, data) {
            return requests.post(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/additionalfields", data);
        },

        getAllForPurchaseOrder: function (orderId, extension) {
            var url = apiHelper.getPurchaseOrderBaseUrl(orderId) + "/additionalfields";
            if (extension !== "") {
                url += "?extension=" + extension;
            }
            return requests.getJson(url);
        },

        setAllForPurchaseOrder: function (orderId, extension, data) {
            var url = apiHelper.getPurchaseOrderBaseUrl(orderId) + "/additionalfields"
            if (extension !== "") {
                url += "?extension=" + extension;
            }
            return requests.post(url, data);
        },

        getAdditionalFieldsFromServiceContract: function (quotationId, contractNumber) {
            var url = apiHelper.getBaseUrl() + `/service-call-quotations/${quotationId}/from-contract-additionalfields?contractNumber=` + contractNumber;
            return requests.getJson(url);
        },

        setAllForRequisition: function (requisitionId, data) {
            var url = apiHelper.getBaseUrl() + `/material-requisitions/${requisitionId}/additionalfields`
            
            return requests.post(url, data);
        },

        getAllForRequisition: function (requisitionId) {
            var url = apiHelper.getBaseUrl() + `/material-requisitions/${requisitionId}/additionalfields`;
            return requests.getJson(url);
        },

        getAllForQuotation: function (quotationId) {
            var url = apiHelper.getBaseUrl() + `/service-call-quotations/${quotationId}/additionalfields`;
            return requests.getJson(url);
        },

        setAllForQuotation: function (quotationId, data) {
            var url = apiHelper.getBaseUrl() + `/service-call-quotations/${quotationId}/additionalfields`
            return requests.post(url, data);
        },

        setAllForRequisitionEquipment: function (requisitionId, data) {
            var url = apiHelper.getBaseUrl() + `/requisition-equipment/${requisitionId}/additionalfields`

            return requests.post(url, data);
        },

        getAllForRequisitionEquipment: function (requisitionId) {
            var url = apiHelper.getBaseUrl() + `/requisition-equipment/${requisitionId}/additionalfields`;
            return requests.getJson(url);
        },

        setAllForReceiptOfGoods: function (receiptId, data) {
            var url = apiHelper.getBaseUrl() + `/receipts-of-goods/${receiptId}/additionalfields`

            return requests.post(url, data);
        },

        getAllForReceiptOfGoods: function (receiptId) {
            var url = apiHelper.getBaseUrl() + `/receipts-of-goods/${receiptId}/additionalfields`;
            return requests.getJson(url);
        },

    };

    return exports;
});
