import { ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { ServiceQuotationSecurityProxy } from "api/proxies/service-quotation-security-proxy";
import { autoinject } from "aurelia-framework";

@autoinject()
export class ServiceCallQuotationSecurityService {
    constructor(private readonly serviceQuotationSecurityProxy: ServiceQuotationSecurityProxy) {
    }

    public async getSecuritySettings(): Promise<ServiceQuotationSecuritySettingsModel> {
        const securitySettings = await this.serviceQuotationSecurityProxy.GetServiceQuotationSecuritySettingsForCurrentUser() as ServiceQuotationSecuritySettingsModel;

        const allStatuses = Object.values(ServiceCallQuotationStatus);
        if (securitySettings.IsAdministrator || securitySettings.HasAccessToAllStatuses) {
            securitySettings.Statuses = allStatuses;
        } else {
            securitySettings.Statuses = allStatuses.filter((status: ServiceCallQuotationStatus) => (securitySettings.Statuses as string[]).includes(status));
        }

        return securitySettings;
    }

    public isQuotationReadOnly(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !quotation || quotation.IsServiceContractNotFound ||
            !securitySettings ||
            !(securitySettings.Statuses as Array<string | null>).includes(quotation.Status) ||
            [ServiceCallQuotationStatus.CallGenerated, ServiceCallQuotationStatus.ManuallyClosed].includes(quotation!.Status as ServiceCallQuotationStatus);
    }

    public isQuotationReadOnlyInMobile(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return this.isQuotationReadOnly(quotation, securitySettings) ||
            ![ServiceCallQuotationStatus.InProgressByTheTechnician, ServiceCallQuotationStatus.OnHold].includes(quotation!.Status as ServiceCallQuotationStatus);
    }

    public isQuotationReadOnlyInDesktop(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return this.isQuotationReadOnly(quotation, securitySettings) ||
            [ServiceCallQuotationStatus.InProgressByTheTechnician].includes(quotation!.Status as ServiceCallQuotationStatus);
    }

    public isQuotationPricingReadOnlyInDesktop(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return this.isQuotationReadOnly(quotation, securitySettings) ||
            [ServiceCallQuotationStatus.InProgressByTheTechnician, ServiceCallQuotationStatus.SentToCustomer, ServiceCallQuotationStatus.AcceptedByTheCustomer, ServiceCallQuotationStatus.RefusedByTheCustomer].includes(quotation!.Status as ServiceCallQuotationStatus);
    }

    public isQuotationStatusReadOnlyInDesktop(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !quotation || quotation.IsServiceContractNotFound ||
            !securitySettings ||
            !(securitySettings.Statuses as Array<string | null>).includes(quotation.Status) ||
            [ServiceCallQuotationStatus.InProgressByTheTechnician, ServiceCallQuotationStatus.ManuallyClosed].includes(quotation!.Status as ServiceCallQuotationStatus);
    }

    public canCreateQuotationInMobile(securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!securitySettings &&
            (securitySettings.IsAdministrator || (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.InProgressByTheTechnician));
    }

    public canCreateQuotation(securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!securitySettings &&
            (securitySettings.IsAdministrator || (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.Entered));
    }

    public canDeleteQuotation(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsServiceContractNotFound &&
            quotation.Id > 0 &&
            !!securitySettings &&
            (securitySettings.IsAdministrator || quotation.CreationUserId === securitySettings.UserId) &&
            ![ServiceCallQuotationStatus.SentToCustomer, ServiceCallQuotationStatus.AcceptedByTheCustomer, ServiceCallQuotationStatus.CallGenerated].includes(quotation.Status as ServiceCallQuotationStatus);
    }

    public canPutQuotationOnHold(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsServiceContractNotFound &&
            quotation.Status === ServiceCallQuotationStatus.InProgressByTheTechnician &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.OnHold);
    }

    public canResumeQuotation(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsServiceContractNotFound &&
            quotation.Status === ServiceCallQuotationStatus.OnHold &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.InProgressByTheTechnician);
    }

    public canSendQuotationToOffice(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsServiceContractNotFound &&
            quotation.Status === ServiceCallQuotationStatus.InProgressByTheTechnician &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.CompletedByTheTechnician);
    }

    public canSendQuotationToCustomer(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsServiceContractNotFound &&
            [ServiceCallQuotationStatus.InProgressByTheTechnician].includes(quotation.Status as ServiceCallQuotationStatus) &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.SentToCustomer);
    }

    public canCaptureSignatureForQuotation(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsServiceContractNotFound &&
            [ServiceCallQuotationStatus.InProgressByTheTechnician].includes(quotation.Status as ServiceCallQuotationStatus) &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.AcceptedByTheCustomer);
    }

    public canRefuseQuotation(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsServiceContractNotFound &&
            [ServiceCallQuotationStatus.InProgressByTheTechnician].includes(quotation.Status as ServiceCallQuotationStatus) &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.RefusedByTheCustomer);
    }

    public canCreateServiceCallFromQuotation(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsWorkOrder && !quotation.IsServiceContractNotFound &&
            quotation.Status === ServiceCallQuotationStatus.AcceptedByTheCustomer &&
            !quotation.GeneratedServiceCallId &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.CallGenerated);
    }

    public canEditServiceCallType(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && !quotation.IsWorkOrder && !quotation.IsServiceContractNotFound &&
            !quotation.GeneratedServiceCallId &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.CallGenerated);
    }

    public canCreateWorkOrderFromQuotation(quotation: ServiceCallQuotationDetailsModel | null, securitySettings: ServiceQuotationSecuritySettingsModel | null): boolean {
        return !!quotation && quotation.IsWorkOrder &&
            quotation.Status === ServiceCallQuotationStatus.AcceptedByTheCustomer &&
            !quotation.GeneratedServiceCallId &&
            !!securitySettings &&
            (securitySettings.Statuses as Array<string | null>).includes(ServiceCallQuotationStatus.CallGenerated);
    }

    public containNotInCatalogItem(quotation: ServiceCallQuotationDetailsModel | null): boolean {
        if (!quotation || !quotation.Items) {
            return false;
        }

        return quotation.Items.filter((item: ServiceCallQuotationItemModel) => item.CatalogId !== null && item.CatalogId!.charAt(0) === "!").length > 0;
    }
}
