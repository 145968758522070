import { ServiceWorkOrderProjectProxy } from "api/proxies/service-work-order-project-proxy";

import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";

import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InvoicingProjectModel } from "api/models/company/project/invoicing-project-model";

@autoinject()
export class ServiceWorkOrderProjectService {
    constructor(private readonly serviceWorkOrderProjectProxy: ServiceWorkOrderProjectProxy) {}

    public async GetProjects(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectBaseModel[] | null> {
        return this.serviceWorkOrderProjectProxy.GetProjects(filter, pagingInfo, requestConfig);
    }

    public async GetInvoicingProjects(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InvoicingProjectModel[] | null> {
        return this.serviceWorkOrderProjectProxy.GetInvoicingProjects(filter, pagingInfo, requestConfig);
    }
}
