import { WorkOrderWorkTaskDocumentService } from "services/work-order-work-task-document-service";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class WorkTaskEditDocumentsAdd extends FormBase {

    public actions: any;
    public transactionNumber: number = 0;
    public workOrderId: any;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly workOrderWorkTaskDocumentService: WorkOrderWorkTaskDocumentService
    ) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<void> {
        this.transactionNumber = params.transactionNumber;
        this.workOrderId = decodeURIComponent(params.id);
        this.actions = { save: (models: any): JQueryDeferred<any> => {
            const dfd = jQuery.Deferred();
            this.workOrderWorkTaskDocumentService.AddDocumentForWorkTask(this.transactionNumber, models).then(() => {
                return dfd.resolve();
            });
            return dfd;
         }};
    }
}
