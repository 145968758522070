import { autoinject, bindable, transient } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { GridBase } from "components/ag-grid/grids/grid-base";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
export class QuotationDataModificationGrid extends GridBase {

    @bindable
    public hideBreakDownKey: boolean = true;

    constructor(
        element: Element,
        i18n: I18N,
        userGridStateService: UserGridStateService,
        eventAggregator: EventAggregator
    ) {
        super(element, i18n, userGridStateService, eventAggregator);
    }

    public breakDownGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        return params.data.BreakdownKeyDescription !== "" ? params.data.BreakdownKey + " - " + params.data.BreakdownKeyDescription : params.data.BreakdownKey;
    }
}
