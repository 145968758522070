import { IItemGroupedWithParent } from "../interfaces/IItemGroupedWithParent";
import { ProcoreSynchronizationDirection } from "api/enums/procore-synchronization-direction";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";

export class PropertyGetterGroupedWithParent {
  private emptyValue: string = "---";

  public getDirectionArrowWithParent(params: any): any {
    const value = this.getRowValue(params);

    const direction = Number.parseInt(value, 0) as ProcoreSynchronizationDirection;

    switch (direction) {
      case ProcoreSynchronizationDirection.ToMaestro:
        return "<span class='fas fa-long-arrow-alt-left'></span>";
      case ProcoreSynchronizationDirection.ToProcore:
        return "<span class='fas fa-long-arrow-alt-right'></span>";
      case ProcoreSynchronizationDirection.Both:
        return "<span class='fas fa-arrows-alt-h'></span>";
      case ProcoreSynchronizationDirection.None:
        return "";
    }
  }

  public getDatePropertyWithParent(params: any): any {
    const value = this.getRowValue(params);

    if (value === this.emptyValue) {
      return value;
    }

    return params.formatValue(value);
  }

  public getPropertyWithParentWithIdentation(params: any): string {
    let value = this.getRowValue(params);
    const hierarchyLevel = this.getRowHierarchyLevel(params);

    value = this.setRowIdentation(value, hierarchyLevel);

    return value;
  }

  public getRowValue(params: any): string {
    const field = params.colDef.field;

    const data = this.getRowData(params);
    const value = data[field];

    // Empty Value, we check every conditions, because if it's 0 we want the condition to fail.
    if (value === undefined || value === null || value === "") {
      return this.emptyValue;
    }

    return value;
  }

  private getRowHierarchyLevel(params: any): number {
    const data = this.getRowData(params);

    return data.hierarchyLevel;
  }

  private setRowIdentation(value: string, hierarchyLevel: number): string {
    if (hierarchyLevel > 0) {
      let spacing = "";
      for (let i = 0; i < hierarchyLevel; i++) {
        spacing += "&nbsp;&nbsp;&nbsp;&nbsp;";
      }

      value = spacing + value;
    }

    return value;
  }

  private getViewModel(params: any): IItemGroupedWithParent {
    const viewModel: IItemGroupedWithParent = params.context.viewModel as IItemGroupedWithParent;

    if (!Array.isArray(viewModel.internalItems)) {
      throw Error("Your context must implements internalItems.");
    }

    return viewModel;
  }

  private getRowData(params: any): any {
    // Is Parent Node
    if (!params.data) {
      const viewModel = this.getViewModel(params);
      const element = viewModel.internalItems!.find((item: any): any => item.MaestroIdentifier === params.node.key ||
                                                                        item.ProcoreIdentifier === params.node.key);

      if (!element) {
        return {
          hierarchyLevel: 0,
          Errors: [],
          Warnings: []
        };
      }

      return element;
    }

    return params.data;
  }
}
