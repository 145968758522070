import { autoinject, customElement } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { Router } from "aurelia-router";

@autoinject()
@customElement("category-navigation-item")
export class CategoryNavigationItem {

    @bindable.booleanAttr public disabled: boolean = false;

    @bindable.booleanAttr public displayCount: boolean = true;
    @bindable.booleanAttr public displayChevron: boolean = true;
    @bindable.booleanAttr public displayAddButton: boolean = false;
    
    @bindable.string public itemCaptionKey: string = "";
    @bindable.string public itemCount: string = "";

    @bindable.string public href: string | null = null;
    @bindable.string public routeName: string = "";
    @bindable.none public params: { [key: string]: any } | null = null;

    @bindable.string public addButtonLink: string = "";
    @bindable.string public addButtonRouteName: string = "";
    @bindable.none public addButtonRouteParams: { [key: string]: any } | null = null;
    
    constructor(private readonly element: Element, private readonly router: Router) {}

    public async bind(): Promise<void> {
        await this.initializeLink();
        await this.initializeAddButtonLink();
    }
   
    public async initializeLink(): Promise<void> {
        if (!this.href && !this.routeName) {
            this.href = "#";
            return;
        }

        if (!this.href) {
            await this.router.ensureConfigured();
            this.href = this.router.generate(this.routeName, this.params);
        }
    }

    public async initializeAddButtonLink(): Promise<void> {
        if (!this.addButtonLink && !this.addButtonRouteName) {
            this.addButtonLink = "#";
            return;
        }

        if (!this.addButtonLink) {
            await this.router.ensureConfigured();
            this.addButtonLink = this.router.generate(this.addButtonRouteName, this.addButtonRouteParams);
        }
    }
}
