import ModelDateTransformer from "api/util/model-date-transformer";
import { ApiHelper } from "helpers/api-helper";
import { CloneHelper } from "helpers/cloneHelper";
import { HttpClient } from "http-client/http-client";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import URI from "urijs";
import URITemplate from "urijs/src/URITemplate";

export default abstract class BaseProxy {
    private httpClientInstance: HttpClient | null = null;

    public get httpClient(): HttpClient {
        // Lazy
        return this.httpClientInstance || (this.httpClientInstance = this.httpClientFactory.getMaestroApiHttpClient(this.apiHelper.getBaseApiUrlForCurrentCompany()));
    }

    public set httpClient(value: HttpClient) {
        this.httpClientInstance = value;
    }

    constructor(
        private readonly modelDateTransformer: ModelDateTransformer,
        protected readonly httpClientFactory: HttpClientFactory,
        private readonly apiHelper: ApiHelper
    ) {
    }

    public async get<TR>(uri: string, requestConfig?: HttpClientRequestConfig): Promise<TR> {
        return await this.httpClient.get(uri, requestConfig);
    }

    public async post<TD, TR>(uri: string, data?: TD, requestConfig?: HttpClientRequestConfig, transformData: boolean = true): Promise<TR> {

        let dataToSend;
        if (transformData) {
            dataToSend = this.transformModelsToSend(data);
        } else {
            dataToSend = data;
        }

        return await this.httpClient.post(uri, dataToSend, requestConfig);
    }

    public async delete<TR>(uri: string, requestConfig?: HttpClientRequestConfig): Promise<TR> {
        return await this.httpClient.delete(uri, requestConfig);
    }

    public async patch<TD, TR>(uri: string, data?: TD, requestConfig?: HttpClientRequestConfig): Promise<TR> {
        return await this.httpClient.patch(uri, data, requestConfig);

    }

    public buildUri(baseUri: string, templateData: any | null, queryData: object | null): string {
        let result: any;

        // URL parameters: "a/b/{param1}/c"
        if (templateData) {
            // Les types TS pour URITemplate semblent incorrects, expand retourne une string et non un URI.
            const templateResult: string = new URITemplate(baseUri).expand(templateData, { strict: true }) as any;
            result = new URI(templateResult);
        } else {
            result = new URI(baseUri);
        }

        // Query-string parameters
        if (queryData) {
            result = result.query(queryData);
        }

        return result.valueOf();
    }

    public transformModels(model: any, key: string): void {
        this.modelDateTransformer.convertModelPropertiesToDate(model, key);
    }

    private transformModelsToSend(model: any): any {
        let modelToSend = model;

        if (modelToSend) {
            modelToSend = CloneHelper.deepClone(modelToSend);

            this.modelDateTransformer.convertModelPropertiesToString(modelToSend);
        }

        return modelToSend;
    }
}
