import { default as resx } from "core/resx";

export default class ProjectTimeEntryHelper {
    // Build the warning message to display in the dialog when a user tries to create time entries that overlap existing entries.
    buildConflictsMessage(entries) {
        const entriesWithConflicts = entries.filter(entry => entry.HasConflictsOnCurrentProject && entry.LinkedDispatchId === 0);
        const entriesWithConflictsOnOtherProjects = entries.filter(entry => entry.HasConflictsOnOtherProjects && entry.LinkedDispatchId === 0);

        let result = "";

        if (entriesWithConflicts.length) {
            const conflicts = [];
            for (let i = 0; i < entriesWithConflicts.length; i++) {
                conflicts.push(entriesWithConflicts[i].Description + "<br />");
            }

            result += resx.localize("ResourcesWithConflictsInCurrentProject:") +
                "<br />" +
                conflicts.slice(0, 3).join("") +
                (conflicts.length > 3 ? "..." : "");
        }

        if (entriesWithConflicts.length && entriesWithConflictsOnOtherProjects.length) {
            result += "<br />";
        }

        if (entriesWithConflictsOnOtherProjects.length) {
            const conflicts = [];
            for (let i = 0; i < entriesWithConflictsOnOtherProjects.length; i++) {
                for (let j = 0; j < entriesWithConflictsOnOtherProjects[i].ConflictsOnOtherProjects.length; j++) {
                    conflicts.push(entriesWithConflictsOnOtherProjects[i].Description + " (" + entriesWithConflictsOnOtherProjects[i].ConflictsOnOtherProjects[j].DispatchProjectId + ")<br />");
                }
            }

            result += resx.localize("ResourcesWithConflictsInOtherProjects:") +
                "<br />" +
                conflicts.slice(0, 3).join("") +
                (conflicts.length > 3 ? "..." : "");
        }

        return result;
    }
}