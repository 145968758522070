// GENERATED FILE - DO NOT MODIFY
export enum InspectionStatus {
    Entered = "A",
    Accepted = "V",
    Closed = "Z",
    Active = "0",
    Complete = "1",
}

export let nameof = "InspectionStatus";
