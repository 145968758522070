import storage from 'core/storage';

export class LocalStorageHelper {
    get(key) {
        return storage.get(key);
    }

    set(key, value) {
        storage.set(key, value);
    }

    has(key) {
        return storage.has(key);
    }

    remove(key) {
        storage.remove(key);
    }

    clear() {
        storage.clear();
    }

    checkStorage() {
        return storage.checkStorage();
    }
}