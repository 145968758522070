// GENERATED FILE - DO NOT MODIFY
import { ServiceCallActivityModel } from "api/models/company/service/service-call-activity-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallActivityProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetActivities(onlyForMobile: boolean | undefined, quotationId: number | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallActivityModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/activities",
            null,
            { onlyForMobile: (onlyForMobile === null || onlyForMobile === undefined) ? undefined : onlyForMobile.toString(), quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallActivityModel[] | null = await this.get<ServiceCallActivityModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallActivityModel");
        return data;
    }
}
