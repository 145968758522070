import { inject } from "aurelia-framework";
import { default as Axios, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, CancelTokenSource } from "axios";
import Logger from "core/logger";
import { default as RouterHelper } from "helpers/routerHelper";
import { HttpClientCancellationError } from "http-client/http-client-cancellation-error";
import { HttpClientCancellationToken } from "http-client/http-client-cancellation-token";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { Nonce } from "http-client/nonce";

export abstract class HttpClient {
    public static createCancellationToken(): HttpClientCancellationToken {
        const source: CancelTokenSource = Axios.CancelToken.source();
        return new HttpClientCancellationToken(source.token, source.cancel);
    }

    private readonly routerHelper: any;
    private readonly nonce: Nonce;
    private readonly axiosDefaultRequestConfig: AxiosRequestConfig;
    private readonly axiosInstance: AxiosInstance;

    constructor(nonce: Nonce, defaultRequestConfig: HttpClientRequestConfig) {
        this.routerHelper = RouterHelper;
        this.nonce = nonce;
        this.axiosDefaultRequestConfig = this.createAxiosRequestConfig(defaultRequestConfig);
        this.axiosInstance = this.createAxiosInstance();
    }

    public async get(uri: string, requestConfig?: HttpClientRequestConfig): Promise<any> {
        // The config will be combined with the default config specified when the Axios instance was created.
        const axiosRequestConfig: AxiosRequestConfig = requestConfig ? this.createAxiosRequestConfig(requestConfig) : {};

        axiosRequestConfig.method = "get";
        axiosRequestConfig.url = uri;

        const showLoadingScreen: boolean = !requestConfig || (requestConfig.showLoadingScreen === true);
        const response: AxiosResponse<any> = await this.sendRequest(axiosRequestConfig, showLoadingScreen);
        return response.data;
    }

    public async post(uri: string, data: any, requestConfig?: HttpClientRequestConfig): Promise<any> {
        // The config will be combined with the default config specified when the Axios instance was created.
        const axiosRequestConfig: AxiosRequestConfig = requestConfig ? this.createAxiosRequestConfig(requestConfig) : {};

        axiosRequestConfig.method = "post";
        axiosRequestConfig.url = uri;
        axiosRequestConfig.data = data;
        if (typeof data === "string" && (!axiosRequestConfig.headers || !axiosRequestConfig.headers["Content-Type"] || axiosRequestConfig.headers["Content-Type"] === "application/json")) {
            axiosRequestConfig.data = JSON.stringify(data);
        }

        const showLoadingScreen: boolean = !requestConfig || (requestConfig.showLoadingScreen === true);
        const response: AxiosResponse<any> = await this.sendRequest(axiosRequestConfig, showLoadingScreen);
        return response.data;
    }

    public async delete(uri: string, requestConfig?: HttpClientRequestConfig): Promise<any> {
        // The config will be combined with the default config specified when the Axios instance was created.
        const axiosRequestConfig: AxiosRequestConfig = requestConfig ? this.createAxiosRequestConfig(requestConfig) : {};

        axiosRequestConfig.method = "delete";
        axiosRequestConfig.url = uri;

        const showLoadingScreen: boolean = !requestConfig || (requestConfig.showLoadingScreen === true);
        const response: AxiosResponse<any> = await this.sendRequest(axiosRequestConfig, showLoadingScreen);
        return response.data;
    }

    public async patch(uri: string, data: any, requestConfig?: HttpClientRequestConfig): Promise<any> {
        const axiosRequestConfig: AxiosRequestConfig = requestConfig ? this.createAxiosRequestConfig(requestConfig) : {};

        axiosRequestConfig.method = "patch";
        axiosRequestConfig.url = uri;
        axiosRequestConfig.data = data;
        if (typeof data !== "string" && (!axiosRequestConfig.headers || !axiosRequestConfig.headers["Content-Type"] || axiosRequestConfig.headers["Content-Type"] === "application/json")) {
            axiosRequestConfig.data = JSON.stringify(data);
        }

        const showLoadingScreen: boolean = !requestConfig || (requestConfig.showLoadingScreen === true);
        const response: AxiosResponse<any> = await this.sendRequest(axiosRequestConfig, showLoadingScreen);
        return response.data;
    }

    protected createAxiosRequestConfig(source: HttpClientRequestConfig): AxiosRequestConfig {
        const result: AxiosRequestConfig = {
            params: {}
        };

        if (source.baseUrl) {
            result.baseURL = source.baseUrl;
        }

        if (source.timeout) {
            result.timeout = source.timeout;
        }

        if (source.responseType) {
            result.responseType = source.responseType;
        }

        if (!result.headers) {
            result.headers = {};
        }

        if (result.headers["Content-Type"] === null || result.headers["Content-Type"] === undefined) {
            result.headers["Content-Type"] = "application/json";
        }

        if (source.authorizationToken) {
            result.headers.Authorization = `Bearer ${source.authorizationToken}`;
        }

        // Setup the axios cancellation token from the specified token for this request. The requester can then call the cancel() function on his token.
        if (source.cancellationToken) {
            result.cancelToken = source.cancellationToken.token;
        }

        // Disable request caching.
        if (source.cache === false) {
            result.params._ = this.nonce.getNext();
        }

        return result;
    }

    protected createAxiosInstance(): AxiosInstance {
        return Axios.create(this.axiosDefaultRequestConfig);
    }

    private async sendRequest(axiosRequestConfig: AxiosRequestConfig, showLoadingScreen: boolean): Promise<AxiosResponse<any>> {
        if (showLoadingScreen) {
            this.routerHelper.showLoading();
        }

        try {
            return await this.axiosInstance.request(axiosRequestConfig);
        } catch (error) {
            return this.handleRequestError(error as AxiosError);
        } finally {
            if (showLoadingScreen) {
                this.routerHelper.hideLoading();
            }
        }
    }

    private handleRequestError(error: AxiosError): Promise<never> {
        if (Axios.isCancel(error)) {
            const message = "HTTP request canceled." + (error.message ? (` Message: ${error.message}`) : "");
            Logger.debug(message);
            throw new HttpClientCancellationError(message);
        }

        (error as any).isHttpRequestError = true;

        if (error.code === "ECONNABORTED" && (error.message.indexOf("timeout") > -1 || error.message.indexOf("Timeout") > -1)) {
            (error as any).isHttpRequestTimeout = true;
        }

        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response) {
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            throw error;
        }

        // The request was made but no response was received
        if (error.request) {
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
            //console.log(error.request);
            throw error;
        }

        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);

        throw error;
    }
}
