import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";
import documentService from "services/documentService";
import { I18N } from "aurelia-i18n";

import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class ServiceRecommendationEditDocumentAdd {

    public dispatchId: number = 0;
    public editId: number = 0;
    public readonly: boolean = false;
    public serviceType: string = "";
    public actions: any;
    public isDirty: boolean = false;

    constructor(
        private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router
    ) { }

    public activate(params: any): any {
        const paramsQs = routerHelper.getQuerystring(params.q);
        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.editId = params.recommendationId;
        this.dispatchId = params.dispatchId;
        this.serviceType = params.serviceType;

        this.actions = {
            save: documentService.saveRecommendationPictureForDispatch.bind(this, this.dispatchId, params.recommendationId, (params.serviceType === "W" ? "workorder" : "serviceCall"), paramsQs.entityId)
        };
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }
}
