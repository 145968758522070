define([
    "repositories/settingRepository"
], function (settings) {
    "use strict";

    var exports = {
        celsiusToFahrenheit: function (value) {
            return Math.round(((value * 9 / 5) + 32) * 100) / 100;
        },
        fahrenheitsToCelsius: function (value) {
            return (value - 32) * 5 / 9;
        },

        toUserUnitFromCelsius: function (value) {
            if (settings.getUnitSystem() === "imperial") {
                return this.celsiusToFahrenheit(value);
            }
            return value;
        },
        fromUserUnitFromCelsius: function (value) {
            if (settings.getUnitSystem() === "imperial") {
                return this.fahrenheitsToCelsius(value);
            }
            return value;
        },

        getUserTemperatureUnit: function() {
            return settings.getUnitSystem() === "imperial" ? "°F" : "°C";
        }
    };

    return exports;
});
