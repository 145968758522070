import { autoinject } from "aurelia-dependency-injection";
import { Logger } from "aurelia-logging";
import { ConsoleAppender } from "aurelia-logging-console";
import { ErrorManager } from "error-management/error-manager";
import { default as stringify } from "json-stringify-safe";

@autoinject()
export class CustomLogAppender extends ConsoleAppender {
    private readonly errorManager: ErrorManager;

    constructor(errorManager: ErrorManager) {
        super();

        this.errorManager = errorManager;
    }

    public debug(logger: Logger, message: any, ...rest: any[]): void {
        super.debug(logger, message, ...rest);
    }

    public info(logger: Logger, message: any, ...rest: any[]): void {
        super.info(logger, message, ...rest);
    }

    public warn(logger: Logger, message: any, ...rest: any[]): void {
        super.warn(logger, message, ...rest);
    }

    public error(logger: Logger, message: any, aiInfo: any, ...rest: any[]): void {
        // All errors catched or generated by Aurelia will go through here (eg: navigation errors, errors thrown in Aurelia lifecycle handlers, etc.)
        // All errors logged by us using an Aurelia logger will also go through here.
        // Let the default logger write to the console, then send the error to the Error Manager for any additional processing (AppInsights, notify the user, etc.)
        // All other errors (windowerror, unhandledrejection) will go to the ErrorManager without passing here first.
        super.error(logger, message, aiInfo, ...rest);
        this.errorManager.handleError(message, aiInfo, stringify(rest));
    }
}
