import { ProjectConfigModel } from "api/models/company/project/project-config-model";
import { ProjectConfigProxy } from "api/proxies/project-config-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class ProjectConfigService {
    constructor(private readonly projectConfigProxy: ProjectConfigProxy) { }

    public async GetConfig(requestConfig?: IRequestConfig): Promise<ProjectConfigModel| null> {
        return await this.projectConfigProxy.GetConfig(requestConfig) || null;
    }
}
