import { BambooSyncRunReasonItem } from "pages/bamboo-hr/models/bamboo-sync-run-reason-item";

export class BambooSyncRunDashboard  {

    public correlationId: string = "";
    public startDate: string = "";
    public isRunning: boolean = false;
    public isInError: boolean = false;
    public endDate: string = "";
    public fetchBambooDataHasStarted: boolean = false;
    public nbrEmployeeFromBambooIgnored: number = 0;
    public ignoredBambooEmployeeReasons: BambooSyncRunReasonItem[] = [];
    public nbrEmployeeFromBambooAvailable: number = 0;
    public fetchBambooDataHasEnded: boolean = false;
    public fetchMaestroDataHasStarted: boolean = false;
    public nbrEmployeeFromMaestroAvailable: number = 0;
    public fetchMaestroDataHasEnded: boolean = false;
    public comparisonDataHasStarted: boolean = false;
    public nbrEmployeeToBeUpdatedFromBambooToMaestro: number = 0;
    public employeesNeedToBeUpdatedReasons: BambooSyncRunReasonItem[] = [];
    public comparisonDataHasEnded: boolean = false;
    public updateMaestroDataHasStarted: boolean = false;
    public nbrEmployeeUpdatedSuccessfully: number = 0;
    public nbrEmployeeUpdateFailed: number = 0;
    public updateMaestroDataHasEnded: boolean = false;
    public isSyncRunFinished: boolean = false;
    public errors: BambooSyncRunReasonItem[] = [];
    public noComparisonNeededReason: BambooSyncRunReasonItem | null = null;
}
