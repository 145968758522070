import { I18N } from "aurelia-i18n";
import { default as enumHelper } from "helpers/enumHelper";
import { computedFrom, autoinject, bindable, observable } from "aurelia-framework";
import RouteRepository from "repositories/routeRepository";

import Parse from "helpers/parse";
import { default as dateHelper } from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import supplierService from "services/supplierService";
import UserSettingService from "services/userSettingsService";
import { ServiceCallService } from "services/service-call-service";
import { PurchaseOrderService } from "services/purchase-order-service";
import { DispatchProjectService } from "services/dispatch-project-service";

import { ServiceDispatchType } from "enums/service-dispatch-type";
import { StringHelper } from "helpers/string-helper";
import { LocationModel } from "api/models/company/location-model";
import { LocationType } from "api/enums/location-type";
import { CloneHelper } from "helpers/cloneHelper";

@autoinject
export class MaterialOrderEdit {

    @bindable public readonly: boolean = true;
    @bindable public orderId: string = "-1";
    @bindable public extension: any = "";

    @bindable public dispatchProjectCode: string = "";
    @bindable public dispatchDate: string = "";
    @bindable public projectCode: string = "";
    @bindable public serviceType: string = "";

    @bindable public isProject: boolean = false;

    @bindable public actions: any;
    @bindable public dispatchId: any;
    @bindable public workOrderId: any;
    @bindable public documentUrl: string = "";
    @bindable public isDirty: boolean = false;

    public purchaseOrder: any;
    public status: any;
    @observable public orderDate: Date = new Date();
    public selectedSupplier: any;

    public isNew: boolean = true;
    public isReceptionImplicit: boolean = true;
    public allowedSendTransfer: boolean = false;
    public isProjectResponsible: boolean = false;
    public canTransfer: boolean = false;
    public requireSave: boolean = false;

    public memoUrl: string = "";
    public acceptedTypes: any[] = [];

    public additionalFieldsUrl: string = "";
    public unModifiedPurchaseOrder: any = null;

    @computedFrom("orderDate")
    public get addUrl(): string {
        const date = dateHelper.dateToNavigationParameter(this.orderDate);
        let temp = routerHelper.getRelativeUrl("");

        if (StringHelper.endsWith(temp, "edit")) {
            temp += "/";
            temp += this.orderId;
            temp += "/item/";
            temp += date;

            return temp;
        }
        if (this.projectCode) {
            return routerHelper.getRelativeUrl("item", date) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, extension: this.extension});
        }

        return routerHelper.getRelativeUrl("item", date) + routerHelper.addQuerystring({ readonly: this.readonly, extension: this.extension });
    }

    @computedFrom("isNew")
    get btnText(): string {
        return this.isNew ? this.i18n.tr("Add") : this.i18n.tr("Save");
    }

    @computedFrom("isNew")
    get purchaseOrderIdDisplay(): string {
        return this.isNew ? this.i18n.tr("New_F") : this.orderId;
    }

    @computedFrom("purchaseOrder.Items")
    get ordItemsVisible(): boolean {
        return !this.isNew && this.purchaseOrder.Items && this.purchaseOrder.Items.length > 0;
    }

    @computedFrom("isNew")
    get txtNoOrderItem(): string {
        return this.isNew ? this.i18n.tr("lstNoData_PurchaseOrderItemUnsaved") : this.i18n.tr("lstNoData_RequisitionItem");
    }

    @computedFrom("purchaseOrder", "orderDate", "purchaseOrder.AutomaticReceipt", "selectedSupplier", "purchaseOrder.DeliveryAddress", "unModifiedPurchaseOrder")
    get isDirtyCheck(): boolean {
        if (this.readonly) {
            this.isDirty = false;
            return this.isDirty;
        }

        if (!this.unModifiedPurchaseOrder) {
            this.isDirty = false;
            return this.isDirty;
        }

        this.unModifiedPurchaseOrder.OrderDate = this.unModifiedPurchaseOrder.OrderDate ? new Date(new Date(this.unModifiedPurchaseOrder.OrderDate!.toString()).getFullYear(), new Date(new Date(this.unModifiedPurchaseOrder.OrderDate!.toString())).getMonth(), new Date(new Date(this.unModifiedPurchaseOrder.OrderDate!.toString())).getDate(), 0, 0, 0, 0) : this.unModifiedPurchaseOrder.OrderDate;
        const stringifyUnmodifiedMaterial = JSON.stringify(this.unModifiedPurchaseOrder).replace(/[^0-9A-Z]+/gi, "");

        this.purchaseOrder.OrderDate = this.purchaseOrder.OrderDate ? new Date(new Date(this.purchaseOrder.OrderDate!.toString()).getFullYear(), new Date(new Date(this.purchaseOrder.OrderDate!.toString())).getMonth(), new Date(new Date(this.purchaseOrder.OrderDate!.toString())).getDate(), 0, 0, 0, 0) : this.purchaseOrder.OrderDate;
        const stringifyCurrentMaterial = JSON.stringify(this.purchaseOrder).replace(/[^0-9A-Z]+/gi, "");

        this.isDirty = stringifyUnmodifiedMaterial !== stringifyCurrentMaterial;

        return this.isDirty;
    }

    constructor(private readonly i18n: I18N, private readonly purchaseOrderService: PurchaseOrderService, private readonly dispatchProjectService: DispatchProjectService, private readonly serviceCallService: ServiceCallService, private readonly routeRepository: RouteRepository) {
    }

    public orderDateChanged(newValue: any, oldValue: any): void {
        if (!this.purchaseOrder) {
            return;
        }

        this.purchaseOrder.OrderDate = this.orderDate;
    }

    public get lookupSupplier(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await supplierService.getSupplierForCompany(params.data.filter, params.data.page || 1).then((result: any) => {
                    return success(result);
                });
            },
            mapResults: (item: any): any => {
                return { id: item.Code, text: item.Code + " - " + item.Name, data: item };
            }
        };
    }

    public async loadData(): Promise<any> {
        await this.actions.getPurchaseOrder().then(async (data: any) => {
            this.purchaseOrder = data;

            if (!this.purchaseOrder.CreatedByCurrentUser && this.purchaseOrder.Id !== null) {
                this.readonly = true;
            }

            this.unModifiedPurchaseOrder = CloneHelper.deepClone(this.purchaseOrder);

            //Init values for new order
            if (this.purchaseOrder.Id === null) {
                await this.initValuesForNewRequisition();
            } else {
                this.isNew = false;
                this.isReceptionImplicit = this.purchaseOrder.AutomaticReceipt;
            }

            const allowdsdtrf = await this.purchaseOrderService.AllowedSendTransfer();
            this.allowedSendTransfer = allowdsdtrf;

            if (this.isNew) {
                if (this.isProject) {
                    this.purchaseOrder.DeliveryAddress = (await this.dispatchProjectService.GetProjectDeliveryAddress(this.dispatchProjectCode, null, undefined, undefined, this.dispatchId ? this.dispatchId : null, this.workOrderId ? this.workOrderId : null))![0];
                } else {
                    this.purchaseOrder.DeliveryAddress = (await this.serviceCallService.GetDeliveryAddress(this.dispatchId, null, undefined, undefined))![0];
                }
            }

            this.canTransfer = !this.isNew && this.purchaseOrder.Status === enumHelper.purchaseOrderStatus.RESERVED && this.purchaseOrder.CreatedByCurrentUser;

            this.updateself();

            const queryString = routerHelper.addQuerystring({
                dispatchProjectDate: this.dispatchDate,
                orderId: this.orderId,
                readonly: this.readonly,
                extension: this.extension
            });

            this.additionalFieldsUrl = `${routerHelper.getRelativeUrl("additionalFields")}${queryString}`;

        }, (fail: any) => {
            routerHelper.navigateBack();
        });
    }

    public async initValuesForNewRequisition(): Promise<void> {
        this.isNew = true;
        this.purchaseOrder.Status = enumHelper.purchaseOrderStatus.RESERVED;
        this.purchaseOrder.OrderDate = new Date();
        this.purchaseOrder.UserNo = -1;
        this.purchaseOrder.Items = [];

        const usrSettingReceptionImplicit = await UserSettingService.getReceptionImplicitSetting();
        if (usrSettingReceptionImplicit) {
            this.isReceptionImplicit = Parse.Boolean(usrSettingReceptionImplicit.Value);
        }

        this.purchaseOrder.AutomaticReceipt = this.isReceptionImplicit;
    }

    public updateself(): void {
        this.orderDate = this.purchaseOrder.OrderDate;

        if (this.purchaseOrder.SupplierCode !== null) {
            this.selectedSupplier = { id: this.purchaseOrder.SupplierCode, text: this.purchaseOrder.SupplierCode + " - " + this.purchaseOrder.Supplier };
        }

        this.readonly = this.readonly || this.purchaseOrder.Status !== enumHelper.purchaseOrderStatus.RESERVED;
    }

    public async bind(): Promise<void> {
        if (this.orderId === "-1") {
            this.readonly = false;
        }

        const queryString = routerHelper.addQuerystring({
            dispatchProjectDate: this.dispatchDate,
            orderId: this.orderId,
            readonly: this.readonly,
            extension: this.extension
        });

        this.memoUrl = `${routerHelper.getRelativeUrl("memo")}${queryString}`;

        this.acceptedTypes = [LocationType.Alternative, LocationType.Customer, LocationType.Project, LocationType.Contact, LocationType.Supplier, LocationType.SupplierSite, LocationType.Company];
        this.acceptedTypes.push(...[LocationType.List]);

        await this.loadData();
    }

    public async deleteItem(item: any): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await this.purchaseOrderService.DeletePurchaseOrderLine(this.orderId, item.Line, this.extension);
            this.loadData();

        }
    }

    public async getAddress(addressType: any, filter: any, pagingInfo: any, requestConfig: any): Promise<LocationModel[] | null> {
        const prefix = this.purchaseOrder && this.purchaseOrder.Prefix ? this.purchaseOrder.Prefix : null;
        if (this.isProject) {
            return await this.dispatchProjectService.getProjectDeliveryAddress(this.dispatchProjectCode, addressType, this.selectedSupplier ? this.selectedSupplier.id : null, prefix, this.dispatchId ? this.dispatchId : null, this.workOrderId ? this.workOrderId : null, filter, pagingInfo, requestConfig);
        } else {
            return await this.serviceCallService.GetDeliveryAddress(this.dispatchId, addressType, this.selectedSupplier ? this.selectedSupplier.id : null, prefix, filter, pagingInfo, requestConfig);
        }
    }

    public setReceptionImplicit(): void {
        if (this.readonly) { return; }

        this.isReceptionImplicit = !this.isReceptionImplicit;
        this.purchaseOrder.AutomaticReceipt = this.isReceptionImplicit;
    }

    public async sendToSupplier(): Promise<void> {
        await this.savePurchaseOrder(true);

        const queryString: string = routerHelper.buildQueryString({
            isProjectResponsible: this.isProjectResponsible, readonly: true, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, workOrderId: this.workOrderId
        });

        if (this.isProject && this.serviceType !== ServiceDispatchType.Workorder) {
            routerHelper.navigateToRoute(this.routeRepository.routes.Project_MaterialOrder_summary.name, { dispatchProjectCode: this.dispatchProjectCode, orderId: this.orderId, q: queryString });
        } else {
            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_MaterialOrderSummary.name, { dispatchProjectCode: this.dispatchProjectCode, orderId: this.orderId, serviceType: this.serviceType, dispatchId: this.dispatchId, q: queryString });
        }

    }

    public async closeOrder(): Promise<void> {
        if (await notificationHelper.showConfirmation(this.i18n.tr("msg_CloseOrderConfirmation"))) {
            await this.savePurchaseOrder(true);
            try {
                await this.purchaseOrderService.Transfer(this.purchaseOrder.Id, this.extension);
            } catch (e) {
                if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                    notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
                } else {
                    notificationHelper.showError(e.response.data.ExceptionMessage, undefined, { timeOut: 0, extendedTimeOut: 0 });
                }
                return;
            }
            routerHelper.navigateBack();
        }
    }

    public genUrl(line: any): string {
        return routerHelper.getRelativeUrl("item", dateHelper.dateToNavigationParameter(this.orderDate), line) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, extension: this.extension });
    }

    public getReceiptOfGoods(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.ReceiptOfGoods.name, { orderId: this.orderId, extension: this.extension });
    }

    public async onSupplierSelected(supplier: any): Promise<void> {
        const originalSupplierCode =  this.purchaseOrder.SupplierCode;
        const originalSupplierDesc = this.purchaseOrder.Supplier;
        if (this.purchaseOrder.SupplierCode !== supplier.Code && !!this.purchaseOrder.SupplierCode && this.purchaseOrder.Items.length > 0) {
            if (await notificationHelper.showDialogYesNo(this.i18n.tr("ConfirmUpdatePricesSupplierChanged"))) {
                await this.purchaseOrderService.ChangeSupplier(this.purchaseOrder.Id, supplier.Code, this.extension);
                await this.loadData();
            } else {
                this.purchaseOrder.SupplierCode = originalSupplierCode;
                this.purchaseOrder.Supplier = originalSupplierDesc;

                this.selectedSupplier = { id: this.purchaseOrder.SupplierCode, text: this.purchaseOrder.SupplierCode + " - " + this.purchaseOrder.Supplier };
            }
        }
    }

    public async savePurchaseOrder(preventNavigate: boolean = false): Promise<void> {
        this.purchaseOrder.OrderDate = this.orderDate;

        this.purchaseOrder.Id = this.orderId === "-1" ? null : this.orderId;

        if (!this.isProject) {
            this.purchaseOrder = this.actions.setSpecificFields(this.purchaseOrder);
        }

        if (!this.selectedSupplier) {
            notificationHelper.showValidationError([this.i18n.tr("err_NoSupplierSelected")]);
            return;
        }

        this.purchaseOrder.SupplierCode = this.selectedSupplier.id;

        await UserSettingService.setReceptionImplicitSetting(this.isReceptionImplicit);

        await this.actions.savePurchaseOrderService(this.purchaseOrder).then(async (data: any) => {
            this.unModifiedPurchaseOrder = null;
            this.orderId = data.Id;
        });

        if (this.isNew) {
            this.isNew = false;

            //The navigation doesn't work (doesn't trigger activate or other events), but it at least add the parameter in the URL
            routerHelper.navigate(routerHelper.getRelativeUrl(encodeURIComponent(this.orderId)) + routerHelper.addQuerystring({ readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, dispatchId: this.dispatchId }), { replace: true, trigger: true });
        } else {
            if (preventNavigate !== true) {
                routerHelper.navigateBack();
            }
        }

        routerHelper.hideLoading();
    }
}
