import Config from "config";

define([
    "core/storage"
], function (storage) {
    "use strict";

    var exports = {};

    exports = {
        getPageSize: function () { return Config.defaultRequestPageSize; },
        getMinimumResultsForSearch: function () { return Config.select2MinimumItemsForSearch; },

        getServiceLastShiftUsed: function () {
            return storage.get("SERVICELASTSHIFTUSED");
        },

        setServiceLastShiftUsed: function (value) {
            storage.set("SERVICELASTSHIFTUSED", value);
        },

        clearServiceLastShiftUsed: function () {
            storage.remove("SERVICELASTSHIFTUSED");
        },

        getFieldServiceLastShiftUsed: function () {
            return storage.get("FIELDSERVICELASTSHIFTUSED");
        },

        setFieldServiceLastShiftUsed: function (value) {
            storage.set("FIELDSERVICELASTSHIFTUSED", value);
        },

        clearFieldServiceLastShiftUsed: function () {
            storage.remove("FIELDSERVICELASTSHIFTUSED");
        },

        getInventorySite: function () {
            return storage.get("INVENTORYSITE");
        },

        setInventorySite: function (value) {
            storage.set("INVENTORYSITE", value);
        },

        clearInventorySite: function () {
            storage.remove("INVENTORYSITE");
        },

        getLastProjectGeolocationPost: function () {
            return storage.get("PROJECTLASTGEOPOST");
        },

        setLastProjectGeolocationPost: function (value) {
            storage.set("PROJECTLASTGEOPOST", value);
        },

        getServiceLastSectionShown: function () {
            return storage.get("SERVICELASTSECTIONSHOWN");
        },

        setServiceLastSectionShown: function (value) {
            storage.set("SERVICELASTSECTIONSHOWN", value);
        },

        clearAll: function () {
            exports.clearServiceLastShiftUsed();
            exports.clearCurrentDispatchTemplateCode();
        },

        getLastUrlShown: function () {
            return storage.get("LASTURLSHOWN");
        },

        setLastUrlShown: function (value) {
            storage.set("LASTURLSHOWN", value);
        },

        setCurrentDispatchTemplateCode: function (configs) {
            storage.set("CurrentDispatchTemplateCode", configs);
        },

        getCurrentDispatchTemplateCode: function () {
            return storage.get("CurrentDispatchTemplateCode");
        },

        clearCurrentDispatchTemplateCode: function () {
            storage.remove("CurrentDispatchTemplateCode");
        },

        getReceptionLastSelectedSortOrder: function () {
            return storage.get("RECEPTIONLASTSELECTEDSORTORDER");
        },

        setReceptionLastSelectedSortOrder: function (value) {
            storage.set("RECEPTIONLASTSELECTEDSORTORDER", value);
        },

        getProjectLastSectionShowned: function () {
            return storage.get("PROJECTLASTSECTIONSHOWNED");
        },

        setProjectLastSectionShowned: function (value) {
            storage.set("PROJECTLASTSECTIONSHOWNED", value);
        },
    };

    return exports;
});
