define([
    "jquery",
    "underscore",
    "services/requests",
    "helpers/apiHelper",
    "helpers/dateHelper",
    "repositories/settingRepository",
    "services/defaultService"
], function (jquery, _, requests, apiHelper, dateHelper, settings, defaultService) {
    "use strict";
    var settingsExpire = 3600000;
    function getActivitiesByUrl(url, filter, page, activityType) {
        url = apiHelper.appendFilterPagingParams(url, filter, page);

        if (activityType) {
            if (_.isArray(activityType)) {
                _.each(activityType, function(actType) {
                    url += (url.indexOf("?") === -1 ? "?" : "&") + "activityType=" + actType;
                });
            } else {
                url += (url.indexOf("?") === -1 ? "?" : "&") + "activityType=" + activityType;
            }
        }

        return requests.getJson(url);
    }

    var exports = {
        getProjectList: function (fromDate) {
            return fromDate ? requests.getJson(apiHelper.getDispatchProjectBaseUrl() + "?fromDate=" + fromDate) : requests.getJson(apiHelper.getDispatchProjectBaseUrl());
        },

        getDetail: function (dispatchProjectCode) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode));
        },

        getItemsCount: function (dispatchProjectCode) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/itemscount");
        },

        getActivitiesForDispatchProject: function (dispatchProjectCode, filter, page, activityType) {
            return getActivitiesByUrl(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/activities", filter, page, activityType);
        },

        getActivityForDispatchProject: function (dispatchProjectCode, activityCode) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/activities/" + activityCode);
        },

        getActivitiesForProject: function (projectCode, filter, page, activityType) {
            return getActivitiesByUrl(apiHelper.getProjectBaseUrl(projectCode) + "/activities", filter, page, activityType);
        },

        getHeader: function (dispatchProjectCode) {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/header");
        },

        listLookup: function (templateId, filter, page) {
            var url = apiHelper.getFieldServiceBaseUrl() + "/projects?dispatchtemplatecode=" + templateId;

            if (filter) {
                url += "&filter=" + filter;
            }
            if (page) {
                url += "&page=" + page;
            }

            return requests.getJson(url);
        },

        getProjectWorkSchedule: function (dispatchProjectCode, templateCode) {
            var dfd = new jQuery.Deferred();
            var projectWorkSchedule = JSON.parse(settings.getCurrentProjectWorkSchedule());

            if (!projectWorkSchedule || projectWorkSchedule.templateCode !== templateCode || projectWorkSchedule.dispatchProjectCode !== dispatchProjectCode || dateHelper.calculateTimeSpan(projectWorkSchedule.timestamp) > settingsExpire) {

                if (!templateCode) {
                    templateCode = defaultService.getCurrentDispatchTemplateCode();
                }

                requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/workschedule/" + templateCode).done(function (data) {
                    projectWorkSchedule = {
                        templateCode: templateCode,
                        dispatchProjectCode:dispatchProjectCode,
                        timestamp: dateHelper.getNow(),
                        configs: data
                    };
                    settings.setCurrentProjectWorkSchedule(JSON.stringify(projectWorkSchedule));
                    dfd.resolve(projectWorkSchedule.configs);
                });
            } else {
                dfd.resolve(projectWorkSchedule.configs);
            }

            return dfd;
        },

        getSubProjects: function (dispatchProjectCode, filter, page) {
            let url = apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/subprojects";
            url = apiHelper.appendFilterPagingParams(url, filter, page);
            return requests.getJson(url);
        },

        getLastProjectUsed: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/lastprojectused");
        },

        hasSubProjects: function (dispatchProjectCode) {
            var dfd = new jQuery.Deferred();

            var options = { page: 1, pageSize: 1 };
            requests.getJson(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/subprojects", options)
                .done(function (projects) {
                    dfd.resolve(projects.length > 0);
                })
                .fail(function () {
                    dfd.reject();
                });

            return dfd;
        },

        isCurrentUserProjectResponsible: function () {
            return requests.getJson(apiHelper.getDispatchProjectBaseUrl() + "/current-user-project-responsible");
        },
    };

    return exports;
});
