import { inject, noView, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { LocalStorageHelper } from "helpers/local-storage-helper";
import settingRepository from "repositories/settingRepository";

@inject(LocalStorageHelper, I18N)
export class SettingRepository {

    constructor(localStorageHelper, i18n) {
        this.localStorageHelper = localStorageHelper;
        this.i18n = i18n;
    }

    initialize() {
        settingRepository.initialize();
    }

    getCompany() {
        return settingRepository.getCompany();
    }

    setCompany(company) {
        settingRepository.setCompany(company);
    }

    clearCompany() {
        settingRepository.clearCompany();
    }

    getCompanyName() {
        return settingRepository.getCompanyName();
    }

    setCompanyName(companyName) {
        settingRepository.setCompanyName(companyName);
    }

    setCurrentProjectWorkSchedule(configs) {
        settingRepository.setCurrentProjectWorkSchedule(configs);
    }

    getCurrentProjectWorkSchedule() {
        return settingRepository.getCurrentProjectWorkSchedule();
    }

    clearCurrentProjectWorkSchedule() {
        settingRepository.clearCurrentProjectWorkSchedule();
    }

    getiOSNagger_documentUpload() {
        return settingRepository.getiOSNagger_documentUpload();
    }

    setiOSNagger_documentUpload() {
        settingRepository.setiOSNagger_documentUpload();
    }

    cleariOSNagger_documentUpload() {
        settingRepository.cleariOSNagger_documentUpload();
    }

    getiOSNagger_homeScreen() {
        return settingRepository.getiOSNagger_homeScreen();
    }

    setiOSNagger_homeScreen() {
        settingRepository.setiOSNagger_homeScreen();
    }

    cleariOSNagger_homeScreen() {
        settingRepository.cleariOSNagger_homeScreen();
    }

    // TODO ML: Cette méthode ne devrait pas être ici.
    getLanguages() {
        return [
            { id: 'en', text: 'English' },
            { id: 'fr', text: 'Français' }
        ];
    }

    getLanguage() {
        return settingRepository.getLanguage();
    }

    getFourCharacterCultureCode() {
        return this.getLanguage() + "-ca";
    }

    setLanguage(language) {
        settingRepository.setLanguage(language);
    }

    clearLanguage() {
        settingRepository.clearLanguage();
    }

    parseLanguage(language) {
        return settingRepository.parseLanguage(language);
    }

    // TODO ML: Cette méthode ne devrait pas être ici.
    getUnitSystems() {
        return [
            { id: "metric", text: this.i18n.tr("Metric") },
            { id: "imperial", text: this.i18n.tr("Imperial") }
        ];
    }

    getUnitSystem() {
        return settingRepository.getUnitSystem();
    }

    setUnitSystem(unit) {
        settingRepository.setUnitSystem(unit);
    }

    clearUnitSystem() {
        settingRepository.clearUnitSystem();
    }

    getUserAccessType() {
        return settingRepository.getUserAccessType();
    }

    setUserAccessType(accessType) {
        settingRepository.setUserAccessType(accessType);
    }

    getPrefix() {
        return settingRepository.getPrefix();
    }

    setPrefix(prefix) {
        settingRepository.setPrefix(prefix);
    }

    clearUserAccessType() {
        settingRepository.clearUserAccessType();
    }

    getDispatchTemplate() {
        return settingRepository.getDispatchTemplate();
    }

    setDispatchTemplate(template) {
        settingRepository.setDispatchTemplate(template);
    }

    clearDispatchTemplate() {
        settingRepository.clearDispatchTemplate();
    }

    setCurrentTemplateConfigs(configs) {
        settingRepository.setCurrentTemplateConfigs(configs);
    }

    getCurrentTemplateConfigs() {
        return settingRepository.getCurrentTemplateConfigs();
    }

    clearCurrentTemplateConfigs() {
        settingRepository.clearCurrentTemplateConfigs();
    }

    getUser() {
        return settingRepository.getUser();
    }

    setUser(user) {
        SettingRepository.setUser(user);
    }

    getToken() {
        return settingRepository.getToken();
    }

    setToken(token) {
        settingRepository.setToken(token);
    }

    clearToken() {
        settingRepository.clearToken();
    }

    setTimesheetDefaultValues(values) {
        settingRepository.setTimesheetDefaultValues(values);
    }

    getTimesheetDefaultValues() {
        return settingRepository.getTimesheetDefaultValues();
    }

    getVersion() {
        return settingRepository.getVersion();
    }

    getVersionForDisplay() {
        return settingRepository.getVersionForDisplay();
    }

    clearAll() {
        settingRepository.clearAll();
    }

    getHiddenNavItems() {
        return settingRepository.getHiddenNavItems();
    }

    setHiddenNavItems(values) {
        settingRepository.setHiddenNavItems(values);
    }

    getViewMode() {
        return settingRepository.getViewMode();
    }

    setViewMode(value) {
        settingRepository.setViewMode(value);
    }
}