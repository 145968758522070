
import { CurrencyModel } from "api/models/company/currency-model";
import { PagingInfo } from "api/paging-info";
import { CurrencyProxy } from "api/proxies/currency-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class CurrencyService {

    constructor(private readonly currencyProxy: CurrencyProxy) {
    }

    public async GetQuotationCurrencies(quotationId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CurrencyModel[] | null> {
        return this.currencyProxy.GetQuotationCurrencies(quotationId, undefined, undefined, requestConfig);
    }

}
