// GENERATED FILE - DO NOT MODIFY
export enum WorkflowTransactionType {
    CatalogOrder = 1,
    InventoryProjectTransfer = 4,
    InventorySiteTransfer = 5,
    Sale = 6,
    ProjectPurchase = 11,
    ReceiptOfGoods = 12,
    SubContractorContract = 13,
    InvoiceSupplierOrder = 14,
    Disbursement = 15,
    IndividualDisbursement = 16,
    Requisition = 18,
    PaymentAuthorization = 20,
    FlatRateInvoice = 21,
}

export let nameof = "WorkflowTransactionType";
