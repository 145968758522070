import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { KeyValuePair } from "interfaces/key-value-pair";
import { ProcoreGridColumnEnum } from "../procore-grid-column-enum";
import { ProcoreGridConfigurations } from "../procore-grid-configurations";
import { BaseColumnsConfigurator } from "./base-columns-configurator";

@autoinject
export class BudgetColumnsConfigurator extends BaseColumnsConfigurator {
    public headerChanges: Array<KeyValuePair<ProcoreGridColumnEnum, string>> = [{  key: ProcoreGridColumnEnum.Code,  value: this.i18n.tr("Procore.Sync_Grid_Project_ProjectCode") }];

    public enabledColumns: ProcoreGridColumnEnum[] = [ProcoreGridColumnEnum.Code,
                                                      ProcoreGridColumnEnum.Description,
                                                      ProcoreGridColumnEnum.Message];

    public disabledColumns: ProcoreGridColumnEnum[] = [ProcoreGridColumnEnum.Target,
                                                       ProcoreGridColumnEnum.AdditionalField];

    constructor(private readonly i18n: I18N,
                protected readonly gridConfiguration: ProcoreGridConfigurations) {
        super(gridConfiguration);
        this.ShowCheckboxOnSuccess = true;
    }
}
