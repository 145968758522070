import { transient } from "aurelia-framework";

@transient()
export class InvalidateDateTemplate {

    public templateContext!: any;

    public activate(templateContext: any): void {
        this.templateContext = templateContext;
    }
}
