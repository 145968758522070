import { customElement, autoinject, transient } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { GridBase } from "components/ag-grid/grids/grid-base";
import { FileSizeFormatValueConverter } from "converters/format/file-size-format";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
@customElement("documents-grid")
export class DocumentsGrid extends GridBase {
    constructor(
        element: Element,
        i18n: I18N,
        userGridStateService: UserGridStateService,
        eventAggregator: EventAggregator,
        private readonly fileSizeFormatValueConverter: FileSizeFormatValueConverter
    ) {
        super(element, i18n, userGridStateService, eventAggregator);

        this.showRightPanel = true;
    }

    public sizeFormatter = (params: any): any => {
        return this.fileSizeFormatValueConverter.toView(params.data.Size);
    }
}
