// GENERATED FILE - DO NOT MODIFY
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { EmployeeLoaderEntity } from "api/models/procore/loaders/employee-loader-entity";
import { PccoClientLoaderEntity } from "api/models/procore/loaders/pcco-client-loader-entity";
import { PrimeContractLoaderEntity } from "api/models/procore/loaders/prime-contract-loader-entity";
import { ProcoreBudgetsForAProject } from "api/models/procore/loaders/procore-budgets-for-a-project";
import { ProjectLoaderEntity } from "api/models/procore/loaders/project-loader-entity";
import { SupplierLoaderEntity } from "api/models/procore/loaders/supplier-loader-entity";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProcoreLoadingProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetProjects(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectLoaderEntity[] | null> {
        const uri = this.buildUri(
            "/procore/projects",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectLoaderEntity[] | null = await this.get<ProjectLoaderEntity[] | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.ProjectLoaderEntity");
        return data;
    }

    public async GetSynchronizedProjects(entityToSynchronize: ProcoreEntityToSynchronize, filter: string | null | undefined, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectLoaderEntity[] | null> {
        const uri = this.buildUri(
            "/procore/synchronized_projects",
            null,
            { entityToSynchronize: (entityToSynchronize === null || entityToSynchronize === undefined) ? undefined : entityToSynchronize, filter: (filter === null || filter === undefined) ? undefined : filter, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectLoaderEntity[] | null = await this.get<ProjectLoaderEntity[] | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.ProjectLoaderEntity");
        return data;
    }

    public async GetSuppliers(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<SupplierLoaderEntity[] | null> {
        const uri = this.buildUri(
            "/procore/suppliers",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: SupplierLoaderEntity[] | null = await this.get<SupplierLoaderEntity[] | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.SupplierLoaderEntity");
        return data;
    }

    public async GetPccoClients(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PccoClientLoaderEntity[] | null> {
        const uri = this.buildUri(
            "/procore/pccoClient",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PccoClientLoaderEntity[] | null = await this.get<PccoClientLoaderEntity[] | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.PccoClientLoaderEntity");
        return data;
    }

    public async GetEmployees(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EmployeeLoaderEntity[] | null> {
        const uri = this.buildUri(
            "/procore/employees",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EmployeeLoaderEntity[] | null = await this.get<EmployeeLoaderEntity[] | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.EmployeeLoaderEntity");
        return data;
    }

    public async GetTransactions(projectCode: string | null, ignoreDate: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/procore/projects/{project_id}/get-transactions",
            null,
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode, ignoreDate: (ignoreDate === null || ignoreDate === undefined) ? undefined : ignoreDate.toString() }
        );

        const data: number = await this.get<number>(uri, requestConfig);
        return data;
    }

    public async GetBudget(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ProcoreBudgetsForAProject | null> {
        const uri = this.buildUri(
            "/procore/projects/{project_id}/get-budget",
            null,
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode }
        );

        const data: ProcoreBudgetsForAProject | null = await this.get<ProcoreBudgetsForAProject | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.ProcoreBudgetsForAProject");
        return data;
    }

    public async GetBudgetModification(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ProcoreBudgetsForAProject | null> {
        const uri = this.buildUri(
            "/procore/projects/{project_id}/get-budget-modification",
            null,
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode }
        );

        const data: ProcoreBudgetsForAProject | null = await this.get<ProcoreBudgetsForAProject | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.ProcoreBudgetsForAProject");
        return data;
    }

    public async GetPrimeContract(projectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<PrimeContractLoaderEntity | null> {
        const uri = this.buildUri(
            "/procore/projects/{project_id}/prime-contract",
            null,
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode }
        );

        const data: PrimeContractLoaderEntity | null = await this.get<PrimeContractLoaderEntity | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Loaders.PrimeContractLoaderEntity");
        return data;
    }
}
