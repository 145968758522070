import { bindable, autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import services from "services/serviceService";
import dateHelper from "helpers/dateHelper";

@autoinject
export class EquipmentLine {
  @bindable public equipment: any;
  @bindable public entityId: string = "";
  @bindable public presence: string = "";
  @bindable public readonly: boolean = false;
  @bindable public dispatchId: number = 0;
  @bindable public serviceType: string = "";
  @bindable public allowedServiceCallContractEquipmentManagement: boolean = false;
  @bindable public showStatusButtons: boolean = true;

  public dateHelper: any = dateHelper;

  private i18n: I18N;

  constructor(i18n: I18N) {
    this.i18n = i18n;
    }

   private changeEquipmentUsed(): void {
        if (this.equipment.IsReadOnly || this.readonly) { return; }

        this.equipment.IsUsed = !this.equipment.IsUsed;

        services.changeEquipmentUsed(this.serviceType, this.dispatchId, this.equipment);
   }

  private showDetails(id: string): void {
    routerHelper.navigate(
        routerHelper.navigateTo("Service_Detail_Equipment_Details", this.serviceType, this.dispatchId, id)
        + routerHelper.addQuerystring({ equipmentId: id, presence: this.presence, readonly: this.readonly, entityId: this.entityId, dispatchId: this.dispatchId, allowedServiceCallContractEquipmentManagement: this.allowedServiceCallContractEquipmentManagement })
        );
  }
}
