define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getAllForFieldService: function (workOrderId) {
            return requests.getJson(apiHelper.getWorkOrderTypeBaseUrl(workOrderId) + "/additionalfields");
        },

        setAllForFieldService: function (workOrderId, data) {
            return requests.post(apiHelper.getWorkOrderTypeBaseUrl(workOrderId) + "/additionalfields", data);
        }
    };

    return exports;
});
