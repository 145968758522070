import { AdditionalFieldDefinitionHelper } from "./interfaces/additional-field-definition-helper";
import { AdditionalFieldDefinitionSaveHelper } from "./interfaces/additional-field-definition-save-helper";

export class AdditionalFieldHourHelper implements AdditionalFieldDefinitionHelper, AdditionalFieldDefinitionSaveHelper {
  public getValue(item: any): any {
    return item.Value === ""
      ? ""
      : `${item.Value.substr(0, 2)}:${item.Value.substr(2, 2)}`;
  }

  public getSavedValue(item: any): any {
    return item.Value.replace(":", "");
  }
}
