import { inject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import routerHelper from "helpers/routerHelper";
import dailyEntryService from "services/dailyEntryService";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { CloneHelper } from "helpers/cloneHelper";

@inject(I18N, NotificationHelper, Router)
export class DailyEntryWorkEdit extends FormBase{
    entityId;
    dispatchProjectCode;
    dispatchDate;
    readonly = false;
    isProjectResponsible;
    unModified = null;

    descriptions;

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
        this.entityId = null;
        this.dispatchProjectCode = "";
        this.dispatchDate = "";
        this.readonly = false;
        this.isProjectResponsible = false;

        this.descriptions = [];
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        if (this.unModified === null) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "").toUpperCase();

        const stringifyCurrent = JSON.stringify(document.querySelector(`textarea`).value).replace(/[^0-9A-Z]+/gi, "").toUpperCase();

        return stringifyUnmodified !== stringifyCurrent;
    }

    mapDataToPost() {
        return { WorkDescription: document.querySelector(`textarea`).value };
    }

    activate(params) {
        var paramsQueryString = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.entityId = params.dispatchProjectCode + "-" + params.dailyEntryDate;

        return this.loadData();
    }

    loadData() {
        dailyEntryService.getWorkDescription(this.dispatchProjectCode, this.dispatchDate)
            .done((data) => {
                if (data) {
                    this.unModified = data.WorkDescription;
                    this.descriptions.push({ value: data.WorkDescription, identifier: 'richText' });
                } else {
                    this.unModified = "";
                }
            });
    }

    saveDescription() {
        if(this.readonly) {
            return;
        }
        routerHelper.showLoading();
        dailyEntryService.setWorkDescription(this.dispatchProjectCode, this.dispatchDate, this.mapDataToPost())
            .done((data) => {
                if (data) {
                    this.unModified = null;
                    routerHelper.navigateBack();
                    routerHelper.hideLoading();
                }
            })
            .always(routerHelper.hideLoading);
    }
}
