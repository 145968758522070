import { InspectionService } from "services/inspection-service";
import { InspectionContext } from "api/enums/inspection-context";
import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { bindable, autoinject} from "aurelia-framework";
import dateHelper from "helpers/dateHelper";

@autoinject()
export class ImageEditorHeader {

}
