import { AdditionalFieldDefinitionHelper } from "./interfaces/additional-field-definition-helper";
import { AdditionalFieldDefinitionSaveHelper } from "./interfaces/additional-field-definition-save-helper";
import dateHelper from "helpers/dateHelper";

export class AdditionalFieldDateHelper implements AdditionalFieldDefinitionHelper {
  public getValue(item: any): any {
    return item.Value === "" || item.Value === null
      ? null
        : dateHelper.getVeryShortDateLocal(item.Value);
  }
}
