import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, computedFrom } from "aurelia-framework";
import { RouterHelper } from "helpers/router-helper";
import { NavigationNew } from "repositories/routeRepository";
import { ServiceCallQuotationEquipmentService, ServiceCallQuotationRelatedEquipmentModel } from "services/service-call-quotation-equipment-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";

export interface QuotationEquipmentsParameters {
    quotationId: number;
}

@autoinject()
export class QuotationEquipments {

    public context: any = this;
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public quotationEquipments: ServiceCallQuotationRelatedEquipmentModel[] = [];
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;

    public readonly NavigationNew: string = NavigationNew;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings);
    }

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallQuotationEquipmentService: ServiceCallQuotationEquipmentService,
        private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        private readonly routerHelper: RouterHelper
    ) {}

    public async activate(params: QuotationEquipmentsParameters): Promise<void> {
        params.quotationId = Number(params.quotationId);

        await Promise.all([this.initQuotation(params.quotationId), this.initSecuritySettings()]);
        await this.initQuotationEquipments(this.quotation as ServiceCallQuotationDetailsModel);
    }

    public async searchEquipment(filter: string): Promise<void> {
        if (!this.quotation) { return; }

        this.quotationEquipments = await this.serviceCallQuotationEquipmentService.getRelatedEquipments(this.quotation.Id, this.quotation.ContractId as string, this.quotation.RequiredDate, filter);
    }

    public async equipmentSelectedChanged(): Promise<void> {
        if (!this.quotation) {
            return;
        }

        const onlySelectedRelatedEquipments = this.quotationEquipments.filter((equipment: ServiceCallQuotationRelatedEquipmentModel) => equipment.Selected);
        await this.serviceCallQuotationEquipmentService.saveQuotationEquipments(this.quotation.Id, onlySelectedRelatedEquipments);

        this.routerHelper.navigateSelf();
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, false, true, false, false, false);
    }

    private async initQuotationEquipments(quotation: ServiceCallQuotationDetailsModel): Promise<void> {
        this.quotationEquipments = await this.serviceCallQuotationEquipmentService.getRelatedEquipments(quotation.Id, quotation.ContractId as string, quotation.RequiredDate);
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }
}
