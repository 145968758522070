import { CatalogInventoryLocationProxy } from "api/proxies/catalog-inventory-location-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { PagingInfo } from "api/paging-info";
import { CatalogInventoryLocationModel } from "api/models/company/catalog/catalog-inventory-location-model";

@autoinject()
export class CatalogInventoryLocationService {
    constructor(private readonly catalogItemTypeProxy: CatalogInventoryLocationProxy) {
    }

    public async GetLocations(prefix: string | null | undefined, siteId: string | null | undefined, typeItem: string | null | undefined, itemCategory: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogInventoryLocationModel[] | null> {
        const types = await this.catalogItemTypeProxy.GetLocations(prefix ? prefix : undefined, siteId ? siteId : undefined, typeItem ? typeItem : undefined, itemCategory ? itemCategory : undefined, filter, pagingInfo, requestConfig);

        if (!types) {
            return [];
        }

        return types.map((item: any): any => {
            return {
                id: item.Id,
                text: item.Description,
                data: item
            };
        });
    }
}
