import { inject } from "aurelia-framework";
import { Router } from 'aurelia-router';

import { default as enumHelper } from "helpers/enumHelper";
import UserAccessService from "services/user-access-service";

@inject(Router, UserAccessService)
export default class NavigationItemBuilderService {
    constructor(router, userAccessService) {
        this.router = router;
        this.userAccessService = userAccessService;
    }

    async buildNavigationItemsForMenu () {
        return await this.getAllNavigationItem();
    }

    async buildNavigationItemsForHomePage() {
        const allNavItems = await this.getAllNavigationItem();
        return allNavItems.filter(entry => entry.showOnHomePage);
    }

    async getAllNavigationItem() {
        const array = [];
        const routes = this.router.navigation
            .map(route => route.config)
            .filter(route => route.nav >= 1);

        for (const route of routes) {
            const routeName = route.name.toUpperCase();
            const option = enumHelper.userOptions[routeName];

            if (!option || await this.userAccessService.isOptionEnabled(option)) {
                array.push(route)
            }
        }

        return array;
    }
}
