export class PollingSystem {
    public timer: number = 5000; // In miliseconds
    
    constructor() {
        document.addEventListener("aurelia-composed", () => {
            setInterval(this.action, this.timer);
        });
    }
    
    public action: any = (): void => { /* Action */ };
}
