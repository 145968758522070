// GENERATED FILE - DO NOT MODIFY
import { DefaultBonusParametersModel } from "api/models/company/bonus/default-bonus-parameters-model";
import { DispatchProjectBonusModel } from "api/models/company/project/dispatch/dispatch-project-bonus-model";
import { DispatchProjectDefaultBonusHeaderModel } from "api/models/company/project/dispatch/dispatch-project-default-bonus-header-model";
import { DispatchProjectResourceModel } from "api/models/company/project/dispatch/dispatch-project-resource-model";
import { BonusesHeaderModel } from "api/models/company/timesheet/bonuses-header-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DispatchProjectBonusProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetBonus(bonusId: number, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectBonusModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/bonuses/{bonusId}",
            { bonusId: (bonusId === null || bonusId === undefined) ? undefined : bonusId.toString() },
            null
        );

        const data: DispatchProjectBonusModel | null = await this.get<DispatchProjectBonusModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectBonusModel");
        return data;
    }

    public async GetBonusesForTimeEntry(timeEntryId: number, requestConfig?: HttpClientRequestConfig): Promise<BonusesHeaderModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/bonuses/timeEntry/{timeEntryId}",
            { timeEntryId: (timeEntryId === null || timeEntryId === undefined) ? undefined : timeEntryId.toString() },
            null
        );

        const data: BonusesHeaderModel | null = await this.get<BonusesHeaderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Timesheet.BonusesHeaderModel");
        return data;
    }

    public async CreateOrUpdateBonuses(bonuses: Array<DispatchProjectBonusModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatch/projects/bonuses/post",
            null,
            null
        );

        await this.post<Array<DispatchProjectBonusModel | null> | null, void>(uri, bonuses, requestConfig);
    }

    public async GetDefaultBonuses(model: DefaultBonusParametersModel | null, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectDefaultBonusHeaderModel | null> {
        const uri = this.buildUri(
            "/bonus/defaultBonus",
            null,
            null
        );

        const data: DispatchProjectDefaultBonusHeaderModel | null = await this.post<DefaultBonusParametersModel | null, DispatchProjectDefaultBonusHeaderModel | null>(uri, model, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectDefaultBonusHeaderModel");
        return data;
    }

    public async GetInterfaceBonusesForDispatch(dispatchId: number, model: DefaultBonusParametersModel | null, requestConfig?: HttpClientRequestConfig): Promise<BonusesHeaderModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/bonuses/{dispatchId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: BonusesHeaderModel | null = await this.post<DefaultBonusParametersModel | null, BonusesHeaderModel | null>(uri, model, requestConfig);
        this.transformModels(data, "Company.Timesheet.BonusesHeaderModel");
        return data;
    }

    public async UpdateBonusesQtiesHoursForTimeEntry(timeEntryId: number, model: DefaultBonusParametersModel | null, requestConfig?: HttpClientRequestConfig): Promise<BonusesHeaderModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/bonuses/updateHours/timeEntry/{timeEntryId}",
            { timeEntryId: (timeEntryId === null || timeEntryId === undefined) ? undefined : timeEntryId.toString() },
            null
        );

        const data: BonusesHeaderModel | null = await this.post<DefaultBonusParametersModel | null, BonusesHeaderModel | null>(uri, model, requestConfig);
        this.transformModels(data, "Company.Timesheet.BonusesHeaderModel");
        return data;
    }

    public async DeleteBonus(bonusId: number, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectResourceModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/bonuses/{bonusId}",
            { bonusId: (bonusId === null || bonusId === undefined) ? undefined : bonusId.toString() },
            null
        );

        const data: DispatchProjectResourceModel | null = await this.delete<DispatchProjectResourceModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectResourceModel");
        return data;
    }
}
