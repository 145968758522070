import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import dispatchService from "services/dispatchService";
import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class ServiceComment {

    public btnText: string = "";
    public comment: string = "";
    public unModifiedcomment: string | null = "";
    public dispatchId: number = 0;
    public id: string = "";
    public serviceType: string = "";
    public readonly: boolean = false;

    constructor(private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router) {
    }

    public async activate(params: any): Promise<void> {
        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
        await this.loadData();
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public bindViewModel(serviceType: string, dispatchId: number, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
    }

    public async setComment(): Promise<void> {
        await dispatchService.setComment(this.dispatchId, this.comment)
            .then(() => {
                this.unModifiedcomment = this.comment;
                routerHelper.navigateBack();
            });
    }

    public async loadData(): Promise<void> {
        this.comment = await dispatchService.getComment(this.dispatchId);
        this.unModifiedcomment = this.comment;
        this.btnText = this.i18n.tr("Save");
    }

    public isDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.unModifiedcomment !== this.comment;
    }
}
