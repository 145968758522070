import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";

import HostContext from "core/host-context";
import logger from "core/logger";
import { default as routerHelper } from "helpers/routerHelper";

// Checks that we are not trying to navigate to a page that should not be accessed in hosted mode.
@inject(HostContext)
export class RouteAccessibleInHostedModeVerificationAuthorizeStep {
    hostContext = null;
    routesNotAccessibleInHostedMode = [
        "",
        "Login",
        "Settings",
        "Home"
    ];

    constructor(hostContext) {
        this.hostContext = hostContext;
    }

    run(instruction, next) {
        logger.debug("AUTHORIZE STEPS: RouteAccessibleInHostedModeVerification");

        if (this.hostContext.isHosted() && this.routesNotAccessibleInHostedMode.includes(instruction.config.name)) {
            logger.warn(`Trying to access a route that should not be accessed while in a hosted environment (route: ${instruction.config.name}). Redirecting to the global error page.`);
            return next.cancel(new Redirect("Error")); // Redirect to the error route
        }

        return next();
    }
}