import { inject } from "aurelia-framework";

import * as resx from "core/resx";
import * as enumHelper from "helpers/enumHelper";
import * as notificationHelper from "helpers/notificationHelper";
import * as workflowService from "services/workflowService";
import * as maestroServerConnectService from "services/maestroServerConnectService";

export default class NotificationService {
    constructor() {
    }

    updateApprovalNotification() {
        workflowService.hasapprobations().done(function(gotApproval) {
            if (gotApproval) {
                notificationHelper.notificationCenterMessageSet(enumHelper.notificationCenterKey.WORKFLOWAPPROVALSERVICE, resx.localize('PendingApprovals'), enumHelper.notificationCenterMessageType.ALERT, null, { deleteButton: false });
            } else {
                notificationHelper.notificationCenterMessageRemove(enumHelper.notificationCenterKey.WORKFLOWAPPROVALSERVICE);
            }
        }).fail(function(xhr) {
            xhr.handled = true;
            notificationHelper.notificationCenterMessageSet(enumHelper.notificationCenterKey.WORKFLOWAPPROVALSERVICE, resx.localize('err_GetPendingApprovalError'), enumHelper.notificationCenterMessageType.ERROR, null, { deleteButton: false });
        });
    }

    updateMaestroServerConnectNotification() {
        maestroServerConnectService.canConnect().done(function (canConnect) {
            if (canConnect) {
                notificationHelper.notificationCenterMessageRemoveMaestroServerConnect(enumHelper.notificationCenterKey.MAESTROSERVERCONNECTSERVICE);
            }
        }).fail(function (xhr) {
            xhr.handled = true;
            notificationHelper.notificationCenterMessageSet(enumHelper.notificationCenterKey.MAESTROSERVERCONNECTSERVICE, resx.localize('ApiError.800'), enumHelper.notificationCenterMessageType.ERROR, null, { deleteButton: false });
        });
    }
}