export class FullCalendarLazyLoader {
    public async loadModule(): Promise<any> {
        const [fullCalendar, localeFr, localeFrCa, localeEnCa]: any[] = await Promise.all([
            import(/* webpackChunkName: "fullcalendar" */ "fullcalendar"),
            // @ts-ignore
            import(/* webpackChunkName: "fullcalendar" */ "fullcalendar/dist/locale/fr"),
            // @ts-ignore
            import(/* webpackChunkName: "fullcalendar" */ "fullcalendar/dist/locale/fr-ca"),
            // @ts-ignore
            import(/* webpackChunkName: "fullcalendar" */ "fullcalendar/dist/locale/en-ca"),
        ]);

        return fullCalendar;
    }
}
