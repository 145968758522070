// GENERATED FILE - DO NOT MODIFY
import { LocationModel } from "api/models/company/location-model";
import { LocationTypeModel } from "api/models/company/location-type-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class LocationProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetLocations(type: string | null, id: string | null | undefined, id2: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<LocationModel[] | null> {
        const uri = this.buildUri(
            "/locations",
            null,
            { type: (type === null || type === undefined) ? undefined : type, id: (id === null || id === undefined) ? undefined : id, id2: (id2 === null || id2 === undefined) ? undefined : id2, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: LocationModel[] | null = await this.get<LocationModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.LocationModel");
        return data;
    }

    public async GetLocationTypes(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<LocationTypeModel[] | null> {
        const uri = this.buildUri(
            "/delivery-location-types",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: LocationTypeModel[] | null = await this.get<LocationTypeModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.LocationTypeModel");
        return data;
    }

    public async AddAlternativeLocation(location: LocationModel | null, requestConfig?: HttpClientRequestConfig): Promise<LocationModel | null> {
        const uri = this.buildUri(
            "/add-alternative-location",
            null,
            null
        );

        const data: LocationModel | null = await this.post<LocationModel | null, LocationModel | null>(uri, location, requestConfig);
        this.transformModels(data, "Company.LocationModel");
        return data;
    }
}
