import { CatalogInventoryService } from "services/catalog-inventory-service";
import { ExpandableButtonOptions, ExpandableButtonOptionsSubButtonModel } from "components/buttons/expandable-button/expandable-button-options";
import { autoinject, observable, bindable, PLATFORM } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import notificationHelper from "helpers/notificationHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
import { InventoryProjectTransferItemModel } from "api/models/company/inventory-project-transfer-item-model";
import { ProjectGroupType } from "api/enums/project-group-type";
import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { InventoryProjectTransferStatus } from "api/enums/inventory-project-transfer-status";

@autoinject
export class EditDetails {
  public activities: any[] = [];
  public groups: any[] = [];
  public sites: any[] = [];

  @bindable public unitResultTemplate: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/inventoryTransfer_unit_result.html");
  @bindable public selectedReservations: any[] = [];

  @observable({ changeHandler: "selectedValueChanged" })
  public selectedActivity: any;
  @observable({ changeHandler: "selectedValueChanged" })
  public selectedGroup: any;
  @observable({ changeHandler: "selectedSiteChanged" })
  public selectedSite: any;
  public addDisabled: boolean = true;
  @bindable public inventoryProjectTransferItemsCards: MaCardOptionsModel[] = [];
  @bindable public isActive: boolean = false;

  private transferId?: number;
  private model: InventoryProjectTransferDetailsModel | null = null;

  constructor(private readonly i18n: I18N,
              private readonly routeRepository: RouteRepository,
              private readonly service: InventoryProjectTransferService, private readonly catalogInventoryService: CatalogInventoryService) { }

  public async activate(params: any): Promise<void> {
    this.transferId = params.transferId;

    const response = await this.service.GetTransfer(this.transferId!);
    if (!response) { return; }

    this.isActive = response!.Status === InventoryProjectTransferStatus.Active;

    this.inventoryProjectTransferItemsCards = response.Items!.map((item: InventoryProjectTransferItemModel) => this.createCard(item));

    await this.setProperties(response);
  }

  public gotoNewItem(): void {
    const parameters = {
        transferId: this.transferId,
        sourcePrefix: this.model!.SourcePrefix,
        activityId: this.selectedActivity.id,
        groupId: this.selectedGroup.id,
        siteId: this.selectedSite.id,
        isActive: this.isActive
    };

    routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Edit_Details_Item_Add.name, parameters);
  }

  public gotoScanner(): void {
    const parameters = {
      transferId: this.transferId,
      sourcePrefix: this.model!.SourcePrefix,
      activityId: this.selectedActivity ? this.selectedActivity.id : "",
      groupId: this.selectedGroup ? this.selectedGroup.id : "",
      siteId: this.selectedSite.id
    };

    routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Edit_Details_Item_Add_Scanner.name, parameters);
  }

  public gotoEditItem(transactionNumber: number): void {
    const parameters = {
      transferId: this.transferId,
      transactionNumber: transactionNumber,
      sourcePrefix: this.model!.SourcePrefix,
      activityId: this.selectedActivity ? this.selectedActivity.id : "",
      groupId: this.selectedGroup ? this.selectedGroup.id : "",
      siteId: this.selectedSite.id,
      isActive: this.isActive
    };

    routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Edit_Details_Item_Edit.name, parameters);
  }

  public async deleteItemConfirmation(transactionNumber: number): Promise<void> {
    const dialogMessage = this.i18n.tr("InventoryTransfer_Delete_Confirmation");

    notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<void> => {
          if (success) {

            await this.service.DeleteTransferItem(transactionNumber);

            this.model!.Items = this.model!.Items!.filter((item: InventoryProjectTransferItemModel) => item.TransactionNumber !== transactionNumber);
            this.inventoryProjectTransferItemsCards = this.model!.Items!.map((item: InventoryProjectTransferItemModel) => this.createCard(item));
          }
      });
  }

  public getButtonOptions(): ExpandableButtonOptions {
    return  new ExpandableButtonOptions({
        mainButtonIcon: "fa fa-plus",
        subButtons: [ new ExpandableButtonOptionsSubButtonModel({
            icon:  "fa fa-plus fa-fw",
            text: this.i18n.tr("Add"),
            action: this.gotoNewItem.bind(this)
        }), new ExpandableButtonOptionsSubButtonModel({
            icon: "far fa-tag fa-fw",
            isCustomTemplate: true
        }), new ExpandableButtonOptionsSubButtonModel({
            icon: "fa fa-barcode fa-fw",
            text: this.i18n.tr("AddByScanner"),
            action: this.gotoScanner.bind(this)
        })]
    });
  }

  public get GetReservations(): any {
    return {
            transport: (params: any, success: any, failure: any): any => {
                this.catalogInventoryService.GetCatalogInventoryReservationItems(this.model!.SiteId!, this.model!.SourcePrefix!, params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: CatalogInventoryModel): any => {
                const display = `${item.ReservationNo} - ${item.ProductCode} - ${item.Description1}`;
                return { id: item.ReservationNo, text: display, data: item, selected: false };
            },
        };
    }

    public async onReservationChanged(detail: any[]): Promise<void> {
        if (detail) {
            const reservations = detail.map((x: any): CatalogInventoryModel => x.data.data);
            await this.service.AddReservations(this.transferId!, this.model!.ActivityId!, this.model!.GroupId!, reservations);
            this.selectedReservations = [];
            routerHelper.navigateSelf();
        }
    }

  public createCard(item: InventoryProjectTransferItemModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.gotoEditItem.bind(this, item.TransactionNumber),
            displayLines: [
                new MaCardLineModel({ isCustomTemplate: true})
            ],
            actionItems: [
                new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.gotoEditItem.bind(this, item.TransactionNumber) })
            ],
        });
        if (this.isActive) {
                card.actionItems!.push( new MaCardActionModel({ icon: "fa fa-trash text-danger pt-3", action: this.deleteItemConfirmation.bind(this, item.TransactionNumber) }));
        }

        return card;
    }

    public selectedSiteChanged(newValue: any, oldValue: any): void {
        if (!newValue && !oldValue) {
            return;
        }

        if (newValue && !oldValue) {
            this.model!.SiteId = newValue.id;

            this.service.UpdateTransferItems(this.model);

            // On affiche le message d'erreur uniquement s'il y avait une valeur avant.
        } else if (!!oldValue && !!this.model!.Items && this.model!.Items!.length > 0 && this.model!.SiteId !== newValue.id) {
            const dialogMessage = this.i18n.tr("InventoryTransfer_Project_Changed_Confirmation");

            notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<void> => {
                if (success) {
                    this.model!.SiteId = newValue.id;
                    this.model = await this.service.UpdateTransferItems(this.model);
                    routerHelper.navigateSelf();
                } else {
                    this.selectedSite = oldValue;
                }
            });
        } else {
            this.model!.SiteId = newValue.id;
        }

        this.setAddDisabled();
    }

    public selectedValueChanged(newValue: any, oldvalue: any): void {
        if (this.selectedActivity) {
            this.model!.ActivityId = this.selectedActivity.id;
        }

        if (this.selectedGroup) {
            this.model!.GroupId = this.selectedGroup.id;
        }

        this.service.UpdateTransferItems(this.model);

        this.setAddDisabled();
    }

    private async setProperties(model: InventoryProjectTransferDetailsModel | null): Promise<void> {
    this.model = model;

    if (!model) { return; }

    this.activities = await this.service.GetActivities(model.ProjectNo);
    this.sites = await this.service.GetSites(model.SourcePrefix);

    const allGroups = await this.service.GetExpenseGroups(model.ProjectNo);
    this.groups = allGroups.filter((item: any) => item.data.TypeId !== ProjectGroupType.Billing &&
                                                  item.data.TypeId !== ProjectGroupType.Labour);

    this.selectedSite = this.createDefaultMaSelectElement(model.SiteId, this.sites);
    this.selectedGroup = this.createDefaultMaSelectElement(model.GroupId, this.groups);
    this.selectedActivity = this.createDefaultMaSelectElement(model.ActivityId, this.activities);
  }

  private setAddDisabled(): void {
    this.addDisabled = !this.selectedActivity ||
                       !this.selectedGroup    ||
                       !this.selectedSite;
  }

  private createDefaultMaSelectElement(id: string | null, list: any[]): any {
    if (!id) { return null; }

    return list.find((item: any) => item.id === id);
  }
}
