import { InspectionServiceCallProxy } from "api/proxies/inspection-service-call-proxy";
import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { InspectionTypeLookupModel } from "api/models/company/inspection/inspection-type-lookup-model";

@autoinject()
export class ServiceCallInspectionService {
    constructor(private readonly inspectionServiceCallProxy: InspectionServiceCallProxy) {}

    public async GetInspectionTypesLookup(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InspectionTypeLookupModel[] | null> {
       return this.inspectionServiceCallProxy.GetInspectionTypesLookup(dispatchId, filter, pagingInfo, requestConfig);
    }
}
