// GENERATED FILE - DO NOT MODIFY
import { BonusConfigModel } from "api/models/company/bonus/bonus-config-model";
import { BonusModel } from "api/models/company/bonus/bonus-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class BonusProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetBonuses(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<BonusModel[] | null> {
        const uri = this.buildUri(
            "/bonus",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: BonusModel[] | null = await this.get<BonusModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Bonus.BonusModel");
        return data;
    }

    public async GetBonusesForDispatchProject(projectDispatchCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<BonusModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/bonuses",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: BonusModel[] | null = await this.get<BonusModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Bonus.BonusModel");
        return data;
    }

    public async GetConfig(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<BonusConfigModel[] | null> {
        const uri = this.buildUri(
            "/bonus/config",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: BonusConfigModel[] | null = await this.get<BonusConfigModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Bonus.BonusConfigModel");
        return data;
    }
}
