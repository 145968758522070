define([], function () {
    "use strict";

    var exports = {
        endsWith: function (value, searchString) {
            //minus sign in sustr function is for -1 * of the length
            return value.substr(-searchString.length) === searchString;
        },

        startsWith: function (value, searchString) {
            return value.substr(0, searchString.length) === searchString;
        },

        pad: function(value) {
            return (value < 10) ? ("0" + value) : value;
        },

        trimLeft: function(value, charlist) {
            if (charlist === undefined)
                charlist = "\s";

            return value.replace(new RegExp("^[" + charlist + "]+"), "");
        },

        trimRight: function(value, charlist) {
            if (charlist === undefined)
                charlist = "\s";

            return value.replace(new RegExp("[" + charlist + "]+$"), "");
        },

        trim: function(value, charlist) {
            return this.trimRight(this.trimLeft(value, charlist), charlist);
        },

        UrlAndMailAsLink: function(comment) {
            var urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            var emailRegex = /[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[a-zA-Z]{2,})/g;
         
            comment = this.ResolveMatch(comment, urlRegex, emailRegex, (s) => "<a class='blue' href=" + (s.includes("//") ? s : "//" + s + "/") + " target='_blank'>" + s + "</a>");

            //Check for emails
            comment = this.ResolveMatch(comment, emailRegex, undefined, (s) => "<a class='blue' href=mailto:" + s + ">" + s + "</a>");

            return comment;
        },

        ResolveMatch: function (comment, regex, excludeRegEx, transformText) {
            const matches = comment.match(regex);
            if (matches !== null && matches[0] !== undefined) {
                matches.forEach((match) => {
                    if (excludeRegEx === undefined || !excludeRegEx.test(match)) {
                        comment = comment.replace(match, transformText(match));
                    }
                });
            }
            return comment;
        }
    };

    return exports;
});
