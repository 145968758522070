define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        canConnect: function () {
            return requests.getJsonNoLoading(apiHelper.getMaestrServerConnectBaseUrl() + "/can-connect");
        }
    };

    return exports;
});
