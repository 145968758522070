import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class FieldServiceEmployee {

    public workOrderId: string = "";
    public readonly: boolean = false;
    public dispatchId: number = 0;
    public employeeId: number = 0;
    public employee: any;
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N) { }

    public bindViewModel(workOrderId: string, employeeId: number, dispatchId: number, querystring: string): void {
        const params = routerHelper.getQuerystring(querystring);
        this.readonly = !(params && params.readonly === "false");
        this.workOrderId = workOrderId;
        this.dispatchId = dispatchId;
        this.employeeId = employeeId;
    }

    public activate(params: any): any {
        this.bindViewModel(decodeURIComponent(params.workOrderId), params.employeeId, params.dispatchId, params.q);
    }

    public navigateToActivities(): void {
        let url = routerHelper.navigateTo("FieldService_Hour_Employee", this.workOrderId, true, this.employeeId);
        url += routerHelper.addQuerystring({ readonly: this.readonly });
        routerHelper.navigate(url);
    }

}
