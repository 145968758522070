export default class HostViewmodelProxy {
   
    constructor(hostViewModel) {
        this.wrapFunctions(hostViewModel);
    }

    wrapFunctions(hostViewModel) {
        Object.entries(hostViewModel).filter(([key, value]) => value instanceof Function).forEach(([key, value]) => {
            this[key] = this.wrapFunction(value);
        });
    }

    wrapFunction(value) {
        return (...args) => {
            const promise = value(...args);
            if (promise instanceof Promise) {
                // CEF sharp serialize all exception thrown from c# to string, and the js promise is rejected with the serialized exception instead of an error.We need to wrap all c# function call to make sure we reject all promise with an error.
                // See http://bluebirdjs.com/docs/warning-explanations.html#warning-a-promise-was-rejected-with-a-non-error
                return promise.catch(error => {
                    throw new Error(error);
                });
            }
            return promise;
        }
    }
}