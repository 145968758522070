define([
    "helpers/apiHelper",
    "services/requests"
], function (apiHelper, requests) {
    "use strict";

    var exports = {
        delInterruptionsForDispatchProjectCodeAndDate: function (dispatchProjectCode, dispatchDate, id) {
            return requests.remove(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/interruptions/"+id);
        },

        getInterruptionsForDispatchProjectCodeAndDate: function (dispatchProjectCode, dispatchDate, id) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/interruptions/" + (id ? id : ""));
        },

        setInterruptionsForDispatchProjectCodeAndDate: function (dispatchProjectCode, dispatchDate, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/interruptions/", data);
        }
    };

    return exports;
});
