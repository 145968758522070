import Logger from "core/logger";

export class ConsoleLoggerPostRenderStep {
    run(instruction, next) {
        Logger.debug("POST RENDER STEPS", instruction);

        return next();
    }
}

// Scroll the window to the top after a navigation.
export class ScrollToTopPostRenderStep {
    run(instruction, next) {
        Logger.debug("POST RENDER STEP - Scrolling to top");
        window.scrollTo(0, 1);

        return next();
    }
}

