import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import documentHelper from "helpers/documentHelper";

export class ReceiptOfGoodsDocument {

    public readonly: boolean = false;
    public receiptId: number | null = null;
    public orderNumber: string = "";
    public extension: string = "";

    public actions: any;

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.receiptId = params.receiptId;
        this.orderNumber = params.orderNumber;
        this.extension = params.extension;
        this.actions = {
            getSections: documentService.getSectionsByReceiptId.bind(self, this.receiptId),
            getSectionDocuments: documentService.getDocumentsByReceiptId.bind(self, this.receiptId),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService) };
    }
}
