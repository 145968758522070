import { I18N } from "aurelia-i18n";
import { default as notificationHelper } from "helpers/notificationHelper";
import { CatalogItemCategoryProxy } from "api/proxies/catalog-item-category-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { PagingInfo } from "api/paging-info";
import { CatalogItemCategoryModel } from "api/models/company/catalog/catalog-item-category-model";

@autoinject()
export class CatalogItemCategoryService {
    constructor(private i18n: I18N, private readonly catalogItemCategoryProxy: CatalogItemCategoryProxy) {
    }

    public async GetCategories(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemCategoryModel[] | null> {
        const categories = await this.catalogItemCategoryProxy.GetCategories(filter, pagingInfo, requestConfig);

        if (!categories) {
            return [];
        }

        return categories.map((item: any): any => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.Description}`,
                data: item
            };
        });
    }
}
