import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import dispatchService from "services/dispatchService";
import enumHelper from "helpers/enumHelper";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class AttendancesAbsence extends FormBase {

    public dispatchId: string = "";
    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public reason: string = "";
    public origReason: string | null = "";

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.origReason !== this.reason;
    }

    public activate(params: any): any {
        this.clear();
        this.bindViewModel(params);
    }

    public clear(): void {
        this.reason = "";
    }

    public setReason(): any {
        this.origReason = this.reason;
        dispatchService.setAttendanceStatus(this.dispatchId, { Presence: enumHelper.attendance().ABSENT, AbsenceReason: this.reason })
            .done(routerHelper.navigateBack);
    }

    private bindViewModel(params: any): any {
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dispatchId = params.dispatchId;
    }
}
