import moment from "moment";
import { I18N } from "aurelia-i18n";
import { autoinject, computedFrom } from "aurelia-framework";

import { StringHelper } from "helpers/string-helper";
import { TimeListHelper } from "helpers/time-list-helper";
import { NotificationHelper } from "helpers/notification-helper";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";

import attendanceService from "services/attendanceService";
import { Redirect, Router } from "aurelia-router";
import { DispatchProjectService } from "services/dispatch-project-service";
import { DispatchProjectWorkScheduleModel } from "api/models/company/project/dispatch-project-work-schedule-model";

@autoinject()
export class AttendanceHoursEdit {
    public readonly: boolean = true;
    public enableDateFormat: boolean = true;
    public increment: string = "5";
    public dispatchId: string = "";
    public labelFrom: string = "";
    public isTo: boolean = true;
    public labelTo: string = "";
    public dispatchDate: any;
    public dispatchProjectCode: string = "";
    public dispatchTemplateId: string = "";
    public dispatchProjectWorkScheduleModel: DispatchProjectWorkScheduleModel | null = null;
    public message: string = "";

    public dateFrom: Date = new Date();
    public dateTo: Date = new Date();

    public origdateFrom: Date = new Date();
    public origdateTo: Date = new Date();

    public timeOptions: any;
    public isDirty: boolean = false;

    @computedFrom("dateFrom", "dateTo")
    get isDirtyCheck(): boolean {
        if (this.readonly) {
            this.isDirty = false;
            return this.isDirty;
        }

        this.isDirty = !dateHelper.areEquals(this.origdateFrom, this.dateFrom) || !dateHelper.areEquals(this.origdateTo, this.dateTo);

        return this.isDirty;
    }

    constructor(private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router, private readonly dispatchProjectService: DispatchProjectService) {
        this.notificationHelper = notificationHelper;
    }

    public bindQueryString(queryString: string): void {
        this.readonly = queryStringHelper.parseReadonly(queryString);
    }

    public bindViewModel(dispatchId: any, dateFrom: string, dateTo: string): void {
        this.dispatchId = dispatchId;
        this.dateFrom = moment(dateFrom).toDate();
        this.dateTo = moment(dateTo).toDate();
        this.origdateFrom = moment(dateFrom).toDate();
        this.origdateTo = moment(dateTo).toDate();

        this.initTimeOptions(parseInt(this.increment));
    }

    public async activate(params: any): Promise<any> {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchTemplateId = params.dispatchTemplateId;
        this.message = params.equipmentDescription;
        this.bindQueryString(params.q);

        this.dispatchProjectWorkScheduleModel = await this.dispatchProjectService.getWorkingHours(this.dispatchProjectCode, this.dispatchTemplateId);

        this.bindViewModel(params.dispatchId, decodeURIComponent(params.dateFrom), decodeURIComponent(params.dateTo));
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public async save(): Promise<any> {
        const dateFrom = new Date(dateHelper.parseDate(this.dispatchDate).getFullYear(), dateHelper.parseDate(this.dispatchDate).getMonth(), dateHelper.parseDate(this.dispatchDate).getDate(), this.dateFrom.getHours(), this.dateFrom.getMinutes(), 0, 0);

        let dispatchDateTo = dateHelper.parseDate(this.dispatchDate);
        if (this.dispatchProjectWorkScheduleModel) {
            if (this.dispatchProjectWorkScheduleModel.StartHour > this.dispatchProjectWorkScheduleModel.EndHour) {
                dispatchDateTo = new Date(dateHelper.addDay(this.dispatchDate, 1));
            }
        }

        const dateTo = new Date(dispatchDateTo.getFullYear(), dispatchDateTo.getMonth(), dispatchDateTo.getDate(), this.dateTo.getHours(), this.dateTo.getMinutes(), 0, 0);

        try {
            await attendanceService.updateHoursEquipmentAttendance(this.dispatchProjectCode, dateHelper.formatDateToSend(this.dispatchDate), this.dispatchId, dateHelper.formatDateToSend(dateFrom), dateHelper.formatDateToSend(dateTo));

            this.dateFrom = dateFrom;
            this.dateTo = dateTo;
            this.origdateFrom = dateFrom;
            this.origdateTo = dateTo;

            routerHelper.navigateBack();
        } catch (e) {
            //do nothing exception are catched by the mobility framework
        }
    }

    public isValid(): boolean {
        return true;
    }

    private initTimeOptions(increment: number): void {
        this.timeOptions = TimeListHelper.loadTimeList(increment);
    }
}
