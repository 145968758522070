import { autoinject, observable, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { Redirect, Router } from "aurelia-router";
import RouteRepository from "repositories/routeRepository";

// Model
import { LocationModel } from "api/models/company/location-model";
import { DeliveryAddressModel } from "api/models/company/delivery-address-model";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { ContractDetailSearchModel } from "api/models/company/service/contract-detail-search-model";
import { ServiceCallDispatchCreationModel } from "api/models/company/service/service-call-dispatch-creation-model";
import { ClientModel } from "api/models/company/client/client-model";
import { ServiceCallDispatchResponseModel } from "api/models/company/service-call/service-call-dispatch-response-model";

// Service and Proxy
import { TemplateService } from "services/template-service";
import { ServiceCallProxy } from "api/proxies/service-call-proxy";
import { DeliveryAddressProxy } from "api/proxies/delivery-address-proxy";
import { ServiceCallContractProxy } from "api/proxies/service-call-contract-proxy";
import { ClientProxy } from "api/proxies/client-proxy";
import { CustomerContactService } from "services/customer-contact-service";

// Helper
import { EnumHelper } from "helpers/enum-helper";
import { RouterHelper } from "helpers/router-helper";
import { StringHelper } from "helpers/string-helper";
import { default as phoneHelper } from "helpers/phoneHelper";
import { ValidationHelper } from "helpers/validation-helper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { ErrorManager } from "error-management/error-manager";
import { CloneHelper } from "helpers/cloneHelper";

// Enum
import { ServiceDispatchType } from "enums/service-dispatch-type";
import { ServiceCallType, nameof as nameof_ServiceCallType } from "api/enums/service-call-type";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { ClientStatus } from "api/enums/client-status";
import { ValidationError } from "api/enums/validation-error";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";

export interface DropDownItem {
    id: any;
    text: string;
}

function lookupContracts(self: any, params: any, success: (x: any) => void, failure: (x: any) => void): void {
    let contractTypes = self.dispatchTemplate.ContractTypeList || null;
    if (self.dispatchTemplate.ContractTypeListAll) {
        contractTypes = null;
    }

    self.serviceCallContractProxy
        .Get(params.data.filterSelected, [], contractTypes, [], false, params.data.filter, params.data.page || 1).then(
            (result: ContractDetailSearchModel[]) => {
                return success(result.filter((item: any): boolean => {
                    return item.Id !== self.dispatchTemplate.TemporaryContract;
                }));
            },
            (fail: any) => {
                return failure(fail);
            }
        );
}

function lookupSpecifications(self: any, params: any, success: (x: any) => void, failure: (x: any) => void): void {
    self.deliveryAddressProxy
        .GetDeliveryAddresses(params.data.filter, params.data.page || 1).then(
            (result: DeliveryAddressModel[]) => {
                return success(result);
            },
            (fail: any) => {
                return failure(fail);
            }
        );
}

@autoinject()
export class EntryAdd {
    public i18n: I18N;
    public clientId: string | null = "";

    @observable public callType: ServiceCallType | null = null;
    public callsType: DropDownItem[] = [];
    @observable public comments: string = "";
    @observable public date: Date = new Date();

    @observable public alternativeAddress!: DeliveryAddressModel;
    @observable public displayAddressFields: boolean = false;
    @observable public dispatchTemplate!: DispatchTemplateModel;

    // Contract
    @observable public contract!: any;
    @observable public selectedContractType: string = "Contract";
    @observable public enableContract: boolean = true;
    public hasTemporaryContract: boolean = false;

    // Specifications
    @observable public specification!: any;
    @observable public enableWorkLocation: boolean = true;
    @observable public readonly: boolean = false;
    @observable public readonlyContactName: boolean = false;
    @observable public contactId: number | null = null;
    @observable public contactName: string | null = null;
    public newServiceCall: ServiceCallDispatchCreationModel | null = null;
    public unmodifiedServiceCall: ServiceCallDispatchCreationModel | null = null;

    public getContracts: any = {
        transport: (params: any, success: (x: any) => void, failure: (x: any) => void): any => {
            lookupContracts(this, params, success, failure);
        },
        mapResults: (item: ContractDetailSearchModel): {} => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.CustomerId}`,
                data: item };
        }
    };

    public getSpecifications: any = {
        transport: (params: any, success: (x: any) => void, failure: (x: any) => void): any => {
            lookupSpecifications(this, params, success, failure);
        },
        mapResults: (item: DeliveryAddressModel): {} => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.Name}`,
                data: item
            };
        }
    };

    private readonly EMPTY_CHAR: string = "\0";
    private readonly DISPATCH_NOT_FOUND: number = 8;

    constructor(i18n: I18N,
                private readonly templateService: TemplateService,
                private readonly serviceCallProxy: ServiceCallProxy,
                private readonly deliveryAddressProxy: DeliveryAddressProxy,
                private readonly serviceCallContractProxy: ServiceCallContractProxy,
                private readonly validationController: ValidationController,
                private readonly validationHelper: ValidationHelper,
                private readonly routeRepository: RouteRepository,
                private readonly routerHelper: RouterHelper,
                private readonly enumFormatValueConverter: EnumFormatValueConverter,
                private readonly clientProxy: ClientProxy,
                private readonly errorManager: ErrorManager,
                private readonly customerContactService: CustomerContactService,
                private readonly router: Router) {
        this.i18n = i18n;
    }

    public isDirty(): boolean {
        if (!this.newServiceCall || !this.unmodifiedServiceCall) { return false; }

        const stringifyUnmodified = JSON.stringify(this.unmodifiedServiceCall).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrent = JSON.stringify(this.newServiceCall).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent || this.contract;
    }

    public  canActivate(): boolean | Redirect {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }

        return true;
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            this.routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public async activate(): Promise<void> {
        await this.loadData();
        this.initializeAlternativeAddress();
        this.initValidation();
        this.initializeServiceCallType();
        this.newServiceCall = this.getAddressDto();
        this.unmodifiedServiceCall = this.getAddressDto();
    }

    public async loadData(): Promise<void> {
        const self: any = this;

        await this.templateService.getTemplateConfigs(settingHelper.getSelectedDispatchModel()).done((data: DispatchTemplateModel) => {
            self.dispatchTemplate = data;
            self.hasTemporaryContract = data.TemporaryContract !== "";
            self.displayAddressFields = self.hasTemporaryContract;
        });
    }

    public async contractChanged(newValue: any): Promise<void> {
        this.readonly = false;
        this.clientId = "";
        this.readonlyContactName = false;
        this.contactName = null;
        this.contactId = null;

        if (this.contract) {
            const client: ClientModel | null = await this.clientProxy.GetClientFromContract(this.contract.id);

            if (client) {
                if (client.Status === ClientStatus.Blocked) {
                    this.readonly = true;
                    const message = this.i18n.tr("msg_BlockedCustomer").replace("{0}", client.Id!).replace("{1}", client.Description!).replace("{2}", client.StatusReason!);
                    notificationHelper.showWarning(message, "", { timeOut: 0 });
                    return;
                } else if (client.Status === ClientStatus.Warning) {
                    const warning = this.i18n.tr("msg_BlockedCustomerWarning").replace("{0}", client.Id!).replace("{1}", client.Description!).replace("{2}", client.StatusReason!);
                    notificationHelper.showWarning(warning, "", { timeOut: 0 });
                }
                this.clientId = client.Id;
            }
        }

        this.updateContext("contract", this.contract);
    }

    public specificationChanged(newValue: any): void {
        this.updateContext("specification", this.specification);
    }

    public async populateContacts(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ContactLookupModel[] | null> {
        if (this.clientId !== "") {
            return await this.customerContactService.getClientContacts(this.clientId!, filter, pagingInfo, requestConfig);
        }
        return [];
    }

    public selectedContactChanged(event: CustomEvent<ContactLookupModel>): void {
        const selectedContact: ContactLookupModel | null = event ? event.detail : null;
        this.readonlyContactName = false;
        this.contactName = null;
        this.contactId = null;

        if (selectedContact !== null) {
            this.readonlyContactName = true;
            this.contactName = selectedContact.FullName;
            this.contactId = selectedContact.Id;
        }

    }

    public async save(): Promise<void> {
        if (!await this.validateAndNotify()) {
            return;
        }

        const contractId: string = this.contract ? this.contract.id : this.dispatchTemplate.TemporaryContract;
        this.newServiceCall = this.getAddressDto();

        try {
            const response: ServiceCallDispatchResponseModel | null = await this.serviceCallProxy.AddCall(this.newServiceCall, this.dispatchTemplate.Code, contractId);
            if (response !== null) {

                this.unmodifiedServiceCall = null;

                const routeParameters: any = this.routerHelper.buildMixedRouteParameters(
                    {
                        serviceType: ServiceDispatchType.Service,
                        dispatchId: response.DispatchId
                    }, { readonly: false });

                const navigationParameters: {} = { replace: true, trigger: true };

                this.routerHelper.navigateToRoute(
                    this.routeRepository.routes.Service_Detail.name,
                    routeParameters,
                    navigationParameters
                );
            }
        } catch (error) {
            const validationException = this.errorManager.getApiValidationExceptionFromError(error);
            if (validationException && validationException.ErrorId === ValidationError.DispatchModelNotFound) {
                this.unmodifiedServiceCall = null;
                this.routerHelper.navigateToRoute(this.routeRepository.routes.Settings.name);
                notificationHelper.showError(this.i18n.tr(`ApiError.${ValidationError.DispatchModelNotFound}`) + " " + this.dispatchTemplate.Code , undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                throw error;
            }
        }
    }

    protected initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
        .ensure("date").required().withMessageKey("err_DateRequired")
        .ensure("callType").required().withMessageKey("err_ServiceCallTypeRequired")
        .ensure("contract").satisfies(() => this.contractValidation()).withMessageKey("err_ContractRequired")
        .on(this);

        ValidationRules
        .ensure((x: DeliveryAddressModel) => x.Name).satisfies((name: string) => !!this.contract || !!name).withMessageKey("err_NameRequired")
        .ensure((x: DeliveryAddressModel) => x.Address).satisfies((address: string) => !!this.contract || !!address).withMessageKey("err_AddressRequired")
        .ensure((x: DeliveryAddressModel) => x.City).satisfies((address: string) => !!this.contract || !!address).withMessageKey("err_CityRequired")
        .on(this.alternativeAddress);
    }

    protected async validateAndNotify(): Promise<boolean> {
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        return isValid;
    }

    private initializeServiceCallType(): void {
        this.callsType = EnumHelper.getNumericValues(ServiceCallType)
        .filter((x: ServiceCallType) =>  x !== ServiceCallType.All)
        .map((x: ServiceCallType) => this.createDropDownItem(x))
        .sort((a: DropDownItem, b: DropDownItem) => a.text.localeCompare(b.text));

        // Default value
        this.callType = this.dispatchTemplate.ServiceCallType;
    }

    private createDropDownItem(callType: ServiceCallType): DropDownItem {
        return {
            id: callType,
            text: this.enumFormatValueConverter.toView(callType, nameof_ServiceCallType)
        } as DropDownItem;
    }

    private async contractValidation(): Promise<boolean> {
        const hasTemporaryContract: boolean = !!this.dispatchTemplate.TemporaryContract && this.dispatchTemplate.TemporaryContract!.length !== 0;
        return !!this.contract || hasTemporaryContract;
    }

    private async alternativeAddressValidation(): Promise<boolean> {
        const haveMinimumAddressInfos: boolean = !!this.alternativeAddress.Name && !!this.alternativeAddress.Address && !!this.alternativeAddress.City;
        return !!this.contract || haveMinimumAddressInfos;
    }

    private updateContext(type: any, selection: any): void {
        const selectionIsUndefinedOrNull: boolean =  selection === undefined ||  selection === null;
        const contractIsDefined: boolean = this.contract !== undefined && this.contract !== null;
        const specificationIsDefined: boolean = this.specification !== undefined && this.specification !== null;

        this.enableContract =  selectionIsUndefinedOrNull ? true : contractIsDefined;
        this.enableWorkLocation = selectionIsUndefinedOrNull ? true : specificationIsDefined;
        this.displayAddressFields = this.hasTemporaryContract || (!selectionIsUndefinedOrNull && contractIsDefined);

        if (selectionIsUndefinedOrNull) {
            this.initializeAlternativeAddress();
        } else {
            if (contractIsDefined) {
                this.updateAddressFields(this.contract.data.data.Location, type);
            } else {
                this.updateAddressFields(this.specification.data.data, type);
            }
        }
    }

    private updateAddressFields(location: any, type: string): void {
        this.alternativeAddress.Name = location.Name || "";
        this.alternativeAddress.Address = location.Address || "";
        this.alternativeAddress.City = location.City || "";
        this.alternativeAddress.Province = location.Province || "";
        this.alternativeAddress.PostalCode = location.PostalCode || "";
        this.alternativeAddress.Country = location.Country || "";
        this.alternativeAddress.ZipCode = location.ZipCode || "";

        if (type === "contract") {
            this.alternativeAddress.PhoneNumber = phoneHelper.getDefaultFormat(location.Telephone1) || "";
        } else {
            this.alternativeAddress.PhoneNumber = phoneHelper.getDefaultFormat(location.PhoneNumber) || "";
        }
    }

    private initializeAlternativeAddress(): void {
        this.alternativeAddress = {
            Id : -1,
            Name : "",
            Address : "",
            City : "",
            Province : "",
            PostalCode : "",
            Country : "",
            PhoneNumber : "",
            PhoneNumber2 : "",
            Fax : "",
            ZipCode : "",
            Division: ""
        };
    }

    private getAddressDto(): ServiceCallDispatchCreationModel {
        let specificationId!: string;

        if (!!this.specification) {
            specificationId = this.specification.id;
        }

        const location: LocationModel = this.deliveryAddressModelToLocationModel(this.alternativeAddress, specificationId);

        return {
            Location: location,
            Type: this.callType,
            Comments: this.comments,
            DispatchDate: this.date,
            CreateDispatch: true,
            DispatchEndDate: null,
            DispatchPresence: null,
            ServiceQuotationId: null,
            LocalizationModel: null,
            MaestroCreated: false,
            ContactId: this.contactId !== null ? this.contactId! : 0,
            ContactName: this.contactName
        };
    }

    private deliveryAddressModelToLocationModel(deliveryAddressModel: DeliveryAddressModel, specificationId: string | null): LocationModel {
        return {
            Id: null,
            Id2: null,
            NumericId: null,
            NumericId2: null,
            Type: this.EMPTY_CHAR,
            AddressKey: null,
            Name: deliveryAddressModel.Name,
            Address: deliveryAddressModel.Address,
            City: deliveryAddressModel.City,
            Province: deliveryAddressModel.Province,
            Country: deliveryAddressModel.Country,
            PostalCode: StringHelper.cleanString(deliveryAddressModel.PostalCode || "", StringHelper.keepAlphaNumericOnly),
            ZipCode: deliveryAddressModel.ZipCode,
            Telephone1: StringHelper.cleanString(deliveryAddressModel.PhoneNumber || "", StringHelper.keepAlphaNumericOnly),
            Telephone2: deliveryAddressModel.PhoneNumber2,
            Fax: deliveryAddressModel.Fax,
            Division: null,
            Specification: specificationId,
            DistanceInKm: 0,
            DistanceInMiles: 0
        };
    }
}
