import { MaCardSplitDisplayModel } from "./../../components/layout/ma-card/ma-card-options.model";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";

import { MaCardOptionsModel, MaCardLineModel } from "components/layout/ma-card/ma-card-options.model";
import RouteRepository from "repositories/routeRepository";
import { EquipmentMaintenanceParameters } from "./equipment-maintenance-required-material";
import { ServiceCallContractEquipmentStatusModel } from "../../api/models/company/service/service-call-contract-equipment-status-model";
import { ServiceCallEquipmentMaintenanceMaterialModel } from "api/models/company/service/service-call-equipment-maintenance-material-model";
import { ServiceCallDispatchEquipmentMaintenanceMaterialService } from "services/service-call-dispatch-equipment-maintenance-material";

@autoinject
export class MaterialSummary {

    public materialSummaryCards: MaCardOptionsModel[] = [];
    private dispatchId: number = 0;
    private serviceType: string = "";
    private readonly: boolean = false;
    private materials: ServiceCallEquipmentMaintenanceMaterialModel[] = [];

    constructor(private readonly routeRepository: RouteRepository, private readonly i18n: I18N, private readonly serviceCallDispatchEquipmentMaintenanceMaterialService: ServiceCallDispatchEquipmentMaintenanceMaterialService) {
        this.routeRepository = routeRepository;
    }

    public async activate(params: any): Promise<void> {

        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;

        await this.loadData();
    }

    public createCard(item: ServiceCallEquipmentMaintenanceMaterialModel): MaCardOptionsModel {
        const displayLines: MaCardLineModel[] = [];

        displayLines.push(new MaCardLineModel({
            splitDisplays: [
                new MaCardSplitDisplayModel({
                    display: `${this.i18n.tr("Code:")}`
                }),
                new MaCardSplitDisplayModel({
                    class: "bold",
                    display: `${item.Code}`,
                })
            ]
        }));

        if (item.Description1) {
            displayLines.push(new MaCardLineModel({id: "Desc1", display: `${this.i18n.tr("Description 1:")} ${item.Description1}`}));
        }
        if (item.Description2) {
            displayLines.push(new MaCardLineModel({id: "Desc2", display: `${this.i18n.tr("Description 2:")} ${item.Description2}`}));
        }
        if (item.Description3) {
            displayLines.push(new MaCardLineModel({id: "Desc3", display: `${this.i18n.tr("Description 3:")} ${item.Description3}`}));
        }

        const subCard = new MaCardOptionsModel({
            class: "background-light-grey",
            displayLines: [
                new MaCardLineModel({id: "QtyRequired", splitDisplays: [
                    new MaCardSplitDisplayModel({
                        class: "line-fixed-width-150",
                        display: `${this.i18n.tr("QuantityRequired")}:`
                    }),
                    new MaCardSplitDisplayModel({
                        display: `${item.Quantity}`,
                    })
                ]}),
                new MaCardLineModel({id: "QtyStock", splitDisplays: [
                    new MaCardSplitDisplayModel({
                        class: "line-fixed-width-150",
                        display: `${this.i18n.tr("QuantityInStock")}:`
                    }),
                    new MaCardSplitDisplayModel({
                        display: `${item.QuantityInStock}`,
                    })
                ]}),
                new MaCardLineModel({id: "QtyOrder", splitDisplays: [
                    new MaCardSplitDisplayModel({
                        class: "line-fixed-width-150",
                        display: `${this.i18n.tr("QuantityOrdered")}:`
                    }),
                    new MaCardSplitDisplayModel({
                        display: `${item.QuantityOrdered}`,
                    })
                ]}),
                new MaCardLineModel({id: "QtyMissing", splitDisplays: [
                    new MaCardSplitDisplayModel({
                        class: "line-fixed-width-150",
                        display: `${this.i18n.tr("QuantityMissing")}:`
                    }),
                    new MaCardSplitDisplayModel({
                        display: `${item.QuantityBackOrder}`,
                    })
                ]})]
        });

        displayLines.push(new MaCardLineModel({card: subCard}));

        const card = new MaCardOptionsModel({
            model: item,
            displayLines: displayLines,
            cardBodyClass: "pb-0"
        });

        return card;
    }

    public requiredMaterial(): void {
        return;
    }

    public goToMaintenanceTasks(model: ServiceCallContractEquipmentStatusModel): void {
        const params = {
            serviceType: this.serviceType,
            dispatchId: this.dispatchId,
            equipmentId: model.Id,
            equipmentCode: model.Code,
            maintenanceId: model.MaintenanceId,
            maintenanceFullDescription: model.MaintenanceFullDescription,
            equipmentDescription: model.BrandDescription,
            equipmentSerialNo: model.Serial,
            readonly: this.readonly
        } as EquipmentMaintenanceParameters;

        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Detail_EquipmentMaintenanceTask.name, params);
    }

    private async loadData(): Promise<void> {
        this.materials = await this.serviceCallDispatchEquipmentMaintenanceMaterialService.getAllRequiredMaterialsForMaintenance(this.dispatchId);
        if (this.materials.length > 0) {
            this.materialSummaryCards = this.materials.map(this.createCard.bind(this));
        }
    }
}
