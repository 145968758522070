define([
    "jquery",
    "underscore",
    "repositories/localDBTablesSchemaRepository"
], function (jQuery, _, tablesSchemaRepository) {
    "use strict";

    var dataBaseName = "MAESTROMOBILEBD";
    var dataBaseVersion = 1;
    var currentDb = null;

    function handleUpgradeNeeded(evt) {
        //define all tables schema

        //Pictures Queue
        tablesSchemaRepository.picturesQueue(evt.currentTarget.result.createObjectStore(tablesSchemaRepository.tables.picturesQueue, { keyPath: 'id', autoIncrement: true }));
    }

    function getDb(){
        return window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    }

    function openDb() {
        var dfd = new jQuery.Deferred();

        if (currentDb) {
            dfd.resolve(currentDb);
        } else {
            var indexedDB = getDb();

            var req = indexedDB.open(dataBaseName, dataBaseVersion);
            req.onsuccess = function () {
                currentDb = this.result;
                dfd.resolve(currentDb);
            };
            req.onerror = dfd.reject;

            req.onupgradeneeded = function (evt) {
                handleUpgradeNeeded(evt);
            };
        }

        return dfd;
    }

    function getObjectStore(storeName, mode) {
        var dfd = new jQuery.Deferred();

        openDb().done(function (db) {
            var tx = db.transaction(storeName, mode);
            dfd.resolve(tx.objectStore(storeName), tx);
        });

        return dfd;
    }

    var exports = {
        tables: tablesSchemaRepository.tables,

        selectByCriteria: function (tableName, criteria) {
            var dfd = new jQuery.Deferred();

            getObjectStore(tableName, 'readwrite').done(function (store) {
                var data = [];
                var req = store.openCursor();
                req.onsuccess = function (evt) {
                    var cursor = evt.target.result;

                    if (cursor) {
                        if (_.isMatch(cursor.value, criteria)) {
                            data.push(cursor.value);
                        }

                        cursor.continue();
                    } else {
                        dfd.resolve(data);
                    }
                };
            });

            return dfd;
        },

        insert: function (tableName, rowToInsert) {
            var dfd = new jQuery.Deferred();

            getObjectStore(tableName, 'readwrite').done(function (store, transaction) {
                transaction.oncomplete = dfd.resolve;

                var req = store.add(rowToInsert);
                req.onerror = dfd.reject;
            });

            return dfd;
        },

        insertMultiple: function (tableName, rowsToInsert) {
            var dfd = new jQuery.Deferred();

            getObjectStore(tableName, 'readwrite').done(function (store, transaction) {
                transaction.oncomplete = dfd.resolve;

                _.each(rowsToInsert, function (rowToInsert)
                {
                    var req = store.add(rowToInsert);
                    req.onerror = dfd.reject;
                });
            });

            return dfd;
        },

        update: function (tableName, rowToUpdate) {
            var dfd = new jQuery.Deferred();

            getObjectStore(tableName, 'readwrite').done(function (store, transaction) {
                transaction.oncomplete = dfd.resolve;

                var req = store.put(rowToUpdate);
                req.onerror = dfd.reject;
            });

            return dfd;
        },

        updateMultiple: function (tableName, rowsToUpdate) {
            var dfd = new jQuery.Deferred();

            getObjectStore(tableName, 'readwrite').done(function (store, transaction) {
                transaction.oncomplete = dfd.resolve;

                _.each(rowsToUpdate, function (rowToUpdate)
                {
                    var req = store.put(rowToUpdate);
                    req.onerror = dfd.reject;
                });
            });

            return dfd;
        },

        deleteByKey: function (tableName, key) {
            var dfd = new jQuery.Deferred();
            getObjectStore(tableName, 'readwrite').done(function (store, transaction) {
                transaction.oncomplete = dfd.resolve;

                var req = store.delete(key);
                req.onerror = dfd.reject;
            });

            return dfd;
        },

        deleteByCriteria: function (tableName, criteria) {
            var dfd = new jQuery.Deferred();

            getObjectStore(tableName, 'readwrite').done(function (store, transaction) {
                transaction.oncomplete = dfd.resolve;

                var req = store.openCursor();
                req.onsuccess = function (evt) {
                    var cursor = evt.target.result;

                    if (cursor) {
                        if (_.isMatch(cursor.value, criteria)) {
                            cursor.delete();
                        }

                        cursor.continue();
                    }
                };
            });

            return dfd;
        },

        truncateTable: function (tableName) {
            var dfd = new jQuery.Deferred();

            getObjectStore(tableName, 'readwrite').done(function (store, transaction) {
                transaction.oncomplete = dfd.resolve;

                var req = store.clear();
                req.onerror = dfd.reject;
            });

            return dfd;
        },

        dropDatabase: function(){
            getDb().deleteDatabase(dataBaseName)
        }
    };

    return exports;
});