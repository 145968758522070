import { bindingMode, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { Key } from "ts-keycode-enum";
import { ViewModeHelper } from "helpers/view-mode-helper";

@transient()
export class Checkbox {
    @bindable.booleanAttr({ defaultBindingMode: bindingMode.twoWay })
     public isChecked: boolean = false;

    @bindable.booleanAttr
    public disableToggleFunction: boolean = false;

    @bindable.booleanAttr public disabled: boolean = false;

    @bindable.string public theme: string = "primary";
    @bindable.string public iconClass: string = "fa-check";
    @bindable        public size: number = 1;

    @bindable.string public class: string = "";

    private checkboxElementRef!: HTMLElement;
    private readonly onKeyDownEventHandlerPointer!: EventListener;
    private viewModelHelper!: ViewModeHelper;

    constructor(private readonly element: Element, private readonly viewModeHelper: ViewModeHelper ) {

        this.viewModeHelper = viewModeHelper;

        if (viewModeHelper.getIsDesktopMode()) {
            this.onKeyDownEventHandlerPointer = (e: KeyboardEvent): void => {
                this.onKeyDownEventHandler(e);
            };
        }
    }
    public bind(): void {
        if (this.viewModeHelper.getIsDesktopMode())  {
            this.checkboxElementRef.addEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
        }
    }

    public detached(): void {
        if (this.viewModeHelper.getIsDesktopMode())  {
            this.checkboxElementRef.removeEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
        }
    }

    public toggleCheckbox(): void {
        if (this.disableToggleFunction) { return; }

        this.isChecked = !this.isChecked;
    }

    private onKeyDownEventHandler(e: KeyboardEvent): void {

        const enterHandler = (): void => {
            this.toggleCheckbox();
        };

        switch (e.keyCode) {
            case Key.Space:
            case Key.Enter:
                return enterHandler();
            default:
                return;
        }
    }

    private getSizeStyle(): object {
        return {
            "font-size": this.size + "em",
            "width"    : 44 * this.size + "px",
            "height"    : 44 * this.size + "px",
            "text-align" : "center",
        };
    }
}
