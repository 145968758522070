import { autoinject } from "aurelia-framework";

import { default as contactService } from "services/contactService";
import { default as recommendationService } from "services/recommendationService";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject()
export class RecommendationEdit extends FormBase {
    public readonly: boolean = false;
    public workOrderId: string = "";
    public recommendationId: any = "";
    public fetchClients: any;
    public fetchDefaultContact: any;
    public saveRecommendation: any;
    public parentModel: any;
    public isDirty: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public activate(params: any): any {
        this.bindViewModel(decodeURIComponent(params.workOrderId), params.recommendationId, params.q);
        this.parentModel = this;
    }

    public bindViewModel(workOrderId: string, recommendationId: string, querystring: string): void {
        const params = routerHelper.getQuerystring(querystring);
        this.recommendationId = recommendationId;
        this.workOrderId = workOrderId;
        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }

        this.fetchDefaultContact = contactService.getContactLookupForWorkOrder.bind(contactService, workOrderId);
        this.saveRecommendation = recommendationService.addRecommendationForWorkOrder.bind(recommendationService, workOrderId);
    }
}
