import { GridBase } from "./grid-base";
import { I18N } from "aurelia-i18n";
import { RowNode, RowSelectedEvent, Utils, CheckboxSelectionComponent } from "ag-grid";
import { CustomEventHelper } from "helpers/custom-event-helper";
import logger from "core/logger";
import { ExtendedRowNode } from "../extensions/extended-row-node";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";

export class TreeData extends GridBase {

    public treedata: Element | null = null;

    private readonly onCheckClickedEventHandlerPointer: EventListener;

    constructor(element: Element, i18n: I18N, userGridStateService: UserGridStateService, eventAggregator: EventAggregator) {
        super(element, i18n, userGridStateService, eventAggregator);

        this.onCheckClickedEventHandlerPointer = (e: Event): void => {
            this.onCheckClicked(e);
        };

        document.addEventListener("click", this.onCheckClickedEventHandlerPointer, true);
    }

    public detached(): void {
        document.removeEventListener("click", this.onCheckClickedEventHandlerPointer, true);
    }

    public selectAllEnabledEntities(): void {

        this.gridOptions.api.forEachNode( (node: any): void => {
            if (node.data) {
                node.setSelected(node.data.enabled);
            }
        });

        this.checkAllEnabledEntities();
    }

    public onCheckClicked(event: Event): void {

        if (this.eventTargetIsCheckbox(event)) {
            const rowNode = this.getRowNodeFromEventTarget(event);

            const extendedNode = this.createExtendedNode(rowNode);

            const isSelected = !rowNode.isSelected();

            if (extendedNode.isParent()) {
                if (isSelected) {
                    extendedNode.check();
                } else {
                    extendedNode.uncheck();
                }

                this.changeChildrenState(rowNode, isSelected);
            }

            rowNode.setSelected(isSelected);

            if (extendedNode.hasParent() && !extendedNode.isParentRoot()) {
                this.changeParentState(rowNode);
            }

            this.dispatchRowDataUpdatedEvent(rowNode.data.dataHierarchy, isSelected);

            event.stopImmediatePropagation();
        }
    }

    private getRowNodeFromEventTarget(event: Event): RowNode {
        const eventTarget = event.target as any;
        // hack-ish but seems to work
        return eventTarget.parentElement.__agComponent.rowNode;
    }

    private eventTargetIsCheckbox(event: Event): boolean {
        const eventTarget = event.target as any;
        const arrayOfClasses = Array.from(eventTarget.classList);

        return arrayOfClasses.filter((filteredClassName: string) =>
                filteredClassName.indexOf("ag-icon-checkbox") >= 0 ).length > 0;
    }

    private changeChildrenState(rowNode: RowNode, isSelected: boolean): void {

        const extendedNode = this.createExtendedNode(rowNode);
        const children = extendedNode.getAllLeafChildrenNodes();

        children.forEach((childNode: RowNode) => {

            const childExtendedNode = this.createExtendedNode(childNode);

            if (isSelected) {
                childExtendedNode.check();
            } else {
                childExtendedNode.uncheck();
            }
            childNode.setSelected(isSelected);

            this.dispatchRowDataUpdatedEvent(childNode.data.dataHierarchy, isSelected);
        });
    }

    private dispatchRowDataUpdatedEvent(dataHierarchy: string[], isSelected: boolean): void {
        CustomEventHelper.dispatchEvent(this.element, "row-data-updated", { dataHierarchy: dataHierarchy, nodeState: isSelected }, true, false);
    }

    private createExtendedNode(rowNode: RowNode): ExtendedRowNode {
        return new ExtendedRowNode(rowNode, this.treedata as Element);
    }

    private changeParentState(rowNode: RowNode): void {

        let parentNode = rowNode.parent;

        let parentExtendedNode = this.createExtendedNode(parentNode);
        let extendedNode = this.createExtendedNode(rowNode);

        do {
            if (parentExtendedNode.hasChildren()) {
                if (parentExtendedNode.allChildrenIndeterminated() ) {

                    rowNode.parent.setSelected(false);
                    parentExtendedNode.indeterminate();
                    if (rowNode.parent.data) {
                        this.dispatchRowDataUpdatedEvent(rowNode.parent.data.dataHierarchy, false);
                    }

                } else if (parentExtendedNode.allChildrenSelected() ) {

                    rowNode.parent.setSelected(true);
                    parentExtendedNode.check();
                    if (rowNode.parent.data) {
                        this.dispatchRowDataUpdatedEvent(rowNode.parent.data.dataHierarchy, true);
                    }

                } else if (parentExtendedNode.allChildrenUnselected() ) {

                    rowNode.parent.setSelected(false);
                    parentExtendedNode.uncheck();
                    if (rowNode.parent.data) {
                        this.dispatchRowDataUpdatedEvent(rowNode.parent.data.dataHierarchy, false);
                    }

                } else {

                    rowNode.parent.setSelected(false);
                    parentExtendedNode.indeterminate();
                    if (rowNode.parent.data) {
                        this.dispatchRowDataUpdatedEvent(rowNode.parent.data.dataHierarchy, false);
                    }
                }
            }

            parentNode = parentNode.parent;
            parentExtendedNode = this.createExtendedNode(parentNode);

            rowNode = parentNode;
            extendedNode = this.createExtendedNode(rowNode);
        }
        while (extendedNode.hasParent());
    }

    private checkAllEnabledEntities(): void {

        this.gridOptions.api.forEachNode( (node: any): void => {

            const extendedNode = this.createExtendedNode(node);

            if (extendedNode.isParent()) {
                if (extendedNode.allLeafChildrenSelected()) {
                    extendedNode.check();
                } else if (extendedNode.allLeafChildrenUnselected()) {
                    extendedNode.uncheck();
                } else {
                    extendedNode.indeterminate();
                }
            }

            if (extendedNode.hasParent() && !extendedNode.isParentRoot()) {
                this.changeParentState(node);
            }
        });
    }
}
