import { autoinject, bindable, transient } from "aurelia-framework";

import { default as _ } from "underscore";

import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";

function checkIsChecked(id) {
    if(id) {
        return _.contains(this.selectedDays, id);
    } else {
        this.weekDayTypes.forEach((day) => day.isChecked = _.contains(this.selectedDays, day.id));
    }
}

function weekdayClicked(id) {
    //id = 8 stands for button Mon-Fri to select all weekDays
    if (id === 8) {
        if (this.weekDaysSelected) {
            _.map(this.selectedDays, (id2) => {
                this.weekDaysSelected = false;
                this.selectedDays = this.selectedDays.filter((day) => day !== id2);
            });
        } else {
            this.weekDaysSelected = true;
            this.selectedDays.length = 0;
            this.selectedDays.push(...[1, 2, 3, 4, 5]);
        }
    } else if (_.contains(this.selectedDays, id)) {
        this.weekDaysSelected = false;
        this.selectedDays = this.selectedDays.filter((day) => day !== id);
    } else {
        this.weekDaysSelected = false;
        this.selectedDays.push(id);
    }

    checkIsChecked.call(this)
}

@transient()
@autoinject()
export class MultiSelectDays {

    @bindable selectedDays = [];
    @bindable disabled = false;
    @bindable editionDate = "";

    editionDay = 0;
    weekDayTypes = [];
    weekDaysSelected = false;

    constructor() {
        this.dateHelper = dateHelper;
        this.labelHelper = labelHelper;
    }

    bind() {
        this.loadWidgetSettings();
        this.loadWeekDayTypes();
    }

    loadWidgetSettings() {
        this.editionDay = this.getEditionDay(this.editionDate);

        if (this.disabled) {
            this.selectedDays.push(this.editionDay ? this.editionDay : 7);
        }
    }

    loadWeekDayTypes() {
        this.weekDayTypes = _.map(_.range(1, 9),
            (i) => {
                return {
                    id: i,
                    isChecked: checkIsChecked.call(this, i),
                    click: weekdayClicked.bind(this, i),
                };
            });
    }

    getEditionDay(editionDate) {
        var date = editionDate ? dateHelper.dateFromUTC(editionDate) : dateHelper.getDate();
        return date.getDay();
    }
}
