import { singleton, inject, Factory } from "aurelia-framework";
import { default as HostViewmodelProxy } from "core/host-viewmodel-proxy";

@singleton()
@inject(Factory.of(HostViewmodelProxy))
export default class HostContext {
    viewModel = null;
    searchViewModel = null;

    constructor(hostViewmodelProxyFactory) {
        if (window.HostViewModel) {
            if (!window.HostSearchViewModel) {
                throw new Error("HostViewModel is defined but HostSearchViewModel is not.");
            }
            this.hostViewmodelProxyFactory = hostViewmodelProxyFactory;
            this.viewModel = hostViewmodelProxyFactory(window.HostViewModel);
            this.searchViewModel = hostViewmodelProxyFactory(window.HostSearchViewModel);
        }
    }

    isHosted() {
        return !!this.viewModel;
    }
}