import val from "core/val";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable, observable } from "aurelia-framework";

@autoinject
export class MaSignature {
    public val: any = val;

    @bindable public id: any;
    @bindable public description: string = "";
    @bindable public name: string = "";
    @bindable public getContacts!: { transport: any, mapResults: any};
    @bindable public sign!: () => {};
    @bindable public required: boolean = false;
    @bindable public signatureId: number = 0;
    @bindable public disabled: boolean = false;

    @observable public selectedContact: any;

    public displayRequired: string = "";

    constructor(private readonly i18n: I18N) {}

    public bind(): void {
        this.displayRequired = this.required ? `(${this.i18n.tr("Required")})` : "";
    }

    public selectedContactChanged(newValue: any): void {
        if (!newValue) {
            return;
        }
        this.name = newValue.text;
    }
}
