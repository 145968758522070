import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

import { DailyEntrySmsContactProxy } from "api/proxies/daily-entry-sms-contact-proxy";
import { SmsContactModel } from "api/models/company/contact/sms-contact-model";

@autoinject()
export class DailyEntrySmsContactService {

    constructor(private readonly dailyEntrySmsContactProxy: DailyEntrySmsContactProxy) {
    }

    public async GetSMSContacts(projectDispatchCode: string | null, dispatchDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<SmsContactModel[] | null> {
        return this.dailyEntrySmsContactProxy.GetSMSContacts(projectDispatchCode, dispatchDate, filter, pagingInfo, requestConfig);
    }
}
