import { EventAggregator } from "aurelia-event-aggregator";
import { observable, PLATFORM, computedFrom, autoinject, bindable } from "aurelia-framework";
import { ValidationRules, ValidationControllerFactory, validateTrigger, ValidationController } from "aurelia-validation";
import { default as _ } from "underscore";

import { NavigationContext } from "core/navigation-context";
import { default as dateHelper } from "helpers/dateHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";
import contactService from "services/contactService";
import recommendationService from "services/recommendationService";
import serviceService from "services/serviceService";
import documentService from "services/documentService";
import { StringHelper } from "helpers/string-helper";
import moment from "moment";
import { I18N } from "aurelia-i18n";

const contextPrefix = {
    recommendation: "recommendation_",
    project_recommendation: "project_recommendation_"
};

const predefinedSentenceOptionName = {
    projects: "projects",
    service_calls: "service_calls",
    fieldservices: "fieldservices",
    recommendations: "recommendations"
};

const contexts = {
    recommendations: "recommendations",
    project: "project",
    service_calls: "service_calls_S",
    service_workorder: "service_calls_W",
    fieldservices: "fieldservices"
};

@autoinject
export class MaRecommendationEdit {
    public RECOMMENDATION_STATUS: typeof enumHelper.recommendationStatus = enumHelper.recommendationStatus;
    public RECOMMENDATION_TOPICS: typeof enumHelper.recommendationTopics = enumHelper.recommendationTopics;

    public dateTitle: any;
    public statusLocked: boolean = false;

    @bindable public context: string = "";
    @bindable public readonly: boolean = true;
    @bindable public fetchDefaultCustomer?: any;
    @bindable public fetchDefaultContact?: any;
    @bindable public fetchEquipments?: any;
    @bindable public fetchEquipmentDetails?: any;
    @bindable public saveRecommendation?: any;
    @bindable public updatePageSubtitle?: (tilte: string) => string;
    @bindable public recommendationId: string = "";
    @bindable public parentReadonly: boolean = false;
    @bindable public parentModel: any = null;

    @bindable public predefinedSentencesEntityId: any = null;
    @bindable public equipmentId: any = null;
    @bindable public templateSelection?: any;

    @observable public contact: any;
    @observable public followupContact: any;

    public today: Date = dateHelper.getDate();
    public typeList: any = [];
    public topicList: any = [];
    public pictures: any = [];
    public equipmentDetail: any;
    public templateResult: any = PLATFORM.moduleName("pages/templates/maSelectTemplates/recommendation_edit_result.html");
    public serviceType: string = "S";

    public client: any;
    public equipment: any;
    public contactName: string = "";
    public description: string = "";
    public followupComment: string = "";
    public followupContactName: string = "";
    public followupDate: any;
    public nextFollowupDate: any;
    public type: any;
    @observable public topic: any = { id: enumHelper.recommendationTopics().OTHER.id, text: enumHelper.recommendationTopics().OTHER.label, global: enumHelper.recommendationTopics().OTHER.global};
    public contactNameHR: string = "";
    public project: any;
    public recommendation: any;
    public validationController: ValidationController;
    public navigationContext: NavigationContext;

    public subscription: any;
    public deleteAction!: (documentId: number) => any;
    public contactHR: any;

    public isGlobalRecommendation: boolean = false;
    public isProjectRecommendation: boolean = false;
    public isServiceCallRecommendation: boolean = false;
    public isFieldServiceCallRecommendation: boolean = false;

    public hideTopic: boolean = true;
    public hideCustomer: boolean = true;
    public hideContact: boolean = true;
    public hideEquipment: boolean = true;
    public hideContactHR: boolean = true;
    public hideFollowUp: boolean = true;

    public recommendationInOriginalContext: boolean = false;
    public isAddMode: boolean = false;

    public predefinedSentencesOption: any;
    public unModifiedRecommendation: any = null;

    public map: any = {
        contact: (item: any): any => {
            return {
                id: item.Id,
                text: item.Id + " - " + item.FullName
            };
        },
        client: (item: any): any => {
            return {
                id: item.Id,
                // Id is already in description
                text: item.Description
            };
        },
        types: (item: any): any => {
            return {
                id: item.Id,
                text: item.Id + " - " + item.Name
            };
        },
        equipment: (item: any): any => {
            if (this.context === "recommendations") {
                return {
                    id: item.Code,
                    text: (!StringHelper.startsWith(item.Code, item.Description)  ? item.Code + " - " : "") + item.Description
                };
            } else {
                return {
                    id: item.Id,
                    Code: item.Code,
                    Description: item.Description,
                    text: item.Code + " - " + item.Description,
                    Serial: item.Serial,
                    Brand: item.Brand,
                    Model: item.Model,
                    Location: item.Location,
                    CustomerReference: item.CustomerReference,
                    Quantity: item.Quantity
                };
            }
        },
        contactHR: (item: any): any => {
            return {
                id: item.Id,
                text: item.Id + " - " + item.FullName
            };
        },
        topic: (item: any): any => {
            return {
                id: item.id,
                text: item.label
            };
        }
    };

    public lookupContact: any = {
        transport: async (params: any, success: (x: any) => void, failure: (x: any) => void): Promise<any> => {
            await contactService.getLookupByCustomerCode(this.client.id, params.data.filter, params.data.page || 1).then(
                (result: any) => {
                    return success(result);
                },
                (fail: any) => {
                    return failure(fail);
                });
        },
        mapResults: this.map.contact
    };

    public lookupContactHR: any = {
        transport: async (params: any, success: any, failure: any): Promise<any> => {
            await contactService.getContactsLookup(params.data.filter, params.data.page || 1).then(
                (result: any) => {
                    return success(result);
                },
                (fail: any) => {
                    return failure(fail);
                });
        },
        mapResults: this.map.contactHR
    };

    public lookupEquipment: any = {
        transport: async (params: any, success: any, failure: any): Promise<any> => {
            await this.fetchEquipments(params.data.filter, params.data.page || 1, true).then(
                (result: any) => {
                    return success(result);
                },
                (fail: any) => {
                    return failure(fail);
                });
        },
        mapResults: this.map.equipment
    };

    @computedFrom("context", "topic", "type", "contact", "contactName", "equipment", "contactHR", "description", "followupDate", "followupContact", "followupContactName", "followupComment", "statusLocked", "unModifiedRecommendation")
    get isDirtyCheck(): boolean {
        if (!this.parentModel) {
            return false;
        }

        if (this.readonly || this.statusLocked) {
            this.parentModel.isDirty = false;
            return this.parentModel.isDirty;
        }

        if (!this.unModifiedRecommendation) {
            this.parentModel.isDirty = false;
            return this.parentModel.isDirty;
        }

        const stringifyUnmodified = JSON.stringify(this.unModifiedRecommendation).replace(/[^0-9A-Z]+/gi, "");

        const current = this.buildRecommendationDto();
        const stringifyCurrent = JSON.stringify(current).replace(/[^0-9A-Z]+/gi, "");

        this.parentModel.isDirty = stringifyUnmodified !== stringifyCurrent;

        return this.parentModel.isDirty;
    }

    @computedFrom("topic")
    public get isCurrentTopicGlobal(): boolean {
        if (!this.topic) { return true; }

        const topic: any = _.find(this.RECOMMENDATION_TOPICS(), (x: any) => x.id === +this.topic.id);
        return topic.global;
    }

    @computedFrom("client")
    public get isContactListDisabled(): boolean {
        return !this.client;
    }

    constructor(public readonly i18n: I18N, public readonly eventAggregator: EventAggregator, validationControllerFactory: ValidationControllerFactory, navigationContext: NavigationContext) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.manual;

        this.navigationContext = navigationContext;

        this.templateSelection = (item: any): any => this.tSelection(item);

        // Validation
        ValidationRules
            .ensure((x: any) => x.description).required().withMessageKey("err_DescriptionRequired")
            .on(this);
    }

    public clientChanged(newValue: any): void {
        if (!newValue) {
            this.contact = null;
            this.contactName = "";
        }
    }

    public topicChanged(): void {
        this.updateFlagsFromContextTopic();
    }

    public contactChanged(newValue: any): void {
        this.contactName = newValue ? newValue.text : "";
    }

    public followupContactChanged(newValue: any): void {
        this.followupContactName = newValue ? newValue.text : "";
    }

    public async bind(): Promise<any> {
        this.deleteAction = documentService.deleteDocument.bind(documentService);

        this.subscription = this.eventAggregator.subscribe("document-upload-finish", () => this.reloadDocuments());

        await this.loadData();

        this.isAddMode = !this.recommendationId;
        this.hideFollowUp = this.isAddMode;

        this.updateFlagsFromContextTopic();

        this.setPredefinedSentencesOption();

        this.unModifiedRecommendation = this.buildRecommendationDto();
    }

    public setPredefinedSentencesOption(): void {
        if (this.context === contexts.project || this.isProjectRecommendation) {
            this.context = contexts.project;
            if (!this.predefinedSentencesEntityId) {
                this.predefinedSentencesEntityId = this.recommendation.Project;
            }
            this.predefinedSentencesOption = predefinedSentenceOptionName.projects;
        } else if (this.context === contexts.service_calls || this.context === contexts.service_workorder || this.isServiceCallRecommendation) {

            if (this.topic.id === this.RECOMMENDATION_TOPICS().SERVICECALL.id) {
                this.context = contexts.service_calls;
            }

            if (!this.predefinedSentencesEntityId) {
                if (this.recommendation.ServiceCallDispatchId) {
                    this.predefinedSentencesEntityId = this.recommendation.ServiceCallDispatchId;
                } else if (this.recommendation.WorkOrderDispatchId) {
                    this.predefinedSentencesEntityId = this.recommendation.WorkOrderDispatchId;
                }
            }
            this.predefinedSentencesOption = predefinedSentenceOptionName.service_calls;
        } else if (this.context === contexts.fieldservices || this.isFieldServiceCallRecommendation) {
            if (!this.predefinedSentencesEntityId) {
                this.predefinedSentencesEntityId = this.recommendation.WorkOrderId;
            }
            this.predefinedSentencesOption = predefinedSentenceOptionName.fieldservices;
        }

        this.predefinedSentencesOption = predefinedSentenceOptionName.recommendations;
    }

    public updateFlagsFromContextTopic(): void {
        this.isGlobalRecommendation = this.context === contexts.recommendations && this.isCurrentTopicGlobal;
        this.isProjectRecommendation = this.context === contexts.project || (this.topic && this.topic.id === this.RECOMMENDATION_TOPICS().PROJECT.id);
        this.isServiceCallRecommendation = this.context === contexts.service_calls || (this.topic && this.topic.id === this.RECOMMENDATION_TOPICS().SERVICECALL.id) || (this.topic && this.topic.id === this.RECOMMENDATION_TOPICS().WORKORDER.id && this.project !== "");
        this.isFieldServiceCallRecommendation = this.context === contexts.fieldservices || (this.topic && this.topic.id === this.RECOMMENDATION_TOPICS().WORKORDER.id && this.project === "");

        this.hideTopic = !this.isGlobalRecommendation;

        this.hideCustomer = this.isProjectRecommendation;
        this.hideContact = this.isProjectRecommendation;

        if (this.isServiceCallRecommendation && this.topic.id !== this.RECOMMENDATION_TOPICS().WORKORDER.id) {
            this.hideEquipment = false;
        } else {
            this.hideEquipment = this.topic && this.topic.id !== this.RECOMMENDATION_TOPICS().EQUIPMENT.id;
        }

        this.hideContactHR = this.topic && this.topic.id !== this.RECOMMENDATION_TOPICS().HR.id;

        this.recommendationInOriginalContext = true;

        if (this.parentReadonly || this.statusLocked) {
            this.readonly = true;
        } else {
            // Cannot edit project/service/fieldservice recommendation in the global section.
            this.readonly = !this.recommendationInOriginalContext;
        }

        return;
    }

    public detached(): any {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    public tSelection(item: any): string {
        this.equipmentDetail = item;
        return item.text;
    }

    public async reloadDocuments(): Promise<void> {
        if (this.recommendationId === undefined) {
            return;
        }
        this.pictures = (await recommendationService.getRecommendation(this.recommendationId)).Pictures;
    }

    public async loadData(): Promise<any> {
        const typesPromise = await recommendationService.getRecommendationTypes();
        const customerPromise = this.fetchDefaultCustomer ? await this.fetchDefaultCustomer() : null;
        const contactPromise = this.fetchDefaultContact ? await this.fetchDefaultContact() : null;
        this.recommendation = this.recommendationId ? await recommendationService.getRecommendation(this.recommendationId) : null;
        // Note: Si on ouvre une recommandation d'équipement dans la section globale, pour le moment fetchEquipmentDetails ne sera pas défini et les détails de l'équipement ne s'afficheront pas.
        const equipmentPromise = this.equipmentId && this.fetchEquipmentDetails ? await this.fetchEquipmentDetails(this.equipmentId) : null;

        this.initializeDefaultCustomer(customerPromise);
        this.initializeDefaultContact(contactPromise);
        this.initializeTypes(typesPromise, this.recommendation);
        this.initializeData(this.recommendation);
        this.initializeTopic(this.recommendation);

        if (this.recommendation && this.recommendation.ServiceCallDispatchId) {
            this.fetchEquipments = serviceService.getEquipments.bind(null, this.serviceType, this.recommendation.ServiceCallDispatchId);
            this.fetchEquipmentDetails = serviceService.getEquipmentDetails.bind(null, this.serviceType, this.recommendation.ServiceCallDispatchId);
        }

        await this.initializeEquipmentDetail(equipmentPromise, this.recommendation);
        this.project = this.recommendation ? this.recommendation.Project : null;
    }

    public initializeData(data: any): any {
        if (data) {
            this.statusLocked = +data.Status === this.RECOMMENDATION_STATUS().ONGOING.id || +data.Status === this.RECOMMENDATION_STATUS().COMPLETE.id;
            this.dateTitle = dateHelper.getFullTextDate(data.DateEntered);
            if (this.updatePageSubtitle) {
                this.updatePageSubtitle(this.dateTitle);
            }

            this.bindRecommendationData(data);

            this.pictures = data.Pictures;
        } else {
            this.statusLocked = false;
            this.dateTitle = dateHelper.getFullTextDate(new Date());
            if (this.updatePageSubtitle) {
                this.updatePageSubtitle(this.dateTitle);
            }
            this.pictures = [];
        }
    }

    public initializeTypes(types: any, recommendation: any): void {
        const typeId = recommendation ? recommendation.Type : null;

        this.typeList = _.map(types, this.map.types);
        this.type = _.find(this.typeList, (x: any) => x.id === typeId);
    }

    public initializeTopic(recommendation: any): void {
        const topicId = recommendation ? recommendation.Topic : null;

        this.topicList = _.filter(enumHelper.recommendationTopics(), (item: any) => item.global || item.id === topicId)
            .map(this.map.topic);

        if (topicId) {
            this.topic = _.find(this.topicList, (x: any) => x.id === topicId);
        }
    }

    public initializeDefaultCustomer(customer: any): void {
        this.client = customer ? this.map.client(customer) : null;
    }

    public async initializeEquipmentDetail(equipment: any, recommendation: any): Promise<void> {
        // Note: Si on ouvre une recommandation d'équipement dans la section globale, pour le moment fetchEquipmentDetails ne sera pas défini et les détails de l'équipement ne s'afficheront pas.
        if (!equipment && recommendation && recommendation.EquipmentId && this.fetchEquipmentDetails) {
            equipment = await this.fetchEquipmentDetails(recommendation.EquipmentId).promise();
        }

        if (equipment) {
            this.equipment = { id: equipment.Id, code: equipment.Code, text: equipment.Code + " - " + equipment.Description };
            this.equipmentDetail = equipment;
        } else if (recommendation && recommendation.EquipmentCode) {
            this.equipment = { id: recommendation.EquipmentCode, text: recommendation.EquipmentCode + " - " + recommendation.EquipmentDescription };
        }
    }

    public initializeDefaultContact(defaultContact: any): void {
        if (defaultContact) {
            this.contact = this.map.contact(defaultContact);
        } else {
            this.contact = null;
        }
    }

    public bindRecommendationData(data: any): void {
        if (data.CustomerCode) {
            this.client = { id: data.CustomerCode, text: data.CustomerName };
        }

        if (data.ContactNo) {
            this.contact = { id: data.ContactNo, text: data.ContactName };
        } else {
            this.contactName = data.ContactName;
        }

        this.description = data.Description;
        this.followupDate = data.FollowUpDate ? new Date(data.FollowUpDate) : null;
        this.nextFollowupDate = data.NextFollowUpDate ? new Date(data.NextFollowUpDate) : null;

        if (data.FollowUpContactNo) {
            this.followupContact = { id: data.FollowUpContactNo, text: data.FollowUpContactName };
        } else {
            this.followupContactName = data.FollowUpContactName;
        }

        if (data.ContactHR) {
            this.contactHR = { id: data.ContactHR, text: data.ContactHR + " - " + data.ContactNameHR };
        } else {
            this.contactNameHR = data.ContactNameHR;
        }

        this.followupComment = data.FollowUpComment;
    }

    public clearRecommendationData(): void {
        this.client = null;
        this.contact = null;
        this.contactName = "";
        this.description = "";
        this.followupDate = null;
        this.nextFollowupDate = null;
        this.followupContact = null;
        this.followupContactName = "";
        this.followupComment = "";
        this.equipment = null;
        this.contactHR = null;
        this.contactNameHR = "";
        this.equipmentDetail = "";
        this.type = null;
        this.topic = enumHelper.recommendationTopics().OTHER;
        this.project = null;
        this.unModifiedRecommendation = this.buildRecommendationDto();
    }

    public buildRecommendationDto(): any {
        const data: any = {
            ContactName: this.contactName || "",
            CustomerCode: this.client ? this.client.id : "",
            Description: this.description,
            FollowUpComment: this.followupComment || "",
            FollowUpContactName: this.followupContactName || "",
            Type: (this.type && this.type.id) ? this.type.id : "",
            Topic: this.topic ? this.topic.id : 0,
            Project: this.project
        };

        if (this.contact) {
            data.ContactNo = this.contact.id;
        }

        if (this.followupContact) {
            data.FollowUpContactNo = this.followupContact.id;
        }

        if (this.followupDate) {
            data.FollowUpDate = this.followupDate;
        }

        if (this.nextFollowupDate) {
            data.NextFollowUpDate = this.nextFollowupDate;
        }

        if (this.recommendationId) {
            data.RecommendationId = this.recommendationId;
        }

        if (!this.hideEquipment && this.equipment) {
            if (this.context !== "recommendations") {
                data.EquipmentId = this.equipment.id;
                if (this.equipmentDetail) {
                    data.EquipmentCode = this.equipmentDetail.Code;
                }
            } else {
                data.EquipmentCode = this.equipment.id;
            }
        }

        if (this.contactHR) {
            data.ContactHR = this.contactHR.id;
        }

        return data;
    }

    public goToPictureAdd(recommendationId: string): void {
        this.checkIfCanLeavePage().then((confirm: boolean) => {
            if (confirm) {
                const route = this.navigationContext.getCurrentRouteName() + "_Documents_Add";
                routerHelper.navigateToRoute(route, this.buildNavigationParameters(recommendationId, { subTitle: this.dateTitle }));
            }
        });
    }

    public async checkIfCanLeavePage(): Promise<boolean> {
        if (this.isDirtyCheck) {
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return false;
            }
            this.parentModel.isDirty = false;
        }
        return true;
    }

    public async save(addPicture: any): Promise<void> {
        const result = await this.validationController.validate();

        if (result.valid) {
            await this.executeSave(addPicture);
        } else {
            const error: any = _.pluck(result.results, "message");
            notificationHelper.showValidationError(error);
        }
    }

    public async executeSave(addPicture: any): Promise<void> {
        routerHelper.showLoading();

        this.saveRecommendation(this.buildRecommendationDto())
            .done(async (recommendationId: string) => {
                this.unModifiedRecommendation = null;
                if (addPicture) {
                    const route = this.navigationContext.getCurrentRouteName();
                    const navigationParams = this.buildNavigationParameters(recommendationId);
                    routerHelper.navigateToRoute(route, navigationParams, { replace: true, trigger: false });

                    await this.goToPictureAdd(recommendationId);
                } else {
                    routerHelper.navigateBack();
                }
            })
            .always(routerHelper.hideLoading);
    }

    public clear(): void {
        this.clearRecommendationData();
        this.recommendationId = "";
    }

    public async addPicture(): Promise<void> {
        if (!this.recommendationId) {
            this.save(true);
        } else {
            await this.goToPictureAdd(this.recommendationId);
        }
    }

    public buildNavigationParameters(recommendationId: string, additionnalQueryStringParameters?: any): any {
        const navigationParams = this.navigationContext.getParameters();
        let queryParams = this.navigationContext.getQueryParameters();

        queryParams = routerHelper.getQuerystring(queryParams);
        queryParams = _.extend(queryParams, additionnalQueryStringParameters);
        queryParams = { q: routerHelper.buildQueryString(queryParams)};

        const navigationParameters = _.extend({}, navigationParams, queryParams);

        navigationParameters.recommendationId = recommendationId;

        this.setPredefinedSentencesOption();
        navigationParameters.option = this.predefinedSentencesOption;
        navigationParameters.contextPrefix = this.context === contexts.project ? contextPrefix.project_recommendation : contextPrefix.recommendation;
        navigationParameters.editId = this.predefinedSentencesEntityId ? this.predefinedSentencesEntityId : recommendationId;

        return navigationParameters;
    }
}
