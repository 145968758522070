import { default as stringHelper } from "helpers/stringHelper";
import TextBoxCellEditor from "ag-grid/editors/textBoxCellEditor";

export default class NumericCellEditor extends TextBoxCellEditor {
    constructor(){
        super();
    }

    // Gets called once before the renderer is used
    init(params) {
        super.init(params);
      
        if (params.value !== undefined && params.value !== null) {
            this.inputElement.value = params.value;
        }

        this.lastValidValue = this.inputElement.value;
        this.addValidateInputListener();
    }

    addValidateInputListener() {
        throw "NotYetImplementedException";
    }

    // Returns the new value after editing
    getValue() {
        let value = stringHelper.trimRight(this.inputElement.value, " .");
        value = stringHelper.trimLeft(value, " 0");

        if (value === "" || value === ".0") {
            return 0;
        }

        return value;
    }
   
    validateInput(regex) {
        if (regex.test(this.inputElement.value)) {
            this.lastValidValue = this.inputElement.value;
        } else {
            this.inputElement.value = this.lastValidValue;
        }
    }
}

