import { bindable } from "aurelia-framework";
import logger from "core/logger"

export class StatusFooter {
    @bindable companyName;
    @bindable message;

    attached() {
        logger.warn("StatusFooter is Obsolete. Use the status-header control instead");
    }
}
