import { autoinject } from "aurelia-framework";

import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { ContactProxy } from "api/proxies/contact-proxy";
import { ContactModel } from "api/models/company/contact/contact-model";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ContactService {
    constructor(private readonly contactProxy: ContactProxy) {
    }

    public getContactLookup(contactId: number): Promise<ContactLookupModel | null> {
        return this.contactProxy.GetContactLookup(contactId);
    }

    public getContacts(clientId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ContactModel[] | null> {
        return this.contactProxy.GetContacts(clientId, filter, pagingInfo, requestConfig);
    }

    public getContact(contactId: number, requestConfig?: HttpClientRequestConfig): Promise<ContactModel | null> {
        return this.contactProxy.GetContact(contactId, requestConfig);
    }
}
