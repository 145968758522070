import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import activityService from "services/activityService";
import routerHelper from "helpers/routerHelper";
import { ProjectActivityProgressModel } from "api/models/company/project/project-activity-progress-model";

@autoinject
export class DailyEntryProjectProgress {

    public dispatchDate: Date = new Date();
    public dispatchProjectCode: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public activities: ProjectActivityProgressModel[] = [];

    constructor(private readonly i18n: I18N) {
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        this.loadData();
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);

        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
    }

    public async loadData(): Promise<void> {
        this.activities = await activityService.getActivitiesProgressForDailyEntry(this.dispatchProjectCode, this.dispatchDate);
    }

    public goToUrl(rel: string): void {
        if (!this.readonly) {
            routerHelper.navigate(this.genUrl(rel));
        }
    }

    public genUrl(rel: string): string {
        let url = routerHelper.getRelativeUrl(rel);
        url += routerHelper.addQuerystring({
            isProjectResponsible: this.isProjectResponsible,
            readonly: this.readonly
        });

        return url;
    }

    public gotoAddPage(): void {
        routerHelper.navigate(this.genUrl("edit"));
    }

}
