// GENERATED FILE - DO NOT MODIFY
import { CompanyModel } from "api/models/common/company/company-model";
import { UserSettingsModel } from "api/models/common/settings/user-settings-model";
import { MaestroBiReportBlocDisplayModel } from "api/models/company/maestro-bi/maestro-bi-report-bloc-display-model";
import { MaestroBiReportHierarchyModel } from "api/models/company/maestro-bi/maestro-bi-report-hierarchy-model";
import { MaestroBiReportImportChoiceModel } from "api/models/company/maestro-bi/maestro-bi-report-import-choice-model";
import { MaestroBiReportZipFileModel } from "api/models/company/maestro-bi/maestro-bi-report-zip-file-model";
import { MaestroBiSessionModel } from "api/models/company/maestro-bi/maestro-bi-session-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class MaestroBiProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetCompaniesForCurrentUserAndPartition(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CompanyModel[] | null> {
        const uri = this.buildUri(
            "/maestrobi/companies",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CompanyModel[] | null = await this.get<CompanyModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Common.Company.CompanyModel");
        return data;
    }

    public async GetMaestroBiCustomPrefixGroups(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<UserSettingsModel[] | null> {
        const uri = this.buildUri(
            "/maestrobi/customprefixgroup",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: UserSettingsModel[] | null = await this.get<UserSettingsModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Common.Settings.UserSettingsModel");
        return data;
    }

    public async CreateMaestroBiSession(companyName: string | null, prefixes: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<MaestroBiSessionModel | null> {
        const uri = this.buildUri(
            "/maestrobi/session",
            null,
            { companyName: (companyName === null || companyName === undefined) ? undefined : companyName, prefixes: (prefixes === null || prefixes === undefined) ? undefined : prefixes }
        );

        const data: MaestroBiSessionModel | null = await this.post<void, MaestroBiSessionModel | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.MaestroBi.MaestroBiSessionModel");
        return data;
    }

    public async FetchReportHierarchy(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<MaestroBiReportHierarchyModel[] | null> {
        const uri = this.buildUri(
            "/maestrobi/fetch_report_hierarchy",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: MaestroBiReportHierarchyModel[] | null = await this.get<MaestroBiReportHierarchyModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.MaestroBi.MaestroBiReportHierarchyModel");
        return data;
    }

    public async FetchReportById(reportId: string | null, requestConfig?: HttpClientRequestConfig): Promise<MaestroBiReportBlocDisplayModel | null> {
        const uri = this.buildUri(
            "/maestrobi/fetch_report_by_id",
            null,
            { reportId: (reportId === null || reportId === undefined) ? undefined : reportId }
        );

        const data: MaestroBiReportBlocDisplayModel | null = await this.get<MaestroBiReportBlocDisplayModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.MaestroBi.MaestroBiReportBlocDisplayModel");
        return data;
    }

    public async ExportReportById(reportId: string | null, requestConfig?: HttpClientRequestConfig): Promise<MaestroBiReportZipFileModel | null> {
        const uri = this.buildUri(
            "/maestrobi/export_report_by_id",
            null,
            { reportId: (reportId === null || reportId === undefined) ? undefined : reportId }
        );

        const data: MaestroBiReportZipFileModel | null = await this.get<MaestroBiReportZipFileModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.MaestroBi.MaestroBiReportZipFileModel");
        return data;
    }

    public async GetImportChoices(maestroBiReportZipFileModel: MaestroBiReportZipFileModel | null, requestConfig?: HttpClientRequestConfig): Promise<MaestroBiReportImportChoiceModel | null> {
        const uri = this.buildUri(
            "/maestrobi/get_import_choices",
            null,
            null
        );

        const data: MaestroBiReportImportChoiceModel | null = await this.post<MaestroBiReportZipFileModel | null, MaestroBiReportImportChoiceModel | null>(uri, maestroBiReportZipFileModel, requestConfig);
        this.transformModels(data, "Company.MaestroBi.MaestroBiReportImportChoiceModel");
        return data;
    }

    public async ImportReportWithinZip(maestroBiReportZipFileModel: MaestroBiReportZipFileModel | null, requestConfig?: HttpClientRequestConfig): Promise<string | null> {
        const uri = this.buildUri(
            "/maestrobi/import_report_by_id",
            null,
            null
        );

        const data: string | null = await this.post<MaestroBiReportZipFileModel | null, string | null>(uri, maestroBiReportZipFileModel, requestConfig);
        return data;
    }
}
