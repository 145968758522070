import defaultService from "services/defaultService";
import settingRepository from "repositories/settingRepository";
import { autoinject, bindable, observable } from "aurelia-framework";

import "pages/templates/skillCertificate_FilterByName.html";
import "pages/templates/skillCertificate_FilterByCertificate.html";
import "pages/templates/skillCertificate_FilterBySkill.html";
import { I18N } from "aurelia-i18n";
import documentHelper from "helpers/documentHelper";
import labelHelper from "helpers/labelHelper";
import _ from "underscore";
import routerHelper from "helpers/routerHelper";
import dateHelper from "helpers/dateHelper";
import { EmployeeModel } from "api/models/company/employee/employee-model";

@autoinject
export class SkillCertificates {

    public FilterByNameID: string = "name";
    public FilterByCertificateID: string = "certificate";
    public FilterBySkillID: string = "skill";
    public DEFAULT_FILTER: string = this.FilterByNameID;

    @bindable public actions: any = {};
    @bindable public isProjectResponsible: boolean = false;
    @bindable public readonly: boolean = false;
    @bindable public module: string = "";

    public partialView: string = "";

    public filterBy: string = this.DEFAULT_FILTER;
    public filtersList: any[] = this.getFilterList();

    public data: EmployeeModel[] = [];

    public currentSectionCount: number = 0;
    public sectionsByCertificateHasDocument: boolean = false;
    public sectionsByNameHasDocument: boolean = false;
    public sectionsBySkill: any = null;
    public sectionsByName: any = null;
    public sectionsByCertificate: any = null;

    constructor(private readonly i18n: I18N) {}

    public async bind(): Promise<any> {
        await this.loadData();
        this.initDropDownLists();
    }

    public getFilterList(): any[] {
        return [{
            id: this.FilterByNameID,
            text: this.i18n.tr("SortByName")
        }, {
            id: this.FilterByCertificateID,
            text: this.i18n.tr("SortByCertificate")
        }, {
            id: this.FilterBySkillID,
            text: this.i18n.tr("SortBySkill")
        }];
    }

    public getEmployeeName(emp: EmployeeModel): string {
        return emp.FirstName + " " + emp.LastName;
    }

    public getDownloadUrl(documentId: number): string {
        let documentUrl = documentHelper.getDocumentUrlById(documentId);
        documentUrl += "?access_token=" + settingRepository.getToken();
        return documentUrl;
    }

    public getDocumentIcon(type: string): string {
        return documentHelper.getIconClass(type);
    }

    public getDocumentSize(size: number): string {
        return documentHelper.formatDocumentSize(size);
    }

    public getDocumentType(type: string): string {
        return this.i18n.tr(documentHelper.getLocalizationKey(type));
    }

    public initDropDownLists(): void {
        const select2: any = jQuery("#ddlFilter");
        select2.select2({
                minimumResultsForSearch: defaultService.getMinimumResultsForSearch(),
                allowClear: false,
                placeholder: this.i18n.tr("SelectOoo"),
                language: labelHelper.getDefaultSelect2Labels(),
                width: "100%",
                data: this.filtersList
            })
        .on("change", this.onFilterByChanged.bind(this))
        .val(this.filterBy)
        .trigger("change");
    }

    public async loadData(): Promise<any> {
        this.data = await this.actions.getEmployeesForDispatch();
        this.initializeSections(this.filterBy);
    }

    public initializeSections(filterBy: string): void {
        switch (filterBy) {
            case this.FilterByNameID:
                if (this.sectionsByName === null) {
                    this.initializeSectionsByName(this.data);
                }
                this.currentSectionCount = this.sectionsByName.length;
                break;
            case this.FilterByCertificateID:
                if (this.sectionsByCertificate === null) {
                    this.initializeSectionsByCertificate(this.data);
                }
                this.currentSectionCount = this.sectionsByCertificate.length;
                break;
            case this.FilterBySkillID:
                if (this.sectionsBySkill === null) {
                    this.initializeSectionsBySkills(this.data);
                }
                this.currentSectionCount = this.sectionsBySkill.length;
                break;
        }
    }

    public initializeSectionsByName(data: EmployeeModel[]): void {
        const tempSections: any[] = [];

        if (!Array.isArray(data)) {
            return;
        }

        _.each(data, (employee: EmployeeModel) => {
                    const skills: any[] = [];
                    const certificates: any[] = [];

                    _.each(_.sortBy(employee.Skills!, (x: any) => x.Description.toLowerCase()), (skill: any) => {
                        skills.push(this.formatItemByName(skill, true));
                    });

                    _.each(_.sortBy(employee.Certifications!, (x: any) => x.Description.toLowerCase()), (certificate: any) => {
                        certificates.push(this.formatItemByName(certificate, false));
                    });

                    if (skills.length > 0 || certificates.length > 0) {
                        tempSections.push({
                            name: this.getEmployeeName(employee),
                            link: true ? this.actions.getEmployeeUrl(employee) : "javascript:void(0)",
                            hasLink: this.module === "project",
                            skills: skills,
                            certificates: certificates
                        });
                    }
                });

        this.sectionsByName = _.sortBy(tempSections, (x: any) => x.name.toLowerCase());
    }

    public getDocument(item: any, isSkill: boolean = false): any {
        const singleDocInfo: any =  {
            name: this.i18n.tr("Documents"),
            size: "",
            icon: this.getDocumentIcon("FOLDER"),
            type: "",
            url: (this.actions.addDispatchIdToUrl ? routerHelper.getRelativeUrl(item.DispatchId + "", item.CertificationAssignmentId + "") : routerHelper.getRelativeUrl(item.CertificationAssignmentId + ""))
                + (this.actions.queryStringToAppend ? this.actions.queryStringToAppend : "")
        };

        const listDocInfo: any = {
            id: item.DocumentsInfo && item.DocumentsInfo.length ? item.DocumentsInfo[0].DocumentId : "",
            name: item.DocumentsInfo && item.DocumentsInfo.length ? item.DocumentsInfo[0].FileName : "",
            size: item.DocumentsInfo && item.DocumentsInfo.length ? this.getDocumentSize(item.DocumentsInfo[0].FileSize) : "",
            icon: item.DocumentsInfo && item.DocumentsInfo.length ? this.getDocumentIcon(item.DocumentsInfo[0].Type) : isSkill ? "fa fa-cogs" : "fa fa-certificate",
            type: item.DocumentsInfo && item.DocumentsInfo.length ? this.getDocumentType(item.DocumentsInfo[0].Type) :  "",
            url: item.DocumentsInfo && item.DocumentsInfo.length ? this.getDownloadUrl(item.DocumentsInfo[0].DocumentId) : "javascript:void(0)"
        };

        return item.DocumentsInfo && item.DocumentsInfo.length > 1 ?  singleDocInfo : listDocInfo;
    }

    public formatItemByName(item: any, isSkill: boolean): any {
        if (item.DocumentsInfo) {
            this.sectionsByNameHasDocument = true;
        }

        return {
            name: item.Description,
            link: item.DocumentsInfo > 0 ? this.getDownloadUrl(item.DocumentsInfo.DocumentId) : "",
            expirationDate: item.ExpirationDate == null ? "" : (this.i18n.tr("Expiration:") + dateHelper.getTextDate(item.ExpirationDate)),
            isExpired: item.ExpirationDate == null ? false : dateHelper.isBeforeToday(item.ExpirationDate),
            document: this.getDocument(item, isSkill)
        };
    }

    public initializeSectionsByCertificate(data: EmployeeModel[]): void {
        const tempSections: any[] = [];

        if (!Array.isArray(data)) {
            return;
        }

        _.each(data, (emp: EmployeeModel) => {
            _.each(emp.Certifications!, (cert: any) => {
                if (cert.DocumentsInfo) {
                    this.sectionsByCertificateHasDocument = true;
                }

                let currentSection: any = _.find(tempSections, (s: any) => s.name === cert.Description);

                if (currentSection === undefined || currentSection === null) {
                    currentSection = {
                        name: cert.Description,
                        items: []
                    };
                    tempSections.push(currentSection);
                }

                currentSection.items.push({
                    name: this.getEmployeeName(emp),
                    link: this.module === "project" ? this.actions.getEmployeeUrl(emp) : "javascript:void(0)",
                    expirationDate: cert.ExpirationDate == null ? "" : (this.i18n.tr("Expiration:") + dateHelper.getTextDate(cert.ExpirationDate)),
                    isExpired: cert.ExpirationDate == null ? false : dateHelper.isBeforeToday(cert.ExpirationDate),
                    hasLink: true,
                    document: this.getDocument(cert)
                });
            });
        });

        _.each(tempSections, (section: any) => {
            section.items = _.sortBy(section.items, (x: any) => x.name.toLowerCase());
        });

        this.sectionsByCertificate = _.sortBy(tempSections, (x: any) => x.name.toLowerCase());
    }

    public initializeSectionsBySkills(data: EmployeeModel[]): void {
        const tempSections: any[] = [];

        if (!Array.isArray(data)) {
            return;
        }

        _.each(data, (emp: EmployeeModel) => {
            _.each(emp.Skills!, (skill: any) => {
                let currentSection = _.find(tempSections,
                    (section: any) => {
                        return section.sectionName === skill.Description;
                    });

                if (currentSection === undefined || currentSection === null) {
                    currentSection = {
                        sectionName: skill.Description,
                        items: []
                    };
                    tempSections.push(currentSection);
                }

                currentSection.items.push({
                    employeeName: this.getEmployeeName(emp),
                    employeeLink: this.module === "project" ? this.actions.getEmployeeUrl(emp) : "javascript:void(0)",
                });
            });
        });

        _.each(tempSections, (section: any) => {
            section.items = _.sortBy(section.items, (x: any) => x.employeeName.toLowerCase());
        });

        this.sectionsBySkill = _.sortBy(tempSections, (x: any) => x.sectionName.toLowerCase());
    }

    public onFilterByChanged(e: any): void {
        this.initializeSections(e.target.value);
        this.filterBy = e.target.value;
        this.partialView = "views/templates/skillCertificate_FilterBy" + this.filterBy.charAt(0).toUpperCase() + this.filterBy.slice(1);
    }

    public navigateToEmployee(item: any): void {
        if (item.link !== "") {
            routerHelper.navigate(item.link);
        }
    }

    public checkAndOpenDocument(item: any): void {
        documentHelper.openDocument(item.document);
    }

}
