import { inject, bindable } from "aurelia-framework";

import { I18N } from "aurelia-i18n";

import { default as notificationHelper } from "helpers/notificationHelper";
import { Scanner } from "components/others/scanner";

@inject(I18N)
export class ScannerButton {
    @bindable public disable: boolean = false;
    @bindable public hasTemplate: boolean = false;
    private scanner: Scanner = new Scanner();

    constructor(protected readonly i18n: I18N) {}

    public onScanned(scanner: Scanner, item: any): void {
        notificationHelper.showSuccess(this.i18n.tr("SuccessfulScan"));
        scanner.stopScan();
    }

    public onClicked(): void {
        this.scanner.startScan();
    }

    public stopScanner(): void {
        this.scanner.stopScan();
    }
}
