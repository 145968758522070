import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import settingRepository from "repositories/settingRepository";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { autoinject } from "aurelia-framework";
import { DocumentProxy } from "api/proxies/document-proxy";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class ReceiptOfGoodsDocumentAdd extends FormBase {

    public readonly: boolean = false;
    public receiptId: number | null = null;
    public dispatchTemplateId: string | null = null;
    public isCategorySelectionAllowed: boolean = false;
    public getCategoryProxy?: () => Promise<DocumentCategoryModel[] | null>;
    public isDirty: boolean = false;
    
    public actions: any;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly documentProxy: DocumentProxy, private readonly dispatchTemplateService: DispatchTemplateService
    ) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async activate(params: any): Promise<void> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.receiptId = params.receiptId;
        this.dispatchTemplateId = settingRepository.getDispatchTemplate();
        this.actions = { save: documentService.saveReceiptPicture.bind(self, this.receiptId, this.dispatchTemplateId) };
        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(this.dispatchTemplateId!);
        if (dispatchTemplate !== null) {
            this.isCategorySelectionAllowed = dispatchTemplate.AllowCategorySelection;
            this.getCategoryProxy = this.documentProxy.GetCategories.bind(this.documentProxy, dispatchTemplate.Code);
        }
    }
}
