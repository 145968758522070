import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";
import { MaCardOptionsModel, MaCardActionModel } from "./ma-card-options.model";

@autoinject
export class MaCard {
    @bindable public cardOptions!: MaCardOptionsModel;

    constructor(private readonly i18n: I18N) {}

    public getActionId(action: MaCardActionModel): string {
        return `${this.cardOptions.id}_action_${action.id}`;
    }

    public selectCard(cardOptions: MaCardOptionsModel): void {
        cardOptions.isSelected = !cardOptions.isSelected;
    }
}
