import { Container, inject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";
import logger from "core/logger";
import { default as moment } from "moment";
import "moment/locale/en-ca";
import "moment/locale/fr-ca";
import { SettingRepository } from "repositories/setting-repository";

@inject(SettingRepository)
export class LocalizationHelper {
    private settingRepository: SettingRepository;
    
    constructor(settingRepository: SettingRepository) {
        this.settingRepository = settingRepository;
    }

    //Returns the first part of a standard RFC 1766 culture name
    public getLanguageCode(): string {
        const lang = this.getCultureCode();
        return lang.substring(0, 2);
    }

    //Returns the last part of a standard RFC 1766 culture name
    public getRegionCode(): string {
        const lang = this.getCultureCode();
        return lang.substring(lang.length - 2);
    }

    //Saves a 5-character standard RFC 1766 culture name
    public setCultureCode(lang: string): void {
        logger.info(`Setting locale: ${lang}.`);

        // I18N
        const i18n = Container.instance.get(I18N);
        i18n.setLocale(lang);

        // Moment
        moment.locale(lang);

        // Local settings
        this.settingRepository.setLanguage(lang);
    }

    // Returns the language code following by a dash and the locale. eg: "fr-CA", "en-CA", "en-US"
    public getCultureCode(cultureCode?: string): string {
        cultureCode = cultureCode || this.settingRepository.getLanguage();

        if (cultureCode.length > 2) {
            return cultureCode;
        }

        return cultureCode + "-ca";
    }

    public setLocale(lang: string): void {
        // TODO JL: On devrait toujours persister la locale dans le même format.
        // Des fois on persiste seulement la langue (fr, en), d'autres fois la culture complète ("fr-CA", "en-US").
        logger.info(`Setting locale: ${lang}.`);

        // I18N
        const i18n = Container.instance.get(I18N);
        i18n.setLocale(lang);

        // Moment
        moment.locale(this.getCultureCode(lang));

        // Local settings
        this.settingRepository.setLanguage(lang);
    }
}
