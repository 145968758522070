import { DocumentModel } from "api/models/company/document/document-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { ListDocumentModel } from "api/models/company/document/list-document-model";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { DailyEntryWorkTaskDocumentProxy } from "api/proxies/daily-entry-work-task-document-proxy";

@autoinject()
export class DailyEntryWorkTaskDocumentService {
    constructor(private readonly dailyEntryWorkTaskDocumentProxy: DailyEntryWorkTaskDocumentProxy) { }

    public async AddDocumentForWorkTask(transactionNumber: number, models: Array<DocumentModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.dailyEntryWorkTaskDocumentProxy.AddDocumentForWorkTask(transactionNumber, models, requestConfig);
    }

    public async GetSectionsForWorkTask(transactionNumber: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        return this.dailyEntryWorkTaskDocumentProxy.GetSectionsForWorkTask(transactionNumber, filter, pagingInfo, requestConfig);
    }

    public async GetDocumentsForWorkTask(transactionNumber: number, sectionId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ListDocumentModel[] | null> {
        return this.dailyEntryWorkTaskDocumentProxy.GetDocumentsForWorkTask(transactionNumber, sectionId, filter, pagingInfo, requestConfig);
    }

    public async DeleteDocument(transactionNumber: number, documentId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.dailyEntryWorkTaskDocumentProxy.DeleteDocument(transactionNumber, documentId, requestConfig);
    }
}
