import { autoinject, customElement, TaskQueue } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { Key } from "ts-keycode-enum";

import { Textbox } from "./textbox";

@autoinject()
@customElement("multiline-textbox")
export class MultilineTextbox extends Textbox {
    public isOpen: boolean = false;

    @bindable.string
    public rows: number = 3;
    public textareaRef!: HTMLTextAreaElement;

    private onKeyDownEventHandlerPointer: EventListener;
    private onWindowResizeHandlerPointer: EventListener;

    constructor(element: Element, private readonly taskQueue: TaskQueue) {
        super(element);

        this.onKeyDownEventHandlerPointer = (e: KeyboardEvent): void => {
            this.onKeyDownEventHandler(e);
        };

        this.onWindowResizeHandlerPointer = (e: Event): void => {
            this.onWindowResizeEventHandler();
        };
    }

    public detached(): void {
        window.removeEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
    }

    public enableFullscreen(): void {
        this.isOpen = true;

        window.addEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
        window.addEventListener("resize", this.onWindowResizeHandlerPointer);

        this.makeTextareaFullscreenAndFocus(true);
    }

    public disableFullscreen(): void {
        this.isOpen = false;
        this.textareaRef.style.height = "";

        window.removeEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
        window.removeEventListener("resize", this.onWindowResizeHandlerPointer);

        this.textareaRef.blur();
    }

    private makeTextareaFullscreenAndFocus(resetCursorPosition: boolean): void {
        this.textareaRef.style.height = "";

        this.taskQueue.queueMicroTask(
            (): void => {
                const wrapper = this.textareaRef.parentElement as HTMLElement;

                this.textareaRef.style.height = wrapper.clientHeight + "px";
                this.textareaRef.focus();

                if (resetCursorPosition) {
                    this.textareaRef.setSelectionRange(0, 0);
                    this.textareaRef.scrollTop = 0;
                }
            }
        );
    }

    private onKeyDownEventHandler(e: KeyboardEvent): void {
        if (e.keyCode === Key.Escape) {
            this.disableFullscreen();
            return;
        }

        if (e.keyCode === Key.Tab) {
            e.preventDefault();

            const selectionStart = this.textareaRef.selectionStart;
            this.textareaRef.value = this.textareaRef.value.substring(0, selectionStart) + "\t" + this.textareaRef.value.substring(this.textareaRef.selectionEnd);

            this.textareaRef.selectionEnd = selectionStart + 1;

            return;
        }
    }

    private onWindowResizeEventHandler(): void {
        this.makeTextareaFullscreenAndFocus(false);
    }
}
