import { BudgetLoaderEntity } from "api/models/procore/loaders/budget-loader-entity";
import { ProcoreBudgetsForAProject } from "api/models/procore/loaders/procore-budgets-for-a-project";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ProcoreGridBudgetItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-budget-item";
import { ProcoreGridItemTransformer } from "./procore-grid-item-transformer";

@autoinject
export class BudgetTransformer {
  constructor(private readonly i18n: I18N, private readonly procoreGridItemTransformer: ProcoreGridItemTransformer) {

  }

  public transform(result: ProcoreBudgetsForAProject | null): ProcoreGridBudgetItem[] {
    if (!result || !result.Budgets) {
      return [];
    }

    const language = localStorage.getItem("maestro_user_profile_LANGUAGE");

    return result.Budgets.filter((transaction: BudgetLoaderEntity) => transaction)
                       .map((item: BudgetLoaderEntity): ProcoreGridBudgetItem => {
                            const errors = this.procoreGridItemTransformer.transformErrors(item.Errors, language);
                            const warnings = this.procoreGridItemTransformer.transformErrors(item.Warnings, language);

                            return {
                                ActivityCode: item.ActivityCode,
                                ActivityDescription: item.ActivityDescription,
                                GroupCode: item.GroupCode,
                                GroupDescription: item.GroupDescription,
                                Quantity: item.Quantity,
                                BudgetAmount: item.BudgetAmount.toFixed(2),
                                IsDetails: true,
                                Message: this.procoreGridItemTransformer.getMessage(errors, warnings),
                                Warnings: errors,
                                Errors: warnings
                            } as any;
                       });
  }
}
