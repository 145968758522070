import { Redirect } from "aurelia-router";
import { inject } from "aurelia-framework";

import logger from "core/logger";
import { default as resx } from "core/resx";
import { NavigationContext } from "core/navigation-context";
import notifier from "helpers/notificationHelper";
import settingRepository from "repositories/settingRepository";
import RouteRepository from "repositories/routeRepository";

import { default as BrowserHelper } from "helpers/browserHelper";
import routerHelper from "helpers/routerHelper";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { StringHelper } from "helpers/string-helper";
import applicationInsightsService from "services/applicationInsightsService";
import defaultService from "services/defaultService";

export class ConsoleLoggerPreActivateStep {
    run(instruction, next) {
        logger.debug("PRE ACTIVATE STEPS", instruction);
        return next();
    }
}

/**
 * Checks that the target route is valid for the current view mode.
 * If a corresponding route exists for the current view mode, redirects to that route.
 */
@inject(ViewModeHelper, RouteRepository)
export class ViewModeRouteVerificationPreActivateStep {
    viewModeHelper;
    routeRepository;

    constructor(viewModeHelper, routeRepository) {
        this.viewModeHelper = viewModeHelper;
        this.routeRepository = routeRepository;
    }

    run(instruction, next) {
        logger.debug("PRE ACTIVATE STEPS: ViewModeRouteVerification");

        // Redirect to desktop route.
        if (this.viewModeHelper.getIsDesktopMode() && this.isMobileRoutePattern(instruction.config.route)) {
            const desktopRoute = this.getCorrespondingDesktopRoute(instruction.config.route);
            if (desktopRoute) {
                logger.debug(`Mobile route (${instruction.config.route}) accessed while in desktop view mode. A corresponding desktop route exists, redirecting.`);

                const targetUrl = routerHelper.generate(desktopRoute.name, { ...instruction.queryParams, ...instruction.params });
                return next.cancel(new Redirect(targetUrl));
            }
        }

        // Redirect to mobile route.
        if (!this.viewModeHelper.getIsDesktopMode() && this.isDesktopRoutePattern(instruction.config.route)) {
            const mobileRoute = this.getCorrespondingMobileRoute(instruction.config.route);
            if (mobileRoute) {
                logger.debug(`Desktop route (${instruction.config.route}) accessed while in mobile view mode. A corresponding mobile route exists, redirecting.`);

                const targetUrl = routerHelper.generate(mobileRoute.name, { ...instruction.queryParams, ...instruction.params });
                return next.cancel(new Redirect(targetUrl));
            }
        }

        return next();
    }

    isDesktopRoutePattern(route) {
        return route.startsWith("d/");
    }

    isMobileRoutePattern(route) {
        return route.startsWith("m/");
    }

    getCorrespondingDesktopRoute(route) {
        const correspondingDesktopRoutePattern = StringHelper.replaceAt(route, 0, "d");
        return this.routeRepository.allRoutes().find((x) => x.route === correspondingDesktopRoutePattern);
    }

    getCorrespondingMobileRoute(route) {
        const correspondingMobileRoutePattern = StringHelper.replaceAt(route, 0, "m");
        return this.routeRepository.allRoutes().find((x) => x.route === correspondingMobileRoutePattern);
    }
}

/**
 * Checks that a company is currently selected and if not redirects to the Settings page unless the target route
 * can be accessed without a company.
 */
@inject(RouteRepository)
export class CurrentCompanyVerificationPreActivateStep {
    routeRepository;
    routesAccessibleWithoutACompany = [];

    constructor(routeRepository) {
        this.routeRepository = routeRepository;
        this.routesAccessibleWithoutACompany = [
            this.routeRepository.routes.Settings,
            this.routeRepository.routes.Help,
            this.routeRepository.routes.Error,
            this.routeRepository.routes.ErrorVersion
        ];
    }

    run(instruction, next) {
        logger.debug("PRE ACTIVATE STEPS: CurrentCompanyVerification");

        if (settingRepository.getCompany() || !this.isCompanyRequiredForRoute(instruction.config.name)) {
            return next();
        }
        
        logger.debug(`No company selected and the target route (${instruction.config.route}) requires a company. Redirecting to settings.`);
        return next.cancel(new Redirect("Settings"));
    }

    isCompanyRequiredForRoute(routeName) {
        return !this.routesAccessibleWithoutACompany.find((x) => x.name === routeName);
    }
}

export class ApplicationInsightsTrackPageViewPreActivateStep {
    run(instruction, next) {
        logger.debug("PRE ACTIVATE STEPS: ApplicationInsightsTrackPageView");
        
        applicationInsightsService.trackPageView(instruction.config.name, instruction.fragment);
        return next();
    }
}

export class PersistLastUrlForIosPreActivateStep {
    run(instruction, next) {        
        logger.debug("PRE ACTIVATE STEPS: PersistLastUrlForIos");
        
        const url = instruction.fragment + (instruction.queryString ? "?" + instruction.queryString : "");

        if (BrowserHelper.isWebApp()) {
            defaultService.setLastUrlShown(url);
        }
        
        return next();
    }

}

@inject(RouteRepository)
export class RouteVerificationPreActivateStep {
    routeRepository;

    constructor(routeRepository) {
        this.routeRepository = routeRepository;
    }

    run(instruction, next) {
        logger.debug("PRE ACTIVATE STEPS: RouteVerificationVerification");

        var route = this.routeRepository.getRoute(instruction.config.route);

        if (!route) {
            logger.error("Route not found: " + instruction.config.route);
            return next.cancel();
        }

        if (route.access) {
            var accesParam = route.access.split("=");
            var qryString = routerHelper.getQuerystring(instruction.queryParams);

            if (qryString === undefined) {
                return next.cancel();
            }

            if (accesParam[1] !== qryString[accesParam[0]]) {
                notifier.showWarning(resx.localize("YouDoNotHaveAccessToThisPage"));
                return next.cancel();
            }
        }
        
        return next();
    }
}

@inject(NavigationContext)
export class UpdateCurrentNavigationInstructionPreActivateStep {
    constructor(navigationContext) {
        this.navigationContext = navigationContext;
    }

    run(instruction, next) {
        logger.debug("PRE ACTIVATE STEPS: Update Current Navigation Instruction");
        this.navigationContext.currentNavigationInstruction = instruction;
        return next();
    }
}