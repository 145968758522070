export default class BaseCellEditor {
    // Gets called once before the renderer is used
    init(params) {
        this.focusAfterAttached = params.cellStartedEdit;       
    }

    // Focus and select can be done after the gui is attached
    afterGuiAttached() {
        if (this.focusAfterAttached) {
            this.focusIn();            
        }
    }

    isCancelBeforeStart() {
          return this.cancelBeforeStart;
    }

    // We can reject the value here after editing
    isCancelAfterEnd() {
        return false;
    }

    // Any cleanup we need to be done here
    destroy() {
       
    }

    // If true, then this editor will appear in a popup 
    // We could leave this method out, false is the default
    isPopup() {
        return false;
    }

    // Gets called once when grid ready to insert the element
    getGui() {
        throw "NotYetImplementedException";
    }

    // Returns the new value after editing
    getValue() {
        throw "NotYetImplementedException";
    }

     // when we tab onto this editor, we want to focus the contents
    focusIn() {
        throw "NotYetImplementedException";
    }

    // when we tab out of the editor, this gets called
    focusOut() {
        throw "NotYetImplementedException";
    }
}

