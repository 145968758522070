import { autoinject, bindable, transient } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import enumHelper from "helpers/enumHelper";
import { default as GuidHelper } from "helpers/guidHelper";
import _ from "underscore";

@transient()
@autoinject()
export class AddAttendance {

    @bindable public  ajax: any;
    @bindable public  disabled: boolean = false;
    @bindable public  id: any;
    @bindable public  okAction: any;
    @bindable public  minimumInputLength: number = 0;
    @bindable public  clearOnClsoe: boolean = false;
    @bindable public  showNotAvailable: boolean = false;

    public attendanceTypes: typeof enumHelper.attendanceTypes = enumHelper.attendanceTypes;

    public selectedResourceType: number = 0;
    public resourcesTypes: any[] = [];
    public resourcesTypesLabel: any[] = [];
    public selectedItem: any[] = [];

    constructor(private readonly i18n: I18N, private readonly guidHelper: GuidHelper) {
    }

    public bind(): any {
        this.selectedResourceType = this.attendanceTypes().EMPLOYEE.id;

        this.id = this.id ? this.id : this.guidHelper.createGuid();
        this.resourcesTypes = _.map(this.attendanceTypes(), (att: any) => att.id);
        this.resourcesTypesLabel = _.map(this.attendanceTypes(), (att: any)  => att.label);
    }
}
