import { default as routerHelper } from "helpers/routerHelper";

export class MapLinkValueConverter {
    constructor() {
        //
    }

    public toView(value: any): string {
        if (!value) {
            return "";
        }

        return routerHelper.mapAddress(value);
    }
}
