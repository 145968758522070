import { Container } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";

import { default as Logger } from "core/logger";
import HostContext from "core/host-context";
import RouteRepository from "repositories/routeRepository";

define([
    "underscore",
    "helpers/notificationHelper",
    "helpers/stringHelper",
    "helpers/localDBHelper",
    "core/resx",
    "repositories/settingRepository",
    "services/defaultService",
    "core/storage"
], function(
    _,
    notifier,
    stringHelper,
    localDBHelper,
    resx,
    settings,
    defaultService,
    storage
) {
    "use strict";

    var viewModel = function() {
        var self = this;
        self.router = null;
        self.originalBack = null;
        self.showLoadingCount = 0;
        self.previousInstruction = false;

        if (Container.instance) {
            self.hostContext = Container.instance.get(HostContext);
        }

        function populateExtraParameters(url, args) {
            _.each(args, function(val) {
                url += "/" + val;
            });

            if (url.indexOf("#") !== 0) {
                if (url.charAt(0) === "/") {
                    url = "#" + url;
                } else {
                    url = "#/" + url;
                }
            }

            return url;
        }

        function removeLastFrag(route) {
            var arr = route.split("/");

            if (arr.length > 1) {
                arr.pop();
                return arr.join("/");
            }
            return null;
        }

        function goToParent(parent, instruction) {
            //_.each(parent.route.split("/"), function (frag) {
            //    if (frag.indexOf(':') !== -1) {
            //        parentUrl += instruction.params[cptParamsUsed];
            //        cptParamsUsed++;
            //    } else {
            //        parentUrl += frag.replace("(", "").replace(")", "");
            //    }
            //    parentUrl += '/';
            //});
            //parentUrl = parentUrl.replace(/\/$/, "");

            var parentUrl = parent.navModel.href;
            if (instruction.queryString) {
                parentUrl += "?" + instruction.queryString;
            }

            self.router.navigate(parentUrl, { trigger: true, replace: true });
        }

        function findParentRouteForBack(instruction) {
            var route = instruction.config.route;
            var tempRoute = removeLastFrag(route);
            var parentRoute = "";

            function isRoute(r) {
                return r.route === tempRoute;
            }

            while (!parentRoute && tempRoute) {
                parentRoute = _.find(self.router.routes, isRoute);

                if (!parentRoute) {
                    tempRoute = removeLastFrag(tempRoute);
                }
            }

            return parentRoute;
        }

        self.init = function(router) {
            self.router = router;
            self.originalBack = router.navigateBack;
            router.navigateBack = self.navigateBack;
        };

        self.navigate = function(fragment, options) {
            Logger.warn(
                "DEPRECATED - routerHelper.navigate is deprecated! Use routerHelper.navigateToRoute to navigate using the route name instead of using a fragment"
            );
            self.router.navigate(fragment, options);
        };

        self.navigateToRoute = function(route, params, options) {
            if (self.hostContext && self.hostContext.isHosted()) {
                return self.navigateToRouteHosted(route, params, options);
            } else {
                self.router.navigateToRoute(route, params, options);
                return Promise.resolve();
            }
        };

        self.navigateToRouteHosted = function(routeName, params, options) {
            const route = self.router.routes.find(r => {
                return r.name === routeName;
            });

            if (!route) {
                Logger.error(`Invalid route name: '${routeName}'`);
                return Promise.resolve();
            } else {
                return self.hostContext.viewModel.navigateToRoute(
                    routeName,
                    JSON.stringify(params),
                    options && options.isModal ? true : false,
                    options && options.sizes ? options.sizes : null
                );

                // TODO ML: En ce moment on affiche toujours les nouvelles fenêtres en modal. Pour les fenêtres non-modales ça prendrait une façon d'être notifié dans le JS quand la fenêtre est fermée
            }
        };

        self.queryStringValidation = "&validation=maestro";

        self.findRootRoute = function() {
            if (!self.router.currentInstruction) {
                return undefined;
            }
            var currentRoute = self.router.currentInstruction.config;
            var rootRoute = _.find(self.router.navigation, function(route) {
                return stringHelper.startsWith(currentRoute.route, route.config.route);
            });
            return rootRoute.route;
        };

        self.findDefaultRouteFragment = function() {
            var currentRoute = self.router.currentInstruction.config;
            if (currentRoute.route !== "") {
                return currentRoute.route;
            }
            var defaultRoute = _.find(self.router.routes, function(route) {
                return route.name === "Home";
            });
            return defaultRoute.route;
        };

        self.getRouteByName = function(routeName) {
            return _.findWhere(self.router.routes, { name: routeName });
        };

        self.getRelativeUrl = function(url) {
            var fragment = self.getCurrentInstructionFragment();
            url = url.length > 0 && url.indexOf("/") !== 0 ? (!fragment.endsWith("/") ? "/" + url : url) : url;
            if (fragment === "") {
                fragment = self.findDefaultRouteFragment();
            }
            url = fragment + url;

            return populateExtraParameters(url, _.map(_.rest(arguments), encodeURIComponent));
        };

        self.getCurrentInstructionFragment = function() {
            return self.router.history.fragment.split("?")[0];
        };

        self.generate = function(routeName, params) {
            return self.router.generate(routeName, params);
        };

        self.navigateTo = function(routeName) {
            var url = "";
            var routeRepository = Container.instance.get(RouteRepository);
            var route = routeRepository.getRouteByName(routeName);
            var args = _.map(_.rest(arguments), encodeURIComponent);
            var usedArgs = [];
            var i = 0;

            if (route.indexOf("/")) {
                _.each(route.split("/"), function(part) {
                    if (part.indexOf(":") !== -1) {
                        if (i < args.length) {
                            url += args[i] + "/";
                            usedArgs.push(args[i]);
                            i++;
                        }
                    } else {
                        url += part.replace("(", "") + "/";
                    }
                });
            }

            if (stringHelper.endsWith(url, "/")) {
                url = url.substr(0, url.length - 1);
            }

            args = _.difference(args, usedArgs);

            return populateExtraParameters(url, args);
        };

        self.navigateBack = function() {
            if (self.hostContext && self.hostContext.isHosted()) {
                self.navigateBackHosted();
                return;
            }

            if (!window.navigator.onLine) {
                notifier.showError(resx.localize("err_NetworkError"));
            } else {
                var instruction = self.router.currentInstruction;

                if (window.history.length <= 1) {
                    var parentRoute = findParentRouteForBack(instruction);
                    if (parentRoute === "") {
                        parentRoute = self.getRouteByName("Home");
                    }
                    goToParent(parentRoute, instruction);
                } else {
                    if (instruction !== null && instruction !== undefined) {
                        var route = instruction.config.route;
                        if (
                            !_.find(self.router.navigation, function(r) {
                                return r.route === route && r.config.showBack === false;
                            })
                        ) {
                            if (_.isFunction(self.originalBack)) {
                                self.originalBack.apply(self.router);
                            }
                        }
                    }
                }
            }
        };

        self.navigateBackHosted = function() {
            self.hostContext.viewModel.closeWindow();
        };

        self.showLoading = function() {
            self.showLoadingCount++;
            if (self.showLoadingCount !== 1) {
                return;
            }

            var eventAggregator = Container.instance.get(EventAggregator);
            eventAggregator.publish("overlay:loading:visible", true);
        };

        self.hideLoading = function(forceHide) {
            self.showLoadingCount--;
            if (self.showLoadingCount < 0) {
                self.showLoadingCount = 0;
            }
            if (self.showLoadingCount !== 0 && !forceHide) {
                return;
            }

            var eventAggregator = Container.instance.get(EventAggregator);
            eventAggregator.publish("overlay:loading:visible", false);

            if (forceHide && self.router) {
                self.router.isNavigating = false;
                self.showLoadingCount = 0;
            }
        };

        self.isLoading = function() {
            return self.showLoadingCount > 0;
        }

        self.mapAddress = function(address) {
            return "//maps.apple.com/?q=" + encodeURIComponent(address);
        };

        self.addQuerystring = function(qryString) {
            return "?q=" + self.buildQueryString(qryString);
        };

        /*
            Build route parameters using a dictionnary of route parameters and another dictionnary of legacy parameters
            to encode in base64 in the query string for compatibility reasons

            navigationParameters: Dictionnary of key value navigation parameters. Either route parameters or key-values to append in the query string in clear text
            legacyQueryStringParameters: Dictionnary of key value to encode in base64 and append in the query string in a single blob variable
            return: Dictionnary of parameters to use when calling navigate to route
        */
        self.buildMixedRouteParameters = (routeParameters, legacyParametersToEncode) => {
            const encodedQs = this.buildQueryString(legacyParametersToEncode);
            return Object.assign({}, routeParameters, { q: encodedQs });
        };

        self.buildQueryString = function(qryString) {
            if (!qryString) {
                return "";
            }

            var querystring = _.map(Object.keys(qryString), function(key) {
                return [key, encodeURIComponent(qryString[key])].join("=");
            }).join("&");

            querystring += self.queryStringValidation;

            return window.btoa(querystring.indexOf("?") === 0 ? querystring.slice(1) : querystring);
        };

        self.getQuerystring = function(querystring) {
            if (!querystring) {
                return undefined;
            }
            var decodedError = false;
            var decodedString = "";

            try {
                if (querystring && querystring.q) {
                    // Sometimes querystring is a queryparams object but not always
                    querystring = querystring.q;
                }

                decodedString = window.atob(querystring);
                //handle coma in decode...
                decodedString = decodedString.replace(/%2C/g, ",");
            } catch (err) {
                decodedError = true;
            }

            //if decoding went wrong... redirect to the section root url
            if (decodedError || !stringHelper.endsWith(decodedString, self.queryStringValidation)) {
                var rootRoute = self.findRootRoute();
                if (rootRoute) {
                    self.router.navigate(rootRoute);
                } else {
                    self.router.navigate(self.findDefaultRouteFragment());
                }
                notifier.showWarning(resx.localize("err_URLInvalid"));
                return undefined;
            }
            //parse as object
            var params = decodedString.split("&");
            var result = {};
            _.each(params, function(param) {
                param = param.split("=");
                result[param[0]] = decodeURIComponent(param[1]) || "";
            });
            return result;
        };

        self.addQueryStringNoEncode = function(uri, key, value) {
            let separator = "?";

            if (uri.indexOf("?") !== -1) {
                separator = "&";
            }

            return uri + separator + key + "=" + value;
        };

        self.getQueryStringNoEncode = function(field, url) {
            const href = url ? url : window.location.href;
            const reg = new RegExp(`[?&]${field}=([^&#]*)`, "i");
            const string = reg.exec(href);

            return string ? string[1] : null;
        };

        self.showGlobalError = function() {
            var host = window.location.hostname;
            if (host.indexOf("/localhost/") === -1) {
                storage.clear();
                localDBHelper.dropDatabase();
                window.location.href = "//" + window.location.host + "/MaestroMobile/error.html";
            }
        };

        self.showVersionError = function() {
            var host = window.location.hostname;
            if (host.indexOf("/localhost/") === -1) {
                storage.clear();
                localDBHelper.dropDatabase();
                window.location.href = "//" + window.location.host + "/MaestroMobile/error_version.html";
            }
        };

        self.navigateSelf = function() {
            if (self.router.currentInstruction !== null) {
                var url = self.router.currentInstruction.fragment + "?" + self.router.currentInstruction.queryString;

                self.router.loadUrl(url);
            } else {
                window.location.reload(true);
            }
        };

        self.getPreviousRouteName = function(navigationInstruction) {
            if (navigationInstruction.previousInstruction) {
                return navigationInstruction.previousInstruction.config.name;
            } else {
                return "Home";
            }
        };

        return self;
    };

    return new viewModel();
});
