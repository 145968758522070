import { default as requests } from "services/requests";
import { default as apiHelper } from "helpers/apiHelper";

export default class ProductionBidItemService {
    getProductionBidItem(dispatchProjectCode, dispatchDate) {
        return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/biditemproduction')
            .promise();
    }

    saveProductionBidItem(dispatchProjectCode, dispatchDate, data) {
        return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/biditemproduction', data)
            .promise();
    }
}

