import { observable, computedFrom } from "aurelia-binding";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import Parse from "helpers/parse";
import routerHelper from "helpers/routerHelper";
import { NotificationHelper } from "helpers/notification-helper";
import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { CatalogService } from "services/catalog-service";
import { default as apiHelper } from "helpers/apiHelper";
import { ServiceCallDispatchEquipmentMaintenanceMaterialService } from "services/service-call-dispatch-equipment-maintenance-material";
import { ServiceCallEquipmentMaintenanceMaterialModel } from "api/models/company/service/service-call-equipment-maintenance-material-model";
import { CloneHelper } from "helpers/cloneHelper";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class EquipmentMaintenanceRequiredMaterialEdit {
    public params: any;

    public materialItem: ServiceCallEquipmentMaintenanceMaterialModel | null = null;
    public unmodifiedMaterial: ServiceCallEquipmentMaintenanceMaterialModel | null = null;

    public readonly: boolean = false;

    @observable public selectedItem: any;
    @observable public inventoryScaleFactor: number;

    public catalogBaseUrl: string = "";

    @computedFrom("materialItem.Code", "materialItem.Quantity")
    public get isDirty(): boolean {
        if (this.readonly || !this.materialItem || !this.unmodifiedMaterial) { return false; }

        const stringifyUnmodifiedMaterial = JSON.stringify(this.unmodifiedMaterial).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrentMaterial = JSON.stringify(this.materialItem).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodifiedMaterial !== stringifyCurrentMaterial;
    }

    constructor(private i18n: I18N,
                private readonly notificationHelper: NotificationHelper,
                private readonly serviceCallDispatchEquipmentMaintenanceMaterialService: ServiceCallDispatchEquipmentMaintenanceMaterialService,
                private readonly catalogService: CatalogService,
                private readonly router: Router) {
                    this.inventoryScaleFactor = catalogService.defaultScaleFactor;
                }

    public async activate(params: any): Promise<void> {
        this.params = params;

        this.readonly = Parse.Boolean(this.params.readonly);
        this.catalogBaseUrl = apiHelper.getCatalogServiceBaseUrl(this.params.serviceType) + "dispatch/" + this.params.dispatchId + "/materialrequisitions";

        this.materialItem = await this.serviceCallDispatchEquipmentMaintenanceMaterialService.getRequiredMaterialForMaintenance(this.params.dispatchId, this.params.equipmentId, this.params.materialId);
        this.unmodifiedMaterial = CloneHelper.deepClone(this.materialItem);
        this.inventoryScaleFactor = this.materialItem!.InventoryScaleFactor;

        const itemData = {
            Code: this.materialItem!.Code,
            Description1: this.materialItem!.Description1,
            Description2: this.materialItem!.Description2,
            Description3: this.materialItem!.Description3,
            InventoryScaleFactor: this.inventoryScaleFactor
        };

        this.selectedItem = { id: this.materialItem!.Code, text: this.materialItem!.Code, data: itemData };
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public async selectedItemChanged(catalogItem: any): Promise<void> {
        if (!catalogItem.data) {
            return;
        }

        this.materialItem!.Code = catalogItem.data.Code;
        this.materialItem!.Description1 = catalogItem.data.Description1;
        this.materialItem!.Description2 = catalogItem.data.Description2;
        this.materialItem!.Description3 = catalogItem.data.Description3;
        this.inventoryScaleFactor = catalogItem.data.InventoryScaleFactor;
    }

    public async save(): Promise<void> {
        const msg = this.i18n.tr("msg_ChangeRequiredMaterialForAllVisist");
        const onlyCurrentVisit = await this.notificationHelper.showDialogYesNo(msg);

        try {
            await this.serviceCallDispatchEquipmentMaintenanceMaterialService.replaceRequiredMaterialForMaintenance(this.params.dispatchId, this.materialItem, this.params.equipmentId, this.unmodifiedMaterial!.Code, !onlyCurrentVisit);
            this.unmodifiedMaterial = null;

            routerHelper.navigateBack();
        } catch (e) {
            // do nothing, handle by error messaging
        }
    }
}
