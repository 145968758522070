import { BaseEnumToIcon } from "./base-enum-to-icon";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";

export class ServiceCallQuotationItemTypeIconValueConverter extends BaseEnumToIcon {
    protected get mappings(): {[key: string]: string} {
        const map: {[key: string]: string}  = {};

        // map[ServiceCallQuotationItemType.BillingItem] = "fa fa-ticket";
        map[ServiceCallQuotationItemType.BillingItem] = "fa fa-balance-scale";
        // map[ServiceCallQuotationItemType.BillingItem] = "fa fa-shopping-basket";

        // map[ServiceCallQuotationItemType.Equipment] = "fa fa-car";
        // map[ServiceCallQuotationItemType.Equipment] = "fa fa-industry";
        // map[ServiceCallQuotationItemType.Equipment] = "fa fa-truck";
        // map[ServiceCallQuotationItemType.Equipment] = "fa fa-anchor";
        map[ServiceCallQuotationItemType.Equipment] = "fa fa-cubes";

        map[ServiceCallQuotationItemType.Labor] = "fa fa-user";
        map[ServiceCallQuotationItemType.Material] = "fa fa-wrench";
        map[ServiceCallQuotationItemType.MiscCost] = "fa fa-balance-scale";
        map[ServiceCallQuotationItemType.Bonus] = "fa fa-piggy-bank";

        return map;
    }
}
