import { bindable, transient, bindingMode, observable, DOM } from "aurelia-framework";

import { default as logger } from 'core/logger';
import { default as resx } from "core/resx";
import { default as enumHelper } from "helpers/enumHelper";
import { default as common } from "services/commonService";
import { Key } from "ts-keycode-enum";

@transient()
export class PredefinedSentences {
    resx = null;

    textbox = null;

    @bindable context = '';
    @bindable option = '';
    @bindable disabled = false;
    @bindable entityId = null;
    @bindable height = null;
    @bindable maxLength = 0;
    @bindable placeholder = '';
    @bindable width = '100%';
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value = '';
    @bindable acceptCrLf = true;
    /* The predefined sentence item selected from the select control */
    @observable selectedValue = false;

    get ajax() {
        return {
            transport: (params, success, failure) => {
                var option = enumHelper.predefinedSentences.options[this.option];
                var context = enumHelper.predefinedSentences.contexts[this.context];

                if (!option || !context) {
                    logger.error("Invalid Predefined Sentence Configuration", { option: option, context: context });
                }

                common.getDefinedText(option, context, this.entityId, params.data.filter, params.data.page)
                    .done(success)
                    .fail(failure);
            },
            mapResults: (item) => { return { id: 0, text: item.Description }; }
        }
    }

    constructor() {
        this.resx = resx;
    }

    removeNewLine() {
        if (!this.acceptCrLf && this.value) {
            this.value = this.value.replace(/\n/g, " ");
        }
    }

    handleKeyDown(event) {
        if (!this.acceptCrLf) {
            if (event.keyCode == Key.Enter) {
                return false;
            }
            this.removeNewLine();
        }
        return true;
    }

    attached() {
        this.textbox.style.minHeight = this.height;
        this.textbox.style.minWidth = this.width;
        this.textbox.style.width = this.width;

        this.textbox.maxlength = this.maxLength;
        this.textbox.placeholder = this.placeholder;
    }

    selectedValueChanged(item){
        this.insertText(item);
    }

    insertText(item) {
        if (item && item.text) {
            const scrollPos = this.textbox.scrollTop;
            const strPos = this.textbox.selectionStart;

            let textBefore = '';
            let textAfter = '';

            if (this.value){
                textBefore = this.value.substring(0, strPos);
                textAfter = this.value.substring(strPos, this.value.length);
            }

            let text = '';
            if (this.acceptCrLf) {
                text = textBefore + item.text + '\n' + textAfter
            } else {
                text = textBefore + item.text + " " + textAfter
            }

            this.value = this.maxLength
                ? text.substr(0, this.maxLength)
                : text;

            this.textbox.selectionStart = strPos + item.text.length + 1;
            this.textbox.selectionEnd = strPos + item.text.length + 1;
            this.textbox.focus();
            this.textbox.scrollTop = scrollPos;
        }
    }
}