import { autoinject } from "aurelia-framework";
import { WorkOrderMaintenanceModel } from "api/models/company/workorder/work-order-maintenance-model";
import { WorkOrderMaintenanceMeasureService } from "services/work-order-measure-service";
import { nameof as nameof_WorkOrderMaintenanceType } from "api/enums/work-order-maintenance-type";
import { RouterHelper } from "helpers/router-helper";
import { parseReadonly } from "helpers/queryStringHelper";
import notificationHelper from "helpers/notificationHelper";
import { CloneHelper } from "helpers/cloneHelper";
import { Redirect, Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";

@autoinject()
export class MaintenanceTasks {

    public dispatchId: string = "";
    public readonly: boolean = false;
    public maintenance: WorkOrderMaintenanceModel | null = null;
    public nameof_WorkOrderMaintenanceType: string = nameof_WorkOrderMaintenanceType;
    private oldMaintenance: WorkOrderMaintenanceModel | null = null;

    constructor(
        private readonly workOrderMaintenanceMeasureService: WorkOrderMaintenanceMeasureService,
        private readonly routerHelper: RouterHelper,
        private readonly i18n: I18N,
        private readonly router: Router
    ) {
    }

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.dispatchId;
        
        const paramsQs = this.routerHelper.getQuerystring(params.q);
        if (paramsQs && paramsQs.readonly !== "undefined" && paramsQs.readonly) {
            this.readonly = parseReadonly(params.q);
        }

        this.maintenance = await this.workOrderMaintenanceMeasureService.getMaintenanceMeasures(+params.dispatchId);
        this.oldMaintenance = CloneHelper.deepClone(this.maintenance);
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            this.routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public async save(): Promise<void> {
        await this.workOrderMaintenanceMeasureService.saveMaintenanceMeasures(this.maintenance);
        this.oldMaintenance = CloneHelper.deepClone(this.maintenance);
        this.routerHelper.navigateBack();
    }

    public isDirty(): boolean {
        const stringifyUnmodifiedMaintenance = JSON.stringify(this.oldMaintenance).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrentMaintenance = JSON.stringify(this.maintenance).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodifiedMaintenance !== stringifyCurrentMaintenance;
    }
}
