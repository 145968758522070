import _ from "underscore";
import moment from "moment";
import { I18N } from "aurelia-i18n";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import queryStringHelper from "helpers/queryStringHelper";

import dailyEntryService from "services/dailyEntryService";
import dispatchService from "services/dispatchService";
import templateService from "services/templateService";
import { DispatchProjectTimeEntriesSummaryResourceModel } from "api/models/company/project/dispatch/dispatch-project-time-entries-summary-resource-model";
import { DispatchProjectTimeEntriesSummaryProjectModel } from "api/models/company/project/dispatch/dispatch-project-time-entries-summary-project-model";

export class ProjectHoursSummary {
    public list: DispatchProjectTimeEntriesSummaryResourceModel[] = [];
    public selectedDate: Date = moment().toDate();
    public selectedMaxDate: Date = moment().toDate();
    public isProjectResponsible: boolean = false;
    public isHourSummaryToday: boolean = false;

    public isSameSelectedDay: boolean = true;

    private allInError: number = 0;
    private allOk: number = 1;
    private someOk: number = 2;

    constructor(private readonly i18n: I18N) {
    }

    public activate(params: any): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
        this.isHourSummaryToday = queryStringHelper.parse(params.q, "isHourSummaryToday") ? queryStringHelper.parse(params.q, "isHourSummaryToday") : false;

        if (this.isHourSummaryToday) {
            this.selectedDate = moment().toDate();
            this.selectedMaxDate = moment().toDate();
        }

        return this.loadData();
    }

    public dateChanged(): void {
        this.isSameSelectedDay = moment(this.selectedDate).isSame(this.selectedMaxDate, "day");
        this.reloadList();
    }

    public save(): any {
        const dispatchProjectCodeList = this.getUncompleteProjectsCodeList();

        if (dispatchProjectCodeList.length > 0) {
            routerHelper.showLoading();

            dailyEntryService.completeList(dateHelper.formatDate("YYYY-MM-DD", this.selectedDate), dispatchProjectCodeList, this.getTimeEntryMode(), this.getInChargeRequireIndicateWhenEmployeeLeftWorksite())
                .then((data: any) => {
                    switch (data.Status) {
                        case this.allOk:
                            routerHelper.navigateBack();
                            break;
                        case this.someOk:
                            this.loadData();
                            this.showSomeErrorsWarning(data);
                            break;
                        case this.allInError:
                            this.showAllInErrorWarning();
                            break;
                        default:
                            break;
                    }
                    routerHelper.hideLoading();
                });
        } else {
            this.showAllReadonlyWarning();
        }
    }

    private getUncompleteProjectsCodeList(): any {
        const dispatchProjectCodeList: string[] = [];

        this.list.forEach((employee: DispatchProjectTimeEntriesSummaryResourceModel) => {
            if (employee.Projects) {
                employee.Projects.forEach((project: DispatchProjectTimeEntriesSummaryProjectModel) => {
                    if (project) {
                        if (!project.Readonly && _.indexOf(dispatchProjectCodeList, project.DispatchProjectCode!) === -1) {
                            dispatchProjectCodeList.push(project.DispatchProjectCode!);
                        }
                    }
                });
            }

        });

        return dispatchProjectCodeList;
    }

    private loadData(): Promise<any> {
        return dispatchService.getHoursSummary(dateHelper.dateToNavigationParameter(this.selectedDate), dateHelper.dateToNavigationParameter(this.selectedMaxDate)).then((data: DispatchProjectTimeEntriesSummaryResourceModel[]) => {
            this.list = data;
        });
    }

    private reloadList(): void {
        routerHelper.showLoading();
        this.loadData().then(() => {
            routerHelper.hideLoading();
        });
    }

    private showSomeErrorsWarning(data: any): void {
        let msg = "";

        for (const i of data.ProjectInError) {
            msg += data.ProjectInError[i] + "<br/>";
        }

        notificationHelper.showWarning(this.i18n.tr("err_CompleteAllDailyEntry_SomeError").replace("{prjs}", msg), "", { timeOut: 5000 });
    }

    private showAllInErrorWarning(): void {
        notificationHelper.showWarning(this.i18n.tr("err_CompleteAllDailyEntry_AllError"));
    }

    private showAllReadonlyWarning(): void {
        notificationHelper.showWarning(this.i18n.tr("err_CompleteAllDailyEntry_AllReadonly"));
    }

    private getTimeEntryMode(): any {
        return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
    }

    private getInChargeRequireIndicateWhenEmployeeLeftWorksite(): any {
        return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
    }

}
