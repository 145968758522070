import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";
import defaultService from "services/defaultService";
import _ from "underscore";

@autoinject
export class MaRecommendation {

    @bindable public readonly: boolean = false;
    @bindable public fetchRecommendations!: (status: any, filter: any, page: any) => any;    // : Promise<>;
    @bindable public showTopics: boolean = false;
    @bindable public masterEntityId: number = 0;
    @bindable public backOnSave: boolean = false;

    public dateGroups: any[] = [];
    public dateHelper: typeof dateHelper = dateHelper;
    public RECOMMENDATION_STATUS: typeof enumHelper.recommendationStatus = enumHelper.recommendationStatus;
    public RECOMMENDATION_TOPICS: typeof enumHelper.recommendationTopics = enumHelper.recommendationTopics;
    public dateGroupsByStatus: any = {};
    public sectionShown: number = 0;
    public recommendationStatusValues: any[] = [];
    public page: number = 0;

    public filter: string = "";
    public scrollMessageText: string = "";
    public isScrollMessageVisible: boolean = false;
    public loadingStatus: any = {}; // True if the end of the infinite scrolling is reached for current tab
    public isScrollMessageShown: boolean = false;

    constructor(private readonly i18n: I18N) {
    }

    public async bind(): Promise<any> {
        await this.loadData(this.sectionShown);

        this.scrollMessageText = this.i18n.tr("ScrollForMore"); //still relevant?
        this.sectionShown = this.RECOMMENDATION_STATUS().ENTERED.id;

        this.recommendationStatusValues = _.values(this.RECOMMENDATION_STATUS());
        this.dateGroupsByStatus = {};
        this.sectionShown = this.RECOMMENDATION_STATUS().ENTERED.id;
    }

    public async loadData(status: number): Promise<any> {
        this.page = 1;
        const ls = await this.getData(status, this.page);
        this.setCachedList(this.sectionShown, ls);
        this.dateGroups = ls;
    }

    public getCachedList(statusId: number): any[] {
        return this.dateGroupsByStatus[statusId];
    }

    public setCachedList(statusId: number, ls: any[]): void {
        this.dateGroupsByStatus[statusId] = ls;
    }

    public async getData(status: number, page: number): Promise<any> {
        const filter = this.filter ? this.filter : "";

        const result = await this.fetchRecommendations(status, filter, page);
        return await this.getDataDone(result);
    }

    public async getDataDone(data: any): Promise<any> {
        data = await this.filterRecommendationsByStatusId(data, this.sectionShown);
        const itemCount = _.reduce(data,
            (aggregate: any, cur: any) => {
                return cur.Recommendations.length + aggregate;
            },
            0);

        if (itemCount >= defaultService.getPageSize()) {
            this.loadingStatus[this.sectionShown] = false;
            this.initScroll();
        } else {
            this.loadingStatus[this.sectionShown] = true;
            this.disposeScroll();
        }

        return data;
    }

    public filterRecommendationsByStatusId(data: any, statusId: number): any {
        _.each(data,
            (datum: any) => {
                datum.Recommendations = _.filter(datum.Recommendations,
                    (recommendation: any) => {
                        return Number.parseInt(recommendation.Status) === statusId;
                    });
            });

        return _.filter(data, (datum: any) => datum.Recommendations.length > 0);
    }

    public getStatusLabel(status: number): string {
        switch (status) {
            case this.RECOMMENDATION_STATUS().COMPLETE.id:
                return this.i18n.tr("Completed_F");

            case this.RECOMMENDATION_STATUS().ONGOING.id:
                return this.RECOMMENDATION_STATUS().ONGOING.label;

            default:
                return this.RECOMMENDATION_STATUS().ENTERED.label;
        }
    }

    public getStatusIcon(status: number): string {
        switch (status) {
            case this.RECOMMENDATION_STATUS().COMPLETE.id:
                return "fa fa-check";

            case this.RECOMMENDATION_STATUS().ONGOING.id:
                return "ma ma-ongoing";

            default:
                return "fa fa-hourglass-start";
        }
    }

    public getTypeTitle(recommendation: any): any {
        const refId = (recommendation.WorkOrderId
            ? recommendation.WorkOrderId
            : (recommendation.ServiceCallId ? recommendation.ServiceCallId : ""));

        return refId + (refId && recommendation.Type ? " - " : "") + recommendation.Type;
    }

    public getTopicTitle(recommendation: any): any {
        if (!recommendation.Topic) {
            return "";
        }

        const topic: any = _.find(this.RECOMMENDATION_TOPICS(), (x: any) => x.id === recommendation.Topic);

        if (!topic || !topic.label) {
            return "";
        }

        return topic.label;
    }

    public gotoPage(recommendation: any): void {
        let url;

        if (recommendation) {
            url = routerHelper.getRelativeUrl("edit", recommendation.RecommendationId) + routerHelper.addQuerystring({ entityId: this.masterEntityId, readonly: this.readonly });
        } else {
            url = routerHelper.getRelativeUrl("edit") + routerHelper.addQuerystring({ entityId: this.masterEntityId });
        }

        routerHelper.navigate(url);
    }

    public initScroll(): void {
        jQuery(document).scroll(this.scrollHandler.bind(this));
        this.showScrollMessage("scroll");
    }

    public disposeScroll(): void {
        jQuery(document).off("scroll");
        this.hideScrollMessage();
    }

    public scrollHandler: () => void = async (): Promise<void> => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showScrollMessage("loading");
            this.page += 1;
            const ls = await this.getData(this.sectionShown, this.page);
            const actualLs = this.joinList(this.dateGroups, ls);
            this.dateGroups = actualLs;
            this.setCachedList(this.sectionShown, actualLs);
        }
    }

    public joinList(baseList: any, list: any[]): any[] {
        _.each(list,
            (item: any) => {
                const baseItemToMerge = _.find(baseList,
                    (baseItem: any) => {
                        return baseItem.Date === item.Date;
                    });

                if (baseItemToMerge) {
                    baseItemToMerge.Recommendations = _.union(baseItemToMerge.Recommendations, item.Recommendations);
                } else {
                    baseList.push(item);
                }
            });

        return baseList;
    }

    public showScrollMessage(msgId: string): void {
        if (msgId === "loading") {
            this.scrollMessageText = this.i18n.tr("LoadingMoreResults");
        } else {
            this.scrollMessageText = this.i18n.tr("ScrollForMore");
        }

        this.isScrollMessageShown = true;
    }

    public hideScrollMessage(): void {
        this.isScrollMessageShown = false;
    }

    public disposeAll(): void {
        this.dateGroupsByStatus = {};
        this.dateGroups = [];
        this.disposeScroll();
    }

    public tabClicked(status: any): void {
        this.sectionShown = status.id;

        const cachedList = this.getCachedList(this.sectionShown);

        if (!cachedList) {
            this.loadData(status.id);
        } else {
            this.dateGroups = cachedList;

            if (this.loadingStatus[status.id]) {
                this.disposeScroll();
            } else {
                this.initScroll();
            }
        }
    }

    public async searchRecommendation(filter: string): Promise<any> {
        this.filter = filter;
        this.page = 1;
        this.disposeAll();
        const ls = await this.getData(this.sectionShown, this.page);
        this.setCachedList(this.sectionShown, ls);
        this.dateGroups = ls;
    }
}
