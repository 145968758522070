import { ProcoreDirectCostTransactionType } from "api/enums/procore-direct-cost-transaction-type";
import { ProcoreTransactionType } from "api/enums/procore-transaction-type";
import { DirectCostTransactionLoaderEntity } from "api/models/procore/loaders/direct-cost-transaction-loader-entity";
import { ProcoreTransactionGroup } from "api/models/procore/loaders/procore-transaction-group";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { DateFormatValueConverter } from "converters/format/date-format";
import { ProcoreGridGroup } from "pages/procore/synchronization/composition/grid/models/procore-grid-group";
import { ProcoreGridItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-item";
import { ProcoreGridDirectCostItem } from "pages/procore/synchronization/composition/grid/models/procore-grid-transaction-item";
import { ProcoreGridItemTransformer } from "./procore-grid-item-transformer";

@autoinject
export class DirectCostTransformer {
  constructor(private readonly i18n: I18N, private readonly procoreGridItemTransformer: ProcoreGridItemTransformer) {

  }

  public transform(transactions: Array<ProcoreTransactionGroup | null> | null): ProcoreGridGroup[] {
    if (!transactions || transactions.length <= 0) {
      return [];
    }

    return transactions.filter((transaction: ProcoreTransactionGroup) => transaction && transaction.LoaderEntities && transaction.LoaderEntities.length > 0)
                       .map((transaction: ProcoreTransactionGroup): ProcoreGridGroup => {
                          return {
                            GroupName: this.i18n.tr(`Procore.ProcoreDirectCostTransactionType_${ProcoreDirectCostTransactionType[transaction.Type]}`),
                            IsGroup: true,
                            DirectCostGroup: true,
                            DirectCostType: transaction.Type,
                            Target: null,
                            Children: this.transformTransactions(transaction.LoaderEntities, transaction.Type)
                          } as ProcoreGridGroup;
                       });
  }

  public transformTransactions(transactions: Array<DirectCostTransactionLoaderEntity | null> | null, directCostType: ProcoreDirectCostTransactionType): ProcoreGridItem[] {
    if (!transactions) {
      return [];
    }

    return transactions.filter((transaction: DirectCostTransactionLoaderEntity) => transaction)
                       .map((transaction: DirectCostTransactionLoaderEntity) => {
                            const language = localStorage.getItem("maestro_user_profile_LANGUAGE");
                            const errors = this.procoreGridItemTransformer.transformErrors(transaction.Errors, language);
                            const warnings = this.procoreGridItemTransformer.transformErrors(transaction.Warnings, language);
                            return {
                                AccountingDate: this.shortDateFormatter(transaction.AccountingDate),
                                BonusCode: transaction.BonusCode,
                                CalculatedAmount: transaction.CalculatedAmount,
                                Code: transaction.Code,
                                Children: transaction.Children,
                                Description: transaction.Description,
                                EquipmentCode: transaction.EquipmentCode,
                                EquipmentDescription: transaction.EquipmentDescription,
                                EmployeeNumber: transaction.EmployeeNumber,
                                EmployeeFirstName: transaction.EmployeeFirstName,
                                EmployeeLastName: transaction.EmployeeLastName,
                                ExtensionNumber: transaction.ExtensionNumber,
                                FinalAmount: transaction.FinalAmount,
                                Amount: transaction.Amount,
                                InvoiceNumber: transaction.InvoiceNumber,
                                IsSynchronized: transaction.IsSynchronized,
                                OrderNumber: transaction.OrderNumber,
                                ProcoreTransactionType: this.i18n.tr(`Procore.DirectCostConstants_${transaction.Version}`),
                                SupplierCode: transaction.SupplierCode,
                                Target: transaction.Target,
                                TransactionType: transaction.TransactionType,
                                DirectCostType: directCostType,
                                IsDetails: true,
                                Errors: errors,
                                Warnings: warnings,
                                Status: transaction.Status,
                                Message: this.procoreGridItemTransformer.getMessage(errors, warnings),
                            } as ProcoreGridDirectCostItem;
                       });
  }

  private shortDateFormatter(date: Date | null): any {
    if (!date) {
      return null;
    }

    const dateFormatValueConverter: DateFormatValueConverter = Container.instance.get(DateFormatValueConverter);

    return dateFormatValueConverter.toView(date, "L");
  }
}
