import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { autoinject } from "aurelia-framework";
import { DispatchContactProxy } from "api/proxies/dispatch-contact-proxy";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";

@autoinject()
export class DispatchContactService {

    constructor(private readonly dispatchContactProxy: DispatchContactProxy) {

    }

    public async GetCustomerSiteContactsLookupForDispatchClient(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ContactLookupModel[] | null> {
        return this.dispatchContactProxy.GetCustomerSiteContactsLookupForDispatchClient(dispatchId, filter, pagingInfo, requestConfig);
    }

}
