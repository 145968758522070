import { inject } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as routerHelper } from "helpers/routerHelper";
import { default as errorsService } from "services/errorsService";
import { default as requests } from "services/requests";
import { default as apiHelper } from "helpers/apiHelper";

import { ErrorProxy } from "api/proxies/error-proxy";

@inject(ErrorProxy)
export class Api {
    errorProxy;
    statusCode;
    type;

    constructor(errorProxy) {
        this.errorProxy = errorProxy;
    }

    activate(params) {
        this.statusCode = params.statusCode;
        this.type = params.type;

        if (this.type === "activate") {
            return this.callServerReturnStatusCode(this.statusCode);
        }
        if (this.type === "activatevalidation") {
            return this.callServerReturnValidationError(this.statusCode);
        }
        if (this.type === "activateaxios") {
            return this.callServerReturnStatusCodeAxios(this.statusCode);
        }
        if (this.type === "activatevalidationaxios") {
            return this.callServerReturnValidationErrorAxios(this.statusCode);
        }
    }

    attached() {
        if (this.type === "attached") {
            return this.callServerReturnStatusCode(this.statusCode);
        }
        if (this.type === "attachedvalidation") {
            return this.callServerReturnValidationError(this.statusCode);
        }
        if (this.type === "attachedaxios") {
            return this.callServerReturnStatusCodeAxios(this.statusCode);
        }
        if (this.type === "attachedvalidationaxios") {
            return this.callServerReturnValidationErrorAxios(this.statusCode);
        }
    }

    callServerReturnStatusCode(statusCode) {
        const url = apiHelper.getBaseUrl() + "/error/status/" + statusCode;
        return requests.getJson(url);
    }

    callServerReturnStatusCodeAxios(statusCode) {
        return this.errorProxy.ReturnCode(statusCode);
    }

    callServerReturnValidationError(errorCode) {
        const url = apiHelper.getBaseUrl() + "/error/validation/" + errorCode;
        return requests.getJson(url);
    }

    callServerReturnValidationErrorAxios(errorCode) {
        return this.errorProxy.ReturnValidationException(errorCode);
    }
}