import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import settingRepository from "repositories/settingRepository";
import documentService from "services/documentService";

import { I18N } from "aurelia-i18n";

import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@autoinject
export class RecommendationEditDocumentAdd {

    public readonly: boolean = false;
    public subTitle: string = "";
    public editId: any;
    public option: string = "";
    public contextPrefix: string = "";
    public actions!: { save: any; };
    public isDirty: boolean = false;

    constructor(
        private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router
    ) { }

    public activate(params: any): void {
        const queryStringParams = routerHelper.getQuerystring(params.q);
        this.subTitle = decodeURIComponent(queryStringParams.subTitle);
        this.editId = params.editId;
        this.option = params.option;
        this.contextPrefix = params.contextPrefix;

        const dispatchTemplateId = settingRepository.getDispatchTemplate();

        this.actions = {
            save: documentService.saveRecommendationPicture.bind(this, params.recommendationId, "recommendation", this.editId, dispatchTemplateId)
        };
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

}
