import Logger from "core/logger";
define([
    "core/storage"
], function (storage) {
    "use strict";
    var userAgent = window.navigator.userAgent.toLowerCase();

    function isWebKit() {
        return 'WebkitAppearance' in document.documentElement.style;
    }

    function isEdge() {
        return userAgent.indexOf('edge') !== -1;
    }

    function isChrome() {
        return userAgent.indexOf('chrome') !== -1;
    }

    function isSafari() {
        return userAgent.indexOf('safari') !== -1;
    }

    function isOpera() {
        return userAgent.indexOf('opera') !== -1 ||
            userAgent.indexOf('opios') !== -1 ||
            userAgent.indexOf('opr/') !== -1; //Android 8.0.0 is putting opr### in UA , opera browser is puting opr/### in the UA
    }

    function isFirefox() {
        return userAgent.indexOf('firefox') !== -1 || userAgent.indexOf('fxios') !== -1;
    }

    function isSafariWebAp() {
        return userAgent.indexOf('applewebkit') !== -1;
    }

    //use trident for IE

    function isOtherThanPermitted() {
        return isOpera() || isFirefox() || isEdge();
    }

    function iOSversion() {
        if (/iP(hone|od|ad)/.test(window.navigator.platform)) {
            var v = (window.navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
        return [-1];
    }

    var exports = {
        isSupported: function () {
            return isWebKit() && (isChrome() || isSafari() || isSafariWebAp()) && !isOtherThanPermitted();
        },

        localStorageAccess: function () {
            //Local storage not accessible in Safari on privacy mode
            return storage.checkStorage();
        },

        isWebApp: function () {
            return "standalone" in window.navigator && window.navigator.standalone;
        },

        isIOS11WebApp: function () {
            return this.isWebApp() && iOSversion()[0] >= 11;
        },

        isChromeOnIOS: function () {
            return userAgent.toLowerCase().includes("crios")
        },

        isGetUserMediaSupported() {
            return (window.navigator.mediaDevices && typeof window.navigator.mediaDevices.getUserMedia === "function");
        },

        isProtocolSecure() {
            // TODO renommer à isSecureContext 
            // TODO changer pour utiliser window.isSecureContext
            return (window.location.protocol === "https:" || window.location.hostname === "localhost");
        },

        async isCameraDeviceFound() {
            if (!window.navigator.mediaDevices || !window.navigator.mediaDevices.enumerateDevices) {
                return false;
            }

            try {
                const devices = await window.navigator.mediaDevices.enumerateDevices();
                return devices.some((device) => { return device.kind === "videoinput"; });
            } catch (error) {
                Logger.warn(error);
                return false;
            }
        },

        checkCameraAccessResult: {
            Ok: 0,
            NotSupported: 1, // Live stream not supported
            NotAllowed: 2,   // User did not allow access to the camera
            NotReadable: 3   // Camera access is allowed, but something prevented us from accessing it. Usually happens when the camera is in use in another app or browser tab
        },

        // TODO: Déplacer les méthodes qui ont rapport avec la caméra dans une classe spécifique pour manipuler la caméra.
        async checkCameraAccess() {
            if (!this.isGetUserMediaSupported()) {
                return this.checkCameraAccessResult.NotSupported;
            }

            try {
                const stream = await window.navigator.mediaDevices.getUserMedia({ video: true });
                if (stream) {
                    const tracks = stream && stream.getVideoTracks();
                    if (tracks && tracks.length) {
                        tracks[0].stop();
                    }

                    return this.checkCameraAccessResult.Ok;
                } else {
                    return this.checkCameraAccessResult.NotSupported;
                }
            } catch (error) {
                Logger.warn("Camera not accessible. Error name: " + error.name + ", Error message: " + error.message + ".");

                if (error.name === "NotAllowedError") {
                    return this.checkCameraAccessResult.NotAllowed;
                } else if (error.name === "NotReadableError") {
                    return this.checkCameraAccessResult.NotReadable;
                }

                return this.checkCameraAccessResult.NotAllowed;
            }
        },

        getOS: function () {
            var userAgent = window.navigator.userAgent,
                platform = window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        },
    };

    return exports;
});

