import { autoinject, bindable, DOM } from "aurelia-framework";
import _ from "underscore";
import { CustomEventHelper } from "helpers/custom-event-helper";

@autoinject
export class MaEvaluationCriterias {
    @bindable public text: string = "";
    @bindable public value: number = 0;
    @bindable public min: number = 0;
    @bindable public max: number = 5;
    @bindable public disable: boolean = false;
    @bindable public employee: any = null;
    @bindable public id: string = "";

    public list: any;

    constructor(private readonly element: Element) {
    }

    public bind(): any {
        this.list = [];
        for (let i = this.min; i <= this.max; i++) {
            this.list.push(i);
        }
    }

    public setValue(item: any): void {
        if (this.value === item) {
            this.value = 0;
        } else {
            this.value = item;
        }

        this.list = [];
        for (let i = this.min; i <= this.max; i++) {
            this.list.push(i);
        }

        const critToEdit = _.find(this.employee.criterias, (crit: any) => {
            return crit.id === this.id;
        });

        if (critToEdit) {
            critToEdit.value = this.value;
        }

        CustomEventHelper.dispatchEvent(this.element, "value-changed", this.employee);
    }

}
