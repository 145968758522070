import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import { PurchaseOrderService } from "services/purchase-order-service";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-typed-observable-plugin";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { CloneHelper } from "helpers/cloneHelper";
import { FormBase } from "pages/form-base";

@autoinject
export class MaterialOrderMemo extends FormBase {

    public readonly: boolean = false;
    public workOrderId: string = "";
    public orderId: any = null;
    public extension: any = null;
    public memo: string | null = "";
    public element: HTMLTextAreaElement | null = null;
    public unModified: any = null;
    @bindable public purchaseOrder: any = null;

    private minPaddingNoScroll: number = 2;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly purchaseOrderService: PurchaseOrderService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        if (!this.unModified) {
            return false;
        }

        if (!this.purchaseOrder) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrent = JSON.stringify(this.purchaseOrder).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async activate(params: any): Promise<void> {
        this.purchaseOrder = await this.purchaseOrderService.GetPurchaseOrder(params.orderId, params.extension);
        this.unModified = CloneHelper.deepClone(this.purchaseOrder);
        this.workOrderId = decodeURIComponent(params.workOrderId);
    }

    public attached(): void {
        this.resize();
    }

    public close(): void {
        routerHelper.navigateBack();
    }

    public async save(): Promise<void> {
        await this.purchaseOrderService.SavePurchaseOrderHeaderForWorkOrder(this.workOrderId, this.purchaseOrder).then(async (success: any) => {
            if (success) {
                this.unModified = null;
                routerHelper.navigateBack();
            }
        });
    }

    private resize(): void {
        if (this.element) {
            this.element.style.width = "100%";
            this.element.style.height = this.element.scrollHeight + this.minPaddingNoScroll + "px";
        }
    }
}
