import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import contactService from "services/contactService";
import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";

@autoinject
export class ProjectContact {
    public actions: any = {};
    public contacts: any;
    public dispatchProjectCode: string = "";
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N, private readonly dispatchProjectService: DispatchProjectService) {
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    public activate(params: any): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
        this.dispatchProjectCode = params.dispatchProjectCode;
    }

    public bind(): any {
        this.actions = {
            getContacts: contactService.getLookupByDispatchProjectCode.bind(contactService, this.dispatchProjectCode)
        };
    }

    private async checkIsProjectActive(): Promise<void> {
       await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
