import { I18N } from "aurelia-i18n";
import val from "core/val";
import { autoinject, bindable } from "aurelia-framework";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { NotificationHelper } from "helpers/notification-helper";

import { InventoryProjectTransferStatus } from "api/enums/inventory-project-transfer-status";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";
import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";
import { InventoryProjectTransferItemModel } from "api/models/company/inventory-project-transfer-item-model";

@autoinject()
export class InventoryProjectTransferInfo {
    public val: any = val;
    public dateHelper: any = dateHelper;

    @bindable public option: any = "";
    @bindable public transfer!: InventoryProjectTransferDetailsModel | null;

    @bindable public isMultiPrefix: boolean = false;
    @bindable public isActive: boolean = false;
    @bindable public isProjectLockedByWorkOrder: boolean = false;

    @bindable public btnLinks: any[] | undefined;

    constructor(
        private i18n: I18N,
        private routeRepository: RouteRepository,
        private readonly inventoryProjectTransferService: InventoryProjectTransferService,
        private readonly notificationHelper: NotificationHelper) {}

    public async activate(params: any): Promise<void> {
        this.transfer = await this.inventoryProjectTransferService.GetTransfer(params.transferId);
        this.isActive = this.transfer!.Status === InventoryProjectTransferStatus.Active;
        this.initButtonLinks();
    }

    public initButtonLinks(): void {
        this.btnLinks = [
            {
                id: "Memo",
                display: true,
                displayChevron: true,
                count: 0,
                linkMain: routerHelper.generate(this.routeRepository.routes.InventoryProjectTransfer_Memo.name, {transferId : this.transfer!.Id}),
                text: "Memo"
            },
            {
                id: "Documents",
                display: true,
                displayChevron: true,
                count: 0,
                linkMain: routerHelper.generate(this.routeRepository.routes.InventoryProjectTransfer_Documents.name, {transferId : this.transfer!.Id}),
                text: "Pictures/Documents"
            },
            {
                id: "Items",
                display: true,
                displayChevron: true,
                displayCount: true,
                count: this.transfer!.Items != null ? this.transfer!.Items!.length : 0,
                linkMain: routerHelper.generate(this.routeRepository.routes.InventoryProjectTransfer_Edit_Details.name, { transferId: this.transfer!.Id }),
                text: "Items"
            }];
    }

    public editTransfer(): void {
        if (this.isActive) {
            routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Edit.name, {transferId : this.transfer!.Id});
        }
    }

    public async completeTransfer(): Promise<void> {
        // Vérifier qu'on a des items.
        const hasNoItem: boolean = this.transfer!.Items!.length === 0;
        if (hasNoItem) {
            this.notificationHelper.showError(this.i18n.tr("err_InventoryTransfer_CompleteNoItems"), undefined, { timeOut: 0, extendedTimeOut: 0 });
            return;
        }

        // Vérifier qu'on a aucune quantité à 0.
        const hasItemWithQuantitySetToZero: boolean = this.transfer!.Items!.some((item: InventoryProjectTransferItemModel) => item.InventoryQty === 0);
        if (hasItemWithQuantitySetToZero) {
            this.notificationHelper.showError(this.i18n.tr("ApiError.631"), undefined, { timeOut: 0, extendedTimeOut: 0 });
            return;
        }

        const dialogMessage = this.i18n.tr("InventoryProjectTransfer_ConfirmComplete");

        this.notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<void> => {
            if (success) {
                try {
                    await this.inventoryProjectTransferService.CompleteTransfer(this.transfer!.Id);
                } catch (e) {
                    e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId ? this.notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 }) : this.notificationHelper.showError(e.response.data.ExceptionMessage, undefined, { timeOut: 0, extendedTimeOut: 0 });
                    return;
                }
                routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer.name);
            }
        });
    }
}
