import { autoinject, bindingMode, customElement } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

export interface RadioButtonItem {
    id: any;
    text: string;
}

@autoinject()
@customElement("radio-button-new")
export class RadioButtonNew {
    @bindable
    public items: RadioButtonItem[] = [];

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public value: any = null;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public readonly: boolean = false;

    private itemClicked(item: RadioButtonItem): void {
        this.value = item.id;
    }
}
