// GENERATED FILE - DO NOT MODIFY
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { ProcoreConnectToProcoreResult } from "api/models/company/procore/procore-connect-to-procore-result";
import { ProcoreCompanyModel } from "api/models/procore/company/procore-company-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProcoreConfigurationProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetProcoreConfiguration(requestConfig?: HttpClientRequestConfig): Promise<ProcoreCompanyConfigurationModel | null> {
        const uri = this.buildUri(
            "/procore/configuration",
            null,
            null
        );

        const data: ProcoreCompanyConfigurationModel | null = await this.get<ProcoreCompanyConfigurationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Procore.ProcoreCompanyConfigurationModel");
        return data;
    }

    public async GetProcoreCompanies(authorizationCode: string | null, authorizationCodeRedirectUrl: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProcoreCompanyModel[] | null> {
        const uri = this.buildUri(
            "/procore/configuration/Companies",
            null,
            { authorizationCode: (authorizationCode === null || authorizationCode === undefined) ? undefined : authorizationCode, authorizationCodeRedirectUrl: (authorizationCodeRedirectUrl === null || authorizationCodeRedirectUrl === undefined) ? undefined : authorizationCodeRedirectUrl, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProcoreCompanyModel[] | null = await this.get<ProcoreCompanyModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Procore.Company.ProcoreCompanyModel");
        return data;
    }

    public async SaveProcoreConfiguration(configuration: ProcoreCompanyConfigurationModel | null, requestConfig?: HttpClientRequestConfig): Promise<ProcoreCompanyConfigurationModel | null> {
        const uri = this.buildUri(
            "/procore/configuration",
            null,
            null
        );

        const data: ProcoreCompanyConfigurationModel | null = await this.post<ProcoreCompanyConfigurationModel | null, ProcoreCompanyConfigurationModel | null>(uri, configuration, requestConfig);
        this.transformModels(data, "Company.Procore.ProcoreCompanyConfigurationModel");
        return data;
    }

    public async ConnectToProcore(requestConfig?: HttpClientRequestConfig): Promise<ProcoreConnectToProcoreResult | null> {
        const uri = this.buildUri(
            "/procore/configuration/connect_to_procore",
            null,
            null
        );

        const data: ProcoreConnectToProcoreResult | null = await this.post<void, ProcoreConnectToProcoreResult | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.Procore.ProcoreConnectToProcoreResult");
        return data;
    }
}
