import Config from "config";
import logger from "core/logger";
import { LocalizationModel } from "api/models/company/dispatch/localization-model";

export class GeolocationService {
    
    public async getCurrentLocation(ignoreError: boolean = false): Promise<LocalizationModel | null> {

        return new Promise<LocalizationModel | null>((resolve: (value?: LocalizationModel | null) => void, reject: (reason?: any) => void): void => {

            const successCallback = (position: Position): void => {
                const localizationModel: LocalizationModel = {
                    Latitude: position.coords.latitude,
                    Longitude: position.coords.longitude
                };

                resolve(localizationModel);
            };

            const failureCallback = (positionError: PositionError): void => {
                logger.warn("Geolocation failed: " + positionError.code + " - " + positionError.message);
                if (!ignoreError) {
                    reject(positionError);
                } else {
                    resolve(null);
                }
            };

            window.navigator.geolocation.getCurrentPosition(successCallback, failureCallback, { timeout: Config.geolocationTimeout, maximumAge: Config.geolocationMaximumAge });
        });
    }

}
