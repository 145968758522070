import { inject, customAttribute } from "aurelia-framework";
import Hammer from "hammerjs";

abstract class BaseHammerJSCustomAttribute {
    public callback: (() => void) | undefined;

    protected abstract event: string;
    protected abstract hammer: HammerManager;
    protected readonly element: Element;

    constructor(element: Element) {
        this.element = element;
    }

    public attached(): void {
        this.hammer.on(this.event, this.handle.bind(this));
    }

    public detached(): void {
        this.hammer.off(this.event, this.handle.bind(this));
    }

    public valueChanged(newValue: () => void): void {
        this.callback = newValue;
    }

    protected handle(event: HammerInput): void {
        if (this.callback) {
            this.callback.call(null, { hammerEvent: event });
        }
    }
}

@customAttribute("press")
@inject(Element)
export class HammerPressCustomAttribute extends BaseHammerJSCustomAttribute {
    protected readonly event: string;
    protected readonly hammer: HammerManager;

    constructor(element: Element) {
        super(element);

        this.event = "press";
        this.hammer = new Hammer.Manager(element, {
            recognizers: [[Hammer.Press]],
        });
    }
}

@customAttribute("tap")
@inject(Element)
export class HammerTapCustomAttribute extends BaseHammerJSCustomAttribute {
    protected readonly event: string;
    protected readonly hammer: HammerManager;

    constructor(element: Element) {
        super(element);

        this.event = "tap";
        this.hammer = new Hammer.Manager(element, {
            recognizers: [[Hammer.Tap]],
        });
    }
}

@customAttribute("long-press")
@inject(Element)
export class HammerLongPressCustomAttribute extends BaseHammerJSCustomAttribute {
    protected readonly event: string;
    protected readonly hammer: HammerManager;

    constructor(element: Element) {
        super(element);

        this.event = "press";
        this.hammer = new Hammer.Manager(element, {
            recognizers: [[Hammer.Press, { time: 1000 }]],
        });
    }
}
