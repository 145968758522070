import { autoinject, customElement } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { Router } from "aurelia-router";

@autoinject()
@customElement("btn")
export class Button {
    @bindable.booleanAttr public disabled: boolean = false;

    @bindable.booleanAttr public outline: boolean = false;

    // Set the true to prevent adding legacy css classes
    @bindable.booleanAttr public preventLegacyBehavior: boolean = false;

    @bindable.string public theme: string = "";

    // Navigation parameters
    @bindable.string public route: string | null = null;
    @bindable.none public params: { [key: string]: any } | null = null;

    public href: string | null = null;

    constructor(private readonly element: Element, private readonly router: Router) {}

    public async bind(): Promise<void> {
        await this.initializeHref();
    }

    public attached(): void {
        this.element.addEventListener("click", this.clicked, true);
    }

    public dettached(): void {
        this.element.removeEventListener("click", this.clicked, true);
    }

    public async initializeHref(): Promise<void> {
        if (!this.route) {
            return;
        }

        await this.router.ensureConfigured();
        this.href = this.router.generate(this.route, this.params);
    }

    public clicked(e: Event): void {
        if (this.disabled) {
            e.preventDefault();
            e.stopPropagation();
        }
    }
}
