import { autoinject } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { nameof as nameof_ServiceCallQuotationItemType, ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";

@autoinject()
export class QuotationItem {
    @bindable.none
    public Item!: ServiceCallQuotationItemModel;

    @bindable.boolean
    public showSellingPrices: boolean = true;

    @bindable.boolean
    public showCostPrices: boolean = true;

    @bindable.boolean
    public showNonBillingDescription: boolean = true;

    @bindable.string
    public specifiedLanguage: string | undefined = undefined;

    public readonly ServiceCallQuotationItemType: typeof ServiceCallQuotationItemType = ServiceCallQuotationItemType;
    public readonly nameof_ServiceCallQuotationItemType: typeof nameof_ServiceCallQuotationItemType = nameof_ServiceCallQuotationItemType;
    public readonly NotInCatalogPrefix: string = "!";
}
