import { default as recommendationService } from "services/recommendationService";

export class Recommendation {
    fetchRecommendations;

    constructor() {
    }

    activate() {
        this.fetchRecommendations = async (completedOnly, enteredOnly, inProgressOnly, filter, page) => await recommendationService.getRecommendations(completedOnly, enteredOnly, inProgressOnly, filter, page).promise();
    }
}
