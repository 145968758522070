import { observable, inject, bindingMode, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

import Assert from "core/assert";
import { default as dateHelper } from "helpers/dateHelper";
import { Key } from "ts-keycode-enum";
import { CustomEventHelper } from "helpers/custom-event-helper";
import moment from "moment";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { FormControl } from "components/form-controls/form-control";

// TODO JL: Faire en sorte qu'arriver à la fin du Dt picker monte de journée.
// TODO JL: Faire fonctionner les flèches pour sélection la date
// TODO JL: Faire fonctionner le tab
@transient()
@inject(Element, ViewModeHelper)
export class DateTimePicker extends FormControl {

    containerElement = null;
    buttonElement = null;
    calendarElement = null;
    open = false;
    @bindable.string dateFormat = "";

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable label;
    @bindable disabled = false;
    @bindable options = [];
    @bindable enableTime = true;
    @bindable enableDate = true;
    @bindable.date minDate;
    @bindable.date maxDate;
    @bindable.string placeholder = "[placeholder]LongDateTimeFormatPlaceholder";

    @observable date = null;
    @observable time = null;
    @observable() stringRepresentation = "";

    valueChanged(newValue) {
        this.updateStringRepresentation(newValue);
        this.setDateTime(newValue)
    }

    dateChanged(newValue, oldValue) {
        Assert.isDate(newValue);

        if ((newValue && oldValue && newValue.toISOString() !== oldValue.toISOString()) || (newValue && !oldValue)) {
            if (this.enableTime) {
                this.setValue(newValue, this.time);
            } else {
                this.setValue(newValue, "00:00");
            }

            if (newValue && oldValue && newValue.toISOString() !== oldValue.toISOString()) {
                this.raiseEventDateChange(this.value, oldValue);
            }
        }
    }

    raiseEventDateChange(newValue, oldValue) {
            CustomEventHelper.dispatchEvent(this.element,
                "value-changed",
                newValue ? newValue : null,
                false,
                false);
    }

    timeChanged(value) {
        if (this.date && value === null && !this.enableTime) { return; }
        this.setValue(this.date, value);
    }

    stringRepresentationChanged(newValue, oldValue) {
        if (this.viewModeHelper && this.viewModeHelper.getIsDesktopMode()) {
            if (newValue) {
                this.valueChanged(newValue, oldValue);
                this.time = dateHelper.getTime(newValue);
            } else {
                this.valueChanged(null, oldValue);
            }
        }
    }

    updateStringRepresentation(value) {
        if (!value) {
            this.stringRepresentation = "";
            return;
        }

        const date = dateHelper.setMomentLocal(value);

        this.stringRepresentation = date.format(this.dateFormat);
        this.stringRepresentation = this.stringRepresentation.charAt(0).toUpperCase() + this.stringRepresentation.slice(1)
    }

    constructor(element, viewModeHelper) {
        super(element);
        this.viewModeHelper = viewModeHelper;

        this.onKeyDownEventHandlerDelegate = (e) => {
            this.onKeyDownEventHandler(e);
        };
    }

    bind() {
        if (!this.enableTime) {
            this.placeholder = "[placeholder]LongDateFormatPlaceholder";
        }
        if (!this.dateFormat) {
            const date = dateHelper.setMomentLocal(moment());
            this.dateFormat = this.viewModeHelper.getIsMobileMode() ? date.localeData().longDateFormat("LL") : this.enableTime ? "YYYY-MM-DD HH:mm" : date.localeData().longDateFormat("L");
        }
        this.updateStringRepresentation(this.value);
        this.setDateTime(this.value);
    }

    setDateTime(value) {
        if (value) {
            this.date = dateHelper.setHoursMinOnDate(value, 0, 0);
            this.time = dateHelper.getTime(value);
        }
        else {
            this.date = null;
            this.time = null;
        }
    }

    attached() {
        super.attached();

        window.addEventListener("keydown", this.onKeyDownEventHandlerDelegate, true);
    }

    detached() {
        window.removeEventListener("keydown", this.onKeyDownEventHandlerDelegate, true);
    }

    setValue(date, time) {
        if (!(date && time)) {
            this.value = null;
            return;
        }

        const [hours, minutes] = time.split(':');

        this.value = dateHelper.setHoursMinOnDate(date, hours, minutes);
    }

    buttonClicked() {
        if (this.open) {
            this.closeCalendar();
        } else {
            this.openCalendar();
        }
    }

    openCalendar() {
        this.open = true;
    }

    closeCalendar() {
        this.open = false;
    }

    onDateSelected(event) {
        event.stopPropagation();
        if (!this.enableTime || this.viewModeHelper.getIsMobileMode()) {
            this.closeCalendar();
        }
    }

    clear(event) {
        event.stopPropagation();
        this.value = null;
    }

    onKeyDownEventHandler(e) {
        const escapeHandler = () => {
            if (this.open) {
                this.closeCalendar();
            }
        };

        const downArrowHandler = () => {
            if (!this.open && this.containerElement.contains(window.document.activeElement)) {
                this.openCalendar();
            }
        };

        switch (e.keyCode) {
            case Key.Escape:
                return escapeHandler();
            case Key.DownArrow:
                return downArrowHandler();
            default:
                return;
        }
    }
}