import { autoinject } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";
import notificationHelper from "helpers/notificationHelper";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import interruptionService from "services/interruptionService";

@autoinject
export class DailyEntryInterruption {

    public readonly: boolean = false;
    public isProjectResponsible: boolean = false;
    public interruptions: any;
    public dispatchProjectCode: string = "";
    public dispatchDate: Date = new Date();

    public dateHelper: typeof dateHelper = dateHelper;

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        this.loadData();
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, queryString: string): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

        this.readonly = queryStringHelper.parseReadonly(queryString);

        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
    }

    public async loadData(): Promise<any> {
        this.interruptions = await interruptionService.getInterruptionsForDispatchProjectCodeAndDate(this.dispatchProjectCode, this.dispatchDate);
    }

    public async delete(data: any): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await interruptionService.delInterruptionsForDispatchProjectCodeAndDate(this.dispatchProjectCode, this.dispatchDate, data.LineNumber);
            await this.loadData();
        }

    }

    public gotoPage(rel: string): void {
        routerHelper.navigate(routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly }));
    }
}
