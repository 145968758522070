import BaseCellRenderer from "ag-grid/renderers/baseCellRenderer";

export default class CheckBoxCellRenderer extends BaseCellRenderer {
    constructor(){
        super();
    }

    inputElement = document.createElement("input");
    divElement = document.createElement("div");
    spanElement = document.createElement("span");

    squareInnerHTML = "<i class='fa fa-square'></i>";
    checkInnerHTML = "<i class='fa fa-check-square'></i>";

    // gets called once before the renderer is used
    init(params) {
        super.init(params);
        this.cell = params;
        
        this.inputElement.type = "checkbox";
        this.inputElement.disabled = true;
        
        this.divElement.classList.add("cell-checkbox");
        this.divElement.appendChild(this.spanElement);

        this.toggleInputAndSpanElements(params);     
    }

    // gets called once when grid ready to insert the element
    getGui() {
        return this.divElement;
    }

    // gets called whenever the user gets the cell to refresh
    refresh(params) {
        this.toggleInputAndSpanElements(params);
        // return true to tell the grid we refreshed successfully
        return true;
    }

    // gets called when the cell is removed from the grid
    destroy() {
    }

    toggleInputAndSpanElements(params) {
        if (params.value === true || params.value === 1 || params.value === "true" || params.value === "1") {
            this.inputElement.checked = true;
            this.spanElement.innerHTML = this.checkInnerHTML;
        } else {
            this.inputElement.checked = false;
            this.spanElement.innerHTML = this.squareInnerHTML;
        }
    }
}
