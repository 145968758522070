import { bindable } from "aurelia-framework";

export class HomeSectionsButton {
    @bindable input;

    isOpen = false;

    toggleExpander() {
        this.isOpen = !this.isOpen;
    }
}