import { bindable, inject } from "aurelia-framework";

@inject(Element)
export class CssClassCustomAttribute {
    element;

    constructor(element) {
        this.element = element;
    }

    bind() {
        this.updateControl(this.value);
    }

    valueChanged(newValue){
        this.updateControl(newValue);
    }

    updateControl(value) {
        for (let cssClass in value) {
            this.updateClass(cssClass, value[cssClass]);
        }
    }

    updateClass(cssClass, toggle) {
        if (toggle) {
            this.element.classList.add(cssClass);
        } else {
            this.element.classList.remove(cssClass);
        }
    }
}