// GENERATED FILE - DO NOT MODIFY
import { ServiceCallQuotationHistoryModel } from "api/models/company/service-call/service-call-quotation-history-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class WorkOrderQuotationHistoryProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetWorkOrderHistory(dispatchId: number, filter: string | null | undefined, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallQuotationHistoryModel[] | null> {
        const uri = this.buildUri(
            "/work-order-quotations/dispatch/{dispatchId}/history",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: (filter === null || filter === undefined) ? undefined : filter, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallQuotationHistoryModel[] | null = await this.get<ServiceCallQuotationHistoryModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallQuotationHistoryModel");
        return data;
    }
}
