
import { customElement, containerless } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { ProcoreSynchronizationResultDifferenceModel } from "api/models/procore/synchronization/procore-synchronization-result-difference-model";

@customElement("synchronization-result-entity-table")
@containerless
export class SynchronizationResultEntityTable {
    
    @bindable
    public items: ProcoreSynchronizationResultDifferenceModel[] = [];
}
