// GENERATED FILE - DO NOT MODIFY
import { DocumentModel } from "api/models/company/document/document-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class InspectionDocumentProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async AddDocumentForInspection(inspectionId: number, models: Array<DocumentModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/inspections/{inspectionId}/documents",
            { inspectionId: (inspectionId === null || inspectionId === undefined) ? undefined : inspectionId.toString() },
            null
        );

        const data: boolean = await this.post<Array<DocumentModel | null> | null, boolean>(uri, models, requestConfig);
        return data;
    }

    public async GetSectionsForInspection(inspectionId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        const uri = this.buildUri(
            "/inspections/{inspectionId}/documents/sections",
            { inspectionId: (inspectionId === null || inspectionId === undefined) ? undefined : inspectionId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DocumentSectionModel[] | null = await this.get<DocumentSectionModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.DocumentSectionModel");
        return data;
    }

    public async GetDocumentsForInspection(inspectionId: number, sectionId: string | null, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel | null> {
        const uri = this.buildUri(
            "/inspections/{inspectionId}/documents/sections/{sectionId}",
            { inspectionId: (inspectionId === null || inspectionId === undefined) ? undefined : inspectionId.toString(), sectionId: (sectionId === null || sectionId === undefined) ? undefined : sectionId },
            null
        );

        const data: DocumentSectionModel | null = await this.get<DocumentSectionModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.DocumentSectionModel");
        return data;
    }
}
