import { default as toastr } from "toastr";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as documentQueueService } from "services/documentQueueService";
import { default as notifier } from "helpers/notificationHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as routerHelper } from "helpers/routerHelper";

export class notificationCenterHelper {
        resx = resx;
        documentQueueService = documentQueueService;
        notifier = notifier;
        enumHelper = enumHelper;
        routerHelper = routerHelper;

        notificationCenterMessage = [];
        notificationCenterMessageClass = [];


        setWorkflowMenuNotificationCounter(count) {
            var approvalMenu = routerHelper.getRouteByName("Approval");
        }

         createNotificationMessageActions() {
            return {
                deleteButton: false,
                actions: [
                    {
                        css: "blue",
                        icon: "fa fa-refresh",
                        text: resx.localize("Resume"),
                        action: () => {
                            documentQueueService.resumeTransfer();
                            this.removeMessageToNotificationCenter(enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE);
                        }
                    },
                    {
                        css: "red",
                        icon: "fa fa-times",
                        text: resx.localize("Cancel"),
                        action: () => {
                            documentQueueService.clear();
                            this.removeMessageToNotificationCenter(enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE);
                        }
                    }
                ]
            };
        }

         getEntitiesList(list) {
            var result = "";

            _.map(_.uniq(list, 'entityId'),
                 (item) => {
                    switch (item.entityType) {
                    case "workorder":
                        result += resx.localize("theWorkorder");
                        break;
                    case "serviceCall":
                        result += resx.localize("theServiceCall");
                        break;
                    case "project":
                        result += resx.localize("theProject");
                        break;
                    case "recommendation":
                        result += resx.localize("theRecommendation");
                        break;
                    }

                    result += " " + item.entityId + ", ";
                });
            //remove la coma
            result = result.replace(/,\s*$/, "");
            //replace coma by word and before last item
            result = result.replace(/,([^,]*)$/, " " + resx.localize("and") + " $1");
            return result;
        }

        removeMessageToNotificationCenter(key) {
            this.notificationCenterMessage = _.reject(
                this.notificationCenterMessage,
                 (msg) => {
                    return msg.key === key;
                });

            if (key === enumHelper.notificationCenterKey.WORKFLOWAPPROVALSERVICE) {
                this.setWorkflowMenuNotificationCounter(0);
            }
        }

        addMessageToNotificationCenter(key, text, type, addActionsButton, options) {
            var index = _.findIndex(this.notificationCenterMessage, (msg) => { return msg.key === key; });
            if (index === -1) {
                var notification = {
                    key: key,
                    text: text,
                    type: type,
                    deleteButton: true,
                    actions: []
                };
                if (addActionsButton) {
                    _.extend(notification, this.createNotificationMessageActions());
                }

                _.extend(notification, options);
                this.notificationCenterMessage.push(notification);
            } else {
                this.notificationCenterMessage[index].text = text;
                this.notificationCenterMessage[index].type = type;
            }

            this.notificationCenterMessageClass = type;

            if (key === enumHelper.notificationCenterKey.WORKFLOWAPPROVALSERVICE &&
                type !== enumHelper.notificationCenterMessageType.ERROR) {
                this.setWorkflowMenuNotificationCounter(1);
            }
        }

        validateUnCompleteFileTransfer () {
            documentQueueService.getUnCompleteTransfer().done((list)=> {
                if (list.length) {
                    notifier.showDialogYesNo(
                        resx.localize("msg_FileTransferResume").replace("[entities]",
                            this.getEntitiesList(list)).replace("[nbFiles]", list.length),
                        resx.localize("msg_FileTransferResumeTitle"))
                        .done((success) => {
                            if (success) {
                                documentQueueService.resumeTransfer();
                            } else {
                                this.addMessageToNotificationCenter(
                                    enumHelper.notificationCenterKey.DOCUMENTQUEUESERVICE,
                                    resx.localize("msg_FileTransferResumeMenu").replace("[nbFiles]", list.length),
                                    enumHelper.notificationCenterMessageType.ALERT,
                                    true);
                            }
                        });
                }
            });
        }


    }
