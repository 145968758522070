import { default as routerHelper } from "helpers/routerHelper";
import { default as purchaseOrderService } from "services/purchaseOrderService"
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { observable, computedFrom, inject } from "aurelia-framework";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import { I18N } from "aurelia-i18n";

function setSpecificFields(item) {
    item.WorkOrderId = this.workOrderId;
    return item;
}

@inject(I18N, NotificationHelper, Router)
export default class MaterialOrderEdit extends FormBase {

    @observable workOrderId = "";
    @observable orderId = "";
    @observable extension = "";
    isDirty = false;

    @computedFrom("dispatchDate")
    get documentUrl() {
        return routerHelper.getRelativeUrl("document");
    }

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
        this.readonly = false;
    }

    checkDirty() {
        return this.isDirty;
    }

    activate(params) {
        this.workOrderId = decodeURIComponent(params.workOrderId);
        this.orderId = params.orderId ? params.orderId : "-1";

        if (params.q) {
            const qsParams = routerHelper.getQuerystring(params.q);
            if (qsParams) {
                this.readonly = queryStringHelper.parseReadonly(params.q);

                this.extension = qsParams.extension ? qsParams.extension : "";

                if (qsParams.dispatchDate) {
                    this.dispatchDate = qsParams.dispatchDate;
                }

                if (qsParams.projectCode) {
                    this.projectCode = qsParams.projectCode;
                }

                if (qsParams.dispatchProjectCode) {
                    this.dispatchProjectCode = qsParams.dispatchProjectCode;
                }
            }
        }

        this.actions = {
            getPurchaseOrder: purchaseOrderService.getPurchaseOrder.bind(this, this.orderId, this.extension),
            savePurchaseOrderService: purchaseOrderService.setPurchaseOrderHeaderForWorkOrder.bind(this, this.workOrderId),
            setSpecificFields: setSpecificFields
        };
    }
}