import { autoinject } from "aurelia-framework";
import routerHelper, { navigateToRoute } from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import employeeService from "services/employeeService";

@autoinject
export class ServiceSkillCertificate {

    public dispatchId: number = 0;
    public actions: any;
    public serviceType: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = true;

    constructor(private readonly routeRepository: RouteRepository) {
    }

    public activate(params: any): void {
        this.dispatchId = params.dispatchId;
        this.serviceType = params.serviceType;

        const paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        this.readonly = (paramsQs && paramsQs.readonly === "true") || false;
        this.loadData();
    }

    public getEmployeeUrl(employeeId: number): string {
        return "#";
    }

    public loadData(): void {
        this.actions = {
            getEmployeesForDispatch: employeeService.getEmployeesForDispatch.bind(employeeService, this.dispatchId),
            getEmployeeUrl: this.getEmployeeUrl.bind(this),
            addDispatchIdToUrl: false
        };
    }
}
