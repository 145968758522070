export default class ViewportHelper {
    setViewport(deviceWidth, width, initialScale, userScalable, minimalUi) {
        const viewportElement = this.getViewportElement();

        if (viewportElement) {
            let contentItems = [];

            // width
            if (deviceWidth) {
                contentItems.push("width=device-width");
            } else if (width) {
                contentItems.push("width=" + width);
            }

            // initial-scale
            if (initialScale) {
                contentItems.push("initial-scale=" + initialScale);
            }

            // user-scalable
            if (userScalable != null && userScalable != undefined) {
                if (userScalable) {
                    contentItems.push("user-scalable=yes");
                } else {
                    contentItems.push("user-scalable=no");
                }
            }

            // minimal-ui
            if (minimalUi) {
                contentItems.push("minimal-ui");
            }

            viewportElement.setAttribute("content", contentItems.join());
        }
    }

    getViewportElement() {
        return document.getElementById("viewport");
    }
}