import { autoinject } from "aurelia-framework";
import { ServiceCallDispatchEquipmentMaintenanceTaskService } from "services/service-call-dispatch-equipment-maintenance-task-service";

import { I18N } from "aurelia-i18n";

import { MaCardOptionsModel, MaCardLineModel } from "components/layout/ma-card/ma-card-options.model";
import { CallReturnEquipmentMaintenanceTaskModel } from "api/models/company/service/call-return-equipment-maintenance-task-model";
import { EquipmentMaintenanceParameters } from "./equipment-maintenance-required-material";
import { default as routerHelper } from "helpers/routerHelper";
import { ServiceCallEquipmentMaintenanceTaskType } from "api/enums/service-call-equipment-maintenance-task-type";
import { checkedNumberValues } from "helpers/enumHelper";
import { yesNoTaskValues } from "helpers/enumHelper";
import Parse from "helpers/parse";
import { NotificationHelper } from "helpers/notification-helper";

@autoinject
export class EquipmentMaintenanceTask {
    public taskCards!: MaCardOptionsModel[];

    public dispatchId: number = 0;
    public equipmentCode: string = "";
    public equipmentId: number = 0;
    public maintenanceId: string = "";
    public maintenanceFullDescription: string = "";
    public readonly: boolean = false;
    public readonly ServiceCallEquipmentMaintenanceTaskType: typeof ServiceCallEquipmentMaintenanceTaskType = ServiceCallEquipmentMaintenanceTaskType;
    public checkedNumberValues: typeof checkedNumberValues = checkedNumberValues;
    public yesNoTaskValues: typeof yesNoTaskValues = yesNoTaskValues;
    public equipmentDescription: string = "";
    public equipmentBrand: string = "";
    public equipmentSerialNo: string = "";
    public copyValuesForSameMaintenance: boolean = false;
    public copyValuesCheckboxTitle: string = "";
    
    private tasks: CallReturnEquipmentMaintenanceTaskModel[] | null = null;

    constructor(private readonly i18N: I18N, private readonly serviceCallDispatchEquipmentMaintenanceTaskService: ServiceCallDispatchEquipmentMaintenanceTaskService, private readonly notificationHelper: NotificationHelper) {
    }

    public async activate(params: EquipmentMaintenanceParameters): Promise<void> {
        this.equipmentCode = params.equipmentCode;
        this.equipmentId = params.equipmentId;
        this.maintenanceId = params.maintenanceId;
        this.maintenanceFullDescription = params.maintenanceFullDescription;
        this.dispatchId = params.dispatchId;
        this.readonly = Parse.Boolean(params.readonly);
        this.equipmentDescription = params.equipmentDescription;
        this.equipmentBrand = params.equipmentBrand;
        this.equipmentSerialNo = params.equipmentSerialNo;
       
        await this.loadData();
    }

    public createCard(item: CallReturnEquipmentMaintenanceTaskModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            displayLines: [
                new MaCardLineModel({ isCustomTemplate: true })
            ]
        });
        
        return card;
    }

    public setResult(item: CallReturnEquipmentMaintenanceTaskModel, value: any): void {
        item.Result = value;
    }

    public async save(): Promise<void> {

        if (this.copyValuesForSameMaintenance) {
            const msgWarning = this.i18N.tr("msg_ServiceCallMaintenanceTaskCopyValuesConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return;
            }
        }

        await this.serviceCallDispatchEquipmentMaintenanceTaskService.save(this.dispatchId, this.tasks, this.copyValuesForSameMaintenance);
        routerHelper.navigateBack();
    }

    public onClickCopyValue(): void {
        this.copyValuesForSameMaintenance = !this.copyValuesForSameMaintenance;
    }

    private async loadData(): Promise<void> {
        this.tasks = await this.serviceCallDispatchEquipmentMaintenanceTaskService.get(this.dispatchId, this.equipmentId, this.maintenanceId);
        this.taskCards = this.tasks!.map(this.createCard.bind(this));

        this.copyValuesCheckboxTitle = this.i18N.tr("ServiceCallMaintenanceTaskCopyValuesCheckboxTitle") + " " + this.maintenanceId + " - " + this.maintenanceFullDescription;
    }
}
