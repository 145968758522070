import { ProjectModel } from "api/models/company/project/project-model";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import phoneHelper from "helpers/phoneHelper";
import { RouterHelper } from "helpers/router-helper";
import routerHelper from "helpers/routerHelper";
import commonService from "services/commonService";
import projectService from "services/projectService";
import userSettingService from "services/userSettingsService";
import dateHelper from "helpers/dateHelper";
import defaultService from "services/defaultService";

@autoinject()
export default class ProjectIndex {
    public onlyActiveDispatch: boolean = false;
    public list: ProjectModel[] = [];
    public hasAtLeastOneResponsibleProject: boolean = false;
    public viewbag: any;
    public sectionByProject: string = "byProject";
    public sectionByDate: string = "byDate";
    public selectedDate: Date = new Date();
    public minDate: Date = new Date();

    public sectionShown: string = "";

    constructor(private readonly i18n: I18N) {
    }

    public async loadData(): Promise<any> {
        commonService.refreshProjectConfigs();

        const usersetting = await userSettingService.getOnlyActiveDispatchSetting();
        if (usersetting) {
            this.onlyActiveDispatch = usersetting.Value === "true";
        }

        this.list = [];
        this.list = this.sectionShown === this.sectionByProject ? await projectService.getProjectList(null) : await projectService.getProjectList(dateHelper.formatDateToSend(this.selectedDate));
        this.hasAtLeastOneResponsibleProject = await projectService.isCurrentUserProjectResponsible();
    }
    //#endregion

    //#region Public Functions
    public async activate(): Promise<any> {
        this.minDate.setDate(this.selectedDate.getDate() - 1);

        this.sectionShown = defaultService.getProjectLastSectionShowned();

        if (!this.sectionShown) {
            this.sectionShown = this.sectionByProject;
            await this.show(this.sectionShown);
        } else {
            await this.show(this.sectionShown);
        }
    }

    public genUrl(isProjectResponsible: boolean, id: string): string {
        const url = routerHelper.getRelativeUrl("", id);
        return url + routerHelper.addQuerystring({ isProjectResponsible: isProjectResponsible});
    }

    public goToHoursSummary(): void {
        this.viewbag = {isHourSummaryToday: true};
        let url = routerHelper.getRelativeUrl("hoursSummary");
        url += routerHelper.addQuerystring({ isProjectResponsible: this.hasAtLeastOneResponsibleProject });

        routerHelper.navigate(url);
    }

    public async setOnlyActiveDispatchesSetting(): Promise<void> {
        this.onlyActiveDispatch = !this.onlyActiveDispatch;
        await userSettingService.setOnlyActiveDispatchSetting(this.onlyActiveDispatch);
        await this.loadData();
    }

    public async show(section: string): Promise<void> {
        this.list = [];
        this.sectionShown = section;
        defaultService.setProjectLastSectionShowned(this.sectionShown);
        if (section === this.sectionByProject) {
            await this.loadData();
        } else {
            await this.loadData();
        }
    }
}
