export default class EmailHelper {
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Returns invalid e-mails present in a comma- or semicolon-separated string.
    findInvalidEmailsInString(emails) {
        if (typeof emails !== "string")
            throw new Error("Parameter must be a string.");

        const splittedEmails = emails.split(/;|,/);
        return this.findInvalidEmails(splittedEmails);
    }

    // Returns invalid e-mails present in an array.
    findInvalidEmails(emails) {
        if (!Array.isArray(emails))
            throw new Error("Parameter must be an array.");

        return emails.filter(email => !this.isEmailValid(email))
            .map(email => email.replace(/\s/g, ""));
    }

    // Validates a single e-mail address.
    isEmailValid(email) {
        return email &&
            typeof email === "string" &&
            this.emailRegex.test(email.replace(/\s/g, ""));
    }
}