define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        deleteBonus: function (bonusId) {
            return requests.remove(apiHelper.getBaseUrl() + "/dispatch/projects/bonuses/" + bonusId);
        },

        getBonus: function(id) {
            return requests.getJson(apiHelper.getBaseUrl() + "/dispatch/projects/bonuses/" + id);
        },

        // TODO: KG - ShowLoading: false - Transport
        getBonuses: function (filter, page) {
            var url = apiHelper.getBaseUrl() + "/bonus";
            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        // TODO: KG - ShowLoading: false - Transport
        getBonusesForDispatchProject: function (dispatchProjectCode, filter, page) {
            var url = apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/bonuses";
            url = apiHelper.appendFilterPagingParams(url, filter, page);
            
            return requests.getJson(url);
        },

        getBonusesForTimeEntry: function (timeEntryId, filter, page) {
            var url = apiHelper.getDispatchProjectBaseUrl() + "/bonuses/timeEntry/" + timeEntryId;
            url = apiHelper.appendFilterPagingParams(url, filter, page);
            
            return requests.getJson(url);
        },

        // TODO: KG - ShowLoading: false - Transport
        setBonus: function (data) {
            return requests.post(apiHelper.getBaseUrl() + "/dispatch/projects/bonuses/post", data);
        },

        getBonusConfigs: function () {
            return requests.getJson(apiHelper.getBaseUrl() + "/bonus/config");
        },

        getInterfaceBonusesForDispatch: function (dispatchId, data) {
            return requests.post(apiHelper.getDispatchProjectBaseUrl() + "/bonuses/" + dispatchId, data);
        },

        updateBonusesQtiesHoursForTimeEntry: function (timeEntryId, data) {
            return requests.post(apiHelper.getDispatchProjectBaseUrl() + "/bonuses/updateHours/timeEntry/" + timeEntryId, data);
        }
    };

    return exports;
});
