import { AdditionalFieldDefinitionHelper } from "./interfaces/additional-field-definition-helper";
import { AdditionalFieldDefinitionSaveHelper } from "./interfaces/additional-field-definition-save-helper";

export class AdditionalFieldDropdownHelper implements AdditionalFieldDefinitionHelper, AdditionalFieldDefinitionSaveHelper {
  public getValue(item: any): any {
    return {
      id: item.Value,
      text: item.Value,
      data: undefined
    };
  }

  public getSavedValue(item: any): string {
    if (item.Value) {
      return item.Value.id;
    }
    
    return "";
  }
}
