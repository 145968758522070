import { bindable, transient } from "aurelia-framework";

import { default as projectService } from "services/projectService";
import { default as dateHelper } from "helpers/dateHelper";

@transient()
export class ProjectHeader {
    @bindable public dispatchProjectCode: string = "";
    @bindable public dispatchDate: string = "";
    @bindable public hideInfo: boolean = false;

    public previousDispatchProjectCode: string = "";
    public previousDescription: string = "";
    public title: string = "";
    public subtitle: string = "";
    public url: string = "";

    public attached(): void {
        this.loadData();
    }

    public updateValues(dispatchProjectCode: string, dispatchDate: string, description: string): void {
        if (dispatchDate) {
            this.title = dateHelper.getFullTextDate(dispatchDate);
        } else {
            this.title = "";
        }

        this.subtitle = dispatchProjectCode + " - " + description;
        this.url = "#project/" + encodeURIComponent(dispatchProjectCode) + "/information";

        this.previousDispatchProjectCode = dispatchProjectCode;
        this.previousDescription = description;
    }

    private loadData(): void {
        if (this.dispatchProjectCode !== this.previousDispatchProjectCode) {
            projectService.getHeader(this.dispatchProjectCode).done((data: any): any => {
                this.updateValues(this.dispatchProjectCode, this.dispatchDate, data.Description);
            });
        } else {
            this.updateValues(this.dispatchProjectCode, this.dispatchDate, this.previousDescription);
        }
    }
}
