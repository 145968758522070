// GENERATED FILE - DO NOT MODIFY
export enum ServiceCallWorkOrderQuotationStatus {
    InProgressByTheResponsible = "A",
    OnHold = "B",
    CompletedByTheResponsible = "C",
    Entered = "E",
    Confirmed = "P",
    SentToCustomer = "R",
    AcceptedByTheCustomer = "S",
    CallGenerated = "T",
    RefusedByTheCustomer = "X",
    ManuallyClosed = "Y",
}

export let nameof = "ServiceCallWorkOrderQuotationStatus";
