// GENERATED FILE - DO NOT MODIFY
import { ContractSearchAddressType } from "api/enums/contract-search-address-type";
import { ContractDetailModel } from "api/models/company/service/contract-detail-model";
import { ContractDetailSearchModel } from "api/models/company/service/contract-detail-search-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallContractProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetServiceContract(contractNumber: string | null, requestConfig?: HttpClientRequestConfig): Promise<ContractDetailModel | null> {
        const uri = this.buildUri(
            "/service-call/contract/by-id",
            null,
            { contractNumber: (contractNumber === null || contractNumber === undefined) ? undefined : contractNumber }
        );

        const data: ContractDetailModel | null = await this.get<ContractDetailModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ContractDetailModel");
        return data;
    }

    public async Get(type: ContractSearchAddressType | undefined, statusToExclude: Array<string | null> | null | undefined, contractTypes: Array<string | null> | null | undefined, contractTypesToExclude: Array<string | null> | null | undefined, currentPrefixOnly: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ContractDetailSearchModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/contract",
            null,
            { type: (type === null || type === undefined) ? undefined : type, statusToExclude: (statusToExclude === null || statusToExclude === undefined) ? undefined : statusToExclude, contractTypes: (contractTypes === null || contractTypes === undefined) ? undefined : contractTypes, contractTypesToExclude: (contractTypesToExclude === null || contractTypesToExclude === undefined) ? undefined : contractTypesToExclude, currentPrefixOnly: (currentPrefixOnly === null || currentPrefixOnly === undefined) ? undefined : currentPrefixOnly.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ContractDetailSearchModel[] | null = await this.get<ContractDetailSearchModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ContractDetailSearchModel");
        return data;
    }

    public async PendingContractCanCreateServiceCall(contractNumber: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/serviceCalls/contract/pending-contract-can-create-service-call",
            null,
            { contractNumber: (contractNumber === null || contractNumber === undefined) ? undefined : contractNumber }
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }
}
