import { singleton } from "aurelia-framework";
import { HttpClient } from "http-client/http-client";

export interface ICancellationToken {
    cancel(reason?: string): void;
}

@singleton()
export class CancellationTokenFactory {
    public getCancellationToken(): ICancellationToken {
        return HttpClient.createCancellationToken();
    }
}
