define([
    "services/requests"
], function (requests) {
    "use strict";

    var exports = {
        getAll: function () {
            return requests.getJson("api/companies");
        }
    };
    return exports;
});
